import { NumberDisplayConfig } from '../directives/number-display.directive';

export class Rounder {
    // roundTo: 2, roundAfter: 5 for numbers like ADG
    public static round(num: number): number {
        return this.performRounding({
            number: num
        });
    }

    public static performRounding(numConfigToRound: NumberDisplayConfig): number {
        if (!numConfigToRound.roundTo && numConfigToRound.number >= 250) {
            numConfigToRound.roundTo = 0;
        } else if (!numConfigToRound.roundTo && numConfigToRound.number >= 5) {
            numConfigToRound.roundTo = 1;
        } else if (!numConfigToRound.roundTo) {
            numConfigToRound.roundTo = 2;
        }
        numConfigToRound.roundAfter = numConfigToRound.roundAfter || 200;

        if (numConfigToRound.number === null || numConfigToRound.number === undefined) {
            return null;
        } else {
            let rounded = 0;
            if (numConfigToRound.roundAfter > numConfigToRound.number) {
                const roundBy = Math.pow(10, numConfigToRound.roundTo);
                rounded = (Math.round(numConfigToRound.number * roundBy) / roundBy);
            } else {
                // Should not show any decimal places so rounding to remove them
                rounded = Math.round(numConfigToRound.number);
            }
            return rounded;
        }
    }
}
import { AuthService } from '../../auth/auth.service';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';





import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';
@Injectable()
export class SettingsService {
  constructor(private authService: AuthService, private apiService: ApiService) { }

  public deleteAll = (): Observable<any> => {
    return this.apiService.post('/odata/Farms/Services.DeleteAll/', {}, 'text');
  }

  public getTempPassword = (email: string): Observable<any> => {
    return this.apiService.get('/Account/GetTempPassword?email=' + email, 'text');
  }
}

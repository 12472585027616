<div class="card card-widget">

  <!-- if no values -->
  <div class="grey-out" *ngIf="dashboardEducationService.eventsE"></div>

  <!-- widget header -->
  <div class="card-header">
    <h3 class="m-0" i18n="@@activity">Activity</h3>
  </div>

  <!-- widget body/content -->
  <div class="card-body">

    <!-- widget loading -->
    <app-loading *ngIf="!events" [local]="true"></app-loading>

    <!-- no activities -->
    <div *ngIf="events && events.length === 0" class="text-center">
      <h3>
        <ng-container i18n="@@norecentactivity">No recent activity</ng-container>.
      </h3>
    </div>

    <!-- widget data -->
    <ng-container *ngIf="events && events.length > 0">
      <div class="timeline-list-wrapper">
        <ol class="timeline-list">

          <cdk-virtual-scroll-viewport
            minBufferPx="900"
            maxBufferPx="900"
            itemSize="10"
            class="cdk-virtual-scroll-viewport">

            <li *cdkVirtualFor="let event of events" class="timeline-item d-flex">

              <div class="timeline-icon {{eventIcons[event.eventType+'_class']}}">
                <span class="fa {{eventIcons[event.eventType+'_icon']}}" aria-hidden="true"></span>
              </div>

              <div class="timeline-desc">
                <b>{{event.titleText}}</b>
                <br>
                <span [innerHtml]="event.messageText"></span>
              </div>

              <div class="timeline-date" *ngIf="event.timeStamp" [appTimeSince]="event.timeStamp" title="{{dayMonthYearHourMinuteAmPmString(event.timeStamp)}}"></div>

            </li>
          </cdk-virtual-scroll-viewport>

        </ol>
      </div>
    </ng-container>

  </div>
</div>

<!-- quick search -->
<div *ngIf="showSearch" class="input-group input-group mr-1 mb-1">
  <div class="input-group-prepend">
    <span class="input-group-text"><i class="fa fa-search"></i></span>
  </div>
  <input #gb type="text" size="50" class="form-control" i18n-placeholder="placeholder text for a filter input@@quicksearch" placeholder="Quick search" (input)="searchBoxChanged()">
</div>

<!-- filters -->
<ng-container *ngIf="showFilters">

  <!-- list of applied filters -->
  <ng-container *ngFor="let name of filterNames">

    <!-- above or equal to / on or after -->
    <div class="btn-group btn-group mr-1 mb-1" *ngIf="filters[name].greater !== null && filters[name].greater !== undefined">
      <button class="btn btn-ternary" (click)="editFilter(name, 'greater', filters[name].greater); op.toggle($event);">
        {{filters[name].display}}
        <ng-container *ngIf="filters[name].type < 2" i18n="@@aboveorequalto">above or equal to</ng-container>
        <ng-container *ngIf="filters[name].type === 2" i18n="@@onorafter">on or after</ng-container>
        <span *ngIf="filters[name].type < 2">&nbsp;{{ filters[name].greater?.value ? filters[name].greater.value : filters[name].greater }}</span>
        <span *ngIf="filters[name].type === 2">&nbsp;{{dayMonthYearString(filters[name].greater)}}</span>
      </button>
      <button class="btn btn-ternary" (click)="filters[name].greater = null; removeFilter(); ">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <!-- below / before -->
    <div class="btn-group btn-group mr-1 mb-1" *ngIf="filters[name].less !== null && filters[name].less !== undefined">
      <button class="btn btn-ternary" (click)="editFilter(name, 'less', filters[name].less); op.toggle($event);">
        {{filters[name].display}}
        <ng-container *ngIf="filters[name].type < 2" i18n="@@belowlowcase">below</ng-container>
        <ng-container *ngIf="filters[name].type === 2" i18n="@@before">before</ng-container>
        <span *ngIf="filters[name].type < 2">&nbsp;{{ filters[name].less?.value ? filters[name].less.value : filters[name].less }}</span>
        <span *ngIf="filters[name].type === 2">&nbsp;{{dayMonthYearString(filters[name].less)}}</span>
      </button>
      <button class="btn btn-ternary" (click)="filters[name].less = null; removeFilter();">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <!-- equal to / on -->
    <div class="btn-group btn-group mr-1 mb-1" *ngIf="filters[name].equal !== null && filters[name].equal !== undefined">
      <button class="btn btn-ternary" (click)="editFilter(name, 'equal', filters[name].equal); op.toggle($event);">
        {{filters[name].display}}
        <ng-container *ngIf="filters[name].type < 2" i18n="@@equalto">equal to</ng-container>
        <ng-container *ngIf="filters[name].type === 2" i18n="@@on">on</ng-container>
        <span *ngIf="filters[name].type < 2">&nbsp;{{ filters[name].equal?.label ? filters[name].equal.label : filters[name].equal }}</span>
        <span *ngIf="filters[name].type === 2">&nbsp;{{dayMonthYearString(filters[name].equal)}}</span>
      </button>
      <button class="btn btn-ternary" (click)="filters[name].equal = null; removeFilter();">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <!-- not equal to -->
    <div class="btn-group btn-group mr-1 mb-1" *ngIf="filters[name].notEqual !== null && filters[name].notEqual !== undefined">
      <button class="btn btn-ternary" (click)="editFilter(name, 'notEqual', filters[name].notEqual); op.toggle($event);">
        {{filters[name].display}}
        <ng-container i18n="@@notequalto">not equal to</ng-container>
        <span *ngIf="filters[name].type < 2">&nbsp;{{ filters[name].notEqual?.value ? filters[name].notEqual.value : filters[name].notEqual }}</span>
        <span *ngIf="filters[name].type === 2">&nbsp;{{dayMonthYearString(filters[name].notEqual)}}</span>
      </button>
      <button class="btn btn-ternary" (click)="filters[name].notEqual = null; removeFilter();">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <!-- is unknown -->
    <div class="btn-group btn-group mr-1 mb-1" *ngIf="filters[name].isUnknown">
      <button class="btn btn-ternary" (click)="editFilter(name, 'isUnknown', filters[name].isUnknown); op.toggle($event);">
        {{filters[name].display}}
        <span i18n="@@IsUnknown">is unknown</span>
      </button>
      <button class="btn btn-ternary" (click)="filters[name].isUnknown = false; removeFilter();">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <!-- has any value -->
    <div class="btn-group btn-group mr-1 mb-1" *ngIf="filters[name].isKnown">
      <button class="btn btn-ternary" (click)="editFilter(name, 'isKnown', filters[name].isKnown); op.toggle($event);">
        {{filters[name].display}}
        <span i18n="@@hasAnyValue">has any value</span>
      </button>
      <button class="btn btn-ternary" (click)="filters[name].isKnown = false; removeFilter();">
        <i class="fa fa-close"></i>
      </button>
    </div>
  </ng-container>

  <!-- overlay with filter select -->
  <div ngbDropdown #op=ngbDropdown class="add-filter-button">

    <!-- add filter button -->
    <button class="btn mr-1 mb-1" ngbDropdownToggle  [ngClass]="op.isOpen() ? 'btn-primary' : 'btn-link'" (click)="resetFilterSelector();">
      <i class="fa fa-plus mr-1"></i>
      <ng-container i18n="@@addFilter">Add Filter</ng-container>
    </button>

    <div ngbDropdownMenu class="filter-overlay">
      <form (ngSubmit)="addFilter($event); op.close();">

        <!-- select filter OPTION -->
        <div class="filter-overlay-element" style="width: 225px;">
          <ng-select autofocus
                     class="form-control form-control-ng-select"
                     bindLabel="label"
                     [items]="filterOptions"
                     [clearable]="false"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="filterOption"
                     (change)="filterOptionChanged()">
          </ng-select>
        </div>

        <!-- select filter TYPE -->
        <div class="filter-overlay-element" style="width: 150px;">
          <ng-select class="form-control form-control-ng-select"
                     bindLabel="label"
                     [items]="filterTypes"
                     [clearable]="false"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="filterType">
          </ng-select>
        </div>

        <!-- set filter VALUE -->
        <div class="filter-overlay-element" style="width: 180px;" *ngIf="filterOption && filterType?.value !== 'isKnown' && filterType?.value !== 'isUnknown'">

          <!-- if string use dropdown -->
          <ng-select *ngIf="filterOption && filterOption.type === 1 && allSuggestions.length > 0"
                     class="form-control form-control-ng-select"
                     bindLabel="label"
                     bindValue="value"
                     [items]="suggestions"
                     [clearable]="false"
                     [virtualScroll]="suggestions.length > 6"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="amount">
          </ng-select>

          <!-- if string AND no values. tell user no valies -->
          <p class="text-shady-lady" i18n="@@fieldIsEmpty" *ngIf="filterOption && filterOption.type === 1 && allSuggestions.length == 0">This field is empty</p>

          <!-- if number use standard input -->
          <input *ngIf="filterOption && filterOption.type === 0" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="amount"
                 [type]="filterOption.type === 0 ? 'number' : 'text'" value="0" step="{{filterOption === 'weight' ? '50' : '0.1'}}">

          <!-- if date use primeng's date picker -->
          <input *ngIf="filterOption && filterOption.type === 2"  class="form-control" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()"
                 [ngModelOptions]="{standalone: true}" [(ngModel)]="amount">
          <!--        <p-calendar [locale]="localeService.calendarLocale"></p-calendar>-->

        </div>

        <!-- submit button -->
        <div class="filter-overlay-element">
          <button type="submit" [disabled]="amount === null && filterType?.value !== 'isKnown' && filterType?.value !== 'isUnknown'" class="btn btn-primary" i18n="@@ok">OK</button>
        </div>

      </form>
    </div>

  </div>

</ng-container>

import { Component, OnInit, Input } from '@angular/core';
import { UploadSummary } from '../../models/upload-summary.model';

@Component({
  selector: 'app-successful-upload',
  templateUrl: './successful-upload.component.html',
  styleUrls: ['./successful-upload.component.scss']
})
export class SuccessfulUploadComponent implements OnInit {
  @Input()
  uploadSummaries: UploadSummary;

  constructor() { }

  ngOnInit() {
  }

}

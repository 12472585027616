import { LocaleService } from '../helpers/locale.service';
import { Directive, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { Rounder } from '../helpers/rounder.helper';

@Directive({
  selector: '[appNumberDisplay]'
})
export class NumberDisplayDirective implements OnChanges {
  @Input('appNumberDisplay')
  appNumberDisplay: NumberDisplayConfig;

  displayNumber = '';

  constructor(private el: ElementRef, private localeService: LocaleService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.appNumberDisplay && changes['appNumberDisplay'].currentValue) {
      let num = Rounder.performRounding(this.appNumberDisplay);
      this.displayNumber = num !== null && !isNaN(num) && Math.abs(num) !== Infinity ? num.toString() : this.localeService.constants.stringNA;
      this.el.nativeElement.innerText = this.displayNumber;
    }
  }

}

export class NumberDisplayConfig {
  number: number;
  roundTo ? = 2;
  roundAfter ? = 200;
}

<section id="main" class="external-page-content">

  <div class="form-holder">

    <app-loading *ngIf="invitationStatus !== 2 && invitationStatus !== 3"></app-loading>

    <div class="card card-external" *ngIf="invitationStatus === 2 || invitationStatus === 3">

      <div class="card-body">

        <!-- logo -->
        <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

        <!-- content -->

        <div *ngIf="invitationStatus === 2 && invitation">

          <h3 i18n="@@incorrectAccount">Incorrect account</h3>

          <p i18n="invitation.email is a code reference so needs to remain untouched@@sorryButThisInvitationIsForXYZPleaseLogOutAndClickOnTheLinkAgain">Sorry, but this invitation is
            for {{invitation.email}}. Please log out and click on the link again to login/create new account.</p>
          <p i18n="@@ifYouWouldLikeToHaveThisAccountAddedToThisFarmPleaseContactTheFarmAdministratorAndRequestANewInvitationForThisAccount">If you would like to have this account added to this
            farm please contact the farm administrator and request a new invitation for this account</p>
          <p>
            <ng-container i18n="@@click">Click</ng-container>&nbsp;<a i18n="@@here" routerLink="/main/dashboard">here</a>&nbsp;<ng-container i18n="@@toGoBackToTheHomePage">to go back to the
            home page
          </ng-container>
          </p>

        </div>


        <div *ngIf="invitationStatus == 3">
          <h3 i18n="@@error">Error</h3>
          <p>
            <ng-container i18n="@@unfortunatelyTheFollowingErrorHasOccuredWhileProcessingYourRequest">Unfortunately the following error has occured while processing your request</ng-container>:
          </p>
          <p><i>{{errorMessage}}</i></p>
          <p>
            <ng-container i18n="@@click">Click</ng-container>&nbsp;<a i18n="@@here" routerLink="/main/dashboard">here</a>&nbsp;<ng-container i18n="MiHub is the product name@@toGoBackToMiHub">
            to go back to MiHub
          </ng-container>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- header -->
<div class="modal-header">
  <h3 i18n="@@editroleallcaps">EDIT ROLE</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body" *ngIf="!form">
  <app-loading [local]="true"></app-loading>
</div>

<!-- content -->
<form *ngIf="form" [formGroup]="form" class="modal-form-with-footer">
  <div class="modal-body">

    <div class="custom-control custom-radio">
      <input id="radioUser" type="radio" formControlName="farmRoleId" [value]="2" class="custom-control-input">
      <label for="radioUser" (click)="form.controls['farmRoleId'].setValue(2)" class="custom-control-label">
        <span i18n="@@user">User</span>
        <div i18n="@@userRoleDescription">Has full access to the farm but cannot invite other users.</div>
      </label>
    </div>

    <div class="custom-control custom-radio mt-3">
      <input id="radioGroupViewer" type="radio" formControlName="farmRoleId" [value]="3" class="custom-control-input">
      <label for="radioGroupViewer" (click)="form.controls['farmRoleId'].setValue(3)" class="custom-control-label">
        <span i18n="@@groupViewer">Group Viewer</span>
        <div i18n="@@groupViewerRoleDescription">Can view selected groups and the animals in them.</div>
      </label>
    </div>

    <!-- group -->
    <div class="ml-4 mt-3">
      <ng-select
        class="form-control form-control-ng-select"
        *ngIf="farmRoleId?.value == 3"
        [multiple]="true"
        [items]="groups"
        [placeholder]="localeService.constants.stringSelectGroups"
        [closeOnSelect]="false"
        bindLabel="displayName"
        bindValue="groupId"
        [(ngModel)]="selectedGroupsIds"
        [ngModelOptions]="{standalone: true}">

        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="custom-control custom-checkbox">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" class="custom-control-input">
            <label class="custom-control-label">{{ item.displayName }}</label>
          </div>
        </ng-template>

      </ng-select>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-link" (click)="ngbActiveModal.dismiss()" i18n="@@cancel">Cancel</button>
    <button [disabled]="!form || (form.controls['farmRoleId'].value === 2 && selectedGroupsCount === 0)" class="btn btn-primary ml-1" (click)="saveUserOrInvitation()" i18n="@@save">
      Save
    </button>
  </div>

</form>


import utcToLocalProperty, { utcToNZProperty } from "../api/utc-to-local-converter";

export class MobileApplication {
    dataLinkDeviceId: number;
    uniqueDeviceId: string;
    dataLinkDeviceModel: string;
    dataLinkVersion: string;
    farm_FarmId: number;
    @utcToNZProperty
    lastSeen: Date;
    created: Date;
    createdBy: string;
    modified: Date;
    modifiedBy: string;
    appType: string;
}
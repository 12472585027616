import {SessionInfoComponent} from './main/sessions/session-info/session-info.component';

import {SessionInvalidRowComponent} from './main/sessions/session-invalid-row/session-invalid-row.component';
import {AnimalAdgComponent} from './main/animals/animal-summary/animal-adg/animal-adg.component';
import {
  AnimalSummaryStatsComponent
} from './main/animals/animal-summary/animal-summary-stats/animal-summary-stats.component';
import {AnimalReportComponent} from './main/animals/animal-all/animal-report.component';

import {AnimalSummaryComponent} from './main/animals/animal-summary/animal-summary.component';
import {
  AdminFileImportReportComponent
} from './main/admin/admin-file-import-report/admin-file-import-report.component';
import {AdminGuard} from './main/admin/admin.guard';
import {AdminComponent} from './main/admin/admin.component';
import {MainGuard} from './main/main-guard.service';
import {SubscriptionsComponent} from './main/subscriptions/subscriptions.component';
import {TermsAndConditionsComponent} from './other/terms-and-conditions/terms-and-conditions.component';
import {PrivacyPolicyComponent} from './other/privacy-policy/privacy-policy.component';
import {CommsComponent} from './other/comms/comms.component';
import {SessionsAllComponent} from './main/sessions/sessions-all/sessions-all.component';
import {GroupsAllComponent} from './main/groups/groups-all/groups-all.component';
import {SessionsSummaryComponent} from './main/sessions/sessions-summary/sessions-summary.component';
import {SessionsComponent} from './main/sessions/sessions.component';
import {GroupsDetailsComponent} from './main/groups/groups-details/groups-details.component';
import {GroupsSummaryComponent} from './main/groups/groups-summary/groups-summary.component';
import {InvitationsComponent} from './other/invitations/invitations.component';

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

import {AuthGuard} from './auth/auth-guard.service';
import {AuthService} from './auth/auth.service';

import {AuthComponent} from './auth/auth.component';
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ForgotPasswordConfirmationComponent} from './auth/forgot-password-confirmation/forgot-password-confirmation.component';

import {MainComponent} from './main/main.component';
import {DashboardComponent} from './main/dashboard/dashboard.component';
import {TransferComponent} from './main/transfer/transfer.component';

import {GroupsComponent} from './main/groups/groups.component';

import {ReportsComponent} from './main/reports/reports.component';

import {UserDetailsComponent} from './main/manage/user-details/user-details.component';
import {ManageComponent} from './main/manage/manage.component';

import {NotFoundComponent} from './errors/not-found/not-found.component';
import {SessionsDetailsComponent} from './main/sessions/sessions-details/sessions-details.component';
import {SettingsComponent} from './main/settings/settings.component';

import {InviteComponent} from './main/invite/invite.component';
import {AnimalsComponent} from './main/animals/animals.component';
import {AnimalWeightGainComponent} from './main/animals/animal-summary/animal-weight-gain/animal-weight-gain.component';
import {AnimalLifeDataComponent} from './main/animals/animal-summary/animal-life-data/animal-life-data.component';
import {AnimalSessionsListComponent} from './main/animals/animal-summary/animal-sessions-list/animal-sessions-list.component';
import {AnimalDetailsComponent} from './main/animals/animal-details/animal-details.component';
import {WowDashboardComponent} from './main/dashboard/wow-dashboard/wow-dashboard.component';
import {AdminUsers} from './main/admin/admin-users';
import {AdminUsersComponent} from './main/admin/admin-users/admin-users.component';
import {AdminFarmsComponent} from './main/admin/admin-farms/admin-farms.component';
import {AdminTraceabilityComponent} from './main/admin/admin-traceability/admin-traceability.component';
import {AdminWhitelistIpsComponent} from './main/admin/admin-whitelist-ips/admin-whitelist-ips.component';
import {GroupViewerGuard} from './main/group-viewer-guard.service';
import {ActionsComponent} from './main/actions/actions.component';
import {ActionsV2Component} from './main/actionsV2/actionsV2.component';
import {GroupInfoComponent} from './main/groups/group-info/group-info.component';
import {AnimalInfoComponent} from './main/animals/animal-info/animal-info.component';
import { CookiePolicyComponent } from './other/cookie-policy/cookie-policy.component';


const appRoutes: Routes = [
  { path: '404', component: NotFoundComponent },
  { path: 'invitations/:code', component: InvitationsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'comms', component: CommsComponent },
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'register/:code', component: RegisterComponent },
      { path: 'resetPassword/:email/:code', component: ResetPasswordComponent },
      { path: 'forgotPasswordConfirmation', component: ForgotPasswordConfirmationComponent },
      { path: 'forgotPassword', component: ForgotPasswordComponent }
    ]
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    component: MainComponent,
    data: {
      breadcrumb: 'Dashboard'
    },
    children: [
      { path: 'settings', component: SettingsComponent, canActivate: [MainGuard, GroupViewerGuard], data: { breadcrumb: 'Settings' } },
      { path: 'subscriptions', component: SubscriptionsComponent, data: { breadcrumb: 'Subscriptions' } },
      { path: 'dashboard', component: DashboardComponent, canActivate: [MainGuard, GroupViewerGuard] },
      { path: 'wow-dashboard', component: WowDashboardComponent, canActivate: [MainGuard, GroupViewerGuard] },

      { path: 'transfer', component: TransferComponent, canActivate: [MainGuard, GroupViewerGuard], data: { breadcrumb: 'Upload' } },
      {
        path: 'manage',
        component: ManageComponent,
        children: [
          { path: 'user', component: UserDetailsComponent, data: { breadcrumb: 'My Details' } },
        ]
      },
      { path: 'actions', component: ActionsComponent, canActivate: [MainGuard, GroupViewerGuard], data: { breadcrumb: 'Actions' } },
      { path: 'actionsV2', component: ActionsV2Component, canActivate: [MainGuard, GroupViewerGuard], data: { breadcrumb: 'Actions' } },
      {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [MainGuard],
        data: {
          breadcrumb: 'Groups'
        },
        children: [
          { path: 'all', component: GroupsAllComponent },
          { path: 'details', component: GroupInfoComponent, data: { breadcrumb: 'Group Details' } },
          { path: 'summary', component: GroupInfoComponent, data: { breadcrumb: 'Group Details' } },
          { path: '', redirectTo: 'all', pathMatch: 'full' }
        ]
      },
      {
        path: 'animals',
        component: AnimalsComponent,
        canActivate: [MainGuard],
        data: {
          breadcrumb: 'Animals'
        },
        children: [
          { path: 'all', component: AnimalReportComponent },
          { path: 'details', component: AnimalInfoComponent, data: { breadcrumb: 'Animal Details' } },
          { path: 'summary', component: AnimalInfoComponent, data: { breadcrumb: 'Animal Details' } },
          { path: '', redirectTo: 'all', pathMatch: 'full' }
        ]
      },
      {
        path: 'sessions',
        component: SessionsComponent,
        canActivate: [MainGuard],
        data: {
          breadcrumb: 'Sessions'
        },
        children: [
          { path: 'all', component: SessionsAllComponent },
          { path: 'details', component: SessionInfoComponent, data: { breadcrumb: 'Session Details' } },
          { path: 'summary', component: SessionInfoComponent, data: { breadcrumb: 'Session Details' } },
          { path: '', redirectTo: 'all', pathMatch: 'full' }
        ]
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AdminGuard],
        children: [
          { path: 'users', component: AdminUsersComponent, data: { breadcrumb: 'Users' } },
          { path: 'farms', component: AdminFarmsComponent, data: { breadcrumb: 'Farms' } },
          { path: 'traceability', component: AdminTraceabilityComponent, data: { breadcrumb: 'Traceability' } },
          { path: 'file-import', component: AdminFileImportReportComponent, data: { breadcrumb: 'File Import' } },
          { path: 'whitelist-ips', component: AdminWhitelistIpsComponent, data: { breadcrumb: 'Whitelist IPs' } },
          { path: '', redirectTo: 'file-import', pathMatch: 'full' }
        ]
      },
      { path: 'users', component: InviteComponent, canActivate: [MainGuard, GroupViewerGuard], data: { breadcrumb: 'Users' } }
    ]
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard, MainGuard, GroupViewerGuard, AdminGuard, { provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule {};

export const routingComponents = [AuthComponent , CommsComponent, LoginComponent, RegisterComponent, MainComponent, DashboardComponent, TransferComponent
  , ReportsComponent, AnimalReportComponent, NotFoundComponent, PrivacyPolicyComponent, CookiePolicyComponent, SubscriptionsComponent, AdminUsersComponent
  , UserDetailsComponent, ManageComponent, ForgotPasswordComponent, ResetPasswordComponent, AdminComponent, AdminFileImportReportComponent
  , InvitationsComponent, GroupsComponent, GroupsDetailsComponent, SessionsComponent, SessionsSummaryComponent, AdminFarmsComponent, AdminTraceabilityComponent
  , GroupsAllComponent, SessionsDetailsComponent, SettingsComponent, SessionsAllComponent, TermsAndConditionsComponent, AdminWhitelistIpsComponent
  , AnimalSummaryComponent, AnimalsComponent, AnimalWeightGainComponent, AnimalLifeDataComponent, AnimalSessionsListComponent
  , AnimalSummaryStatsComponent, AnimalAdgComponent, AnimalDetailsComponent, SessionInvalidRowComponent, WowDashboardComponent
  , SessionInfoComponent, AnimalInfoComponent];

import {User} from '../../models/user.model';
import {CensusWeightSummary} from '../../models/session-weight-summary';
import {SessionAction} from '../../models/session-action';
import utcToLocalProperty from '../../api/utc-to-local-converter';


export class SessionSummary {
  actions?: SessionAction[];
  animalCount: number;
  averageWeight: number;
  censuses?: CensusWeightSummary[];
  created: string | Date;
  createdBy: User;
  deviceId: number | string;
  farm_FarmId: number;
  fieldDisplayOrder: string
  importDateTime: string;
  importSource: string;
  invalidRowCount: number;
  isIgnoredToAssignGroup: boolean;
  maxWeight: number;
  minWeight: number;
  modified: string | Date;
  modifiedBy: User;
  sessionDay: number;
  sessionEndDate: string;
  sessionId: number;
  sessionImportAuditId: number;
  sessionName: string;
  sessionNote: string;
  @utcToLocalProperty
  sessionStartDate: Date;
  userAgent: string;
}

import { WeightRecord } from '../../models';
import utcToLocalProperty from '../../api/utc-to-local-converter';

export class AnimalStatus {
    animalId: number;
    statusCode?: number;
    @utcToLocalProperty
    statusDate: Date;
    @utcToLocalProperty
    firstSeen: Date;
}
export interface AnimalDataSet {
    animals: Array<Animal>;
    allAnimalsCount: number;
}
class BaseAnimal {
    animalId: number = null;
    farm_FarmId: number = null;
    userDefinedFieldsJson: any = null;
    status: number = null;
    customAnimalIdentifiers: Array<CustomAnimalIdentifier>;
}
export class Animal extends BaseAnimal {
    displayName: string;
    Created: Date;
    CreatedBy: string;
    Modified: Date;
    ModifiedBy: string;
    weightRecords?: Array<WeightRecord>;
    paddockName: string;
    pplLastSeen?: Date;
    firstSeen?: Date;
}
export class CustomAnimalIdentifier {
    customAnimalIdentifiersId: number;
    farm_FarmId: number;
    animal_AnimalId: number;
    internalName: string;
    name: string;
    value: string;
    rawValue: string;
}


export enum AnimalStatusEnum {
    none = null,
    sold = 2,
    dead = 3
}

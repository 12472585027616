import {Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import * as moment from 'moment';

import {ActionsSoldDeadService} from '../../actions-sold-dead.service';
import {LocaleService} from '../../../../../helpers/locale.service';
import {AboveOrBelowSummary} from '../../../../models/above-or-below-summary';

@Component({
  selector: 'app-actions-sold-dead-model-splitter-time',
  templateUrl: './actions-sold-dead-model-splitter-time.component.html',
  styleUrls: ['./actions-sold-dead-model-splitter-time.component.scss']
})
export class ActionsSoldDeadModelSplitterTimeComponent implements OnInit, OnChanges {
  public static self: ActionsSoldDeadModelSplitterTimeComponent;
  @Input() headerName: string;

  aboveSummary: AboveOrBelowSummary = {};
  belowSummary: AboveOrBelowSummary = {};

  time = '09:00:00';
  internalTimeFormat = 'HH:mm:ss';

  aboveSelected = true;

  get displayTime(): string {
    return moment(this.time, this.internalTimeFormat).toDate().toLocaleTimeString();
  }

  constructor(public actionsSoldDeadService: ActionsSoldDeadService,
              public localeService: LocaleService) {
    ActionsSoldDeadModelSplitterTimeComponent.self = this;
  }

  ngOnInit() {
    setTimeout(() => { // run with delay to avoid front end glitch
      this.actionsSoldDeadService.getWeightRecordIds = this.getWeightRecordIds;
    }, 0);

    this.workoutSummaries();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headerName && changes['headerName'].currentValue) {
      this.workoutSummaries()
    }
  }

  workoutSummaries(): void {
    this.aboveSummary = { count: 0, weight: 0, label: '', value: this.aboveSummary ? this.aboveSummary.value : null, weightRecordIds: [] };
    this.belowSummary = { count: 0, weight: 0, label: '', value: this.belowSummary ? this.belowSummary.value : null, weightRecordIds: [] };

    this.actionsSoldDeadService.animalData.forEach(animalData => {
      if (animalData[this.headerName] !== null && animalData[this.headerName] !== undefined) {
        const momentAnimalData = moment(animalData[this.headerName], this.internalTimeFormat);
        const momentTime = moment(this.time, this.internalTimeFormat);

        if (momentAnimalData.isSameOrBefore(momentTime)) {
          this.belowSummary.count++;
          this.belowSummary.weight += animalData.weight || 0;
          this.belowSummary.weightRecordIds.push(animalData.weightRecordId);
        } else {
          this.aboveSummary.count++;
          this.aboveSummary.weight += animalData.weight || 0;
          this.aboveSummary.weightRecordIds.push(animalData.weightRecordId);
        }
      }
    });

    this.aboveSummary.weight = this.aboveSummary.count > 0 ? Math.round(this.aboveSummary.weight / this.aboveSummary.count) : 0;
    this.belowSummary.weight = this.belowSummary.count > 0 ? Math.round(this.belowSummary.weight / this.belowSummary.count) : 0;
  }

  getWeightRecordIds(): Array<number> {
    if (ActionsSoldDeadModelSplitterTimeComponent.self.aboveSelected) {
      return ActionsSoldDeadModelSplitterTimeComponent.self.aboveSummary.weightRecordIds;
    } else {
      return ActionsSoldDeadModelSplitterTimeComponent.self.belowSummary.weightRecordIds;
    }
  }
}

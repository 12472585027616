<h3 class="page-heading" i18n="@@uploadsummary">Upload Summary</h3>

<div class="card" *ngIf="uploadSummaries">
  <div class="table-responsive">
    <table class="table table-hover mb-0">
      <tbody>
        <tr>
          <td *ngIf="uploadSummaries && uploadSummaries.length > 1">
            <ng-container i18n="@@xxsessionfilessuccessfullyuploaded">{{uploadSummaries.length}} session files successfully uploaded</ng-container>
            <span *ngIf="fileWithInvalidRows > 1" class="text-danger" i18n="@@xxsessionfileshaveinvalidrows"> ({{fileWithInvalidRows}} session files have invalid rows)</span>
            <span *ngIf="fileWithInvalidRows === 1" class="text-danger" i18n="@@onesessionfilehasinvalidrows">(1 session file has invalid rows)</span>
          </td>
          <td *ngIf="uploadSummaries && uploadSummaries.length === 1">
            <ng-container i18n="@@onesessionfilesuccessfullyuploaded">1 session file successfully uploaded</ng-container>
            <span *ngIf="fileWithInvalidRows > 1" class="text-danger" i18n="@@xxsessionfileshaveinvalidrows"> ({{fileWithInvalidRows}} session files have invalid rows)</span>
            <span *ngIf="fileWithInvalidRows === 1" class="text-danger" i18n="@@onesessionfilehasinvalidrows">(1 session file has invalid rows)</span>
          </td>
          <td *ngIf="!uploadSummaries || (uploadSummaries && uploadSummaries.length === 0)" i18n="@@nosessionfilessuccessfullyuploaded">No session files successfully uploaded</td>
        </tr>
        <tr>
          <td class="text-danger" *ngIf="errorFileCount > 1" i18n="@@xxsessionfilesfailedtoupload">{{errorFileCount}} session files failed to upload</td>
          <td class="text-danger" *ngIf="errorFileCount === 1" i18n="@@onesessionfilefailedtoupload">1 session file failed to upload</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

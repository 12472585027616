import { AnimalSummary } from '../../models/animal-adg.model';
import { DashboardEducationService } from '../dashboard-education.service';
import { EventAuditService } from '../../api/event-audit.service';
import { EventAudit } from '../../models/event-audit';
import { MainService } from '../../main.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatatypeHelper } from '../../../helpers/datatype.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-activity-widget',
  templateUrl: './activity-widget.component.html',
  styleUrls: ['./activity-widget.component.scss']
})
export class ActivityWidgetComponent implements OnInit, OnDestroy {
  events: Array<EventAudit>;
  private subs: Array<Subscription> = [];

  eventIcons = {
    ImportSuccessful_class: 'bg-success',
    ImportSuccessful_icon: 'fa-file-import',
    ImportPartialSuccessful_class: 'bg-success',
    ImportPartialSuccessful_icon: 'fa-file-import',
    ImportFailed_class: 'bg-danger',
    ImportFailed_icon: 'fa-file-import',
    ImportFromDeviceSuccessful_class: 'bg-success',
    ImportFromDeviceSuccessful_icon: 'fa-file-import',
    ImportFromDevicePartialSuccessful_class: 'bg-success',
    ImportFromDevicePartialSuccessful_icon: 'fa-file-import',
    ImportFromDeviceFailed_class: 'bg-danger',
    ImportFromDeviceFailed_icon: 'fa-file-import',
    SessionAssigned_class: 'bg-success',
    SessionAssigned_icon: 'ci ci-groups-custom-white',
    AnimalAssigned_class: 'bg-success',
    AnimalAssigned_icon: 'fa-groups-custom',
    ActionAssigned_class: 'bg-success',
    ActionAssigned_icon: 'ci ci-groups-custom-white',
    ActionIgnored_class: 'bg-success',
    ActionIgnored_icon: 'ci ci-groups-custom-white',
    SessionAssignmentConfirmed_class: 'bg-success',
    SessionAssignmentConfirmed_icon: 'ci ci-groups-custom-white',
    GroupCreated_class: 'bg-success',
    GroupCreated_icon: 'ci ci-groups-custom-white',
    GroupDeleted_icon: 'fa-trash',
    GroupSessionsChanged_class: 'bg-success',
    GroupSessionsChanged_icon: 'ci ci-groups-custom-white',
    GroupSessionsAdded_class: 'bg-success',
    GroupSessionsAdded_icon: 'ci ci-groups-custom-white',
    GroupSessionsRemoved_class: 'bg-success',
    GroupSessionsRemoved_icon: 'ci ci-groups-custom-white',
    AnimalTagChanged_class: 'bg-success',
    AnimalTagChanged_icon: 'fa-exchange-alt',
    SessionDeleted_icon: 'fa-trash',
    LeftDraftRemoved_class: 'bg-success',
    LeftDraftRemoved_icon: 'fa-arrow-circle-left',
    LeftDraftAdded_class: 'bg-success',
    LeftDraftAdded_icon: 'fa-arrow-circle-left',
    StraightDraftRemoved_class: 'bg-success',
    StraightDraftRemoved_icon: 'fa-arrow-circle-up',
    StraightDraftAdded_class: 'bg-success',
    StraightDraftAdded_icon: 'fa-arrow-circle-up',
    RightDraftRemoved_class: 'bg-success',
    RightDraftRemoved_icon: 'fa-arrow-circle-right',
    RightDraftAdded_class: 'bg-success',
    RightDraftAdded_icon: 'fa-arrow-circle-right',
    MarkAsSold_class: 'bg-success',
    MarkAsSold_icon: 'ci ci-groups-custom-white',
    MarkAsDied_class: 'bg-success',
    MarkAsDied_icon: 'ci ci-groups-custom-white',
    MarkAsCurrent_class: 'bg-success',
    MarkAsCurrent_icon: 'ci ci-groups-custom-white',
    MarkAsSoldInGroup_class: 'bg-success',
    MarkAsSoldInGroup_icon: 'ci ci-groups-custom-white',
    MarkAsDiedInGroup_class: 'bg-success',
    MarkAsDiedInGroup_icon: 'ci ci-groups-custom-white',
    MarkAsCurrentInGroup_class: 'bg-success',
    MarkAsCurrentInGroup_icon: 'ci ci-groups-custom-white',
    MarkAsSoldInAnimalsAll_class: 'bg-success',
    MarkAsSoldInAnimalsAll_icon: 'ci ci-groups-custom-white',
    MarkAsDiedInAnimalsAll_class: 'bg-success',
    MarkAsDiedInAnimalsAll_icon: 'ci ci-groups-custom-white',
    MarkAsCurrentInAnimalsAll_class: 'bg-success',
    MarkAsCurrentInAnimalsAll_icon: 'ci ci-groups-custom-white'
  }

  constructor(private mainService: MainService, private eventAuditService: EventAuditService
    , public dashboardEducationService: DashboardEducationService) { }

  ngOnInit() {
    this.subs.push(this.eventAuditService.eventAudits.subscribe(events => {
      if (events !== null && events !== undefined && events.length === 0) {
        this.dashboardEducationService.getEventData().then(e => {
          this.events = e;
        });
      } else {
        this.dashboardEducationService.eventsE = false;
        this.events = events ? events.slice() : null;
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  dayMonthYearHourMinuteAmPmString(date: Date) {
    return moment(date).format('D MMM YYYY hh:mm a');
  }
}

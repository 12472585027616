import { LocaleService } from '../../helpers/locale.service';
import { SignalrMessage, SignalrType } from '../api/signal-r.service';
import { BottomNotificationsService } from '../../components/bottom-notifications/bottom-notifications.service';
import { MainService } from '../main.service';
import { Component, NgZone, ViewChild, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransferService } from './transfer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalRService } from '../api';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    providers: [TransferService],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferComponent implements OnInit, OnDestroy, AfterViewInit {
    uploadResult: any;
    filesArray: Array<File> = [];
    uploadMessage: string;
    uploadProgress = -1;
    currentFileNumber = 1;
    public canReadXslx = true;
    private subs: Array<Subscription> = [];


    @ViewChild('fileInput', { static: false }) fileInput;

    constructor(private transferService: TransferService, private signalRService: SignalRService, private zone: NgZone
        , public mainService: MainService, private bottomNotificationsService: BottomNotificationsService,
        private route: ActivatedRoute, title: Title, private localeService: LocaleService
        , private authService: AuthService, private router: Router) {
        title.setTitle(`${this.localeService.constants.stringUpload} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    }

    uploadMore() {
        // Resets values so that user can upload more files if they wish.
        // Also resets the page visually, as everything is done from within the one link
        this.uploadResult = null;
        this.uploadMessage = '';
        this.uploadProgress = -1;
        this.filesArray = [];
        this.currentFileNumber = 1;
    }

    filesChanged(fileList) {
        let files: FileList = fileList;
        if (fileList.length === 0) {
            return;
        }
        let size = 0;
        for (let i = 0, l = files.length; i < l; i++) {
            size += files[i].size;
        }

        if (files.length > 300 || size >= 10485760) {
            this.bottomNotificationsService.currentMessage.next({
                title: this.localeService.constants.stringError,
                message: this.localeService.constants.stringPleaseSelectUpload300OrLessFilesAtOneTimeTheTotalSizeOfTheFilesShouldBeUnder10mb,
                type: 'warning'
            });
            return;
        }
        this.filesArray = Array.from(files);
        if (!this.canReadXslx &&
            (this.filesArray.filter(f => f.name.toLocaleLowerCase().endsWith('.xls') || f.name.toLocaleLowerCase().endsWith('.xlsx')).length > 0)) {
            this.bottomNotificationsService.currentMessage.next({
                title: this.localeService.constants.stringError,
                message: this.localeService.constants.stringThisFileTypeIsNotSupportedPleaseSaveItAsACSVFileAndTryAgain,
                type: 'warning'
            });
            return;
        }
        this.fileInput.nativeElement.value = '';
        this.sendCsvs();
    }

    removeFileFromList(file: File) {
        this.filesArray.splice(this.filesArray.indexOf(file), 1);
        this.fileInput.nativeElement.value = '';
    }

    sendCsvs() {
        if (this.filesArray.filter(f => f.name.toLocaleLowerCase().endsWith('.csv')
            || f.name.toLocaleLowerCase().endsWith('.xls') || f.name.toLocaleLowerCase().endsWith('.xlsx')).length > 0) {
            this.uploadProgress = 0;
            this.zone.run(function () { });
            this.transferService.UploadFiles(this.filesArray).subscribe(res => {
                this.filesArray = [];
                this.uploadResult = res;
            });
        }
    }

    messageRecieved(msg: SignalrMessage) {
        if (msg && msg.type === SignalrType.progressBar && msg.data && this.filesArray.length > 0) {
            this.currentFileNumber = Math.ceil(msg.data.count / (100 / this.filesArray.length));
            if (this.currentFileNumber > this.filesArray.length) { this.currentFileNumber = this.filesArray.length; }
            this.uploadProgress = Math.round(msg.data.count);
            this.zone.run(function () { });
        }
    }

    ngOnInit(): void {
        this.canReadXslx = FileReader.prototype.readAsBinaryString != null;
        let ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') !== -1 || ua.indexOf('Trident/') !== -1) {
            this.canReadXslx = false;
        }
        this.subs.push(this.signalRService.update.subscribe(res => {
            if (res !== null && res !== undefined) {
                this.messageRecieved(res)
            }
        }));
        this.subs.push(this.mainService.farmChanged.subscribe(farm => {
            this.uploadMore();
        }));
    }

    openUploadScreen() {
        this.fileInput.nativeElement.click();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy() {
        this.subs.forEach(sub => { sub.unsubscribe(); });
    }
}

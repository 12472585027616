import { Rounder } from '../helpers/rounder.helper';
import { Directive, ElementRef, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { LocaleService } from '../helpers/locale.service';
import { ConvertToUTC } from '../helpers/datatype.helper';

declare var Highcharts: any;
declare var $: any;


@Directive({
  selector: '[appHighcharts]'
})
export class HighchartsDirective implements OnInit, OnChanges {
  // For reference http://api.highcharts.com/highcharts

  @Input('hideDay')
  hideDay = false;

  @Input('appHighcharts')
  appHighcharts: any;
  options: any = {
    title: {
      text: null
    },

    subtitle: {
      text: null
    },
    xAxis: {
      title: {
        text: null
      },
      labels: {
        style: {
          fontFamily: '"Lato", Helvetica, Arial, sans-serif',
          fontSize: '12px'
        }
      },
      lineWidth: 1
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        style: {
          fontFamily: '"Lato", Helvetica, Arial, sans-serif',
          fontSize: '12px'
        }
      },
      lineWidth: 1
    },
    legend: {
      enabled: false
    },

    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            'stroke': 'none',
            'fill': 'black',
            'color': 'black',
            'font-size': '18px',
            textOutline: false
          }
        }
      },
      line:
      { softThreshold: false },
      columnrange: {
        dataLabels: {
          style: {
            textShadow: false,
            textOutline: false
          }
        }
      },
      series: {
        animation: true,
        states: {
          hover: true
        },
        dataLabels: {
          style: {
            textShadow: false,
            textOutline: false
          }
        }
      }
    },
    exporting: {
      enabled: false
    },
    series: [],

    // Lowercase colours are Highcharts defaults. Caps are ones we've overwritten
    // $yellow = #FFCD00, $blue = #001E60
    colors: ['#00AAE7', '#69B342', '#FFCD00', '#EE3124', '#001E60', '#D0D0CE']
  };

  constructor(private el: ElementRef, private localeService: LocaleService) {
    // need to explicitly iterate the arrays to get values out
    let lMonths = [];
    this.localeService.month.forEach(m => lMonths.push(m.fullMonth));
    let sMonths = [];
    this.localeService.month.forEach(m => sMonths.push(m.month));
    let weeks = [];
    this.localeService.weekdays.forEach(m => weeks.push(m));

    Highcharts.setOptions({
      global: {
        useUTC: true
      },
      lang: {
        months: lMonths,
        shortMonths: sMonths,
        weekdays: weeks,
        decimalPoint: this.localeService.constants.decimalSymbol,
        downloadJPEG: this.localeService.constants.stringDownloadJPEG,
        downloadPDF: this.localeService.constants.stringDownloadPDF,
        downloadPNG: this.localeService.constants.stringDownloadPNG,
        printChart: this.localeService.constants.stringPrintChart,
        contextButtonTitle: this.localeService.constants.stringContextButtonTitle
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: true,
            theme: {
              fill: '#ffffff'
            }
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const self = this;
    if (this.appHighcharts && changes['appHighcharts'].currentValue && this.appHighcharts.chart && this.appHighcharts.chart.type === 'column') {
      Object.assign(this.options, changes['appHighcharts'].currentValue);
      // this.options.tooltip.updatePosition = function (point) {
      //   var chart = this.chart,
      //     label = this.label,
      //     pos = (this.options.positioner || this.getPosition).call(
      //       this,
      //       label.width,
      //       label.height,
      //       point
      //     );

      //   // do the move
      //   this.move(
      //     Math.round(pos.x),
      //     Math.round(pos.y || 0), // can be undefined (#3977)
      //     point.plotX + chart.plotLeft - pos.x,
      //     point.plotY + chart.plotTop - pos.y
      //   );
      // };

      if (!this.options.tooltip && this.options.series) {
        let opt = this.options;
        opt.tooltip = {
          crosshairs: true,

          shared: false,

          useHTML: true,
          headerFormat: '<span style="font-weight: 600; font-size: 12px">{point.key}</span><br/>',
          // shared: true,
          backgroundColor: 'rgba(255,255,255,1)',
          style: { 'font-weight': 'bold', opacity: 1, 'background-color': 'rgba(255,255,255,1)', 'z-index': 50 }
        };
      }
      let chart = $(this.el.nativeElement).highcharts(this.options);
    } else if (this.appHighcharts && changes['appHighcharts'].currentValue) {
      Object.assign(this.options, changes['appHighcharts'].currentValue);
      if (!this.options.tooltip && this.options.series) {
        let opt = this.options;
        opt.tooltip = {
          crosshairs: true,
          useHTML: true,
          shared: true,
          backgroundColor: 'rgba(255,255,255,1)',
          style: { opacity: 1, 'background-color': 'rgba(255,255,255,1)', 'z-index': 50 },
          formatter: function () {
            let tooltipInfo = [];
            
            let date = new Date(this.x);
            
            let disp = `<b>${this.hideDay ? '' : date.getUTCDate()} ${self.localeService.month[(date.getUTCMonth())].month} ${date.getUTCFullYear()}</b><br/>`;
            // Idea is. For each series find a point
            // If no point then this series falls outside of current point
            // If so then use trigonometry to find values at that point
            opt.series.forEach(serie => {
              let point = this.points.find(p => p.series.name === serie.name);
              if (point) {
                let s = '';
                if (!serie.onlyExtra && !serie.hideMyDataFromToolTips) {
                  s += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: `;
                  s += point.point.high
                    ? ` ${Rounder.round(point.point.low)}`
                    + `-${Rounder.round(point.point.high)}`
                    : Rounder.round(point.y);
                }
                if (serie.nameOnly) {
                  s = `<span style="color:${point.color}">\u25CF</span> ${serie.name}`;
                }
                if (serie.onlyExtra) {
                  s = '';
                }
                if (serie.zIndex >= 0) {
                  tooltipInfo.push({ order: point.order || serie.zIndex || 0, html: s });
                }
                if (this.y === 0 && serie.onlyExtra) {
                  tooltipInfo = [];
                }
                if (point.point.extra) {
                  point.point.extra.forEach(e => {
                    tooltipInfo.push(e);
                  });
                }
              } else if (serie.name !== 'solddiedpoints' && !serie.hideMyDataFromToolTips) {
                // If we fall here then the series does not have value at this point
                // not apply if serie is sold or died, which we don't want to predict values between points
                if (serie.data.length > 0 && serie.data[0].constructor === Array) { // Area calculation
                  let less = (<Array<Array<number>>>serie.data).filter(s => s[0] < this.x),
                    more = (<Array<Array<number>>>serie.data).filter(s => s[0] > this.x),
                    s = `<span style="color:${(<any>serie).color || 'rgb(0, 170, 231)'}">\u25CF</span> ${serie.name}: `;
                  if (less.length > 0 && more.length > 0) {
                    for (let i = 1; i <= 2; i++) {
                      let prevPoint = { x: less[less.length - 1][0], y: less[less.length - 1][i] },
                        nextPoint = { x: more[0][0], y: more[0][i] },
                        x = (nextPoint.x - prevPoint.x) / 86400000, // adj
                        y = nextPoint.y - prevPoint.y, // opp
                        newX = (this.x - prevPoint.x) / 86400000, // new hyp length
                        ang = Math.atan(y / x),
                        newY = Math.sin(ang) * newX + prevPoint.y;
                      s += Rounder.round(newY).toString() + (i === 2 ? '' : '-');
                    }
                    if (serie.nameOnly) {
                      s = `<span style="color:${point.color}">\u25CF</span> ${serie.name}`;
                    }
                    if (serie.onlyExtra) {
                      s = '';
                    }
                    if (serie.zIndex >= 0) {
                      tooltipInfo.push({ order: serie.zIndex || 0, html: s });
                    }
                  }

                } else if (serie.data.length) { // This is calication for average and simple NON AREA series
                  let less = (<Array<any>>serie.data).filter(s => s.x < this.x),
                    more = (<Array<any>>serie.data).filter(s => s.x > this.x);
                  if (less.length > 0 && more.length > 0) {
                    let prevPoint = less[less.length - 1],
                      nextPoint = more[0],
                      x = (nextPoint.x - prevPoint.x) / 86400000, // adj
                      y = nextPoint.y - prevPoint.y, // opp
                      newX = (this.x - prevPoint.x) / 86400000, // new hyp length
                      ang = Math.atan(y / x),
                      newY = Math.sin(ang) * newX + prevPoint.y,
                      colour = (<any>serie).color || ((<any>serie).marker ? (<any>serie).marker.lineColor : null) || 'rgb(0, 170, 231)',
                      s = `<span style="color:${colour}">\u25CF</span> ${serie.name}: `
                        + Rounder.round(newY);
                    if (serie.nameOnly) {
                      s = `<span style="color:${point.color}">\u25CF</span> ${serie.name}`;
                    }
                    if (serie.onlyExtra) {
                      s = '';
                    }
                    if (serie.zIndex >= 0) {
                      tooltipInfo.push({ order: prevPoint.order || serie.zIndex || 0, html: s });
                    }
                    if (prevPoint.extra) {
                      prevPoint.extra.forEach(e => {
                        tooltipInfo.push(e);
                      });
                    }
                  }
                }
              }
            });

            tooltipInfo.sort((a, b) => b.order - a.order);
            return disp + tooltipInfo.filter(t => t.html !== '').map(t => t.html).join('<br/>');
          },
        };
      }
      let chart = $(this.el.nativeElement).highcharts(this.options);
    }
  }


  ngOnInit() {
  }
}

import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {throwError, Subscription, Observable} from 'rxjs';

import {PermissionsService} from '../../../auth/permissions.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {AuthService} from '../../../auth/auth.service';
import {FarmService} from '../../api/farm.service';
import {MainService} from '../../main.service';
import {SettingsService} from '../../settings/settings.service';
import {CountryService} from '../../api/countries.service';
import {Farm, Country} from '../../models';
import {RequestStatus} from '../../models/request-status.enum';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {LocaleService} from '../../../helpers/locale.service';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';

@Component({
  selector: 'app-farm-details',
  templateUrl: './farm-details.component.html',
  styleUrls: ['./farm-details.component.scss'],
  providers: [CountryService, SettingsService]
})
export class FarmDetailsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  requestStatus: RequestStatus = RequestStatus.notStarted;
  serverErrorMessage = '';
  countries: Array<Country> = [];
  regions: Array<string> = [];
  formHasChanged = false;
  hasDeleteQuery = false;
  loading = false;
  uploadStatus: RequestStatus = RequestStatus.notStarted;

  private subscription: Array<Subscription> = [];

  constructor(private settingsService: SettingsService,
              private farmService: FarmService,
              private authService: AuthService,
              public mainService: MainService,
              private localeService: LocaleService,
              private countryService: CountryService,
              private confirmationService: ConfirmationService,
              private bottomNotificationsService: BottomNotificationsService,
              public permissionsService: PermissionsService,
              private route: ActivatedRoute,
              private globalParameterService: GlobalParameterService) {
  }

  ngOnInit() {
    this.hasDeleteQuery = this.route.snapshot.queryParams['deleteall'] === 'true' ? true : false;
    this.countryService.GetCountries().subscribe(countries => {
      this.countries = countries;
      for (let country of countries) {
        country.regionsArray = JSON.parse(country.regions) as Array<string>;
      }
      if (this.mainService.farmChanged.value) {
        let matchingCountry = this.countries.find(c => c.countryId === this.mainService.farmChanged.value.countries_CountryId);
        if (matchingCountry) {
          this.regions = matchingCountry.regionsArray;
        }
      }
    });
    this.subscription.push(this.mainService.farmChanged.subscribe(farm => {
      if (farm) {
        if (this.mainService.farmChanged.value) {
          let matchingCountry = this.countries.find(c => c.countryId === this.mainService.farmChanged.value.countries_CountryId);
          if (matchingCountry) {
            this.regions = matchingCountry.regionsArray;
          }
        }
        this.setUpFarmForm(farm);
      }
    }));
  }

  ngOnDestroy() {
    for (let i = 0; i < this.subscription.length; i++) {
      this.subscription[i].unsubscribe();
    }
  }

  setUpFarmForm(farm: Farm = null) {
    const allowDetailEdit = this.permissionsService.permissions.farmDetailsEdit;

    this.form = new FormGroup({
      farmName: new FormControl({ value: farm ? farm.farmName : '', disabled: !allowDetailEdit }, Validators.required),
      addressLine1: new FormControl({ value: farm ? farm.addressLine1 : '', disabled: !allowDetailEdit }),
      addressLine2: new FormControl({ value: farm ? farm.addressLine2 : '', disabled: !allowDetailEdit }),
      city: new FormControl({ value: farm ? farm.city : '', disabled: !allowDetailEdit }),
      postcode: new FormControl({ value: farm ? farm.postcode : '', disabled: !allowDetailEdit }),
      region: new FormControl({ value: farm ? farm.region : '', disabled: !allowDetailEdit }, Validators.required),
      country: new FormControl({ value: farm ? farm.countryLookup.countryName : '', disabled: !allowDetailEdit }, Validators.required)
    });

    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  formChanged() {
    // potentially need to check that changes aren't setting fields to their default values
    this.formHasChanged = true;
  }

  save(event: Event) {
    event.preventDefault();

    if (this.form.valid) {
      this.requestStatus = RequestStatus.working;
      let newFarm: any = this.form.value;
      newFarm['farmId'] = this.mainService.farmChanged.value.farmId;
      this.farmService.updateFarm(newFarm).pipe(catchError((err: any, caught: Observable<any>) => {
        this.requestStatus = RequestStatus.notStarted;
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringSaveFailed,
          message: this.localeService.constants.stringFarmDetailsFailedToUpdatePleaseTryAgainLater,
          type: 'warning'
        });
        this.serverErrorMessage = err.statusText;
        return throwError('error');
      })).subscribe(o => {

        if (!o.countryLookup) {
          o.countryLookup = {
            countryName: o.country
          };
        }

        this.mainService.updateFarm(o);
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringSaveSuccessful,
          message: this.localeService.constants.stringFarmDetailsSuccessfullyUpdated,
          type: 'success'
        });
        this.requestStatus = RequestStatus.notStarted;
        this.formHasChanged = false;
      });
    } else {
      this.form.controls['region'].markAsDirty();
      this.form.controls['farmName'].markAsDirty();
    }
  }

  deleteAllSessionAndAnimals(): void {
    this.confirmationService.confirm({
      title: 'DELETE ALL DATA',
      message: 'Are you sure that you want to delete all session and animal data?'
    }).subscribe(() => {
      this.doDeleteAllSessionAndAnimals();
    });
  }

  doDeleteAllSessionAndAnimals(): void {
    this.uploadStatus = RequestStatus.working;

    this.settingsService.deleteAll().pipe(catchError((error: any, caught: any) => {
      let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.uploadStatus = RequestStatus.failed;
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringDeleteAllFailed,
        message: `${this.localeService.constants.stringFailedToDeleteAllData}: ${errMsg}.`,
        type: 'warning'
      });
      return throwError(errMsg);
    })).subscribe(res => {
      if (res !== null) {
        this.globalParameterService.currentSessionId = null;
        this.uploadStatus = RequestStatus.successful;
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringDeleteSuccessful,
          message: this.localeService.constants.stringSuccessfullyDeletedAllSessionAndAnimalData,
          type: 'success'
        });

        // need to refresh all data here too
        this.mainService.changeFarm(this.mainService.farmChanged.value.farmId);
      }
    });
  }

  deleteDemoFarm() {
    if (this.mainService.farmChanged.value.farmType === 'Demo') {
      this.loading = true;
      this.farmService.deleteDemoFarm(this.mainService.farmChanged.value.farmId).subscribe(() => {
        location.reload();
      }, err => {
        this.loading = false;
      });
    }
  }

  resetFarm() {
    this.loading = true;
    this.farmService.resetDemoFarm(this.mainService.farmChanged.value.farmId).subscribe(() => {
      location.reload(); // since this is an internal functionality reload the whole page. (easier)
    }, err => {
      this.loading = false;
    });
  }
}

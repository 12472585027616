
<div class="modal-header">
  <h3 i18n="@@createnewfarm">CREATE NEW FARM</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<app-loading *ngIf="loading"></app-loading>

<form *ngIf="!loading && form" [formGroup]="form">

  <div class="modal-body">

    <app-form-input [showError]="false" i18n-error="@@farmnameisrequired" error="Farm name is required">
      <input type="text" (change)="showError = false;" i18n-placeholder="@@farmname" placeholder="Farm Name" formControlName="farmName"
             class="form-control">
    </app-form-input>

  </div>

  <!-- footer -->
  <div class="modal-footer">

    <ng-container *ngIf="!showError">
      <a href="javascript:void(0)" (click)="ngbActiveModal.dismiss()" class="btn btn-link" i18n="@@cancel">Cancel</a>
      <button type="button" (click)="createFarm()" class="btn btn-primary ml-1" i18n="@@createfarm">Create Farm</button>
    </ng-container>

    <ng-container *ngIf="showError">
      <div class="d-flex card-footer" *ngIf="showError">
        <div class="alert alert-danger footer-alert dark alert-dismissable mb-0 lh25" (click)="showError = false">
          <button type="button" data-dismiss="alert" aria-hidden="true" class="close posr" style="margin-top: 16px"></button>
          <i class="fa fa-exclamation-circle "></i>
          <strong i18n="@@error">Error: </strong>
          <span i18n="@@uniquefarmname">Farm must have a unique name</span>
        </div>
      </div>
    </ng-container>

  </div>

</form>



import {throwError as observableThrowError, Observable, throwError} from 'rxjs';
import { LocaleService } from '../../helpers/locale.service';
import { UserSubscription } from '../models/user-subscription.model';
import { AuthService } from '../../auth/auth.service';
import { BottomNotificationsService } from '../../components/bottom-notifications/bottom-notifications.service';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GlobalParameterService } from '../../helpers/global-parameter.service'
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ConvertToUTC } from '../../helpers/datatype.helper';

interface IEntity<T> {
    new(...args: any[]): T;
}

@Injectable()
export class ApiService {
    private static me: ApiService;
    public bottomNotificationsService: BottomNotificationsService;

    constructor(@Inject(forwardRef(() => AuthService)) private authService: AuthService, private http: HttpClient
        , @Inject(forwardRef(() => LocaleService)) private localeService: LocaleService
        , private route: ActivatedRoute, private globalParameterService: GlobalParameterService) { ApiService.me = this; }

    handleError(error: any): Observable<any> {
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (error.status === 503) {
            window.location.reload(true);
        }
        if (error.status === 401) {
            ApiService.me.authService.logout();
        } else if ((error.status === 403 || error.status === 500) && (ApiService.me.authService.trialDaysLeft > 0)) { // 400 should be handled by specific endpoint request
            ApiService.me.bottomNotificationsService.currentMessage.next({
                title: ApiService.me.localeService.constants.stringErrorOccurred,
                message: ApiService.me.localeService.constants.stringApiErrorReloadPage,
                type: 'warning'
            });
            return observableThrowError(errMsg);
        }
    }

    private checkIsLatestAppVersionAndCurrentFarmThenReturnResult(res: any) {
        if (res.headers && res.headers !== undefined) {
            let v = res.headers.get('app-version');
            if (v && v !== undefined) {
                if (this.globalParameterService.appVersion.length === 0) {
                    this.globalParameterService.appVersion = v;
                }
                if (this.globalParameterService.appVersion !== v) {
                    // reload app
                    window.location.reload(true);
                }
            }
            // protection here to ensure the result is for the current farm,
            // in case we've switched farms while the request was running
            let f = res.headers.get('farmId');
            if (f && f !== undefined && this.authService.currentUser && this.authService.currentUser !== undefined) {
                if (f.toString() !== this.authService.currentUser.currentFarm_FarmId.toString()) {
                    return null;
                }
            }
        }

        return res.body !== undefined ? res.body : null; // this is protection for server timeouts etc, so that we don't break subscriptions/observables
    }

    get(url: string, responseType = 'json'): Observable<any> {
        const responseTypeUntyped: any = responseType;
        let headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        if (this.authService.currentUser && this.authService.currentUser !== undefined && this.authService.currentUser.currentFarm_FarmId) {
            headers = headers.append('FarmId', this.authService.currentUser.currentFarm_FarmId.toString());
        }
        return this.http.get(environment.apiUrlRoot + url,
            { headers: headers, responseType: responseTypeUntyped, observe: 'response' })
            .pipe(map(r => { return this.checkIsLatestAppVersionAndCurrentFarmThenReturnResult(r); }))
            .pipe(catchError((err, caught) => {
                this.handleError(err);
                // return of(null);
                return throwError(err);
            }));
    }

    getTyped<T>(type:any, url:string, responseType='json'): Observable<any>{
        return this.get(url, responseType)
        .pipe(
            map(odata=> {
                return odata.value? odata.value : odata;
            }),
            map(response=>{
                return this._deserialize<T>(type, response);
            }));
    }
    
    /** @param {string} url Start without / or . */
    post(url: string, params: any, responseType = 'json'): Observable<any> {
        const responseTypeUntyped: any = responseType;
        let headers = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=utf-8')
            .append('Prefer', 'return=representation')
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        if (this.authService.currentUser && this.authService.currentUser !== undefined && this.authService.currentUser.currentFarm_FarmId) {
            headers = headers.append('FarmId', this.authService.currentUser.currentFarm_FarmId.toString());
        }
        return this.http.post(environment.apiUrlRoot + url
            , params, { headers: headers, responseType: responseTypeUntyped, observe: 'response' })
            .pipe(map(r => { return this.checkIsLatestAppVersionAndCurrentFarmThenReturnResult(r); }))
            .pipe(catchError((err, caught) => {
                this.handleError(err);
                // return of(null);
                return throwError(err);
            }));
    }
    /** @param {string} url Start without / or . */
    postType<T>(type:any, url: string, params: any, responseType:string = 'json'): Observable<any> {
        return this.post(url, params, responseType)
        .pipe(
            map(odata=> odata.value),
            map(response=>{
                return this._deserialize<T>(type, response);
            }));
    }

    patch(url: string, params: any, responseType = 'json'): Observable<any> {
        const responseTypeUntyped: any = responseType;
        let headers = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=utf-8')
            .append('X-HTTP-Method-Override', 'PATCH')
            .append('Prefer', 'return=representation')
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        if (this.authService.currentUser && this.authService.currentUser !== undefined && this.authService.currentUser.currentFarm_FarmId) {
            headers = headers.append('FarmId', this.authService.currentUser.currentFarm_FarmId.toString());
        }
        return this.http.post(environment.apiUrlRoot + url
            , params, { headers: headers, responseType: responseTypeUntyped, observe: 'response' })
            .pipe(map(r => { return this.checkIsLatestAppVersionAndCurrentFarmThenReturnResult(r); }))
            .pipe(catchError((err, caught) => {
                this.handleError(err);
                return of(null);
            }));
    }

    /** @param {string} url Start without / or . */
    delete(url: string, responseType = 'json'): Observable<any> {
        const responseTypeUntyped: any = responseType;
        let headers = new HttpHeaders()
            .append('Content-Type', 'application/json; charset=utf-8')
            .append('Prefer', 'return=representation') // must have to be able to recieve returned object
            // .append('X-HTTP-Method-Override', 'PATCH');
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        if (this.authService.currentUser && this.authService.currentUser !== undefined && this.authService.currentUser.currentFarm_FarmId) {
            headers = headers.append('FarmId', this.authService.currentUser.currentFarm_FarmId.toString());
        }
        return this.http.delete(environment.apiUrlRoot + url
            , { headers: headers, responseType: responseTypeUntyped, observe: 'response' })
            .pipe(map(r => { return this.checkIsLatestAppVersionAndCurrentFarmThenReturnResult(r); }))
            .pipe(catchError((err, caught) => {
                this.handleError(err);
                return of(null);
            }));
    }
    private _deserialize<T>(type: IEntity<T>, response:any){
        if (Array.isArray(response)) {
            let entities = [];
            response.forEach(item=>{
                let entity = this._convertSingle<T>(type,item);
                entities.push(entity);
            })
            
            return entities;
        }
        let entity = this._convertSingle<T>(type,response);
        return entity;
    }

    private _convertSingle<T>(type:IEntity<T>,item:any){

        let entity = this._instantiate(type); 
        Object.assign(entity, item);
        return entity;
    }

    private _instantiate<T>(type: IEntity<T>): T {
        return new type();
      }
}
export function ToAbsoluteDateString(date:Date):string{
    //extract the date from local and append 'Z'; to json converts to 
    return moment(date).toISOString()
}
export function ToAbsoluteDate(utcString:string):Date{
    return ConvertToUTC(utcString).set({hour:0, minute:0, second:0}).toDate()
}
import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: ' app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() local = false;
  @Input() relative = false;
  // @Input() loadingText: string = 'Loading...';

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.local) {
      (this.elementRef.nativeElement as HTMLElement).parentElement.style.minHeight = '100px';
    }
  }

  ngOnDestroy(): void {
    if (this.local) {
      (this.elementRef.nativeElement as HTMLElement).parentElement.style.minHeight = '0px';
    }
  }
}

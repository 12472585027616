
export interface Scripts {
    name: string;
    src: string;
    locale?: string;
}

export const ScriptStore: Scripts[] = [
    { name: 'alasql', src: 'https://cdnjs.cloudflare.com/ajax/libs/alasql/0.3.7/alasql.min.js' },
    { name: 'jszip', src: 'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.4/jszip.min.js' },
    { name: 'jspdf', src: 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.4/jspdf.min.js' },
    { name: 'jspdfAutotable', src: 'https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/2.3.0/jspdf.plugin.autotable.min.js' },
    { name: 'hcBoost', src: 'https://cdnjs.cloudflare.com/ajax/libs/highcharts/6.0.3/js/modules/boost.js' },
    { name: 'hcMore', src: 'https://cdnjs.cloudflare.com/ajax/libs/highcharts/6.0.3/highcharts-more.js' },
    { name: 'exporting', src: 'https://cdnjs.cloudflare.com/ajax/libs/highcharts/6.0.3/js/modules/exporting.js' },
    { name: 'offlineExporting', src: 'https://cdnjs.cloudflare.com/ajax/libs/highcharts/6.0.3/js/modules/offline-exporting.js' },
    { name: 'jsTimezone', src: 'https://cdnjs.cloudflare.com/ajax/libs/jstimezonedetect/1.0.6/jstz.min.js' },
    { name: 'momentJs', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment-with-locales.min.js' },

// we don't currently use individual locale files
    { name: 'momentJs-pt', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.20.1/locale/pt-br.js' },
    { name: 'momentJs-fr', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.20.1/locale/fr.js' },
    { name: 'momentJs-de', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.20.1/locale/de.js' },
    { name: 'momentJs-es', src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.20.1/locale/es.js' },

    { name: 'wootric', src: 'https://disutgh7q0ncc.cloudfront.net/beacon.js' },
    { name: 'jquerySignalR', src: 'https://cdnjs.cloudflare.com/ajax/libs/signalr.js/2.2.1/jquery.signalR.min.js' },
    { name: 'SignalRHubs', src: '../signalr/hubs' },
    { name: 'highcharts', src: 'https://cdnjs.cloudflare.com/ajax/libs/highcharts/6.0.3/highcharts.js' }
];

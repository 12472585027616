<!--<app-actions-modal [singleAnimal]="true" (actionComplete)="actionCompleted($event);"></app-actions-modal>-->

  <div class="modal-header noBottomBorder">
    <h3 i18n="@@animaldetails">Animal Details</h3>
    <div class="modal-button-group">
      <app-animal-actions [animal]="animal" (actionCompleted)="actionCompleted($event)" (statusChange)="status = $event" [parentView]="parentView"></app-animal-actions>
      <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
    </div>
  </div>

  <!-- page content, tabs -->

  <div class="modal-body p-0">

    <app-loading *ngIf="loading" [local]="true"></app-loading>

    <ngb-tabset *ngIf="!loading" [destroyOnHide]="tabDestroyOnHide" (tabChange)="tabChange($event)" #tabSet="ngbTabset" [activeId]="activeTabOnLoad">

      <!-- summary -->
      <ngb-tab i18n-title="@@summary" title="Summary" id="summary">
        <ng-template ngbTabContent>
          <div class="page-content">

            <!-- stats -->
            <app-animal-summary-stats [animal]="animal" [status]="status"></app-animal-summary-stats>

            <app-animal-summary [animal]="animal" [groups]="groups" [sessions]="sessions"></app-animal-summary>
          </div>
        </ng-template>
      </ngb-tab>

      <!-- animal list -->
      <ngb-tab i18n-title="@@sessionData" title="Session Data" id="sessionData">
        <ng-template ngbTabContent>
          <div class="page-content">

            <!-- stats -->
            <app-animal-summary-stats [animal]="animal" [status]="status"></app-animal-summary-stats>

            <app-animal-details [animal]="animal" [sessions]="sessions" [weightRecordIds]="weightRecordIds"></app-animal-details>
          </div>
        </ng-template>
      </ngb-tab>

    </ngb-tabset>
  </div>

<div class="row no no-gutters small-gutters mb-2" *ngFor="let item of selectList; let i = index">

  <div class="col-sm-6">
    <span *ngIf="item.value">{{item.value}}</span>
    <span *ngIf="!item.value" i18n="@@noValue">No Value</span>
    <div class="text-shady-lady">{{item.count}}
      <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{item.weight}}
      <ng-container i18n="abbreviation for average@@avg">Avg</ng-container>
    </div>
  </div>

  <div class="col-sm-6" *ngIf="groupsSelectList">

    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"      
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="item.selectedGroupId"
      (ngModelChange)="groupChanged(i, item, $event)">
    </ng-select>

  </div>

</div>

<h2 i18n="@@farms">Farms</h2>

<div class="card mb-5">

  <div class="table-responsive">
    <app-loading *ngIf="!farms" [local]="true"></app-loading>
    <table *ngIf="farms" class="table mb-0">
      <thead>
        <tr>
          <th i18n="@@farmname">Farm Name</th>
          <th i18n="@@createdby">Created By</th>
          <th i18n="@@createdin">Created on</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let farm of farms; let i = index">
          <tr [class.deleting]="farm.deleting" *ngIf="!farm.deleted">
            <td>{{farm.farmName}}</td>
            <td><span *ngIf="farm.creator">{{farm.creator.firstName}} {{farm.creator.lastName}} ({{farm.creator.email}})</span></td>
            <td>{{dayMonthYearString(farm.created)}}</td>
            <td class="table-actions">
              <a class="action-icon-holder danger" *ngIf="!farm.deleting && nonDeletedFarms && nonDeletedFarms.length > 1" (click)="deleteFarm(farm)">
                <i class="fas fa-trash"></i>
              </a>
              <a *ngIf="farm.deleting">
                <i class="fa fa-circle-o-notch fa-spin"></i>
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="card-footer text-center" *ngIf="farms && mainService.canCreateFarms">
    <button *ngIf="farms && mainService.canCreateFarms" class="btn btn-primary" (click)="createNewFarm()" i18n="@@createfarm">Create Farm</button>
  </div>

</div>

<ng-template #deleteConfirmationTitleTemplate>
  <ng-container i18n="@@deleteFarmCaps">DELETE FARM</ng-container>
</ng-template>

<ng-template #deleteConfirmationTemplate>
  <ng-container i18n="@@stringAreYouSureDeleteFarmX">Are you sure you would like to delete <strong>{{farmToDelete.farmName}}</strong>?</ng-container>
</ng-template>

import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { WeightPlanPoint } from '../../../models/weight-plan.model';
import { Animal } from '../../../models';
import { Group } from '../../../models/group.model';
import { Rounder } from '../../../../helpers/rounder.helper';
import { LocaleService } from '../../../../helpers/locale.service';
import {PermissionsService} from '../../../../auth/permissions.service';
import {IntegrationService} from '../../../api/integration.service';

@Component({
    selector: 'app-animal-weight-gain',
    templateUrl: './animal-weight-gain.component.html',
    styleUrls: ['./animal-weight-gain.component.scss']
})
export class AnimalWeightGainComponent implements OnInit, OnChanges {

    @Input()
    animal: Animal;
    @Input()
    groups: Array<Group>;

    options: any;
    chartName: string;

    isPplFarmWithIntegration = false;


    constructor(private localeService: LocaleService, private permissionsService: PermissionsService, private integrationService: IntegrationService) { }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes['animal'] && changes['animal'].previousValue)
            || (changes['groups'] && changes['groups'].previousValue)) {
            this.redrawGraph();
        }
    }

    ngOnInit() {
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }
        this.redrawGraph();
    }

    redrawGraph() {
        if (this.groups === null || !this.animal.weightRecords) {
            this.options = {
                series: []
            };
            return;
        }

        this.chartName = `${this.animal.displayName} - ` + this.localeService.constants.stringWeightGainGraph;

        let chartPoints = this.animal.weightRecords !== null ? this.animal.weightRecords.filter(wr => wr.weight).slice() : [];
        chartPoints.sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime());

        let points = chartPoints.map((point) => {
            return {
                x: point.timeStamp.setUTCHours(0,0,0,0),
                y: point.weight
            };
        });

        let seriesData = this.GetSeriesData(points);

        let xAxis: any = {
            type: 'datetime',
            tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
            dateTimeLabelFormats: {
                month: '%b %Y'
            },
            labels: {
                style: {
                    fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                    fontSize: '12px'
                }
            },
        },

            series = seriesData;

        let cName = this.chartName;
        this.options = {
            title: {
                text: null
            },

            subtitle: {
                text: null
            },
            xAxis: xAxis,
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                        fontSize: '12px'
                    }
                },
                lineWidth: 1,
                min: 0
            },

            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                text: this.localeService.constants.stringPrintChart,
                                onclick: function () {
                                    this.print();
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPDF,
                                onclick: function () {
                                    this.exportChart({
                                        type: 'application/pdf',
                                        filename: cName
                                    });
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPNG,
                                onclick: function () {
                                    this.exportChart({
                                        filename: cName
                                    });
                                }
                            }
                        ]
                    }
                },
            },
            series: series
        };
    }

    GetSeriesData(points: any) {
        let initData = {
            name: this.localeService.constants.stringWeight,
            id: 'weight',
            allowPointSelect: true,
            data: points,
            marker: { enabled: !(this.isPplFarmWithIntegration && points.length>1), lineWidth: 2 },
            zIndex: 3,
            type: 'spline'
        };
        let res = Array<any>();
        res.push(initData);

        if (this.groups !== null && this.groups.length > 0) {
            res = this.GetGroupsTargetWeightData(initData);
        }

        return res;
    }

    GetGroupsTargetWeightData(initData: any) {
        let res = Array<any>();
        res.push(initData);
        let i = 5;
        for (let grp of this.groups) {
            let theData = (<Array<WeightPlanPoint>>JSON.parse(grp.targetWeightPlan.weightPlanPointsJson))
                .map(e => { return { x: new Date(e.date).getTime(), y: e.weight }; });
            let theObj = {
                name: grp.displayName + ' ' + this.localeService.constants.stringTarget,
                id: grp.displayName,
                allowPointSelect: true,
                data: theData,
                color: `#69B342`,
                marker: { enabled: true, lineWidth: 1, lineColor: `#69B342`, fillColor: `#69B342`, symbol: 'diamond' },
                zIndex: i,
                type: 'spline'
            }
            i++;
            res.push(theObj);
        }
        return res;
    }

}

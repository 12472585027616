import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';

import {IntegrationService} from '../../../../api/integration.service';
import {MainService} from '../../../../main.service';
import {Integration} from '../../../../models/integration';
import {BottomNotificationsService} from '../../../../../components/bottom-notifications/bottom-notifications.service';
import {IntercomService} from '../../../../intercom.service';
import {AuthService} from '../../../../../auth/auth.service';
import {SessionService} from '../../../../api/session.service';
import {GroupsService} from '../../../../services/groups.service';
import {EventAuditService} from '../../../../api/event-audit.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-ppl-integration',
  templateUrl: './new-ppl-integration.component.html',
  styleUrls: ['./new-ppl-integration.component.scss']
})
export class NewPplIntegrationComponent implements OnInit {
  @Input() integration: Integration = null;
  @Output() integrationChanged = new EventEmitter<Integration>();

  window = window;
  form: FormGroup;
  loading: boolean;

  constructor(
    private integrationService: IntegrationService,
    private mainService: MainService,
    private eventAuditService: EventAuditService,
    private bottomNotificationsService: BottomNotificationsService,
    private intercomService: IntercomService,
    private authService: AuthService,
    public ngbActiveModal: NgbActiveModal,
    private sessionService: SessionService,
    private groupsService: GroupsService) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      login: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  get login(): AbstractControl {
    return this.form && this.form.get('login');
  }

  get password(): AbstractControl {
    return this.form && this.form.get('password');
  }

  pplFinished(integration: Integration) { // called after ppl connected or updated
    this.loading = false;
    setTimeout(() => {
      this.integrationChanged.emit(integration);
      // this.sessionService.getLatestSessions(); // get new sessions
      // this.groupsService.getLatestGroups(); // get new groups
      // this.eventAuditService.getLatestEvents(this.mainService.farmChanged.value.farmId);
    });
  }

  sendPpl(event: Event) {
    event.preventDefault();

    this.loading = true;
    if (this.mainService.farmChanged.value) {
      if (this.integration) { // update existing PPL connection
        this.integrationService.updateIntegration(this.integration.integrationId, {
          loginInfo: JSON.stringify(this.form.value)
        }).subscribe(integration => {
          if (integration != null) {
            this.integration.authenticationFailed = integration.authenticationFailed;
            this.bottomNotificationsService.currentMessage.next({
              title: 'Success',
              message: 'Precision Pastoral Remote WOW connected'
            });
            this.pplFinished(integration);
          } else {
            this.bottomNotificationsService.currentMessage.next({
              title: 'Error',
              type: 'warning',
              message: 'Unable to connect to Precision Pastoral, please try again later.'
            });
            this.loading = false;
          }
        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Error',
            type: 'warning',
            message: 'Unable to connect to Precision Pastoral, please try again later'
          });
          this.loading = false;
        });
      } else { // Create new ppl integration
        this.integrationService.saveIntegration({
          integratedDevices: [],
          integrationName: 'PPL',
          farm_FarmId: this.mainService.farmChanged.value.farmId,
          loginInfo: JSON.stringify(this.form.value)
        }).subscribe(integration => {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Success',
            message: 'Precision Pastoral Remote WOW connected'
          });

          this.mainService.farmChanged.value.integrationName = 'PPL';

          // send Intercom event
          this.intercomService.logEvent('Set-up-PPL-integration', '/main/settings/', {
            user_id: this.authService.currentUser.userId,
            farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
            integrationId: integration.integrationId
          });
          this.pplFinished(integration);
        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Error',
            type: 'warning',
            message: 'Unable to connect to Precision Pastoral, please try again later'
          });
          this.loading = false;
        });
      }
    }
  }
}

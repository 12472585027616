<!--<p-dialog *ngIf="this.actionsModalService.action && this.actionsModalService.visible">-->
<div class="modal-header">
  <h3>{{ actionsModalService.action.groupId ? localeService.constants.stringChangeGroup : localeService.constants.stringAssignToGroup }}</h3>
  <a (click)="ngbActiveModal.dismiss(); clearData();" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body">

  <div *ngIf="!singleAnimal">

    <!-- all animals -->
    <div class="custom-control custom-radio">
      <input id="allAnimals" name="actions-selection-radio" [value]="false" type="radio" [(ngModel)]="showSplitTab" class="custom-control-input">
      <label class="custom-control-label" for="allAnimals">
        <span i18n="@@allAnimalsToTheSameGroup">All animals to the same group</span>
      </label>
    </div>

    <!-- some animals -->
    <div class="custom-control custom-radio">
      <input id="someAnimals" name="actions-selection-radio" [value]="true" type="radio" [(ngModel)]="showSplitTab" class="custom-control-input">
      <label class="custom-control-label" for="someAnimals">
        <span i18n="@@splitAnimalsIntoDifferentGroups">Split animals into different groups</span>
      </label>
    </div>
  </div>

  <!-- splitting animals between groups -->
  <div class="mt-4" *ngIf="showSplitTab">
    <app-actions-modal-splitter (selectedFilterType)="updateFilterType($event)" class="active"></app-actions-modal-splitter>
  </div>

  <!-- previous weights section -->
  <h3 class="mt-4" *ngIf="actionsModalService.action?.groupId !== null && !singleAnimal" i18n="@@previousweights">Previous Weights</h3>
  <div *ngIf="actionsModalService.action?.groupId !== null" class="form-group row no-gutters small-gutters">
    <label *ngIf="!singleAnimal" class="col-6 col-form-label" i18n="@@WeightsBeforeThisSession">
      Weights before this session
    </label>
    <label *ngIf="singleAnimal" class="col-6 col-form-label" i18n="@@WeightsBeforeTheMostRecentSession">
      Weights before the most recent session
    </label>

    <div class="col-6">
      <div class="form-group">
        <ng-select
          class="form-control form-control-ng-select"
          [items]="dropdownItems"
          bindLabel="label"
          bindValue="value"
          [searchable]="false"
          dropdownPosition="bottom"
          [clearable]="false"
          [(ngModel)]="actionsModalService.movePriorWeights"
          (ngModelChange)="previousWeightsChanged($event)">
        </ng-select>
      </div>
    </div>

  </div>

  <!-- all animals to same group -->
  <div class="mt-4" *ngIf="!showSplitTab">
    <app-actions-modal-group-selection [singleAnimal]="singleAnimal" (closeModal)="closeOrDismissModal($event)" (newGroupSelected)="newGroupSelected($event)"></app-actions-modal-group-selection>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-link" (click)="ngbActiveModal.dismiss(); clearData();" i18n="@@cancel">Cancel</button>
  <button class="btn btn-primary ml-1" [disabled]="lockDoneButton" (click)="save()" i18n="@@done">Done</button>
</div>


<ng-template #errorTemplateTitle>
  <div><strong i18n="@@error">Error</strong></div>
</ng-template>

<ng-template #errorTemplateMessageAnimalCount>
  <ng-container i18n="@@MustHaveAtLeast1AnimalAssignedToAGroup">Must have at least 1 animal assigned to a group</ng-container>
</ng-template>

<ng-template #errorTemplateMessageGroup>
  <ng-container i18n="@@PleaseSelectValidGroup">Please select valid group</ng-container>
</ng-template>

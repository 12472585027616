
import { Renderer2, RendererStyleFlags2 } from '@angular/core';
import { RowHeader } from '../main/models/report-header.model';
declare var $: any;

export class FloatingHeaderHelper {

  public static fixHeaderWidths(renderer: Renderer2) {
    let ths = document.getElementsByTagName('th');
    let thead = document.getElementsByTagName('thead')[0];
    let newWidth = thead.offsetWidth / (ths.length + 1);
    for (let i = 0; i < ths.length; i++) {
      let th = ths[i];
      renderer.setStyle(th, 'width', newWidth + 'px');
    }
  }

  public static reformatHeaderSpaces(headers: Array<RowHeader>) {
    headers.forEach(h => {
      if (typeof h.header.toString().trim === 'function') {
        let hs = h.header.toString().trim().split(' ');
        if (hs.length > 2) {
          let sc = hs.length - 1;
          let s = Math.ceil(sc / 2);
          let newH = hs.join('&nbsp;');
          newH = newH.replace(RegExp('^(?:.*?&nbsp;){' + s + '}'), function(x) { return x.replace(RegExp('&nbsp;$'), ' ')});
          h.header = newH;
        }
      }
    });
    return headers;
  }

  public static IsDisplayingFloatingHeader(): boolean {
    let navbar = document.getElementsByClassName('navbar-fixed-top')[0],
      tcaption = document.getElementsByClassName('ui-table-caption')[0];
    let tcpi = tcaption !== null && tcaption !== undefined ? tcaption.getBoundingClientRect() : null,
      nbpi = navbar !== null && navbar !== undefined ? navbar.getBoundingClientRect() : null;
    if (tcpi === null || nbpi === null) {
      return false;
    }
    return tcpi.bottom <= nbpi.height;
  }

  public static CleanUpTable(renderer: Renderer2) {
    let dth = document.getElementsByTagName('thead');
    if (dth !== null && dth !== undefined && dth.length > 0 && dth[0] !== null && dth[0] !== undefined) {
      dth[0].classList.remove('header_scrolled');
    }
    FloatingHeaderHelper.removeFakeRows(renderer);
  }

  public static floatHeaderOnScroll(scrollSet: boolean, renderer: Renderer2) {
      let navbar = document.getElementsByClassName('navbar-fixed-top')[0],
        thead = document.getElementsByClassName('ui-table-thead')[0],
        tbody = document.getElementsByClassName('ui-table-tbody')[0],
        tcaption = document.getElementsByClassName('ui-table-caption')[0];
      if (navbar && thead && tcaption) {
          let isTooHigh = this.IsDisplayingFloatingHeader();
          if (isTooHigh) {
            renderer.addClass(thead, 'header_scrolled');
          } else {
            renderer.removeClass(thead, 'header_scrolled');
          }
        if (isTooHigh && !scrollSet) {
          let dup1 = thead.children[0].cloneNode(true);
          let row1 = tbody.insertBefore(dup1, tbody.children[0]);
          renderer.addClass(row1, 'ui-widget-content');
          renderer.addClass(row1, 'fakeTableRow');
          scrollSet = true;
          this.updateColumnWidths(renderer); // only calculate on changes, not every scroll movement
        } else if (!isTooHigh && scrollSet) {
          this.removeFakeRows(renderer);
          scrollSet = false;
        }
      }
      return scrollSet;
  }

  public static removeFakeRows(renderer: Renderer2) {
    this.updateColumnWidths(renderer); // only calculate on changes, not every scroll movement
    let fakeRows = document.getElementsByClassName('fakeTableRow');
    for (let i = 0; i < fakeRows.length; i++) {
      fakeRows[i].remove();
    }
  }

  public static updateColumnWidths(renderer: Renderer2) {
    setTimeout(
      <TimerHandler><unknown>this.reallyUpdateColumnWidths(renderer)
      , 50
    );
  }

  private static reallyUpdateColumnWidths(renderer: Renderer2) {
    let tbody = document.getElementsByClassName('ui-table-tbody')[0],
      thead = document.getElementsByClassName('ui-table-thead')[0];
    if (tbody !== null && tbody !== undefined
          && thead !== null && thead !== undefined) {

      let bodyWidth = tbody.getBoundingClientRect().width;
      let headWidth = thead.getBoundingClientRect().width;
      let maxWidth = Math.max(headWidth, bodyWidth);
      renderer.setStyle(thead, 'width', maxWidth + 'px');
      renderer.setStyle(tbody, 'width', maxWidth + 'px');

      let ua = window.navigator.userAgent;
      let msie = ua.indexOf('MSIE ');
      let trident = ua.indexOf('Trident/');
      let edge = ua.indexOf('Edge/');
      if (msie > 0 || trident > 0 || edge > 0) { // If Internet Explorer, we need to check for extended menu
        let dtd = document.getElementsByClassName('ui-table-wrapper')[0];
        if (dtd !== null && dtd !== undefined) {
          let dtdpi = dtd.getBoundingClientRect();
          renderer.setStyle(thead, 'left', dtdpi.left + 'px');
        }
      }

      let isTooHigh = this.IsDisplayingFloatingHeader();
      let ths = document.getElementsByTagName('th');
      let tds = document.getElementsByTagName('td');
      let halfWay = isTooHigh ? ths.length / 2 : ths.length;
      for (let i = 0; i < halfWay; i++) {
        let th1 = ths[i];
        let theTd = tds[i];
        if (th1 !== null && th1 !== undefined
                && theTd !== null && theTd !== undefined) {
          let w1 = th1.getBoundingClientRect().width;
          let tdw = theTd.getBoundingClientRect().width;
          let maxW = Math.max(tdw, w1);
          renderer.setStyle(th1, 'width', maxW + 'px');
        }
      }

      this.clipFloatingHeader($('.ui-table-wrapper').scrollLeft(), renderer);
    }
  }

  public static clipFloatingHeader(scrollLeft, renderer: Renderer2, extraMargin = 0) {
    let thead = document.getElementsByClassName('ui-table-thead')[0],
      twrapper = document.getElementsByClassName('ui-table-wrapper')[0];
    if (twrapper !== null && twrapper !== undefined
          && thead !== null && thead !== undefined) {
      let originalLeftCoord = twrapper.getBoundingClientRect().left;
      let dtpi = thead.getBoundingClientRect();
      let wrapWidth = twrapper.getBoundingClientRect().width;
      let currLeftCoord = dtpi.left;
      let dtheadHeight = dtpi.height;
      // need to hide dthead between currLeftCoord and originalLeftCoord, and from (originalLeftCoord + headerWidth) onwards
      setTimeout(() => {
        renderer.setStyle(thead, 'left', (originalLeftCoord - scrollLeft + extraMargin) + 'px');
        let theRect = 'rect(0px,' + ((originalLeftCoord - currLeftCoord) + wrapWidth + extraMargin) + 'px,' + dtheadHeight + 'px,' + (originalLeftCoord - currLeftCoord) + 'px)';
        renderer.setStyle(thead, 'clip', theRect);
      });
    }
  }
}

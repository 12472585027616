<div class="card card-widget">

  <!-- if education -->
  <div class="grey-out" *ngIf="sessionService.sessions.value && dashboardEducationService.sessionsE"></div>

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0">
      <span *ngIf="session"><h3 class="m-0" *ngIf="session">{{session.sessionName}} ({{dayMonthYearString(session.sessionStartDate)}})</h3></span>
      <span *ngIf="!session"><h3 class="m-0" i18n="@@sessions">Sessions</h3></span>
    </h3>
    <div class="right">
      <a routerLink="/main/sessions/all" i18n="@@allsessions">All Sessions</a>
    </div>
  </div>

  <!-- widget body/content -->
  <div class="card-body">

    <!-- widget loading -->
    <app-loading *ngIf="!sessions || loading" [local]="true"></app-loading>

    <!-- no sessions -->
    <div *ngIf="sessions && sessions.length === 0" class="text-center">
      <h3>
        <a routerLink="/main/transfer">
          <ng-container i18n="@@nosessionsgotorecordpage">No sessions have been imported. Go to the record page to import a new weight session</ng-container>.
        </a>
      </h3>
    </div>

    <!-- session selection -->
    <div class="row mb-3" *ngIf="session && sessions && sessions.length > 0">
      <div class="col-md-6">
        <ng-select
          class="form-control form-control-ng-select"
          [items]="sessionsSelectList"
          [placeholder]="sessionLabel"
          (search)="sessionsFiltered($event.term)"
          (close)="sessionsFiltered('')"
          [clearable]="false"
          dropdownPosition="bottom"
          (change)="sessionChanged($event.value)">

        </ng-select>
      </div>
    </div>

    <!-- widget data -->
    <div *ngIf="session && sessions && sessions.length > 0" class="dashboard-chart" [appHighcharts]="options"></div>

  </div>
</div>

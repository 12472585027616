import {Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone, Renderer2, ViewChild, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {combineLatest, Subscription} from 'rxjs';

import {Rounder} from '../../../helpers/rounder.helper';
import {AnimalService} from '../../api/animal.service';
import {ReportsService} from '../../reports/reports.service';
import {GroupsService} from '../../services/groups.service';
import {RowHeader} from '../../models/report-header.model';
import {WeightRecordService} from '../../api';
import {SessionService} from '../../api/session.service';
import {MainService} from '../../main.service';
import {Session} from '../../models/session.model';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {FloatingHeaderHelper} from '../../../helpers/floating-header.helper';
import {Animal, SessionSummary, WeightRecord} from '../../models';
import {LocaleService} from '../../../helpers/locale.service';
import {DetailsSearchParamType} from '../../models/details-search-params';
import {ScriptService} from '../../script.service';
import {PermissionsService} from '../../../auth/permissions.service';
import {AuthService} from '../../../auth/auth.service';
import {ApiService} from '../../api/api.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {ActionsSoldDeadService} from '../../actions/actions-sold-dead-modal/actions-sold-dead.service';
import {AnimalStatusEnum, AnimalStatus} from '../../models';
import {SessionAction} from '../../models/session-action';
import {ConvertToUTC, DatatypeHelper} from '../../../helpers/datatype.helper';
import {AnimalInfoComponent, AnimalInfoParentViewEnum} from '../../animals/animal-info/animal-info.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import {ActionsAssignFilteredListModalService} from '../../actions/actions-assign-filtered-list-modal/actions-assign-filtered-list-modal.service';

declare var $: any;

@Component({
  selector: 'app-sessions-details',
  templateUrl: './sessions-details.component.html',
  styleUrls: ['./sessions-details.component.scss']
})
export class SessionsDetailsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() sessionSummary: SessionSummary;

  @ViewChild('floatHeaders', { static: false }) floatHeaders: any;

  filteredSession: Session = null; // Toshow to summary data when animals are filtered
  rows: Array<any>;
  fullDataSet: Array<any>; // this one is for filtering options. Fix for 2076
  headers: Array<RowHeader>;
  curHeaders: Array<RowHeader>;
  filterOptions: Array<any> = [];
  private allWrc: Array<WeightRecord>;
  private subs: Array<Subscription> = [];
  // the following subscriptions are not in the array as we need to kill and reinstantiate at times

  private weightsAndAnimalsSub: Subscription;

  scrollSet = false;

  filteredAnimals: Array<any> = [];

  sortDetails: any = null;
  sortField: string = null;
  sortOrder: any = null;

  statuses: Array<AnimalStatus> = null;
  statusChangeAnimalIds: Array<number> = null;
  statusChangeValue: AnimalStatusEnum = null;
  initialLoad = false;
  filterProcessing = false;
  showAnimalStatus = false;
  showGroupName = false;
  cachedHeaders: Array<RowHeader>;

  trigger = false;
  animalStatusSub: Subscription;
  isMobile: boolean;

  constructor(private sessionService: SessionService, private authService: AuthService
    , private mainService: MainService, private route: ActivatedRoute, private router: Router
    , public reportsService: ReportsService, private weightRecordService: WeightRecordService, private animalService: AnimalService
    , private bottomNotificationsService: BottomNotificationsService, public apiService: ApiService, private groupsService: GroupsService
    , title: Title, private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, private scriptService: ScriptService
    , public globalParameterService: GlobalParameterService, private localeService: LocaleService, private renderer: Renderer2
    , private actionsSoldDeadService: ActionsSoldDeadService, public permissionsService: PermissionsService,
              private ngbModal: NgbModal, private location: Location,
              private actionsAssignFilteredListModalService: ActionsAssignFilteredListModalService) {
    title.setTitle(`${this.localeService.constants.stringSessionDetails} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.scriptService.load('alasql', 'jszip', 'jspdf').then(data => {
      this.scriptService.load('jspdfAutotable').then(laterData => {
      });
    });
  }

  ngOnInit(): void {
    this.isMobile = this.mainService.isMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.sessionSummary) {
      return;
    }

    this.initialLoad = true;

    // get statuses and weights
    this.animalStatusSub = combineLatest([
      this.animalService.getAnimalStatusesForSession(this.sessionSummary.sessionId),
      this.groupsService.groups
    ])
      .subscribe(as => {
        const animalStatuses = as[0];
        if (animalStatuses && as[1]) {
          this.statuses = [...animalStatuses];
          this.weightsAndAnimalsSub = this.weightRecordService.GetWeightsAndAnimalsForSessionId(this.sessionSummary.sessionId).subscribe(wrs => {
            this.allWrc = wrs;
            this.processData();
            this.animalStatusSub.unsubscribe();
          });
        }
      });

    // on filter change process shown data
    this.subs.push(this.globalParameterService.detailsSearchParams.subscribe(search => {
      if (this.allWrc) {
        this.processData();
      }
    }));

    setTimeout(() => {
      $(window).on('scroll');
      $(window).scroll(event => {
        this.scrollSet = FloatingHeaderHelper.floatHeaderOnScroll(this.scrollSet, this.renderer);
        FloatingHeaderHelper.updateColumnWidths(this.renderer);
      });
      FloatingHeaderHelper.CleanUpTable(this.renderer);
    });

    this.mainService.actionDate = this.mainService.setDate(this.sessionSummary.sessionStartDate);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());

    if (this.weightsAndAnimalsSub) {
      this.weightsAndAnimalsSub.unsubscribe();
    }

    this.globalParameterService.detailsSearchParams.next({});
    setTimeout(() => {
      $(window).off('scroll');
    });
  }

  get isPplSession() : boolean{
    return this.sessionSummary.importSource === 'PPL';
  }

  markAsCurrent(rows: Array<any>) {
    this.updateStatus(rows, AnimalStatusEnum.none);
  }

  markAsSold(rows: Array<any>) {
    this.updateStatus(rows, AnimalStatusEnum.sold);
  }

  markAsDied(rows: Array<any>) {
    this.updateStatus(rows, AnimalStatusEnum.dead);
  }

  updateStatus(rows: Array<any>, status: AnimalStatusEnum) {
    let animalIds = rows.filter(r => r.animalId).map(r => r.animalId);
    this.statusChangeAnimalIds = animalIds;
    this.statusChangeValue = status;
    this.showAnimalStatus = this.curHeaders.findIndex(ch => ch.field === 'animalStatus') >= 0;
    this.showGroupName = this.curHeaders.findIndex(ch => ch.field === 'groupName') >= 0;
    this.cachedHeaders = JSON.parse(JSON.stringify(this.curHeaders)); // get a non-referential copy of list
    let wrs = this.allWrc.filter(w => animalIds.findIndex(a => a === w.animal_AnimalId) >= 0).slice();
    let statusString = status ? status.toString() : 'current';
    let action = <SessionAction>{
      animalCount: wrs.length
      , ided: true, weightRecords: wrs
      , sessionId: this.sessionSummary.sessionId
      , sessionStartDate: this.sessionSummary.sessionStartDate
      , v_ActionId: statusString
    };
    let filtered = wrs.length < this.fullDataSet.length; // currently not using this, so all modals will just be confirmations
    this.trigger = !this.trigger;
    this.mainService.actionDate = this.mainService.setDate(this.sessionSummary.sessionStartDate);
    this.actionsSoldDeadService.init(action, status, true).subscribe(vActionId => {
      this.soldDeadModalResult(vActionId);
    });
  }

  soldDeadModalResult(vActionId: any) {
    if (vActionId) {
      this.statusChangeAnimalIds.forEach(aid => {
        const animal = this.rows.find(r => r['AnimalId'] && r['AnimalId'] === +aid);

        if (animal) {
          const status = this.statuses.find(s => s.animalId === animal['AnimalId']);

          animal['animalStatus'] = this.statusChangeValue === AnimalStatusEnum.sold
            ? this.localeService.constants.stringSold
            : this.statusChangeValue === AnimalStatusEnum.dead
              ? this.localeService.constants.stringDied
              : this.localeService.constants.stringCurrent;
          if (this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead || this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold) {
            let theDate = this.actionsSoldDeadService.action.sessionStartDate ? this.actionsSoldDeadService.action.sessionStartDate : new Date();
            animal['animalStatusDate'] = DatatypeHelper.tryFormatDateWithMoment(theDate, 'D MMM YYYY');
          } else {
            animal['animalStatusDate'] = animal['firstSeen'];
          }

          if (status) {
            status.statusCode = this.statusChangeValue;
            status.statusDate = animal['animalStatusDate'];
          }
        }
      });
    }
    this.statusChangeAnimalIds = null;
    this.statusChangeValue = null;
    this.mainService.actionDate = this.mainService.setDate();
  }

  filtered(event) {
    if (event.filteredValue.length < this.rows.length || Object.keys(this.globalParameterService.detailsSearchParams.value).length > 0) {
      this.filteredSession = new Session();
      let totalCount = 0, totalWeight = 0, totalCountWithWeight = 0;
      event.filteredValue.forEach(el => {
        totalCount++;
        if (el.Weight) {
          totalCountWithWeight++;
          totalWeight += el.Weight;
          if (el.Weight > this.filteredSession.maxWeight) {
            this.filteredSession.maxWeight = el.Weight;
          }
          if (el.Weight < this.filteredSession.minWeight || this.filteredSession.minWeight === 0) {
            this.filteredSession.minWeight = el.Weight;
          }
        }
      });
      this.filteredSession.averageWeight = totalCountWithWeight > 0 ? totalWeight / totalCountWithWeight : 0;
      this.filteredSession.animalCount = totalCount;

      this.filteredAnimals = event.filteredValue;
    } else {
      this.filteredSession = null;
      this.filteredAnimals = this.rows;
    }
    if (this.floatHeaders) {
      this.floatHeaders.checkScrollbarVisibility();
    }
  }

  columnsReordered(columns) {
    this.curHeaders = columns.map(e => this.headers.find(h => h.field === e.field));
    this.saveOrder(this.curHeaders);
    if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
      window.scrollTo(0, 0);
    }
  }

  saveOrder(headers, scrollRight = false) {
    let sendingHeaders = JSON.parse(JSON.stringify(headers)); // get a non-referential copy of list
    if (sendingHeaders.findIndex(ch => ch.field === 'animalStatus') >= 0) {
      sendingHeaders.splice(sendingHeaders.findIndex(ch => ch.field === 'animalStatus'), 1); // don't include animalStatus, animalStatusDate or groupName in saved headers in DB
    }
    if (sendingHeaders.findIndex(ch => ch.field === 'animalStatusDate') >= 0) {
      sendingHeaders.splice(sendingHeaders.findIndex(ch => ch.field === 'animalStatusDate'), 1); // don't include animalStatus, animalStatusDate or groupName in saved headers in DB
    }
    if (sendingHeaders.findIndex(ch => ch.field === 'groupName') >= 0) {
      sendingHeaders.splice(sendingHeaders.findIndex(ch => ch.field === 'groupName'), 1); // don't include animalStatus, animalStatusDate or groupName in saved headers in DB
    }

    this.resetHeaderState(sendingHeaders, x=>this.isPplSession && x.field === 'timeStamp');

    let fieldDisplayOrder = JSON.stringify(headers);
    let sendingFieldDisplayOrder = JSON.stringify(sendingHeaders);
    let currentRoleId = this.authService.currentUser.userFarms.find(uf => uf.farmId === this.authService.currentUser.currentFarm_FarmId).farmRoleId;
    if (this.authService.currentUser.userType.toLowerCase() !== 'superuser' && currentRoleId !== 3) {
      this.sessionService.SaveColumnOrder(this.sessionSummary.sessionId, sendingFieldDisplayOrder)
        .subscribe(() => {
          this.sessionSummary.fieldDisplayOrder = fieldDisplayOrder;
        });
    } else {
      // we don't want SuperUser or GroupViewer to change the columns displayed or their order for the Session in the DB,
      // but we do want them to be able to change it for themselves during current viewing
      this.sessionSummary.fieldDisplayOrder = fieldDisplayOrder;
    }
    setTimeout(() => {
      this.curHeaders = headers.slice();
      FloatingHeaderHelper.fixHeaderWidths(this.renderer);
    });
  }

  private resetHeaderState(sendingHeaders: any, predicate:(header:RowHeader)=>boolean) {
    let index = sendingHeaders.findIndex(predicate)
    if (index >= 0) {
      sendingHeaders.splice(index,1);
    }
  }

  getDisplayName() {
    let ssd = new Date(this.sessionSummary.sessionStartDate), formattedStartDate = `${ssd.getUTCDate()} ${this.localeService.month[(ssd.getUTCMonth())].month} ${ssd.getUTCFullYear()}`;
    return `${this.sessionSummary.sessionName} (${formattedStartDate})`;
  }

  processData() {
    this.rows = [];
    this.fullDataSet = [];

    if (this.initialLoad && this.sessionSummary.fieldDisplayOrder) {
      let headersCopy = JSON.parse(this.sessionSummary.fieldDisplayOrder); // get a non-referential copy of list
      if (headersCopy.findIndex(ch => ch.field === 'animalStatus') >= 0) {
        headersCopy.splice(headersCopy.findIndex(ch => ch.field === 'animalStatus'), 1); // don't include animalStatus, animalStatusDate or groupName
      }
      if (headersCopy.findIndex(ch => ch.field === 'animalStatusDate') >= 0) {
        headersCopy.splice(headersCopy.findIndex(ch => ch.field === 'animalStatusDate'), 1); // don't include animalStatus, animalStatusDate or groupName
      }
      if (headersCopy.findIndex(ch => ch.field === 'groupName') >= 0) {
        headersCopy.splice(headersCopy.findIndex(ch => ch.field === 'groupName'), 1); // don't include animalStatus, animalStatusDate or groupName
      }
      let fieldDisplayOrder = JSON.stringify(headersCopy);
      this.sessionSummary.fieldDisplayOrder = fieldDisplayOrder;
    } else if (this.cachedHeaders) {
      let fieldDisplayOrder = JSON.stringify(this.cachedHeaders);
      this.sessionSummary.fieldDisplayOrder = fieldDisplayOrder;
      this.cachedHeaders = null;
    }

    this.initialLoad = false;

    let data: Array<WeightRecord>;
    let primaryIdName = {};
    const search = this.globalParameterService.detailsSearchParams.value,
      params = Object.keys(search);
    this.filteredSession = null;
    if (params && params.length > 0) {
      this.filteredSession = new Session();
    }
    data = this.allWrc.slice();

    if (!data) { return; }
    this.headers = [{ header: this.localeService.constants.stringWeight, field: 'Weight', type: DetailsSearchParamType.number }
      , { header: this.localeService.constants.stringStatus, field: 'animalStatus', type: DetailsSearchParamType.string, visible: false }
      , { header: this.localeService.constants.stringStatusDate, field: 'animalStatusDate', type: DetailsSearchParamType.string, visible: false }
      , { header: this.localeService.constants.stringGroup, field: 'groupName', type: DetailsSearchParamType.string, visible: false }];
    let showWeight = false, totalWeight = 0, weightCount = 0;
    for (let i = 0, l = data.length; i < l; i++) {
      let isFilteredOut = false, row = {};

      if(this.isPplSession){
        row['timeStamp'] = ConvertToUTC(data[i].timeStamp).format('D MMM YYYY');
      }

      row['weightRecordId'] = data[i].weightRecordId;
      if (data[i].animal && data[i].animal.animalId !== null) {
        row['animalId'] = data[i].animal.animalId;
      }

      if (data[i].weight !== null) {
        row['Weight'] = Rounder.round(data[i].weight);
        showWeight = true;
      }

      if (data[i].groups_GroupId) {
        let grp = this.groupsService.groups.value.find(g => g.groupId === data[i].groups_GroupId);
        if (grp !== null && grp !== undefined) {
          row['groupName'] = grp.displayName;
          row['groups_GroupId'] = grp.groupId;
        }
      }

      if (this.statuses) {
        let status = this.localeService.constants.stringCurrent;
        if (this.statuses.findIndex(s => s.animalId === data[i].animal_AnimalId && s.statusCode && s.statusCode === AnimalStatusEnum.sold) >= 0) {
          status = this.localeService.constants.stringSold;
        } else if (this.statuses.findIndex(s => s.animalId === data[i].animal_AnimalId && s.statusCode && s.statusCode === AnimalStatusEnum.dead) >= 0) {
          status = this.localeService.constants.stringDied;
        }
        row['animalStatus'] = status;
        if (this.statuses.findIndex(s => s.animalId === data[i].animal_AnimalId) >= 0) {
          let stat = this.statuses.filter(s => s.animalId === data[i].animal_AnimalId)[0];
          row['animalStatusDate'] = DatatypeHelper.tryFormatDateWithMoment(stat.statusDate, 'D MMM YYYY');
          row['firstSeen'] = DatatypeHelper.tryFormatDateWithMoment(stat.firstSeen, 'D MMM YYYY');
        }
      }

      /* SessionImports with Only-LifeData would not have associated Animal, hence fetch the life data from WeightRecord */
      if (data[i].animal !== null) {
        row['AnimalId'] = data[i].animal_AnimalId;
        data[i].animal.customAnimalIdentifiers.forEach(cid => {
          let val = cid.value;
          if (cid.rawValue) {
            val = cid.rawValue;
          }
          row[cid.internalName || cid.name] = val;
          if (!this.headers.find(h => h.field === (cid.internalName || cid.name))) {
            if (cid.internalName && !primaryIdName[cid.internalName]) {
              primaryIdName[cid.internalName] = cid.name;
            }
            /* If Ids have same Internal name, the last row in the data would set the header, example (IDV and VID has same internal name, VID, so it's the order in which
              the data is processed would determine the header)
            */
            this.headers.unshift(<any>{ header: (primaryIdName[cid.internalName] || cid.name), field: (cid.internalName || cid.name), isId: true })
          }
        });
        this.reportsService.extend(row, JSON.parse(data[i].animal.userDefinedFieldsJson)
          , this.headers, this.localeService.constants.stringLifeData);
      } else {
        // we only use the LifeDataUserDefinedFieldJson property for WRs when there is no Animal, as data[i].lifeDataUserDefinedFieldJson
        // is the static values at the time of upload, and instead the above data[i].animal.userDefinedFieldsJson provides the
        // consolidated current values for these properties
        if (data[i].lifeDataUserDefinedFieldJson !== null) {
          this.reportsService.extend(row, JSON.parse(data[i].lifeDataUserDefinedFieldJson)
            , this.headers, this.localeService.constants.stringLifeData);
        }
      }
      if (data[i].userDefinedFieldsJson !== null) {
        this.reportsService.extend(row, JSON.parse(data[i].userDefinedFieldsJson)
          , this.headers, this.localeService.constants.stringSessionData);
      }


      params.forEach(param => {
        // if wrc[param] is null and there is any filter in place then we do not show it.
        if (search[param].compare(row[param])) {
          isFilteredOut = true;
        }
      });

      if (!isFilteredOut && this.filteredSession) {
        this.filteredSession.animalCount++;
        if (row['Weight'] && row['Weight'] > 0) {
          weightCount++;
          totalWeight += row['Weight'];
          if (row['Weight'] > this.filteredSession.maxWeight) {
            this.filteredSession.maxWeight = row['Weight']
          } else if (row['Weight'] < this.filteredSession.minWeight || this.filteredSession.minWeight === 0) {
            this.filteredSession.minWeight = row['Weight'];
          }
        }
      }
      this.fullDataSet.push(row);
      if (!isFilteredOut) {
        this.rows.push(row);
      }
      this.filteredAnimals = this.rows;
    }
    this.headers = FloatingHeaderHelper.reformatHeaderSpaces(this.headers);

    if (!this.sessionSummary || !this.sessionSummary.fieldDisplayOrder) {
      // group, status, and statusDate are not displayed by default
      this.curHeaders = this.headers.filter(h => h.field !== 'groupName' && h.field !== 'animalStatus' && h.field !== 'animalStatusDate');
    } else {
      let persistedColumns = <Array<RowHeader>>JSON.parse(this.sessionSummary.fieldDisplayOrder);
      this.curHeaders = [...this.headers.filter(h => persistedColumns.findIndex(pc => pc.field === h.field) >= 0)]
        .sort((a, b) => persistedColumns.findIndex(p => p.field === a.field) - persistedColumns.findIndex(p => p.field === b.field));
    }
    if (this.curHeaders) {
      if (!showWeight && this.curHeaders.findIndex(ch => ch.field === 'Weight') >= 0) {
        this.curHeaders.splice(this.curHeaders.findIndex(ch => ch.field === 'Weight'), 1); // don't display Weight by default if no values
      }
    }

    if(this.isPplSession){
      let timeStampHeader = {header:this.localeService.constants.stringDate, field:'timeStamp', type:DetailsSearchParamType.date, visible:true};
      this.headers.push(timeStampHeader);
      this.curHeaders.push(timeStampHeader);
    }

    if (this.filteredSession) {
      this.filteredSession.averageWeight = totalWeight / weightCount;
    }
    this.filterOptions = this.headers.filter(header => header.field[0] && header.field[0] !== '#' && header.field !== 'adgGraph' && header.field !== 'wgGraph')
      .map(header => {
        return { label: header.header, type: header.type >= 0 ? header.type : DetailsSearchParamType.string, value: header.field }
      });

    if (this.sortDetails && this.sortDetails.field !== undefined && this.rows) {
      this.reportsService.sortData(this.sortDetails, this.rows);
      this.sortField = this.sortDetails.field;
      this.sortOrder = this.sortDetails.order;
    } else {
      this.rows = this.rows.sort((a, b) => a.weightRecordId - b.weightRecordId);
    }
  }

  sortEvent(event: any) {
    if (event.field && event.field !== undefined) {
      this.sortDetails = event;
    }
  }

  scrollToTop() {
    if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
      window.scrollTo(0, 0);
    }
  }

  openAnimalInModal(animal: Animal): void {
    const modalRef = this.ngbModal.open(AnimalInfoComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-75'
    });

    const animalId = animal['animalId'] || animal['AnimalId'] || animal['#aid'];
    modalRef.componentInstance.animalId = animalId;
    modalRef.componentInstance.parentView = AnimalInfoParentViewEnum.SESSION;
    modalRef.componentInstance.parentId = this.sessionSummary.sessionId;

    let currPath = this.location.path();
    let updatedUrl = currPath.substring(0, currPath.indexOf('/sessions/details')) + '/animals/all?id=' + animalId + '&tab=summary';
    this.location.go(updatedUrl); // rewrites url without reloading component etc

    modalRef.componentInstance.actionDone.subscribe(res => {
      if (res.group) {
        const currentAnimal = this.rows.find(r => r['AnimalId'] && r['AnimalId'] === +animalId);
        const group = this.groupsService.groups.value.find(g => g.groupId === res.group.groupId);
        currentAnimal['groupName'] = group.displayName;
        currentAnimal['groups_GroupId'] = group.groupId;
      } else {
        this.statusChangeAnimalIds = [res.vActionId];
        this.statusChangeValue = res.status;
        this.soldDeadModalResult(res.vActionId);
      }
    });
  }

  // new assignment or assign to different group
  assignOrReassignAnimals(): void {
    let animalIds = this.filteredAnimals.map(function(a) { return a['AnimalId']; });
    this.filteredAnimals.forEach(fa => {
      fa.animal_AnimalId = fa['AnimalId'];
    });
    this.actionsAssignFilteredListModalService.init(animalIds, this.filteredAnimals, true).subscribe(result => {
      this.assignmentResult(result);
    });
  }

  assignmentResult(result: SessionAction) {
    let groupDisplayName = this.groupsService.groups.value.find(g => g.groupId === result.groupId).displayName;
    result.weightRecords.forEach(wr => {
      this.rows.find(a => a.weightRecordId === wr.weightRecordId).groupName = groupDisplayName;
      this.rows.find(a => a.weightRecordId === wr.weightRecordId).groups_GroupId = result.groupId;
      this.allWrc.find(a => a.weightRecordId === wr.weightRecordId).groups_GroupId = result.groupId;
    });
  }

  dayMonthString(date: string) {
    return ConvertToUTC(date).format('D MMM');
  }

  yearString(date: Date) {
    return ConvertToUTC(date).format('YYYY');
  }
}

import { AuthService } from '../../auth/auth.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConsolidatedAnimalMovementRequest } from '../models/animal-movement.model';
import { WhitelistIp } from '../models/whitelist-ip.model';

declare let moment: any;

@Injectable()
export class AdminService {

    constructor(private authService: AuthService, private apiService: ApiService) {  }

    setManualIntercomFlag(val: boolean = true): Observable<any> {
        return this.apiService.post('/Admin/SetIntercomUpdateFlag?turnOn=' + val, null );
    }

    setMassageEidsFlag(val: boolean = true): Observable<any> {
        return this.apiService.post('/Admin/SetMassageEidsFlag?turnOn=' + val, null );
    }

    forceUpdateSpecificFarmIds(farmId: number): Observable<any> {
        return this.apiService.post('/Admin/ForceUpdateSpecificFarmEids?farmId=' + farmId, null );
    }

    getTransfers(pg: Number = 1, startDate: Date = null, endDate: Date = null, ignoreSuccessful: Boolean = true): Observable<Array<ConsolidatedAnimalMovementRequest>> {
        let ed = endDate !== null ? endDate : new Date();
        let sd = new Date();
        sd.setDate(ed.getDate() - 10);
        if (startDate !== null) {
            sd = startDate;
        }
        let showAllRecords = !ignoreSuccessful;
        let sdf = moment(sd).format('YYYY-MM-DD');
        let edf = moment(ed).format('YYYY-MM-DD');
        return this.apiService.get('/Admin/GetAnimalMovementRequests?page=' + pg + '&startDate=' + sdf + '&endDate=' + edf + '&showAllRecords=' + showAllRecords);
    }

    getWhitelistIps(): Observable<Array<WhitelistIp>> {
        return this.apiService.get('/Admin/GetWhitelistIpAddresses');
    }

    addWhitelistIp(startIp: string, endIp: string, ipName: string): Observable<any> {
        return this.apiService.post(
            `/Admin/AddIpAddressToWhitelist`, {
                startIpAddress: startIp,
                endIpAddress: endIp,
                ipName: ipName
            }, 'text');
    }

    editWhitelistIp(ipId: number, startIp: string, endIp: string, ipName: string): Observable<any> {
        return this.apiService.post(
            `/Admin/UpdateIpAddress`, {
                whitelistIpsId: ipId,
                startIpAddress: startIp,
                endIpAddress: endIp,
                ipName: ipName
            }, 'text');
    }

    removeWhitelistIp(ipId: number): Observable<any> {
        return this.apiService.post(`/Admin/RemoveIpAddressFromWhitelist?ipId=` + ipId, null);
    }
}

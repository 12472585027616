import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {Animal, AnimalStatusEnum} from '../shared/animal.model';
import {PermissionsService} from '../../../auth/permissions.service';
import {WeightRecord} from '../../models';
import {SessionAction} from '../../models/session-action';
import {GroupsService} from '../../services/groups.service';
import {LocaleService} from '../../../helpers/locale.service';
import {ActionsModalService} from '../../actions/actions-modal/actions-modal.service';
import {ActionsSoldDeadService} from '../../actions/actions-sold-dead-modal/actions-sold-dead.service';
import {PageActionsComponent} from '../../../shared/page-actions/page-actions.component';
import {AnimalInfoParentViewEnum} from '../animal-info/animal-info.component';
import {MainService} from '../../main.service';
import {AnimalService} from '../../api/animal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-animal-actions',
  templateUrl: './animal-actions.component.html'
})
export class AnimalActionsComponent extends PageActionsComponent implements OnChanges {
  @Input() animal: Animal;
  @Input() parentView: AnimalInfoParentViewEnum;

  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionCompleted: EventEmitter<any> = new EventEmitter<any>();

  mostRecentWeightRecord: WeightRecord = null;
  changingStatus: AnimalStatusEnum = null;

  showAssignAnimal: boolean;
  showMarkAsCurrent: boolean;
  showMarkAsDeadSold: boolean;

  constructor(public permissionsService: PermissionsService,
              private localeService: LocaleService,
              private groupsService: GroupsService,
              public actionsModalService: ActionsModalService,
              private actionsSoldDeadService: ActionsSoldDeadService,
              private mainService: MainService,
              private animalService: AnimalService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showAssignAnimal = false;
    this.showMarkAsCurrent = false;
    this.showMarkAsDeadSold = false;

    // if all animal details are downloaded
    if (this.animal && this.animal.weightRecords) {
      this.setActionButtons();
    }
  }

  setActionButtons() {
    if (this.permissionsService.permissions.canEditData) {
      const wrs = this.animal.weightRecords.slice().sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());
      // if most recent WR has a group - this logic allows for future feature that Animals can be removed from a Group without being reassigned
      this.mostRecentWeightRecord = wrs[0];

      this.showAssignAnimal = true;

      if (wrs.find(wr => wr.animalStatus_AnimalStatusId === AnimalStatusEnum.sold) || wrs.find(wr => wr.animalStatus_AnimalStatusId === AnimalStatusEnum.dead)) {
        this.showMarkAsCurrent = true;
      } else {
        this.showMarkAsDeadSold = true;
      }
    }
  }

  // new assignment or assign to different group
  assignOrReassignAnimal(): void {
    let curGrp = null;
    if (this.mostRecentWeightRecord.groups_GroupId) {
      curGrp = this.groupsService.groups.value.find(g => g.groupId === this.mostRecentWeightRecord.groups_GroupId);
    }
    this.mostRecentWeightRecord.animal_AnimalId = this.animal.animalId;
    let wrs: Array<WeightRecord> = [];
    wrs.push(this.mostRecentWeightRecord);

    this.actionsModalService.init(<SessionAction>{
      v_ActionId: this.animal.animalId.toString(),
      farmId: this.animal.farm_FarmId,
      averageWeight: this.mostRecentWeightRecord.weight,
      groupId: this.mostRecentWeightRecord.groups_GroupId,
      animalCount: 1,
      confirmationRequiredGroup: curGrp,
      sessionId: this.mostRecentWeightRecord.session_SessionId,
      ided: true,
      entireSession: false,
      reassignGroup: !!this.mostRecentWeightRecord.groups_GroupId,
      oldGroupId: this.mostRecentWeightRecord.groups_GroupId,
      sessionStartDate: this.mostRecentWeightRecord.timeStamp,
      weightRecords: wrs
    }).subscribe(result => {
      this.actionCompleted.emit({
        vActionId: this.animal.animalId.toString(),
        status: this.animal.status,
        group: result
      });
    });
  }

  markAsCurrent() {
    this.updateStatus(AnimalStatusEnum.none);
  }

  markAsSold() {
    this.updateStatus(AnimalStatusEnum.sold);
  }

  markAsDead() {
    this.updateStatus(AnimalStatusEnum.dead);
  }

  updateStatus(status: AnimalStatusEnum) {
    const wrs = this.animal.weightRecords.slice().filter(w => w.session_SessionId).sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
    const firstSeen = wrs[0].timeStamp;
    let session = wrs[wrs.length - 1];
    let action = new SessionAction();
    action.sessionId = session.session_SessionId;
    action.v_ActionId = this.animal.animalId.toString() + '_single';
    action.ided = true;
    action.animalCount = 1;
    action.weightRecords = [wrs[wrs.length - 1]];
    this.changingStatus = status;

    // https://datamars.atlassian.net/wiki/spaces/MS/pages/96665609/Update+Animal+Status
    if (status === AnimalStatusEnum.none) {
       firstSeen ? this.mainService.setDate(firstSeen) : this.mainService.setDate();
    } else {
      const dt = new Date();
      const utcNow = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(),0,0,0,0)
      action.sessionStartDate = this.parentView === AnimalInfoParentViewEnum.SESSION ? session.timeStamp : new Date(utcNow);
    }
    

    // open modal
    this.actionsSoldDeadService.init(action, status, true).subscribe(() => {
      this.actionCompleted.emit({
        vActionId: this.animal.animalId.toString(),
        status: status
      });
    });
  }

  soldDeadModalResult(event) {
    if (event) {
      // we don't want to reload all Animal data, so just display updated info if successful
      if (this.changingStatus === AnimalStatusEnum.sold) {
        this.statusChange.emit(this.localeService.constants.stringSold);
        this.showMarkAsCurrent = true;
      } else if (this.changingStatus === AnimalStatusEnum.dead) {
        this.statusChange.emit(this.localeService.constants.stringDead);
        this.showMarkAsCurrent = true;
      } else {
        this.statusChange.emit(this.localeService.constants.stringCurrent);
        this.showMarkAsDeadSold = true;
      }
    }
    this.changingStatus = null;
  }
}

import { LocaleService } from '../../helpers/locale.service';
import { SignalRService, SignalrType } from './signal-r.service';
import { AuthService } from '../../auth/auth.service';
import { MainService } from '../main.service';
import { EventAudit, EventAuditTemplate } from '../models/event-audit';
import { SessionService } from './session.service';
import { GroupService } from './group.service';
import { environment } from '../../../environments/environment';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable ,  Subscription ,  BehaviorSubject } from 'rxjs';
import { ApiService, ToAbsoluteDate } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { DatatypeHelper } from '../../helpers/datatype.helper';
import * as moment from 'moment';



@Injectable({
  providedIn: 'root'
})
export class EventAuditService {
    public eventAudits = new BehaviorSubject<Array<EventAudit>>(null);
    public eventAuditTemplate: Array<EventAuditTemplate>;
    private version: string;
    constructor(private mainService: MainService, private apiService: ApiService, private sessionService: SessionService, private groupService: GroupService
        , private authService: AuthService, private signalRService: SignalRService, private localeService: LocaleService) {
    }

    public getLatestEvents(farmId: number) {
        if (!this.eventAudits.value || this.eventAudits.value.length === 0) {
            this.getEvents(farmId);
        } else {
            let lastDate = new Date(this.eventAudits.value[this.eventAudits.value.length - 1].timeStamp).toISOString();
            this.apiService.get(`/odata/EventAudits?$filter=farm_FarmId eq ${farmId} and timeStamp gt ${lastDate}&$orderby=timeStamp desc&$top=200`)
                .pipe(map((res: any) => res.value))
                .subscribe(events => {
                    events.forEach(event => {
                        event = this.processEvent(event);
                        if (!event) {
                            return;
                        }
                        this.eventAudits.value.push(event);
                    });
                    this.eventAudits.next(this.eventAudits.value);
                });
        }
    }

    processEvent(event:EventAudit) {
        const template = this.eventAuditTemplate[event.eventType];
        if (template) {
            event.titleText = template.titleTemplate;
            event.messageText = template.messageTemplate;
            event.timeStamp = moment.tz(event.timeStamp.toString(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland').utc().toDate() //moment(event.timeStamp).toDate();
            let data = JSON.parse(event.dataJson);
            
            Object.keys(data)
            .filter(prop=>prop=='sessionStartDate')
            .map(prop=>{
                data[prop] = ToAbsoluteDate(data[prop])
            });

            for (let prop in data) {
                if (data.hasOwnProperty(prop) && data[prop]
                    && (event.messageText.indexOf(`<${prop}/>`) > -1 || event.titleText.indexOf(`<${prop}/>`) > -1)) {
                        data[prop] = DatatypeHelper.tryFormatDateWithMoment(data[prop], 'D MMM YYYY');
                        event.messageText = event.messageText.replace(`<${prop}/>`, data[prop]);
                        event.titleText = event.titleText.replace(`<${prop}/>`, data[prop]);
                } else if (prop.toLowerCase() !== 'sessionid') {
                    // this is to handle empty strings or no value being passed through for a property,
                    // so we want to clear their placeholders from the template text
                    // don't do sessionId though, as we handle that below
                    event.messageText = event.messageText.replace(`<${prop}/>`, '');
                    event.titleText = event.titleText.replace(`<${prop}/>`, '');
                }
            }
            // if we're missing sessionId due to legacy data etc
            if (event.messageText.indexOf('sessionId') > -1 || event.titleText.indexOf('sessionId') > -1) {
                this.sessionService.sessions.subscribe(sess => {
                  if (sess) {
                    let seshes = sess.filter(s => s.sessionName === data['sessionName']);
                    let sesh = seshes[0];
                    if (seshes.length > 1) {
                        if (seshes.filter(s => DatatypeHelper.dateEquals(s.sessionStartDate.toString(), data['sessionStartDate'], 'D MMM YYYY')).length === 1) {
                            sesh = seshes.filter(s => DatatypeHelper.dateEquals(s.sessionStartDate.toString(), data['sessionStartDate'], 'D MMM YYYY'))[0];
                        } else if (seshes.filter(s => s.sessionEndDate !== null && DatatypeHelper.dateEquals(s.sessionEndDate.toString(), data['sessionStartDate'], 'D MMM YYYY')).length === 1) {
                                sesh = seshes.filter(s => s.sessionEndDate !== null && DatatypeHelper.dateEquals(s.sessionEndDate.toString(), data['sessionStartDate'], 'D MMM YYYY'))[0];
                        }
                    }
                    if (sesh && sesh.sessionId && sesh.sessionId > 0) {
                        event.messageText = event.messageText.replace(`<sessionId/>`, sesh.sessionId.toString());
                        event.titleText = event.titleText.replace(`<sessionId/>`, sesh.sessionId.toString());
                    }
                  }
                });
            }
            // if we're still missing sessionId (eg the Session has been deleted)
            // the quote characters below are no longer used currently, but the code to set them up is worth leaving intact for ease of future usage
            let startQuoteChar = this.localeService.locale === 'fr' ? '« ' : '\''; // french has a space after the quote
            let endQuoteChar = this.localeService.locale === 'fr' ? ' »' : '\''; // french has a space before the quote
            if (event.messageText.indexOf('sessionId') > -1 || event.titleText.indexOf('sessionId') > -1) {
                let sessionIdLink = `<a class=\"activityWidgetLink\" href=\"/#/main/sessions/summary?id=<sessionId/>\">`
                + data['sessionName'] + ` (` + data['sessionStartDate'] + `)</a>`;
                let sessionDisplayName = data['sessionName'] + ` (` + data['sessionStartDate'] + `)`;
                event.messageText = event.messageText.replace(sessionIdLink, sessionDisplayName);
                event.titleText = event.titleText.replace(sessionIdLink, sessionDisplayName);
            }
            if (event.messageText.indexOf('sessions/summary?id=0') > -1 || event.titleText.indexOf('sessions/summary?id=0') > -1) {
                let sessionIdLink = `<a class=\"activityWidgetLink\" href=\"/#/main/sessions/summary?id=0\">`
                + data['sessionName'] + ` (` + data['sessionStartDate'] + `)</a>`;
                let sessionDisplayName = data['sessionName'] + ` (` + data['sessionStartDate'] + `)`;
                event.messageText = event.messageText.replace(sessionIdLink, sessionDisplayName);
                event.titleText = event.titleText.replace(sessionIdLink, sessionDisplayName);
            }
            return event;
        }
        return null;
    }

    getEvents(farmId: number) {
        this.apiService.get(`/odata/EventAudits?$filter=farm_FarmId eq ${farmId}&$orderby=timeStamp desc&$top=200`)
            .pipe(map((res: any) => res.value), catchError(this.apiService.handleError))
            .subscribe((events: Array<EventAudit>) => {
                events.forEach(event => {
                    event = this.processEvent(event);
                });
                this.eventAudits.next(events.filter(e => e));
            });
    }
}

import { LocaleService } from '../../../helpers/locale.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Integration, IntegratedDevice } from '../../models/integration';
import { CalculatedDraft } from '../../models/draft';
import { IntegrationService } from '../../api/integration.service';
import { DraftsService } from '../../api/drafts.service';
import { Title } from '@angular/platform-browser';
import { MainService } from '../../main.service';
import { ScriptService } from '../../script.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { IntercomService } from '../../intercom.service';
import { AuthService } from '../../../auth/auth.service';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';
import { DatatypeHelper, ConvertToUTC } from '../../../helpers/datatype.helper';

@Component({
  selector: 'app-wow-dashboard',
  templateUrl: './wow-dashboard.component.html',
  styleUrls: ['./wow-dashboard.component.scss']
})
export class WowDashboardComponent implements OnInit, OnDestroy {
  public integration: Integration;
  public loading = false;
  public showDrafting = false;
  public devices: Array<IntegratedDevice> = [];
  private subs: Array<Subscription> = [];

  public activeDrafts: Array<CalculatedDraft> = [];

  constructor(private integrationService: IntegrationService,
              title: Title,
              private mainService: MainService,
              private router: Router,
              private localeService: LocaleService,
              private draftsService: DraftsService,
              private bottomNotificationsService: BottomNotificationsService,
              private intercomService: IntercomService,
              private authService: AuthService,
              private confirmationService: ConfirmationService,
              private scriptService: ScriptService) {
    title.setTitle(`${this.localeService.constants.stringRemoteWOWDashboard} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
  }

  dayMonthString(date: string) {
    return ConvertToUTC(date).format('D MMM');
  }

  deleteDraft(draftId): void {
    this.confirmationService.confirm({
      title: 'DELETE DRAFT',
      message: 'Are you sure that you want to delete this draft?'
    }).subscribe(() => {
      this.doDeleteDraft(draftId);
    })
  }

  doDeleteDraft(draftId) {
    this.draftsService.DeleteDraft(draftId).subscribe(res => {
      this.draftsService.GetCalculatedDrafts().subscribe(result => {
        this.activeDrafts = result['value'];
      });
      this.bottomNotificationsService.currentMessage.next({
        title: 'Remove Draft Success',
        message: 'Draft has been removed.',
        type: 'success'
      });
      this.intercomService.logEvent('Delete Draft', this.router.url, {
        user_id: this.authService.currentUser.userId,
        farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
        draftId: draftId
      });
    })
  }

  ngOnInit() {
    this.scriptService.load('momentJs').then(data => {
      this.subs.push(this.integrationService.integration.subscribe(int => {
        if (this.mainService.farmChanged.value && (this.mainService.farmChanged.value.integrationName !== 'PPL')) {
          this.router.navigate(['/main/dashboard']);
          this.integration = null;
        } else if (int !== null && int !== undefined) {
          this.loading = false;
          this.devices = [];
          this.integration = int;
          this.integration.integratedDevices.forEach(device => {
            if (device.drafter > 0) {
              this.showDrafting = true;
            }
            if (device.isActive) {
              this.devices.push(device);
            }
          });
        } else {
          this.integration = null;
          this.devices = [];
          this.loading = true;
        }
      }));

      this.draftsService.GetCalculatedDrafts().subscribe(result => {
        this.activeDrafts = result['value'];
      });
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

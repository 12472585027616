<app-summary-header
  i18n-headerTitle="@@settings"
  i18n-subHeaderTitle="MiHub is the product name@@mihublivestocksettings"
  headerTitle="Settings"
  subHeaderTitle="MiHub Livestock settings">
</app-summary-header>

<div>

  <ngb-tabset [destroyOnHide]="false">

    <ngb-tab i18n-title="@@farmdetails" title="Farm Details">
      <ng-template ngbTabContent>
        <div class="page-content">
          <app-farm-details></app-farm-details>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab i18n-title="@@devices" title="Devices" *ngIf="!permissionsService.permissions.groupViewOnly">
      <ng-template ngbTabContent>
        <div class="page-content">
          <app-device-details></app-device-details>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab i18n-title="@@remotewowintegration" title="Remote WOW Integration" *ngIf="mainService.farmChanged.value && mainService.farmChanged.value.countries_CountryId === 11 && permissionsService.permissions.canAccessPpl">
      <ng-template ngbTabContent>
        <div class="page-content">
          <app-ppl-integration></app-ppl-integration>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab i18n-title="@@generatetemporarypassword" title="Generate Temporary Password" *ngIf="authService.currentUser && authService.currentUser.userType === 'SuperUser'">
      <ng-template ngbTabContent>
        <div class="page-content">
          <app-temp-password></app-temp-password>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab i18n-title="@@stafftesting" title="Staff Testing" *ngIf="authService.currentUser && authService.currentUser.userType === 'SuperUser'">
      <ng-template ngbTabContent>
        <div class="page-content">
          <app-staff-testing></app-staff-testing>
        </div>
      </ng-template>
    </ngb-tab>

  </ngb-tabset>

<!--  <div class="mb-5" *ngIf="authService.currentUser && authService.currentUser.userType === 'SuperUser'">-->
<!--    <app-staff-testing></app-staff-testing>-->
<!--  </div>-->

<!--  <div class="mb-5" *ngIf="!permissionsService.permissions.groupViewOnly">-->
<!--    <app-device-details></app-device-details>-->
<!--  </div>-->

<!--  <div class="mb-5" *ngIf="mainService.farmChanged.value && mainService.farmChanged.value.countries_CountryId === 11 && permissionsService.permissions.canAccessPpl">-->
<!--    <app-ppl-integration></app-ppl-integration>-->
<!--  </div>-->

<!--  <div class="mb-5">-->
<!--    <app-farm-details></app-farm-details>-->
<!--  </div>-->

<!--  <div class="mb-5">-->
<!--    <app-temp-password></app-temp-password>-->
<!--  </div>-->

</div>

import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { Helper } from '../../helpers/instantiate.helper';
import { Draft, CalculatedDraft } from '../models/draft';
import { catchError } from 'rxjs/operators';





@Injectable({
  providedIn: 'root'
})
export class DraftsService {
    constructor( private authService: AuthService
        , private apiService: ApiService) {
    }

    public SaveDraft = (draft: Draft): Observable<Draft> => {
        return this.apiService.post(`/odata/Drafts`, draft)
        .pipe(catchError(this.apiService.handleError));
    }

    public DeleteDraft = (draftId: number): Observable<any> => {
        return this.apiService.delete(`/odata/Drafts(${draftId})`, 'text')
        .pipe(catchError(this.apiService.handleError));
    }

    public GetCalculatedDrafts(): Observable<CalculatedDraft[]> {
        return this.apiService.get(`/odata/Drafts/Services.CalculatedDrafts`)
        .pipe(catchError(this.apiService.handleError));
    }
}

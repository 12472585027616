<h3 *ngIf="!singleAnimal || actionsModalService.action?.groupId !== null" i18n="@@choosegroup">Choose group</h3>

<div class="group-list">
  <button class="btn mb-2" [ngClass]="actionsModalService.selectedGroupId === -1 ? 'btn-primary' : 'btn-outline-primary'" (click)="newGroup()" i18n="@@newgroup">New Group</button>
  <button *ngFor="let group of activeGroups" [ngClass]="actionsModalService.selectedGroupId === group.groupId ? 'btn-primary' : 'btn-outline-primary'"
    class="btn mb-2" (click)="actionsModalService.selectedGroupId = group.groupId">{{group.displayName}}</button>
    
  <button class="btn btn-link dropdown-toggle normalText" *ngIf="!showCompletedGroups && completedGroups && completedGroups.length > 0" (click)="showCompletedGroups = true" i18n="@@showcompletedgroups">Show completed groups</button>
  <ng-container *ngIf="showCompletedGroups">
    <hr />
    <button *ngFor="let group of completedGroups" [ngClass]="actionsModalService.selectedGroupId === group.groupId ? 'btn-primary' : 'btn-outline-primary'"
      class="btn mb-2" (click)="actionsModalService.selectedGroupId = group.groupId">{{group.displayName}}</button>
  </ng-container>
</div>

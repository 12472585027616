<div class="card">
  <div class="card-header">
    <h3>Generate Temporary Password</h3>
  </div>
  <div class="card-body">
    <div *ngIf="tempPass.length > 0">
      <div class="col-xs-4"></div>
      <div class="col-xs-4 alert dark alert-success mt5 p5 alert-sm">
        Your temporary password is: <strong>{{tempPass}}</strong>
      </div>
      <div class="col-xs-4"></div>
    </div>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="generatePassword($event)" class="form-horizontal">
      <div class="col-xs-12">
        <label class="col-lg-3 control-label">Email Address:</label>
        <div class="col-lg-8">
          <app-form-input [showError]="!form.controls['email'].valid && form.controls['email'].dirty"
                          i18n-error="@@emailerror" error="A valid email is required"
                          i18n-errorValidateEmail="@@emailerror" errorValidateEmail="A valid email is required">
            <input type="text" formControlName="email" class="form-control">
          </app-form-input>
          <div class="alert dark alert-danger alert-sm" *ngIf="!form.controls['email'].valid && form.controls['email'].dirty"><i class="fa fa-exclamation-circle "></i>A valid email is required.</div>
        </div>
      </div>
      <div class="col-xs-12">
        <button style="width:200px; margin: auto; display: block;" type="submit" [class.disabled]="!form.controls['email'].dirty || !form.controls['email'].valid" class="btn btn-primary">Generate
        </button>
      </div>
    </form>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h3 class="m-0" i18n="@@invitations">Invitations</h3>
  </div>
  <div class="card-body">

    <div *ngIf="invitations.length > 0" class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>
              <ng-container i18n="@@invitedby">Invited by</ng-container>
            </th>
            <th>
              <ng-container i18n="@@farmname">Farm name</ng-container>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invitation of invitations">
            <td>{{invitation.inviteeFirstName}} {{invitation.inviteeLastName}}</td>
            <td>{{invitation.farmName}}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-primary" (click)="accept(invitation)" [disabled]="invitation.accepted" i18n="@@accept">Accept</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="invitations.length === 0">
      <h5 i18n="@@nopendinginvitations">No pending invitations</h5>
    </div>

  </div>
</div>

<h3 class="page-heading" i18n="@@errors">Errors</h3>

<div class="card" *ngIf="errors">
  <div class="table-responsive">
    <table class="table table-hover mb-0">
      <thead>
        <tr>
          <th *ngIf="!hideFileName">
            <ng-container i18n="@@filename">File Name</ng-container>
          </th>
          <th>
            <ng-container i18n="@@error">Error</ng-container>
          </th>
          <th>
            <ng-container i18n="@@description">Description</ng-container>
          </th>
          <th>
            <ng-container i18n="@@row">Row</ng-container>
          </th>
          <th>
            <ng-container i18n="@@column">Column</ng-container>
          </th>
          <th>
            <ng-container i18n="@@status">Status</ng-container>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let error of errors">
          <td *ngIf="!hideFileName">{{error.fileName}}</td>
          <td class="text-danger">{{error.errorResult}}</td>
          <td>{{error.description}}</td>
          <td *ngIf="error.row">{{error.row}}</td>
          <td *ngIf="!error.row" i18n="acronym for Not Applicable@@na">N/A</td>
          <td *ngIf="error.column">{{error.column}}</td>
          <td *ngIf="!error.column" i18n="acronym for Not Applicable@@na">N/A</td>
          <td>
            <span class="fas fa-times text-danger"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

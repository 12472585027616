import { AuthService } from '../../auth/auth.service';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';





import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';

import { Farm } from '../models/farm.model';
import { User } from '../models/user.model';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    private users: Array<User>

    constructor(private authService: AuthService, private apiService: ApiService) {
    }
    public SaveUserData = (user: User): Observable<Response> => {
        let self = this;
        return this.apiService.patch(`/odata/Users('${user.userId}')`,
            {
                'firstName': user.firstName, 'lastName': user.lastName, 'phoneNumber': user.phoneNumber, 'email': user.email
                , 'addressLine1': user.addressLine1, 'addressLine2': user.addressLine2, 'city': user.city, 'country': user.country,
                'postcode': user.postcode, 'region': user.region
            }
        )
            .pipe(map((res: any) => {
                let newUser = Object.assign(this.authService.currentUser, res);
                self.authService.refreshUser(newUser.userId).subscribe();
                localStorage.setItem('user', JSON.stringify(newUser));
                return res;
            }));
    }

    public ChangeUserCurrentFarm = (farmId: number): Observable<User> => {
        let userId = this.authService.currentUser.userId;
        return this.apiService.patch(`/odata/Users('${userId}')?$expand=subscriptions($expand=subscriptionPlan),userFarms`
            , { 'currentFarm_FarmId': farmId });
    }

    public UpdateEmailAndPassword = (email: string, password: string = '', oldPassword: string = ''): Observable<any> => {
        return this.apiService.patch(`/account/PatchUser`
            , { email: email, 'password': password, 'oldPassword': oldPassword });
    }

    public UserExists = (code: string): Observable<boolean> => {
        return this.apiService.get(`/odata/Users/Services.UserExistsForInvitation(Code='${code}')`
        )
        .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    public deleteUser = (): Observable<boolean> => {
        return this.apiService.get(`/odata/Users/Services.DeleteTestUser()`, 'text')
            .pipe(map(() => true));
    }

    public patchUser(userId: string, patched): Observable<User> {
        return this.apiService.patch(`/odata/Users('${userId}')`, patched);
    }

    /* THIS IS ADMIN ONLY CODE... DO NOT USE IF USER IS NOT SUPER ADMIN */
    public getUsers(farmId: number = null, userId: string = null): Observable<Array<User>> {
        if (this.users && this.users !== undefined) {
            if (farmId) {
                return of(this.users.filter(u => u.userFarms.filter(uf => uf.farmId == farmId).length > 0)); // needs to be == to handle string vs number typing in JS
            }
            if (userId) {
                return of(this.users.filter(u => u.userId === userId));
            }
            return of(this.users);
        }

        return this.apiService.get('/odata/Users?$select=userId,firstName,lastName,created,email,userType&$expand=userFarms').pipe(map(res => {
            if (farmId) { // dont save when getting users for 1 farm only
                let subset = res.value.filter(u => u.userFarms.filter(uf => uf.farmId == farmId).length > 0); // needs to be == to handle string vs number typing in JS
                return subset;
            }
            if (userId) { // dont save when only getting a single user
                let subset = res.value.filter(u => u.userId === userId);
                return subset;
            }
            this.users = res.value;
            return this.users;
        }));
    }

    public getUsersFarms(user: User): Observable<User> {
        return this.apiService
            .get(`/odata/Users('${user.userId}')?$expand=userFarms($expand=farm($select=farmName);$filter=farmRoleId eq 1)&$select=userFarms`)
            .pipe(map(res => {
                user.userFarms = res.userFarms;
                return user;
            }));
    }
}

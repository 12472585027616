import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-education',
  templateUrl: './dashboard-education.component.html',
  styleUrls: ['./dashboard-education.component.scss']
})
export class DashboardEducationComponent implements OnInit {
  @Input()
  text = 'I am some education text. I will teach you how this works';

  @Input()
  titleText = 'TITLE';

  @Input()
  buttonText = 'Record';

  @Input()
  link = '/main/transfer';

  @Input()
  image: string;

  constructor() { }

  ngOnInit() {
  }

}

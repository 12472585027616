import {Injectable, OnDestroy} from '@angular/core';
import {map, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {Observable, Subscription, BehaviorSubject} from 'rxjs';
import {SignalRService} from './signal-r.service';

import {MainService} from '../main.service';
import {AuthService} from '../../auth/auth.service';
import {Animal, AnimalDataSet, AnimalStatus} from '../animals/shared/animal.model';
import {ApiService, ToAbsoluteDateString} from './api.service';
import {SessionService} from './session.service';
import {WeightRecord} from '../models';

@Injectable({
  providedIn: 'root'
})
export class AnimalService implements OnDestroy {
  // private static self: AnimalService;
  private allAnimals: Array<Animal> = null;

  private customAnimalIdentifiers: Array<any> = null;
  private lastVisitedFarmId: number = null;

  private allAnimalsCount = 0;

  public animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);

  private subs: Array<Subscription> = [];

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private mainService: MainService,
              private signalRService: SignalRService,
              private sessionService: SessionService) {
    // AnimalService.self = this;

    // if sessions are changing for whatever reason then we need to redownload animals (farm change/upload etc.)
    this.subs.push(this.sessionService.sessions.subscribe(sessions => {
      this.allAnimals = null;
      this.customAnimalIdentifiers = null;
    }));
  }

  public getAnimalIds = (farmId: number): Observable<Array<any>> => {
    if (farmId === this.lastVisitedFarmId && this.customAnimalIdentifiers) {
      return of(this.customAnimalIdentifiers);
    }
    return this.apiService.get(`/odata/CustomAnimalIdentifiers?$select=animal_AnimalId,name,internalName,value,rawValue&$orderby=animal_AnimalId`).pipe(map(allIds => {
        const animIds: Array<any> = [];
        allIds.value.forEach((id, index) => {
          if (index >= 1 && id.animal_AnimalId === allIds.value[index - 1].animal_AnimalId) {
            animIds[animIds.length - 1].customAnimalIdentifiers.push(id);
          } else {
            animIds.push({ customAnimalIdentifiers: [id], animalId: id.animal_AnimalId });
          }
        });
        this.lastVisitedFarmId = farmId;
        this.customAnimalIdentifiers = animIds;
        return animIds;
      })
      , catchError(this.apiService.handleError));
  }

  private getNext4000Animals(farmId: number, initPage = false) {
    return this.apiService.get(
      `/odata/Animals?$select=animalId,userDefinedFieldsJson,paddockName,pplLastSeen`
      + `&$expand=customAnimalIdentifiers($select=name,value,internalName,rawValue)`
      + `&$orderby=animalId asc`
      + `&$top=${initPage ? 100 : 4000}&$count=true&$skip=${this.allAnimals ? this.allAnimals.length : 0}`)
      .pipe(map((res: any) => {
          this.allAnimalsCount = res['@odata.count'];
          if (!this.allAnimals) {
            this.allAnimals = [];
          }
          this.allAnimals = this.allAnimals.concat(res.value);
          
          // Jira:  https://datamars.atlassian.net/browse/MIHUB-162
          // Since we've requested the animals in ascending order ("&$orderby=animalId asc"),
          // we should never occur see them out of order, so this safety test will have no effect.
          // But if they are out of order, reload the page, as something very bad has gone wrong.
          let l = this.allAnimals.length - 1;
          if (l > 0)
          {
            for (let i=0; i<l; i++)
            {
              // console.log(`i=${i},l=${l},a[${i}]=${this.allAnimals[i].animalId},a[${i+1}]=${this.allAnimals[i+1].animalId}`);
              if (this.allAnimals[i].animalId >= this.allAnimals[i+1].animalId)
              {
                this.allAnimals = [];
                window.location.reload();
                return this.allAnimals;
              }
            }
          }
          
          if (this.allAnimals.length >= this.allAnimalsCount) { // if we got all animals then let BS know
            let ads = <AnimalDataSet>{};
            ads.animals = this.allAnimals;
            ads.allAnimalsCount = this.allAnimalsCount;
            this.animalsDownloaded.next(ads);
          } else { // else go fetch next batch
            this.getNext4000Animals(farmId).subscribe();
          }
          return this.allAnimals;
        }),
        catchError(this.apiService.handleError));
  }

  public getAllAnimals = (farmId: number): Observable<Array<Animal>> => {
    if (this.allAnimals) {
      return of(this.allAnimals); // so we return ANY animals we have if we have any. report then wait for BS to tell it when all animals are ready.
    }
    return this.getNext4000Animals(farmId, true); // only get 100 page if we are starting out.
  }


  public GetAnimalSummaryAndDetail(animalId: number): Observable<Animal> {
    return this.apiService.getTyped<Animal>(Animal,`/odata/Animals(${animalId})?$expand=weightRecords($select=weightRecordId,weight`
      + `,userDefinedFieldsJson,session_SessionId,timeStamp,groups_GroupId,animalStatus_AnimalStatusId)`
      + `,customAnimalIdentifiers($select=internalName,name,value,rawValue)`)
      .pipe(map((animal:Animal)=>{
        animal.weightRecords = animal.weightRecords.map(w=>{return Object.assign(new WeightRecord(), w)});
        return animal;
      }));
  }

  getAnimalStatusesForSession(sessionId: number): Observable<Array<AnimalStatus>> {
    return this.apiService.getTyped<AnimalStatus>(AnimalStatus,`/odata/Animals/Services.GetAnimalStatusesForSession(sessionId=${sessionId})`)
      .pipe(catchError(this.apiService.handleError));
  }

  updateAnimalsStatusesDied(idAnimals: boolean, sessionId: number, groupId: number = null, actionDate: Date): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    const absoluteDate=ToAbsoluteDateString(actionDate);

    return this.apiService.post(
      `/odata/Actions/Services.UpdateAnimalsStatusesDied`, {
        'sessionId': sessionId,
        'groupId': groupId,
        'idAnimals': idAnimals,
        'actionDate': ToAbsoluteDateString(actionDate)
      });
  }

  updateAnimalsStatusesSold(idAnimals: boolean, sessionId: number, groupId: number = null, actionDate: Date): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    const absoluteDate=ToAbsoluteDateString(actionDate)

    return this.apiService.post(
      `/odata/Actions/Services.UpdateAnimalsStatusesSold`, {
        'sessionId': sessionId,
        'groupId': groupId,
        'idAnimals': idAnimals,
        'actionDate':  ToAbsoluteDateString(actionDate)
      });
  }

  updateAnimalsStatusesCurrent(idAnimals: boolean, sessionId: number, groupId: number = null, actionDate: Date): Observable<boolean> {
    const absoluteDate=ToAbsoluteDateString(actionDate);
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);

    return this.apiService.post(
      `/odata/Actions/Services.UpdateAnimalsStatusesCurrent`, {
        'sessionId': sessionId,
        'groupId': groupId,
        'idAnimals': idAnimals,
        'actionDate':  ToAbsoluteDateString(actionDate)
      });
  }

  updateAnimalsStatusesDiedBySessionId(idAnimals: boolean, sessionId: number): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    return this.apiService.post(
      `/odata/Animals/Services.UpdateAnimalsStatusesDiedBySessionId`, {
        'sessionId': sessionId,
        'idAnimals': idAnimals
      });
  }

  updateAnimalsStatusesSoldBySessionId(idAnimals: boolean, sessionId: number): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    return this.apiService.post(
      `/odata/Animals/Services.UpdateAnimalsStatusesSoldBySessionId`, {
        'sessionId': sessionId,
        'idAnimals': idAnimals
      });
  }

  updateAnimalsStatusesCurrentBySessionId(idAnimals: boolean, sessionId: number): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    return this.apiService.post(
      `/odata/Animals/Services.UpdateAnimalsStatusesCurrentBySessionId`, {
        'sessionId': sessionId,
        'idAnimals': idAnimals
      });
  }

  updateAnimalsStatusesDiedByWRIds(weightRecordIds: Array<number>, sessionId: number, groupId: number, actionDate: Date): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    const absoluteDate=ToAbsoluteDateString(actionDate)

    return this.apiService.post(
      `/odata/Animals/Services.UpdateAnimalsStatusesDiedByWeightRecordIds`, {
        'sessionId': sessionId,
        'groupId': groupId,
        'weightRecordIds': weightRecordIds,
        'actionDate':  ToAbsoluteDateString(actionDate)
      });
  }

  updateAnimalsStatusesSoldByWRIds(weightRecordIds: Array<number>, sessionId: number, groupId: number, actionDate: Date): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    const absoluteDate=ToAbsoluteDateString(actionDate);

    return this.apiService.post(
      `/odata/Animals/Services.UpdateAnimalsStatusesSoldByWeightRecordIds`, {
        'sessionId': sessionId,
        'groupId': groupId,
        'weightRecordIds': weightRecordIds,
        'actionDate':  ToAbsoluteDateString(actionDate)
      });
  }

  updateAnimalsStatusesCurrentByWRIds(weightRecordIds: Array<number>, sessionId: number, groupId: number, actionDate: Date): Observable<boolean> {
    this.allAnimals = null;
    this.animalsDownloaded = new BehaviorSubject<AnimalDataSet>(null);
    const absoluteDate=ToAbsoluteDateString(actionDate);

    return this.apiService.post(
      `/odata/Animals/Services.UpdateAnimalsStatusesCurrentByWeightRecordIds`, {
        'sessionId': sessionId,
        'groupId': groupId,
        'weightRecordIds': weightRecordIds,
        'actionDate':  ToAbsoluteDateString(actionDate)
      });
  }

  ngOnDestroy() {
    if (this.animalsDownloaded) {
      this.animalsDownloaded.unsubscribe();
    }

    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}

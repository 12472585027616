<!--<app-action-group-selection #actionGroupSelector (groupChosen)="assignNewGroup($event)"></app-action-group-selection>-->

<!--<div class="d-flex">-->
<!--  <h2 i18n="@@remotewowintegration">Remote WOW Integration</h2>-->
<!--</div>-->
<div class="mb-3" *ngIf="!loading && integration !== null">
  <button class="btn btn-primary pull-right" (click)="openIntegrationModal(integration)">Update Details</button>
</div>

<div class="card">

  <div class="card-body" *ngIf="loading">
    <app-loading [local]="true"></app-loading>
  </div>

  <div>

    <!-- no integrations -->
    <div *ngIf="!loading && integration === null" class="text-center">
      <a class="w100p" target="_blank" href="https://livestock.tru-test.com/en-au/product/remote-wow-systems">
        <img src="assets/images/Remote WOW MiHub Banner.jpg" class="w100p ppl-upsell-banner"/>
      </a>
      <button class="btn btn-primary my-3" (click)="openIntegrationModal()">Add Remote WOW Device</button>
    </div>

    <div *ngIf="!loading && integration !== null">
      <div *ngIf="integration.authenticationFailed" class="alert alert-danger" style="height: 50px !important; padding: 10px !important; cursor: pointer;"
           (click)="openIntegrationModal(integration)">
        <i class="fa fa-exclamation-circle fa-2x" style="color:white;"></i>
        <span
          style="position: absolute; line-height: 50px; vertical-align: middle; top: 0px; left: 60px;">Authentication has failed for this connection. Please click here to re-enter your password.</span>
      </div>
      <table class="table mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Seen</th>
            <th>Autodrafter</th>
            <th>Group</th>
            <th>Active</th>
            <th style="width: 190px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let paddock of integration.integratedDevices">
            <td>{{paddock.integratedDeviceName}}</td>
            <td>{{dayMonthYearString(paddock.lastSeen)}}</td>
            <td>
              <i class="fa" [class.fa-check]="paddock.drafter > 0"></i>
            </td>
            <td>
              <a *ngIf="paddock.group" [routerLink]="['/main/groups/summary']" [queryParams]="{id:paddock.group.groupId}">{{paddock.group.displayName}}</a>
            </td>
            <td>
              <i class="fa" [class.fa-check]="paddock.isActive > 0"></i>
            </td>
            <td class="">

              <div class="btn-group btn-group-sm">
                <button type="button" class="btn btn-primary" (click)="currentlyEditedGroup = paddock.group; createNewGroupService.editGroup(paddock.group)" i18n="@@edit">Edit</button>
                <div class="btn-group" ngbDropdown role="group" placement="bottom-right">
                  <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle (click)="currentlyEditedIntegration = paddock"></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button ngbDropdownItem (click)="openChangeGroupModal(paddock)" i18n="@@changegroup">Change Group</button>
                  </div>
                </div>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {LocaleService} from './helpers/locale.service';
import {environment} from '../environments/environment';
import {GlobalParameterService} from './helpers/global-parameter.service';

@Component({
  selector: 'app-root',
  template: '<ng-container *ngIf="localeService.constants && localeService.month"><router-outlet>'
    + '</router-outlet></ng-container><app-bottom-notifications></app-bottom-notifications>',
  providers: [GlobalParameterService]
})
export class AppComponent implements OnInit {
  fullUrl = '';

  constructor(
    private router: Router,
    public localeService: LocaleService,
    private ngbModalStack: NgbModal) {
        NgbModalRef.prototype['c'] = NgbModalRef.prototype.close;
        NgbModalRef.prototype.close = function(reason: string) {
          let secondary = document.querySelector('.modal-secondary-menu');
          if (secondary !== null) {
            document.querySelector('.modal-backdrop').classList.remove('show');
            secondary.classList.add('closing');
          }
          setTimeout(() => {
            this['c'](reason);
          }, 230); // this delay is less than the transition time of the 'closing' class
        };
        NgbModalRef.prototype['d'] = NgbModalRef.prototype.dismiss;
        NgbModalRef.prototype.dismiss = function(reason: string) {
          let secondary = document.querySelector('.modal-secondary-menu');
          if (secondary !== null) {
            document.querySelector('.modal-backdrop').classList.remove('show');
            secondary.classList.add('closing');
          }
          setTimeout(() => {
            this['d'](reason);
          }, 230); // this delay is less than the transition time of the 'closing' class
        };
  }

  public ngOnInit(): void {
    let events = this.router.events.subscribe(e => {
      events.closed = true;
    });

    if (window['ga']) {
      window['ga']('create', environment.gaCode);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd) || !window['ga']) {
        return;
      }

      this.ngbModalStack.dismissAll();

      if (this.fullUrl !== this.router.url) {
        // we need to avoid sending to google analytics twice when navigate to groups/summary and navigate to groups/summary?groupId
        const excludeUrls = ['groups/summary', 'groups/details', 'sessions/summary', 'sessions/details', 'animals/details'];
        let ignoreGoogleAnalytics = excludeUrls.some(url => this.fullUrl.indexOf(url) !== -1 && this.router.url.indexOf(url) !== -1
          && this.fullUrl.indexOf('?') === -1 && this.router.url.indexOf('?') !== -1);
        if (!ignoreGoogleAnalytics) {
          window['ga']('set', 'page', this.router.url);
          window['ga']('send', 'pageview');
        }
      }
      this.fullUrl = this.router.url;
    });
  }
}

import {Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {SelectItem} from 'primeng/components/common/selectitem';

import {LocaleService} from '../../../helpers/locale.service';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {DetailsSearchParams, DetailsSearchParam, DetailsSearchParamType} from '../../models/details-search-params';
import {DatatypeHelper} from '../../../helpers/datatype.helper';

@Component({
  selector: 'app-reports-filters',
  templateUrl: './reports-filters.component.html',
  styleUrls: ['./reports-filters.component.scss']
})
export class ReportsFiltersComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() data: Array<any> = [];
  @Input() filterOptions: Array<any> = [{ label: this.localeService.constants.stringWeight, type: DetailsSearchParamType.number, value: 'weight' }];
  @Input() showSearch = true;
  @Input() showFilters = true;

  @Output() globalFilterChanged = new EventEmitter<any>(null);

  @ViewChild('gb', { static: false }) globalFilter;

  filterOption = null;
  filterTypes: SelectItem[] = [];
  filters: DetailsSearchParams;
  filterNames: string[];
  filterType: SelectItem;
  amount = 0;
  suggestions: SelectItem[] = [];
  allSuggestions: string[] = [];

  private oldFilterOption = null;
  private oldFilterType: SelectItem = null;
  private subscription: Subscription;

  private filterTypesDate: Array<SelectItem> = [
    { label: this.localeService.constants.stringBefore, value: 'less' },
    { label: this.localeService.constants.stringOnOrAfter, value: 'greater' },
    { label: this.localeService.constants.stringOn, value: 'equal' },
    { label: this.localeService.constants.stringIsUnknown, value: 'isUnknown' },
    { label: this.localeService.constants.stringIsKnown, value: 'isKnown' }
  ];

  private filterTypesNumber: Array<SelectItem> = [
    { label: this.localeService.constants.stringBelow, value: 'less' },
    { label: this.localeService.constants.stringAboveOrEqualTo, value: 'greater' },
    { label: this.localeService.constants.stringEqualTo, value: 'equal' },
    { label: this.localeService.constants.stringIsUnknown, value: 'isUnknown' },
    { label: this.localeService.constants.stringIsKnown, value: 'isKnown' }
  ];

  private filterTypesString: Array<SelectItem> = [
    { label: this.localeService.constants.stringEqualTo, value: 'equal' },
    { label: this.localeService.constants.stringNotEqualTo, value: 'notEqual' },
    { label: this.localeService.constants.stringIsUnknown, value: 'isUnknown' },
    { label: this.localeService.constants.stringIsKnown, value: 'isKnown' }
  ];


  constructor(private globalParameterService: GlobalParameterService, public localeService: LocaleService) {
  }

  ngOnInit() {
    this.subscription = this.globalParameterService.detailsSearchParams.subscribe(search => {
      if (search) {
        this.filters = Object.assign({}, search);
        this.filterNames = Object.keys(search);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data && this.filterOptions && changes['data'] && changes['data'].currentValue) {

      this.filterOptions.forEach(filter => {
        filter.label = filter.label.replace(/&nbsp;/g, ' ');
        let newType = filter.type;
        if (filter.value && ['EID', 'VID', 'LID'].indexOf(filter.value) > -1) { // force ID fields to always be string filter
          filter.type = DetailsSearchParamType.string;
        } else if (filter.type === DetailsSearchParamType.string) {
          for (let param in this.data) {
            if (this.data[param] && filter.value && this.data[param][filter.value]) {
              let type = DatatypeHelper.getFieldType(this.data[param][filter.value]);
              if (type === DetailsSearchParamType.string) {
                break;
              }
              if (type !== newType) {
                newType = type;
              }
            }
          }
          if (newType !== filter.type) {
            filter.type = newType;
            filter.label = filter.label;
          }
        }
      });

      this.filterOptions = this.filterOptions.slice();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  dayMonthYearString(date: string) {
    return DatatypeHelper.tryFormatDateWithMoment(date.toString(), 'D MMM YYYY');
  }

  filterOptionChanged(): void {
    if (!this.filterOption) {
      return;
    }

    // set filter types list
    switch (this.filterOption.type) {
      case DetailsSearchParamType.string:
        this.filterTypes = this.filterTypesString;
        break;
      case DetailsSearchParamType.date:
        this.filterTypes = this.filterTypesDate;
        break;
      case DetailsSearchParamType.number:
        this.filterTypes = this.filterTypesNumber;
        break;
    }

    // preset filter type
    if (!this.filterType || !this.doesFilterTypeExists()) {
      this.filterType = this.filterTypes[0];
    }

    // ???
    if (!this.oldFilterOption || this.oldFilterOption.type !== this.filterOption.type) {
      this.amount = null;
    }

    // populate a list of autocomplete if is string
    if (this.filterOption && this.filterOption.type === 1) {
      this.allSuggestions = [];
      this.suggestions = [];

      for (let dataItem of this.data) {
        const filterOptionValue = dataItem[this.filterOption.value];
        let alreadyInSuggestion = false;

        if (['EID', 'VID', 'LID'].indexOf(this.filterOption.value) === -1) {
          alreadyInSuggestion = this.allSuggestions.indexOf(filterOptionValue) > -1;
        }

        if (filterOptionValue && !alreadyInSuggestion) {
          this.allSuggestions.push(filterOptionValue);
          this.suggestions.push({
            label: filterOptionValue,
            value: filterOptionValue
          });
        }
      }
    }
  }

  editFilter(filterOptionStr, filterType, amount) {
    this.filterOption = this.filterOptions.find(fo => fo.value === filterOptionStr);
    this.oldFilterOption = Object.assign({}, this.filterOption);

    this.filterType = this.filterTypes.find(ft => ft.value === filterType);
    this.oldFilterType = Object.assign({}, this.filterType);

    this.amount = amount;

    this.filterOptionChanged();
  }

  resetFilterSelector(filter: DetailsSearchParam = null) {
    this.filterOption = this.filterOptions.length > 0 ? this.filterOptions[0] : null;
    this.oldFilterOption = null;

    this.filterType = null;
    this.oldFilterType = null;

    this.amount = 0;

    this.filterOptionChanged();
  }

  ngAfterViewInit() {
    if (this.globalFilter) {
      this.globalFilterChanged.emit(this.globalFilter.nativeElement);
    }
  }

  searchBoxChanged() {
    this.globalFilterChanged.emit(this.globalFilter.nativeElement);
  }

  addFilter(event) {
    event.preventDefault();

    const filter: DetailsSearchParams = this.globalParameterService.detailsSearchParams.value;

    if (!filter[this.filterOption.value]) {
      filter[this.filterOption.value] = new DetailsSearchParam(this.filterOption.label, this.filterOption.type, null, null, null);
    }

    if (this.amount !== null || this.filterType.value === 'isUnknown' || this.filterType.value === 'isKnown') {
      this.setFilterAmount(this.filterOption.value, this.filterType.value, this.filterType.value === 'isUnknown' || this.filterType.value === 'isKnown' ? true : this.amount);

      if (this.oldFilterOption && (this.filterType.value !== this.oldFilterType.value || this.filterOption.value !== this.oldFilterOption.value)) {
        this.setFilterAmount(this.oldFilterOption.value, this.oldFilterType.value, null);
        this.removeFilter();
      }

      this.globalParameterService.detailsSearchParams.next(filter);
    }
  }

  removeFilter() {
    setTimeout(() => {
      for (const param in this.filters) {
        if (this.filters[param].greater === null && this.filters[param].less === null
          && this.filters[param].equal === null && this.filters[param].notEqual === null
          && this.filters[param].isKnown === false && this.filters[param].isUnknown === false) {
          delete this.filters[param];
        }
      }
      this.globalParameterService.detailsSearchParams.next(this.filters)
    });
  }

  private doesFilterTypeExists(): boolean {
    return this.filterTypes.filter(ft => ft.value === this.filterType.value && ft.label === this.filterType.label).length === 1;
  }

  private setFilterAmount(filterOption: string, filterType: string, amount: any) {
    const filter = this.globalParameterService.detailsSearchParams.value;
    filter[filterOption][filterType] = amount;
  }
}

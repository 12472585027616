import {Component, OnInit, Input, Inject, ViewChild, TemplateRef} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {SessionAction} from '../../models/session-action';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {LocaleService} from '../../../helpers/locale.service';
import {SessionService} from '../../api/session.service';
import {Helper} from '../../../helpers/instantiate.helper';
import {ActionsAssignFilteredListModalService} from './actions-assign-filtered-list-modal.service';
import {ActionsModalService} from '../actions-modal/actions-modal.service';
import {ActionsService} from '../actions.service';
import {GroupsService} from '../../services/groups.service';
import {MainService} from '../../main.service';
import {ActionService} from '../../api/action.service';
import {Group} from '../../models/group.model';
import {GlobalLoaderService} from '../../../core/global-loader.service';

@Component({
  templateUrl: './actions-assign-filtered-list-modal.component.html',
  styleUrls: ['./actions-assign-filtered-list-modal.component.scss']
})
export class ActionsAssignFilteredListModalComponent implements OnInit {

  @Input() singleAnimal = false;
  @Input() reassignString = '';
  @Input() unassignedString = '';
  @Input() showPreviousWeights = true;
  @Input() sessionAssignment = false;
  @Input() changingGroup = false;
  @Input() reassignCountString = '';

  @ViewChild('errorTemplateTitle', { static: true }) errorTemplateTitle: TemplateRef<any>;
  @ViewChild('errorTemplateMessageAnimalCount', { static: true }) errorTemplateMessageAnimalCount: TemplateRef<any>;
  @ViewChild('errorTemplateMessageGroup', { static: true }) errorTemplateMessageGroup: TemplateRef<any>;

  window = window;
  showSplitTab = false;
  lockDoneButton = false;
  filterType: number;
  dropdownItems = [
    { value: true, label: this.localeService.constants.stringMoveToTheNewGroup },
    { value: false, label: this.localeService.constants.stringStayInTheCurrentGroup }
  ];

  private opened = false;

  constructor(public actionsAssignFilteredListModalService: ActionsAssignFilteredListModalService,
              private actionsModalService: ActionsModalService,
              public createNewGroupService: CreateNewGroupService,
              private actionsService: ActionsService,
              private actionService: ActionService,
              private bottomNotificationsService: BottomNotificationsService,
              public localeService: LocaleService,
              private groupsService: GroupsService,
              private sessionService: SessionService,
              private mainService: MainService,
              private globalLoaderService: GlobalLoaderService,
              public ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal) {
  }

  ngOnInit() {
    // update movePriorWeights here if needed
  }

  closeOrDismissModal(group?: Group): void {
    // if (this.createNewGroupService.stage === 0) { // if we are not moving on to create group then close modal
    // this.actionComplete.emit(group && group.groupId ? this.actionsModalService.action.v_ActionId : null);

    if (group && group.groupId && this.actionsAssignFilteredListModalService.action && this.actionsAssignFilteredListModalService.action.v_ActionId) {
      this.ngbActiveModal.close(this.actionsAssignFilteredListModalService.action);
    } else {
      this.ngbActiveModal.dismiss();
    }

    this.clearData();
    this.ngbModal.dismissAll();
    // }
  }

  clearData(): void {
    this.actionsAssignFilteredListModalService.action = null;
    this.showSplitTab = false;
    this.lockDoneButton = false;
    this.actionsAssignFilteredListModalService.visible = false;
  }

  newGroupSelected(group: Group): void {
    this.save();
  }

  save() {
    this.actionsAssignFilteredListModalService.action = Helper.instantiate(new SessionAction(), Object.assign({}, this.actionsModalService.action));
    this.actionsAssignFilteredListModalService.selectedGroupId = this.actionsModalService.selectedGroupId;
    // we never have splitting done in this component as the animals being assigned are already filtered etc
    if (!this.actionsAssignFilteredListModalService.selectedGroupId) {
      this.bottomNotificationsService.currentMessage.next({
        titleTemplate: this.errorTemplateTitle,
        messageTemplate: this.errorTemplateMessageGroup,
        type: 'failure',
        duration: 6000
      });

      this.globalLoaderService.showGlobalLoader = false;
      return;
    }

    this.lockDoneButton = true;
    this.actionsAssignFilteredListModalService.action.greyOut = true;
    if (this.actionsAssignFilteredListModalService.action.groupId && !this.mainService.farmChanged.value.groupAssignmentAll) {
      // handling setting this value if the user doesn't change it from the default
      // don't want to set a default value until they hit save though
      this.previousWeightsChanged(false);
    }
    if (this.actionsAssignFilteredListModalService.selectedGroupId) { // assigning an action

      let newAction = Helper.instantiate(new SessionAction(), Object.assign({}, this.actionsAssignFilteredListModalService.action));
      newAction.groupId = this.actionsAssignFilteredListModalService.selectedGroupId;
      newAction.recommendedGroupId = this.actionsAssignFilteredListModalService.action.recomendedGroup
                ? this.actionsAssignFilteredListModalService.action.recomendedGroup.groupId
                : null;
      if (newAction.recommendedGroupId !== null && newAction.groupId !== newAction.recommendedGroupId) {
        newAction.reassignGroup = true;
      }
      let wrIds = [];
      if (newAction.weightRecords !== null && newAction.weightRecords !== undefined) {
        newAction.weightRecords.forEach(wr => {
          wrIds.push(wr.weightRecordId);
        });
      }
      let weightRecordSplit = {
          fieldName: 'weight'
        , splitCriteria: 'below 999999'
        , groupId: newAction.groupId
        , weightRecordIds: wrIds
      };
      (
        this.actionsService.assignWeightRecordsToGroups(newAction, [weightRecordSplit], this.mainService.farmChanged.value.groupAssignmentAll)
      ).subscribe(() => {
        this.actionsService.updateRecommendation();
        let baseMessage = this.actionsAssignFilteredListModalService.action.ided
          ? (<string>this.localeService.constants.stringAnimalshavebeenassignedtothegroup)
          : (<string>this.localeService.constants.stringWeightsWithoutIDshavebeenassignedtothegroup);
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringAnimalsSuccessfullyAssignedToAGroup,
          message: baseMessage
            .replace('<animalCount/>', this.actionsAssignFilteredListModalService.action.animalCount.toString())
            .replace('<groupDisplayName/>', this.groupsService.groups.value.find(g => g.groupId === this.actionsAssignFilteredListModalService.selectedGroupId).groupName),
          type: 'success'
        });
        // this.actionComplete.emit(this.actionsModalService.action.v_ActionId);
        Object.assign(this.actionsAssignFilteredListModalService.action, newAction);
        this.ngbActiveModal.close(this.actionsAssignFilteredListModalService.action);
        this.actionsAssignFilteredListModalService.action = null;
        this.lockDoneButton = false;
        this.globalLoaderService.showGlobalLoader = false;
      }, err => {
        this.globalLoaderService.showGlobalLoader = false;
        this.lockDoneButton = false;
        this.actionsAssignFilteredListModalService.action.greyOut = false;
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringUpdateFailed,
          message: this.localeService.constants.stringSessionAssignmentFailed,
          type: 'warning'
        });
      });
    } else {
      this.globalLoaderService.showGlobalLoader = false;
    }
  }

  previousWeightsChanged(keep: boolean) {
    // this.actionsModalService.movePriorWeights = keep;
    this.actionsAssignFilteredListModalService.action.movePriorWeights = this.actionsAssignFilteredListModalService.movePriorWeights;
    this.actionService.updateFarmGroupAssignmentProperty(keep);
  }
}

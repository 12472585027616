<section id="main" class="external-page-content">

  <div class="form-holder">
    
    <div class="card card-external card-login">

  <div class="card-body">

    <!-- logo -->
    <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

    <!-- message -->
    <!-- using innerHTML so that we can style elements of the message -->
    <div class="messageContent" [innerHTML]="marketingMessage"></div>

    <div *ngIf="optingOut && !optedOut" class="d-flex flex-align-items-center">
      <button (click)="submitOptOut()" class="btn btn-primary"><span i18n="@@confirm">Confirm</span></button>
    </div>

  </div>
</div>

</div>

</section>

import { Component, OnInit, Input } from '@angular/core';
import { ErrorSummary } from '../../models/error-summary.model';
import { LocaleService } from '../../../helpers/locale.service';

@Component({
  selector: 'app-error-summary',
  templateUrl: './error-summary.component.html',
  styleUrls: ['./error-summary.component.scss']
})
export class ErrorSummaryComponent implements OnInit {
  @Input()
  errors: Array<ErrorSummary>;
  @Input()
  hideFileName = false;

  constructor(private localeService: LocaleService) { }

  ngOnInit() {
    /*
    this.localeService.getUploadErrors().subscribe(msgs => {
      if (msgs) {
        this.errors.forEach(error => {
          if (msgs[error.errorTypeText]) {
            const params = JSON.parse(error.params);
            let line: string = msgs[error.errorTypeText].message;
            for (const param in params) {
              if (param && params[param] && line.indexOf(param)) {
                line = line.replace(`<${param}/>`, params[param])
              }
            }
            if (this.localeService.constants[error.errorResult]) {
              error.errorResult = this.localeService.constants[error.errorResult];
            }
            error.description = line;
          }
        });
      }
    });
    */
  }
}

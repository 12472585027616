<!--<p-dialog [modal]="true" class="ui-dialog-show-overflow" [responsive]="true" [resizable]="false" [width]="window.innerWidth > 350? 350 : window.innerWidth"-->
<!--  minWidth="350" i18n-header="WOW is accronym for Walk On Weighing@@remoteWowDraft" header="REMOTE WOW DRAFT" [(visible)]="showModal" [draggable]="false" (onBeforeHide)="showModal = false;">-->

<div class="modal-header">
  <h3 i18n="WOW is accronym for Walk On Weighing@@remoteWowDraft">REMOTE WOW DRAFT</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<form *ngIf="form" [formGroup]="form" class="modal-form-with-footer">

  <div class="modal-body">

    <!-- draft name -->
    <app-form-input [control]="form.get('draftName')" i18n-error="@@draftnamerequired" error="Draft Name required" i18n-label="@@draftname" label="Draft Name">
      <input type="text" formControlName="draftName" class="form-control">
    </app-form-input>

    <!-- possible device selection -->
    <div>

      <ng-container *ngIf="integratedDevices.length === 1">
        <div>Drafter:&nbsp;&nbsp;</div>
        {{integratedDevice.integratedDeviceName}}
      </ng-container>

      <app-form-input *ngIf="integratedDevices.length > 1" [control]="form.get('draftDevice')" i18n-error="@@draftdevicerequired" error="Draft Device required" i18n-label="@@draftdevice" label="Draft Device">
        <ng-select
          formControlName="draftDevice"
          [items]="integratedDevices"
          placeholder="Drafter"
          bindLabel="integratedDeviceName"
          (change)="integratedDevice = $event">
        </ng-select>
      </app-form-input>

    </div>

    <div class="text-center mt-3">
      <div *ngIf="animalIds" class="mb-0 mt-2">{{animalIds.length}}</div>
      <div i18n="EID is acronym for Electronic Identification@@AnimalsWithEids">Animals with EIDs</div>
      <div i18n="@@willbedrafted">will be drafted</div>
    </div>

    <div class="text-center mt-3">
      <a (click)="this.form.controls['draftDirection_DraftDirectionId'].setValue(3)">
        <i class="col-xs-offset-3 col-xs-3 fa fa-arrow-circle-left" style="text-align: right"
           [class.active]="this.form.controls['draftDirection_DraftDirectionId'].value === 3"
           [class.inactive]="this.form.controls['draftDirection_DraftDirectionId'].value !== 3"></i>
      </a>
      <a (click)="this.form.controls['draftDirection_DraftDirectionId'].setValue(1)">
        <i class="col-xs-3 fa fa-arrow-circle-right"
           [class.active]="this.form.controls['draftDirection_DraftDirectionId'].value === 1"
           [class.inactive]="this.form.controls['draftDirection_DraftDirectionId'].value !== 1"></i>
      </a>

    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-link" (click)="ngbActiveModal.dismiss()" i18n="@@cancel">Cancel</button>
    <button [disabled]="(this.form && !this.form.valid) || (animalIds && animalIds.length === 0)" class="btn btn-primary ml-1" (click)="sendDraft()" i18n="@@draft">Draft</button>
  </div>

</form>

import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
declare let $: any;

@Injectable()
export class SignalRService {

  private conn: any = null;

  // Whenever there is an update from signalR I update this BS.
  // This allows me to listen to mupltiple events without needing multiple subscriptions.
  update = new BehaviorSubject<SignalrMessage>({ type: SignalrType.empty });
  constructor(private authService: AuthService, public apiService: ApiService, private ngZone: NgZone) { }

  public connect(farmId: number): void {
    if (!this.conn && this.authService.currentUser && $.connection.progressBarHub) {
      this.ngZone.runOutsideAngular(() => {
        this.conn = $.connection.hub;
        this.conn.logging = true;

        const progressNotifier = $.connection.progressBarHub,
          self = this;
        progressNotifier.connection.url = environment.apiUrlRoot + '/signalr/progressbarhub';
        // client-side sendMessage function that will be called from the server-side
        progressNotifier.client.sendMessage = function (message, count) {
          self.update.next({ type: SignalrType.progressBar, data: { message: message, count: count } });
        };
        progressNotifier.client.forceRefresh = function () {
          self.update.next({ type: SignalrType.forceRefresh });
        };

        progressNotifier.client.sessionImportAudits = function (sessionImportId: number, sessionId: number, modifiedDate) {
          if (sessionImportId) {
            self.update.next({ type: SignalrType.imports, id: sessionImportId, modifiedDate: new Date(modifiedDate) });
            if (sessionId) {
              self.update.next({ type: SignalrType.sessions, id: sessionId, modifiedDate: new Date(modifiedDate) });
            }
          }
        };


        progressNotifier.client.bulkSessions = function (sessionIds: Array<number>, modifiedDate) {
          self.update.next({ type: SignalrType.sessions, ids: sessionIds, modifiedDate: new Date(modifiedDate) });
        };

        progressNotifier.client.sessionsDelete = function (sessionId: number, modifiedDate) {
          self.update.next({ type: SignalrType.sessionsDelete, id: sessionId, modifiedDate: new Date(modifiedDate) });
        };

        progressNotifier.client.events = function (eventId: number, modifiedDate) {
          self.update.next({ type: SignalrType.events, id: eventId, modifiedDate: new Date(modifiedDate) });
        };
        progressNotifier.client.bulkEvents = function (eventIds: Array<number>, modifiedDate) {
          self.update.next({ type: SignalrType.events, ids: eventIds, modifiedDate: new Date(modifiedDate) });
        };


        progressNotifier.client.bulkGroups = function (groupIds: Array<number>, modifiedDate) {
          if (groupIds && groupIds.length > 0) {
            self.update.next({ type: SignalrType.groups, ids: groupIds, modifiedDate: new Date(modifiedDate) });
          }
        };

        progressNotifier.client.groupsDelete = function (groupId: number, modifiedDate) {
          if (!isNaN(groupId)) {
            self.update.next({ type: SignalrType.groupsDelete, id: groupId, modifiedDate: new Date(modifiedDate) });
          }
        };

        progressNotifier.client.animals = function (animalId: number, modifiedDate) {
          self.update.next({ type: SignalrType.animals, id: animalId, modifiedDate: new Date(modifiedDate) });
        };
        progressNotifier.client.bulkAnimals = function (animalIds: Array<number>, modifiedDate) {
          self.update.next({ type: SignalrType.animals, ids: animalIds, modifiedDate: new Date(modifiedDate) });
        };

        if (!this.conn) {
          return;
        }

        this.conn.qs = { userId: this.authService.currentUser.userId, farmId: farmId };
        this.conn.start({ jsonp: false }, function (e) {
          // console.log('initial start signalR');
        });

        this.conn.disconnected(function () {
          if (this.apiService) {
            let wl = window.location.toString(), k = wl.indexOf('.'),
            env = window.location.toString().substring(0, k).replace('http://', '').replace('https://', '');
            this.apiService.post('/Api/Error/Log'
              , {
                  msg: 'SignalR disconnection detected'
                  , windowurl: window.location.toString()
                  , jserrorurl: document.location.href
                  , farmId: this.mainService && this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
                  , farmName: this.mainService && this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmName : null
                  , error: 'SignalR disconnection detected'
                  , userid: this.authService && this.authService.currentUser ? this.authService.currentUser.userId : null
                  , username: this.authService && this.authService.currentUser ? this.authService.currentUser.email : null
                  , environment: env
                  , jwtpayload: localStorage.getItem('access-token') || ''
              }).subscribe();
            if (window['ga']) {
                if ((<any>window).ga) {
                    (<any>window).ga('send', 'event', 'Error', window.location.href, null, { error: 'SignalR disconnection detected' });
                }
            }
          }
          if (this.conn !== null && this.conn !== undefined) {
            setTimeout(function () {
              this.conn.start({ jsonp: false }, function (e) {
                // console.log('restart signalR');
                if (this.apiService) {
                  let wl = window.location.toString(), k = wl.indexOf('.'),
                  env = window.location.toString().substring(0, k).replace('http://', '').replace('https://', '');
                  this.apiService.post('/Api/Error/Log'
                  , {
                      msg: 'Restarted signalR connection'
                      , windowurl: window.location.toString()
                      , jserrorurl: document.location.href
                      , farmId: this.mainService && this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
                      , farmName: this.mainService && this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmName : null
                      , error: 'Restarted signalR connection'
                      , userid: this.authService && this.authService.currentUser ? this.authService.currentUser.userId : null
                      , username: this.authService && this.authService.currentUser ? this.authService.currentUser.email : null
                      , environment: env
                      , jwtpayload: localStorage.getItem('access-token') || ''
                  }).subscribe();
                if (window['ga']) {
                    if ((<any>window).ga) {
                        (<any>window).ga('send', 'event', 'Error', window.location.href, null, { error: 'Restarted signalR connection' });
                    }
                }
              }
            });
            }, 5000);
          }
        });

      });
    } else if (this.authService.currentUser) {
      if (!this.conn) {
        return;
      }

      this.conn.qs = { userId: this.authService.currentUser.userId, farmId: farmId };
      if (this.conn.transport) {
        this.conn.transport.lostConnection(this.conn);
      }
    }
  }
}

// Message object must have a type
// Optional is an ID to update
// And data object wich may conatin any data recieved from SignalR request such as progress bar info
export class SignalrMessage {
  type: SignalrType;
  id?: number;
  ids?: Array<number>;
  data?: any;
  modifiedDate?: Date;
}

export enum SignalrType {
  empty = 0,
  forceRefresh = 1,
  progressBar = 2,
  imports = 3,
  sessions = 4,
  groups = 5,
  events = 6,
  sessionsDelete = 7,
  groupsDelete = 8,
  animals = 9
}

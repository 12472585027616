import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {MainGuard} from '../../main/main-guard.service';
import {ApiService} from '../../main/api/api.service';
import {NotificationMessage} from './bottom-notifications.component';

@Injectable()
export class BottomNotificationsService {
  currentMessage = new BehaviorSubject<NotificationMessage>(null);

  constructor(private apiService: ApiService, private mainGuard: MainGuard) {
    this.apiService.bottomNotificationsService = this;
    this.mainGuard.bottomNotificationsService = this;
  }

  secondLevelNotification(data): void {
    console.error('Message from secondLevelNotification: ', data);
  }
}

import {GroupsService} from '../services/groups.service';
import {SignalRService, SignalrType} from '../api/signal-r.service';
import {Group} from '../models/group.model';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import {SessionService} from '../api/session.service';
import {SessionGroupSummary} from '../models/session-group-summary.model';
import {SessionAction} from '../models/session-action';
import {SessionGroupService} from '../api/session-group.service';
import {MainService} from '../main.service';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription, BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {IntercomService} from '../intercom.service';
import {LocaleService} from '../../helpers/locale.service';
import {PermissionsService} from '../../auth/permissions.service';
import {ApiService, ToAbsoluteDate, ToAbsoluteDateString} from '../api/api.service';
import {SessionRecomendation} from '../models/session-recomendation';
import {Helper} from '../../helpers/instantiate.helper';
import {GroupCluster} from '../models/group-for-cluster.model';
import {map, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  private gettingRecommendations = false;
  private sessionGroupRecomendations: Observable<any>;
  private lastSessionRecommendation: number;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private mainService: MainService) {
  }

  assignAction(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    if (movePriorWeights) {
      this.updateFarmGroupAssignmentProperty(movePriorWeights);
      action.movePriorWeights = movePriorWeights;
    }
    
    this._setPostData(action);
    return this.apiService.post(`/odata/Actions`, action, 'text');
  }

  reAssignAction(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    if (movePriorWeights) {
      this.updateFarmGroupAssignmentProperty(movePriorWeights);
      action.movePriorWeights = movePriorWeights;
    }
    this._setPostData(action);
    return this.apiService.patch(`/odata/Actions('${action.v_ActionId}')`, action, 'text');
  }

  assignOrReassignSingleAnimal(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    if (movePriorWeights) {
      this.updateFarmGroupAssignmentProperty(movePriorWeights);
    }
    let animalId = action.weightRecords[0].animal_AnimalId,
      groupId = action.groupId,
      sessionId = action.weightRecords[0].session_SessionId;

    return this.apiService.post(`/odata/Actions/Services.AssignOrReassignSingleAnimal`, {
      groupId: groupId,
      sessionId: sessionId,
      animalId: animalId,
      movePriorWeights: movePriorWeights
    }, 'text');
  }

  assignSingleAnimal(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    // leaving as wrappers here so that naming is more obvious, and it is easier to vary functionality in the future
    // but at present we don't have dupliucated code
    return this.assignOrReassignSingleAnimal(action, movePriorWeights);
  }

  reAssignSingleAnimal(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    // leaving as wrappers here so that naming is more obvious, and it is easier to vary functionality in the future
    // but at present we don't have dupliucated code
    return this.assignOrReassignSingleAnimal(action, movePriorWeights);
  }

  updateFarmGroupAssignmentProperty(mpw: boolean) {
    // this will not trigger a signalR update etc to other users on this farm
    // however, doing a full call to .updateFarm() causes modal to disappear from forefront of client
    this.mainService.farmChanged.value.groupAssignmentAll = mpw;
  }

  confirmAssignment(sessionId: number, groupId: number): Observable<any> {
    return this.apiService.post(
      `/odata/Sessions/Services.ConfirmAssignment`, {
        sessionId: sessionId,
        groupId: groupId,
        idAnimals: true
      }, 'text');
  }

  ignoreWeightRecords(action: SessionAction, autoGenerated: boolean = false): Observable<boolean> {
    return this.apiService.post(
      `/odata/WeightRecords/Services.IgnoreWeightRecords`, {
        sessionId: action.sessionId,
        groupId: action.groupId,
        idAnimals: action.ided,
        autoGenerated: autoGenerated,
        recommendedGroupId: action.recomendedGroup ? action.recomendedGroup.groupId : null
      });
  }

  SessionGroupRecomendations(farmId: number): Observable<Array<SessionRecomendation>> {
    let lastSessionRecommendation = Date.now();
    this.lastSessionRecommendation = lastSessionRecommendation;
    if (!this.gettingRecommendations) { // using this to prevent multiple calls in parallel
      this.gettingRecommendations = true;
      this.sessionGroupRecomendations = this.apiService.get(`/odata/Sessions/Services.SessionGroupRecomendations(farmId=${farmId},debug=${this.route.snapshot.queryParams['debug'] ? 'true' : 'false'})`)
        .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
      this.gettingRecommendations = false;
      if (this.lastSessionRecommendation !== lastSessionRecommendation) {
        return this.SessionGroupRecomendations(farmId);
      }
      return this.sessionGroupRecomendations;
    }
    return this.sessionGroupRecomendations;
  }

  getMaGroups(farmId: number): Observable<Array<GroupCluster>> {
    return this.apiService.get(`/api/Scheduler/BetaTest?farmId=${farmId}`);
  }

  private _setPostData(action){
    Object.keys(action)
    .filter(key=> key==='weightRecords' ||  key==='entireSession' || key === 'greyOut' || key=== 'groupBy' || key=== 'orderBy' || key==='done')
    .map(key=>{
      delete(action[key])
    })

    action['sessionStartDate'] = ToAbsoluteDateString(action['sessionStartDate']);
  }
}

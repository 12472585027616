import {Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { SelectItem } from 'primeng/components/common/selectitem';
import { WeightRecord } from '../../../models/index';
import { Subscription } from 'rxjs';
import { IActionsModalSplitter } from './i-actions-modal-splitter';
import { ActionsModalService } from '../actions-modal.service';
import { GroupsService } from '../../../services/groups.service';
import { LocaleService } from '../../../../helpers/locale.service';

@Component({
  selector: 'app-actions-modal-splitter',
  templateUrl: './actions-modal-splitter.component.html',
  styleUrls: ['./actions-modal-splitter.component.scss']
})
export class ActionsModalSplitterComponent implements OnInit, OnDestroy {
  @Output()
  public selectedFilterType = new EventEmitter<any>();

  public selectedItem: any;
  public groups: Array<SelectItem>;
  private sub: Subscription;

  private magicSplitNumber = 10; // 10 is a magic number chosen by product

  constructor(public actionsModalService: ActionsModalService, private groupsService: GroupsService
    , public localeService: LocaleService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.actionsModalService.getGroupsWrSplit = null;
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /* awful code. took out reference to it on html. needs complete rewrite*/
  useNumberSplitter() {
    if (this.selectedItem.type !== 0) {
      return false;
    }
    // if it is a number, check how many distinct values there are
    let distinctCount = this.getDistinctValuesCount();
    return distinctCount > this.magicSplitNumber;
  }

  useStringSplitter() {
    if (this.selectedItem.type === 1) {
      return true; // yes for strings
    }
    if (this.selectedItem.type === 2) {
      return false; // no for dates
    }
    if (this.selectedItem.type === 3) {
      return false; // no for times
    }
    // since it is a number, check how many distinct values there are
    let distinctCount = this.getDistinctValuesCount();
    return distinctCount <= this.magicSplitNumber;
  }

  getDistinctValuesCount() {
    let fieldName = this.selectedItem.name;
    let fieldValues = this.actionsModalService.animalData.map(item => item[fieldName]);
    let distinct = [];
    fieldValues.forEach(fv => {
      if ((fv !== null && fv !== undefined) && (distinct[fv] === null || distinct[fv] === undefined)) {
        distinct.push(fv.toString());
      }
    });
    return distinct.length;
  }

  selectedItemChanged(header): void {
    this.selectedFilterType.emit(header);
  }
}

import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Country } from '../models/country.model';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class CountryService {
    private cachedCountries: Array<Country> = [];
    constructor(private authService: AuthService, private apiService: ApiService) { }
    public GetCountries = (): Observable<Array<Country>> => {
        if (this.cachedCountries.length > 0) {
            return of(this.cachedCountries);
        }
        return this.apiService.get('/odata/Countries')
            .pipe(map((res: any) => {
                this.cachedCountries = res.value.sort((a, b) => a.countryName.localeCompare(b.countryName));
                return this.cachedCountries;
            }),
                catchError(this.apiService.handleError));
    }
}
<!--<p-dialog *ngIf="visible && stage === 0 && !skipNewGroupStage" i18n-header="@@choosegroupallcaps" header="CHOOSE GROUP" [draggable]="false"-->
<!--          [responsive]="true" [resizable]="false" [width]="window.innerWidth > 400? 400 : window.innerWidth * 0.9" minWidth="350"-->
<!--          (onHide)="currentGroupId = null; session = null; visible = false; sessionUpdated.next(null);"-->
<!--          [visible]="visible" [modal]="true" class="ui-dialog-show-overflow">-->
<div class="modal-header">
  <h3 i18n="@@choosegroupallcaps">CHOOSE GROUP</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body">

  <div class="mb-3">
    <button class="btn btn-primary w100p" (click)="showError=null; currentGroupId = -1; doneClicked();" [ngClass]="currentGroupId === -1 ? 'btn-primary' : 'btn-outline-primary'" i18n="@@newgroup">
      New Group
    </button>
  </div>

  <h4 i18n="@@existinggroups">Existing groups</h4>

  <div *ngIf="groupsService.groups.value && groupsService.groups.value.length > 0">
    <button *ngFor="let group of groupsService.groups.value"
            [ngClass]="currentGroupId === group.groupId ? 'btn-primary' : 'btn-secondary'"
            (click)="showError=null; currentGroupId = group.groupId;"
            class="btn btn-sm mr-1">{{group.displayName}}</button>
  </div>

</div>

<div class="modal-footer">
  <ng-container *ngIf="!showError">
    <button class="btn btn-link" (click)="currentGroupId = null; session = null; sessionUpdated.next(null); groupChosen.next(null);"
            i18n="@@cancel">Cancel
    </button>
    <button *ngIf="currentGroupId !== null" class="btn btn-primary ml-1" (click)="doneClicked();">
      <ng-container *ngIf="currentGroupId !== null && currentGroupId !== 0" i18n="@@next">Next</ng-container>
      <ng-container *ngIf="currentGroupId === null || currentGroupId === 0" i18n="@@done">Done</ng-container>
    </button>
  </ng-container>

  <div *ngIf="showError" class="alert alert-danger dark alert-dismissable p12 mb-0 lh25 w100p" style="display: table" (click)="showError=null">
    <div style="display: table-cell; vertical-align: middle;" class="pl5">
      <i class="fa fa-exclamation-circle"></i>
    </div>
    <div style="display: table-cell; vertical-align: middle;" class="">
      <strong>
        <ng-container i18n="@@error">Error</ng-container>
        : </strong>
      <ng-container i18n="@@selectneworexistinggrouptocontinue">Select New Group or an Existing Group to continue</ng-container>
    </div>
    <div style="display: table-cell; vertical-align: middle;">
      <button type="button" data-dismiss="alert" style="right:0px;" aria-hidden="true" class="close posr"></button>
    </div>
  </div>
</div>

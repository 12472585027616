import {Component, Input, SimpleChanges, OnChanges} from '@angular/core';
import {Router} from '@angular/router';

import {PermissionsService} from '../../../../auth/permissions.service';
import {Animal, AnimalStatusEnum} from '../../shared/animal.model';
import {AnimalService} from '../../../api/animal.service';
import {LocaleService} from '../../../../helpers/locale.service';
import {IntercomService} from '../../../intercom.service';
import {BottomNotificationsService} from '../../../../components/bottom-notifications/bottom-notifications.service';
import {AuthService} from '../../../../auth/auth.service';
import {MainService} from '../../../main.service';
import {IntegrationService} from '../../../api/integration.service';
import {Subscription} from 'rxjs';
import {WeightRecordService} from '../../../api';
import { ConvertToUTC, DatatypeHelper } from '../../../../helpers/datatype.helper';

@Component({
  selector: 'app-animal-summary-stats',
  templateUrl: './animal-summary-stats.component.html',
  styleUrls: ['./animal-summary-stats.component.scss'],
  providers: []
})
export class AnimalSummaryStatsComponent implements OnChanges {
  @Input() animal: Animal;
  @Input() status = '';

  lastWeight: number;
  animalAdg: number;
  firstSeen: Date;
  lastSeen: Date;

  summariesSub: Subscription = null;
  loading: boolean;

  constructor(
    private localeService: LocaleService,
    public permissionsService: PermissionsService,
    private animalService: AnimalService,
    private bottomNotificationsService: BottomNotificationsService,
    private intercomService: IntercomService,
    public router: Router,
    private authService: AuthService,
    private mainService: MainService,
    private integrationService: IntegrationService,
    private weightRecordService: WeightRecordService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['animal'] && changes['animal'].currentValue)) {
      if (this.animal && this.animal.weightRecords && this.animal.weightRecords.length > 0) {
        this.updateDatesAndStatus();

        const wrsWithWeights = this.animal.weightRecords !== null
          ? this.animal.weightRecords.slice().filter(w => w.weight).sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime())
          : [];

        this.lastWeight = wrsWithWeights.length > 0 ? wrsWithWeights[wrsWithWeights.length - 1].weight : null;

        if (wrsWithWeights.length > 1) {
          // const prevWeight = wrs.find(a => a.timeStamp !== wrs[])
          let prevWeight = null;
          for (let i = wrsWithWeights.length - 2; i >= 0; i--) {
            // for ppl we use custom logic to get adg // find next timestamp thats 3 weeks ish (atleast) away
            // 1728000000 is 20 days in ms
            if (this.integrationService.integration.value && this.integrationService.integration.value.integrationName === 'PPL'
              && new Date(wrsWithWeights[wrsWithWeights.length - 1].timeStamp).getTime() - new Date(wrsWithWeights[i].timeStamp).getTime() >= 1728000000) {
              prevWeight = wrsWithWeights[i];
              break;
            } else if ((!this.integrationService.integration.value || this.integrationService.integration.value.integrationName !== 'PPL')
              && wrsWithWeights[i].timeStamp !== wrsWithWeights[wrsWithWeights.length - 1].timeStamp) {
              prevWeight = wrsWithWeights[i];
              break;
            }
          }
          if (prevWeight) {
            this.animalAdg = Math.round((wrsWithWeights[wrsWithWeights.length - 1].weight - prevWeight.weight) /
              ((new Date(wrsWithWeights[wrsWithWeights.length - 1].timeStamp).getTime() - new Date(prevWeight.timeStamp).getTime()) / (1000 * 60 * 60 * 24))
              * 100) / 100;
          }
        }
      } else {
        this.lastSeen = null;
        this.firstSeen = null;
        this.animalAdg = null;
        this.lastWeight = null;
        this.status = '';
      }
    }
  }

  dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'D MMM YYYY');
  }

  updateDatesAndStatus() {
    if (this.summariesSub) {
      this.summariesSub.unsubscribe();
    }

    this.loading = true;
    this.summariesSub = this.weightRecordService.GetAnimalsWeightSummaries(this.mainService.farmChanged.value.farmId).subscribe(awgs => {
      this.loading = false;
      if (awgs !== null && awgs !== undefined) {
        let anim = awgs[this.animal.animalId];
        if (anim && anim.lastSeen) {
          this.lastSeen = ConvertToUTC(anim.lastSeen).toDate()
          let status = anim.status;
          if (status === AnimalStatusEnum.sold) {
            this.status = this.localeService.constants.stringSold;
          } else if (status === AnimalStatusEnum.dead) {
            this.status = this.localeService.constants.stringDied;
          } else {
            this.status = this.localeService.constants.stringCurrent;
          }
        }
      }
      if (this.animal.pplLastSeen !== null) {
        this.lastSeen = ConvertToUTC(this.animal.pplLastSeen).toDate();
      }
    }, err => {
      this.loading = false;
    });
  }
}

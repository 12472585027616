import { Month } from '../../helpers/Month';
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, HostListener, SimpleChange, ViewChild } from '@angular/core';
import { LocaleService } from '../../helpers/locale.service';
import { DatatypeHelper, ConvertToUTC } from '../../helpers/datatype.helper';
import * as moment from 'moment';


@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {
  visible = false;
  selectedYear: number = new Date().getFullYear();
  selectedMonth;
  selectedDay = 1; // day of the month. If more then current month (eg 31 days in 28 day month) then choose last

  @Input()
  displayDate: Date = new Date();

  @Input()
  startDate: Date;

  @Input()
  endDate: Date;

  @Input()
  minDate: Date;
  months: Array<Month> = [];

  @Input()
  width = '150px';

  @Input()
  invalid = false;

  @Input()
  ignoreEndDate = false;


  @Output()
  dateChange = new EventEmitter<Date>();

  hasChanged = false;

  @ViewChild('theDatePicker', { static: false }) theDatePicker;

  constructor(private monthPickerEl: ElementRef, public localeService: LocaleService) {
    this.selectedMonth = localeService.month[new Date().getMonth()];
  }

  @HostListener('window:click', ['$event.target'])
  windowClicked(clickedEl) {
    if (!this.monthPickerEl.nativeElement.contains(clickedEl)) {
      this.visible = false;
    }
  }

  getDate(monthId: number):moment.Moment {
    return ConvertToUTC([2017, monthId, 1, 0, 0, 0]);
  }

  yearChanged() {
    this.months = [];
    this.localeService.month.forEach(m => this.months.push(Object.assign({}, m)));
    if (this.minDate) {
      for (let i = 0; i < this.months.length; i++) {
        if (this.minDate.getUTCMonth() > i && this.selectedYear === this.minDate.getUTCFullYear()) {
          this.months[i].disabled = true;
        } else if (this.selectedYear < this.minDate.getUTCFullYear()) {
          this.months[i].disabled = true;
        }
      }
    }
  }

  monthString(date: moment.Moment) {
    return date.format('MMM');
  }

  dayMonthYearString(date: Date) {
    return moment.utc(date).format('D MMMM YYYY')
  }

  updateCurSlectorDateOnOpen() {
    const selectedDate =  ConvertToUTC(this.displayDate);
    this.selectedYear = selectedDate.year();
    this.selectedMonth = this.localeService.month[selectedDate.month()];
    this.selectedDay = 1;
    // this.unfocusInput();
  }

  textEdited(text: string) {
    let texts = text.toLowerCase().split(' ');
    if (texts.length === 3) { // valid input date must have day month year (month in selected language)
      for (let i = 0, l = this.localeService.month.length; i < l; i++) {
        if (!isNaN(<any>texts[0]) && parseInt(texts[0], 10) > 0 && parseInt(texts[0], 10) < 32 // check that date is valid
          && !isNaN(<any>texts[2]) && parseInt(texts[2], 10) > 1990 && parseInt(texts[2], 10) < 2100 // check that year is valid
          && (texts[1] === this.localeService.month[i].month.toLowerCase() || texts[1] === this.localeService.month[i].fullMonth.toLowerCase())) { // check month
          this.selectedYear = parseInt(texts[2], 10);
          this.selectedDay = parseInt(texts[0], 10);
          this.monthChange(i);
          return;
        }
      }
    };
    this.monthChange(this.selectedMonth.id);
  }

  monthChange(newMonth: number) {
    this.hasChanged = true;
    this.selectedMonth = this.localeService.month[newMonth];
    let d = ConvertToUTC([this.selectedYear, newMonth , this.selectedDay, 0, 0, 0,0]);
    this.displayDate = d.toDate();
   
    this.dateChange.emit(this.displayDate);
    this.visible = false;
    this.unfocusInput();
  }

  unfocusInput() {
    if (this.theDatePicker !== null && this.theDatePicker !== undefined) {
      this.theDatePicker.nativeElement.blur();
    }
  }

  ngOnInit() {
    this.selectedMonth = this.localeService.month[this.displayDate.getMonth()];
    this.selectedYear = this.displayDate.getFullYear();
    this.hasChanged = false;
    // this.visible = true;
    this.unfocusInput();
    this.yearChanged();
  }
}

import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {SubscriptionService} from '../../api/subscription.service';
import {UserSubscription} from '../../models/user-subscription.model';
import {AuthService} from '../../../auth/auth.service';
import {Component, OnInit, Input, OnDestroy, LOCALE_ID, Injectable, Inject} from '@angular/core';
import {Subscription} from 'rxjs';
import {FarmCreationComponent} from '../../manage/farm-creation/farm-creation.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AddDeviceComponent} from '../add-device/add-device.component';
import { IntercomService } from '../../intercom.service';

@Component({
  selector: 'app-subscriptions-info',
  templateUrl: './subscriptions-info.component.html',
  styleUrls: ['./subscriptions-info.component.scss']
})
export class SubscriptionsInfoComponent implements OnInit, OnDestroy {
  subscriptionsPlanId: number;
  subscriptionId: number;
  locale: string;

  private sub: Subscription;

  constructor(public authService: AuthService,
              private subscriptionService: SubscriptionService,
              private bottomNotificationsService: BottomNotificationsService,
              private ngbModal: NgbModal,
              public intercomService: IntercomService,
              @Inject(LOCALE_ID) locale: string) {
    if (!locale) {
      locale = 'en';
    }
    this.locale = locale.substr(0, 2);
  }

  ngOnInit() {
    this.sub = this.authService.currentSubscription.subscribe(sub => {
      this.subscriptionsPlanId = sub ? sub.subscriptionPlan_SubscriptionPlanId : null;
      this.subscriptionId = sub ? sub.subscriptionId : null;
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  createNewFarm(): void {
    const modalRef = this.ngbModal.open(FarmCreationComponent, {
      backdrop: 'static',
      windowClass: 'modal-right'
    });

    modalRef.result.then(() => {

    }, () => {});
  }

  openAddDeviceModal(): void {
    const modalRef: NgbModalRef = this.ngbModal.open(AddDeviceComponent, {});

    modalRef.componentInstance.subscriptionId = this.subscriptionId;
  }
}

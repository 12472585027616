import { EventAuditService } from '../api/event-audit.service';
import { EventAudit } from '../models/event-audit';
import { AuthService } from '../../auth/auth.service';
import { SessionGroupSummary } from '../models/session-group-summary.model';
import { Session } from '../models/session.model';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { DatatypeHelper } from '../../helpers/datatype.helper';




import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment.test';

@Injectable()
export class DashboardEducationService {
    public eventsE = false;
    public groupsE = false;
    public sessionsE = false;
    public actionsE = false;

    constructor(private http: HttpClient, private authService: AuthService, private apiService: ApiService, private eventAuditService: EventAuditService) {
    }

    async getEventData(): Promise<Array<EventAudit>> {
        this.eventsE = true;
        let res = null;
        const headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        await this.http.get('./assets/json/dashboard-education-events.json',
            { headers: headers }).toPromise().then((e: any) => {
                res = e.value;
                res.forEach(event => {
                    const template = this.eventAuditService.eventAuditTemplate[event.eventType];
                    if (template) {
                        event.titleText = template.titleTemplate;
                        event.messageText = template.messageTemplate;
                        let data = JSON.parse(event.dataJson);
                        for (let prop in data) {
                            if (data.hasOwnProperty(prop)) {
                                data[prop] = DatatypeHelper.tryFormatDateWithMoment(data[prop], 'D MMM YYYY');
                                event.messageText = event.messageText.replace(`<${prop}/>`, data[prop]);
                                event.titleText = event.titleText.replace(`<${prop}/>`, data[prop]);
                            }
                        }
                    }
                });
            });
        return res;
    }

    async getSessionData(): Promise<Session> {
        this.sessionsE = true;
        let res = null;
        const headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        await this.http.get('./assets/json/dashboard-education-session.json').toPromise().then(e => {
            res = e;
        });
        return res;
    }

    async getGroupData(): Promise<Array<any>> {
        this.groupsE = true;
        let res = null;
        const headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        await this.http.get('./assets/json/dashboard-education-groups.json').toPromise().then((e: any) => {
            res = e.value;
        });
        return res;
    }

    async getActionsData(): Promise<Array<SessionGroupSummary>> {
        this.actionsE = true;
        let res = null;
        const headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + this.authService.getToken());
        await this.http.get('./assets/json/dashboard-education-actions.json').toPromise().then(e => {
            res = e;
        });
        return res;
    }

}

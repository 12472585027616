<div class="card card-widget">

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0" i18n="@@animalweight">Animal Weight</h3>
  </div>

  <!-- widget body/content -->
  <div class="card-body">
    <div style="width: 100%; height: 100%; overflow: hidden;" [appHighcharts]="options"></div>
  </div>

</div>

<app-loading *ngIf="!finishedLoading"></app-loading>

<div *ngIf="finishedLoading" class="p20">
  <div class="col-xs-12 col-sm-6">
    <button type="button" (click)="displayModal()">Add IP Range</button>
  </div>
</div>

<div *ngIf="finishedLoading">
  <p-table [value]="ips" rowHover="true" class="no-overflow" #dataTable tableStyleClass="table table-hover">

    <!-- header -->
    <ng-template pTemplate="header">
      <tr class="ui-widget-content">
        <th class="ui-state-default">IP Name</th>
        <th class="ui-state-default">Start IP Address</th>
        <th class="ui-state-default">End IP Address</th>
        <th class="ui-state-default"></th>
        <th class="ui-state-default"></th>
      </tr>
    </ng-template>

    <!-- rows body -->
    <ng-template pTemplate="body" let-rowData let-expanded="expanded">
      <tr class="ui-widget-content">
        <td>{{ rowData.ipName }}</td>
        <td>{{ rowData.startIpAddress }}</td>
        <td>{{ rowData.endIpAddress }}</td>
        <td>
          <button type="button" (click)="displayModal(rowData)">Edit IP Range</button>
        </td>
        <td>
          <i (click)="deleteIpAddressRange(rowData.whitelistIpsId)" style="color:red;" class="fa fa-trash-o fa-lg" aria-hidden="true "></i>
        </td>
      </tr>
    </ng-template>

  </p-table>

</div>


<p-dialog [modal]="true" class="ui-dialog-show-overflow" [responsive]="true" [resizable]="false" [width]="window.innerWidth > 600? 600 : window.innerWidth"
  minWidth="350" header="{{modalHeader}}" [draggable]="false" [(visible)]="showModal" (onHide)="hideDialog()">
  <div class=" pb25">
      <form *ngIf="form" class="mb-0" [formGroup]="form">
          <app-form-input hidden="true" >
              <input type="text" formControlName="ipId" class="form-control">
          </app-form-input>
          <app-form-input [showError]="!form.controls['startIpAddress'].valid && form.controls['startIpAddress'].dirty" error="Please enter a valid starting IP address">
              <input type="text" #startIpAddressInput placeholder="Starting IP address" formControlName="startIpAddress" class="form-control" (blur)="prepopulateEndIp()" />
          </app-form-input>
          <app-form-input [showError]="!form.controls['endIpAddress'].valid && form.controls['endIpAddress'].dirty"  error="Please enter a valid ending IP address">
              <input type="text" #endIpAddressInput placeholder="End IP address" formControlName="endIpAddress" class="form-control" />
          </app-form-input>
          <app-form-input [showError]="!form.controls['ipName'].valid && form.controls['ipName'].dirty"  error="Please enter a name for this IP range">
              <input type="text" #ipNameInput placeholder="IP range name" formControlName="ipName" class="form-control" />
          </app-form-input>
      </form>
  </div>

  <p-footer>
      <div class="card-footer">
          <button class="btn btn-primary pull-left" (click)="cancelClicked()" i18n="@@cancel">Cancel</button>
          <button class="btn btn-primary pull-right" (click)="submitForm()">Submit</button>
      </div>
  </p-footer>
</p-dialog>

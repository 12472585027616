import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';

import {PermissionsService} from '../../../auth/permissions.service';
import {Group} from '../../models/group.model';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {GroupsService} from '../../services/groups.service';
import {GroupActionsEnum} from '../group-actions.enum';
import {Subscription} from 'rxjs';
import {WeightRecordService} from '../../api';
import {Location} from '@angular/common';

@Component({
  templateUrl: './group-info.component.html'
})
export class GroupInfoComponent implements OnInit, OnDestroy {
  group: Group;
  tabDestroyOnHide: boolean;
  adgAnimalCount: number;

  activeTabOnLoad: string;
  sub: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private globalParameterService: GlobalParameterService,
              private permissionsService: PermissionsService,
              private groupsService: GroupsService,
              public zone: NgZone,
              private weightRecordService: WeightRecordService,
              private location: Location) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tabDestroyOnHide = true;
      this.group = null;
      this.globalParameterService.detailsSearchParams.next({});

      const groupId = +params['id'] || +this.globalParameterService.currentGroupId;
      this.globalParameterService.currentGroupId = groupId;
      const tab = this.activatedRoute.snapshot.queryParams['tab'];

      if (tab) {
        this.activeTabOnLoad = tab;
      } else {
        this.activeTabOnLoad = 'summary';
      }

      this.sub = this.groupsService.groups.subscribe(groups => {
        if (groups && groups.length > 0) {
          setTimeout(() => {
            this.zone.run(() => {
              this.group = groups.find(g => g.groupId === groupId);

              if (this.group) {
                this.adgAnimalCount = this.group.censuses.length > 0 ? this.group.censuses[0].animalCount : null;
              }
            });
          });
        } else {
          this.router.navigate(['/main/groups/all']);
        }
      });
    });

    let currPath = this.location.path();
    if (currPath.includes('/summary')) {
      let updatedUrl = currPath.replace('/summary', '/details');
      this.location.go(updatedUrl); // rewrites url without reloading component etc
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  groupActionDone(action: GroupActionsEnum): void {
    switch (action) {
      case GroupActionsEnum.DELETED:
        this.router.navigate(['/main/groups/all']);
        break;
      case GroupActionsEnum.EDITED:
        // this.router.navigate(['/main/groups/all']);
        break;
      case GroupActionsEnum.NOT_EDITED:
      case GroupActionsEnum.NOT_DELETED:
      case GroupActionsEnum.SHARED:
      case GroupActionsEnum.NOT_SHARED:
      default:
      // do nothing
    }
  }

  // TODO: do not load all tabs content on load; but once loaded - do not load again?
  tabChange(tabChange: NgbTabChangeEvent): void {
    let currPath = this.location.path();
    let newTab = tabChange.nextId;
    let currTab = tabChange.activeId;
    let updatedUrl = currPath.includes('tab=' + currTab)
      ? currPath.replace('tab=' + currTab, 'tab=' + newTab)
      : currPath.includes('tab=' + newTab)
        ? currPath
        : currPath + '&tab=' + newTab;
    this.location.go(updatedUrl); // rewrites url without reloading component etc
    if (this.tabDestroyOnHide) {
      // this.tabDestroyOnHide = false;
    }
  }
}

<section id="main" class="external-page-content">

  <div class="form-holder">

    <div class="card card-external">

      <div class="card-body">

        <!-- logo -->
        <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

        <!-- content -->
        <div class="mt-2 pb-5" id="cookiePolicyHolder"></div>

        <div class="text-center mb-2">
          <button routerLink="/privacy-policy" class="btn btn-link mx-1" i18n="@@privacypolicy">Privacy Policy</button>
          <button routerLink="/terms-and-conditions" class="btn btn-link mx-1" i18n="@@cookiepolicy">Terms and Conditions</button>
        </div>

      </div>
    </div>
  </div>
</section>

import {BottomNotificationsService} from '../components/bottom-notifications/bottom-notifications.service';
import {FarmUser} from './models/farm-user.model';
import {MainGuard} from './main-guard.service';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';

import {SignalRService} from './api/signal-r.service';
import {UserService} from './api/user.service';
import {AuthService} from '../auth/auth.service';
import {FarmService} from './api/farm.service';
import {Farm} from './models/farm.model';
import {Injectable, OnDestroy, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {LocaleService} from '../helpers/locale.service';
import {IMainService} from '../mocks/interfaces/i-main-service';
import {of, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {mobileBreakpoint} from '../helpers/constants';
import { isDate } from 'util';

export interface LeftMenuOpenStatus {
  forced: boolean;
  value: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MainService implements IMainService {
  farmChanged = new BehaviorSubject<Farm>(null);
  leftMenuOpen: EventEmitter<LeftMenuOpenStatus> = new EventEmitter<LeftMenuOpenStatus>();
  farmsChanged = new BehaviorSubject<Array<Farm>>(null);
  canCreateFarms = false; // Set this to true if use does not have a sub or has avaliable farm slots in sub

  farmUsers: Array<FarmUser>;
  farmId: number;

  localeOffset = '';
  localeString = '';

  animalsWeightSummariesCached: { [key: number]: any } = null;

  mainCustomCssClasses: string[];

  actionDate: string | Date = new Date();

  usernameValidationRegex = /^([a-zA-Z]{2,})|([a-zA-Z][a-zA-Z\- ']+[a-zA-Z])$/;

  constructor(private farmService: FarmService,
              private authService: AuthService,
              private mainGuard: MainGuard,
              private bottomNotificationsService: BottomNotificationsService,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private signalRService: SignalRService,
              private localeService: LocaleService) {

  }

  getFarmForSuperUser() {
    this.farmService.getFarm(this.authService.currentUser.currentFarm_FarmId).then(farm => {
      this.farmChanged.next(farm);
      this.updateFarms();
    });
  }

  updateFarms(callback = null) {
    this.farmService.getUsersFarms().subscribe(farms => {
      const self = this;
      if (farms && farms.length > 0) {
        farms = Array.from(new Set(farms)); // uniquify the list of Farms
      }
      if (farms && farms.length === 0) {
        this.mainGuard.hasFarms = false;
        this.canCreateFarms = true;
        this.router.navigate(['/main/subscriptions']);
      }

      if (self.authService.currentUser && farms && farms.length > 0 && farms.find(f => f.farmId === self.authService.currentUser.currentFarm_FarmId)) {
        this.farmService.getFarm(self.authService.currentUser.currentFarm_FarmId).then(farm => {
          if ((self.authService.currentUser && self.authService.currentUser.userType) !== 'SuperUser') {
            // superusers will already have current farm loaded, don't want to refresh data again
            this.farmChanged.next(farm);
          }
          this.farmsChanged.next(null);
          this.farmsChanged.next(farms);
        });
        // const farm = farms.filter(f => f.farmId === self.authService.currentUser.currentFarm_FarmId)[0] || null;
      } else {
        this.farmChanged.next(null);
        this.farmsChanged.next([]);
      }
      if (callback) {
        callback();
      }
    });
  }

  updateFarm(farm: Farm) {
    const farmIndex = this.farmsChanged.value.findIndex(f => f.farmId === farm.farmId);
    this.farmsChanged.value[farmIndex] = farm;
    this.farmChanged.next(farm);

    // force update farm in farm service
    if (farm.farmId) {
      this.farmService.getFarm(farm.farmId, true);
    }
  }

  addNewFarm(farm: Farm) {
    this.authService.addNewFarmToCurrentUser(farm);
    this.farmsChanged.value.push(farm);
    this.farmChanged.next(farm);
    let fs = Array.from(new Set(this.farmsChanged.value));
    this.farmsChanged.value.slice(0, this.farmsChanged.value.length);
    fs.forEach(f => this.farmsChanged.value.push(f));
    this.farmsChanged.next(this.farmsChanged.value.sort((a, b) => a.farmName.localeCompare(b.farmName)));
  }

  changeFarm(newFarmId, callback = null, force = false) {
    let oldFarm = this.farmChanged.value;
    this.farmChanged.next(null);
    let superAdmin = this.authService.currentUser && this.authService.currentUser.userType === 'SuperUser';
    this.farmService.getFarm(newFarmId, force).then(newFarm => {
      if (newFarm && newFarm.subscriptionDaysLeft <= 0 && (!this.authService.currentSubscription.value
        || newFarm.subscription_SubscriptionId !== this.authService.currentSubscription.value.subscriptionId)
        && !superAdmin) {
        this.farmChanged.next(oldFarm);
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringFarmsSubscriptionHasExpired,
          message: (<string>this.localeService.constants.stringFarmOwnerNeedsToUpgradeTheirSubscriptionBeforeThisFarmCanBeAccessed)
            .replace('<farmOwner/>', newFarm.farmOwner),
          type: 'error'
        });
        this.router.navigate(['/main']);
      } else if (newFarm) {
        this.userService.ChangeUserCurrentFarm(newFarmId).subscribe(user => {
          this.authService.currentUser = user;
          // this.farmsChanged.value.push(newFarm);
          this.farmChanged.next(newFarm);
          if (!superAdmin) {
            if (this.authService.currentUser.userFarms.find(uf => newFarm.farmId === uf.farmId).farmRoleId === 3) {
              this.router.navigate(['/main/groups/all']);
            }
            if (newFarm.subscriptionDaysLeft <= 0) {
              if (this.authService.currentUser.userFarms.find(uf => newFarm.farmId === uf.farmId).farmRoleId === 2) {
                this.router.navigate(['/main']);
              } else {
                this.router.navigate(['/main/subscriptions']);
              }
            } else {
              if (window.location.href.indexOf('groups/details') !== -1) {
                this.router.navigate(['/main/groups/all']);
              } else  if (window.location.href.indexOf('sessions/details') !== -1) {
                this.router.navigate(['/main/sessions/all']);
              }
            }
          }
          if (document.body) {
            document.body.scrollTop = 0;
          }
          if (callback) {
            callback();
          }
        });
      }
    });
  }

  removeUser(user: FarmUser) {
    if (this.farmUsers) {
      const index = this.farmUsers.findIndex(uf => uf.userId === user.userId);
      if (index >= 0) {
        this.farmUsers.splice(index, 1);
      }
    }
  }

  getUsers(): Observable<Array<FarmUser>> {
    if (this.farmChanged.value) {
      return this.farmService.getUsersForFarm(this.farmChanged.value.farmId).pipe(map(users => {
        this.farmUsers = users.sort((a, b) => {
          let r = 0;
          if (a && b && a.lastName && b.lastName) {
            r = a.lastName.localeCompare(b.lastName);
          }
          if (r === 0 && a && b && a.firstName && b.firstName) {
            r = a.firstName.localeCompare(b.firstName);
          }
          if (a && b && a.farmRoleId && b.farmRoleId) {
            r = a.farmRoleId - b.farmRoleId;
          }
          return r;
        });
        return this.farmUsers;
      }));
    } else {
      of(null);
    }
  }

  setMomentLocale() {
    if (this.farmChanged.value) {
      let country = this.farmChanged.value.countryLookup.countryName;
      let loc = this.localeService.locale;
      // https://en.wikipedia.org/wiki/Date_format_by_country
      let isUsDateFormat = ['United States', 'American Samoa', 'Micronesia, Federated States Of', 'Guam', 'Japan',
        'Korea, Republic Of', 'Marshall Islands', 'Northern Mariana Islands', 'Virgin Islands, U.S.'].findIndex(i => i === country) >= 0;
      if (isUsDateFormat) {
        this.localeString = 'en-us';
      } else if (loc === 'pt' || loc === 'fr' || loc === 'de' || loc === 'es') {
        this.localeString = loc;
      } else if (loc === 'en') { // leaving this explicit check in case we add other cases in the future
        this.localeString = 'en-gb'; // let's be generic for english
      } else {
        this.localeString = 'en-gb'; // default to Great Britain, as per above comment
      }

      if (this.localeString === 'pt') { // we want localestring to still be just 'pt', but moment needs it to be explicitly Brazilian variant of PT
        moment.locale('pt-br');
      } else {
        moment.locale(this.localeString);
      }

      this.localeOffset = moment(new Date()).format('ZZ');
    }
  }

  setDate(date?: any): Date {
    if (!date) {
      const dateObject = moment().toObject();
      return new Date(Date.UTC(dateObject.years, dateObject.months, dateObject.date
           , 0, 0, 0,0));
    } else {
      if(isDate(date)){
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()
          , 0, 0, 0,0));
        //return date;
      }
      //New date() produces timezone offset date, use moment to parse to avoid timezone offset
      return moment.parseZone(date).toDate();
    }
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}

<!-- loading animation -->
<div *ngIf="loading">
  <app-loading [local]="true"></app-loading>
</div>

<!-- numbers -->
<div class="card card-summary-header" *ngIf="!loading">
  <div class="card-body">
    <div>
      <h2 [appNumberDisplay]="{number: groupAnimCount.animalCount}"></h2>
      <p class="text-shady-lady mb-0" i18n="@@groupedanimals">Grouped Animals</p>
    </div>
    <div>
      <h2 [appNumberDisplay]="{number: groupAnimCount.groupCount}"></h2>
      <p class="text-shady-lady mb-0">{{groupAnimCount.groupCount != 1 ? this.localeService.constants.stringGroups : this.localeService.constants.stringGroup}}</p>
    </div>
    <div *ngIf="groupAnimCount.beefCount">
      <h2 [appNumberDisplay]="{number: groupAnimCount.beefCount}"></h2>
        <p class="text-shady-lady mb-0" i18n="@@beef">Beef</p>
    </div>
    <div *ngIf="groupAnimCount.dairyCount">
      <h2 [appNumberDisplay]="{number: groupAnimCount.dairyCount}"></h2>
        <p class="text-shady-lady mb-0" i18n="@@dairy">Dairy</p>
    </div>
    <div *ngIf="groupAnimCount.sheepCount">
      <h2 [appNumberDisplay]="{number: groupAnimCount.sheepCount}"></h2>
        <p class="text-shady-lady mb-0" i18n="@@sheep">Sheep</p>
    </div>
    <div *ngIf="groupAnimCount.deerCount">
      <h2 [appNumberDisplay]="{number: groupAnimCount.deerCount}"></h2>
        <p class="text-shady-lady mb-0" i18n="@@deer">Deer</p>
    </div>
    <div *ngIf="groupAnimCount.otherCount">
      <h2 [appNumberDisplay]="{number: groupAnimCount.otherCount}"></h2>
        <p class="text-shady-lady mb-0" i18n="@@other">Other</p>
    </div>
  </div>
</div>

import {UserFarm} from '../../models/user-farm';
import {Component, OnInit} from '@angular/core';
import {Validators, FormGroup, FormControl} from '@angular/forms';
import {FormValidation} from '../../../helpers/form-validation.helper';
import {AdminService} from '../../api/admin.service';
import {UserService} from '../../api/user.service';
import {AuthService} from '../../../auth/auth.service';
import {User} from '../../models/user.model';
import {SelectItem} from 'primeng/components/common/selectitem';
import {FarmService} from '../../api/farm.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {MainService} from '../../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../settings/settings.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AdminResetPasswordComponent} from './admin-reset-password/admin-reset-password.component';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
  providers: [AdminService, SettingsService]
})
export class AdminUsersComponent implements OnInit {
  users: Array<User>;
  allUsers: Array<User>;
  dialogVisible = false;
  userTypes: Array<SelectItem> = [{ label: 'Standard', value: 'Standard' }, { label: 'Staff', value: 'Staff' }, { label: 'SuperUser', value: 'SuperUser' }];
  farmsPickerOptions: Array<SelectItem>;
  editedUser: User = null;
  filteredFarmId: number = null;
  filteredUserId: string = null;
  adminFarms: Array<UserFarm>;
  showSuperUsers = false;
  changingToFromSuperUser = false;

  constructor(
    private userService: UserService,
    private farmService: FarmService,
    private bottomNotificationsService: BottomNotificationsService,
    private route: ActivatedRoute,
    private mainService: MainService,
    private authService: AuthService,
    private adminService: AdminService,
    private router: Router,
    private settingsService: SettingsService,
    private ngbModal: NgbModal) {
  }

  ngOnInit() {
    this.filteredFarmId = parseInt(this.route.snapshot.queryParams['farmId'], 10) || 0;
    this.filteredUserId = this.route.snapshot.queryParams['userId'] || '';
    this.getUsers();
    this.mainService.farmsChanged.subscribe(farms => {
      if (farms) {
        this.farmsPickerOptions = farms.map(f => {
          return { value: f.farmId, label: f.farmName }
        });
        this.farmsPickerOptions.splice(0, 0, { label: 'ALL', value: 0 })
      } else {
        this.farmsPickerOptions = null;
      }
    });
  }

  showSuperUsersChanged() {
    if (this.showSuperUsers) {
      this.users = this.allUsers.filter(u => u.email !== 'e2e-test@trutest.co.nz');
    } else {
      this.users = this.allUsers.filter(u => u.userType !== 'SuperUser' && u.email !== 'e2e-test@trutest.co.nz');
    }
  }

  setManualIntercomFlag() {
    this.adminService.setManualIntercomFlag().subscribe(res => {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Forced Intercom Update',
        message: 'Forced Intercom update is set for tonight'
      });
    }, err => {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Forced Intercom Update Failed',
        message: 'Unable to set Forced Intercom update for tonight',
        type: 'warning'
      });
    });
  }

  getUsers() {
    this.userService.getUsers(
      this.filteredFarmId >= 0 ? this.filteredFarmId : null,
      this.filteredUserId.length > 0 ? this.filteredUserId : null
    ).subscribe(users => {
      this.allUsers = users;
      // just filtering out some users that we do not want to be visible here.
      this.users = users.filter(u => u.userType !== 'SuperUser' && u.email !== 'e2e-test@trutest.co.nz');
    });
  }

  impersonateUser(email: string) {
    email = email.trim();
    this.settingsService.getTempPassword(email).subscribe(resPass => {
      this.authService.logoutNoRedirect();
      this.authService.login(email, resPass).subscribe(res => {
        if (res) {
          window.location.reload(true);
        }
      });
    });
  }

  resetPasswordModal(userEmail: string) {
    const modalRef: NgbModalRef = this.ngbModal.open(AdminResetPasswordComponent, {
      backdrop: 'static',
      windowClass: 'modal-right'
    });
    
    const component: AdminResetPasswordComponent = modalRef.componentInstance;

    component.pResetEmail = userEmail;

    modalRef.result.then(() => {

    }, () => {
    });
  }

  changeUserType(changeFarms: boolean) {
    if (changeFarms) {
      const userType = this.editedUser.userType;
      this.editedUser.userFarms.forEach(uf => {
        if (uf.farmRoleId === 1) { // just to be save. i have a filter in odata to return farm role 1 only.
          let farm = { farmId: uf.farmId, farmType: userType === 'Staff' || userType === 'SuperUser' ? 'Test' : 'Standard' };
          this.farmService.updateFarm(farm).subscribe();
        }
      });
    }

    let patched = { userId: this.editedUser.userId, userType: this.editedUser.userType };
    this.userService.patchUser(this.editedUser.userId, patched)
      .subscribe(user => {
        const index = this.users.findIndex(u => u.userId === user.userId);
        if (index >= 0) {
          this.users[index] = user;
          this.users = [...this.users];
          if (!this.changingToFromSuperUser) {
            this.mainService.updateFarms();
          }
        }
        this.editedUser = null;
        this.adminFarms = null;
        this.dialogVisible = false;
        this.changingToFromSuperUser = false;
        this.bottomNotificationsService.currentMessage.next({
          title: 'User updated',
          message: 'Saved changes to user type'
        });
      }, err => {
        this.bottomNotificationsService.currentMessage.next({
          title: 'User update failed',
          message: 'Was unable to save changes to user',
          type: 'warning'
        });
      });
  }

  userTypeChanged(user: User, newType: string) {
    if (user.userType !== newType && newType !== 'SuperUser' && user.userType !== 'SuperUser') {
      this.dialogVisible = true;
      this.userService.getUsersFarms(user).subscribe(updatedUser => {
        this.editedUser = Object.assign({}, updatedUser);
        this.editedUser.userType = newType;
        this.adminFarms = this.editedUser.userFarms.filter(uf => uf.farmRoleId === 1);
      });
    } else {
      this.editedUser = Object.assign({}, user);
      this.editedUser.userType = newType;
      this.changingToFromSuperUser = true;
      this.changeUserType(false);
    }
  }

}

import { AuthService } from '../../auth/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';


@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.currentUser !== null && this.authService.currentUser !== undefined && this.authService.currentUser.userType === 'SuperUser') {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}

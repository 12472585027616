<div class="row no-gutters small-gutters mb-2" *ngFor="let item of selectList; let i = index">

  <div class="custom-control custom-checkbox">

    <input id="{{i}}-sold-dead-checkbox" type="checkbox" [value]="true" [(ngModel)]="this.selectedItems[i]" (change)="selectionChanged()" class="custom-control-input">

    <label for="{{i}}-sold-dead-checkbox" class="custom-control-label">
      <span *ngIf="item.value">{{item.value}}</span>
      <span *ngIf="!item.value" i18n="@@noValue">No Value</span>
      <div>{{item.count}}
        <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{item.weight}}
        <ng-container i18n="abbreviation for average@@avg">Avg</ng-container>
      </div>
    </label>

  </div>

</div>

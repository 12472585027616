<app-loading *ngIf="!sessionSummary"></app-loading>

<ng-container *ngIf="sessionSummary">

  <!-- invalid row in session file -->
  <div class="my-2" *ngIf="sessionSummary.invalidRowCount && sessionSummary.invalidRowCount !== 0">
    <app-session-invalid-row [currentSession]="sessionSummary"></app-session-invalid-row>
  </div>

  <!-- session group assignments -->
  <div class="mb-3" *ngIf="sessionSummary.animalCount > 0">
    <!--    <app-session-group-assignments [sessionActions]="sessionActions" [preAllocatedGroups]="preAllocatedGroups" (assignmentDone)="assignmentDone($event)"></app-session-group-assignments>-->

    <div class="card">
      <div class="card-body">

        <app-loading *ngIf="preAllocatedGroups.length === 0 && sessionActions.length === 0" [local]="true"></app-loading>

        <ng-container *ngIf="preAllocatedGroups.length > 0 || sessionActions.length > 0">

          <!-- unhandled actions -->
          <ng-container *ngFor="let action of sessionActions">
            <div *ngIf="action" class="action-box-container">
              <app-session-group-assignment-box [action]="action" (assignmentDone)="assignmentDone($event)"></app-session-group-assignment-box>
            </div>
          </ng-container>

          <!-- handled actions, animals are already allocated in groups, but user can reallocate them -->
          <ng-container *ngFor="let action of preAllocatedGroups">
            <div *ngIf="action" class="action-box-container">
              <app-session-group-assignment-box [action]="action" (assignmentDone)="assignmentDone($event)"></app-session-group-assignment-box>
            </div>
          </ng-container>

        </ng-container>

      </div>
    </div>

  </div>

  <!-- content -->
  <div class="row">
    <app-session-weight-distribution class="col-md-6" *ngIf="weights" [session]="sessionSummary" [weightRecords]="weights" (changeTab)="changeTab.next($event)"></app-session-weight-distribution>
    <app-sessions-group-by-weight class="col-md-6" *ngIf="weights" [session]="sessionSummary" [weightRecords]="weights" (changeTab)="changeTab.next($event)"></app-sessions-group-by-weight>
  </div>

</ng-container>

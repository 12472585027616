import { Session } from '../models/session.model';
import { MainService } from '../main.service';
import { WeightPlan } from '../models/weight-plan.model';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Animal, WeightRecord } from '../models';
import { Group, BaseGroup } from '../models/group.model';
import { GroupsAnimalCount } from '../models/groups-animal-count.model';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { SessionGroupAutomation } from '../models/session-group-automation.model';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';
import { Helper } from '../../helpers/instantiate.helper';
import { map } from 'rxjs/operators';
import { AnimalSummary } from '../models/animal-adg.model';
import { GroupWeightRecordSplit } from '../models/group-weight-record-split.model';
import { SessionAction } from '../models/session-action';
import { SignalRService, SignalrType } from '../api';
import { SessionService } from '../api/session.service';
import { ApiService } from '../api/api.service';
import { GroupService } from '../api/group.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService implements OnDestroy {
    private curFarmId: number;
    // We get TWP along with all the groups as TWP is quite often reused and its direclty links to group object
    public groups = new BehaviorSubject<Array<Group>>(null);
    private subs: Array<Subscription> = [];

    public static GetGroupDisplayName(groupName: string, twp: WeightPlan) {
        return twp ? `${groupName} (${new Date(twp.startDate).getUTCFullYear()})` : groupName;
    }

    constructor(private authService: AuthService, private signalRService: SignalRService
        , private mainService: MainService, private sessionService: SessionService
        , private groupService: GroupService) {

        this.subs.push(this.signalRService.update.subscribe(msg => {
            // If index is >= 0 then this is an existing group being updated.
            // Only run this when the BS values have been initialised previously.
            let index = this.groups.value ? this.groups.value.findIndex(g => g.groupId === msg.id) : -1;
            if (msg.type === SignalrType.forceRefresh && this.mainService.farmChanged.value.farmId) {
                this.groups.next(null);
                this.getGroups(this.mainService.farmChanged.value.farmId);
            } else if (msg.type === SignalrType.groupsDelete && !isNaN(msg.id) && this.groups.value) {
                let groupDeleted = this.groups.value.find(g => g.groupId === msg.id);
                if (groupDeleted) {
                    let sessionIds = [];
                    groupDeleted.censuses.forEach(c => sessionIds = sessionIds.concat(c.sessionIds));
                    this.sessionService.updateSessions(sessionIds);
                }
                this.groups.next(null);
                this.getGroups(this.mainService.farmChanged.value.farmId);
            } else if (msg.type === SignalrType.sessionsDelete && !isNaN(msg.id) && this.groups.value) {
                this.groups.value.forEach(group => {
                    if (group.censuses && group.censuses.length > 0) {
                        if (group.censuses.find(c => c.sessionIds.length > 0 && c.sessionIds.findIndex(id => id === msg.id) >= 0)) {
                            // need to redownlad the group
                            this.groupService.getGroup(group.groupId).subscribe((grp: Group) => {
                                if (grp) {
                                    setTimeout(() => {
                                        index = this.groups.value.findIndex(g => g.groupId === grp.groupId);
                                        grp.displayName = GroupsService.GetGroupDisplayName(grp.groupName, grp.targetWeightPlan);
                                        if (index >= 0) { // should ALWAYS be this, as we are forcing a full download of an existing group
                                            this.groups.value[index] = grp;
                                        } else { // otherwise backup plan, just because
                                            this.groups.value.push(grp);
                                        }
                                        this.groups.next(this.groups.value.sort((a, b) => {
                                            let yearDiff = new Date(b.targetWeightPlan.startDate).getFullYear()
                                                - new Date(a.targetWeightPlan.startDate).getFullYear();
                                            return yearDiff === 0 ? a.displayName.localeCompare(b.displayName) : yearDiff;
                                        }));
                                    }, 0);
                                }
                            });
                        }
                    }
                });
                this.groups.next(this.groups.value);
            } else if (this.groups.value && msg.type === SignalrType.groups && this.groups.value && msg.ids && (index < 0 ||
                new Date(this.groups.value[index].modified).getTime() < msg.modifiedDate.getTime())) {
                msg.ids.forEach(id => {
                    this.groupService.getGroup(id).subscribe((group: Group) => {
                        if (group) {
                            setTimeout(() => {
                                index = this.groups.value.findIndex(g => g.groupId === group.groupId);
                                group.displayName = GroupsService.GetGroupDisplayName(group.groupName, group.targetWeightPlan);
                                if (index >= 0) {
                                    this.groups.value[index] = group;
                                } else {
                                    this.groups.value.push(group);
                                }
                                this.groups.next(this.groups.value.sort((a, b) => {
                                    let yearDiff = new Date(b.targetWeightPlan.startDate).getFullYear()
                                        - new Date(a.targetWeightPlan.startDate).getFullYear();
                                    return yearDiff === 0 ? a.displayName.localeCompare(b.displayName) : yearDiff;
                                }));
                            }, 0);
                        }
                    });
                });
            }
        }));

        this.subs.push(this.mainService.farmChanged.subscribe(farm => {
            if (farm) {
                this.groups.next(null);
                this.getGroups(farm.farmId);
            }
        }));
    }

    private getGroups(farmId: number) {
        this.curFarmId = farmId;
        this.groupService.getGroups(farmId)
            .subscribe((groups: Array<Group>) => {
                groups.forEach(g => {
                    g.displayName = GroupsService.GetGroupDisplayName(g.groupName, g.targetWeightPlan);
                });
                this.groups.next(groups.sort((a, b) => {
                    let yearDiff = new Date(b.targetWeightPlan.startDate).getFullYear()
                        - new Date(a.targetWeightPlan.startDate).getFullYear();
                    return yearDiff === 0 ? a.displayName.localeCompare(b.displayName) : yearDiff;
                }));
            });
    }

    public getLatestGroups() {
        if (this.groups.value && this.groups.value.length > 0) {
            let latestGroupCreated: Date = null;
            this.groups.value.forEach(g => {
                if (!latestGroupCreated || new Date(g.created).getTime() > latestGroupCreated.getTime()) {
                    latestGroupCreated = new Date(g.created);
                }
            });
            this.groupService.getLatestGroups(this.curFarmId, latestGroupCreated)
                .subscribe((groups: Array<Group>) => {
                    groups.forEach(g => {
                        if (!this.groups.value.find(gg => gg.groupId === g.groupId)) {
                            g.displayName = GroupsService.GetGroupDisplayName(g.groupName, g.targetWeightPlan);
                            this.groups.value.push(g);
                        }
                    });
                    // groups = groups.concat(this.groups.value);
                    this.groups.next(this.groups.value.sort((a, b) => {
                        let yearDiff = new Date(b.targetWeightPlan.startDate).getFullYear()
                            - new Date(a.targetWeightPlan.startDate).getFullYear();
                        return yearDiff === 0 ? a.displayName.localeCompare(b.displayName) : yearDiff;
                    }));
                });
        } else if (this.curFarmId) {
            this.getGroups(this.curFarmId);
        }
    }

    public saveGroup = (group: BaseGroup, sessionGroupAutomation: SessionGroupAutomation[]): Observable<Group> => {
        const baseGroup = Helper.instantiate(new BaseGroup(), group);
        return this.groupService.saveGroup(baseGroup)
            .pipe(map((grp: Group) => {
                grp.displayName = GroupsService.GetGroupDisplayName(grp.groupName, grp.targetWeightPlan);
                let index = this.groups.value.findIndex(g => g.groupId === grp.groupId);
                if (index < 0) {
                    this.groups.value.push(grp);
                    this.groups.next(this.groups.value);
                }
                return grp;
            }));
    }
    public changeGroupColumnDisplayOrder = (groupId: number, fieldDisplayOrder: any): Observable<Group> => {
        return this.groupService.changeGroupColumnDisplayOrder(groupId, fieldDisplayOrder);
    }

    public updateGroup = (groupId: number, changed: any): Observable<Group> => {
        if (changed.targetWeightPlan) {
            delete changed.targetWeightPlan;
        }
        return this.groupService.updateGroup(groupId, changed)
            .pipe(map((grp: Group) => {
                grp.displayName = GroupsService.GetGroupDisplayName(grp.groupName, grp.targetWeightPlan);
                // let index = this.groups.value.findIndex(g => g.groupId === groupId);
                // this.groups.value[index] = Object.assign(this.groups.value[index], grp);
                // this.groups.next(this.groups.value);
                return grp;
            }));
    }

    deleteGroup = (groupId: number): Observable<any> => {
      return this.groupService.deleteGroup(groupId);
    }

    public getAnimalSummary = (groupId: number): Observable<Array<AnimalSummary>> => {
        return this.groupService.getAnimalSummary(groupId);
    }


    public assignWeightRecordsToGroups(action: SessionAction, groupAssignments: Array<GroupWeightRecordSplit>, movePriorWeights?: boolean): Observable<any> {
        return this.groupService.assignWeightRecordsToGroups(action.recomendedGroup ? action.recomendedGroup.groupId : null, groupAssignments, movePriorWeights);
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
            sub.unsubscribe();
        });
    }
}

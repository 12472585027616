import { DashboardEducationService } from '../dashboard-education.service';
import { GroupsService } from '../../services/groups.service';
import { Group } from '../../models/group.model';
import { MainService } from '../../main.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-widget',
  templateUrl: './group-widget.component.html',
  styleUrls: ['./group-widget.component.scss']
})
export class GroupWidgetComponent implements OnInit, OnDestroy {
  private subs: Array<Subscription> = [];
  public groups: Array<Group>;

  constructor(private mainService: MainService, private router: Router, private groupsService: GroupsService
    , public dashboardEducationService: DashboardEducationService) { }

  ngOnInit() {
    this.subs.push(this.groupsService.groups.subscribe(groups => {
      if (groups !== null && groups !== undefined && groups.length === 0) {
        this.dashboardEducationService.getGroupData().then(g => {
          this.groups = this.sortGroups(g);
        });
      } else {
        this.dashboardEducationService.groupsE = false;
        if (groups !== null) {
          let activeGroups = groups.filter(g => g.isCurrent);
          this.groups = activeGroups.length > 0 ? this.sortGroups(activeGroups) : [];
         } else {
          this.groups = null;
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  sortGroups(groups: Array<Group>): Array<Group> {
    // Special sort by last update
    return groups.slice().sort((a, b) => {
      if (a.censuses && b.censuses) {
        // higher value of censusId means it has been newly updated, hence display it first
        return Math.max.apply(null, b.censuses.map(c => new Date(c.timeStamp).getTime())) - Math.max.apply(null, a.censuses.map(c => new Date(c.timeStamp).getTime()));
      }
      const chartPointComp = <any>(a.censuses !== null) - <any>(b.censuses !== null),
        yearDiff = new Date(b.targetWeightPlan.startDate).getFullYear() - new Date(a.targetWeightPlan.startDate).getFullYear();
      return chartPointComp === 0 ? yearDiff === 0 ?
        a['displayName'].localeCompare(b['displayName']) : yearDiff : chartPointComp;
    }).slice(0, 3);
  }

}

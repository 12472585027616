<div *ngIf="messageLog.length > 0">

  <div class="alert"
       *ngFor="let msg of messageLog; let i = index"
       [style.bottom]="(i * 100 + 15) + 'px'"
       [class.fadeOutDown]="msg.closing"
       [class.fadeInDown]="!msg.closing"
       [class.alert-success]="!msg.type || msg.type === 'success'"
       [class.alert-warning]="msg.type === 'warning'"
       [class.alert-danger]="msg.type === 'error' || msg.type === 'failure' || msg.type === 'exclamation'">

    <!-- icon -->
    <i *ngIf="msg.type !== 'exclamation'" class="fa fa-2x fa-info-circle"></i>
    <i *ngIf="msg.type === 'exclamation'" class="fa fa-2x fa-exclamation-circle"></i>

    <!-- content -->
    <div>

      <!-- string -->
      <strong *ngIf="msg.title">{{msg.title}}<br></strong>
      <span *ngIf="msg.message" [innerHTML]="msg.message"></span>

      <!-- templates -->
      <ng-container *ngTemplateOutlet="msg.titleTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="msg.messageTemplate"></ng-container>

    </div>

    <!-- close -->
    <button type="button" class="close ml-auto" data-dismiss="alert" aria-label="Close" (click)="closeMsg(msg)">
      <i class="fas fa-times"></i>
    </button>

<!--      <div style="cursor: pointer;" *ngIf="!msg.pinned" (click)="msg.pinned = true;"><span title="Stick" class="fa fa-pause"></span></div>-->
<!--      <div *ngIf="msg.pinned"><span title="Pinned" class="fa fa-thumb-tack"></span></div>-->

  </div>

</div>

import {Component, OnInit, Input, AfterViewInit, Renderer2, OnDestroy} from '@angular/core';
import { MainService } from '../../main/main.service';
import { Subscription } from 'rxjs';
import { FloatingHeaderHelper } from '../../helpers/floating-header.helper';

declare let $: any

@Component({
  selector: 'app-floating-scroll-bar',
  templateUrl: './floating-scroll-bar.component.html',
  styleUrls: ['./floating-scroll-bar.component.scss']
})
export class FloatingScrollBarComponent implements OnInit, AfterViewInit, OnDestroy {
  public width = 0;
  public innerWidth = 0;
  private sub: Subscription;
  private customScrollClicked = false;
  private keepCheckingWidthCheck = true;

  constructor(private mainService: MainService, private renderer: Renderer2) { }



  ngOnInit() {
    this.sub = this.mainService.leftMenuOpen.subscribe(() => {
      this.setApropriateWidth();
    });
  }

  ngAfterViewInit() {
    $(window).on('scroll resize', (a) => {
      if ($('.ui-table-tbody').offset() === null || $('.ui-table-tbody').offset() === undefined) {
        return;
      }
      if (($('.ui-table-tbody').offset().top + $('.ui-table-tbody').height()) < ($(window).scrollTop() + $(window).height())
        || $('.ui-table-tbody').offset().top > ($(window).scrollTop() + $(window).height())) {
        $('.floaty-scroll-bar-outer').hide()
      } else {
        $('.floaty-scroll-bar-outer').show();
      }
      this.setApropriateWidth();
    });

    $('.floaty-scroll-bar-outer').scroll((e, b) => {
      this.setNativeScrollFromCustom();
    });

    $('.floaty-scroll-bar-outer').mousedown((e, b) => {
      this.customScrollClicked = true;
    });
    $('.floaty-scroll-bar-outer').click((e, b) => {
      this.customScrollClicked = false;
    });

    $('.ui-table-wrapper').scroll(() => {
      this.setCustomScrollFromNative();
    });
    this.keepCheckingWidth();
    this.clipHeader();
  }

  private keepCheckingWidth() {
    setTimeout(() => {
      if (!this.keepCheckingWidthCheck) {
        return;
      }
      this.setApropriateWidth();
    }, 2000);
  }

  private setApropriateWidth() {
    setTimeout(() => {
      this.width = $('.ui-table-wrapper').width();
      this.innerWidth = $('.ui-table-tbody').width();
    });
  }

  private setNativeScrollFromCustom() {
    $('.ui-table-wrapper').scrollLeft($('.floaty-scroll-bar-outer').scrollLeft());
    this.clipHeader();
  }
  private setCustomScrollFromNative() {
    if (!this.customScrollClicked) {
      $('.floaty-scroll-bar-outer').scrollLeft($('.ui-table-wrapper').scrollLeft());
    }
    this.clipHeader();
  }

  private clipHeader() {
    FloatingHeaderHelper.clipFloatingHeader($('.ui-table-wrapper').scrollLeft(), this.renderer);
    setTimeout(() => { FloatingHeaderHelper.clipFloatingHeader($('.ui-table-wrapper').scrollLeft(), this.renderer); });
  }

  ngOnDestroy() {
    this.keepCheckingWidthCheck = false;
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

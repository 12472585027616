import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AuthService} from '../../auth/auth.service';
import {WeightPlanTemplate} from '../models/weight-plan-template.model';
import {ApiService} from './api.service';


@Injectable()
export class WeightPlanTemplateService {

  constructor(private authService: AuthService, private apiService: ApiService) {
  }

  getWeightPlanTemplates(): Observable<Array<WeightPlanTemplate>> {
    return this.apiService.get(`/odata/TargetWeightPlanTemplates`).pipe(map(res => res.value));
  }
}



import { PermissionsService } from '../../auth/permissions.service';
import { LocaleService } from '../../helpers/locale.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
import { MainService } from '../main.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(title: Title, public authService: AuthService, public mainService: MainService
    , private localeService: LocaleService, public permissionsService: PermissionsService) {
    title.setTitle(`${this.localeService.constants.stringSettings} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`); }

  ngOnInit() { }
}

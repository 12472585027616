import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {Observable, Subscription, BehaviorSubject} from 'rxjs';
import {combineLatest} from 'rxjs';

import {GroupWeightRecordSplit} from '../models/group-weight-record-split.model';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import {SessionService} from '../api/session.service';
import {SessionAction} from '../models/session-action';
import {SessionGroupService} from '../api/session-group.service';
import {MainService} from '../main.service';
import {IntercomService} from '../intercom.service';
import {LocaleService} from '../../helpers/locale.service';
import {PermissionsService} from '../../auth/permissions.service';
import {SessionRecomendation} from '../models/session-recomendation';
import {AuthService} from '../../auth/auth.service';
import {ApiService} from '../api/api.service';
import {ActionService} from '../api/action.service';
import {GroupsService} from '../services/groups.service';
import {SessionGroupSummary} from '../models/session-group-summary.model';
import {Group} from '../models/group.model';
import {SessionDoableActions} from './shared/session-doable-actions';


@Injectable({
  providedIn: 'root'
})
export class ActionsService implements OnDestroy {
  recommendations = new BehaviorSubject<Array<SessionRecomendation>>(null);

  private sub: Subscription;
  private lastFarmId: number = null;
  private lastSessionCount: number = null;
  private lastGroupCount: number = null;

  constructor(
    private mainService: MainService,
    private sessionGroupService: SessionGroupService,
    private sessionService: SessionService,
    private permissionsService: PermissionsService,
    private bottomNotificationsService: BottomNotificationsService,
    private groupsService: GroupsService,
    private router: Router,
    private intercomService: IntercomService,
    private localeService: LocaleService,
    private apiService: ApiService,
    private actionService: ActionService,
    private authService: AuthService) {

    this.sub = combineLatest([
      this.sessionService.sessions, this.groupsService.groups, this.mainService.farmChanged
    ]).subscribe(sessionsGroupsFarm => {
      let farm = sessionsGroupsFarm[2],
        sessions = sessionsGroupsFarm[0],
        groups = sessionsGroupsFarm[1];

      if (farm && sessions) {
        // not wait for the groups as we want to fire recommendation call before we get groups result
        // need to check sessions is not null, otherwise change farm will double api call
        let sessionCount = sessions ? sessions.length : -1;
        let groupCount = groups ? groups.length : -1;
        if (sessionCount !== -1 && this.lastSessionCount === -1) {
          this.lastSessionCount = sessionCount;
        }
        if (groupCount !== -1 && this.lastGroupCount === -1) {
          this.lastGroupCount = groupCount;
        }
        if (farm.farmId !== this.lastFarmId || sessionCount !== this.lastSessionCount || groupCount !== this.lastGroupCount) {
          this.updateRecommendation();
        }
      }
    });

  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  updateRecommendation() {
    this.lastFarmId = this.mainService.farmChanged.value.farmId;
    this.lastGroupCount = this.groupsService.groups.value ? this.groupsService.groups.value.length : -1;
    this.lastSessionCount = this.sessionService.sessions.value ? this.sessionService.sessions.value.length : -1;
    this.actionService.SessionGroupRecomendations(this.lastFarmId).subscribe((recos: Array<SessionRecomendation>) => {
      this.recommendations.next(recos);
    })
  }

  assignAction(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    return this.actionService.assignAction(action, movePriorWeights).pipe(map(res => {
      this.intercomService.logEvent('Action Assigned', this.router.url, {
        user_id: this.authService.currentUser.userId,
        farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
      });
      return res;
    }));
  }

  reAssignAction(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    return this.actionService.reAssignAction(action, movePriorWeights).pipe(map(res => {
      this.intercomService.logEvent('Changed Action Assignment', this.router.url, {
        user_id: this.authService.currentUser.userId,
        farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
      });
      return res;
    }));
  }

  assignSingleAnimal(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    return this.actionService.assignSingleAnimal(action, movePriorWeights).pipe(map(res => {
      this.intercomService.logEvent('Single Animal Assigned', this.router.url, {
        user_id: this.authService.currentUser.userId,
        farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
      });
      return res;
    }));
  }

  reAssignSingleAnimal(action: SessionAction, movePriorWeights?: boolean): Observable<any> {
    return this.actionService.reAssignSingleAnimal(action, movePriorWeights).pipe(map(res => {
      this.intercomService.logEvent('Changed Single Animal Assignment', this.router.url, {
        user_id: this.authService.currentUser.userId,
        farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
      });
      return res;
    }));
  }

  assignWeightRecordsToGroups(action: SessionAction, groupAssignments: Array<GroupWeightRecordSplit>, movePriorWeights?: boolean): Observable<any> {
    return this.groupsService.assignWeightRecordsToGroups(action, groupAssignments, movePriorWeights).pipe(map(res => {
      this.intercomService.logEvent('Assigned Weight Records to Groups', this.router.url, {
        user_id: this.authService.currentUser.userId,
        farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
      });
      return res;
    }));
  }

  confirmAssignment(action: SessionAction): Observable<any> {
    return this.actionService.confirmAssignment(action.sessionId, action.groupId).pipe(
      tap(() => {

        // update sessions
        this.sessionService.updateSessions([action.sessionId]);

        this.intercomService.logEvent('Confirmed Action Assignment', this.router.url, {
          user_id: this.authService.currentUser.userId,
          farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
        });
      })
    );
  }

  ignoreWeightRecords(action: SessionAction, showNotificationDueToNotAutoGenerated: boolean = true): Observable<boolean> {
    return this.actionService.ignoreWeightRecords(action, !showNotificationDueToNotAutoGenerated).pipe(
      tap(() => {

        // update sessions
        this.sessionService.updateSessions([action.sessionId]);

        if (showNotificationDueToNotAutoGenerated) {
          this.bottomNotificationsService.currentMessage.next({
            title: (action.ided ? <string>this.localeService.constants.stringActionHasBeenIgnored
              : <string>this.localeService.constants.stringWeightrecordsHaveBeenIgnored),
            message: (action.ided ? <string>this.localeService.constants.stringActionHasBeenIgnoredMessage
              : <string>this.localeService.constants.stringWeightrecordsHaveBeenIgnoredMessage)
              .replace('<animalCount/>', action.animalCount.toString()),
            type: 'success'
          });
        }

        this.intercomService.logEvent('Ignored Action', this.router.url, {
          user_id: this.authService.currentUser.userId,
          farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
        });
      })
    );
  }

  assignActionToRecommendedGroup(action: SessionAction, showNotification = true): Observable<any> {
    if (!action.recomendedGroup) {
      return;
    }

    const committedAction: any = Object.assign({}, action);
    committedAction.groupId = committedAction.recomendedGroup.groupId;
    committedAction.recommendedGroupId = committedAction.recomendedGroup.groupId;
    delete committedAction.recomendedGroup;

    return this.actionService.assignAction(<SessionAction>committedAction, false).pipe(
      tap(() => {

        // update sessions
        this.sessionService.updateSessions([action.sessionId]);

        if (showNotification) {
          let baseMessage = action.ided
            ? (<string>this.localeService.constants.stringAnimalshavebeenassignedtothegroup)
            : (<string>this.localeService.constants.stringWeightsWithoutIDshavebeenassignedtothegroup);
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAnimalsSuccessfullyAssignedToAGroup,
            message: baseMessage
              .replace('<animalCount/>', action.animalCount.toString())
              .replace('<groupDisplayName/>', action.recomendedGroup.displayName),
            type: 'success'
          });
        }

        this.intercomService.logEvent('Approve Assignment', this.router.url, {
          user_id: this.authService.currentUser.userId,
          farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null
        });
      })
    );
  }

  /** used in actions lists - actions page and actions widget **/
  getSessionsWithDoableActions(sessionGroupSummaries: SessionGroupSummary[], groups: Group[]): SessionDoableActions {
    let actionsCount = 0;
    let sessions = null;

    if (sessionGroupSummaries !== null && sessionGroupSummaries !== undefined && groups !== null && groups !== undefined) {
      sessions = [];

      sessionGroupSummaries.forEach(s => {
        const session = Object.assign({}, <SessionGroupSummary>s);

        if (session.actions && session.actions.length > 0) {

          // strip out ignored actions
          session.actions = session.actions.filter(a => !a.v_ActionId || !a.v_ActionId.endsWith('_I')).slice();

          if (session.actions.length > 0) {
            actionsCount += session.actions.length;

            session.actions.forEach(action => {
              if (action.groupId) {
                action.confirmationRequiredGroup = this.groupsService.groups.value.find(g => g.groupId === action.groupId);
              }
            });

            sessions.push(session);
          }
        }
      });

      sessions = sessions.sort((a, b) => new Date(b.importDateTime).getTime() - new Date(a.importDateTime).getTime());

    } else {
      sessions = null;
      actionsCount = 0;
    }

    return <SessionDoableActions>{
      sessions,
      actionsCount
    }
  }

  /** used in actions lists - actions page and actions widget **/
  getSessionsWithRecommendations(recommendations: SessionRecomendation[], sessions: SessionGroupSummary[]): SessionGroupSummary[] {

    // set recommended group
    recommendations.forEach(rec => {
      let session = sessions.find(s => rec.sessionId === s.sessionId);

      if (session) {
        let action = session.actions.find(a => rec.sessionId === a.sessionId && rec.ided === a.ided && !a.groupId);
        let group = this.groupsService.groups.value.find(g => g.groupId === rec.groupId);

        if (action) {
          action.recomendedGroup = group;
        }
      }
    });

    sessions.forEach(s => {
      if (s.actions) {
        s.actions.forEach(a => {

          if (a.v_ActionId && a.v_ActionId.endsWith('_I')) {

            // strip out because ignored
            let ign = s.actions.findIndex(aa => a.v_ActionId === aa.v_ActionId);
            s.actions.splice(ign, 1);
          } else if (a.groupId) {

            // TODO if action has groupId
            a.confirmationRequiredGroup = this.groupsService.groups.value.find(g => g.groupId === a.groupId);
            a.orderBy = 1;
          } else {
            a.orderBy = a.ided ? 2 : 3;
          }
        });

        // order actions: auto-allocated, new animals, non-ID'd weights
        // 1 action.groupId
        // 2 !action.groupId && action.ided
        // 3 !action.groupId && !action.ided
        s.actions.sort(a => a.orderBy);
      }
    });

    return sessions;
  }
}

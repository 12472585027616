<div class="card">
  <div class="card-body text-center">

    <!-- content -->
    <div class="content">
      <ng-content></ng-content>
    </div>

    <!-- videos -->
    <div class="mt-4" *ngIf="cleanUrls.length > 0">
      <div class="row">
        <div *ngFor="let url of cleanUrls" class="col-md-6 mx-auto">
          <div class="video">
            <div class="video-wrapper">
              <iframe width="560" height="349" [src]="url" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary mt-4" *ngIf="buttonText && permissionsService.permissions.canEditData" (click)="onClick.emit()">{{buttonText}}</button>

  </div>
</div>

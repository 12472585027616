import {MainService} from '../main.service';
import {SessionWeightSummary} from '../models/session-weight-summary';
import {SignalRService, SignalrType} from './signal-r.service';
import {AuthService} from '../../auth/auth.service';
import {Injectable, OnDestroy, Inject, forwardRef} from '@angular/core';

import {Observable, Subscription, BehaviorSubject} from 'rxjs';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

import {Session} from '../models/session.model';
import {ApiService} from './api.service';
import {AnimalSummary} from '../models/animal-adg.model';
import {map, catchError, tap} from 'rxjs/operators';
import {of, combineLatest} from 'rxjs';
import { SessionAction } from '../models/session-action';


@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {
  private curFarmId: number;
  private subs: Array<Subscription> = [];
  public sessions = new BehaviorSubject<Array<SessionWeightSummary>>(null);
  public sessionsObservable: Observable<SessionWeightSummary[]> = this.sessions.asObservable();

  constructor(private authService: AuthService, private signalRService: SignalRService,
              private mainService: MainService, private apiService: ApiService) {
    // Download new session when singalR session updates
    this.subs.push(this.signalRService.update.subscribe(msg => {
      // Download new sessions when singalr updates
      if (msg.type === SignalrType.forceRefresh && this.mainService.farmChanged.value) {
        this.sessions.next(null);
        this.getSessions(this.mainService.farmChanged.value.farmId);
      } else if (msg.type === SignalrType.sessions && !isNaN(msg.id)) { // single session update
        this.updateSessions([msg.id]);
      } else if (msg.type === SignalrType.sessions && msg.ids && msg.ids.length > 0) { // bulk session update
        this.updateSessions(msg.ids);
      } else if (msg.type === SignalrType.sessionsDelete && !isNaN(msg.id) && this.sessions.value) {
        let index = this.sessions.value.findIndex(c => c.sessionId === msg.id);
        if (index >= 0) {
          this.sessions.value.splice(index, 1);
          this.sessions.next(this.sessions.value);
        }
      } else if (msg.type === SignalrType.groups && msg.ids) { // Update census ids for sessions when group censuses updated
        this.apiService.postType<SessionWeightSummary>(SessionWeightSummary,`/odata/Sessions/Services.SessionsSummaries()`,
          { sessionIds: [], groupIds: msg.ids })
          .subscribe((sessions: Array<SessionWeightSummary>) => {
            if (sessions && this.sessions.value) {
              this.sessions.value.filter(s => s.censuses.find(c => c.group_GroupId === msg.id))
                .forEach(s => {
                  s.censuses.splice(s.censuses.findIndex(c => c.group_GroupId === msg.id), 1);
                });
              sessions.forEach(s => {
                let i = this.sessions.value.findIndex(curS => curS.sessionId === s.sessionId);
                if (i >= 0) {
                  this.sessions.value[i] = s;
                }
              });
              this.sessions.next(this.sessions.value
                .sort((a, b) => new Date(b.sessionStartDate).getTime() - new Date(a.sessionStartDate).getTime()));
            }
          });
      }
    }));
    // redownload data when farm changed
    this.subs.push(this.mainService.farmChanged.subscribe(farm => {
      if (farm) {
        this.sessions.next(null);
        this.getSessions(farm.farmId);
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public updateSessions(sessionIds: Array<number>) {
    this.apiService.postType<SessionWeightSummary>(SessionWeightSummary,`/odata/Sessions/Services.SessionsSummaries()`,
      { sessionIds: sessionIds, groupIds: [] })
      .pipe(
        map((sessions:SessionWeightSummary[])=>{
      
          return sessions.map(s=>{
            s.actions= (s.actions || []).map(a=>Object.assign(new SessionAction(),a))
            return s;
          });
        }))
      .subscribe((changedSessions: Array<SessionWeightSummary>) => {
        if (changedSessions) {
          const currentSessions = this.sessions.value.slice();

          changedSessions.forEach(changedSession => {
            const indexToDelete = this.sessions.value.findIndex(s => s.sessionId === changedSession.sessionId);

            if (indexToDelete >= 0) {
              currentSessions.splice(indexToDelete, 1);
            }
            currentSessions.push(changedSession);
          });

          this.sessions.next(currentSessions.sort((a, b) => new Date(b.sessionStartDate).getTime() - new Date(a.sessionStartDate).getTime()));
        }
      });
  }

  private getSessions(farmId: number) {
    this.curFarmId = farmId;
    this.apiService.postType<SessionWeightSummary[]>(SessionWeightSummary,`/odata/Sessions/Services.SessionsSummaries()`,
      { sessionIds: [], groupIds: [] })
      .pipe(map((sessions:SessionWeightSummary[])=>{
        
        return sessions.map(s=>{
          s.actions= (s.actions || []).map(a=>Object.assign(new SessionAction(),a))
          return s;
        });
      }))
      .subscribe(sessions => {
        //this.sessions.next(sessions.sort((a, b) => new Date(b.sessionStartDate).getTime() - new Date(a.sessionStartDate).getTime()));
        const sorted = sessions.sort((a, b) => b.sessionStartDate.getTime() - a.sessionStartDate.getTime())
        this.sessions.next(sorted);
      });
  }

  public getLatestSessions() {
    if (this.sessions.value && this.sessions.value.length > 0) {
      let latestSessionDate: Date = null;
      this.sessions.value.forEach(s => {
        if (!latestSessionDate || new Date(s.importDateTime).getTime() > latestSessionDate.getTime()) {
          latestSessionDate = new Date(s.importDateTime);
        }
      });
      this.apiService.get(`/odata/Sessions/Services.GetLatestSessionsSummaries(sessionIds=[],groupIds=[])`
        + `?$filter=importDateTime gt cast(${encodeURI(new Date(latestSessionDate).toISOString())},Edm.DateTimeOffset)`)
        .pipe(map((res: any) => res.value))
        .subscribe(sessions => {
          sessions = sessions.concat(this.sessions.value);
          this.sessions.next(sessions
            .sort((a, b) => new Date(b.sessionStartDate).getTime() - new Date(a.sessionStartDate).getTime()))
        });
    } else {
      this.getSessionsForCurrentFarm();
    }
  }

  public getSessionsForCurrentFarm() {
    this.getSessions(this.curFarmId ? this.curFarmId : this.mainService.farmChanged.value.farmId);
  }

  private extractData(res: any) {
    let body = res;
    return body || {};
  }

  public GetSessionWithWeights = (sessionId: number): Observable<Session> => {
    return this.apiService.getTyped<Session>(Session,`/odata/Sessions(${sessionId})?$expand=weightRecords($select=weight)`);
  }

  public SaveColumnOrder = (sessionId: number, fieldDisplayOrder: string): Observable<any> => {
    return this.apiService.patch(`/odata/Sessions(${sessionId})`, { 'fieldDisplayOrder': fieldDisplayOrder });
  }

  deleteSession (sessionId: number): Observable<any> {
    return this.apiService.delete(`/odata/Sessions(${sessionId})`, 'text');
  }
}

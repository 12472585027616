<div class="card">

  <div class="card-header">
    <h3 *ngIf="!selectedDate">Camera</h3>
    <h3 *ngIf="selectedDate">{{selectedDate}}</h3>
  </div>

  <div class="card-body">

    <app-loading [local]="true" *ngIf="!images"></app-loading>

    <ng-container *ngIf="images">
      <div class="w100p text-center">
        <p-galleria [images]="images" *ngIf="images.length > 0" [panelWidth]="paddocks.length > 1 ? 450 : 494" [panelHeight]="paddocks.length > 1 ? 320 : 355"
                    frameHeight="25" [showCaption]="false" [autoPlay]="false" [showCaption]="true"></p-galleria>
        <h3 class="text-center mb-0" *ngIf="images.length === 0" style="height: 320px;">No images available for this paddock.</h3>
      </div>
      <div class="mt-3 camera-draft-selector" *ngIf="paddocks.length > 1">

        <ng-select
          class="form-control form-control-ng-select"
          [items]="paddocks"
          bindLabel="label"
          bindValue="value"
          [clearable]="false"
          [(ngModel)]="curPaddockId"
          (change)="filterByPaddock()">
        </ng-select>

      </div>
    </ng-container>

  </div>
</div>

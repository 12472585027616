import { Component, OnInit, Input } from '@angular/core';
import { Group } from '../../models/group.model';
import { FarmUser } from '../../models/farm-user.model';
import { Invitation } from '../../models';
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { InvitationService } from '../../api/invitation.service';
import { IntercomService } from '../../intercom.service';
import { MainService } from '../../main.service';
import { LocaleService } from '../../../helpers/locale.service';
import { InvitationFarmRoleId } from '../../models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GlobalLoaderService} from '../../../core/global-loader.service';

@Component({
  selector: 'app-edit-userinvitation',
  templateUrl: './edit-userinvitation.component.html',
  styleUrls: ['./edit-userinvitation.component.scss']
})
export class EditUserinvitationComponent implements OnInit {
  @Input() groups: Array<Group>;
  @Input() farmUsers: Array<FarmUser>;
  @Input() invitations: Array<Invitation> = [];
  @Input() farmUser;

  selectedGroupMessage: string;
  selectedGroupsCount = 0;
  window: Window = window;
  form: FormGroup;

  selectedGroupsIds = [];

  private opened = false;

  constructor(
    private bottomNotificationsService: BottomNotificationsService,
    private invitationService: InvitationService,
    private intercomService: IntercomService,
    private mainService: MainService,
    private localeService: LocaleService,
    public ngbActiveModal: NgbActiveModal,
    private globalLoaderService: GlobalLoaderService
  ) { }

  ngOnInit() {
    if (this.farmUser.groupIdsJson) {
      this.selectedGroupsIds = JSON.parse(this.farmUser.groupIdsJson);
    }

    if (this.farmUser.invitationId) {
      this.editInvitation(this.farmUser as Invitation);
    } else {
      this.editUser(this.farmUser as unknown as FarmUser);
    }
  }

  editUser(user: FarmUser) {
    this.form = null;
    this.generateForm(user.userId, null, user.farmRoleId,
      user.groupIdsJson && user.groupIdsJson.length > 0 ? JSON.parse(user.groupIdsJson) as Array<number> : null);
  }

  editInvitation(invitation: Invitation) {
    this.form = null;
    setTimeout(() => {
      this.generateForm(null, invitation.invitationId, invitation.farmRoleId,
        invitation.groupIdsJson && invitation.groupIdsJson.length > 0 ? JSON.parse(invitation.groupIdsJson) as Array<number> : null);
    });
  }

  saveUserOrInvitation() {
    const farmRoleId = this.form.controls['farmRoleId'].value as InvitationFarmRoleId;
    const groupIdsJson = farmRoleId === InvitationFarmRoleId.GroupReport ? JSON.stringify(this.selectedGroupsIds) : null;

    if (this.form.controls['invitationId'].value !== null) {
      this.updateInvitation(this.form.controls['invitationId'].value, farmRoleId, groupIdsJson);
    } else if (this.form.controls['userId'].value !== null) {
      this.updateUser(this.form.controls['userId'].value, farmRoleId, groupIdsJson);
    }
  }

  updateUser(userId: string, farmRoleId: InvitationFarmRoleId, groupIdsJson: string) {
    const user = this.farmUsers.find(uf => uf.userId === userId);

    this.globalLoaderService.showGlobalLoader = true;

    this.invitationService.updateUserForFarm(user.userId, this.mainService.farmChanged.value.farmId, farmRoleId, groupIdsJson).subscribe(res => {
      this.globalLoaderService.showGlobalLoader = false;

      this.bottomNotificationsService.currentMessage.next({
        title: 'User successfully updated',
        message: `${user.firstName} ${user.lastName} ${this.localeService.constants.stringHasBeenUpdated}`,
        type: 'success'
      });
      this.ngbActiveModal.close(user);
    }, err => {
      this.globalLoaderService.showGlobalLoader = false;

      this.bottomNotificationsService.currentMessage.next({
        title: 'User update failed',
        message: `${user.firstName} ${user.lastName} ${this.localeService.constants.stringCannotBeUpdatedAtThisTimePleaseTryAgainLater}.`,
        type: 'warning'
      });
    });
  }

  updateInvitation(invitationId: number, farmRoleId: InvitationFarmRoleId, groupIdsJson: string) {
    const invitation = this.invitations.find(i => i.invitationId === invitationId);

    this.globalLoaderService.showGlobalLoader = true;

    this.invitationService.updateInvitation(invitation.code, this.mainService.farmChanged.value.farmId, farmRoleId, groupIdsJson).subscribe(res => {
      this.globalLoaderService.showGlobalLoader = false;

      if (res) {
        // invitation.farmRoleId = farmRoleId;
        // invitation.groupIdsJson = groupIdsJson;
        this.bottomNotificationsService.currentMessage.next({
          title: 'Invitation successfully updated',
          message: `${invitation.firstName} ${invitation.lastName} ${this.localeService.constants.stringHasBeenUpdated}`,
          type: 'success'
        });
        this.ngbActiveModal.close(invitation);
      } else {
        this.bottomNotificationsService.currentMessage.next({
          title: 'Invitation update failed',
          message: `Invitation for ${invitation.firstName} ${invitation.lastName} ${this.localeService.constants.stringCannotBeUpdatedAtThisTimePleaseTryAgainLater}.`,
          type: 'warning'
        });
      }
    }, err => {
      this.globalLoaderService.showGlobalLoader = false;

      this.bottomNotificationsService.currentMessage.next({
        title: 'Invitation update failed',
        message: `Invitation for ${invitation.firstName} ${invitation.lastName} ${this.localeService.constants.stringCannotBeUpdatedAtThisTimePleaseTryAgainLater}.`,
        type: 'warning'
      });
    })
  }

  generateForm(userId: string, invitationId: number, farmRoleId: InvitationFarmRoleId, groupIds: Array<number>) {
    if (this.groups && groupIds) {
      this.groups.forEach(g => g.selected = groupIds.indexOf(g.groupId) >= 0);
    } else if (this.groups) {
      this.groups.forEach(g => g.selected = false);
    }
    this.updateSelectedGroupCount();
    this.form = new FormGroup({
      farmRoleId: new FormControl(farmRoleId, Validators.required),
      userId: new FormControl(userId),
      invitationId: new FormControl(invitationId),
    });
  }

  updateSelectedGroupCount() {
    this.selectedGroupMessage = this.localeService.constants.stringSelectGroups;
    if (this.groups) {
      this.selectedGroupsCount = this.groups.filter(g => g.selected).length;
      switch (this.selectedGroupsCount) {
        case 0:
          this.selectedGroupMessage = this.localeService.constants.stringSelectGroups;
          break;
        case 1:
          this.selectedGroupMessage = this.groups.filter(g => g.selected)[0].displayName;
          break;
        case 2:
          this.selectedGroupMessage = this.groups.filter(g => g.selected)[0].displayName + ', ' + this.groups.filter(g => g.selected)[1].displayName;
          break;
        default:
          this.selectedGroupMessage = this.selectedGroupsCount + ' ' + this.localeService.constants.stringGroups;
          break;
      }
    }
  }

  get farmRoleId(): AbstractControl {
    return this.form && this.form.get('farmRoleId');
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Integration, IntegratedDevice } from '../../../../models/integration';
import { SessionService } from '../../../../api/session.service';
import { GroupsService } from '../../../../services/groups.service';
import { EventAuditService } from '../../../../api/event-audit.service';
import { MainService } from '../../../../main.service';
import { IntegrationService } from '../../../../api/integration.service';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ppl-choose-device-modal',
  templateUrl: './ppl-choose-device-modal.component.html',
  styleUrls: ['./ppl-choose-device-modal.component.scss']
})
export class PplChooseDeviceModalComponent implements OnInit {
  @Input()
  public integration: Integration;
  public devices: Array<IntegratedDevice>;

  @Output()
  public integrationChanged = new EventEmitter<Integration>();
  public loading = false;
  visible = true;

  public window = window;

  constructor(
    private integrationService: IntegrationService,
    private mainService: MainService,
    private sessionService: SessionService,
    private groupsService: GroupsService,
    private eventAuditService: EventAuditService,
    public ngbActiveModal: NgbActiveModal,
    private ngbModal: NgbModal) { }

  ngOnInit() {
    this.devices = [];
    this.integration.integratedDevices.forEach(id => {
      this.devices.push(Object.assign({}, id));
    });
  }

  sendPpl() { // called after ppl connected or updated
    if (this.devices) {
      this.loading = true;
      // we made integration in prev step (or we are editing). so here we patch.
      this.integrationService.updateDevices(this.integration.integrationId, this.devices).subscribe(integration => {
        setTimeout(() => {
          this.loading = false;
          this.integrationChanged.emit(integration);
          this.ngbModal.dismissAll();
          this.sessionService.getLatestSessions(); // get new sessions
          this.groupsService.getLatestGroups(); // get new groups
          this.eventAuditService.getLatestEvents(this.mainService.farmChanged.value.farmId);
        });
      }, () => {
        this.loading = false;
      });
    }
  }
}

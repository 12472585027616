import { AuthService } from '../../auth/auth.service';
import { Invitation } from '../models/invitation.model';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';





import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { InvitationFarmRoleId } from '../models/index';
@Injectable({
  providedIn: 'root'
})
export class InvitationService {

    constructor(private authService: AuthService,
        private apiService: ApiService) { }

    public sendInvitation = (invitation: Invitation): Observable<Invitation> => {
        return this.apiService.post('/odata/Invitations', invitation);
    }

    public getInvitations = (): Observable<Array<Invitation>> => {
        return this.apiService.get(`/odata/Invitations/Services.MyInvitations()`)
        .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    public getInvitation = (code: string): Observable<Invitation> => {
        return this.apiService.get(`/odata/Invitations('${code}')`);
    }

    public acceptInvitation = (code: string): Observable<Response> => {
        return this.apiService.post(`/odata/Invitations/Services.AcceptInvitation`, { code: code }, 'text');
    }

    public getInvitedUsersForFarm = (farmId: number): Observable<Array<Invitation>> => {
        return this.apiService.get(`/odata/Invitations/Services.GetInvitationsForFarm(farmId=${farmId})`)
        .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    public removeUserFromFarm = (userId: string, farmId: number): Observable<Response> => {
        return this.apiService.post(`/odata/Invitations/Services.RemoveUserFromFarm`,
            { userId: userId, farmId: farmId }, 'text');
    }

    public removeInvitation = (invitation: Invitation): Observable<Response> => {
        return this.apiService.delete(`/odata/Invitations('${invitation.code}')`, 'text');
    }

    public updateUserForFarm = (userId: string, farmId: number, farmRoleId: number, groupIdsJson: string): Observable<Response> => {
        return this.apiService.post(`/odata/Users/Services.UpdateUserForFarm`,
            { userId: userId, farmRoleId: farmRoleId, farmId: farmId, groupIdsJson: groupIdsJson }, 'text');
    }

    public updateInvitation = (code: string, farmId: number, farmRoleId: InvitationFarmRoleId, groupIdsJson: string): Observable<Response> => {
        return this.apiService.post(`/odata/Invitations/Services.PatchInvitation`, { 'code': code, 'farmRoleId': farmRoleId, 'groupIdsJson' : groupIdsJson}, 'text');
    }
}

<ng-container *ngIf="animal">

  <div class="row">

    <div class="col-md-6">
      <app-animal-weight-gain *ngIf="animal && groups" [animal]="animal" [groups]="groups" class="d-block mb-4"></app-animal-weight-gain>
    </div>

    <div class="col-md-6">
      <app-animal-life-data *ngIf="animal && groups" [animal]="animal" [groups]="groups" class="d-block mb-4"></app-animal-life-data>
    </div>

    <div class="col-md-6">
      <app-animal-adg *ngIf="animal && groups" [animal]="animal" [groups]="groups" class="d-block mb-4"></app-animal-adg>
    </div>

    <div class="col-md-6">
      <app-animal-sessions-list *ngIf="sessions" [sessions]="sessions" class="d-block mb-4"></app-animal-sessions-list>
    </div>

  </div>
</ng-container>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {map} from 'rxjs/operators';

import {LocaleService} from '../../helpers/locale.service';
import {FarmUser} from '../models/farm-user.model';
import {IntercomService} from '../intercom.service';
import {UserSubscription} from '../models/user-subscription.model';
import {Group} from '../models/group.model';
import {Farm, Invitation, InvitationFarmRoleId} from '../models';
import {FarmService} from '../api';
import {MainService} from '../main.service';
import {AuthService} from '../../auth/auth.service';
import {InvitationService} from '../api/invitation.service';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import {PermissionsService} from '../../auth/permissions.service';
import {GroupsService} from '../services/groups.service';
import {ConfirmationService} from '../../components/confirmation/confirmation.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {InviteModalComponent} from './invite-modal/invite-modal.component';
import {EditUserinvitationComponent} from './edit-userinvitation/edit-userinvitation.component';
import {User} from '../models/user.model';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit, OnDestroy {
  farm: Farm;
  farmUsers: Array<FarmUser>;
  invitations: Array<Invitation>;
  errorMessage: string = null;
  displayAddUserModal = false;
  groups: Array<Group>; // for invitation modal
  currentSub: UserSubscription;
  loading: boolean;

  displayedColumns = ['name', 'phonenumber', 'email', 'role', 'actions'];

  private subs: Array<Subscription> = [];
  private usersSubscription: Subscription;
  private invitationsSubscription: Subscription;
  private groupsSubscription: Subscription;

  constructor(
    private farmService: FarmService,
    private mainService: MainService,
    public authService: AuthService,
    private invitationService: InvitationService,
    private bottomNotificationsService: BottomNotificationsService,
    public permissionsService: PermissionsService,
    private title: Title,
    private groupsService: GroupsService,
    private intercomService: IntercomService,
    private localeService: LocaleService,
    private confirmationService: ConfirmationService,
    private ngbModal: NgbModal
  ) {
  }

  ngOnInit() {
    this.title.setTitle(`${this.localeService.constants.stringInvite} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);

    // these fields should already be blank in the resultset from server
    if (this.permissionsService.currentFarmRoleId > 1 && this.authService.currentUser.userType !== 'SuperUser') {
      this.displayedColumns.splice(this.displayedColumns.indexOf('phonenumber'), 1);
      this.displayedColumns.splice(this.displayedColumns.indexOf('email'), 1);
    }

    this.subs.push(this.mainService.farmChanged.subscribe(farm => {
      this.farm = farm;
      this.invitations = null;
      this.farmUsers = null;

      if (farm !== null && farm !== undefined && this.authService.currentUser) {

        this.getFarmUsers();
        this.getInvitations();
        this.getGroups();

      }
    }));

    this.subs.push(this.authService.currentSubscription.subscribe(sub => {
      this.currentSub = sub;
    }));

  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());

    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
    }

    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }

    if (this.invitationsSubscription) {
      this.invitationsSubscription.unsubscribe();
    }
  }

  removeUser(user: FarmUser) {
    this.confirmationService.confirm({
      message: this.localeService.constants.stringAreYouSureThatYouWantToRemoveAccess.replace('<userName/>', `${user.firstName} ${user.lastName}`)
    }).subscribe(() => {
      this.doRemoveUser(user);
    });
  }

  doRemoveUser(user: FarmUser): void {
    this.loading = true;
    this.invitationService.removeUserFromFarm(user.userId, this.mainService.farmChanged.value.farmId).subscribe(res => {
      this.loading = false;
      const index = this.farmUsers.findIndex(uf => uf.userId === user.userId);
      this.farmUsers.splice(index, 1);
      this.farmUsers = this.farmUsers.slice();
      this.mainService.removeUser(user);
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringUserSuccessfullyRemoved,
        message: (<string>this.localeService.constants.stringFirstNameLastNameHasBeenRemovedFromFarmName)
          .replace('<firstName/>', user.firstName)
          .replace('<lastName/>', user.lastName)
          .replace('<farmName/>', this.mainService.farmChanged.value.farmName),
        type: 'success'
      });
    }, err => {
      this.loading = false;
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringUserRemoveFailed,
        message: (<string>this.localeService.constants.stringFirstNameLastNameCannotBeRemovedAtThisTimePleaseTryAgainLater)
          .replace('<firstName/>', user.firstName)
          .replace('<lastName/>', user.lastName),
        type: 'warning'
      });
    });
  }

  removeInvitation(invitation: Invitation, index: number) {
    this.confirmationService.confirm({
      message: this.localeService.constants.stringAreYouSureThatYouWantToRemoveAccess.replace('<userName/>', `${invitation.firstName} ${invitation.lastName}`)
    }).subscribe(() => {
      this.doRemoveInvitation(invitation, index);
    });
  }

  doRemoveInvitation(invitation: Invitation, index: number) {
    this.loading = true;
    this.invitationService.removeInvitation(invitation).subscribe(res => {
      this.loading = false;
      this.invitations.splice(index, 1);
      this.invitations = this.invitations.slice();
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringInvitationSuccessfullyRemoved,
        message: (<string>this.localeService.constants.stringFirstNameLastNameHasBeenRemovedFromFarmName)
          .replace('<firstName/>', invitation.firstName)
          .replace('<lastName/>', invitation.lastName)
          .replace('<farmName/>', this.mainService.farmChanged.value.farmName),
        type: 'success'
      });
    }, err => {
      this.loading = false;
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringInvitationRemovalFailed,
        message: (<string>this.localeService.constants.stringInvitationCannotBeRemovedAtThisTimePleaseTryAgainLater)
          .replace('<firstName/>', invitation.firstName)
          .replace('<lastName/>', invitation.lastName),
        type: 'warning'
      });
    })
  }

  openInviteModal(): void {
    const modalRef: NgbModalRef = this.ngbModal.open(InviteModalComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    modalRef.componentInstance.groups = this.groups;
    modalRef.componentInstance.allInvitations = this.invitations;
    modalRef.componentInstance.defaultFarmRoleId = InvitationFarmRoleId.Editor; // 2
    modalRef.componentInstance.invitations.subscribe(invs => this.invitations = invs);
    modalRef.result.then(() => {
      of('start').pipe(

      );
      this.getFarmUsers();
      this.getInvitations();
      this.getGroups();
    }, () => {});
  }

  openEditInvitationModal(uf: User): void {
    const modalRef: NgbModalRef = this.ngbModal.open(EditUserinvitationComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    const component: EditUserinvitationComponent = modalRef.componentInstance;

    component.groups = this.groups;
    component.farmUsers = this.farmUsers;
    component.invitations = this.invitations;
    component.farmUser = uf;

    modalRef.result.then(() => {
      of('start').pipe(

      );
      this.getFarmUsers();
      this.getInvitations();
      this.getGroups();
    }, () => {});
  }

  private getFarmUsers() {
    // unsubscribe before new subscription
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }

    this.usersSubscription = this.mainService.getUsers().subscribe(users => {
      this.farmUsers = users;
    });
  }

  private getInvitations() {
    if (!this.farm) {
      return;
    }

    // unsubscribe before new subscription
    if (this.invitationsSubscription) {
      this.invitationsSubscription.unsubscribe();
    }

    this.invitationsSubscription = this.invitationService.getInvitedUsersForFarm(this.farm.farmId).subscribe(i => {
      this.invitations = i;
    });
  }

  private getGroups() {

    // unsubscribe before new subscription
    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
    }

    // get groups for invitation module
    this.groupsSubscription = this.groupsService.groups.subscribe(groups => {
      if (groups && groups.slice) {
        this.groups = groups.slice();
      }
    });
  }
}

import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subscription, combineLatest} from 'rxjs';

import {LocaleService} from '../../helpers/locale.service';
import {GroupsService} from '../services/groups.service';
import {SessionService} from '../api/session.service';
import {MainService} from '../main.service';
import {SessionGroupSummary} from '../models/session-group-summary.model';
import {ActionsModalService} from './actions-modal/actions-modal.service';
import {SessionRecomendation} from '../models/session-recomendation';
import {ActionsService} from './actions.service';
import {SessionAction} from '../models/session-action';
import {Group} from '../models/group.model';
import {SessionDoableActions} from './shared/session-doable-actions';
import { DatatypeHelper } from '../../helpers/datatype.helper';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnDestroy {
  allSessions: Array<SessionGroupSummary> = null;
  visibleSessions: Array<SessionGroupSummary>;
  actionsCount = 0;

  private sub: Subscription;
  private recommendationsSub: Subscription;

  constructor(private actionsService: ActionsService,
              private route: ActivatedRoute,
              private mainService: MainService,
              private title: Title,
              private sessionService: SessionService,
              private groupsService: GroupsService,
              private localeService: LocaleService,
              public actionsModalService: ActionsModalService) {
  }

  ngOnInit() {
    this.title.setTitle(`${this.localeService.constants.stringActions} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);

    this.sub = combineLatest([
      this.sessionService.sessions, this.groupsService.groups
    ]).subscribe(sessionsAndGroups => {
      const sessions = sessionsAndGroups[0];
      const groups = sessionsAndGroups[1];

      this.handleSessions(sessions, groups);
    });

    // listen for recommendations change
    this.recommendationsSub = this.actionsService.recommendations.subscribe(recommendations => {
      this.handleRecommendations(recommendations);
    });

  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.recommendationsSub) {
      this.recommendationsSub.unsubscribe();
    }
  }

  search(searchWords: string) {
    this.visibleSessions = [];

    if (searchWords && searchWords.replace(' ', '') !== '') {
      let sw = searchWords.split(' ').filter(s => s.length > 0);
      this.allSessions.forEach(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.sessionName.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
          ret += (new Date(s.sessionStartDate).toString()).substr(4, 11).toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        if (ret >= sw.length) {
          this.visibleSessions.push(s);
        }
      });
    } else {
      this.visibleSessions = this.allSessions;
    }
  }

  disableEnableAction(actionId: string, enabled: boolean | 'DONE') {
    const session = this.allSessions.find(s => s.actions.findIndex(a => a.v_ActionId === actionId) >= 0);

    if (session) {
      const action = session.actions.find(a => a.v_ActionId === actionId);
      if (action) {
        action.greyOut = (enabled === true || enabled === false) ? !enabled : true;
        action.done = enabled === 'DONE';

        if (enabled === 'DONE') {
          this.soldDeadModalResult(action);
        }
      }
    }
  }

  soldDeadModalResult(action: SessionAction) {
    if (action.v_ActionId !== null && action.v_ActionId !== undefined) {
      this.actionsService.ignoreWeightRecords(action, false).subscribe(res => {
        // sessions will be updated through service (sessions behaviour subject)

        const session = this.allSessions.find(s => s.actions.findIndex(a => a.v_ActionId === action.v_ActionId) >= 0);
        if (session) {
          const ac = session.actions.find(a => a.v_ActionId === a.v_ActionId);
          if (ac) {
            this.actionsService.ignoreWeightRecords(ac, false).subscribe(() => {});
          }
        }
      });
    }
  }

  private handleSessions(sessions: SessionGroupSummary[], groups: Group[]): void {
    if (sessions !== null && sessions !== undefined && groups !== null && groups !== undefined) {
      if (!this.allSessions) {
        this.allSessions = []
        this.visibleSessions = [];
      }
      sessions.forEach((s:SessionGroupSummary) => {
        let oldMatchingSession = this.allSessions ? this.allSessions.find(ss => ss.sessionId === s.sessionId) : null;
        if (oldMatchingSession) {
          if (!oldMatchingSession.actions) {
            oldMatchingSession.actions = [];
          }
          let actions = s.actions ? s.actions.slice() : [];

          // strip out ignored Actions from both
          oldMatchingSession.actions = oldMatchingSession.actions.filter(a => !a.v_ActionId || !a.v_ActionId.endsWith('_I')).slice();
          actions = actions.filter(a => !a.v_ActionId || !a.v_ActionId.endsWith('_I')).slice();

          // merge previously displayed actions and new coming actions
          for (let i = oldMatchingSession.actions.length - 1; i >= 0; i--) { // we will delete actions that are not applicable any longer.
            if (!oldMatchingSession.actions[i].greyOut) {
              let newAction = actions.find(aa => oldMatchingSession.actions[i].v_ActionId === aa.v_ActionId
                && aa.animalCount === oldMatchingSession.actions[i].animalCount);
              if (!newAction) {
                oldMatchingSession.actions.splice(i, 1);
              }
            }
          }
          actions.forEach(newAction => {
            let oldActionI = oldMatchingSession.actions.findIndex(aa => newAction.v_ActionId === aa.v_ActionId && aa.animalCount === newAction.animalCount);
            if (oldActionI >= 0 && !oldMatchingSession.actions[oldActionI].greyOut) {
              oldMatchingSession.actions[oldActionI] = Object.assign(oldMatchingSession.actions[oldActionI], newAction);
            } else if (oldActionI < 0) {
              newAction.fadeIn = true;
              oldMatchingSession.actions.push(newAction);
            }
          });
        } else {
          const newSession = s
          if (newSession.actions && newSession.actions.length > 0) {
            // strip out ignored Actions
            newSession.actions = newSession.actions.filter(a => !a.v_ActionId || !a.v_ActionId.endsWith('_I')).slice();
            this.allSessions.push(newSession);
          }
        }
      });
      this.allSessions.forEach(s => {
        if (s.actions) {
          s.actions.forEach(a => {
            if (a.v_ActionId && a.v_ActionId.endsWith('_I')) {
              // strip out because ignored
              let ign = s.actions.findIndex(aa => a.v_ActionId === aa.v_ActionId);
              s.actions.splice(ign, 1);
            } else if (a.groupId) {
              a.confirmationRequiredGroup = this.groupsService.groups.value.find(g => g.groupId === a.groupId);
            }
          });
        }
      });
      this.allSessions = this.allSessions.slice().sort((a, b) => new Date(b.importDateTime).getTime() - new Date(a.importDateTime).getTime());
      if (this.actionsService.recommendations.value) {
        this.handleRecommendations(this.actionsService.recommendations.value);
      }
      this.actionsCount = this.allSessions.filter(ss => !ss.hidden && ss.actions && ss.actions.length > 0).length;
    } else {
      this.allSessions = null;
      this.actionsCount = 0;
    }

      this.search('');
  }

  private handleRecommendations(recommendations: SessionRecomendation[]): void {
    if (recommendations && this.allSessions && this.groupsService.groups.value) {
      recommendations.forEach(rec => {
        let sesh = this.allSessions.find(s => rec.sessionId === s.sessionId);
        if (sesh) {
          let action = sesh.actions.find(a => rec.sessionId === a.sessionId && rec.ided === a.ided && !a.groupId),
            group = this.groupsService.groups.value.find(g => g.groupId === rec.groupId);
          if (action) {
            action.recomendedGroup = group;
          }
        }
      });
      this.allSessions.forEach(s => {
        if (s.actions) {
          s.actions.forEach(a => {
            if (a.groupId) {
              a.confirmationRequiredGroup = this.groupsService.groups.value.find(g => g.groupId === a.groupId);
            }
          })
        }
      })
      this.allSessions = this.allSessions.slice();
      this.search('');
    }
  }

  dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'D MMM YYYY');
  }
}



  <!-- details -->
  <ng-container *ngIf="step === 1">
    <app-new-group-details
      [group]="group"
      [sessionAction]="sessionAction"
      [templates]="templates"
      [step]="step"
      (nextStep)="nextStep($event)"
      (prevStepOrCancel)="prevStepOrCancel($event)"
      (setCanClose)="resetCanClose($event)">
    </app-new-group-details>
  </ng-container>

  <!-- group plans -->
  <ng-container *ngIf="step === 2">
    <app-new-group-plans
      [group]="group"
      [templates]="templates"
      [step]="step"
      [sessionAction]="sessionAction"
      (nextStep)="nextStep($event)"
      (prevStepOrCancel)="prevStepOrCancel($event)"
      (setCanClose)="resetCanClose($event)">
    </app-new-group-plans>
  </ng-container>

  <!-- group plans adjustments -->
  <ng-container *ngIf="step === 3">
    <app-new-group-plan-adjustment
      [group]="group"
      [action]="sessionAction"
      [templates]="templates"
      [step]="step"
      (nextStep)="nextStep($event)"
      (prevStepOrCancel)="prevStepOrCancel($event)"
      (setCanClose)="resetCanClose($event)">
    </app-new-group-plan-adjustment>
  </ng-container>

  <!-- finalisation -->
  <ng-container *ngIf="step === 4">
    <app-new-group-finilisation
      [group]="group"
      [sessionAction]="sessionAction"
      [assignAction]="assignAction"
      [step]="step"
      (finishModifications)="finishModifications($event)"
      (prevStepOrCancel)="prevStepOrCancel($event)"
      (setCanClose)="resetCanClose($event)">
    </app-new-group-finilisation>
  </ng-container>

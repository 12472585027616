<app-loading *ngIf="!allImports"></app-loading>

<ng-container *ngIf="allImports">
  <div class="row mb-3 flex-align-items-center">

    <div class="col-md-6 d-flex">

      <div class="custom-control custom-checkbox mr-md-3">
        <input [(ngModel)]="currentFarmOnly" (change)="getImportsForDates();" id="checkbox1" type="checkbox" class="custom-control-input">
        <label for="checkbox1" class="custom-control-label">Show only files for this farm</label>
      </div>

      <div class="custom-control custom-checkbox mr-md-3">
        <input [(ngModel)]="ignoreSuccessfulFiles" (change)="getImportsForDates();" id="checkbox2" type="checkbox" class="custom-control-input">
        <label for="checkbox2" class="custom-control-label">Ignore successful files</label>
      </div>

      <div class="custom-control custom-checkbox mr-md-3">
        <input [(ngModel)]="isIgnoreDuplicateUpload" (change)="ignoreDuplicateUpload();" id="checkbox3" type="checkbox" class="custom-control-input">
        <label for="checkbox3" class="custom-control-label">Ignore duplicate upload</label>
      </div>

    </div>

    <div class="col-md-3">
      <input class="form-control form-control-sm w100p" type="date" name="" (input)="startDateChanged(parseDate($event.target.value));"
             [value]="startDate | date: 'yyyy-MM-dd'" [max]="endDate | date: 'yyyy-MM-dd'" placeholder="From">
    </div>

    <div class="col-md-3">
      <input class="form-control form-control-sm w100p" type="date" name="" (input)="endDate=parseDate($event.target.value); getImportsForDates();"
             [value]="endDate | date: 'yyyy-MM-dd'" [min]="startDate | date: 'yyyy-MM-dd'" placeholder="To">
    </div>

  </div>

  <div class="card card-table">

    <!-- new table -->
    <p-table [value]="imports" rowHover="true" class="table-text-wrap" #dataTable dataKey="sessionImportAuditId" tableStyleClass="table table-hover mb-0">

      <!-- header -->
      <ng-template pTemplate="header">
        <tr class="ui-widget-content">
          <th class="ui-state-default" style="width: 3em"></th>
          <th class="ui-state-default">Farm Name</th>
          <th class="ui-state-default">File Name</th>
          <th class="ui-state-default">User Name</th>
          <th class="ui-state-default">User Agent</th>
          <th class="ui-state-default">Date</th>
          <th class="ui-state-default">Successful</th>
          <th class="ui-state-default">Download</th>
        </tr>
      </ng-template>

      <!-- rows body -->
      <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr class="ui-widget-content" [pRowToggler]="rowData">
          <td style="width: 3em">
            <a href="javascript:void(0)">
              <i class="fa" [ngClass]="expanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            </a>
          </td>
          <td>{{ rowData.farmName }}</td>
          <td>{{ rowData.sessionFile?.fileName }}</td>
          <td>{{ rowData.userName }}</td>
          <td>{{ rowData.userAgent }}</td>
          <td>{{ rowData.created | date:'d MMM yyyy HH:mm:ss' }}</td>
          <td>
            <i *ngIf="!rowData.errors || rowData.errors.length === 0" class="fa fa-check-circle fa-2x class-local-1"></i>
            <i *ngIf="rowData.errors && rowData.errors.length > 0" class="fa fa-times-circle fa-2x class-local-2"></i>
          </td>
          <td>
            <button class="btn btn-secondary btn-sm" *ngIf="rowData.sessionFile" type="button"
                    (click)="$event.stopPropagation(); downloadSession(rowData.sessionImportAuditId, false);" i18n="button for downloading@@csv">CSV
            </button>
            <button class="btn ml-1 btn-secondary btn-sm" *ngIf="rowData.sessionFile" type="button"
                    (click)="$event.stopPropagation(); downloadSession(rowData.sessionImportAuditId, true);" i18n="button for downloading@@raw">RAW
            </button>
          </td>
        </tr>
      </ng-template>

      <!-- row expansion -->
      <ng-template pTemplate="rowexpansion" let-rowData>
        <ng-container *ngIf="rowData.errors && rowData.errors.length > 0">
          <tr class="ui-expanded-row-content">
            <td [attr.colspan]="8">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 80px; text-align: left;">Error Code</th>
                    <th style="width: 220px; text-align: left;">Error Result</th>
                    <th style="width: 100px; text-align: left;">Row</th>
                    <th style="width: 100px; text-align: left;">Column</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let error of rowData.errors">
                    <td [title]="error.ErrorResultCode">{{error.ErrorResultCode}}</td>
                    <td [title]="error.ErrorResult">{{error.ErrorResult}}</td>
                    <td [title]="error.Row">{{error.Row}}</td>
                    <td [title]="error.Column">{{error.Column}}</td>
                    <td [title]="error.Description">{{error.Description}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </ng-template>

    </p-table>

  </div>
</ng-container>

<!-- farm form -->
<div class="card">

  <div class="card-header">
    <h3 class="m-0" i18n="@@farmdetails">Farm Details</h3>
  </div>

  <div class="card-body">

    <form [formGroup]="form" (ngSubmit)="save($event)" class="form-horizontal" *ngIf="form && mainService.farmChanged.value">

      <!-- farm name -->
      <app-form-input [control]="form.get('farmName')" i18n-label="@@farmname" label="Farm Name"
                      i18n-error="@@farmnameisrequired" error="Farm name is required">
        <input type="text" formControlName="farmName" class="form-control">
      </app-form-input>

      <!-- address 1 -->
      <app-form-input [control]="form.get('addressLine1')" i18n-error="@@addressisrequired" error="Address is required" i18n-label="@@address1" label="Address 1">
        <input type="text" formControlName="addressLine1" class="form-control">
      </app-form-input>

      <!-- address 2 -->
      <app-form-input [control]="form.get('addressLine2')" i18n-error="@@addressisrequired" error="Address is required" i18n-label="@@address2" label="Address 2">
        <input type="text" formControlName="addressLine2" class="form-control">
      </app-form-input>

      <!-- city -->
      <app-form-input [control]="form.get('city')" i18n-error="@@towncitynameisrequired" error="Town/City name is required" i18n-label="@@towncity" label="Town/City">
        <input type="text" formControlName="city" class="form-control">
      </app-form-input>

      <!-- post code -->
      <app-form-input [control]="form.get('postcode')" i18n-error="@@postcodeisrequired" error="Post Code is required" i18n-label="@@postcode" label="Post Code">
        <input type="text" formControlName="postcode" class="form-control">
      </app-form-input>

      <!-- region -->
      <app-form-input [control]="form.get('region')" i18n-error="@@regionisrequired" error="Region is required" i18n-label="@@region" label="Region">
        <ng-select
          formControlName="region"
          [items]="regions"
          [clearable]="false">
        </ng-select>
      </app-form-input>

      <!-- country -->
      <app-form-input [control]="form.get('country')" i18n-error="@@countryisrequired" error="Country is required" i18n-label="@@country" label="Country">
        <ng-select
          formControlName="country"
          [items]="countries"
          bindLabel="countryName"
          bindValue="countryName"
          [clearable]="false"
          (change)="regions = $event.regionsArray; form.controls['region'].setValue(null)">
        </ng-select>
      </app-form-input>

      <!-- submit button -->
      <div class="mt-3">
        <button *ngIf="permissionsService.permissions.farmUsersView" type="submit" [disabled]="!formHasChanged" class="btn btn-primary" i18n="@@savechanges">
          Save Changes
        </button>
      </div>

    </form>
  </div>
</div>

<!-- delete farm -->
<div *ngIf="permissionsService.permissions.farmDeleteAll && hasDeleteQuery" class="card mt-5">
  <div class="card-header">
    <h3 class="m-0" i18n="@@delete">Delete</h3>
  </div>
  <div class="card-body">
    <button *ngIf="uploadStatus == 0" (click)="deleteAllSessionAndAnimals()" class="btn btn-danger btn-lg" i18n="@@deleteallsessions">Delete all sessions and animals</button>
    <div *ngIf="uploadStatus == 1">
      <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
      <span class="sr-only" i18n="@@loading">Loading...</span>
    </div>
  </div>
</div>

<!-- reset farm -->
<div *ngIf="mainService.farmChanged.value && mainService.farmChanged.value.farmType === 'Demo'" class="card mt-5">
  <div class="card-header">
    <h3 class="m-0" i18n="@@reset">Reset</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <button (click)="resetFarm();" class="btn btn-danger btn-lg" i18n="@@resetdemoacct">Reset Demo account to its defaults</button>
      </div>
      <div class="col-md-6 text-right">
        <button (click)="deleteDemoFarm();" class="btn btn-danger btn-lg" i18n="@@deletedemofarm">Delete Demo farm</button>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="requestStatus === 1 || loading"></app-loading>

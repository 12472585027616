import { SessionSummary } from '../../../models';
import { Component, Input } from '@angular/core';
import { DatatypeHelper } from '../../../../helpers/datatype.helper';

@Component({
  selector: 'app-animal-sessions-list',
  templateUrl: './animal-sessions-list.component.html',
  styleUrls: ['./animal-sessions-list.component.scss']
})
export class AnimalSessionsListComponent {

  @Input()
  sessions: Array<SessionSummary>;

  constructor() { }

  dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'D MMM YYYY');
  }

}

<div class="modal-header modal-header-side-menu">
  <h3 class="text-capitalize">{{ mainService.farmChanged.value?.farmName }}</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body">

  <ng-container *ngIf="showSettingsAndUsers">
    <h4 class="margin-x">{{ mainService.farmChanged.value?.farmName }}</h4>
    <div class="list-group list-group-flush mb-5">
      <a class="list-group-item list-group-item-action" (click)="ngbActiveModal.close()" routerLink="/main/users" i18n="@@users">
        <i class="fas fa-users"></i> Users
      </a>
      <a class="list-group-item list-group-item-action" (click)="ngbActiveModal.close()" routerLink="/main/settings" i18n="@@settings">
        <i class="fas fa-cog"></i> Settings
      </a>
    </div>
  </ng-container>

  <h4 class="margin-x" i18n="@@changeFarm">Change Farm</h4>
  <div class="list-group list-group-flush mb-5">

    <div class="form-group form-group-sm margin-x" *ngIf="mainService.farmsChanged.value?.length > farmLengthLimit">
      <input class="form-control form-control-sm" [(ngModel)]="farmsFilter" i18n-placeholder="@@filterfarms" placeholder="Filter farms">
    </div>

    <cdk-virtual-scroll-viewport
      minBufferPx="500"
      maxBufferPx="800"
      [itemSize]="farmHeight"
      [style.height]="mainService.farmsChanged.value?.length > farmLengthLimit ? farmLengthLimit*farmHeight + 'px' : mainService.farmsChanged.value?.length * farmHeight + 'px'"
      style="overflow: auto;">
      <button class="list-group-item list-group-item-action farm" *cdkVirtualFor="let farm of getFilteredFarms()" (click)="changeFarm(farm)" [style.min-height.px]="farmHeight">
        <span class="d-flex flex-nowrap flex-column">
            <strong>{{ farm.farmName }}</strong>
            <span class="desc" *ngIf="authService.currentUser.userType === 'SuperUser'">
              <span>ID: {{ farm.farmId }}</span>
              <span *ngIf="!farm.subscriptionDaysLeft || farm.subscriptionDaysLeft < 0" class="expired text-warning">(Expired)</span>
            </span>
            <span class="desc" *ngIf="authService.currentUser.userType !== 'SuperUser'">
              {{ farm.country || farm.countryLookup?.countryName }}
            </span>
        </span>
        <i [ngClass]="farm.farmId === authService.currentUser.currentFarm_FarmId ? 'fas fa-check-circle text-success' : 'fal fa-circle'"></i>
      </button>
    </cdk-virtual-scroll-viewport>
  </div>

  <ng-container *ngIf="authService.currentUser.userType !== 'SuperUser' && (mainService.canCreateFarms || authService.isDatamarsUser)">
    <h4 class="margin-x" i18n="@@createnewfarm">Create new farm</h4>
    <div class="list-group list-group-flush mb-5">
      <button *ngIf="mainService.canCreateFarms" class="list-group-item list-group-item-action" (click)="createNewFarm()" i18n="@@createnewfarm">
        <i class="fas fa-plus-circle"></i> Create new farm
      </button>
      <button *ngIf="authService.isDatamarsUser" class="list-group-item list-group-item-action" (click)="createNewDemoFarm()" i18n="@@createnewdemofarm">
        <i class="fas fa-plus-circle"></i> Create new demo farm
      </button>
    </div>
    <span></span>
  </ng-container>

</div>

<button type="button" hidden ngbAutofocus></button>

import {Component, Input, Output, EventEmitter, TemplateRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  @Input() messageTitle: string;
  @Input() messageBody: string;

  @Input() messageTitleTemplate: TemplateRef<any>;
  @Input() messageBodyTemplate: TemplateRef<any>;

  @Input() keys: any;


  @Output() userConfirmed = new EventEmitter<any>();
  @Output() userCancelled = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal) {
  }

  confirm(): void {
    this.activeModal.close();
  }

  reject(): void {
    this.activeModal.dismiss();
  }
}

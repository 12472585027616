import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Breadcrumb} from './breadcrumb';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() headerTitle: string;

  breadcrumbs: Breadcrumb[];

  constructor(public router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '/', breadcrumbs = []): any[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    const routeURL: string = children[0].snapshot.url.map(segment => segment.path).join('/');
    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    const label = children[0].snapshot.data.breadcrumb;
    if (label) {
      breadcrumbs.push({ label, url });
    }

    return this.createBreadcrumbs(children[0], url, breadcrumbs);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {FormValidation} from '../../../../helpers/form-validation.helper';
import {BottomNotificationsService} from '../../../../components/bottom-notifications/bottom-notifications.service';
import {AuthService} from '../../../../auth/auth.service';

@Component({
  templateUrl: './admin-reset-password.component.html',
  styleUrls: ['./admin-reset-password.component.scss']
})
export class AdminResetPasswordComponent implements OnInit {
  @Input() pResetEmail: string;

  form: FormGroup;
  pResetCode = '';

  private opened = false;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    private bottomNotificationsService: BottomNotificationsService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required])),
    }, FormValidation.validateConfirmPassword);

    this.form.valueChanges.subscribe(() => {
    });
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.form.get('confirmPassword');
  }

  resetPassword() {
    if (this.form.valid) {
      this.pResetCode = '';
      this.authService.superAdminGetResetCode(this.pResetEmail).subscribe((c) => {
        if (c !== null && c.length > 0) {
          this.pResetCode = c.split('"').join('');
          this.authService.resetPassword(this.pResetEmail,
            this.form.get('password').value, this.form.get('confirmPassword').value, this.pResetCode).subscribe((res) => {
              this.bottomNotificationsService.currentMessage.next({
                title: 'Reset Password Succeeded',
                message: 'Password was reset for ' + this.pResetEmail,
                type: 'success'
              });
              this.ngbActiveModal.close();
            },
            e => {
              this.bottomNotificationsService.currentMessage.next({
                title: 'Reset Password Failed',
                message: 'Token invalid or expired, or an invalid password was used. Reset password again',
                type: 'warning'
              });
            });
        } else {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Reset Password Failed',
            message: 'Token invalid or expired. Reset password again',
            type: 'warning'
          });
        }
      });
    } else {
      this.form.controls['password'].markAsDirty();
      this.form.controls['confirmPassword'].markAsDirty();
    }
  }
}

import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Validators, FormGroup, FormControl, AbstractControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {LocaleService} from '../../../helpers/locale.service';
import {AuthService} from '../../../auth/auth.service';
import {Invitation} from '../../models';
import {FormValidation} from '../../../helpers/form-validation.helper';
import {MainService} from '../../main.service';
import {FarmUser} from '../../models/farm-user.model';
import {Group, GroupCensusWeightSummary} from '../../models/group.model';
import {InvitationService} from '../../api/invitation.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {InvitationFarmRoleId} from '../../models';
import {Rounder} from '../../../helpers/rounder.helper';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupsService} from '../../services/groups.service';
import {GlobalLoaderService} from '../../../core/global-loader.service';

@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit, OnDestroy {
  @Input() groups: Array<Group> = [];
  @Input() defaultFarmRoleId: InvitationFarmRoleId = null;
  @Input() defaultGroup: Group = null;
  @Input() allInvitations: Array<Invitation> = [];

  @Output() invitations = new EventEmitter<Array<Invitation>>();

  form: FormGroup = null;
  invitation: Invitation;
  errorMessage: string = null;
  userFarms: Array<FarmUser>;
  selectedGroupMessage = this.localeService.constants.stringSelectGroups;
  selectedGroupsCount = 0;

  selectedGroups = this.groups && this.defaultGroup ? this.groups.filter(group => group.groupId === this.defaultGroup.groupId) : [];

  displayGroups: Array<Group> = [];

  private subs: Array<Subscription> = [];

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private invitationService: InvitationService,
    private bottomNotificationsService: BottomNotificationsService,
    public localeService: LocaleService,
    private ngbActiveModal: NgbActiveModal,
    private groupsService: GroupsService,
    private globalLoaderService: GlobalLoaderService
  ) {
  }

  ngOnInit() {
    this.subs.push(this.mainService.farmChanged.subscribe(farm => {
      if (farm && this.authService.currentUser) {
        this.invitation = new Invitation(this.authService.currentUser.userId, farm.farmId);
        this.subs.push(this.mainService.getUsers().subscribe(users => {
          this.userFarms = users;
        }));

        this.generateForm();

        if (this.allInvitations && this.allInvitations.length === 0) {
          this.invitationService.getInvitedUsersForFarm(farm.farmId).subscribe(i => {
            this.allInvitations = i;
          });
        }
      }
    }));

    this.groupsService.groups.subscribe(groups => {
      this.groups = groups;
      this.selectedGroups = this.groups && this.defaultGroup ? this.groups.filter(group => group.groupId === this.defaultGroup.groupId) : [];

      this.setDisplayGroups();
    });
    this.generateForm();
  }

  setDisplayGroups() {
    this.displayGroups = this.groups.filter(g => g.isCurrent);
    this.selectedGroups.filter(sg => !sg.isCurrent).forEach(s =>
      this.displayGroups.push(s)
    );
  }

  groupsFiltered(searchWords: string) {
    if (searchWords && searchWords.replace(' ', '') !== '') {
      let sw = searchWords.split(' ').filter(s => s.length > 0);
      this.displayGroups = this.groups.filter(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.groupName.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        return ret >= sw.length;
      });
    } else {
      this.setDisplayGroups();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  updateSelectedGroupCount() {
    this.selectedGroupMessage = this.localeService.constants.stringSelectGroups;
    if (this.displayGroups) {
      this.selectedGroupsCount = this.displayGroups.filter(g => g.selected).length;
      switch (this.selectedGroupsCount) {
        case 0:
          this.selectedGroupMessage = this.localeService.constants.stringSelectGroups;
          break;
        case 1:
          this.selectedGroupMessage = this.displayGroups.filter(g => g.selected)[0].displayName;
          break;
        case 2:
          this.selectedGroupMessage = this.displayGroups.filter(g => g.selected)[0].displayName + ', ' + this.displayGroups.filter(g => g.selected)[1].displayName;
          break;
        default:
          this.selectedGroupMessage = this.selectedGroupsCount + ' ' + this.localeService.constants.stringGroups;
          break;
      }
    }
  }

  cancelClicked() {
    this.hideDialog();
  }

  hideDialog() {
    this.errorMessage = null;
    this.invitations.emit(this.allInvitations);
    this.ngbActiveModal.close(this.allInvitations);
  }

  generateForm() {
    if (this.groups) {
      this.groups.forEach(g => g.selected = false);
      if (this.defaultGroup) {
        const group = this.groups.find(g => g.groupId === this.defaultGroup.groupId);

        if (group) {
          group.selected = true;
        }
        if (!this.displayGroups.find(dg => dg.groupId === group.groupId)) {
          this.displayGroups.push(group);
        }
      }
    }

    this.updateSelectedGroupCount();
    this.form = new FormGroup({
      farmRoleId: new FormControl((<number>this.defaultFarmRoleId) || (<number>InvitationFarmRoleId.Editor), Validators.required),
      firstName: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.mainService.usernameValidationRegex)])),
      lastName: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.mainService.usernameValidationRegex)])),
      email: new FormControl('', Validators.compose([FormValidation.validateEmail, Validators.required])),
      dummyField: new FormControl('')
    });
  }

  get farmRoleId(): AbstractControl {
    return this.form && this.form.get('farmRoleId');
  }

  sendInvitation() {
    let email = this.form.controls['email'].value.toLowerCase().trim();
    // Check to see if such user already exists for current farm or has already been invited
    if (this.form.valid && this.userFarms.find(uf => uf.email.toLowerCase() === email)
      || this.allInvitations.find(inv => inv.email.toLowerCase() === email)
    ) {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Invitation failed to send',
        message: `This user has alredy been invited to the farm`,
        type: 'warning'
      });
    } else if (this.form.valid) {
      delete this.form.value['dummyField'];
      this.invitation = Object.assign(this.invitation, this.form.value);
      this.invitation.email = email;

      this.invitation.groupIdsJson = JSON.stringify(this.selectedGroups.map(g => {
        return {
          GroupId: g.groupId,
          DisplayName: g.displayName,
          Adg: g.adg ? g.adg : this.localeService.constants.stringNA
        }
      }));

      this.invitation.groupIdsCensusJson = JSON.stringify(this.selectedGroups.map(g => this.formatCensus(g.groupId, g.adg, g.currentStatus)));
      this.globalLoaderService.showGlobalLoader = true;

      this.invitationService.sendInvitation(this.invitation)
        .pipe(catchError(err => {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Invitation failed to send',
            message: `Invitation failed to send. Please try again later.`,
            type: 'warning'
          });
          this.globalLoaderService.showGlobalLoader = false;
          this.hideDialog();
          return this.errorMessage = JSON.parse(err.error).error.message;
        }))
        .subscribe(invitation => {
          this.globalLoaderService.showGlobalLoader = false;

          this.allInvitations.push(<Invitation>invitation);
          this.invitations.emit(this.allInvitations);
          this.bottomNotificationsService.currentMessage.next({
            title: 'Invitation sent',
            message: `An invitation has been sent to ${this.invitation.email},
             they will be added to this farm once they accept the invitation.`,
            type: 'success'
          });
          this.hideDialog();
        });
    } else {
      this.markAllFieldsAsDirty();
    }
  }

  /* round the figures as in UI, to make the numbers consistent in UI and eMail. refer #MIHUBDRY-4403  */
  private formatCensus(groupId: number, adg: number, census: GroupCensusWeightSummary): GroupCensusWeightSummary {
    let formattedCensus = Object.assign({}, census);

    /*
    groupId property in 'current Status' model would be empty if the all animals' are died or sold (problematic sql query logic)
    Invitation census model need GroupId, hence pass groupid if census.groupid is null
    */
    formattedCensus.group_GroupId = formattedCensus.group_GroupId || groupId;
    formattedCensus.censusAdg = adg;

    if (formattedCensus.animalAdg != undefined) {
      formattedCensus.animalAdg = Rounder.round(formattedCensus.animalAdg);
    }

    if (formattedCensus.averageWeight != undefined) {
      formattedCensus.averageWeight = Rounder.round(formattedCensus.averageWeight);
    }
    return formattedCensus;
  }

  markAllFieldsAsDirty() {
    this.form.controls['farmRoleId'].markAsDirty();
    this.form.controls['email'].markAsDirty();
    this.form.controls['firstName'].markAsDirty();
    this.form.controls['lastName'].markAsDirty();
  }
}

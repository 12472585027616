
<div class="modal-header" >
  <h3 i18n="@@targetweighttemplates">TARGET WEIGHT TEMPLATES</h3>
  <app-new-group-line [group]="group" [templates]="templates" [step]="step" [forcedTemplatesStepDisplay]="true" ></app-new-group-line>
  <a (click)="prevStepOrCancel.next('cancel')" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body" >

  <h4 class="mb-4" i18n="@@selectOneOfPredefinedTemplatesOrCreateCustomOne">Select one of predefined templates or create custom one</h4>

  <div class="fixed-width-blocks templates" >

    <!-- templates list -->
    <div *ngFor="let template of templates" class="fixed-width-block template" [class.active]="selectedGraph === template" (click)="planSelected(template)">

      <!-- template name -->
      <div class="template-name">
        <h1>
          {{template.planTemplateName}}
        </h1>
      </div>

      <!-- template data -->
      <div class="template-data">

        <!-- start -->
        <div class="template-data-column start">
          <div class="template-data-cell" i18n="@@start">Start</div>
          <div class="divider"></div>
          <div class="template-data-cell">
            <div>{{template.startMonth}}</div>
            <div class="number">{{template.startWeight}}<span>kg</span></div>
          </div>
        </div>

        <!-- finish -->
        <div class="template-data-column finish">
          <div class="template-data-cell" i18n="@@finish">Finish</div>
          <div class="divider"></div>
          <div class="template-data-cell">
            <div>{{template.endMonth}}</div>
            <div class="number">{{template.endWeight}}<span>kg</span></div>
          </div>
        </div>

      </div>
    </div>

    <!-- custom -->
    <div class="fixed-width-block template custom" [class.active]="selectedGraph && selectedGraph.targetWeightPlanTemplateId === 0" (click)="planSelected(null)">
      <div class="text-center">
        <h1 i18n="@@custom">Custom</h1>
        <i class="fas fa-3x fa-plus mt-2"></i>
      </div>
    </div>

  </div>
</div>

<div class="modal-footer" >
  <a href="javascript:void(0) " class="btn btn-link" (click)="prevStepOrCancel.next('cancel');" i18n="@@cancel">Cancel</a>
  <button class="btn btn-secondary ml-1" (click)="prevStepOrCancel.next('prev')" i18n="@@back">Back</button>
  <button [disabled]="!selectedGraph" class="btn btn-primary ml-1" (click)="next() " i18n="@@next">Next</button>
</div>

import {Component, ViewChild, NgZone} from '@angular/core';
import {OnInit, OnDestroy, HostListener} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';
import {CookieService} from 'ngx-cookie';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localeNz from '@angular/common/locales/en-NZ';
import localeEn from '@angular/common/locales/en';

import {DraftsService} from './api/drafts.service';
import {SubscriptionService} from './api/subscription.service';
import {MainGuard} from './main-guard.service';
import {EventAuditService} from './api/event-audit.service';
import {PermissionsService} from '../auth/permissions.service';
import {IntercomService} from './intercom.service';
import {MainService} from './main.service';
import {ScriptService} from './script.service';
import {AuthService} from '../auth/auth.service';
import {FarmService, SignalRService, SignalrType} from './api';
import {environment} from '../../environments/environment';
import {WeightRecordService} from './api';
import {SessionImportAuditsService} from './api/session-import-audits.service';
import {SessionService} from './api/session.service';
import {GlobalParameterService} from '../helpers/global-parameter.service';
import {GaService} from '../gaService';
import {NpsSurveyService} from './nps-survey.service';
import {ActionService} from './api/action.service';
import {ApiService} from './api/api.service';
import {Farm} from './models/index';
import {UserService} from './api/user.service';
import {EventAudit} from './models/event-audit';
import {map} from 'rxjs/operators';
import {LocaleService} from '../helpers/locale.service';
import {SpeciesService} from './api/species.service';
import {IntegrationService} from './api/integration.service';
import {GlobalLoaderService} from '../core/global-loader.service';

registerLocaleData(localeEn, 'en-us');
registerLocaleData(localeNz, 'en-nz');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localePt, 'pt');

declare let $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [GlobalParameterService, SessionImportAuditsService, NpsSurveyService]
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('dropDownButton', { static: false }) dropDownButton;
  @ViewChild('changeFarmButton', { static: false }) changeFarmButton;
  imgLocations = environment.imgLocations;
  topRightMenuDropdown = false;
  changeFarmDropdown = false;
  lang = 'en';
  url: string;
  window = window;
  private groupViewerBlockedUrl = ['/main/dashboard', '/main/actions', '/main/transfer', '/main/wow-dashboard'];
  private subs: Array<Subscription> = [];

  @ViewChild('autocomplete', { static: false }) autocomplete;
  public filteredFarms: Array<Farm> = [];
  text: string;

  constructor(public authService: AuthService,
              public router: Router,
              private route: ActivatedRoute,
              public mainService: MainService,
              private intercomService: IntercomService,
              private farmService: FarmService,
              private userService: UserService,
              private permissionsService: PermissionsService,
              private eventAuditService: EventAuditService,
              private scriptService: ScriptService,
              private cookieService: CookieService,
              private gaService: GaService,
              private npsSurveyService: NpsSurveyService,
              private apiService: ApiService,
              private ngZone: NgZone,
              private signalRService: SignalRService,
              private mainGuard: MainGuard,
              private localeService: LocaleService,
              private speciesService: SpeciesService,
              private integrationService: IntegrationService,
              private globalLoaderService: GlobalLoaderService) {
  }

  @HostListener('window:click', ['$event.target'])
  windowClicked(clickedEl) {
    if (this.topRightMenuDropdown && (clickedEl !== this.dropDownButton.nativeElement)) {
      this.topRightMenuDropdown = false;
    }
    if (this.changeFarmDropdown && (clickedEl !== this.changeFarmButton.nativeElement)) {
      this.changeFarmDropdown = false;
    }
  }

  toggleLeftMenu() {
    this.mainService.leftMenuOpen.emit();
  }

  changeFarm(newFarm: any) {
    this.changeFarmDropdown = false;
    this.mainService.changeFarm(newFarm.farmId);
  }

  autoSelected(newFarm: any) {
    this.mainService.changeFarm(newFarm.farmId);
    // need to clear input now
    this.text = null;
  }

  filterFarms(event) {
    let filter1 = event.query;
    if (filter1 && filter1.length > 0) {
      this.filteredFarms = this.mainService.farmsChanged.value ? this.mainService.farmsChanged.value.filter(function (f) {
        let n = f.farmName.toLowerCase().indexOf(filter1.toLowerCase());
        let i = f.farmId.toString().indexOf(filter1.toLowerCase());
        return n > -1 || i > -1;
      }).slice(0, 100) : [];
    } else {
      this.filteredFarms = null;
    }
  }

  logoutButtonClicked() {
    this.apiService.post('/api/UserActivity',
      {
        category: 2,
        timeStamp: new Date(),
        eventName: 'Logout',
        pageName: this.router.url,
        farm_FarmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
        userId: this.authService.currentUserChanged.value.userId,
        userGenerated: true
      }, 'text'
    ).subscribe(res => {
    }, err => {
    });
    this.authService.logout();
  }

  private pplRedirect() {
    if (this.mainService.farmChanged.value && this.router.url.split(`/`).join('').endsWith('main')) {
      setTimeout(() => {
        if (this.mainService.farmChanged.value.integrationName === 'PPL') {
          this.router.navigate(['/main/wow-dashboard']);
        } else {
          this.router.navigate(['/main/dashboard']);
        }
      });
    }
  }

  public ngOnInit(): void {
    // this.globalLoaderService.showGlobalLoader = true;
    this.scriptService.load('highcharts').then(data => {
      this.lang = this.cookieService.get('locale') || 'en';
      let scriptsToLoad = ['hcBoost', 'hcMore', 'exporting', 'offlineExporting', 'momentJs', 'wootric', 'jsTimezone'];
      /*
      if (this.lang && this.lang !== 'en' && this.lang.length === 2) {
          scriptsToLoad.push('momentJs-' + this.lang);
      }
      */
      this.scriptService.loadArray(scriptsToLoad).then(laterData => {
        this.subs.push(this.authService.currentUserChanged.subscribe(user => {
          // if (user && !this.intercomConnected) {
          //     this.intercomConnected = this.intercomService.connect();
          // }
          if (user) {
            this.gaService.updateUser(user);
            this.npsSurveyService.runNpsSurvey(user);
            if (user.userType === 'SuperUser') {
              this.userService.getUsers(null, null).subscribe(users => {
              });
            }

          }
        }));
        this.subs.push(this.mainService.farmChanged.subscribe(farm => {
          this.mainService.setMomentLocale();
          if (farm && this.router.url.split(`/`).join('').endsWith('main') && this.permissionsService.permissions.canAccessPpl) {
            this.pplRedirect();
          } else if (farm && (this.router.url.split(`/`).join('').endsWith('main') || this.groupViewerBlockedUrl.find(url => this.router.url.indexOf(url) >= 0))
            && this.permissionsService.permissions.groupViewOnly) {
            {
              this.router.navigate(['/main/groups/all']);
              return false;
            }
          }
        }));

        this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd) || !window['ga']) {
            return;
          }

          let index = this.router.url.indexOf('?'),
            newUrl = index < 0 ? this.router.url : this.router.url.substring(0, index);
          if (newUrl !== this.url) {
            window.scrollTo(0, 0);
          }

          this.pplRedirect();
          this.url = newUrl;
        });
      });
    });

    // TODO: moved from service
    const sub1 = this.authService.currentUserChanged.subscribe(user => {
      if (user && user.userType !== 'SuperUser' && !this.mainService.farmsChanged.value) {
        this.mainService.updateFarms();
      } else if (user && user.userType === 'SuperUser' && !this.mainService.farmsChanged.value) {
        // get the current Farm first, then load all the Farms
        this.mainService.getFarmForSuperUser();
      }
    });

    // TODO: moved from service
    // reconect signalr when farm changed
    const sub2 = combineLatest([this.mainService.farmsChanged, this.authService.currentSubscription]).subscribe(farmAndSub => {
      const farms = farmAndSub[0], sub = farmAndSub[1];
      // Checking if user has permisison to create new farms.
      if (farms) {
        this.mainService.canCreateFarms = sub === null || (sub.subscriptionPlan && farms &&
          sub.subscriptionPlan.farmLimit > farms.filter(f => f.subscription_SubscriptionId === sub.subscriptionId && f.farmType.toLowerCase() !== 'demo').length)
          ? true : false;
      }
    });

    // TODO: moved from service
    // reconect signalr when farm changed
    const sub3 = this.mainService.farmChanged.subscribe(farm => {
     //  console.log(farm);
      this.speciesService.species = null;
      this.mainService.farmUsers = null;

      // Since main guard is inited before this service need to manualy set current farm
      if (farm) {
        /* Beta logic. This logic will not affect feature or test */
        if (this.mainService.farmId !== farm.farmId && farm.beta === true && (window.location.href.toLowerCase().indexOf('/livestock.mihub.tru-test.com') > 0
          || window.location.href.toLowerCase().indexOf('release.livestock.mihub.tru-test.com') > 0)) { // we are beta but on production
          setTimeout(() => {
            window.location.href = 'https://beta.livestock.mihub.tru-test.com/#/?token=' + this.authService.getToken();
          });
        } else if (this.mainService.farmId !== farm.farmId && !farm.beta
          && window.location.href.toLowerCase().indexOf('beta.livestock.mihub.tru-test.com') > 0) { // we are not beta but on beta branch
          setTimeout(() => {
            window.location.href = 'https://livestock.mihub.tru-test.com/#/?token=' + this.authService.getToken();
          });
        } else {
          // (RE)Initing signalR when farm has changed. Mast have
          this.signalRService.connect(farm.farmId);
          this.mainGuard.currentFarm = farm;
          this.mainService.farmId = farm.farmId;
        }
        this.mainService.setMomentLocale(); // ensure moment locale is updated to match whenever the farm changes
      } else {
        this.mainGuard.currentFarm = null;
      }

      if (farm) {
        this.integrationService.getIntegrations(farm.farmId).subscribe(int => {
          this.integrationService.integration.next(int);
        }, err => {
          this.integrationService.integration.next(null);
        });
      } else {
        this.integrationService.integration.next(null);
      }
    });

    this.subs.push(sub1);
    this.subs.push(sub2);
    this.subs.push(sub3);

    // TODO: moved from event audit service
    this.localeService.getEventAuditTemplates().subscribe(e => {
      this.eventAuditService.eventAuditTemplate = e;

      const sub4 = this.mainService.farmChanged.subscribe(farm => {
        if (farm) {
          this.eventAuditService.getEvents(farm.farmId);
        }
      });
      this.subs.push(sub4);

      const sub5 = this.signalRService.update.subscribe(msg => {
        if (msg.type === SignalrType.forceRefresh) {
          this.eventAuditService.eventAudits.next(null);
          this.eventAuditService.eventAudits.next([]);
        } else if (msg.type === SignalrType.events && !isNaN(msg.id)) {
          this.apiService.get(`/odata/EventAudits(${msg.id})`)
            .subscribe((event: EventAudit) => {
              event = this.eventAuditService.processEvent(event);
              if (!event) {
                return;
              }
              if (this.eventAuditService.eventAudits.value === null) {
                this.eventAuditService.eventAudits.next([event]);
              } else {
                this.eventAuditService.eventAudits.value.unshift(event);
                this.eventAuditService.eventAudits.next(this.eventAuditService.eventAudits.value);
              }

            });
        } else if (msg.type === SignalrType.events && msg.ids && msg.ids.length > 0) {
          // modif date is actualy upload start datetime. that means that greater then will get ALL events.
          this.apiService.get(`/odata/EventAudits?$filter=modified gt ${new Date(msg.modifiedDate).toISOString()}`)
            .pipe(map((res: any) => res.value))
            .subscribe((events: Array<EventAudit>) => {
              events.forEach(event => {
                event = this.eventAuditService.processEvent(event);
                if (!event) {
                  return;
                }
                if (this.eventAuditService.eventAudits.value === null) {
                  this.eventAuditService.eventAudits.next([event]);
                } else {
                  this.eventAuditService.eventAudits.value.unshift(event);
                  this.eventAuditService.eventAudits.next(this.eventAuditService.eventAudits.value);
                }
              });
            });
        }
      });

      this.subs.push(sub5);
    });
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public changeLanguage(language: string): void {
    let url = this.window.location.href;
    if (url.startsWith('https://staging.livestock.mihub.tru-test.com/') ||
        url.startsWith('https://release.livestock.mihub.tru-test.com/') ||
        url.startsWith('https://livestock.mihub.tru-test.com/')) {
      this.cookieService.put('locale', language, { expires: '9999-12-31', domain: 'mihub.tru-test.com' });
    } else {
      this.cookieService.put('locale', language, { expires: '9999-12-31', domain: 'livestock.datamars.com' });
    }

    location.reload();
  }
}

<div class="row no-gutters small-gutters">
  <div class="col-sm-7">

    <div class="row no-gutters small-gutters">
      <div class="col-sm-7">
        <span i18n="@@onorafter">On or after</span>&nbsp;
        <div class="text-shady-lady">{{aboveSummary.count}}
          <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{aboveSummary.weight}}
          <ng-container i18n="abbreviation for average@@avg">Avg</ng-container>
        </div>
      </div>

      <div class="col-sm-5">

        <!-- datepicker -->
        <input
          class="form-control"
          ngbDatepicker
          #d="ngbDatepicker"
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="aboveOrEqualToDate"
          container="body"
          (ngModelChange)="workoutSummaries()"
          (click)="d.toggle()">
<!--        <div class="input-group">-->
<!--          <div class="input-group-append">-->
<!--            <button (click)="d.toggle()" class="btn btn-outline-secondary" type="button">-->
<!--              <i class="fal fa-calendar"></i>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

  <!--        <p-calendar [locale]="localeService.calendarLocale" [style]="{height: '42px !important'}" appendTo="body" class="default w100p" dateFormat="d M yy"-->
  <!--          [ngModelOptions]="{standalone: true}" (onSelect)="workoutSummaries()" (onBlur)="workoutSummaries()"-->
  <!--          [(ngModel)]="aboveOrEqualToDate">-->
  <!--        </p-calendar>-->
      </div>
    </div>

  </div>

  <div class="col-sm-5" *ngIf="groupsSelectList && aboveSummary">
    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="aboveSummary.value"
      (ngModelChange)="groupChanged($event)">
    </ng-select>
  </div>

</div>


<div class="row no-gutters small-gutters mt-5 mt-sm-3">

  <div class="col-sm-7">
    <span i18n="@@before">Before</span>&nbsp;<strong>{{aboveOrEqualToDate | date : 'd MMM yyyy'}}</strong>
    <div class="text-shady-lady">{{belowSummary.count}}
      <span i18n="@@animalsCapitalA">Animals</span>&nbsp;&middot;&nbsp;{{belowSummary.weight}}
      <span i18n="abbreviation for average@@avg">Avg</span>
    </div>
  </div>

  <div class="col-sm-5" *ngIf="groupsSelectList && belowSummary">
    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="belowSummary.value"
      (ngModelChange)="groupChanged($event, false)">
    </ng-select>
  </div>

</div>

import { Session } from './session.model';
import { WeightRecord } from './';
import { SessionAction } from './session-action';
import { DatatypeHelper } from '../../helpers/datatype.helper';
import utcToLocalProperty from '../api/utc-to-local-converter';


export class SessionWeightSummary implements Session {
    sessionId: number;
    @utcToLocalProperty
    sessionStartDate: Date;
    @utcToLocalProperty
    sessionEndDate?: Date;
    sessionNote?: string;
    farm_FarmId: number;
    fieldDisplayOrder?: string;
    weightRecords?: Array<WeightRecord>;
    censuses?: Array<CensusWeightSummary>;
    device?: any;
    sessionName?: string;

    averageWeight: number;
    animalCount: number;
    maxWeight: number;
    minWeight: number;

    importDateTime: Date;

    modified: Date;
	created: Date;
	createdBy: string;
    modifiedBy: string;
    
    deleted? = false;
    currentlyDeleting? = false;
    isIgnoredToAssignGroup?: boolean;

    actions: Array<SessionAction>;
    hidden = false;

// importSource can be removed
    importSource?: string;

// invalidRowCount can be removed
    invalidRowCount: number; // for partial upload, count how many rows are invalid in each session, data is from SessionImportSummary table

    userAgent?: string;

    sessionImportAuditId: number;
    sessionDay: number;
}

export class CensusWeightSummary {
    session_SessionId: number;
    // censusId?: number;
    group_GroupId?: number;

    timeStamp?: Date;
    weightRecords?: Array<WeightRecord>;
}

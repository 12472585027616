import utcToLocalProperty, { utcToNZProperty } from "../api/utc-to-local-converter";

export class Device {
    deviceId?: number;
    model: string;
    serialNumber?: string;
    softwareVersion?: string;
    @utcToNZProperty
    lastSeen: Date;
    farm_FarmId?: number;

    userId?: string;

    deviceJson?: string;
}

export class DeviceType {
    id: number;
    label: string;
    minLength: number;
    maxLength: number;
    minVal: number; // 0 will be ignored as a check
    maxVal: number; // 0 will be ignored as a check
    checked?: boolean;
}

export const deviceList: Array<DeviceType> = [
    { id: 1, label: 'Xr5000', minLength: 6, maxLength: 6, minVal: 500101, maxVal: 0 },
    { id: 2, label: 'Id5000', minLength: 6, maxLength: 6, minVal: 500101, maxVal: 0 },
    { id: 3, label: 'Xr3000', minLength: 5, maxLength: 6, minVal: 10000, maxVal: 0 },
    { id: 4, label: 'ID3000', minLength: 5, maxLength: 6, minVal: 10000, maxVal: 0 },
    { id: 5, label: 'EziWeigh7/7I', minLength: 6, maxLength: 6, minVal: 300001, maxVal: 0 },
    { id: 6, label: 'EziWeigh6/6I', minLength: 6, maxLength: 6, minVal: 300001, maxVal: 0 },
    { id: 11, label: 'S2', minLength: 6, maxLength: 6, minVal: 500001, maxVal: 0 },
    { id: 12, label: 'S3', minLength: 12, maxLength: 12, minVal: 7150000000, maxVal: 0 },
    { id: 7, label: 'Xrs2', minLength: 6, maxLength: 6, minVal: 500001, maxVal: 0 },
    { id: 8, label: 'Srs2', minLength: 6, maxLength: 6, minVal: 500001, maxVal: 0 },
    { id: 9, label: 'Xrs', minLength: 6, maxLength: 6, minVal: 500001, maxVal: 0 },
    { id: 10, label: 'Xrp2', minLength: 6, maxLength: 6, minVal: 700001, maxVal: 0 },
    { id: 11, label: 'JR5000', minLength: 6, maxLength: 6, minVal: 500101, maxVal: 0 },
]

import {throwError as observableThrowError, Observable} from 'rxjs';
import {InvitationService} from '../../main/api/invitation.service';
import {deviceList, DeviceType, Invitation, Country} from '../../main/models';
import {RegisterUser} from '../app-user.model';
import {AuthService} from '../auth.service';
import {CountryService} from '../../main/api/countries.service';
import {Component, OnDestroy} from '@angular/core';
import {OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FormValidation} from '../../helpers/form-validation.helper';
import {ScriptService} from '../../main/script.service';

import {Title} from '@angular/platform-browser';
import {LocaleService} from '../../helpers/locale.service';
import {GaService} from '../../gaService';
import {ApiService} from '../../main/api/api.service';
import {map, catchError} from 'rxjs/operators';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import { MainService } from '../../main/main.service';

declare let jstz: any;
declare let $: any;

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [CountryService],
})

export class RegisterComponent implements OnInit, OnDestroy {
  isLoading = false;
  registerError = '';
  invitation: Invitation;
  code: string = null;
  form: FormGroup;
  deviceList = deviceList;

  countries: Array<Country> = [];
  regions: Array<string> = [];

  tcCheckboxChecked = false;
  ppCheckboxChecked = false;
  submitted = false;
  marketingAgreed = undefined;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private invitationService: InvitationService,
    public countryService: CountryService,
    public localeService: LocaleService,
    public title: Title,
    private gaService: GaService,
    private scriptService: ScriptService,
    private apiService: ApiService,
    private bottomNotificationsService: BottomNotificationsService,
    private mainService: MainService
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle(`${this.localeService.constants.stringRegistration} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);

    this.code = this.route.snapshot.params['code'];

    this.scriptService.load('jsTimezone').then(data => {
      this.countryService.GetCountries().subscribe(countries => {
        let selectedCountryName = 'New Zealand';

        for (let country of countries) {
          country.regionsArray = JSON.parse(country.regions) as Array<string>;
        }
        if (countries && countries.length > 0) {
          let defaultCountry = countries.find(c => c.countryName === 'New Zealand');
          if (defaultCountry) {
            selectedCountryName = defaultCountry.countryName;
            this.regions = defaultCountry.regionsArray;
          } else {
            selectedCountryName = countries[0].countryName;
            this.regions = countries[0].regionsArray;
          }
        }
        this.countries = countries;
        if (jstz) {
          try {
            // need to catch one exception from jstz in timezone (UTC-02:00) Mid-Atlantic
            let timezone = jstz.determine();
            let timezoneString = timezone.name();
            let timezoneRegion = timezoneString.indexOf('/') !== -1 ? timezoneString.split('/')[1] : timezoneString;
            if (timezoneString) {
              // please leave this log here to debug if anytime we find a country not pre-selected correctly
              console.info(timezoneString);
              // 1st try to identify country by jstz field from database
              let matchingCountry = countries.find(c => c.jstz.indexOf(timezoneString) !== -1);
              if (!matchingCountry) {
                // 2nd try to identify country based on region matching
                matchingCountry = countries.find(c => {
                  for (let region of c.regionsArray) {
                    if (timezoneRegion && region.indexOf(timezoneRegion) !== -1) {
                      return true;
                    }
                  }
                  return false;
                });
              }
              if (!matchingCountry) {
                // 3rd try to identify country based on country name
                matchingCountry = countries.find(c => timezoneString.indexOf(c.countryName) !== -1);
              }
              if (matchingCountry) {
                selectedCountryName = matchingCountry.countryName;
                this.regions = matchingCountry.regionsArray;
              }
            }
          } catch (error) {
          }
        }

        if (this.code) {
          this.invitationService.getInvitation(this.code).pipe(catchError(err => {
            return observableThrowError(err);
          })).subscribe(invitation => {
            this.invitation = invitation;
            this.createRegistrationForm(selectedCountryName);
          });
        } else {
          this.createRegistrationForm(selectedCountryName);
        }
      });
    });
  }

  ngOnDestroy() {
    if (!this.isLoading) {
      this.authService.registrationDetails = this.form;
    }
  }

  formChanged() {
    this.registerError = '';
  }

  registerNewUser() {

    // mark form touched and dirty
    this.form.markAllAsTouched();
    Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsDirty());

    if (this.form.valid && this.tcCheckboxChecked && this.ppCheckboxChecked) {
      this.isLoading = true;
      let registerUser = <RegisterUser>this.form.value;
      registerUser.email = registerUser.email.trim(); // ensure no wrapping spaces, validator also ignores leading/trailing spaces already
      registerUser.source = 'Website';
      registerUser.phoneNumber = this.form.controls['phoneNumberForMiHub'].value;
      registerUser.region = this.form.controls['regionForMiHub'].value;
      registerUser.country = this.form.controls['countryForMiHub'].value;
      registerUser.DevicesUsed = this.deviceList.filter(e => e.checked).map(e => e.label);
      registerUser.marketingAgreed = this.marketingAgreed;
      if (this.code) {
        registerUser.code = this.code;
      }
      let timezone = jstz.determine();
      let timezoneString = timezone.name();
      if (timezoneString) {
        registerUser.jstzTimeZone = timezoneString;
      }
      this.authService.register(registerUser).subscribe(() => {
        this.gaService.logEvent('Register', this.router.url, {}, this.invitation ? 'Invitation' : 'Normal');
        this.apiService.post('/api/UserActivity',
          {
            category: 2,
            timeStamp: new Date(),
            eventName: 'Register',
            pageName: this.router.url,
            farm_FarmId: null,
            userId: null,
            userGenerated: true
          }, 'text'
        ).subscribe(res => {
        }, err => {
        });
        this.authService.login(registerUser.email, registerUser.password).subscribe(() => {
          this.router.navigate(['/main', 'dashboard']);
        });
      }, err => {
        this.isLoading = false;
        let errorMsg = JSON.parse(err.error).error.message;
        if (errorMsg.indexOf('is already taken') >= 0 && errorMsg.indexOf('Email') >= 0) {
          errorMsg = (<string>this.localeService.constants.stringEmailIsAlreadyTaken)
            .replace('<emailAddress/>', registerUser.email)
        } else if (errorMsg.indexOf('not a valid e-mail address') >= 0) {
          errorMsg = this.localeService.constants.stringAValidEmailIsRequired;
        }
        // this.registerError = errorMsg;
        this.bottomNotificationsService.currentMessage.next({
          type: 'error',
          title: errorMsg,
          message: ''
        });

        this.submitted = true;
      });
    } else {
      let hasScrolled = false;
      this.submitted = true;
      for (const param in this.form.controls) {
        if (this.form.controls[param] && this.form.controls[param].invalid) {
          this.form.controls[param].markAsDirty();
          if (!hasScrolled) {
            hasScrolled = true;
            $('.main-section').animate({
              scrollTop: $('#content>div>div.w100p').outerHeight() + 10
            }, 1000);
          }
        }
      }
    }
  }

  createRegistrationForm(selectedCountryName: string) {
    let firstName = new FormControl(this.invitation != null ? this.invitation.firstName : ''
      , Validators.compose(
        // either at least two alpha characters, or allow hyphen or spaces as long as first and last characters are alpha
        [Validators.required, Validators.pattern(this.mainService.usernameValidationRegex)]
      ));
    let lastName = new FormControl(this.invitation != null ? this.invitation.lastName : ''
      , Validators.compose(
        // either at least two alpha characters, or allow hyphen or spaces as long as first and last characters are alpha
        [Validators.required, Validators.pattern(this.mainService.usernameValidationRegex)]
      ));
    let phoneNumber = new FormControl(''
      , Validators.compose(
        [Validators.required, Validators.pattern(/^[0-9 \+\-\(\)]+$/)]
      ));
    let emailField = new FormControl(this.invitation != null ? this.invitation.email : ''
      , Validators.compose(
        [FormValidation.validateEmail, Validators.required]
      ));
    let passwordField = new FormControl(''
      , Validators.compose(
        [Validators.required, Validators.minLength(6), Validators.pattern(/\d+/)]
      ));
    let passwordConfirm = new FormControl('');
    let region = new FormControl(null, Validators.required);
    let country = new FormControl(selectedCountryName, Validators.required);
    this.form = this.authService.registrationDetails || new FormGroup({
      firstName: firstName,
      lastName: lastName,
      phoneNumberForMiHub: phoneNumber,
      email: emailField,
      password: passwordField,
      confirmPassword: passwordConfirm,
      regionForMiHub: region,
      countryForMiHub: country
    }, [FormValidation.validateConfirmPassword, FormValidation.validateCountryExists(this.countries), FormValidation.validateRegionExistsForCountry(this.countries)]);
    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  tcCheckboxClicked($event) {
    this.tcCheckboxChecked = $event.target.checked;
    this.submitted = false;
  }

  ppCheckboxClicked($event) {
    this.ppCheckboxChecked = $event.target.checked;
    this.submitted = false;
  }

  marketingCheckboxClicked($event) {
    this.marketingAgreed = $event.target.checked;
    this.submitted = false;
  }

  marketingRadioClicked(value: boolean) {
    this.marketingAgreed = value;
    this.submitted = false;
  }

  isUpperCase(label: string) {
    return label.indexOf('EziWeigh') === -1;
  }
}

<div class="card card-widget">

  <!-- if education -->
  <app-dashboard-education
    image="Groups-BLUE.png"
    i18n-buttonText="@@getstartedcaps"
    buttonText="GET STARTED"
    i18n-titleText="@@steptwocaps"
    titleText="STEP 2"
    link="/main/actions"
    i18n-text="@@addsessionfilestogroups"
    text="Add your session files to groups"
    *ngIf="dashboardEducationService.groupsE"></app-dashboard-education>

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0" i18n="@@groups">Groups</h3>
    <div class="right" *ngIf="groups && groups.length > 0">
      <a routerLink="/main/groups/all" i18n="@@allgroups">All Groups</a>
    </div>
  </div>

  <!-- widget body/content -->
  <div class="card-body" [class.noScroll]="groups && groups.length === 0">

    <!-- widget loading -->
    <app-loading *ngIf="!groups" [local]="true"></app-loading>

    <!-- no groups -->
    <div *ngIf="groups && groups.length === 0" class="noDataBlock text-center text-shady-lady">
      <i class="fa fa-calendar-alt"></i>
      <h3 i18n="@@nocurrentgroups">No current groups</h3>
      <div class="innerDescription">
        <ng-container i18n="@@createanewgroupandassignanimalstotracktheirperformancetoatargetweight">
          Create a new group and assign animals to track their performance to a target weight</ng-container>.
      </div>
      <div><a class="btn btn-primary ml-2" routerLink="/main/groups/all">
        <i class="fas fa-plus"></i>
        <span i18n="@@creategroup">Create Group</span>
      </a></div>
    </div>

    <!-- widget data -->
    <div class="group-widget">

      <div *ngFor="let group of groups" class="widget-row-height container-fluid">

        <div *ngIf="group.groupId && group.censuses">

          <div class="row">

            <div class="col-sm-6">

              <ng-container *ngIf="group.censuses.length > 0">
                <h3 class="mb-0 text-ellipsis">
                  <a routerLink="/main/groups/summary" [queryParams]="{id: group.groupId}">{{group.displayName}}</a>
                </h3>
                <div class="row mt-2 text-center">
                  <div class="col-6">
                    <h3 [appNumberDisplay]="{number: group.currentStatus.animalCount}"></h3>
                    <p class="text-shady-lady" i18n="@@animals">Animals</p>
                  </div>
                  <div class="col-6">
                    <h3 [appNumberDisplay]="{number:group.currentStatus.averageWeight}"></h3>
                    <p class="text-shady-lady text-nowrap" i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="group.censuses.length == 0">
                <h3 class="mb-0 text-ellipsis">
                  <a routerLink="/main/groups/summary" [queryParams]="{id: group.groupId}">{{group.displayName}}</a>
                </h3>
                <p i18n="@@noanimalsassigned">No animals assigned</p>
              </ng-container>

            </div>

            <div class="col-sm-6" style="height: 110px">
              <app-group-widget-graph [group]="group" class="local-class-1"></app-group-widget-graph>
            </div>

          </div>


        </div>
      </div>
    </div>

  </div>
</div>

import {Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';

import { ActionsSoldDeadService } from '../actions-sold-dead.service';
import { GroupsService } from '../../../services/groups.service';
import { LocaleService } from '../../../../helpers/locale.service';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-actions-sold-dead-model-spliter',
  templateUrl: './actions-sold-dead-model-spliter.component.html',
  styleUrls: ['./actions-sold-dead-model-spliter.component.scss']
})
export class ActionsSoldDeadModelSpliterComponent implements OnInit, OnDestroy {
  @Output()
  public selectedFilter = new EventEmitter<any>();

  public selectedItem: any;
  public groups: Array<SelectItem>;
  private sub: Subscription;

  constructor(public actionsSoldDeadService: ActionsSoldDeadService, private groupsService: GroupsService, public localeService: LocaleService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.actionsSoldDeadService.getWeightRecordIds = null;
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  selectedItemChanged(header): void {
    this.selectedFilter.emit(header);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {of} from 'rxjs';

import {FarmUser} from '../models/farm-user.model';
import {AuthService} from '../../auth/auth.service';
import {Farm} from '../models';
import {ApiService} from './api.service';

declare var jstz: any;

@Injectable({
  providedIn: 'root'
})
export class FarmService {
  private farmUsers: Array<FarmUser>;

  private farm: Farm = null;

  constructor(private authService: AuthService, private apiService: ApiService) {
  }

  public getUsersFarms = (): Observable<Array<Farm>> => {
    return this.apiService.get('/odata/Farms?$select=farmId,featureVersionTag,farmName,subscriptionDaysLeft,subscription_SubscriptionId,created,farmType,country'
      + '&$expand=userFarms($count=true;$select=userId)&$orderby=farmName')
      .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
  }

  public async getFarm(farmId: number, force = false) {
    if (farmId && this.authService.currentUser) {
      if (this.authService.currentUser.userFarms.findIndex(uf => uf.farmId === farmId) === -1) {
        return null;
      }
      if (this.farm && this.farm.farmId === farmId && !force) { // even if we have the current Farm, we may want to force reloading it from server
        return this.farm;
      }
      this.farm = await this.apiService.get(`/odata/Farms(${farmId})?$expand=countryLookup($select=countryName)`).toPromise();
      return this.farm;
    } else {
      return null;
    }
  }

  public getUsersForFarm = (farmId: number): Observable<Array<FarmUser>> => {
    if (!this.farmUsers || this.farmUsers.length === 0 || this.farmUsers[0].farmId !== farmId) {
      return this.apiService.get(`/odata/Farms/Services.FarmUsers(farmId=${farmId})`)
        .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    } else {
      return of(this.farmUsers);
    }
  }

  public updateFarm = (farm: any): Observable<Farm> => {
    return this.apiService.patch(`/odata/Farms(${farm.farmId})`, farm);
  }

  public deleteDemoFarm = (farmId: number): Observable<Response> => {
    return this.apiService.post(`/odata/Farms/Services.RemoveFarm/`, { farmId: farmId }, 'text');
  }

  public SaveColumnOrder = (farmId: number, order: string): Observable<boolean> => {
    return this.apiService.patch(`/odata/Farms(${farmId})`
      , { 'fieldDisplayOrder': order }, 'text')
      .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
  }

  public SaveNewFarm = (farm: Farm): Observable<Farm> => {
    const timezone = jstz.determine();
    const timezoneString = timezone.name();
    if (timezoneString) {
      farm.timeZone = timezoneString;
    }
    farm.source = 'Website';
    return this.apiService.post(`/odata/Farms`
      , farm);
  }

  public createNewDemoFarm(farmName: string) {
    return this.apiService.post(`/odata/Farms/Services.DuplicateFarm/`, { farmName: farmName }, 'text');
  }

  public resetDemoFarm(farmId) {
    return this.apiService.post(`/odata/Farms/Services.ResetFarm/`, { farmId: farmId });
  }

  /* SUPPER USER FUNCTIONALITY */
  public getDataForFarmAdminPage(): Observable<Array<any>> {
    return this.apiService.get('/odata/Farms?$expand=userFarms($count=true;$select=userId)'
      + '&$select=farmId,country,farmName,created,farmType'
      + '&$filter=deleted eq false')
      .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));

  }
}

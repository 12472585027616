import {LocaleService} from '../../../helpers/locale.service';
import {ReportsService} from '../../reports/reports.service';
import {RowHeader} from '../../models/report-header.model';
import {MainService} from '../../main.service';
import {FarmService, WeightRecordService} from '../../api';
import {ApiService} from '../../api/api.service';
import {AnimalService} from '../../api/animal.service';
import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Animal, AnimalStatusEnum} from '../shared/animal.model';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {Rounder} from '../../../helpers/rounder.helper';
import {FloatingHeaderHelper} from '../../../helpers/floating-header.helper';
import {DetailsSearchParamType} from '../../models/details-search-params';
import {ScriptService} from '../../script.service';
import {WeightRecord} from '../../models/weight-record.model';
import {SessionAction} from '../../models/session-action';
import {ActionsSoldDeadService} from '../../actions/actions-sold-dead-modal/actions-sold-dead.service';
import {PermissionsService} from '../../../auth/permissions.service';
import {IntegrationService} from '../../api/integration.service';
import {IntegratedDevice, Integration} from '../../models/integration';
import {map} from 'rxjs/operators';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {DatatypeHelper, ConvertToUTC} from '../../../helpers/datatype.helper';
import {AnimalInfoComponent, AnimalInfoParentViewEnum} from '../animal-info/animal-info.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CreateDraftComponent} from '../../groups/create-draft/create-draft.component';
import {GroupsService} from '../../services/groups.service';
import { Location } from '@angular/common';
import {mobileBreakpoint} from '../../../helpers/constants';
import {ActionsAssignFilteredListModalService} from '../../actions/actions-assign-filtered-list-modal/actions-assign-filtered-list-modal.service';

declare var $: any;
declare let moment: any;

@Component({
  selector: 'app-animal-report',
  templateUrl: './animal-report.component.html',
  styleUrls: ['./animal-report.component.scss']
})
export class AnimalReportComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('floatHeaders', { static: false }) floatHeaders: any;

  rows: Array<any> = null;
  headers: Array<RowHeader>;
  curHeaders: Array<RowHeader> = [];
  headerWidths: Map<string, number> = null;
  filterOptions: Array<any> = [];

  selectedAnimal: Animal;
  scrollSet = false;
  public stacked = false;
  public allRows: Array<any> = [];
  private subs: Array<Subscription> = [];
  private weightsSub: Subscription;
  private weightDataSub: { [key: number]: any } = null;

  public allAnimalsCount: number;
  public completedDownload = false;
  processingData = false;

  awgs: any;

  filteredAnimals: Array<any> = [];
  filteredAnimalsAverageWeight: number = null;
  filteredAnimalsMinWeight: number = null;
  filteredAnimalsMaxWeight: number = null;
  filteredAnimalsAdg: number = null;

  totalAnimalCount: number;

  dataForPpl: Array<number>;
  integration: Integration = null;
  curIntegratedDevice: IntegratedDevice = null;

  displayAnimalId: number;
  activeTabOnLoad: string;

  timeout;
  isMobile: boolean;

  constructor(
    private animalService: AnimalService,
    private farmService: FarmService,
    public reportsService: ReportsService,
    private integrationService: IntegrationService,
    private mainService: MainService, title: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public globalParameterService: GlobalParameterService,
    private bottomNotificationsService: BottomNotificationsService,
    private weightRecordService: WeightRecordService,
    private ngZone: NgZone,
    public router: Router,
    private renderer: Renderer2,
    public permissionsService: PermissionsService,
    private localeService: LocaleService,
    private scriptService: ScriptService,
    private actionsSoldDeadService: ActionsSoldDeadService,
    public apiService: ApiService,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private groupsService: GroupsService,
    private location: Location,
    private actionsAssignFilteredListModalService: ActionsAssignFilteredListModalService
  ) {
    title.setTitle(`${this.localeService.constants.stringAllAnimals} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.scriptService.load('alasql', 'jszip', 'jspdf').then(data => {
      this.scriptService.load('jspdfAutotable').then(laterData => {
      });
    });
  }

  showCreateDraftModal() {
    const modalRef: NgbModalRef = this.ngbModal.open(CreateDraftComponent, {});

    const component: CreateDraftComponent = modalRef.componentInstance;

    component.animalIds = this.dataForPpl;
    component.integratedDevice = this.curIntegratedDevice;
    component.integratedDevices = this.integration.integratedDevices;
  }

  markAsCurrent(rows) {
    this.updateStatus(rows, AnimalStatusEnum.none);
  }

  markAsSold(rows) {
    this.updateStatus(rows, AnimalStatusEnum.sold);
  }

  markAsDied(rows) {
    this.updateStatus(rows, AnimalStatusEnum.dead);
  }

  updateStatus(rows, status) {
    let animalIds = rows.filter(r => r['#aid']).map(r => r['#aid']);
    let wrs = rows.filter(w => w.weightRecordId).map(r =>
      <WeightRecord>{
        weightRecordId: r.weightRecordId, weight: r.Weight, animal_AnimalId: r.AnimalId,
        animal: { userDefinedFieldsJson: r.userDefinedFieldsJson }
      });
    let action = <SessionAction>{ v_ActionId: rows.length, animalCount: wrs.length, ided: true, weightRecords: wrs, sessionStartDate: new Date() };
    let filtered = this.totalAnimalCount !== null && wrs.length < this.totalAnimalCount; // currently not using this, so all modals will just be confirmations
    this.mainService.actionDate = this.mainService.setDate();
    this.actionsSoldDeadService.init(action, status, true).subscribe(vActionId => {
      this.soldDeadModalResult(vActionId);
    });
  }

  soldDeadModalResult(vActionId: any) {
    if (vActionId !== null && vActionId !== undefined) {
      let action = this.actionsSoldDeadService.action;
      let status = this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
        ? this.localeService.constants.stringSold
        : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
          ? this.localeService.constants.stringDied
          : this.localeService.constants.stringCurrent;
      let split = this.actionsSoldDeadService.getWeightRecordIds !== null;
      let ids = split ? this.actionsSoldDeadService.getWeightRecordIds() : null;
      let animalIds = split
        ? action.weightRecords.filter(wr => ids.indexOf(wr.weightRecordId) >= 0).map(wr => wr.animal_AnimalId)
        : action.weightRecords.map(wr => wr.animal_AnimalId);
      animalIds.forEach(aid => {
        let animal = this.allRows.find(r => r['AnimalId'] && r['AnimalId'] === aid);
        if (animal) {
          animal['animalStatus'] = status;
          if (this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead || this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold) {
            let theDate = action.sessionStartDate ? action.sessionStartDate : new Date();
            animal['animalStatusDate'] = DatatypeHelper.tryFormatDateWithMoment(theDate.toDateString(), 'D MMM YYYY');
          } else {
            animal['animalStatusDate'] = animal['firstSeen'];
          }
        }
      });
      this.mainService.animalsWeightSummariesCached = null;
      this.refreshWeightsSub();
    }
    this.mainService.actionDate = this.mainService.setDate();
  }

  filterChanged() {
    if (this.floatHeaders) {
      this.floatHeaders.checkScrollbarVisibility();
    }
  }

  filterAnimalsBySearchBox(event) {
    if (this.globalParameterService.detailsSearchParams) {
      const search = this.globalParameterService.detailsSearchParams.value,
        params = Object.keys(search);
      if (event || params.length > 0) {
        this.filteredAnimals = event.filteredValue;
        this.calculateWeights(this.filteredAnimals);
      }
    }
    this.filtered();
  }

  filterAnimalsByGlobalFilter() {
    if (this.globalParameterService.detailsSearchParams) {

      const search = this.globalParameterService.detailsSearchParams.value,
        params = Object.keys(search);
      if (params && params.length > 0 && this.allRows.length > 0) {
        setTimeout(() => {
          this.rows = [...this.allRows.filter(wrc => {
            let res = true;
            params.forEach(param => {
              // if wrc[param] is null and there is any filter in place then we do not show it.
              if (search[param].compare(wrc[param] !== undefined ? wrc[param] : wrc[param.charAt(0).toUpperCase() + param.slice(1)])) {
                res = false;
                return;
              }
            });
            return res;
          })];
          this.filteredAnimals = this.rows;
          this.calculateWeights(this.filteredAnimals);
        });
      } else {
        setTimeout(() => { // prevent zone change error
          this.rows = this.rows ? [...this.allRows] : null; // if we never loaded data then do not load anything
          this.filteredAnimals = this.rows ? [...this.rows] : [];
          this.calculateWeights(this.filteredAnimals);
        });
      }
    }
    this.filtered();
  }

  filtered() {
    setTimeout(() => {
      this.dataForPpl = this.filteredAnimals.filter(f => f['EID'] !== null && f['EID'] !== undefined && f['EID'] !== '').map(v => v['AnimalId']); // get rid of non-EID animals
    });
  }

  processData(animals: Array<Animal>) {
    if (!this.processingData) {
      this.reallyProcessData(animals);
    }
  }

  reallyProcessData(animals: Array<Animal>) {
    this.processingData = true;
    this.dataForPpl = [];
    this.headers = [{ header: this.localeService.constants.stringWeight, field: 'Weight', type: DetailsSearchParamType.number }
      , { header: this.localeService.constants.stringADGSinceFirstSeen, field: 'adgFirstSeen', type: DetailsSearchParamType.number }
      , { header: this.localeService.constants.stringADGSinceLastSeen, field: 'adgLastSeen', type: DetailsSearchParamType.number }
      , { header: this.localeService.constants.stringWeightGainSinceLastSeen, field: 'weightGainLastSeen', type: DetailsSearchParamType.number }
      , { header: this.localeService.constants.stringWeightGainSinceFirstSeen, field: 'weightGainFirstSeen', type: DetailsSearchParamType.number }
      , { header: this.localeService.constants.stringGroupName, field: 'groupName', type: DetailsSearchParamType.string }
      , { header: this.localeService.constants.stringFirstSeen, field: 'firstSeen', type: DetailsSearchParamType.date }
      , { header: this.localeService.constants.stringLastSeen, field: 'lastSeen', type: DetailsSearchParamType.date }
      , { header: this.localeService.constants.stringStatus, field: 'animalStatus', type: DetailsSearchParamType.string }
      , { header: this.localeService.constants.stringStatusDate, field: 'animalStatusDate', type: DetailsSearchParamType.string }];
    // only PPL Farms have the below field generated
    if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration && this.integrationService.integration.value) {
      this.headers.push({ header: this.localeService.constants.stringRemoteWowDevice, field: 'remoteWowDevice', type: DetailsSearchParamType.string });
    }
    this.allRows = [];
    let count = 0, primaryIdName = {};
    for (let i = 0, l = animals.length; i < l; i++) {
      let row = {};
      row['AnimalId'] = animals[i].animalId;
      animals[i].customAnimalIdentifiers.forEach(cid => {
        let val = cid.value;
        if (cid.rawValue) {
          val = cid.rawValue;
        }
        row[cid.internalName || cid.name] = val;
        if (!this.headers.find(h => h.field === (cid.internalName || cid.name))) {
          if (cid.internalName && !primaryIdName[cid.internalName]) {
            primaryIdName[cid.internalName] = cid.name;
          }
          this.headers.unshift({ header: primaryIdName[cid.internalName] || cid.name, field: cid.internalName || cid.name, isCustomId: true })
        }
      });
      row['adgFirstSeen'] = '';
      row['adgLastSeen'] = '';
      row['weightGainLastSeen'] = '';
      row['weightGainFirstSeen'] = '';
      row['firstSeen'] = '';
      row['lastSeen'] = '';

      // only PPL Farms have the below field generated
      if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration && this.integrationService.integration.value) {
        row['remoteWowDevice'] = animals[i].paddockName;
      }

      let thisRow = JSON.parse(animals[i].userDefinedFieldsJson);
      row['userDefinedFieldsJson'] = animals[i].userDefinedFieldsJson;
      this.reportsService.extend(row, JSON.parse(animals[i].userDefinedFieldsJson), this.headers, this.localeService.constants.stringLifeData);
      if (animals[i].pplLastSeen !== null) {
        row['lastSeen'] = animals[i].pplLastSeen;
      }

      this.headers.forEach(h => {
        if (row[h.field]) {
          row[h.field] = DatatypeHelper.tryFormatDateWithMoment(row[h.field], 'D MMM YYYY');
        }
      });

      this.allRows.push(row);
      if (row['EID'] !== null && row['EID'] !== undefined && row['EID'] !== '') { // get rid of non-EID animals
        this.dataForPpl.push(row['EID']);
      }
      count++;
    }

    this.headers = FloatingHeaderHelper.reformatHeaderSpaces(this.headers);

    if (this.mainService.farmChanged && this.mainService.farmChanged.value.fieldDisplayOrder !== null) {
      let persistedColumns = <Array<RowHeader>>JSON.parse(this.mainService.farmChanged.value.fieldDisplayOrder);
      this.curHeaders = [...this.headers.filter(col => persistedColumns.findIndex(h => h.field === col.field) >= 0)];
    } else {
      this.curHeaders = [...this.headers.filter(h => h.category !== this.localeService.constants.stringLifeData
        && h.field !== 'firstSeen' && h.field !== 'adgFirstSeen'
        && h.field !== 'weightGainFirstSeen' && h.field !== 'weightGainLastSeen'
        && h.field !== 'groupName' && h.field !== 'animalStatus' && h.field !== 'animalStatusDate')]; // group, status, and statusDate are not displayed by default
    }

    this.rows = [...this.allRows];
    this.mergeWeightData(animals);
    this.filteredAnimals = [...this.rows];

    this.filterOptions = this.headers.filter(header => header.field[0] && header.field[0] !== '#' && header.field !== 'adgGraph' && header.field !== 'wgGraph')
      .map(header => {
        return { label: header.header['replaceAll']('&nbsp;', ' '), type: header.type >= 0 ? header.type : DetailsSearchParamType.string, value: header.field }
      });

    this.calculateWeights(this.filteredAnimals);
    this.processingData = false;

    if (this.displayAnimalId && this.displayAnimalId > 0 && this.completedDownload) {
      let animal = animals.find(a => a.animalId === this.displayAnimalId);
      if (animal) {
        this.openAnimalInModal(animal);
      }
      this.displayAnimalId = null;
    }
  }

  private calculateWeights(d: { [key: number]: any }) {
    this.completedDownload = this.allRows.length === this.allAnimalsCount;
    if (this.completedDownload && d && !this.objIsEmpty(d)) {
      let cwRes = this.weightRecordService.CalculateWeights(d);
      this.filteredAnimalsMinWeight = cwRes.filteredAnimalsMinWeight;
      this.filteredAnimalsMaxWeight = cwRes.filteredAnimalsMaxWeight;
      this.filteredAnimalsAverageWeight = cwRes.filteredAnimalsAverageWeight;
      this.filteredAnimalsAdg = cwRes.filteredAnimalsAdg;
      if (cwRes.weightCount > 0) {
        return;
      }
    }
    if (this.completedDownload) {
      this.filteredAnimalsMinWeight = NaN;
      this.filteredAnimalsMaxWeight = NaN;
      this.filteredAnimalsAverageWeight = NaN;
      this.filteredAnimalsAdg = NaN;
    }
  }

  objIsEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  // need this function as dont want to redownload weight info when full download finishes (when more then 100 animals)
  private getAnimalSummary(): Observable<any> {
    return this.weightRecordService.GetAnimalsWeightSummaries(this.mainService.farmChanged.value.farmId)
      .pipe(map(res => {
        this.weightDataSub = res;
        this.calculateWeights(res);
        return res;
      }));
  }


  mergeWeightData(data) {
    const ids = [];
    data.forEach(cr => {
      ids.push(cr.animalId);
    });
    if (this.awgs && !this.objIsEmpty(this.awgs)) {
      this.rows = this.rows.map(row => {
        // let awg = this.rows ? this.rows.find(cr => awg.animalId === cr['AnimalId']) : null;
        if (row && row !== undefined && row['AnimalId'] && this.awgs && this.awgs[row['AnimalId']]) {
          let awg = this.awgs[row['AnimalId']];
          row['adgFirstSeen'] = Rounder.round(awg.adgFirstSeen);
          row['adgLastSeen'] = Rounder.round(awg.adgLastSeen);
          row['Weight'] = Rounder.round(awg.weight);
          row['weightRecordId'] = awg.weightRecordId;
          row['firstSeen'] = DatatypeHelper.tryFormatDateWithMoment(awg.firstSeen, 'D MMM YYYY');
          row['lastSeen'] = ConvertToUTC(awg.lastSeen).format('D MMM YYYY');
          row['weightGainLastSeen'] = awg.weightGainLastSeen;
          row['weightGainFirstSeen'] = awg.weightGainFirstSeen;
          row['groupName'] = awg.groupName;
          row['groups_GroupId'] = awg.groupId;
          row['animalStatus'] = this.localeService.constants.stringCurrent;
          if (awg.status) {
            row['animalStatus'] = awg.status === AnimalStatusEnum.sold
              ? this.localeService.constants.stringSold
              : awg.status === AnimalStatusEnum.dead
                ? this.localeService.constants.stringDied
                : this.localeService.constants.stringCurrent;
          }
          row['animalStatusDate'] = DatatypeHelper.tryFormatDateWithMoment(awg.statusDate, 'D MMM YYYY');
        }

        return row;
      });

      this.filteredAnimals = [...this.rows];
    }
  }

  ngOnInit() {
    this.isMobile = this.mainService.isMobile();

    this.displayAnimalId = parseInt(this.route.snapshot.queryParams['id'], 10) || 0;
    const tab = this.route.snapshot.queryParams['tab'];
    if (tab) {
      this.activeTabOnLoad = tab;
    }

    this.curHeaders = null;
    setTimeout(() => {
      $(window).on('scroll');
      $(window).scroll(event => {
        this.scrollSet = FloatingHeaderHelper.floatHeaderOnScroll(this.scrollSet, this.renderer);
        FloatingHeaderHelper.updateColumnWidths(this.renderer);
      });
      FloatingHeaderHelper.CleanUpTable(this.renderer);
    });
  }

  refreshWeightsSub() {
    if (this.weightsSub) {
      this.weightsSub.unsubscribe();
      this.weightsSub = null;
    }
    if (this.weightDataSub) {
      this.weightDataSub = null;
    }
    this.weightsSub = this.getAnimalSummary().subscribe(awgs => {
      if (awgs !== null && awgs !== undefined) {
        this.awgs = awgs;
        if (this.allRows && this.allRows.length > 0) {
          this.mergeWeightData(this.allRows);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.subs.push(this.animalService.animalsDownloaded.subscribe(animalsDataSet => { // if on the initial run we did not get all animals update ui.
      let animals = null;
      if (animalsDataSet !== null && animalsDataSet !== undefined) {
        animals = animalsDataSet.animals;
        this.allAnimalsCount = animalsDataSet.allAnimalsCount;
        this.calculateWeights(this.weightDataSub);
      }
      if (animals && animals.length > this.allRows.length) { // only reload if we did not get everything
        this.totalAnimalCount = animals.length; // hold onto max animal count
        this.allRows = []; // clean up old data to avoid duplicate rows.
        this.rows = null;
        if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
          window.scrollTo(0, 0);
        }
        this.processData(animals); // load up full data set
      }
    }));

    this.subs.push(this.mainService.farmChanged.subscribe(farm => {
      this.curHeaders = null;
      this.weightDataSub = null;
      if (farm !== null && farm !== undefined) {
        this.allRows = [];
        this.rows = null;
        this.subs.push(this.animalService.getAllAnimals(farm.farmId).subscribe(data => this.processData(data)));
        this.refreshWeightsSub();
      }
    }));
    this.subs.push(this.globalParameterService.detailsSearchParams.subscribe(search => {
      if (this.allRows && search !== null && search !== undefined) {
        this.filterAnimalsByGlobalFilter();
      }
    }));

    this.subs.push(this.integrationService.integration.subscribe(i => {
      this.integration = this.integrationService.integration ? this.integrationService.integration.value : null;
      if (this.integration && this.integration.integratedDevices) {
        this.curIntegratedDevice = this.integration.integratedDevices[0]; // just take the first one as default - we have no way to prioritise them
      } else {
        this.curIntegratedDevice = null; // explicitly set to null
      }
    }));

    $('.ui-table-wrapper').scroll(event => {
      setTimeout(
        <TimerHandler><unknown>FloatingHeaderHelper.clipFloatingHeader($('.ui-table-wrapper').scrollLeft(), this.renderer)
        , 1000);
    });

    $('.ui-paginator').click(event => {
      if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
        window.scrollTo(0, 0);
      }
    });

    $('.ui-table-thead').click(event => {
      if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
        window.scrollTo(0, 0);
      }
    });

    // this.refreshWeightsSub();
  }

  columnsReordered(columns, scrollRight = false) {
    this.curHeaders = columns.map(e => this.headers.find(h => h.field === e.field));
    let fieldDisplayOrder = JSON.stringify(this.curHeaders);
    this.farmService.SaveColumnOrder(this.mainService.farmChanged.value.farmId, fieldDisplayOrder)
      .subscribe(() => setTimeout(() => {
        this.mainService.farmChanged.value.fieldDisplayOrder = fieldDisplayOrder;
        FloatingHeaderHelper.updateColumnWidths(this.renderer);
        FloatingHeaderHelper.fixHeaderWidths(this.renderer);
      }, 0));

    this.curHeaders = [...this.curHeaders];

    if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
      window.scrollTo(0, 0);
    }
  }

  ngOnDestroy() {
    this.curHeaders = null;
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
    if (this.weightsSub) {
      this.weightsSub.unsubscribe();
    }
    setTimeout(() => {
      $(window).off('scroll');
    }, 0);
  }

  openAnimalInModal(animal: Animal): void {
    const modalRef = this.ngbModal.open(AnimalInfoComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-75'
    });

    const animalId = animal['animalId'] || animal['AnimalId'] || animal['#aid'];
    modalRef.componentInstance.animalId = animalId;
    modalRef.componentInstance.parentView = AnimalInfoParentViewEnum.ANIMALS;
    modalRef.componentInstance.activeTabOnLoad = this.activeTabOnLoad;

    let currPath = this.location.path();
    let updatedUrl = currPath.includes('id=' + animalId)
                        ? currPath
                        : currPath.includes('?')
                          ? currPath + '&id=' + animalId
                          : currPath + '?id=' + animalId;
    this.location.go(updatedUrl); // rewrites url without reloading component etc

    modalRef.componentInstance.actionDone.subscribe(res => {
      if (res.group) {
        const currentAnimal = this.rows.find(r => r['AnimalId'] && r['AnimalId'] === +animalId);
        const group = this.groupsService.groups.value.find(g => g.groupId === res.group.groupId);
        currentAnimal['groupName'] = group.displayName;
        currentAnimal['groups_GroupId'] = group.groupId;
      } else {
        this.soldDeadModalResult(res.vActionId);
      }
    });
  }

  // new assignment or assign to different group
  assignOrReassignAnimals(): void {
    let animalIds = this.filteredAnimals.map(function(a) { return a['AnimalId']; });
    this.actionsAssignFilteredListModalService.init(animalIds).subscribe(result => {
      this.assignmentResult(result);
    });
  }

  assignmentResult(result: SessionAction) {
    let groupDisplayName = this.groupsService.groups.value.find(g => g.groupId === result.groupId).displayName;
    result.weightRecords.forEach(wr => {
      this.rows.find(a => a.AnimalId === wr.animal_AnimalId).groupName = groupDisplayName;
      this.rows.find(a => a.AnimalId === wr.animal_AnimalId).groups_GroupId = result.groupId;
    });
  }
}

import { MobileApplication } from '../models/data-link-device.model';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Device } from '../models/device.model';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map, catchError } from 'rxjs/operators';



@Injectable()
export class DeviceService {

    constructor( private authService: AuthService, private apiService: ApiService) { }

    public GetDeviceForFarm = (farmId: number): Observable<Array<Device>> => {
        return this.apiService.getTyped<Device>(Device,`/odata/Devices?$filter=farm_FarmId eq ${farmId}`)
            .pipe(catchError(this.apiService.handleError));
    }

    public GetDataLinkDeviceForFarm = (farmId: number): Observable<Array<MobileApplication>> => {
        return this.apiService.getTyped<MobileApplication>(MobileApplication,`/odata/DataLinkDevices?$filter=farm_FarmId eq ${farmId}`)
            .pipe(catchError(this.apiService.handleError));
    }

    public AddDevice = (serial: string, model: string): Observable<boolean> => {
        return this.apiService.post(`/odata/Devices/Services.AddDevice`
            , { serial: serial, model: model });
    }

}

import {Component, OnInit, Input, HostListener} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {MainService} from '../main.service';
import {mobileBreakpoint} from '../../helpers/constants';

@Component({
  selector: 'app-summary-header',
  templateUrl: './summary-header.component.html',
  styleUrls: ['./summary-header.component.scss'],
  providers: []
})
export class SummaryHeaderComponent implements OnInit {
  @Input() headerTitle = this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data['breadcrumb'];
  @Input() subHeaderTitle = '';

  timeout;
  windowWidth = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      if (window.innerWidth !== this.windowWidth) {
        this.mainService.leftMenuOpen.emit({
          forced: true,
          value: window.innerWidth >= mobileBreakpoint
        });

        this.windowWidth = window.innerWidth;
      }
    }, 200);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private mainService: MainService
  ) {
  }

  ngOnInit(): void {
    if (window.innerWidth < mobileBreakpoint) {
      this.mainService.leftMenuOpen.emit({
        forced: true,
        value: false
      });
    }
  }

  toggleLeftMenu() {
    this.mainService.leftMenuOpen.emit({
      forced: true,
      value: true
    });
  }
}


<div class="modal-header">
  <h3>Reset user's password</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<form [formGroup]="form" class="modal-form-with-footer">
  <div class="modal-body">
    <app-form-input error="Password must be at least 6 characters long and contain digits" [showError]="form.controls.password.dirty && form.controls.password.invalid">
      <input type="password" placeholder="Enter a new password" formControlName="password" class="form-control" name="password">
    </app-form-input>
    <app-form-input error="Password must be at least 6 characters long and contain digits"
                    [showError]="form.controls.confirmPassword.dirty && form.controls.password.valid && form.invalid">
      <input type="password" placeholder="Confirm the new password" formControlName="confirmPassword" class="form-control" name="confirmPassword">
    </app-form-input>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="ngbActiveModal.dismiss()" class="btn btn-link" i18n="@@cancel" autofocus>Cancel</button>
    <button type="submit" (click)="resetPassword()" class="btn btn-primary ml-1">Reset password</button>
  </div>
</form>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

import {LocaleService} from '../../helpers/locale.service';
import {DashboardEducationService} from './dashboard-education.service';
import {SignalRService} from '../api';
import {MainService} from '../main.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardEducationService]
})
export class DashboardComponent implements OnInit, OnDestroy {

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private signalRService: SignalRService,
    private router: Router,
    private title: Title,
    private localeService: LocaleService) {
  }

  ngOnInit() {
    this.title.setTitle(`${this.localeService.constants.stringDashboard} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
  }

  ngOnDestroy() {

  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';

import {RowHeader} from '../../models/report-header.model';
import {CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AnimalInfoComponent, AnimalInfoParentViewEnum} from '../animal-info/animal-info.component';
import {Animal} from '../shared/animal.model';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-animals-list',
  templateUrl: './animals-list.component.html',
  styleUrls: ['./animals-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimalsListComponent implements OnChanges {
  @Input() headers: RowHeader[] = [];
  @Input() dataSource;

  @ViewChild('string', { static: true }) stringTemplate: TemplateRef<any>;
  @ViewChild('number', { static: true }) numberTemplate: TemplateRef<any>;
  @ViewChild('link', { static: true }) linkTemplate: TemplateRef<any>;
  @ViewChild('date', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('graph', { static: true }) graphTemplate: TemplateRef<any>;

  @ViewChild(CdkVirtualScrollViewport, { static: true }) vs: CdkVirtualScrollViewport;

  displayedColumns: string[];

  previousIndex;

  constructor(private ngbModal: NgbModal) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.headers) {
      this.setDisplayedColumns();
    }
    this.vs.scrollToIndex(0);
  }

  getTemplate(header: RowHeader): TemplateRef<any> {
    // console.log(header);

    return header.header === 'EID' || header.header === 'VID' || header.header === 'LID' ? this.linkTemplate : this.stringTemplate;
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.headers, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  setDisplayedColumns() {
    this.displayedColumns = this.headers ? this.headers.map(header => header.field) : [];
  }

  openAnimalInModal(animal: Animal): void {
    const modalRef = this.ngbModal.open(AnimalInfoComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-75'
    });

    modalRef.componentInstance.animalId = animal['AnimalId'];
    modalRef.componentInstance.parentView = AnimalInfoParentViewEnum.ANIMALS;
  }

  trackByFn(index, item: Animal): number {
    return item['AnimalId'];
  }
}

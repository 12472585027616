<div class="card">
  <div class="card-header">
    <h3 class="m-0" i18n="@@password">Password</h3>
  </div>
  <div class="card-body">

    <!-- enter old password form -->
    <form [formGroup]="oldPasswordForm" *ngIf="oldPasswordForm" (ngSubmit)="checkOldPassword($event)">

      <!-- password -->
      <app-form-input [control]="oldPasswordForm.get('password')" i18n-label="@@currentpassword" label="Current Password"
                      i18n-error="@@pleaseenteryourcurrentpassword" error="Please enter your current password">
        <input type="password" formControlName="password" class="form-control">
      </app-form-input>

      <div class="form-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!oldPasswordForm.dirty" i18n="@@changepassword">Change Password</button>
      </div>

    </form>

    <!-- enter new password form -->
    <form [formGroup]="passwordChangeForm" (ngSubmit)="changePassword($event)" *ngIf="passwordChangeForm">

      <!-- password -->
      <app-form-input [control]="passwordChangeForm.get('password')" i18n-label="@@newpassword" label="New Password"
					  i18n-error="@@passwordrequiresatleastsixcharactersandonenumber" error="Password requires at least 6 characters and one number"
					  i18n-errorMinlength="@@passwordrequiresatleastsixcharactersandonenumber" errorMinlength="Password requires at least 6 characters and one number"
					  i18n-errorPattern="@@passwordrequiresatleastsixcharactersandonenumber" errorPattern="Password requires at least 6 characters and one number">
        <input type="password" formControlName="password" class="form-control" autofocus>
      </app-form-input>

      <!-- confirm password -->
      <app-form-input i18n-label="@@confirmpassword" label="Confirm Password"
                      [showError]="passwordChangeForm.hasError('passwordMismatch') && passwordChangeForm.controls['confirmPassword'].dirty"
                      i18n-errorPasswordMismatch="@@passwordsdonotmatch" errorPasswordMismatch="Passwords do not match">
        <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{'app-invalid': passwordChangeForm.hasError('passwordMismatch')}">
      </app-form-input>

      <div class="form-footer">
        <button type="submit" class="btn btn-primary" i18n="@@changepassword">Change Password</button>
      </div>

    </form>

    <app-loading *ngIf="passwordChangeLoading" [local]="true"></app-loading>

  </div>
</div>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {combineLatest, Subscription} from 'rxjs';
import {NgbActiveModal, NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';

import {Animal} from '../../models';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {PermissionsService} from '../../../auth/permissions.service';
import {LocaleService} from '../../../helpers/locale.service';
import {SessionWeightSummary} from '../../models/session-weight-summary';
import {Group} from '../../models/group.model';
import {AnimalService} from '../../api/animal.service';
import {SessionService} from '../../api/session.service';
import {GroupsService} from '../../services/groups.service';
import {SignalRService, SignalrType} from '../../api';
import { Location } from '@angular/common';

export enum AnimalInfoParentViewEnum {
  ANIMALS = 'ANIMALS',
  GROUP = 'GROUP',
  SESSION = 'SESSION'
}

@Component({
  templateUrl: './animal-info.component.html'
})
export class AnimalInfoComponent implements OnInit, OnDestroy {
  @Input() animalId: number;
  @Input() parentView: AnimalInfoParentViewEnum;
  @Input() parentId: number;

  @Output() actionDone: EventEmitter<any> = new EventEmitter<any>();

  animal: Animal;
  status = '';
  tabDestroyOnHide = true;
  weightRecordIds: number[] = [];
  groups: Array<Group>;
  sessions: Array<SessionWeightSummary>;
  loading = false;

  activeTabOnLoad: string;

  private signalRSub: Subscription;

  constructor(private route: ActivatedRoute,
              private globalParameterService: GlobalParameterService,
              private title: Title,
              private localeService: LocaleService,
              private animalService: AnimalService,
              private sessionService: SessionService,
              private groupsService: GroupsService,
              private signalRService: SignalRService,
              public ngbActiveModal: NgbActiveModal,
              private location: Location) {
  }

  ngOnInit(): void {
    if (!this.animalId) {
      this.animalId = +this.route.snapshot.queryParams['id'] || +this.globalParameterService.currentAnimalId;
    }
    this.globalParameterService.currentAnimalId = this.animalId;

    // Update Animal when singalR animals updates
    this.signalRSub = this.signalRService.update.subscribe(msg => {
      // Reload Animal summary when singalr updates
      if (msg.type === SignalrType.animals && !isNaN(msg.id)) { // single animal update
        if (msg.id === this.animal.animalId) {
          this.updateAnimal();
        }
      } else if (msg.type === SignalrType.animals && msg.ids && msg.ids.length > 0) { // bulk animals update
        msg.ids.forEach(id => {
          if (this.animal && id === this.animal.animalId) {
            this.updateAnimal();
          }
        });
      }
    });

    this.title.setTitle(`${this.localeService.constants.stringAnimalSummary} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.updateAnimal();
  }

  ngOnDestroy(): void {
    this.signalRSub.unsubscribe();

    // might need to change URL back to sessions/details or groups/details if viewing animal from there
    // otherwise clean up the URL to its base state
    let currPath = this.location.path();
    let currAnimalsAll = currPath.includes('animals/all?'); // used to detect if it is a clicked link, or a return to the Animals/All screen
    if (this.parentView === AnimalInfoParentViewEnum.SESSION && currAnimalsAll) {
      let updatedUrl = currPath.substring(0, currPath.indexOf('/animals/all')) + '/sessions/details?id=' + this.parentId + '&tab=animals';
      this.location.go(updatedUrl); // rewrites url without reloading component etc
    }
    if (this.parentView === AnimalInfoParentViewEnum.GROUP && currAnimalsAll) {
      let updatedUrl = currPath.substring(0, currPath.indexOf('/animals/all')) + '/groups/details?id=' + this.parentId + '&tab=animals';
      this.location.go(updatedUrl); // rewrites url without reloading component etc
    }
    if (this.parentView === AnimalInfoParentViewEnum.ANIMALS) {
      let updatedUrl = currAnimalsAll
                            ? currPath.substring(0, currPath.indexOf('?'))
                            : currPath;
      this.location.go(updatedUrl); // rewrites url without reloading component etc
    }
  }

  // TODO: do not load all tabs content on load; but once loaded - do not load again?
  tabChange(tabChange: NgbTabChangeEvent): void {
    let currPath = this.location.path();
    let newTab = tabChange.nextId;
    let currTab = tabChange.activeId;
    let updatedUrl = currPath.includes('tab=' + currTab)
                          ? currPath.replace('tab=' + currTab, 'tab=' + newTab)
                          : currPath.includes('tab=' + newTab)
                              ? currPath
                              : currPath + '&tab=' + newTab;
    this.location.go(updatedUrl); // rewrites url without reloading component etc
    if (this.tabDestroyOnHide) {
      this.tabDestroyOnHide = false;
    }
  }

  actionCompleted(event) {
    this.updateAnimal();
    this.actionDone.emit(event);
  }

  private updateAnimal() {
    if (!this.animalId) {
      return;
    }

    this.loading = true;
    combineLatest([
      this.animalService.GetAnimalSummaryAndDetail(this.animalId),
      this.groupsService.groups,
      this.sessionService.sessions
    ]).subscribe(animalGroupSession => {
      this.loading = false;

      let animal = animalGroupSession[0],
        grps = animalGroupSession[1],
        sessions = animalGroupSession[2];
      if (animal && grps && sessions) {

        this.animal = animal;
        this.weightRecordIds = [];
        if (animal.weightRecords) {

          let wrIds = Array<number>();
          animal.weightRecords.forEach(function (wr) {
            if (wrIds.indexOf(wr.weightRecordId) === -1) {
              wrIds.push(wr.weightRecordId);
            }
          });
          this.weightRecordIds = wrIds;
        }

        let sids = Array<number>();
        if (this.animal.weightRecords) {
          this.animal.weightRecords.forEach(function (wr) {
            if (sids.indexOf(wr.session_SessionId) === -1) {
              sids.push(wr.session_SessionId);
            }
          });
        }
        this.groups = [];
        if (grps && grps.length > 0 && animal.weightRecords) {
          grps.forEach(grp => {
            if (grp.censuses) {
              if (animal.weightRecords.find(wr => wr.groups_GroupId === grp.groupId)) {
                this.groups.push(grp);
              }
            }
          });
        }

        let sessionSummaries = Array<SessionWeightSummary>();
        if (sessions && sessions.length > 0) {
          sessions.forEach(function (session) {
            if (sids.indexOf(session.sessionId) >= 0 && sessionSummaries.indexOf(session) === -1) {
              sessionSummaries.push(session);
            }
          });
        }
        this.sessions = sessionSummaries;
      }
    }, err => {
      this.loading = false;
    });
  }
}

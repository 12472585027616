import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {UserService} from '../../../api/user.service';
import {AuthService} from '../../../../auth/auth.service';
import {BottomNotificationsService} from '../../../../components/bottom-notifications/bottom-notifications.service';
import {FormValidation} from '../../../../helpers/form-validation.helper';
import {Subscription} from 'rxjs';
import {LocaleService} from '../../../../helpers/locale.service';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit, OnDestroy {
  oldPasswordForm: FormGroup;
  passwordChangeForm: FormGroup;
  oldPassword: string = null;
  passwordChangeLoading = false;

  private sub: Subscription;

  constructor(
    private bottomNotificationsService: BottomNotificationsService,
    private userService: UserService,
    private authService: AuthService,
    private localeService: LocaleService) {
  }

  ngOnInit() {
    this.sub = this.authService.currentUserChanged.subscribe(user => {
      if (user && !this.passwordChangeForm) {
        this.generateOldPasswordForm();
      }
    });
  }

  generateOldPasswordForm() {
    this.oldPasswordForm = new FormGroup({
      password: new FormControl('', Validators.required)
    });
  }

  generatePasswordChangeForm(): void {
    this.passwordChangeForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/)]),
      confirmPassword: new FormControl(''),
    }, FormValidation.validateConfirmPassword);
  }

  checkOldPassword(event: Event) {
    event.preventDefault();

    if (!this.oldPasswordForm.valid) {
      this.oldPasswordForm.controls['password'].markAsDirty();
      return;
    }

    this.passwordChangeLoading = true;
    this.authService.login(this.authService.currentUser.email, this.oldPasswordForm.controls['password'].value).subscribe(res => {
      this.oldPassword = this.oldPasswordForm.controls['password'].value;
      this.oldPasswordForm = null;
      this.generatePasswordChangeForm();
      this.passwordChangeLoading = false;
    }, err => {
      this.passwordChangeLoading = false;
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringErrorOccurred,
        message: this.localeService.constants.stringThePasswordYouHaveProvidedIsIncorect,
        type: 'warning'
      });
    });
  }

  changePassword(event: Event) {
    event.preventDefault();

    if (this.passwordChangeForm.valid) {

      this.passwordChangeLoading = true;
      this.userService.UpdateEmailAndPassword(this.authService.currentUser.email, this.passwordChangeForm.controls['password'].value, this.oldPassword)
        .subscribe(res => {
          this.passwordChangeLoading = false;
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringSuccess,
            message: this.localeService.constants.stringPasswordSuccessfullyChanged
          });
          this.oldPassword = null;
          this.passwordChangeForm = null;
          this.generateOldPasswordForm();
        }, err => {
          this.passwordChangeLoading = false;
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringErrorOccurred,
            message: this.localeService.constants.stringUnableToChangePasswordAtThisTimePleaseTryAgainLater,
            type: 'warning'
          });
        })
    } else {
      this.passwordChangeForm.controls['password'].markAsDirty();
      this.passwordChangeForm.controls['confirmPassword'].markAsDirty();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

<div ngbDropdown autoClose="outside" #d="ngbDropdown" placement="bottom-right">
  <button class="btn btn-link" ngbDropdownToggle i18n="@@choosecolumns">Columns</button>
  <div ngbDropdownMenu>

    <!-- select/unselect all -->
    <button ngbDropdownItem (click)="listChanged(); d.close();">
        <input type="checkbox" class="dropdown-checkbox mr-2" [checked]="curHeaders && headers && curHeaders.length === headers.length">
      <span i18n="@@selectAll">Select All</span>
    </button>

    <div class="dropdown-divider"></div>

    <!-- groups -->
    <ng-container *ngFor="let group of groupedHeader">

      <!-- category name -->
      <button ngbDropdownItem *ngIf="group.category" class="dropdown-header" (click)="group.hidden = group.hidden === null ? true : !group.hidden">
        <span class="fa mr-2" [class.fa-angle-right]="group.hidden" [class.fa-angle-down]="!group.hidden"></span>
        <strong>
          {{group.category}}
        </strong>
      </button>

      <!-- columns -->
      <ng-container *ngIf="!group.hidden">
        <button ngbDropdownItem *ngFor="let header of group.list" (click)="listChanged(header);">
          <ng-container *ngIf="header.header && header.header[0] !== '#'">
            <input type="checkbox" class="dropdown-checkbox mr-2" id="header_{{header.header}}" [checked]="header.visible">
            <span [innerHTML]="header.header"></span>
          </ng-container>
        </button>
      </ng-container>

    </ng-container>

  </div>
</div>

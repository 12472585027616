import { WeightRecord } from '../../../models/weight-record.model';
import { WeightPlanPoint } from '../../../models/weight-plan.model';
import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { Group, GroupCensusWeightSummary } from '../../../models/group.model';
import { Animal } from '../../shared/animal.model';
import { LocaleService } from '../../../../helpers/locale.service';
import { IntegrationService } from '../../../api/integration.service';
import {PermissionsService} from '../../../../auth/permissions.service';

@Component({
    selector: 'app-animal-adg',
    templateUrl: './animal-adg.component.html',
    styleUrls: ['./animal-adg.component.scss']
})
export class AnimalAdgComponent implements OnInit, OnChanges {
    @Input()
    groups: Array<Group>;
    @Input()
    animal: Animal;

    options: any;
    chartName: string;

    isPplFarmWithIntegration = false;

    constructor(private localeService: LocaleService, private integrationService: IntegrationService, private permissionsService: PermissionsService) { }

    ngOnInit() {
        // this.redrawGraph();
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        if ((changes['groups'] && changes['groups'].currentValue) || (changes['animal'] && changes['animal'].currentValue)) {
            this.redrawGraph(); // this runs on init anyways. no point in running twice
        }
    }

    redrawGraph() {
        if (!this.groups || !this.animal || !this.animal.weightRecords) {
            this.options = {
                series: []
            };
            return;
        }
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }

        this.chartName = `${this.animal.displayName} - ` + this.localeService.constants.stringADGGraph;

        let series = [];
        for (let group of this.groups) {
            let adgTwp = [], prevTwp: WeightPlanPoint = null,
                twp: Array<WeightPlanPoint> = JSON.parse(group.targetWeightPlan.weightPlanPointsJson);
            twp.forEach(point => {
                if (prevTwp !== null && point != null) {
                    let days = Math.round((new Date(point.date).getTime() - new Date(prevTwp.date).getTime()) / 86400000);
                    if (days > 0) {
                        let startDate = new Date(prevTwp.date),
                            weight = Math.round(((point.weight - prevTwp.weight) / days) * 1000) / 1000;
                        for (let i = 0; i <= days; i++) {
                            startDate.setDate(startDate.getDate() + 1);
                            startDate.setHours(0, 0, 0, 0);
                            adgTwp.push({
                                x: startDate.getTime() + (i === 0 ? 1 : 0),
                                y: weight,
                                noTooltip: i === days,
                                marker: {
                                    enabled: i === days
                                }
                            });
                        }
                    }
                }
                prevTwp = point;
            });
            adgTwp = adgTwp.sort((a, b) => a.x - b.x); // This fixes HC error 15
            let dayDiff = Math.ceil(new Date(twp[twp.length - 1].date).getTime() - new Date(twp[0].date).getTime()) / 86400000;
            let twpAdg = Math.round((twp[twp.length - 1].weight - twp[0].weight) * 1000 / dayDiff) / 1000;
            series.push({
                turboThreshold: 0,
                name: group.displayName + ' ' + this.localeService.constants.stringTarget,
                id: group.displayName,
                allowPointSelect: true,
                data: adgTwp,
                zIndex: series.length + 4,
                opacity: 0.4,
                color: `#69B342`,
                marker: { enabled: true, lineWidth: 1, lineColor: `#69B342`, fillColor: `#69B342`, symbol: 'diamond' },
                type: 'line'
            });
        }

        let adgAvg = [], prev: WeightRecord = null, prev2: WeightRecord = null, prev3: WeightRecord = null,
        weights = this.animal.weightRecords.slice();
        weights.sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
        weights.forEach((point, index, ) => {
            if (prev !== null && point != null) {
                let days = Math.round((new Date(point.timeStamp).getTime() - new Date(prev.timeStamp).getTime()) / 86400000);
                if (days > 0 && point.weight > 0 && prev.weight > 0) {
                    let startDate = new Date(prev.timeStamp),
                        weightAdg = (point.weight - prev.weight) / days;
                    for (let i = 0; i <= days; i++) {
                        // https://datamars.atlassian.net/wiki/spaces/MS/pages/68419585/Variable+Calculations+for+PPL
                        if (this.integrationService.integration.value) { // only PPL farms get this set
                            if (index > 2) {
                                let pplDate = new Date(weights[index - 1].timeStamp);
                                pplDate.setDate(pplDate.getDate() + 1);
                                pplDate.setHours(0, 0, 0, 0);
                                adgAvg.push({
                                    x: pplDate.getTime(),
                                    y: Math.round(weightAdg * 100) / 100,
                                    noTooltip: true,
                                    marker: {
                                        enabled: false
                                    }
                                });
                            }

                            i = days;
                            startDate = new Date(point.timeStamp);
                        }
                        startDate.setDate(startDate.getDate() + 1);
                        startDate.setHours(0, 0, 0, 0);
                        adgAvg.push({
                            x: startDate.getTime(),
                            y: Math.round(weightAdg * 100) / 100,
                            noTooltip: i === days,
                            marker: {
                                enabled: i === days
                            }
                        });
                    }
                }
            }
            if (point && point.weight) {
                if (this.integrationService.integration.value) { // only PPL farms get this set
                    // need to skip interim 2 weeks to get 3 week ADG for PPL
                    prev = prev2;
                    prev2 = prev3;
                    prev3 = point;
                } else {
                    prev = point;
                }
            }
        });

        let xAxis: any = {
            type: 'datetime',
            tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
            dateTimeLabelFormats: {
                month: '%b %Y'
            },
            labels: {
                style: {
                    fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                    fontSize: '12px'
                }
            },
        };

        series.push({
            turboThreshold: 0,
            name: 'ADG',
            allowPointSelect: true,
            data: adgAvg,
            zIndex: 3,
            id: 'ADG',
            marker: { enabled: !this.isPplFarmWithIntegration, lineWidth:  this.isPplFarmWithIntegration?0:1 },
            opacity: 0.4,
            type: 'line'
        });


        let cName = this.chartName;
        this.options = {
            title: {
                text: null
            },

            subtitle: {
                text: null
            },
            xAxis: xAxis,
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                        fontSize: '12px'
                    }
                },
                lineWidth: 1
            },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                text: this.localeService.constants.stringPrintChart,
                                onclick: function () {
                                    this.print();
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPDF,
                                onclick: function () {
                                    this.exportChart({
                                        type: 'application/pdf',
                                        filename: cName
                                    });
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPNG,
                                onclick: function () {
                                    this.exportChart({
                                        filename: cName
                                    });
                                }
                            }
                        ]
                    }
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0
                },
                series: {
                    animation: true,
                    states: {
                        hover: false
                    }
                }
            },
            series: series
        };
    }
}

<div class="modal-header">

  <!-- string -->
  <h3 *ngIf="messageTitle">{{ messageTitle }}</h3>

  <!-- template -->
  <h3 *ngIf="messageTitleTemplate">
    <ng-container *ngTemplateOutlet="messageTitleTemplate"></ng-container>
  </h3>

  <!-- missing both -->
  <h3 *ngIf="!messageTitle && !messageTitleTemplate" i18n="@@pleaseConfirm">Please confirm</h3>

  <!-- close -->
  <a (click)="reject()" class="close"><i class="fas fa-times"></i></a>

</div>

<div class="modal-body">

  <!-- string -->
  <ng-container *ngIf="messageBody">{{ messageBody }}</ng-container>

  <!-- template -->
  <ng-container *ngIf="messageBodyTemplate">
    <ng-container *ngTemplateOutlet="messageBodyTemplate"></ng-container>
  </ng-container>

  <button type="button" hidden ngbAutofocus></button>

</div>

<div class="modal-footer">
  <button class="btn btn-link" i18n="@@no" (click)="reject()">No</button>
  <button class="btn btn-primary ml-1" i18n="@@yes" (click)="confirm()">Yes</button>
</div>

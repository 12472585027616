import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import {IActionsModalSplitter} from '../i-actions-modal-splitter';
import {GroupWeightRecordSplit} from '../../../../models/group-weight-record-split.model';
import {CreateNewGroupService} from '../../../../create-new-group/create-new-group.service';
import {Group} from '../../../../models/group.model';
import {ActionsModalService} from '../../actions-modal.service';
import {SessionService} from '../../../../api/session.service';
import {LocaleService} from '../../../../../helpers/locale.service';
import { SelectItem } from '../../../../models/select-item';

@Component({
  selector: 'app-actions-modal-splitter-strings',
  templateUrl: './actions-modal-splitter-strings.component.html',
  styleUrls: ['./actions-modal-splitter-strings.component.scss']
})
export class ActionsModalSplitterStringsComponent implements OnInit, OnChanges, IActionsModalSplitter {
  static self: ActionsModalSplitterStringsComponent;
  public selectList: Array<any> = [];
  @Input()
  public headerName: string;

  weightRecordSelection: { [key: number]: GroupWeightRecordSplit } = {};

  groupsSelectList: Array<SelectItem> = [];

  constructor(
    public actionsModalService: ActionsModalService,
    private createNewGroupService: CreateNewGroupService,
    private sessionService: SessionService,
    private localeService: LocaleService) {
    ActionsModalSplitterStringsComponent.self = this;
  }

  ngOnInit() {
    setTimeout(() => { // run with delay to avoid front end glitch
      this.actionsModalService.getGroupsWrSplit = this.getGroupWrsSplit;
      this.groupsFiltered('');
    }, 0);

  }

  groupChanged(index: number, selectListItem: any, groupId) {
    if (this.weightRecordSelection[index]) {
      if (groupId) {
        this.weightRecordSelection[index].weightRecordIds = selectListItem.weightRecordIds;
        this.weightRecordSelection[index].groupId = groupId;
      } else {
        delete this.weightRecordSelection[index];
      }
    }

    if (groupId > 0) {
      let split = new GroupWeightRecordSplit(groupId, this.headerName, selectListItem.value);
      split.weightRecordIds = selectListItem.weightRecordIds;
      selectListItem.displayName = this.actionsModalService.groups.find(g => g.value === groupId).label;
      this.weightRecordSelection[index] = split;
    } else if (groupId === 0) {
      this.createNewGroupService.createGroup(this.actionsModalService.action, false, selectListItem.value, (group: Group) => {
        setTimeout(() => {

          // update groups, add new one
          this.actionsModalService.updateGroups();
          this.groupsSelectList = this.actionsModalService.groups.slice();

          if (group && group.groupId) {
            let split = new GroupWeightRecordSplit(group.groupId, this.headerName, selectListItem.value);
            split.weightRecordIds = selectListItem.weightRecordIds;
            this.weightRecordSelection[index] = split;
            selectListItem.displayName = group.displayName;
            selectListItem.selectedGroupId = this.actionsModalService.groups.find(a => a.value === group.groupId).value;
          }
        }, 0)
      }).result.then(() => {}, () => {
        this.selectList[index].selectedGroupId = null;
        this.groupChanged(index, selectListItem, null);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headerName && changes['headerName'] && changes['headerName'].currentValue) {
      this.selectList = [];
      this.actionsModalService.animalData.forEach(ad => {
        let found = this.selectList.find(a => a.value === ad[this.headerName]);
        if (!found) {
          this.selectList.push({ value: ad[this.headerName], count: 1, weight: ad.weight || 0, weightRecordIds: [ad.weightRecordId], selectedGroupId: null });
        } else {
          found.count++;
          found.weight += (ad.weight || 0);
          found.weightRecordIds.push(ad.weightRecordId);
        }
      });
      this.selectList.forEach(sl => {
        sl.weight = Math.round(sl.weight / sl.count);
      });
    }
  }


  getGroupWrsSplit(): Array<GroupWeightRecordSplit> {
    let self = ActionsModalSplitterStringsComponent.self, splits: Array<GroupWeightRecordSplit> = [];
    for (let param in self.weightRecordSelection) {
      if (self.weightRecordSelection[param] && self.weightRecordSelection[param].weightRecordIds && self.weightRecordSelection[param].weightRecordIds.length > 0) {
        let split = splits.find(s => s.groupId === self.weightRecordSelection[param].groupId);
        if (split) {
          split.weightRecordIds = split.weightRecordIds.concat(self.weightRecordSelection[param].weightRecordIds);
        } else {
          splits.push(self.weightRecordSelection[param]);
        }
      }
    }
    return splits;
  }

  groupsFiltered(searchWords: string) {
    if (searchWords && searchWords.replace(' ', '') !== '') {
      let sw = searchWords.split(' ').filter(s => s.length > 0);
      this.actionsModalService.updateGroups();
      this.groupsSelectList = this.actionsModalService.groups.filter(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.label.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        return ret >= sw.length;
      });
    } else {
      this.actionsModalService.updateGroups(1);
      this.groupsSelectList = this.actionsModalService.groups.slice();
    }
  }
}

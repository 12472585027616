import { AuthService } from '../../auth/auth.service';
import { WeightRecord } from '../models';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';


import { ApiService } from './api.service';
import { Session } from '../models/session.model';
import { EArray } from '../../helpers/constants.helper';
import { AnimalSummary } from '../models/animal-adg.model';
import { SessionService } from './session.service';
import { MainService } from '../main.service';
@Injectable({
    providedIn: 'root',
  })
export class WeightRecordService {
    calculatedWeightCache: CalculatedWeightModel = null;
    private dCache: { [key: number]: any } = null;

    constructor(private authService: AuthService, private apiService: ApiService, private sessionService: SessionService, private mainService: MainService) {
        this.sessionService.sessions.subscribe(s => { this.mainService.animalsWeightSummariesCached = null })
    }

    public GetAllWeightRecordIdsForSession = (sessionId: number): Observable<Array<WeightRecord>> => {
        return this.apiService.getTyped<WeightRecord>(WeightRecord,`/odata/WeightRecords?$filter=session_SessionId eq ${sessionId}&$select=weight,animal_AnimalId,groups_GroupId&$orderby=weight`)
    }

    public GetAllWeightRecordsForDay = (day: number): Observable<Array<any>> => {
        return this.apiService.get(`/odata/Sessions?$expand=weightRecords($select=weight)&$filter=sessionDay eq ${day}&$select=weightRecords`)
            .pipe(map((res: { value: EArray<Session> }) => res.value.flatten('weightRecords').map(e => e.weight)));
    }

    public getUniededWeightsForDayAndGroup = (day: number, groupId: number): Observable<Array<number>> => {
        return this.apiService.get(`/odata/Sessions?$expand=weightRecords($select=weight;`
            + `$filter=groups_GroupId eq ${groupId} and animal_AnimalId eq null)&$filter=sessionDay eq ${day}&$select=weightRecords`)
            .pipe(map((res: { value: EArray<Session> }) => res.value.flatten('weightRecords').map(e => e.weight)));
    }
    public getIdedWeightsForGroup = (groupId: number): Observable<Array<number>> => {
        return this.apiService.get(`/odata/Groups/Services.GroupsIdedWeightRecords(groupId=${groupId})?$select=weight`)
            .pipe(map((res: { value: Array<{ weight: number }> }) => res.value.map(e => e.weight)));
    }

    public GetWeightsAndAnimalsForSessionId = (sessionId: number): Observable<Array<WeightRecord>> => {
        return this.apiService.get(`/odata/WeightRecords?$filter=session_SessionId eq ${sessionId}`
             + `&$select=weightRecordId,animal_AnimalId,weight,timeStamp,userDefinedFieldsJson,groups_GroupId,lifeDataUserDefinedFieldJson`
             + `&$expand=animal`
             + `($select=animalId,userDefinedFieldsJson;$expand=customAnimalIdentifiers($select=internalName,name,value,rawValue))`
            )
            .pipe(map((res: any) => res.value));
    }


    public getWeightsWithNoAnimalIds = (groupId: number, timestamp: Date): Observable<Array<AnimalSummary>> => {
        let time = new Date(new Date(new Date(new Date(timestamp).setHours(0)).setSeconds(0)).setMinutes(0)).toISOString();
        return this.apiService.get(`/odata/WeightRecords?$filter=groups_GroupId eq ${groupId} and animal_AnimalId eq null and `
            + `timeStamp ge ${time}`
            + `&$select=weightRecordId,weight,timeStamp,userDefinedFieldsJson`
            )
            .pipe(map((res: any) => {
                return res.value.map(wr => {
                    return {
                    'animalId': null,
                    'weight': wr.weight,
                    'timeStamp': wr.timeStamp,
                    'sessionData': wr.userDefinedFieldsJson,
                    'paddockName': wr.integratedDeviceName,
                    'weightRecordId': wr.weightRecordId
                    }
                });
            }));
    }

    public GetAllWeightRecordsForAnimals = (groupId: number, animalIds: Array<number>): Observable<Array<any>> => {
        return this.apiService.post(`/odata/WeightRecords/Services.GetWeightRecordSummaryForAnimals`,
            {
                groupId: groupId,
                animalIds: animalIds
            })
            .pipe(map((res: any) => res.value));
    }


    public GetAnimalsWeightSummaries = (farmId: number): Observable<{ [key: number]: any }> => {
        if (this.mainService.animalsWeightSummariesCached) {
            return of(this.mainService.animalsWeightSummariesCached);
        }
        return this.apiService.get(`/odata/WeightRecords/Services.AnimalsWeightSummaries(farmId=${farmId})`)
            .pipe(map((res: any) => {
                let animals = {};
                (<Array<any>>res.value).forEach(a => {
                    animals[a.animalId] = a;
                });
                this.mainService.animalsWeightSummariesCached = animals;
                return this.mainService.animalsWeightSummariesCached;
            }));
    }

    public ResetAnimalsWeightSummariesCache(farmId: number) {
        this.mainService.animalsWeightSummariesCached = null;
        this.GetAnimalsWeightSummaries(farmId).subscribe();
    }

    public CalculateWeights(d: { [key: number]: any }): CalculatedWeightModel {
        if (this.calculatedWeightCache && JSON.stringify(d) === JSON.stringify(this.dCache)) {
            return this.calculatedWeightCache;
        }

        let calculatedWeight = new CalculatedWeightModel();
        calculatedWeight.adgCount = 0;
        calculatedWeight.weightCount = 0;
        calculatedWeight.filteredAnimalsMinWeight = Number.MAX_SAFE_INTEGER;
        calculatedWeight.filteredAnimalsMaxWeight = Number.MIN_SAFE_INTEGER;
        calculatedWeight.filteredAnimalsAverageWeight = 0
        calculatedWeight.filteredAnimalsAdg = 0;
        for (let prop in d) {
          if (d[prop]) { // if animal
            if (d[prop]['weight'] || d[prop]['Weight']) { // if weight
                let w = d[prop]['weight'] || d[prop]['Weight'];
                calculatedWeight.filteredAnimalsAverageWeight += w;
                calculatedWeight.weightCount++;
                if (calculatedWeight.filteredAnimalsMinWeight > w) {
                    calculatedWeight.filteredAnimalsMinWeight = w;
                }
                if (calculatedWeight.filteredAnimalsMaxWeight < w) {
                    calculatedWeight.filteredAnimalsMaxWeight = w;
                }
            }
            if (!isNaN(d[prop]['adgLastSeen'])) {
                calculatedWeight.filteredAnimalsAdg += Number(d[prop]['adgLastSeen']);
                calculatedWeight.adgCount++;
            }
          }
        }

        calculatedWeight.filteredAnimalsAverageWeight /= calculatedWeight.weightCount;
        calculatedWeight.filteredAnimalsAdg /= calculatedWeight.adgCount;

        if (this.calculatedWeightCache
            && (
                calculatedWeight.filteredAnimalsMinWeight === Number.MAX_SAFE_INTEGER || calculatedWeight.filteredAnimalsMaxWeight === Number.MIN_SAFE_INTEGER
            )) {
            return this.calculatedWeightCache;
        }

        this.calculatedWeightCache = calculatedWeight;
        return this.calculatedWeightCache;
    }

    public getDataFieldsForSession(sessionId: number, Ided: boolean, groupId = null): Observable<Array<WeightRecord>> {
        return this.apiService.get(`/odata/WeightRecords` +
            `?$expand=animal($select=userDefinedFieldsJson)&` +
            // + `$select=userDefinedFieldsJson,weight,weightRecordId,animal_AnimalId&$
            `$filter=animal_AnimalId ${Ided ? 'ne' : 'eq'} null and session_SessionId eq ${sessionId}`
            + (groupId !== null ? ` and groups_GroupId eq ${groupId}` : ` and groups_GroupId eq null`)
            + ` and (animalStatus_AnimalStatusId eq null or animalStatus_AnimalStatusId eq 1)`)
            .pipe(map((res: any) => res.value));
    }

    public getDataFieldsForEntireSession(sessionId: number, Ided: boolean): Observable<Array<WeightRecord>> {
        return this.apiService.get(`/odata/WeightRecords?` +
            `$filter=animal_AnimalId ${Ided ? 'ne' : 'eq'} null and session_SessionId eq ${sessionId}` +
            ` and (animalStatus_AnimalStatusId eq null or animalStatus_AnimalStatusId eq 1)&` +
            `$expand=animal($select=userDefinedFieldsJson)&` +
            `$select=userDefinedFieldsJson,weight,weightRecordId,animal_AnimalId`)
            .pipe(map((res: any) => res.value));
    }
}

export class CalculatedWeightModel {
    public adgCount: number;
    public weightCount: number;
    public filteredAnimalsMinWeight: number;
    public filteredAnimalsMaxWeight: number;
    public filteredAnimalsAverageWeight: number;
    public filteredAnimalsAdg: number;
}

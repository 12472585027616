<ng-container *ngIf="showConfirmation" >

  <div class="modal-header" >
    <h3 i18n="@@groupdetails">GROUP DETAILS</h3>
    <app-new-group-line [group]="group" [templates]="templates" [step]="step" ></app-new-group-line>
    <a (click)="prevStepOrCancel.next('cancel')" class="close"><i class="fas fa-times"></i></a>
  </div>

  <div class="modal-body" >
    <h4 class="mv15">{{group.groupId ? localeService.constants.stringGroupWillBeUpdatedWithTheFollowingDetails : localeService.constants.stringANewGroupWillBeCreatedWithTheFollowingDetails}}
    </h4>

    <form class="row row-line" [formGroup]="form" *ngIf="form && form.controls.groupName" style="display: flex; align-items: center;">
      <div class="col-md-3">
        <strong i18n="@@name">Name</strong>:
        <span *ngIf="form.controls.groupName.invalid" style="color: red;" i18n="@@thisnameisalreadyinuse">This name is already in use</span>
      </div>
      <div class="col-md-9">
        <input type="text" formControlName="groupName" [value]="group.groupName" class="form-control" >
      </div>
    </form>
    <div class="row row-line">
      <div class="col-md-3">
        <strong i18n="@@species">Species</strong>:
      </div>
      <div class="col-md-9">{{curSpecies ? curSpecies.speciesName : localeService.constants.stringNA}}</div>
    </div>
    <div class="row row-line">
      <div class="col-md-3">
        <strong i18n="@@weightplan">Weight Plan</strong>:
      </div>
      <div class="col-md-9">{{group.targetWeightPlan.planName}}</div>
    </div>
    <div class="row row-line">
      <div class="col-md-3">
        <strong i18n="@@breed">Breed</strong>:
      </div>
      <div class="col-md-9">{{curBreed ? curBreed.breedName : localeService.constants.stringNA}}</div>
    </div>
    <div class="row row-line">
      <div class="col-md-3">
        <strong i18n="@@sex">Sex</strong>:
      </div>
      <div class="col-md-9">{{curSex ? curSex.sexName : localeService.constants.stringNA}}</div>
    </div>
  </div>

  <div class="modal-footer" >
    <a class="btn btn-link" (click)="prevStepOrCancel.next('cancel');" i18n="@@cancel">Cancel</a>
    <button class="btn btn-secondary ml-1" (click)="prevStepOrCancel.next('prev')" i18n="@@back">Back</button>
    <button [disabled]="disableCreateButton" class="btn btn-primary ml-1" (click)="next();">
      {{group.groupId ? localeService.constants.stringUpdate : localeService.constants.stringCreate}}
    </button>
  </div>

</ng-container>

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value): NgbDateStruct {
    return value;
  }

  format(date: NgbDateStruct): string {
    return date ? moment([date.year, date.month - 1, date.day]).format('D MMM YYYY') : '';
  }
}

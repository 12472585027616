import { ErrorSummary } from '../../models/error-summary.model';
import { SessionImportAuditsService } from '../../api/session-import-audits.service';
import { SessionWeightSummary } from '../../models/session-weight-summary';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-session-invalid-row',
  templateUrl: './session-invalid-row.component.html',
  styleUrls: ['./session-invalid-row.component.scss']
})
export class SessionInvalidRowComponent implements OnInit, OnChanges {

  @Input()
  currentSession: SessionWeightSummary;

  errors: ErrorSummary[];
  showErrors = false;
  badPayload = false;

  constructor(private sessionImportAuditsService: SessionImportAuditsService) { }

  ngOnInit() {
    if (this.currentSession.userAgent) {
      // parse userAgent for iOS or Android etc
      let ua = this.currentSession.userAgent.toLowerCase();
      this.badPayload = ua.indexOf('android') >= 0 || ua.indexOf('ios') >= 0;
    } else {
      if (this.currentSession.importSource === null || this.currentSession.importSource.toUpperCase() !== 'PPL') {
        this.badPayload = true;
      }
    }
  }

  getOriginalFile() {
    this.sessionImportAuditsService.getImportedFile(this.currentSession.sessionImportAuditId, false).subscribe(file => {
      {
        const blob = new Blob([file], { type: 'text/csv' }),
          link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.currentSession.sessionName +
          (this.currentSession.sessionName.endsWith('.csv') ? '' : '.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          link.remove();
      }
    });
  }

  getErrors() {
    if (!this.errors) {
      this.sessionImportAuditsService.getImportedError(this.currentSession.sessionImportAuditId).subscribe(errors => {
        {
          this.errors = [];
          for (let error of JSON.parse(errors)) {
            let sessionError = new SessionErrorSummary();
            sessionError.errorResult = error.ErrorResult;
            sessionError.description = error.Description;
            sessionError.fileName = error.FileName;
            sessionError.column = error.Column;
            sessionError.row = error.Row;
            sessionError.errorTypeText = error.errorTypeText;
            this.errors.push(sessionError);
          }
        }
      });
    }
    this.showErrors = !this.showErrors;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentSession && changes['currentSession'].currentValue) {
      this.errors = null;
      this.showErrors = false;
    }
  }
}

class SessionErrorSummary implements ErrorSummary {
  errorResult: string;
  description: string;
  fileName: string;
  column: string;
  row: string;
  errorTypeText: string;
}

import { Farm } from '../main/models/farm.model';
import { Country } from '../main/models';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export class FormValidation {

    public static validateEmail(c: FormControl): { [key: string]: any } {
        /*
        This regex may need to be expanded further.

        Currently checks that:
            * checks that the email isn't blank
            * checks the the email contains an @ symbol with at least 1 character before it
            * checks that there is a domain ie @ with at least 2 letters after it
            * checks that it ends with a fullstop with at least 2 letters after it
            * additional validation for local name: https://en.wikipedia.org/wiki/Email_address#Local-part
            * there are some funky extensions to the original email specifications, which allow some otherwise "bad"
            * characters or character combinations in the local name component of an email address (eg ".." or " ")
            * if the local name is quoted

        Have not allowed local domain name with no TLD, because ICANN highly discourages dotless email addresses (eg [admin@mailserver1]),
        no real characters at all (eg space between the quotes: [" "@example.org]), or single character domains (eg [example@s.example]) at this stage

        */

        if (c.value === undefined) {
          return null;
        }

        let emailRegex = /(.+)@(.+){2,}\.(.+){2,}/; // standard email

        let spaceOrDoubleDotRegex = /(.+)((\.\.)|(\ ))(.+)/; // check for two consecutive dots or a space
        let quotedSpaceOrDoubleDotEmailRegex = /\"(.+)((\.\.)|(\ ))(.+)\"@(.+){2,}\.(.+){2,}/; // quotes around local name if consecutive dots or a space

        let otherSpecialCharactersRegex = /(.+)[\"\(\)\,\:\;\<\>@\[\]](.+)@(.+)/; // these special characters are allowed in local name if it's quoted
        let quotedOtherSpecialCharactersRegex = /\"(.+)[\"\(\)\,\:\;\<\>@\[\]](.+)\"@(.+){2,}\.(.+){2,}/; // quotes around special characters in local name

        if (spaceOrDoubleDotRegex.test(c.value.trim()) && !quotedSpaceOrDoubleDotEmailRegex.test(c.value.trim())) {
            // if it has consecutive dots or a space, and isn't quoted
            return {
                validateEmail: {
                    valid: false
                }
            };
        }
        if (otherSpecialCharactersRegex.test(c.value.trim()) && !quotedOtherSpecialCharactersRegex.test(c.value.trim())) {
            // if it has selected allowed special characters, and isn't quoted
            return {
                validateEmail: {
                    valid: false
                }
            };
        }

        return emailRegex.test(c.value.trim()) && (<string>c.value.trim()).split('@').length === 2 ? null : {
            validateEmail: {
                valid: false
            }
        };
    }

    public static validateIpAddress(c: FormControl): { [key: string]: any } {
        /*
        Regex taken from https://www.regular-expressions.info/ip.html
        Checks:
            * there are four dot seperated values
            * each value is between 0-255, with no leading zeros (leading zeroa imply octal notation)
        */
        // tslint:disable-next-line:max-line-length
        let ipRegex = /(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])/;
        return ipRegex.test(c.value) ? null : {
            validateIpAddress: {
                valid: false
            }
        };
    }

    public static validateCountryExists(countryList: Array<Country>): ValidatorFn {
        return (control: FormControl): { [key: string]: boolean } => {
            if (control.root['controls']) {
                let country = control.root['controls'].countryForMiHub.value;
                if (countryList.findIndex(c => c.countryName === country) === -1) {
                    return { 'invalidCountry': true };
                }
            }
            return null;
        }
    }

    public static validateRegionExistsForCountry(countryList: Array<Country>): ValidatorFn {
        return (control: FormControl): { [key: string]: boolean } => {
            if (control.root['controls']) {
                let country = control.root['controls'].countryForMiHub.value;
                if (countryList.findIndex(c => c.countryName === country) > -1) {
                    let regionList = countryList.find(c => c.countryName === country).regionsArray;
                    let region = control.root['controls'].regionForMiHub.value;
                    if (region && region !== '' && regionList.findIndex(r => r === region) === -1) {
                        // we only want to display an invalid region message if they've actually selected a region
                        // otherwise, the dirty + empty form error will do its thing
                        return { 'invalidRegion': true };
                    }
                }
            }
            return null;
        }
    }

    public static validateConfirmPassword(control: FormControl): { [key: string]: boolean } {
        if (control.root['controls']) {
            let confPass = control.root['controls'].confirmPassword.value,
                pass = control.root['controls'].password.value,
                oldPass = control.root['controls'].oldPassword ? control.root['controls'].oldPassword.value : null;
            // oldPass is used by change password in the user-details.component
            if (pass || confPass) {
                if (pass !== confPass) {
                    return {
                        'passwordMismatch': true
                    };
                }
            }
            if (oldPass != null) {
                if (pass === '' && oldPass !== '') {
                    // has old pass and empty new pass, user may though can set to empty password, so we set it invalid
                    return { 'newPasswordEmpty': true };
                } else if (oldPass === '' && pass !== '') {
                    // has new password but oldPass is empty, so user cannot change password
                    return { 'oldPasswordEmpty': true };
                }
            }
        }
        return null;
    }

    public static validateFarmName(farms: Array<Farm>): any {
        return (control: FormControl) => {
            let match = null;
            farms.forEach(farm => {
                if (farm.farmName && control.value && farm.farmName.toLowerCase() === control.value.toLowerCase()) {
                    match = { 'farmNameMatched': true };
                }
            });
            return match;
        };
    }
}

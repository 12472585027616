export const mobileBreakpoint = 769;


// import { Month } from '../../app/helpers/Month';


// // "STANDARD" ONES THAT CAN BE DEALT WITH BY GOOGLE ETC
// const enMonthList: Array<Month> = [
//     { id: 0, month: 'Jan', fullMonth: 'January' },
//     { id: 1, month: 'Feb', fullMonth: 'February' },
//     { id: 2, month: 'Mar', fullMonth: 'March' },
//     { id: 3, month: 'Apr', fullMonth: 'April' },
//     { id: 4, month: 'May', fullMonth: 'May' },
//     { id: 5, month: 'Jun', fullMonth: 'June' },
//     { id: 6, month: 'Jul', fullMonth: 'July' },
//     { id: 7, month: 'Aug', fullMonth: 'August' },
//     { id: 8, month: 'Sep', fullMonth: 'September' },
//     { id: 9, month: 'Oct', fullMonth: 'October' },
//     { id: 10, month: 'Nov', fullMonth: 'November' },
//     { id: 11, month: 'Dec', fullMonth: 'December' }
// ];
// const enLongMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
// const enShortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// const enWeekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
// const enDecimalSymbol = '.';

// const frMonthList: Array<Month> = [
//     { id: 0, month: 'Janv.', fullMonth: 'Janvier' },
//     { id: 1, month: 'Févr.', fullMonth: 'Février' },
//     { id: 2, month: 'Mars', fullMonth: 'Mars' },
//     { id: 3, month: 'Avr.', fullMonth: 'Avril' },
//     { id: 4, month: 'Mai', fullMonth: 'Mai' },
//     { id: 5, month: 'Juin', fullMonth: 'Juin' },
//     { id: 6, month: 'Juil.', fullMonth: 'Juillet' },
//     { id: 7, month: 'Août', fullMonth: 'Août' },
//     { id: 8, month: 'Sept.', fullMonth: 'Septembre' },
//     { id: 9, month: 'Oct.', fullMonth: 'Octobre' },
//     { id: 10, month: 'Nov.', fullMonth: 'Novembre' },
//     { id: 11, month: 'Déc.', fullMonth: 'Décembre' }
// ];
// const frLongMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
// const frShortMonths = ['Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin',  'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];
// const frWeekdays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
// const frDecimalSymbol = ',';
// // END OF "STANDARD" ONES

// export const enConstants = {
//     monthList: enMonthList,
//     longMonths: enLongMonths,
//     shortMonths: enShortMonths,
//     weekdays: enWeekdays,
//     decimalSymbol: enDecimalSymbol
// };

// export const frConstants = {
//     monthList: frMonthList,
//     longMonths: frLongMonths,
//     shortMonths: frShortMonths,
//     weekdays: frWeekdays,
//     decimalSymbol: frDecimalSymbol
// };

<app-summary-header i18n-headerTitle="@@actions" headerTitle="Actions" i18n-subHeaderTitle="@@assignYourAnimalsAndWeightsToGroups" subHeaderTitle="Assign your animals and weights to groups">
  <div class="col-xs-12">
    <!-- <app-form-input class="col-xs-12 col-md-9 col-lg-8 pull-right prn" top="0" icon="fa-search">
      <input type="text" i18n-placeholder="placeholder text for a filter input@@quicksearch" placeholder="Filter" (keyup)="search($event.target.value)"
        class="form-control">
    </app-form-input> -->
  </div>
</app-summary-header>

<div class="container w100p ">
  <div class="col-md-6 col-xs-12">
    <ng-container *ngIf="groupClusters">
      <ng-container *ngFor="let group of groupClusters">
        <ng-container>
          <h2 [class.opacity]="group.fakeGroup">
            {{group.groupName}} ({{group.groupId}}) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="mb-0 mt-2 mh10">(InnerGroupDistance: {{group.scoreDetails.avgInnerGroupDistance}})</span>
            <span class="mb-0 mt-2 mh10">(OuterGroupDistance: {{group.scoreDetails.outerGroupDistance}})</span>
            <span class="mb-0 mt-2 mh10">(GroupSessionAvgWeightScore: {{group.scoreDetails.withInGroupSessionAvgWeightScore}})</span>
          </h2>
          <div class="card ph25 w100p">

            <ng-container *ngFor="let session of group.sessionForClusters; let i = index">
              <div class="row" [class.opacity]="session.groupId !== null">
                <div class="col-xs-1">
                  <i *ngIf="session.groupId !== null" class="fa fa-check" style="color:green"></i>
                  <i *ngIf="session.groupId === null" class="fa fa-question"></i>
                </div>
                <div class="col-xs-5">
                  {{session.sessionName}} ({{session.sessionId}})
                  <span *ngIf="session.sessionStartDate"> ({{dayMonthYearString(session.sessionStartDate)}}) </span>
                </div>
                <div class="col-6">
                  <span>{{session.recordCount}} Animals</span>
                  <span *ngIf="session.groupId !== null">already assigned to the group</span>
                  <span *ngIf="session.groupId === null">recommended to the group</span>
                </div>
              </div>
            </ng-container>

          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-6">
    <!-- sessions to be assigned -->
    <ng-container>
      <h2>
        Sessions no recommendation or not confident about recommendation
      </h2>
      <div class="card ph25 w100p">
        <ng-container *ngFor="let group of groupClustersNotConfident;">
          <ng-container *ngFor="let session of group.sessionForClusters;">
            <div class="row" [class.opacity]="session.groupId !== null">
              <div class="col-xs-3">
                ({{group.groupName}})
              </div>
              <div class="col-xs-4">
                {{session.sessionName}} ({{session.sessionId}})
                <span *ngIf="session.sessionStartDate"> ({{dayMonthYearString(session.sessionStartDate)}}) </span>
              </div>
              <div class="col-xs-5">
                <span>{{session.recordCount}} Animals</span>
                <span>need to assign to a group</span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <app-loading *ngIf="!groupClusters"></app-loading>

  <app-no-data-found *ngIf="groupClusters && groupClusters.length === 0">
    <i class="fa fa-2x fa-check"></i>
    <br/>
    <ng-container i18n="@@youreallcaughtup">You're all caught up</ng-container>
  </app-no-data-found>
</div>

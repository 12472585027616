<div class="card card-widget">

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0" i18n="@@sessions">Sessions</h3>
  </div>

  <!-- widget body/content -->
  <div class="card-body">

    <app-loading *ngIf="!sessions" [local]="true"></app-loading>

    <div *ngIf="sessions && sessions.length === 0">
      <div class="card-body text-center">
        <h3 i18n="@@nosessionsforthisanimal">There are no Sessions for this animal.</h3>
      </div>
    </div>

    <div *ngIf="sessions && sessions.length > 0" class="timeline-list-wrapper">
      <ol class="timeline-list">
        <li class="timeline-item d-flex" *ngFor="let session of sessions">

          <div class="timeline-icon bg-success">
            <span class="fa fa-file-import" aria-hidden="true"></span>
          </div>

          <div class="timeline-desc">
            <b><a routerLink="/main/sessions/summary" [queryParams]="{id: session.sessionId}">{{session.sessionName}}</a></b>
          </div>

          <div class="timeline-date">
            {{dayMonthYearString(session.sessionStartDate)}}
          </div>

        </li>
      </ol>
    </div>

  </div>

</div>

<app-summary-header
  i18n-headerTitle="@@allanimals"
  i18n-subHeaderTitle="@@individualanimalsreportbuilder"
  headerTitle="All Animals"
  subHeaderTitle="Individual animals and report builder">
</app-summary-header>

<div class="page-content page-content-y">

  <!-- loading animation -->
  <app-loading *ngIf="!rows || !allRows"></app-loading>

  <!-- no data -->
  <ng-container *ngIf="rows && allRows.length === 0">
    <app-no-data-found i18n-buttonText="@@uploadsessionfile" buttonText="Upload Session File" (onClick)="router.navigate(['/main/transfer']);">
      <ng-container i18n="@@viewweightandlifedataforallanimals">View weight and life data for all animals.</ng-container>
      <br/>
      <ng-container i18n="@@datacanonlybeshownforanimalswithauniqueid">Data can only be shown for animals with a unique ID.</ng-container>
    </app-no-data-found>
  </ng-container>

  <ng-container *ngIf="rows && allRows.length > 0">

    <!-- numbers -->
    <div *ngIf="rows && filteredAnimals">
      <div *ngIf="completedDownload" class="card card-summary-header">
        <div class="card-body">
          <div>
            <h2>
              <span [appNumberDisplay]="{number: filteredAnimals.length}"></span>
              <span *ngIf="filteredAnimals.length !== allAnimalsCount"> / </span>
              <span *ngIf="filteredAnimals.length !== allAnimalsCount" [appNumberDisplay]="{number: allAnimalsCount}"></span>
            </h2>
            <p class="text-shady-lady" [class.mtSpinner]="!completedDownload" i18n="@@animals">Animals</p>
          </div>
          <div>
            <h2 [appNumberDisplay]="{number: filteredAnimalsAverageWeight}"></h2>
            <p class="text-shady-lady" [class.mtSpinner]="!completedDownload" i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
          </div>
          <div>
            <h2 [appNumberDisplay]="{number: filteredAnimalsMinWeight}"></h2>
            <p class="text-shady-lady" [class.mtSpinner]="!completedDownload" i18n="abbreviation for Minimum Weight@@minweight">Min Weight</p>
          </div>
          <div>
            <h2 [appNumberDisplay]="{number: filteredAnimalsMaxWeight}"></h2>
            <p class="text-shady-lady" [class.mtSpinner]="!completedDownload" i18n="abbreviation for Maximum Weight@@maxweight">Max Weight</p>
          </div>
          <div>
            <h2 [appNumberDisplay]="{number: filteredAnimalsAdg, roundTo: 2, roundAfter: 5}"></h2>
            <p class="text-shady-lady">
              <ng-container i18n="acronym for Average Daily Gain@@adg">ADG</ng-container>
              <!-- <app-adg-icon [group]="curGroup" [animalsWithWeights]="filteredCensus ? filteredCensus.animalCount : null"></app-adg-icon> -->
            </p>
          </div>
        </div>
        <div *ngIf="!completedDownload" class="card-summary-header">
          <app-loading [local]="true"></app-loading>
        </div>
      </div>
    </div>

    <!-- filter -->
    <div class="filter">
      <app-reports-filters
        [data]="allRows || []"
        [filterOptions]="filterOptions"
        (globalFilterChanged)="tt.filterGlobal($event.value, 'contains')"
        (globalParameterService.detailsSearchParams)="tt.filter($event.value, 'contains')">
      </app-reports-filters>
    </div>

    <!-- table -->
    <div [class.hidden]="!rows || allRows.length === 0" #datatableContainer class="card card-table">

      <div class="card-header card-header-table">
        <div class="animals-header-bar">
          <div>

            <!-- actions -->
            <ng-container *ngIf="completedDownload">
              <div ngbDropdown>
                <button class="btn btn-link" ngbDropdownToggle i18n="@@updateStatus">Update Status</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem (click)="markAsCurrent(filteredAnimals)" i18n="@@markAsCurrent">Mark as Current</button>
                  <button ngbDropdownItem (click)="markAsSold(filteredAnimals)">{{localeService.constants.stringMarkAsSold}}</button>
                  <button ngbDropdownItem (click)="markAsDied(filteredAnimals)">{{localeService.constants.stringMarkAsDied}}</button>
                </div>
                <!-- assignment -->
                <button class="btn btn-link" (click)="assignOrReassignAnimals()" *ngIf="permissionsService.permissions.canEditData" i18n="@@changeGroup">Change Group</button>
              </div>
            </ng-container>

            <div class="d-flex align-items-center h100p" *ngIf="!completedDownload">
              <i class="fa fa-circle-o-notch fa-spin mr-2"></i> <span i18n="loading">Loading</span>...
            </div>

          </div>
          <div class="d-flex justify-content-end">

            <!-- export -->
            <div ngbDropdown>
              <button *ngIf="headers" class="btn btn-link" ngbDropdownToggle [disabled]="!completedDownload" i18n="@@export">Export</button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="reportsService.downloadCsv(curHeaders, filteredAnimals, 'animals')">{{localeService.constants.stringSpreadsheetBracketsCsv}}</button>
                <button ngbDropdownItem (click)="reportsService.exportPDF(curHeaders, filteredAnimals,  'animals')">PDF</button>
                <button ngbDropdownItem *ngIf="integration && permissionsService.permissions.canAccessPpl && permissionsService.permissions.canEditData"
                        (click)="showCreateDraftModal()" i18n="@@remotewowdrafter">Remote WOW Drafter
                </button>
              </div>
            </div>

            <!-- columns -->
            <app-reports-column-picker
              *ngIf="curHeaders"
              [curHeaders]="curHeaders"
              [headers]="headers"
              (headersChanged)="columnsReordered($event,true)">
            </app-reports-column-picker>
          </div>
        </div>
      </div>

      <!--      <app-animals-list [headers]="curHeaders" [dataSource]="filteredAnimals" class="take-all"></app-animals-list>-->

      <p-table class="no-overflow"
               #tt
               [columns]="curHeaders"
               [value]="rows"
               (onColReorder)="columnsReordered($event.columns)"
               [reorderableColumns]="true"
               (sortFunction)="reportsService.sortData($event, rows)"
               [customSort]="true"
               [autoLayout]="true"
               [rows]="50"
               [paginator]="true"
               [pageLinks]="11"
               [rowsPerPageOptions]="[20,50,100]"
               (onFilter)="filterChanged();filterAnimalsBySearchBox($event)"
               (window:resize)="reportsService.onResize();"
               tableStyleClass="table table-hover">

        <!-- header -->
        <ng-template pTemplate="header" let-columns>
          <tr class="ui-widget-content">
            <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="ui-state-default" title="{{col.title}}">
              <span [innerHTML]="col.header"></span>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <!-- body -->
        <ng-template pTemplate="body" let-row let-columns="columns">
          <tr class="ui-widget-content">
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.isCustomId">
                <span [title]="row[col.field]">
                  <a href="javascript:void(0)" (click)="openAnimalInModal(row)">{{ row[col.field]}}</a>
                  <!-- <a routerLink="/main/animals/details" [queryParams]="{id:row['AnimalId']}">{{row[col.field]}}</a> -->
                </span>
              </ng-container>
              <ng-container *ngIf="!col.isCustomId">
                <span [title]="row[col.field]">
                    {{row[col.field]}}
                </span>
              </ng-container>
            </td>
          </tr>
        </ng-template>

      </p-table>

      <app-floating-scroll-bar *ngIf="!isMobile"></app-floating-scroll-bar>

    </div>

  </ng-container>
</div>


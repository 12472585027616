<app-summary-header
  i18n-headerTitle="@@actions"
  headerTitle="Actions"
  i18n-subHeaderTitle="@@assignYourAnimalsAndWeightsToGroups"
  subHeaderTitle="Assign your animals and weights to groups">
</app-summary-header>

<app-loading *ngIf="!visibleSessions"></app-loading>

<div class="page-content page-content-y">

  <ng-container *ngIf="allSessions && actionsCount > 0">

    <!-- filter -->
    <div class="quick-search">
      <app-form-input top="0" icon="fa-search">
        <input type="text" i18n-placeholder="placeholder text for a filter input@@quicksearch" placeholder="Filter" (keyup)="search($event.target.value)" class="form-control">
      </app-form-input>
    </div>

    <p-dataScroller [value]="visibleSessions" [rows]="10" #actionsDataScroller>
      <ng-template let-session pTemplate="item">
        <ng-container *ngIf="!session.hidden && session.actions && session.actions.length > 0">

          <div class="card mb-3">

            <div class="card-header">
              <div class="row flex-grow-1">
                <div class="col-md-8">
                  <h3>
                    <a routerLink="/main/sessions/summary" [queryParams]="{id: session.sessionId}">{{session.sessionName}}</a> ({{dayMonthYearString(session.sessionStartDate)}}) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h3>
                </div>
                <div class="col-md-4 session-right">
                    <div class="text-nowrap">
                      <strong class="mb-0 mt-2 mr-2" [appNumberDisplay]="{number: session.animalCount}"></strong>
                      <span class="mr-4" i18n="@@animals">Animals</span>
                    </div>
                    <div class="text-nowrap">
                      <strong class="mb-0 mt-2 mr-2" [appNumberDisplay]="{number: session.averageWeight}"></strong>
                      <span i18n="Average weight|an abbreviation for average weight@@avgweight">Avg Weight</span>
                    </div>
                </div>
              </div>
            </div>

            <div class="card-body py-0">
              <div class="action-box-container" *ngFor="let action of session.actions">

                <app-actions-box
                  *ngIf="action"
                  [action]="action"
                  (disableEnableAction)="disableEnableAction(action.v_ActionId, $event)">
                </app-actions-box>

              </div>
            </div>

          </div>
        </ng-container>
      </ng-template>
    </p-dataScroller>
  </ng-container>

  <div class="card" *ngIf="allSessions && actionsCount === 0">
    <div class="card-body">
      <div class="card-blank-state">
          <i class="fa fa-check"></i>
          <h3>
            <ng-container i18n="@@youreallcaughtup">You're all caught up</ng-container>
          </h3>
          <p>
            <ng-container i18n="@@nopendingactions">No pending actions</ng-container>.
          </p>
      </div>
    </div>
  </div>

</div>


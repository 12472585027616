import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IActionsModalSplitter} from '../i-actions-modal-splitter';
import {GroupWeightRecordSplit} from '../../../../models/group-weight-record-split.model';
import {CreateNewGroupService} from '../../../../create-new-group/create-new-group.service';
import {Group} from '../../../../models/group.model';
import {ActionsModalService} from '../../actions-modal.service';
import {LocaleService} from '../../../../../helpers/locale.service';
import {AboveOrBelowSummary} from '../../../../models/above-or-below-summary';
import { SelectItem } from '../../../../models/select-item';

declare let moment: any;

@Component({
  selector: 'app-actions-modal-splitter-date',
  templateUrl: './actions-modal-splitter-date.component.html',
  styleUrls: ['./actions-modal-splitter-date.component.scss']
})
export class ActionsModalSplitterDateComponent implements OnInit, IActionsModalSplitter, OnChanges {
  static self: ActionsModalSplitterDateComponent;
  @Input() headerName: string;

  aboveOrEqualToDate = new Date();
  aboveSummary: AboveOrBelowSummary = {};
  belowSummary: AboveOrBelowSummary = {};

  groupsSelectList: Array<SelectItem> = [];

  constructor(public actionsModalService: ActionsModalService,
              private createNewGroupService: CreateNewGroupService,
              public localeService: LocaleService) {
    ActionsModalSplitterDateComponent.self = this;
  }

  ngOnInit() {
    setTimeout(() => { // run with delay to avoid front end glitch
      this.actionsModalService.getGroupsWrSplit = this.getGroupWrsSplit;
      this.groupsFiltered('');
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headerName && changes['headerName'].currentValue) {
      this.workoutSummaries()
    }
  }

  workoutSummaries() {
    this.aboveSummary = { count: 0, weight: 0, label: '', value: this.aboveSummary ? this.aboveSummary.value : null, weightRecordIds: [] };
    this.belowSummary = { count: 0, weight: 0, label: '', value: this.belowSummary ? this.belowSummary.value : null, weightRecordIds: [] };
    this.actionsModalService.animalData.forEach(ad => {
      if (ad[this.headerName] !== null && ad[this.headerName] !== undefined) {
        if (ad[this.headerName] && new Date(ad[this.headerName]).getTime() >= new Date(this.aboveOrEqualToDate).getTime()) {
          this.aboveSummary.count++;
          this.aboveSummary.weight += ad.weight || 0;
          this.aboveSummary.weightRecordIds.push(ad.weightRecordId);
        } else {
          this.belowSummary.count++;
          this.belowSummary.weight += ad.weight || 0;
          this.belowSummary.weightRecordIds.push(ad.weightRecordId);
        }
      }
    });
    this.aboveSummary.weight = this.aboveSummary.count > 0 ? Math.round(this.aboveSummary.weight / this.aboveSummary.count) : 0;
    this.belowSummary.weight = this.belowSummary.count > 0 ? Math.round(this.belowSummary.weight / this.belowSummary.count) : 0;
  }

  groupChanged(groupId, above = true) {
    if (groupId === 0) {
      let groupName = above ? this.localeService.constants.stringOnOrAfter + ' ' + moment(this.aboveOrEqualToDate).format('DD MMM YYYY')
        : this.localeService.constants.stringBefore + '  ' + moment(this.aboveOrEqualToDate).format('DD MMM YYYY');
      this.createNewGroupService.createGroup(this.actionsModalService.action, false, groupName, (group: Group) => {
        setTimeout(() => {

          // update groups, add new one
          this.actionsModalService.updateGroups();
          this.groupsSelectList = this.actionsModalService.groups.slice();

          if (above) {
            this.aboveSummary.value = group ? group.groupId : null;
            this.aboveSummary.label = group ? group.displayName : null;
          } else {
            this.belowSummary.value = group ? group.groupId : null;
            this.belowSummary.label = group ? group.displayName : null;
          }
        }, 0)
      }).result.then(() => {}, () => {
        if (above) {
          this.aboveSummary.value = null;
        } else {
          this.belowSummary.value = null;
        }
      });
    } else {
      let group = this.actionsModalService.groups.find(g => g.value === groupId);
      if (above) {
        this.aboveSummary.value = groupId;
        this.aboveSummary.label = group ? group.label : null;
      } else {
        this.belowSummary.value = groupId;
        this.belowSummary.label = group ? group.label : null;
      }
    }
  }

  getGroupWrsSplit(): Array<GroupWeightRecordSplit> {
    const self = ActionsModalSplitterDateComponent.self;
    const splits: Array<GroupWeightRecordSplit> = [];

    // above
    if (self.aboveSummary.value > 0 && self.aboveSummary.count > 0) {
      const split = new GroupWeightRecordSplit(self.aboveSummary.value, self.headerName, ' on or after ' + self.aboveOrEqualToDate.toISOString());
      split.weightRecordIds = self.aboveSummary.weightRecordIds;
      splits.push(split);
    }

    // below
    if (self.belowSummary.value > 0 && self.belowSummary.count > 0) {
      const split = new GroupWeightRecordSplit(self.belowSummary.value, self.headerName, 'before ' + self.aboveOrEqualToDate.toISOString());
      split.weightRecordIds = self.belowSummary.weightRecordIds;
      splits.push(split);
    }

    return splits.length > 0 ? splits : [];
  }

  groupsFiltered(searchWords: string) {
    if (searchWords && searchWords.replace(' ', '') !== '') {
      let sw = searchWords.split(' ').filter(s => s.length > 0);
      this.actionsModalService.updateGroups();
      this.groupsSelectList = this.actionsModalService.groups.filter(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.label.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        return ret >= sw.length;
      });
    } else {
      this.actionsModalService.updateGroups(1);
      this.groupsSelectList = this.actionsModalService.groups.slice();
    }
  }
}

import {SessionService} from './api/session.service';
import {GroupsService} from './services/groups.service';
import {Farm} from './models';
import {Injectable, OnDestroy} from '@angular/core';
import {Router, Event, NavigationStart} from '@angular/router';
import {MainService} from './main.service';
import {Observable, Subscription} from 'rxjs';

import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {User} from '../main/models/user.model';
import {FarmUser} from '../main/models/farm-user.model';
import {GlobalParameterService} from '../helpers/global-parameter.service';
import {GaService} from '../gaService';
import {CookieService} from 'ngx-cookie';
import {ApiService} from './api/api.service';
import {IntegrationService} from './api/integration.service';
import {combineLatest} from 'rxjs';
import {LocaleService} from '../helpers/locale.service';

@Injectable({
  providedIn: 'root'
})
export class IntercomService implements OnDestroy {
  sub: Array<Subscription> = [];
  lastPageName = '';
  intercomConnected = false;
  userSub: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private mainService: MainService,
    private gaService: GaService,
    private groupsService: GroupsService,
    private sessionService: SessionService,
    private globalParameterService: GlobalParameterService,
    private cookieService: CookieService,
    private apiService: ApiService,
    private integrationService: IntegrationService,
    private localeService: LocaleService) {

    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      if (!user && this.intercomConnected) {
        this.logout();
        this.intercomConnected = false;
      } else if (user && !this.intercomConnected) {
        this.connect(user);
        this.intercomConnected = true;
      }
    });
  }

  connect(user: User): boolean {
    if ((<any>window).Intercom && this.authService.currentUserChanged.value
      && AuthService.emailsToIgnore.indexOf(this.authService.currentUserChanged.value.email.toLowerCase()) < 0
      && this.authService.currentUserChanged.value.userType.toLowerCase() !== 'superuser'
      && this.authService.currentUserChanged.value.email.toLowerCase().indexOf('test-e2e-') < 0) {
      if ((<any>window).intercomSettings) {
        (<any>window).intercomSettings.userHash = user.userHash
      }
      (<any>window).Intercom('update', { user_id: this.authService.currentUserChanged.value ? this.authService.currentUserChanged.value.userId : null });
      this.logEvent('pagenavigate', this.router.url);
      this.sub.push(this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          (<any>window).Intercom('update', {
            user_id: this.authService.currentUserChanged && this.authService.currentUserChanged.value
              ? this.authService.currentUserChanged.value.userId
              : null,
            user_hash: user.userHash
          });
          this.logEvent('pagenavigate', event.url);
        }
      }));

      // waiting for groups as new requirement asks to have group and session data
      // groups are loaded after sessions so no reason to wait for sessions as well.
      // no need to pass as updateIntercom called from another place that has sessions/groups.
      this.sub.push(combineLatest(this.authService.currentUserChanged, this.mainService.farmChanged, this.groupsService.groups
        , this.sessionService.sessions, this.authService.currentSubscription).subscribe(data => {
        const iuser = data[0], farm = data[1], groups = data[2], sessions = data[3];
        if (iuser && farm && groups && sessions && AuthService.emailsToIgnore.indexOf(iuser.email.toLowerCase()) < 0
          && (this.authService.currentUserChanged.value && this.authService.currentUserChanged.value.userType.toLowerCase()) !== 'superuser'
          && user.email.toLowerCase().indexOf('test-e2e-') < 0) {
          this.updateIntercom(farm, iuser);
        }
      }));
      return true;
    }
    return false;
  }

  updateIntercom(farm: Farm, user: User) {
    if (farm && user && user.email && user.firstName && user.lastName
      && AuthService.emailsToIgnore.indexOf(user.email.toLowerCase()) < 0
      && (this.authService.currentUserChanged.value && this.authService.currentUserChanged.value.userType.toLowerCase()) !== 'superuser'
      && user.email.toLowerCase().indexOf('test-e2e-') < 0
      && (user.userFarms.find(uf => uf.farmId === farm.farmId))) {
      let animalCount: number = null;
      if (this.sessionService.sessions.value) {
        this.sessionService.sessions.value.forEach(s => animalCount += s.animalCount);
      }
      (<any>window).intercomSettings = {
        app_id: environment.intercomToken,
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        user_id: user.userId,
        phone: user.phoneNumber,
        firstname: user.firstName,
        lastname: user.lastName,
        userType: user.userType,
        userCountry: user.country,
        company: {
          company_id: farm.farmId,
          name: farm.farmName,
          farmUsedDevices: farm.truTestDevicesUsed,
          accountType: farm.farmType,
          region: farm.region,
          plan: farm.subscriptionPlanName,
          deleted: farm.deleted,
          farmType: farm.farmType,

          /* new fields */
          country: farm.countryLookup.countryName,
          sessionCount: this.sessionService.sessions.value ? this.sessionService.sessions.value.length : null,
          groupCount: this.groupsService.groups.value ? this.groupsService.groups.value.length : null,
          animalCount: animalCount
        },
        subscription: this.authService.currentSubscription.value ?
          this.authService.currentSubscription.value.subscriptionPlan.planName : '',
        user_hash: user.userHash
      };
      if (this.integrationService.integration && this.integrationService.integration.value) {
        (<any>window).intercomSettings.company.remoteWOW = 'yes';
      }

      let lang = this.cookieService.get('locale');
      if (lang && lang.length === 2) {
        (<any>window).intercomSettings.language_override = lang;
      }

      (<any>window).Intercom('boot', {
        app_id: environment.intercomToken,
        name: `${user.firstName} ${user.lastName}`, // Full name
        email: user.email // Email address
      });
    }
  }

  logEvent(eventName: string, pageName: string = null, extraVars: any = {}) {
    if (!this.intercomConnected) {
      return;
    }
    if (this.lastPageName !== pageName && pageName) {
      // we need to avoid sending to google analytics twice when navigate to groups/summary and navigate to groups/summary?groupId
      const excludeUrls = ['groups/summary', 'groups/details', 'sessions/summary', 'sessions/details', 'animals/details'];
      let ignoreSendEvent = excludeUrls.some(url => this.lastPageName.indexOf(url) !== -1 && pageName.indexOf(url) !== -1
        && this.lastPageName.indexOf('?') === -1 && pageName.indexOf('?') !== -1);
      if (ignoreSendEvent) {
        this.lastPageName = pageName;
        return;
      }
    }
    if (eventName === 'pagenavigate' && pageName) {
      this.lastPageName = pageName;
    }

    if ((<any>window).Intercom && this.authService.currentUserChanged &&
      this.authService.currentUserChanged.value
      && AuthService.emailsToIgnore.indexOf(this.authService.currentUserChanged.value.email.toLowerCase()) < 0
      && this.authService.currentUserChanged.value.userType.toLowerCase() !== 'superuser'
      && this.authService.currentUserChanged.value.email.toLowerCase().indexOf('test-e2e-') < 0) {
      let event = eventName;
      if (eventName === 'pagenavigate' && pageName.includes('/main/')) {
        // Create a sanitised page name from the URL for identificaiton within Intercom
        event = pageName.substr(6);
        event = event.split('?')[0];
        event = event.replace(/(\/[0-9]+)/, '');
      }
      (<any>window).Intercom('trackEvent', event, Object.assign({
        pagename: pageName,
        farmid: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
        farmname: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmName : null,
        groupid: this.globalParameterService.currentGroupId,
        sessionid: this.globalParameterService.currentSessionId
      }, extraVars));
      this.gaService.logEvent(eventName, pageName || 'no-page', extraVars);
    }

    this.apiService.post('/api/UserActivity',
      {
        category: eventName === 'pagenavigate' ? 1 : 2,
        timeStamp: new Date(),
        eventName: eventName,
        pageName: pageName,
        farm_FarmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
        userId: this.authService.currentUserChanged.value.userId,
        userGenerated: true
      }, 'text'
    ).subscribe(res => {
    }, err => {
    });
  }

  triggerEnterpriseConversation() {
    if ((<any>window).Intercom) {
      (<any>window).Intercom('showNewMessage', this.localeService.constants.stringNewIntercomEnterpriseConversationTitle);
    }
    return false;
  }

  triggerPremiumConversation() {
    if ((<any>window).Intercom) {
      (<any>window).Intercom('showNewMessage', this.localeService.constants.stringNewIntercomPremiumConversationTitle);
    }
    return false;
  }

  logout() {
    if ((<any>window).Intercom) {
      (<any>window).Intercom('shutdown');
      (<any>window).intercomSettings = {};
    }
    this.sub.forEach(s => {
      s.unsubscribe();
    });
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }

    this.sub.forEach(s => {
      s.unsubscribe();
    });
  }
}

import { Rounder } from '../../../../helpers/rounder.helper';
import {Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import { Session } from '../../../models/session.model';
import { Router } from '@angular/router';
import { GlobalParameterService } from '../../../../helpers/global-parameter.service';
import { DetailsSearchParams, DetailsSearchParam, DetailsSearchParamType } from '../../../models/details-search-params';
import { LocaleService } from '../../../../helpers/locale.service';

@Component({
  selector: 'app-session-weight-distribution',
  templateUrl: './session-weight-distribution.component.html',
  styleUrls: ['./session-weight-distribution.component.scss']
})
export class SessionWeightDistributionComponent implements OnInit, OnChanges {
  @Input() weightRecords: Array<number>;
  @Input() session: Session;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  options: any;
  chartName: string;

  constructor(private router: Router, private globalParameterService: GlobalParameterService, private localeService: LocaleService) { }

  ngOnInit() {
    this.setSeries();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.weightRecords && changes['weightRecords'] && changes['weightRecords'].currentValue) {
      this.setSeries();
    }
    this.setChartName();
  }

  setChartName() {
    this.chartName = this.localeService.constants.stringWeightGraph;
    if (this.session !== null) {
      this.chartName = `${this.getDisplayName(this.session)} - ${this.localeService.constants.stringWeightGraph}`;
    }
  }

  getDisplayName(s: Session) {
    let ssd = new Date(s.sessionStartDate)
      , formattedStartDate = `${ssd.getUTCDate()} ${this.localeService.month[(ssd.getUTCMonth())].month} ${ssd.getUTCFullYear()}`;
    return `${s.sessionName} (${formattedStartDate})`;
  }

  calculateBinSize(minWeight, maxWeight) {
    let weightBreakPoints = [10, 20, 50, 100, 200, 500, 1000, 2000];
    let i;

    for (i = 0; i < weightBreakPoints.length; i++) {
      if (Math.ceil(maxWeight) - Math.floor(minWeight) < weightBreakPoints[i]) {
        return weightBreakPoints[i] / 10;
      }
    }

    // shouldn't reach here unless the weight range is greater than 2000.
    return 100;
  }

  createXAxisFromWeights(weights) {
    let minWeight = Math.min.apply(null, weights),
      maxWeight = Math.max.apply(null, weights),
      binSize = this.calculateBinSize(minWeight, maxWeight),
      result: any = new Object();

    minWeight = Math.floor(minWeight / binSize) * binSize;
    maxWeight = Math.floor(maxWeight / binSize) * binSize + binSize;

    result.minWeight = minWeight;
    result.maxWeight = maxWeight;
    result.binSize = binSize;
    result.numberOfBins = (maxWeight - minWeight) / binSize + 1;

    return result;
  }

  setSeries() {
    this.setChartName();
    if (!this.weightRecords || this.weightRecords.length === 0) {
      this.options = null;
      return;
    }
    let xAxis = this.createXAxisFromWeights(this.weightRecords),
      binnedData = new Array(xAxis.numberOfBins);
    for (let i = 0, l = xAxis.numberOfBins; i < l; i++) {
      binnedData[i] = [xAxis.minWeight + i * xAxis.binSize, 0];
    }
    for (let i = 0; i < this.weightRecords.length; i++) {
      let w = this.weightRecords[i],
        bin = Math.floor((w - xAxis.minWeight) / xAxis.binSize);
      binnedData[bin][1] = binnedData[bin][1] + 1;
    }

    let tinterval = 1;
    if (binnedData.length > 1) {
      tinterval = binnedData[1][0] - binnedData[0][0];
    }

    let cName = this.chartName;
    this.options = {
      series: [{
        name: this.localeService.constants.stringCount,
        data: binnedData,
        cursor: 'pointer'
      }],
      title: '',
      subtitle: '',
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        events: {
          beforePrint: function () {
            this.setTitle({
              text: null
            });
          },
          afterPrint: function () {
            this.setTitle({ text: null });
          }
        }
      },
      tooltip: {
        headerFormat: '',
        formatter: function () {
          let val = '', s = '';
          val = (Rounder.performRounding({ number: this.y }) || this.localeService.constants.stringNA).toString();
          s += `${this.series.name}: <b>${val}</b><br/>`;

          return s;
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 2,
          groupPadding: 0,
          shadow: false,
          pointPlacement: +0.5,
          events: {
            click: e => {
              this.globalParameterService.detailsSearchParams.next({
                Weight: new DetailsSearchParam(this.localeService.constants.stringWeight,
                  DetailsSearchParamType.number, e.point.x, e.point.x + e.point.series.closestPointRange, null)
              });
              this.globalParameterService.currentSessionId = this.session.sessionId;
              this.changeTab.emit();
            }
          }
        }
      },
      xAxis: {
        title: {
          text: null
        },
        labels: {
          style: {
            fontFamily: '"Lato", Helvetica, Arial, sans-serif',
            fontSize: '12px'
          }
        },
        tickInterval: tinterval
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          style: {
            fontFamily: '"Lato", Helvetica, Arial, sans-serif',
            fontSize: '12px'
          }
        },
        lineWidth: 1,
        allowDecimals: false
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              {
                text: this.localeService.constants.stringPrintChart,
                onclick: function () {
                  this.print();
                }
              },
              {
                text: this.localeService.constants.stringDownloadPDF,
                onclick: function () {
                  this.setTitle({
                    text: null
                  });
                  this.exportChart({
                    type: 'application/pdf',
                    filename: cName
                  });
                }
              },
              {
                text: this.localeService.constants.stringDownloadPNG,
                onclick: function () {
                  this.setTitle({
                    text: null
                  });
                  this.exportChart({
                    filename: cName
                  });
                }
              }
            ]
          }
        }
      }
    }; // End of options
  }

}

<!-- MAIN BODY -->

<div id="main" class="main-body custom-scrollbar" [ngClass]="mainService.mainCustomCssClasses">

  <!-- MAIN HEADER -->
<!--  <header class="main-header">-->

<!--    &lt;!&ndash; logo and main menu toggle button &ndash;&gt;-->
<!--    <div class="main-menu-toggle" (click)="toggleLeftMenu()"></div>-->

<!--    &lt;!&ndash; right: question mark and user menu &ndash;&gt;-->
<!--    <div class="header-right">-->

<!--      &lt;!&ndash; help button &ndash;&gt;-->
<!--      <a class="header-help mx-1" i18n-title="@@help" title="Help" target="_blank" href="http://livestocksupport.mihub.tru-test.com/">-->
<!--        <span class="ci ci-help-custom d-flex"></span>-->
<!--      </a>-->

<!--      &lt;!&ndash; user menu &ndash;&gt;-->
<!--      <div ngbDropdown placement="bottom-right">-->
<!--        &lt;!&ndash; <app-dropdown [showFilter]="mainService.farmsChanged.value.length > 10" [minWidth]="authService.currentUser && authService.currentUser.userType === 'SuperUser'"></app-dropdown>&ndash;&gt;-->

<!--        <button ngbDropdownToggle-->
<!--                class="btn-dark main-right-menu"-->
<!--                *ngIf="authService.currentUser && authService.currentUser.userType !== 'SuperUser' && mainService.farmsChanged.value"-->
<!--                >-->
<!--          {{authService.currentUser && authService.currentUser.firstName && authService.currentUser.lastName ? authService.currentUser.firstName + ' '+ authService.currentUser.lastName : ''}}<br/>-->
<!--          {{mainService.farmChanged.value ? mainService.farmChanged.value.farmName : ''}}-->
<!--        </button>-->

<!--        <div ngbDropdownMenu>-->
<!--          <button ngbDropdownItem *ngFor="let farm of mainService.farmsChanged.value" (click)="changeFarm(farm)">{{farm.farmName}}</button>-->
<!--          <div class="dropdown-divider"></div>-->
<!--          <button ngbDropdownItem *ngIf="mainService.canCreateFarms" (click)="farmCreationScreen.show = true" i18n="@@createnewfarm">Create new farm</button>-->
<!--          <button ngbDropdownItem (click)="demoFarmCreationScreen.show = true" i18n="@@createnewdemofarm">Create new demo farm</button>-->
<!--          <div class="dropdown-divider"></div>-->
<!--          <button ngbDropdownItem routerLink="subscriptions" i18n="@@subscription">Subscription</button>-->
<!--          <button ngbDropdownItem routerLink="manage/user" (click)="topRightMenuDropdown = false" i18n="@@mydetails">My Details</button>-->
<!--          <div class="dropdown-divider"></div>-->
<!--          <button ngbDropdownItem (click)="logoutButtonClicked()" i18n="@@signout">Sign Out</button>-->
<!--        </div>-->

<!--      </div>-->

<!--      <div ngbDropdown placement="bottom-right">-->
<!--        <button ngbDropdownToggle-->
<!--                class="btn-dark main-right-menu"-->
<!--                *ngIf="(authService.currentUser && authService.currentUser.userType !== 'SuperUser' && !mainService.farmsChanged.value) || (authService.currentUser && authService.currentUser.userType === 'SuperUser' && !mainService.farmChanged.value)">-->
<!--          {{authService.currentUser && authService.currentUser.firstName && authService.currentUser.lastName ? authService.currentUser.firstName + ' '+ authService.currentUser.lastName : ''}}-->
<!--        </button>-->
<!--        <div ngbDropdownMenu>-->
<!--          <button ngbDropdownItem (click)="logoutButtonClicked()" i18n="@@signout">Sign Out</button>-->
<!--        </div>-->
<!--      </div>-->

<!--      <ng-container *ngIf="authService.currentUser && authService.currentUser.userType === 'SuperUser' && mainService.farmChanged.value">-->

<!--        <p-autoComplete *ngIf="mainService.farmsChanged.value" autofocus [(ngModel)]="text" [suggestions]="filteredFarms" (completeMethod)="filterFarms($event)"-->
<!--                        (onSelect)="autoSelected($event);" (onClear)="filterFarms($event)" [forceSelection]="true" [field]="farmName" [minLength]="1">-->
<!--          <ng-template let-farm pTemplate="item">-->
<!--            <div style="font-size:16px;margin:10px;float: right;">({{farm.farmId}})</div>-->
<!--            <div style="font-size:16px;margin:10px">{{farm.farmName}}&nbsp;<span *ngIf="!farm.subscriptionDaysLeft || farm.subscriptionDaysLeft < 0" class="text-shady-lady"-->
<!--                                                                                 style="font-style: italic">(Expired)</span></div>-->
<!--          </ng-template>-->
<!--        </p-autoComplete>-->

<!--        <div *ngIf="!mainService.farmsChanged.value" class="row  mt-1 mb-1 summary-header"-->
<!--             style="float:right; margin-left: 25px; margin-right: 15px; padding-right: 15px; padding-top: 25px; font-size: 16px;">-->
<!--            <div class="col-sm-2 col-xs-4 text-center">-->
<!--                <div class="widget-loading-spinner soloSpinner">-->
<!--                    <i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>-->
<!--                    <span class="sr-only" i18n="@@loading">Loading...</span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div ngbDropdown placement="bottom-right">-->
<!--          <button ngbDropdownToggle-->
<!--                  class="btn-dark main-right-menu"-->
<!--                  *ngIf="mainService.farmChanged && mainService.farmChanged.value">-->
<!--            {{authService.currentUser.firstName}} {{authService.currentUser.lastName}} (SuperUser)<br/>{{mainService.farmChanged.value.farmName}}-->
<!--          </button>-->
<!--          <div ngbDropdownMenu>-->
<!--            <button ngbDropdownItem (click)="logoutButtonClicked()" i18n="@@signout">Sign Out</button>-->
<!--          </div>-->
<!--        </div>-->

<!--      </ng-container>-->

<!--    </div>-->

<!--  </header>-->


  <!-- MAIN ASIDE -->
  <aside class="main-aside custom-scrollbar-reverse">

    <!-- header -->
    <div class="navbar-branding">
      <a routerLink="dashboard" class="navbar-brand">
        <img src="assets/mihub-logo.svg" class="d-inline" width="120">
      </a>
    </div>

    <!-- main menu -->
    <div class="sidebar-left-content">
      <app-main-menu></app-main-menu>
    </div>

  </aside>

  <!-- MAIN SECTION -->
  <section class="main-section">

    <!-- content -->
    <div>
      <app-loading *ngIf="router.url === '/main'"></app-loading>
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <footer class="footer">
      <div class="page-content">
        <div class="footer-content">
          <div class="row flex-align-items-center">
            <div class="col-sm-8">

              <!--                <ng-container i18n="Tru-Test is the company name@@formoreinformationontrutest">For more information on Tru-Test solutions visit our website</ng-container>-->
              <!--                <a href="http://www.tru-test.com">www.tru-test.com</a>-->
              <!--                <br/>-->
              <div class="links-container">
                <a target="_blank" href="http://support.livestock.datamars.com/" i18n="@@helpcentrelink"> Help Centre</a><span class="mid-dot">&middot;</span>
                <a target="_blank" routerLink="/privacy-policy" i18n="@@privacupolicylink"> Privacy Policy</a><span class="mid-dot">&middot;</span>
                <a target="_blank" routerLink="/cookie-policy" i18n="@@cookiepolicylink"> Cookie Policy</a><span class="mid-dot">&middot;</span>
                <a target="_blank" routerLink="/terms-and-conditions" i18n="@@termsandconditionslink">Terms and Conditions</a>
              </div>

              <div class="links-container">
                <a href="javascript:void(0)" (click)="changeLanguage('en')">English</a><span class="mid-dot">&middot;</span>
                <a href="javascript:void(0)" (click)="changeLanguage('fr')">Français</a><span class="mid-dot">&middot;</span>
                <a href="javascript:void(0)" (click)="changeLanguage('de')">Deutsch</a><span class="mid-dot">&middot;</span>
                <a href="javascript:void(0)" (click)="changeLanguage('pt')">Português</a><span class="mid-dot">&middot;</span>
                <a href="javascript:void(0)" (click)="changeLanguage('es')">Español</a>
              </div>
            </div>

            <div class="col-sm-4 app-store-container">
              <!-- app-store -->
              <a *ngIf="lang === 'en'" class="app-store" id="appstore-ad" href='https://itunes.apple.com/nz/app/data-link/id893033296?mt=8'><img src="assets/en-app-store.svg"
                                                                                                                                                  alt="Download from AppStore"
                                                                                                                                                  style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'fr'" class="app-store" id="appstore-ad" href='https://itunes.apple.com/nz/app/data-link/id893033296?mt=8'><img src="assets/fr-app-store.svg"
                                                                                                                                                  alt="Download from AppStore"
                                                                                                                                                  style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'de'" class="app-store" id="appstore-ad" href='https://itunes.apple.com/nz/app/data-link/id893033296?mt=8'><img src="assets/de-app-store.svg"
                                                                                                                                                  alt="Download from AppStore"
                                                                                                                                                  style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'pt'" class="app-store" id="appstore-ad" href='https://itunes.apple.com/nz/app/data-link/id893033296?mt=8'><img src="assets/pt-app-store.svg"
                                                                                                                                                  alt="Download from AppStore"
                                                                                                                                                  style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'es'" class="app-store" id="appstore-ad" href='https://itunes.apple.com/nz/app/data-link/id893033296?mt=8'><img src="assets/es-app-store.svg"
                                                                                                                                                  alt="Download from AppStore"
                                                                                                                                                  style="max-height: 60px;"/></a>
              <!-- play store -->
              <a *ngIf="lang === 'en'" class="play-store" id="playstore-ad"
                 href='https://play.google.com/store/apps/details?id=com.trutest.android&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play'
                                                                                                                                                                      src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
                                                                                                                                                                      style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'fr'" class="play-store" id="playstore-ad"
                 href='https://play.google.com/store/apps/details?id=com.trutest.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible sur Google Play'
                                                                                                                                                                src='https://play.google.com/intl/en_us/badges/images/generic/fr_badge_web_generic.png'
                                                                                                                                                                style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'de'" class="play-store" id="playstore-ad"
                 href='https://play.google.com/store/apps/details?id=com.trutest.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Jetzt bei Google Play'
                                                                                                                                                                src='https://play.google.com/intl/en_us/badges/images/generic/de_badge_web_generic.png'
                                                                                                                                                                style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'pt'" class="play-store" id="playstore-ad"
                 href='https://play.google.com/store/apps/details?id=com.trutest.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponível no Google Play'
                                                                                                                                                                src='https://play.google.com/intl/en_us/badges/images/generic/pt_badge_web_generic.png'
                                                                                                                                                                style="max-height: 60px;"/></a>
              <a *ngIf="lang === 'es'" class="play-store" id="playstore-ad"
                 href='https://play.google.com/store/apps/details?id=com.trutest.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play'
                                                                                                                                                                src='https://play.google.com/intl/en_us/badges/images/generic/es_badge_web_generic.png'
                                                                                                                                                                style="max-height: 60px;"/></a>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </section>

</div>

<!--<app-demo-farm-creation #demoFarmCreationScreen></app-demo-farm-creation>-->

<!-- block background -->
<div class="block-background" (click)="toggleLeftMenu()"></div>

<!-- global loader -->
<app-global-loader></app-global-loader>

import { Session } from './session.model';
import { Animal } from '../animals/shared/animal.model';
import * as moment from 'moment';
import utcToLocalConverter from '../api/utc-to-local-converter';
import utcToLocalProperty from '../api/utc-to-local-converter';

interface IWeightRecord {
    weightRecordId: number;
    weight: number;
    timeStamp: Date;
    userDefinedFieldsJson: any;
    lifeDataUserDefinedFieldJson: any;
    animal_AnimalId: number;
    farm_FarmId: number;
    session_SessionId: number;
    animal: Animal;
    session: Session;

    adgFirstSeen?: any;
    adgLastSeen?: any;
    weightGainFirstSeen?: any;
    weightGainLastSeen?: any;
    groups_GroupId?: any;
    animalStatus_AnimalStatusId?: number;
}
export class WeightRecord implements IWeightRecord{
    weightRecordId: number;
    weight: number;
    userDefinedFieldsJson: any;
    lifeDataUserDefinedFieldJson: any;
    animal_AnimalId: number;
    farm_FarmId: number;
    session_SessionId: number;
    animal: Animal;
    session: Session;
    adgFirstSeen?: any;
    adgLastSeen?: any;
    weightGainFirstSeen?: any;
    weightGainLastSeen?: any;
    groups_GroupId?: any;
    animalStatus_AnimalStatusId?: number;

    @utcToLocalProperty
    timeStamp:Date;
}

export default WeightRecord;
import {Group} from './group.model';
import {WeightRecord} from './weight-record.model';
import utcToLocalProperty from '../api/utc-to-local-converter';

// additional properties (old)
export class SessionAction {
  animalCount: number = null;
  averageWeight: number = null;
  groupId: number = null;
  ided: boolean = null;
  sessionId: number = null;
  @utcToLocalProperty
  sessionStartDate: Date = null;
  v_ActionId?: string = null;
  farmId: number = null;
  recomendedGroup: Group;
  confirmationRequiredGroup: Group;
  greyOut: boolean;
  done: boolean;
  fadeIn: boolean;
  recommendedGroupId: number = null;
  orderBy?: number;
  entireSession = false;
  weightRecords?: Array<WeightRecord> = null;
  reassignGroup = false;
  oldGroupId: number = null;
  movePriorWeights = false;
}

<div class="card" *ngIf="paddock">

  <div class="card-header">
    <h3>{{paddock.integratedDeviceName}}</h3>
  </div>

  <div class="card-body">

    <div class="row">
      <div class="col-1">
        <i *ngIf="connected" class="fa fa-check-circle fa-2x class-local-1"></i>
        <i [hidden]="!connected" *ngIf="!connected" class="fa fa-times-circle fa-2x class-local-2"></i>
      </div>
      <h3 class="col-8 text-ellipsis">{{connectedMessage}} </h3>
      <span class="col-3 text-right text-shady-lady" [appTimeSince]="dateAsUTC(paddock.lastSeen)" title="{{dayMonthYearString(paddock.lastSeen)}}"></span>
    </div>

    <div class="card card-summary-header">
      <div class="card-body">
        <div>
          <h2>{{animalsYesterday}}</h2>
          <p class="text-shady-lady">Animals Yesterday</p>
        </div>
        <div>
          <h2>{{animals3Days}}</h2>
          <p class="text-shady-lady">Last 3 days</p>
        </div>
        <div>
          <h2>{{animalsWeek}}</h2>
          <p class="text-shady-lady">Last 7 days</p>
        </div>
      </div>
    </div>

    <h3 class="col-xs-offset-1 col-xs-8 text-ellipsis mb5 mt-1" *ngIf="group" style="color: black;">
      <a routerLink="/main/groups/summary" [queryParams]="{id: group.groupId}">{{group.displayName}}</a>
    </h3>

    <div class="row">
      <div class="col-sm-4 hidden-xs" *ngIf="group && group.censuses">
        <ng-container *ngIf="group.censuses.length > 0">
          <div class="text-center" style="padding-bottom: 4px;">
            <h2 class="mb-0 mt-2 fs30 monserrat" [appNumberDisplay]="{number: group.currentStatus.animalCount}"></h2>
            <p class="text-shady-lady">Animals</p>
          </div>
          <div class="text-center" style="padding-bottom: 4px;">
            <h2 class="mb-0 mt-2 fs30 monserrat" [appNumberDisplay]="{number: group.currentStatus.averageWeight}"></h2>
            <p class="text-shady-lady">Avg Weight</p>
          </div>
          <div class="text-center">
            <h2 class="mb-0 mt-2 fs30 monserrat" [appAdg]="group"></h2>
            <p class="text-shady-lady">ADG
              <app-adg-icon class="ml-1" [group]="group"></app-adg-icon>
            </p>
          </div>
        </ng-container>
      </div>
      <div class="col-sm-8 col-xs-12" *ngIf="group">
        <app-group-widget-graph class="w100p" style="display: block; height: 225px;" [group]="group"></app-group-widget-graph>
      </div>
      <app-loading *ngIf="!group || !loaded" [local]="true" style="height: 225px;"></app-loading>
    </div>

  </div>
</div>

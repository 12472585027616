import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-transfer-education',
  templateUrl: './transfer-education.component.html',
  styleUrls: ['./transfer-education.component.scss']
})
export class TransferEducationComponent implements OnInit {
  @Output()
  uploadFiles = new EventEmitter();
  public language = 'en';

  constructor(private cookieService: CookieService) { }

  log() {
    this.uploadFiles.emit();
  }

  ngOnInit() {
    let loc = this.cookieService.get('locale') || 'en';
    if (loc === 'pt' || loc === 'es') {
      this.language = loc;
    } else {
      // fallback to 'en' for any other language
      // this explicitly includes FR and DE currently
      // loc === 'fr' || loc === 'de' || loc === 'es'
      this.language = 'en';
    }
  }

}


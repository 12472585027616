import { Session } from './session.model';


export class BaseWeightPlan {
    group_GroupId: number;
    targetWeightPlanTemplateId?: number;
    targetWeightPlanTemplate_TargetWeightPlanTemplateId?: number;
    session?: Session;
    planName?: string;
}


export class WeightPlan extends BaseWeightPlan {
    targetWeightPlanId?: number = null;
    nominalDateOfBirth?: Date = null;
    startDate: Date = null;
    duratonMonth?: number = null;
    weightPlanPointsJson: string = null;
}

export interface WeightPlanPoint {
    date: string;
    weight: number;
}
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { FormValidation } from '../../../helpers/form-validation.helper';
import { AdminService } from '../../api/admin.service';
import { WhitelistIp } from '../../models/whitelist-ip.model';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';

@Component({
  selector: 'app-admin-whitelist-ips',
  templateUrl: './admin-whitelist-ips.component.html',
  styleUrls: ['./admin-whitelist-ips.component.scss'],
  providers: [ AdminService ]
})
export class AdminWhitelistIpsComponent implements OnInit {

  public ips: Array<WhitelistIp> = [];
  public finishedLoading = false;
  public showModal = false;
  form: FormGroup = null;
  modalHeader = '';
  window: Window = window;

  constructor(private adminService: AdminService, private bottomNotificationsService: BottomNotificationsService) { }

  ngOnInit() {
    this.getWhitelistIps();
  }

  getWhitelistIps() {
    this.adminService.getWhitelistIps().subscribe(ips => {
      this.ips = ips;
      this.finishedLoading = true;
    });
  }

  displayModal(wip: WhitelistIp = null) {
    this.showModal = true;

    this.modalHeader = wip ? 'EDIT IP RANGE' : 'ADD IP RANGE';

    this.form = new FormGroup({
      ipId: new FormControl(wip ? wip.whitelistIpsId : ''),
      startIpAddress: new FormControl(wip ? wip.startIpAddress : '', Validators.compose([FormValidation.validateIpAddress, Validators.required])),
      endIpAddress: new FormControl(wip ? wip.endIpAddress : '', Validators.compose([FormValidation.validateIpAddress, Validators.required])),
      ipName: new FormControl(wip ? wip.ipName : '', Validators.required)
    });
  }

  prepopulateEndIp() {
    if (this.form.controls['startIpAddress'].valid && this.form.controls['startIpAddress'].value !== '' && this.form.controls['endIpAddress'].value === '') {
      this.form.controls['endIpAddress'].setValue(this.form.controls['startIpAddress'].value);
    }
  }

  submitForm() {
    if (this.form.valid) {
      if (this.form.controls['ipId'].value === '') {
        // creating new range
        this.addIpAddressRange(
          this.form.controls['startIpAddress'].value,
          this.form.controls['endIpAddress'].value,
          this.form.controls['ipName'].value
        );
      } else {
        // editing existing range
        this.editIpAddressRange(
          this.form.controls['ipId'].value,
          this.form.controls['startIpAddress'].value,
          this.form.controls['endIpAddress'].value,
          this.form.controls['ipName'].value
        );
      }
    }
  }

  hideDialog() {
    this.showModal = false;
    this.modalHeader = '';
    this.getWhitelistIps();
  }

  cancelClicked() {
    this.hideDialog();
  }

  addIpAddressRange(startIp: string, endIp: string, ipName: string) {
    this.adminService.addWhitelistIp(startIp, endIp, ipName).subscribe(res => {
      this.hideDialog();
      this.bottomNotificationsService.currentMessage.next({
        title: 'IP Range Added',
        message: 'IP range ' + startIp + '-' + endIp + ' (' + ipName + ') added to database'
      });
    }, err => {
      this.hideDialog();
      this.bottomNotificationsService.currentMessage.next({
        title: 'IP Range Addition Failed',
        message: 'IP range ' + startIp + '-' + endIp + ' (' + ipName + ') was unable to be added to database',
        type: 'warning'
      });
    });
  }

  editIpAddressRange(ipId: number, startIp: string, endIp: string, ipName: string) {
    this.adminService.editWhitelistIp(ipId, startIp, endIp, ipName).subscribe(res => {
      this.hideDialog();
      this.bottomNotificationsService.currentMessage.next({
        title: 'IP Range Updated',
        message: 'IP range ' + startIp + '-' + endIp + ' (' + ipName + ') updated in database'
      });
    }, err => {
      this.hideDialog();
      this.bottomNotificationsService.currentMessage.next({
        title: 'IP Range Edit Failed',
        message: 'IP range ' + startIp + '-' + endIp + ' (' + ipName + ') was unable to be updated in database',
        type: 'warning'
      });
    });
  }

  deleteIpAddressRange(ipId: number) {
    let wip = this.ips.find(w => w.whitelistIpsId === ipId);
    this.adminService.removeWhitelistIp(ipId).subscribe(res => {
      this.getWhitelistIps();
      this.bottomNotificationsService.currentMessage.next({
        title: 'IP Range Deleted',
        message: 'IP range ' + wip.startIpAddress + '-' + wip.endIpAddress + ' (' + wip.ipName + ') removed from database'
      });
    }, err => {
      this.getWhitelistIps();
      this.bottomNotificationsService.currentMessage.next({
        title: 'IP Range Removal Failed',
        message: 'IP range ' + wip.startIpAddress + '-' + wip.endIpAddress + ' (' + wip.ipName + ') was unable to be removed from database',
        type: 'warning'
      });
    });
  }
}

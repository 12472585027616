import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatatypeHelper } from '../../helpers/datatype.helper';

@Component({
  selector: 'app-date-display-input',
  templateUrl: './date-display-input.component.html',
  styleUrls: ['./date-display-input.component.scss']
})
export class DateDisplayInputComponent implements OnInit {

  @Input()
  contr: GroupGraphPoint;

  @Input()
  readonly: boolean;

  constructor() { }

  ngOnInit() {
  }

  public dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date.toString(), 'D MMMM YYYY');
  }

}

export class GroupGraphPoint {
    date: Date;
    weight: FormControl;
    adg: FormControl;
    animation: string = null;
    buttonColor = '#35373A';

    clash?: boolean = false;

    constructor() { this.buttonColor = '#35373A'; }
}

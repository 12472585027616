<!--<app-actions-sold-dead-modal [trigger]="trigger" (actionComplete)="soldDeadModalResult($event);"></app-actions-sold-dead-modal>-->

<app-loading *ngIf="!rows"></app-loading>

<ng-container *ngIf="rows">

  <!-- numbers -->
  <div class="card card-summary-header">
    <div class="card-body">
      <div>
        <h2>
          <span *ngIf="filteredSession" [appNumberDisplay]="{number: filteredSession.animalCount}"></span>
          <span *ngIf="filteredSession"> / </span>
          <span  [appNumberDisplay]="{number: sessionSummary.animalCount}"></span>
        </h2>
        <p class="text-shady-lady" i18n="@@animals">Animals</p>
      </div>

      <div>
        <h2 *ngIf="filteredSession" [appNumberDisplay]="{number: filteredSession.averageWeight}"></h2>
        <h2 *ngIf="!filteredSession" [appNumberDisplay]="{number: sessionSummary.averageWeight}"></h2>
        <p class="text-shady-lady" i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
      </div>

      <div>
        <h2 *ngIf="filteredSession" [appNumberDisplay]="{number: filteredSession.minWeight}"></h2>
        <h2 *ngIf="!filteredSession" [appNumberDisplay]="{number: sessionSummary.minWeight}"></h2>
        <p class="text-shady-lady" i18n="abbreviation for Minimum Weight@@minweight">Min Weight</p>
      </div>

      <div>
        <h2 *ngIf="filteredSession" [appNumberDisplay]="{number: filteredSession.maxWeight}"></h2>
        <h2 *ngIf="!filteredSession" [appNumberDisplay]="{number: sessionSummary.maxWeight}"></h2>
        <p class="text-shady-lady" i18n="abbreviation for Maximum Weight@@maxweight">Max Weight</p>
      </div>

      <div>
        <h2>{{dayMonthString(sessionSummary.sessionStartDate)}}</h2>
        <p class="text-shady-lady">{{yearString(sessionSummary.sessionStartDate)}}</p>
      </div>
    </div>
  </div>

  <!-- filters -->
  <div class="filter">
    <app-reports-filters
      [data]="fullDataSet || []" [filterOptions]="filterOptions"
      (globalFilterChanged)="tt.filterGlobal($event.value, 'contains')"
      (globalParameterService.detailsSearchParams)="tt.filter($event.value, 'contains')">
    </app-reports-filters>
  </div>

  <!-- actions, exports, table columns and actual table -->
  <div class="card card-table">

    <div class="card-header card-header-table">
      <div class="animals-header-bar">
        <div>

          <!-- actions -->
          <div ngbDropdown>
            <button class="btn btn-link" ngbDropdownToggle *ngIf="headers && permissionsService.permissions.canEditData" i18n="@@updateStatus">Update Status</button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="markAsCurrent(filteredAnimals)" i18n="@@markAsCurrent">Mark as Current</button>
              <button ngbDropdownItem (click)="markAsSold(filteredAnimals)">{{localeService.constants.stringMarkAsSold}}</button>
              <button ngbDropdownItem (click)="markAsDied(filteredAnimals)">{{localeService.constants.stringMarkAsDied}}</button>
            </div>
            <!-- assignment -->
            <button class="btn btn-link" (click)="assignOrReassignAnimals()" *ngIf="headers && permissionsService.permissions.canEditData" i18n="@@changeGroup">Change Group</button>
          </div>

        </div>
        <div class="d-flex justify-content-end">

          <!-- export -->
          <div ngbDropdown>
            <button *ngIf="headers" class="btn btn-link" ngbDropdownToggle i18n="@@export">Export</button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="reportsService.downloadCsv(curHeaders, filteredAnimals, getDisplayName())">{{localeService.constants.stringSpreadsheetBracketsCsv}}</button>
              <button ngbDropdownItem (click)="reportsService.exportPDF(curHeaders, filteredAnimals, getDisplayName())">PDF</button>
            </div>
          </div>

          <!-- columns -->
          <app-reports-column-picker
            *ngIf="headers"
            [curHeaders]="curHeaders"
            [headers]="headers"
            (headersChanged)="columnsReordered($event, true)">
          </app-reports-column-picker>

        </div>
      </div>
    </div>

    <p-table class="no-overflow"
             #tt
             [columns]="curHeaders"
             [value]="rows"
             (onColReorder)="columnsReordered($event.columns)"
             [reorderableColumns]="true"
             (sortFunction)="reportsService.sortData($event, rows); sortEvent($event); scrollToTop();"
             [sortField]="sortField"
             [sortOrder]="sortOrder"
             [customSort]="true"
             (onPage)="scrollToTop()"
             [autoLayout]="true"
             [rows]="50"
             [paginator]="true"
             [pageLinks]="11"
             [rowsPerPageOptions]="[20,50,100]"
             (onFilter)="filtered($event)"
             (window:resize)="reportsService.onResize();"
             tableStyleClass="table table-hover">

      <!-- header -->
      <ng-template pTemplate="header" let-columns>
        <tr class="ui-widget-content">
          <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="ui-state-default">
            <span [innerHTML]="col.header"></span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <!-- body -->
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr class="ui-widget-content">
          <td *ngFor="let col of columns">
            <div *ngIf="col.isId && col.isId === true" class="ui-cell-data">
              <span [title]="row[col.field]">
                <a href="javascript:void(0)" (click)="openAnimalInModal(row)">{{ row[col.field]}}</a>
                <!-- <a routerLink="/main/animals/details" [queryParams]="{id:row['animalId']}">{{row[col.field]}}</a>-->
              </span>
            </div>
            <div *ngIf="!col.isId">
                            <span [title]="row[col.field]">
                                {{row[col.field]}}
                            </span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-floating-scroll-bar *ngIf="!isMobile"></app-floating-scroll-bar>

  </div>

</ng-container>

import {Component, OnInit} from '@angular/core';
import {Validators, FormGroup, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

import {LocaleService} from '../../helpers/locale.service';
import {AuthService} from '../auth.service';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import {FormValidation} from '../../helpers/form-validation.helper';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  errorMessage = '';
  loading: boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    private title: Title,
    private localeService: LocaleService,
    private bottomNotificationsService: BottomNotificationsService
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle(`${this.localeService.constants.stringForgotPassword} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.createRegistrationForm();
  }

  createRegistrationForm() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([FormValidation.validateEmail, Validators.required]))
    });

    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  formChanged() {
    this.errorMessage = '';
  }

  emailLink(event) {

    // mark form touched and dirty
    this.form.markAllAsTouched();
    Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsDirty());

    if (this.form.valid) {
      this.loading = true;
      this.authService.emailResetPassword(this.form.get('email').value).subscribe(res => {
          this.loading = false;
          this.router.navigate(['/forgotPasswordConfirmation']);
        }, error => {
          this.loading = false;
          this.errorMessage = error.message;

        this.bottomNotificationsService.currentMessage.next({
          title: error.message,
          message: '',
          type: 'failure'
        });

        });
    } else {
      if (this.form.get('email').invalid) {
        this.errorMessage = 'A valid email is required.';
      }
    }
    event.preventDefault();
  }
}

<!--<app-actions-sold-dead-modal [trigger]="trigger" (actionComplete)="soldDeadModalResult($event)"></app-actions-sold-dead-modal>-->

<div *ngIf="action" class="row flex-align-items-center action-box" [class.fadeIn]="action.fadeIn" #summary [style.opacity]="action.greyOut ? 0.7 : 1">

<div *ngIf="action.greyOut" [class.grey-out]="action.greyOut"></div>

  <!-- icon -->
  <div class="col-md-1">
    <div class="action-icon" [ngClass]="action.groupId ? 'bg-success' : 'bg-warning'">
      <i class="fal" [class.fa-check]="action.groupId" [class.fa-bell]="!action.groupId" aria-hidden="true"></i>
    </div>
  </div>

  <div class="col-md-11">

    <!-- assign to new group -->
    <div class="row flex-align-items-center" *ngIf="!action.groupId || (action.groupId && !action.confirmationRequiredGroup)">

      <div class="col-md-9">
        <span *ngIf="action.ided" class="mr-3">{{action.animalCount}}&nbsp;<ng-container *ngIf="action.animalCount !== 1" i18n="@@newanimalsneedassigningtoagroup">new&nbsp;animals need assigning to a group</ng-container>
          <ng-container *ngIf="action.animalCount === 1" i18n="@@newanimalneedsassigningtoagroup">new&nbsp;animal needs assigning to a group</ng-container>
        </span>
        <span *ngIf="!action.ided" class="mr-3">{{action.animalCount}}&nbsp;<ng-container *ngIf="action.animalCount !== 1" i18n="@@weightswithoutIDsneedassigningtoagroup">weights without IDs need assigning to a group</ng-container>
          <ng-container *ngIf="action.animalCount === 1" i18n="@@weightwithoutanIDneedsassigningtoagroup">weight without an ID needs assigning to a group</ng-container>
        </span>
      </div>

      <div class="col-md-3 text-right" *ngIf="permissionsService.permissions.canEditData">
        <div class="btn-group">
          <button type="button" class="btn btn-primary" (click)="openAssignmentModal(action)" i18n="@@assign">Assign</button>
          <div class="btn-group" ngbDropdown role="group" placement="bottom-right" *ngIf="action.ided">
            <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem *ngFor="let item of splitButtonSoldDeadItems" (click)="item.command()">
                {{item.label}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- already assigned -->
    <div class="row flex-align-items-center" *ngIf="action.groupId && !!action.confirmationRequiredGroup && !action.recomendedGroup">

      <div class="col-md-9">
        <div *ngIf="action.animalCount === 1 && !action.ided">1&nbsp;<ng-container i18n="specify the group the weight has been assigned to@@weightwithoutidassignedtogroup">weight without ID assigned
          to group
        </ng-container>
          '<b>{{action.confirmationRequiredGroup.displayName}}</b>'
        </div>
        <div *ngIf="action.animalCount !== 1 && !action.ided">{{action.animalCount}}&nbsp;<ng-container i18n="specify the group the weights have been assigned to@@weightswithoutidsassignedtogroup">
          weights without IDs assigned to group
        </ng-container>
          '<b>{{action.confirmationRequiredGroup.displayName}}</b>'
        </div>
        <div *ngIf="action.animalCount === 1 && action.ided">1&nbsp;<ng-container i18n="specify the group the animal has been assigned to@@animalassignedtogroup">animal assigned to group
        </ng-container>
          '<b>{{action.confirmationRequiredGroup.displayName}}</b>'
        </div>
        <div *ngIf="action.animalCount !== 1 && action.ided">{{action.animalCount}}&nbsp;<ng-container i18n="specify the group the animals have been assigned to@@animalsassignedtogroup">animals
          assigned to group
        </ng-container>
          '<b>{{action.confirmationRequiredGroup.displayName}}</b>'
        </div>
      </div>

      <div class="col-md-3 text-right" *ngIf="permissionsService.permissions.canEditData">
        <div class="btn-group">
          <button class="btn btn-primary" (click)="openAssignmentModal(action)" i18n="@@change">Change</button>
          <div class="btn-group" ngbDropdown role="group" placement="bottom-right" *ngIf="action.ided">
            <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem *ngFor="let item of splitButtonSoldDeadItems" (click)="item.command()">
                {{item.label}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

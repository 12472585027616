<div class="card card-widget">

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0" i18n="abbreviation of Animal Information@@animalinfo">Animal Info</h3>
  </div>

  <!-- widget body/content -->
  <div class="card-body">

    <div *ngIf="!animal.customAnimalIdentifiers || animal.customAnimalIdentifiers.length === 0">
      <h3>
        <ng-container i18n="@@noinformationforthisanimal">No Information for this animal</ng-container>.
      </h3>
    </div>

    <table *ngIf="animal.customAnimalIdentifiers" class="details-table">
      <tr *ngFor="let id of animal.customAnimalIdentifiers" class="pb10">
        <td>{{id.name}}</td>
        <td>{{id.rawValue !== null ? id.rawValue : id.value}}</td>
      </tr>
    </table>

    <div class="clearfix pb5"></div>

    <div class="section-divider mt-1 mb20">
      <span><h3 class="m-0" i18n="@@lifedata">Life Data</h3></span>
    </div>

    <div *ngIf="!udfNames || udfNames.length === 0">
      <h3>
        <ng-container i18n="@@noinformationforthisanimal">No Information for this animal</ng-container>.
      </h3>
    </div>

    <table *ngIf="udfNames && udfNames.length > 0" class="details-table">
      <tr *ngFor="let udfName of udfNames; let i = index;">
        <td>{{udfName}}</td>
        <td>{{udfData[i]}}</td>
      </tr>
    </table>

    <div class="clearfix pb5"></div>

    <div class="section-divider mt-1 mb20">
      <span><h3 class="m-0" i18n="@@groups">Groups</h3></span>
    </div>

    <!-- widget loading -->
    <app-loading *ngIf="!groups" [local]="true"></app-loading>

    <div *ngIf="groups && groups.length === 0">
      <h3 i18n="@@notassignedtoanygroup">Not assigned to any Group.</h3>
    </div>

    <ol class="timeline-list">

      <li class="timeline-item d-flex" *ngFor="let group of groups">
        <div class="timeline-icon bg-success">
          <span class="ci ci-groups-custom-white" aria-hidden="true"></span>
        </div>
        <div class="timeline-desc">
          <b><a routerLink="/main/groups/summary" [queryParams]="{id: group.groupId}">{{group.groupName}}</a></b>
        </div>
        <div class="timeline-date hidden-xs hidden-sm mr20">
          {{yearString(group.targetWeightPlan.startDate)}}
        </div>
      </li>

    </ol>

  </div>
</div>

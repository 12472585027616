import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {Group} from '../../models/group.model';
import {SessionGroupSummary} from '../../models/session-group-summary.model';
import {GroupsService} from '../../services/groups.service';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';

@Component({
  selector: 'app-action-group-selection',
  templateUrl: './action-group-selection.component.html',
  styleUrls: ['./action-group-selection.component.scss']
})
export class ActionGroupSelectionComponent implements OnInit {
  @Input() group: Group;
  @Input() session: SessionGroupSummary;

  @Output() assignSession = new EventEmitter<SessionGroupSummary>();
  @Output() sessionUpdated = new EventEmitter<SessionGroupSummary>();
  @Output() groupChosen = new EventEmitter<Group>();

  window = window;
  visible = false;
  skipNewGroupStage = false;
  currentGroupId: number = null;  // if null no selected. if -1 then new groups selected.
  showError = false;

  private opened = false;

  constructor(public groupsService: GroupsService,
              public ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal,
              private createNewGroupService: CreateNewGroupService) {
  }

  ngOnInit() {
    this.currentGroupId = this.group ? this.group.groupId : null;
  }

  assignSessionToGroup() {
    if (this.currentGroupId >= 0) {
      if (this.session) {
        this.session.activeGroup = this.groupsService.groups.value.find(g => g.groupId === this.currentGroupId);
        // this.actionsService.assignGroup(this.session); TODO2
      }
      this.groupChosen.emit(this.groupsService.groups.value.find(g => g.groupId === this.currentGroupId));

      this.session = null;
      this.visible = false;
      this.skipNewGroupStage = false;
    }
  }

  doneClicked() {
    this.skipNewGroupStage = false;

    if (this.currentGroupId !== null) {

      if (this.currentGroupId >= 0) {

        // existing group chosen
        this.assignSessionToGroup()
      } else {

        // create new group
        const modalRef: NgbModalRef = this.createNewGroupService.createGroup();

        modalRef.result.then((group: Group) => {
          this.newGroupCreated(group);
        });
      }
    } else {
      this.showError = true;
    }
  }

  newGroupCreated(group: Group) {
    this.groupChosen.emit(group);
    this.session = null;
    this.currentGroupId = null;
    this.visible = false;
  }
}

import { SessionWeightSummary } from './session-weight-summary';
import { Group } from './group.model';
import { SessionGroupAutomation } from './session-group-automation.model';
import { SessionAction } from './session-action';

export class SessionGroupSummary extends SessionWeightSummary {

    activeGroup?: Group;
    sessionGroupAutomation?: SessionGroupAutomation;
    suggestNewGroup?: boolean;

    hidden = false;
    actions: Array<SessionAction> = [];
}

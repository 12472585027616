<section id="main" class="external-page-content">

  <div class="form-holder">

    <div class="card card-external">

      <div class="card-body text-center">

        <!-- logo -->
        <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

        <!-- content -->
        <div class="mt-2 pb-5">

          <h2>Page not found</h2>

          <div class="py-3">
            <i class="fal fa-scarecrow not-found-icon"></i>
          </div>

          <div class="py-3">
            The page that you are trying to access cannot be found. Click <a href="javascript:void(0)" (click)="location.back()"><strong>here</strong></a> to go back to your previous page
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

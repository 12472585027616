import {Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';

import { environment } from '../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  imgLocations = environment.imgLocations;
  constructor(public location: Location) { }

  ngOnInit() {
    $('body').addClass('external-page');
  }

  ngOnDestroy() {
    $('body').removeClass();
  }
}

import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { GroupWeightRecordSplit } from '../../../../models/group-weight-record-split.model';
import { CreateNewGroupService } from '../../../../create-new-group/create-new-group.service';
import { Group } from '../../../../models/group.model';
import { ActionsSoldDeadService } from '../../actions-sold-dead.service';

@Component({
  selector: 'app-actions-sold-dead-model-spliter-strings',
  templateUrl: './actions-sold-dead-model-spliter-strings.component.html',
  styleUrls: ['./actions-sold-dead-model-spliter-strings.component.scss']
})
export class ActionsSoldDeadModelSpliterStringsComponent implements OnInit, OnChanges {
  static self: ActionsSoldDeadModelSpliterStringsComponent;
  public selectList: Array<any> = [];
  @Input()
  public headerName: string;
  public selectedItems: any = {};
  private selectedIds: Array<number>;

  weightRecordSelection: { [key: number]: GroupWeightRecordSplit } = {}

  constructor(public actionsSoldDeadService: ActionsSoldDeadService, private createNewGroupService: CreateNewGroupService) {
    ActionsSoldDeadModelSpliterStringsComponent.self = this;
  }

  selectionChanged() {
    this.selectedIds = [];
    for (let index in this.selectedItems) {// index is key: number (actual index) and value: if checkbox is checked
      if (this.selectedItems[index]) {
        this.selectedIds = this.selectedIds.concat(this.selectList[index].weightRecordIds);
      }
    }
  }

  ngOnInit() {
    this.actionsSoldDeadService.getWeightRecordIds = this.getWeightRecordIds;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headerName && changes['headerName'] && changes['headerName'].currentValue) {
      this.selectList = [];
      this.actionsSoldDeadService.animalData.forEach(ad => {
        let found = this.selectList.find(a => a.value === ad[this.headerName]);
        if (!found) {
          this.selectList.push({ value: ad[this.headerName], count: 1, weight: ad.weight || 0, weightRecordIds: [ad.weightRecordId] });
        } else {
          found.count++;
          found.weight += (ad.weight || 0);
          found.weightRecordIds.push(ad.weightRecordId);
        }
      });
      this.selectList.forEach(sl => {
        sl.weight = Math.round(sl.weight / sl.count);
      });
    }
  }


  getWeightRecordIds(): Array<number> {
    return ActionsSoldDeadModelSpliterStringsComponent.self.selectedIds;
  }
}

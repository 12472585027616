<!-- widgets -->
<div class="row">

  <div class="col-lg-6">
    <app-group-summary-weight-gain [group]="group" class="d-block mb-4"></app-group-summary-weight-gain>
  </div>

  <div class="col-lg-6">
    <app-groups-group-by-weight [group]="group" [weightRecords]="weightRecords" class="d-block mb-4" (changeTab)="changeTab.next($event)"></app-groups-group-by-weight>
  </div>

  <div class="col-lg-6">
    <app-groups-adg [group]="group" class="d-block mb-4"></app-groups-adg>
  </div>

  <div class="col-lg-6">
    <app-groups-group-by-adg [group]="group" [weightRecordsAdg]="weightRecordsAdg" class="d-block mb-4" (changeTab)="changeTab.next($event)"></app-groups-group-by-adg>
  </div>

</div>

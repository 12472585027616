import { Group, GroupCensusWeightSummary } from '../../../models/group.model';
import { Rounder } from '../../../../helpers/rounder.helper';
import { WeightPlanPoint } from '../../../models/weight-plan.model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocaleService } from '../../../../helpers/locale.service';
import * as moment from 'moment';
import { EArray } from '../../../../helpers/constants.helper';
import {PermissionsService} from '../../../../auth/permissions.service';
import {IntegrationService} from '../../../api/integration.service';

declare var Highcharts: any;


const groupBy_ex = <T, K extends keyof any>(list: T[], getKey: (item: T) => K, key_name:string='timeStamp') =>
  list.reduce((accumulator, currentItem) => {
    const group = getKey(currentItem);
    const existingKey = accumulator.find(x=>x[key_name]===group);

    if (!existingKey) {
        let keyValue = {list:[currentItem]};
        keyValue[key_name]=group;
        accumulator.push(keyValue)
        //accumulator.push({...keyValue, list:[currentItem]})
    }else{
        existingKey.list.push(currentItem)
    }

    return accumulator;
  }, []);

@Component({
    selector: 'app-groups-adg',
    templateUrl: './groups-adg.component.html',
    styleUrls: ['./groups-adg.component.scss']
})
export class GroupsAdgComponent implements OnInit, OnChanges {
    @Input()
    group: Group;

    options: any;
    chartName: string;

    isPplFarmWithIntegration = false;

    constructor(private localeService: LocaleService, private permissionsService: PermissionsService, private integrationService: IntegrationService) { }

    ngOnInit() {
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes['group'] && changes['group'].currentValue) {
            this.redrawGraph();
        }
    }

    redrawGraph() {
        if (this.group === null || this.group.targetWeightPlan === null) {
            this.options = {
                series: []
            };
            return;
        }
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }

        this.chartName = `${this.group.displayName} - ` + this.localeService.constants.stringADGGraph;

        let twp = <Array<WeightPlanPoint>>JSON.parse(this.group.targetWeightPlan.weightPlanPointsJson),
            adgTwp = [], prevTwp: WeightPlanPoint = null;
        twp.forEach(point => {
            if (prevTwp !== null && point != null) {
                let days = moment.utc(point.date).diff(moment.utc(prevTwp.date), "days")
                if (days > 0) {
                    let startDate = moment.utc(prevTwp.date),
                        weight = Math.round(((point.weight - prevTwp.weight) / days) * 1000) / 1000;
                    for (let i = 0; i <= days; i++) {
                        startDate.hours(0).minutes(0).seconds(0)
                        adgTwp.push({
                            x: startDate.valueOf(), 
                            y: weight,
                            noTooltip: i === days,
                            marker: {
                                enabled: i === days
                            }
                        });
                        startDate.add(1,'d')
                    }
                }
            }
            prevTwp = point;
        });

        let adgAvg = [], adgOld = [], prev: GroupCensusWeightSummary = null, censuses = this.group.censuses.slice();
        censuses.sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime());
        if (censuses.length > 0) {
            const censusCalc = censuses[censuses.length - 1] !== null
                                    && censuses[censuses.length - 1] !== undefined
                                    && censuses[censuses.length - 1].animalWithIdCount < 50;
            for (let i = censuses.length - 1; i > 0; i--) { // take out empty adgs to draw straight line
                if ((censusCalc && censuses[i].censusAdg === null) || (!censusCalc && censuses[i].animalAdg === null)) {
                    censuses.splice(i, 1);
                }
            }

            const grpdCensuses= groupBy_ex(censuses, x=>x.timeStamp.getTime(), 'timeStamp')

            grpdCensuses.forEach(points => {
                let point = new GroupCensusWeightSummary();
                point.timeStamp = moment.utc(points.timeStamp).toDate();
                if (points.list && points.list.length > 0) {
                    points.list.forEach((item: GroupCensusWeightSummary) => {
                        point.averageWeight += item.averageWeight * item.animalCount;
                        point.censusAdg += item.censusAdg * item.animalCount;
                        point.animalAdg += item.animalAdg * item.animalCount;
                        point.animalCount += item.animalCount;
                    });
                    if (point.animalCount) {
                        point.averageWeight = point.averageWeight / point.animalCount;
                        point.censusAdg = point.censusAdg / point.animalCount;
                        point.animalAdg = point.animalAdg / point.animalCount;
                    }
                    if (prev !== null) {
                       
                        let days = moment(point.timeStamp).diff(moment(prev.timeStamp), "days")

                        if (days > 0 && point.averageWeight > 0 && prev.averageWeight > 0) {
                            let startDate = moment.utc(prev.timeStamp)
                            for (let i = 0; i <= days; i++) {
                                startDate.hours(0).minutes(0).seconds(0)
                                adgAvg.push({
                                    x: startDate.valueOf(),
                                    y: censusCalc ? point.censusAdg : point.animalAdg,
                                    noTooltip: i === days,
                                    marker: {
                                        enabled: i === days 
                                    },
                                    extra: [{
                                        order: 99,
                                        html: `<span style="color:black;">\u25CF</span> ${this.localeService.constants.stringAnimalCount}: ${point.animalCount}`
                                    }]
                                });
                                startDate.add(1,'d')
                            }
                        }
                    }
                }
                prev = point;
            });
        }
        adgTwp = adgTwp.sort((a, b) => a.x - b.x); // This fixes HC error 15
        let xAxis: any = {
            type: 'datetime',
            tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
            dateTimeLabelFormats: {
                month: '%b %Y'
            },
            labels: {
                style: {
                    fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                    fontSize: '12px'
                }
            },
        },

            series = [{
                name: this.localeService.constants.stringAverage,
                allowPointSelect: true,
                data: adgAvg,
                zIndex: 1,
                opacity: 0.3,
                type: 'line',
                marker: { enabled: true }
            },
            {
                name: this.localeService.constants.stringTarget,
                allowPointSelect: true,
                data: adgTwp,
                zIndex: 2,
                opacity: 0.4,
                type: 'line'
            },
            {
                name: 'OLD',
                allowPointSelect: true,
                data: adgOld,
                zIndex: 3,
                opacity: 0.4,
                type: 'line'
            }];

        let cName = this.chartName;
        this.options = {
            title: {
                text: null
            },

            subtitle: {
                text: null
            },
            xAxis: xAxis,
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                        fontSize: '12px'
                    }
                },
                lineWidth: 1
            },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                text: this.localeService.constants.stringPrintChart,
                                onclick: function () {
                                    this.print();
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPDF,
                                onclick: function () {
                                    this.exportChart({
                                        type: 'application/pdf',
                                        filename: cName
                                    });
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPNG,
                                onclick: function () {
                                    this.exportChart({
                                        filename: cName
                                    });
                                }
                            }
                        ]
                    }
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0
                },
                series: {
                    animation: true,
                    states: {
                        hover: false
                    }
                }
            },
            series: series
        };
    }
    
}

import { Farm, SessionImportAudits } from '../../models';
import { MainService } from '../../main.service';
import { AuthService } from '../../../auth/auth.service';
import { SessionImportAuditsService } from '../../api/session-import-audits.service';
import { Component, OnInit } from '@angular/core';
import {animate, transition, state, style, trigger} from '@angular/animations';

@Component({
  selector: 'app-admin-file-import-report',
  templateUrl: './admin-file-import-report.component.html',
  styleUrls: ['./admin-file-import-report.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AdminFileImportReportComponent implements OnInit {
  public imports: Array<SessionImportAudits>;
  public allImports: Array<SessionImportAudits>;
  public startDate = new Date();
  public endDate = new Date();
  public farms: Array<Farm>;
  public curFarm: Farm;
  public isIgnoreDuplicateUpload = false;
  public currentFarmOnly = true;
  public ignoreSuccessfulFiles = true;
  // public data;
  public cols = [
    { field: 'farmName', header: 'Farm Name' },
    { field: 'sessionFile.fileName', header: 'File Name' },
    { field: 'userName', header: 'User Name' },
    { field: 'created', header: 'Date' }
  ];

  constructor(private authService: AuthService, private mainService: MainService, private sessionImportAuditsService: SessionImportAuditsService) { }

  ngOnInit() {
    this.startDate.setDate(this.startDate.getDate() - 1);

    this.mainService.farmsChanged.subscribe(farms => {
      this.farms = farms;
      this.getImportsForDates()
    });
  }

  startDateChanged(sd: Date) {
    this.startDate = sd;
    this.endDate.setDate(this.startDate.getDate() + 7);
    this.getImportsForDates();
  }

  getImportsForDates() {
    if (this.farms) {
      this.sessionImportAuditsService.getFileImportInformation(this.startDate, this.endDate, this.ignoreSuccessfulFiles
        , (this.currentFarmOnly ? this.mainService.farmChanged.value.farmId : null))
        .subscribe(imports => {
          this.allImports = [];
          imports.forEach(imp => {
            imp['farmName'] = this.farms.find(f => f.farmId === imp.farm_FarmId).farmName;
            try {
              imp['errors'] = JSON.parse(imp.error);
            } catch (ex) {
              imp['errors'] = [{ ErrorResult: 'File failed to upload', Description: imp.error }];
            }
            this.allImports.push(imp);
          });
          this.filterByFarm(this.curFarm);
        });
    } else {
      this.imports = null;
      this.allImports = null;
      this.curFarm = null;
    }
  }

  downloadSession(importId: number, rawData: boolean) {
    this.sessionImportAuditsService.getImportedFile(importId, rawData).subscribe(file => {
      const imp = this.imports.find(i => i.sessionImportAuditId === importId);
      if (imp) {
        let dlName = imp.sessionFile && imp.sessionFile.fileName // files may not always have a fileName in DB
            ? imp.sessionFile.fileName + (imp.sessionFile.fileName.endsWith('.csv') ? '' : '.csv')
            : imp.sessionFile.farmName + '(' + importId + ').csv';
        const blob = new Blob([file], { type: 'text/csv' }),
          link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = dlName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
      }
    });
  }

  filterByFarm(farm: Farm = null) {
    this.curFarm = farm;
    if (farm) {
      this.imports = this.allImports.filter(i => i.farm_FarmId === farm.farmId).slice();
    } else {
      this.imports = this.allImports.slice();
    }
  }

  ignoreDuplicateUpload() {
    if (this.isIgnoreDuplicateUpload) {
      this.imports = this.allImports.filter(i => i['errors'].findIndex(e => e['ErrorType'] === 18) === -1).slice();
    } else {
      this.imports = this.allImports.slice();
    }
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return new Date();
    }
  }
}

import { AuthService } from '../../auth/auth.service';





import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Sex } from '../models/sex.model';
import { LocaleService } from '../../helpers/locale.service';
import { map, catchError } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SexService {
    private cachedSexes: Array<Sex> = [];

    constructor(private authService: AuthService, private apiService: ApiService, private localeService: LocaleService) { }

    public getSexes = (): Observable<Array<Sex>> => {
        if (this.cachedSexes.length > 0) {
            return of(this.cachedSexes);
        }

        return combineLatest(this.localeService.getBreedsSexAndSpeciesTranslation(),
            this.apiService.get(`/odata/Sexes`).pipe(map((res: any) => {
                this.cachedSexes = res.value;
                return this.cachedSexes;
            }))).pipe(map(res => {
                if (res[0] && res[1]) {
                    this.localeService.translateBreedSexArray(res[1], 'sexName', res[0]['sexes']);
                    return res[1];
                }
            }),
                catchError(this.apiService.handleError));
    }
}



import {Component, OnInit, EventEmitter, Output, OnDestroy, Input} from '@angular/core';

import {CreateNewGroupService} from '../../../create-new-group/create-new-group.service';
import {Group} from '../../../models/group.model';
import {ActionsModalService} from '../actions-modal.service';
import {ActionsService} from '../../actions.service';
import {GroupsService} from '../../../services/groups.service';
import {Helper} from '../../../../helpers/instantiate.helper';
import {SessionAction} from '../../../models/session-action';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GlobalLoaderService} from '../../../../core/global-loader.service';

@Component({
  selector: 'app-actions-modal-group-selection',
  templateUrl: './actions-modal-group-selection.component.html',
  styleUrls: ['./actions-modal-group-selection.component.scss']
})
export class ActionsModalGroupSelectionComponent implements OnInit, OnDestroy {
  @Input() singleAnimal = false;

  @Output() newGroupSelected = new EventEmitter<Group>();
  @Output() closeModal = new EventEmitter<Group>();

  activeGroups: Array<Group> = [];
  completedGroups: Array<Group> = [];
  showCompletedGroups = false;

  constructor(public groupsService: GroupsService,
              public actionsModalService: ActionsModalService,
              private actionService: ActionsService,
              private createNewGroupService: CreateNewGroupService,
              private globalLoaderService: GlobalLoaderService,
              private ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.activeGroups = this.groupsService.groups.value.filter(g => g.isCurrent);
    this.completedGroups = this.groupsService.groups.value.filter(g => !g.isCurrent);
  }

  ngOnDestroy() {
    this.actionsModalService.selectedGroupId = null;
  }

  newGroup() {
    this.actionsModalService.selectedGroupId = null;
    this.actionsModalService.visible = false;
    this.actionsModalService.action.greyOut = true; // grey out action for created group.
    this.createNewGroupService.createGroup(this.actionsModalService.action, true, null, (group: Group) => {
      if (group) {
        // this.save(group);
        this.globalLoaderService.showGlobalLoader = true;
        this.actionsModalService.selectedGroupId = group.groupId;
        this.newGroupSelected.emit(group);
      } else {
        this.actionsModalService.action.greyOut = false; // if failed to create a group then ungrey out.
        this.actionsModalService.visible = true;
      }
    }).result.then(() => {
      this.activeGroups = this.groupsService.groups.value.filter(g => g.isCurrent);
      this.completedGroups = this.groupsService.groups.value.filter(g => !g.isCurrent);
    }, dismissReason => {

      // in case user just close the new group modal, without saving the new group
      this.actionsModalService.visible = true;
      this.actionsModalService.action.greyOut = false;
    });
  }

  save(group: Group) {
    let newAction = Helper.instantiate(new SessionAction(), Object.assign({}, this.actionsModalService.action));
    if (this.actionsModalService.action.groupId && !this.actionsModalService.movePriorWeights) {
      // handling setting this value if the user doesn't change it from the default
      // don't want to set a default value until they hit save though
      this.actionsModalService.previousWeightsChanged(false);
    }
    newAction.groupId = group.groupId;
    newAction.recommendedGroupId = this.actionsModalService.action.recomendedGroup ? this.actionsModalService.action.recomendedGroup.groupId : null;
    if (newAction.recommendedGroupId !== null) {
      newAction.reassignGroup = true;
    }
    (
      (this.actionsModalService.action.groupId === null
        ? this.actionService.assignAction(newAction, this.actionsModalService.movePriorWeights)
        : this.actionService.reAssignAction(newAction, this.actionsModalService.movePriorWeights))
    ).subscribe(() => {
      this.actionService.updateRecommendation();
      this.actionsModalService.action = null;
      this.actionsModalService.visible = false;
      this.closeModal.emit(group);
    }, err => {
      this.actionsModalService.action.greyOut = false;
    });
  }
}

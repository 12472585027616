import {Component, OnInit} from '@angular/core';

import {AdminService} from '../../api/admin.service';
import {ConsolidatedAnimalMovementRequest} from '../../models/animal-movement.model';

@Component({
  selector: 'app-admin-traceability',
  templateUrl: './admin-traceability.component.html',
  styleUrls: ['./admin-traceability.component.scss'],
  providers: [AdminService]
})
export class AdminTraceabilityComponent implements OnInit {
  public transfers: Array<ConsolidatedAnimalMovementRequest>;
  public startDate = new Date();
  public endDate = new Date();
  public ignoreSuccessfulTransfers = true;
  public showLoadMoreButton = true;

  public currentPage = 1;

  constructor(private adminService: AdminService) {
  }

  ngOnInit() {
    this.startDate.setDate(this.startDate.getDate() - 10);
    this.getTransfersForDates();
  }

  getNextBatch() {
    this.currentPage++;
    this.getTransfersForDates();
  }

  getTransfersForDates() {
    if (this.currentPage === 1) {
      this.transfers = null;
    }
    this.adminService.getTransfers(this.currentPage, this.startDate, this.endDate, this.ignoreSuccessfulTransfers).subscribe(trans => {
      this.showLoadMoreButton = trans.length === 50; // magic number to match the constant used on server-side for volume returned each search
      if (this.transfers === null) {
        this.transfers = [];
      }
      this.transfers = [...this.transfers, ...trans]; // combine the new data into the existing data
    });
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return new Date();
    }
  }
}

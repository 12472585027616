import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntegrationService } from '../../../api/integration.service';
import { MainService } from '../../../main.service';
import { Subscription } from 'rxjs';
import { IntegrationImage } from '../../../models/integrationImage';
import { SelectItem } from 'primeng/components/common/selectitem';
import { Integration } from '../../../models/integration';
import { ConvertToUTC } from '../../../../helpers/datatype.helper';

// declare let moment: any;
declare let $: any;
declare let moment: any;

@Component({
  selector: 'app-camera-widget',
  templateUrl: './camera-widget.component.html',
  styleUrls: ['./camera-widget.component.scss']
})
export class CameraWidgetComponent implements OnInit, OnDestroy {
  private sub: Subscription
  public imagesForPaddocks: Array<ImagesForPaddocks> = [];
  public images: Array<any>;
  public paddocks: Array<SelectItem> = [];
  public curPaddockId: number;
  public selectedDate: Date;
  public text = null;
  private integration: Integration;
  constructor(private integrationService: IntegrationService, private mainService: MainService) { }


  ngOnInit() {
    this.sub = this.integrationService.integration.subscribe(integration => {
      this.images = null;
      this.imagesForPaddocks = [];

      if (integration) {
        this.paddocks = [];
        this.integration = integration;
        integration.integratedDevices.forEach(id => {
          if (id.isActive) {
            this.paddocks.push({ label: id.integratedDeviceName, value: id.integratedDeviceId });
          }
        });
        this.curPaddockId = integration.integratedDevices.filter(id => id.isActive).length > 0
                            ? integration.integratedDevices.filter(id => id.isActive)[0].integratedDeviceId
                            : null;
        if (this.integrationService.gettingImagesSub) { // incase this a new integration and we are still waiting for initial batch of images.
          this.integrationService.gettingImagesSub.add(res => {
            this.getImages();
          })
        } else { // existing integration
          this.getImages();
        }
      }
    });
  }

  private getImages() {
    let paddockImages = this.imagesForPaddocks.find(i => i.paddockId === this.curPaddockId);
    if (paddockImages) {
      // set current
    } else {
      this.integrationService.getIntegrationImages(this.integration.farm_FarmId, this.curPaddockId).subscribe(imgs => {
        let imagesInPaddock = {
          paddockId: this.curPaddockId, images: imgs.map(img => {
            return {
              source: 'data:image/bmp;base64,' + img.imageData, title: ConvertToUTC(img.timeStamp).format('D MMM YYYY  h:mm a')
              , paddockId: img.integratedDevices_IntegratedDeviceId
            };
          })
        };
        this.imagesForPaddocks.push(imagesInPaddock);
        this.filterByPaddock(imagesInPaddock);
      });
    }
  }

  filterByPaddock(imagesInPaddock = null) {
    this.images = null; // forcing gallerie module to be reloaded.
    setTimeout(() => {
      $('.ui-galleria').off('click'); // just to maker sure event is removed. should avoid any memory leaks.
      let img = imagesInPaddock ? imagesInPaddock : this.imagesForPaddocks.find(i => i.paddockId === this.curPaddockId)
      this.images = img ? img.images.slice() : null;
      if (this.images) {
        setTimeout(() => { // jQuery hack to wait for angular to render images.
          this.setTopDate();
          $('.ui-galleria').on('click', a => {
            this.setTopDate();
          });
        });
      } else {
        this.selectedDate = null;
        this.getImages();
      }
    });
  }

  setTopDate() {
    const el = $('.ui-galleria-panel:not(.ui-helper-hidden)>.ui-panel-images').first();
    if (el && el.attr('title')) {
      $('.ui-galleria-panel-wrapper').first().width(el.width() || 450); // jquery hack to get film strip to size correctly to image width
      this.selectedDate = el.attr('title');
    }
  }

  ngOnDestroy() {
    $('.ui-galleria').off('click');
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

export class ImagesForPaddocks {
  paddockId: number;
  images: Array<any> = [];
}

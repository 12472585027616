import { WeightPlan, WeightPlanPoint } from '../../models/weight-plan.model';
import { Rounder } from '../../../helpers/rounder.helper';
import { LocaleService } from '../../../helpers/locale.service';

export class GroupDetailsWeightGain {
    static sparkLine = {
        chart: {
            backgroundColor: null,
            borderWidth: 0,
            type: 'line',
            margin: [2, 0, 2, 0],
            style: {
                overflow: 'visible'
            },

            // small optimalization, saves 1-2 ms each sparkline
            skipClone: true
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        xAxis: null,
        yAxis: {
            endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            tickPositions: [0]
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                animation: false,
                lineWidth: 2,
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                marker: {
                    radius: 1,
                    states: {
                        hover: {
                            radius: 2
                        }
                    }
                },
                fillOpacity: 0.4
            },
            column: {
                negativeColor: '#910000',
                borderColor: 'silver'
            }
        },
        series: null
    };

    static curTwp = null;
    static twpSeries = null;

    static getOptionsSparkline(twp: WeightPlan, xAxis: Array<any>, series, stringTarget: string) {
        if (this.curTwp !== twp) {
            this.curTwp = twp;
            this.twpSeries = {
                name: stringTarget,
                color: 'rgb(130, 204, 91)',
                zIndex: 3,
                allowPointSelect: false,
                data: (<Array<WeightPlanPoint>>(JSON.parse(twp.weightPlanPointsJson)))
                    .map(ad => { return { x: new Date(ad.date).getTime(), y: ad.weight }; }),
                marker: { enabled: false }
            };
        }
        const newSparkline = Object.assign({}, this.sparkLine);
        if (this.twpSeries) {
            series.push(this.twpSeries);
        }
        newSparkline.xAxis = xAxis;
        newSparkline.series = series;
        return newSparkline;
    }

    static getOptionsColumn(twp: WeightPlan, xAxis, series) {
        return {
            chart: {
                renderTo: this,
                backgroundColor: null,
                borderWidth: 0,
                type: 'column',
                margin: [2, 0, 2, 0],
                style: {
                    overflow: 'visible'
                },

                // small optimalization, saves 1-2 ms each sparkline
                skipClone: true
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            xAxis: xAxis,
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tickPositions: [0]
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    marker: {
                        radius: 1,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    },
                    fillOpacity: 0.25
                },
                column: {
                    negativeColor: '#910000',
                    borderColor: 'silver'
                }
            },
            series: series
        }
    }
}

declare let moment: any;

export class DetailsSearchParams {
    [key: string]: DetailsSearchParam;
}

export class DetailsSearchParam {
    display = '';
    type: DetailsSearchParamType;
    greater: any = null;
    less: any = null;
    equal: any = null;
    notEqual: any = null;
    isUnknown:boolean = false;
    isKnown:boolean = false;

    constructor(display: string, type: DetailsSearchParamType, greater: any, less: any, equal: any) {
        this.display = display;
        this.type = type;
        this.greater = greater;
        this.less = less;
        this.equal = equal;
    }

    // true means mismatch
    compare(val: any): boolean {
        if (((val === undefined) || (this.isKnown && (val === null || val === undefined || (this.isUnknown && (<string>val.toString()).replace(' ', '') === '')))
            || (this.isUnknown && val !== null && val !== undefined && (<string>val.toString()).replace(' ', '') !== ''))
            && !(this.isUnknown && (val === null || val === undefined))) { // new field is blank/null then mismatch
            return true;
        }
        if (!this.isUnknown && (val === null || val === undefined)) {
            // not filter by unknown, but val is null, then mismatch
            return true;
        }
        if (this.type === DetailsSearchParamType.number) {
            return !((this.greater === null || parseFloat(val.toString()) >= parseFloat(this.greater.toString()))
                && (this.less === null || parseFloat(val.toString()) < parseFloat(this.less.toString()))
                && (this.equal === null || parseFloat(val.toString()) === parseFloat(this.equal.toString()))
                && (this.notEqual === null || parseFloat(val.toString()) !== parseFloat(this.notEqual.toString())));
        } else if (this.type === DetailsSearchParamType.string) {
            return !((this.equal === null || val.toString() === this.equal.toString())
                && (this.notEqual === null || val.toString() !== this.notEqual.toString()));
        } else if (this.type === DetailsSearchParamType.date) {
            const valMoment = moment(val).startOf('day'),
                valAsDate = valMoment.valueOf();
            if (isNaN(valAsDate)) {
                return false;
            }
            return !((this.greater === null || valAsDate >= moment(this.greater).startOf('day').valueOf())
                && (this.less === null || valAsDate < moment(this.less).startOf('day').valueOf())
                && (this.equal === null || Math.abs(valMoment.diff(moment(this.equal).startOf('day'), 'minutes')) < 1)
                && (this.notEqual === null || Math.abs(valMoment.diff(moment(this.equal).startOf('day'), 'minutes')) > 1));
        } else if (this.type === DetailsSearchParamType.time) {
            const valAsEpoch = ((new Date(Date.parse(val))).getTime()) / 1000;
            if (isNaN(valAsEpoch)) {
                return false;
            }
            return !((this.greater === null || valAsEpoch >= this.greater.getTime())
                && (this.less === null || valAsEpoch < this.less.getTime())
                && (this.equal === null || valAsEpoch === this.equal.getTime())
                && (this.notEqual === null || valAsEpoch !== this.notEqual.getTime()));
        }
        return false;
    }
}

export enum DetailsSearchParamType {
    number = 0,
    string = 1,
    date = 2,
    time = 3
}

<div class="form-group">

  <label *ngIf="label">{{label}} <span *ngIf="required">*</span> </label>

  <div [ngClass]="{ 'input-group': icon }" [class.input-selected]="inputBox === doc.activeElement" #inputGroup>
    <div class="input-group-prepend" *ngIf="icon">
      <span class="input-group-text"><i class="fa {{icon}}"></i></span>
    </div>
    <ng-content></ng-content>
  </div>

  <div class="text-danger mt-1"
       [class.hide-error]="inputBox === doc.activeElement || inputGroup.nativeElement === doc.activeElement || showError === false || (control && !(control.dirty && control.invalid))"
       *ngIf="getErrorMessage(); let errorMessage;">
    <strong>{{errorMessage}}.</strong>
  </div>

</div>

<ng-container *ngIf="template === 'DETAILS'; else listTemplate;">

  <button *ngIf="showAssignAnimal" class="btn btn-outline-primary ml-2" (click)="assignOrReassignAnimal()">
    <ng-container *ngTemplateOutlet="assignOrReassignTemplate"></ng-container>
  </button>

  <button *ngIf="showMarkAsCurrent" class="btn btn-outline-primary ml-2" (click)="markAsCurrent()">
    <ng-container *ngTemplateOutlet="markAsCurrentTemplate"></ng-container>
  </button>

  <ng-container *ngIf="showMarkAsDeadSold">
    <button class="btn btn-outline-primary ml-2" (click)="markAsSold()">
      <ng-container *ngTemplateOutlet="markAsSoldTemplate"></ng-container>
    </button>
    <button class="btn btn-outline-primary ml-2" (click)="markAsDead()">
      <ng-container *ngTemplateOutlet="markAsDeadTemplate"></ng-container>
    </button>
  </ng-container>

</ng-container>

<ng-template #listTemplate>
  <div ngbDropdown class="d-inline-block dropdown-ellipsis" (click)="$event.stopPropagation(); $event.preventDefault();" placement="bottom-right">
    <button class="btn btn-sm" ngbDropdownToggle>
      <i class="far fa-ellipsis-v"></i>
    </button>
    <div ngbDropdownMenu>

      <button ngbDropdownItem *ngIf="showAssignAnimal" (click)="assignOrReassignAnimal()">
        <ng-container *ngTemplateOutlet="assignOrReassignTemplate"></ng-container>
      </button>

      <button ngbDropdownItem *ngIf="showMarkAsCurrent" (click)="markAsCurrent()">
        <ng-container *ngTemplateOutlet="markAsCurrentTemplate"></ng-container>
      </button>

      <ng-container *ngIf="showMarkAsDeadSold">

        <button ngbDropdownItem (click)="markAsSold()">
          <ng-container *ngTemplateOutlet="markAsSoldTemplate"></ng-container>
        </button>

        <button ngbDropdownItem (click)="markAsDead()">
          <ng-container *ngTemplateOutlet="markAsDeadTemplate"></ng-container>
        </button>

      </ng-container>

    </div>
  </div>
</ng-template>

<ng-template #assignOrReassignTemplate>
  <ng-container *ngIf="mostRecentWeightRecord.groups_GroupId" i18n="@@changeGroup">Change Group</ng-container>
  <ng-container *ngIf="!mostRecentWeightRecord.groups_GroupId" i18n="@@assignToGroup">Assign to Group</ng-container>
</ng-template>

<ng-template #markAsCurrentTemplate>
  <ng-container i18n="@@markAsCurrent">Mark As Current</ng-container>
</ng-template>

<ng-template #markAsSoldTemplate>
  <ng-container i18n="@@markAsSold">Mark As Sold</ng-container>
</ng-template>

<ng-template #markAsDeadTemplate>
  <ng-container i18n="@@markAsDied">Mark As Died</ng-container>
</ng-template>

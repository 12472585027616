import {Component, OnInit, Input} from '@angular/core';

import {Animal} from '../../models';
import {Group} from '../../models/group.model';
import {Session} from '../../models/session.model';


@Component({
  selector: 'app-animal-summary',
  templateUrl: './animal-summary.component.html',
  styleUrls: ['./animal-summary.component.scss']
})
export class AnimalSummaryComponent implements OnInit {
  @Input() animal: Animal;
  @Input() groups: Group[];
  @Input() sessions: Session[];

  constructor() {
  }

  ngOnInit() {

  }
}

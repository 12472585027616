import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {Permissions} from '../main/models/permission.model';
import {AuthService} from './auth.service';
import {MainService} from '../main/main.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService implements OnDestroy {
  public static self: PermissionsService;
  public currentFarmRoleId: number = null;
  private sub: Subscription;
  permissions: Permissions = new Permissions();

  constructor(private mainService: MainService, private authService: AuthService) {
    PermissionsService.self = this;
    this.sub = this.mainService.farmChanged.subscribe(f => {
      if (f) {
        let user = this.authService.currentUser;
        if (user && user.currentFarm_FarmId) {
          let farm = !user.userFarms ? null : user.userFarms
            .find(uf => f.farmId === uf.farmId);
          let perm = farm ? farm.farmRoleId === 1 : false;
          this.currentFarmRoleId = farm.farmRoleId;
          this.permissions = {
            farmDetailsEdit: perm,
            farmDeleteAll: perm,
            farmUsersInvite: perm,
            farmUsersRemove: perm,
            farmUsersView: farm.farmRoleId < 3,
            canAccessPpl: farm.farmRoleId < 3,
            sessionsDelete: farm.farmRoleId < 3,
            canEditData: farm.farmRoleId < 3,
            groupViewOnly: farm.farmRoleId === 3,
          };
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

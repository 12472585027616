<a class="link" (click)="openUserMenu()">

  <i class="fas fa-user-circle"></i>

  <div class="user-name">
    <ng-container *ngIf="authService.currentUser">{{ authService.currentUser.firstName }} {{ authService.currentUser.lastName }}</ng-container>
    <ng-container *ngIf="!authService.currentUser" i18n="@@loading">Loading...</ng-container>
  </div>

</a>

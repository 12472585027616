<app-summary-header
  i18n-headerTitle="@@subscriptionmanagement"
  i18n-subHeaderTitle="@@buyandmanagesubscription"
  headerTitle="Subscription Management"
  subHeaderTitle="Buy and manage your subscription">
</app-summary-header>

<div class="page-content page-content-y">
  <div>
    <app-subscriptions-info></app-subscriptions-info>
  </div>
  <div class="mt-5">
    <app-subscriptions-farms></app-subscriptions-farms>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-errors-sum',
  templateUrl: './form-errors-sum.component.html'
})
export class FormErrorsSumComponent implements OnInit {
  @Input() errors: string | string[];

  constructor() {
  }

  ngOnInit(): void {
  }
}

import { SessionWeightSummary } from './session-weight-summary';
import { Session } from './session.model';
import { User } from './user.model';

export class SessionImportAudits implements ISessionImportAudits {
    sessionImportAuditId: number;
    session_SessionId: number;
    importResult: boolean;
    importDateTime: Date;
    farm_FarmId: number;
    userName: string;
    user_UserId: string;
    // user: User;

    session?: Session;
    deleted: boolean;
    // savedFileName: string;
    sessionFile: any;
    userAgent: string;
    device_DeviceId: number;
    notes: string;
    tagChanges: Array<ITagChange>;
    error?: string;
}


export interface ISessionImportAudits {
    sessionImportAuditId: number;
    session_SessionId: number;
    importResult: boolean;
    importDateTime: Date;
    farm_FarmId: number;
    userName: string;
    user_UserId: string;
    // user: User;

    session?: Session;
    deleted: boolean;
    // savedFileName: string;
    sessionFile: any;
    userAgent: string;
    device_DeviceId: number;
    notes: string;
    tagChanges: Array<ITagChange>;
    error?: string;
}

export interface ITagChange {
    FieldName: string;
    OldValue: string;
    NewValue: string;
}

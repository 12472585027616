import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {AuthService} from '../../../auth/auth.service';
import {MainService} from '../../main.service';
import {ApiService} from '../../api/api.service';
import {FarmService} from '../../api';

@Component({
  templateUrl: './user-menu-modal.component.html',
  styleUrls: ['./user-menu-modal.component.scss']
})
export class UserMenuModalComponent {

  constructor(public authService: AuthService,
              public ngbActiveModal: NgbActiveModal,
              public mainService: MainService,
              private apiService: ApiService,
              private router: Router) {
  }

  logoutButtonClicked() {
    this.apiService.post('/api/UserActivity',
      {
        category: 2,
        timeStamp: new Date(),
        eventName: 'Logout',
        pageName: this.router.url,
        farm_FarmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
        userId: this.authService.currentUserChanged.value.userId,
        userGenerated: true
      }, 'text'
    ).subscribe(res => {
      this.mainService.farmsChanged.next(null);
      this.mainService.farmChanged.next(null);
    }, err => {
    });

    this.authService.logout();
    this.ngbActiveModal.close();
  }
}

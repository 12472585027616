<div class="month-picker-container" #monthPickerEl [style.width]="width" style="position:relative;" (blur)="visible = false;">

  <!-- input -->
  <input #theDatePicker [class.month-picker-invalid]="invalid" [class.text-black]="hasChanged" value="{{dayMonthYearString(displayDate)}}"
         [style.width]="width" class="pl15 pr15 form-control" (focus)="updateCurSlectorDateOnOpen(); visible = true;" style="position: relative;"
         (change)="textEdited($event.target.value)">

  <!-- dropdown -->
  <div [style.width]="width" class="month-picker-dropdown" *ngIf="visible">

    <!-- year selection -->
    <div class="year-selection">
      <button (click)="selectedYear = selectedYear - 1; yearChanged()" class="btn btn-default text-center">
        <i class="fas fa-chevron-left"></i>
      </button>
      <strong class="text-center flex-grow-1">{{selectedYear}}</strong>
      <button (click)="selectedYear = selectedYear + 1; yearChanged()" class="btn btn-default text-center">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <!-- month selection -->
    <div class="month-selection">
      <button *ngFor="let month of months" [disabled]="month.disabled" class="btn btn-default month-picker-cell" [class.active]="month === selectedMonth"
              [value]="month.id" (click)="monthChange(month.id)">{{monthString(getDate(month.id))}}</button>
    </div>
  </div>

</div>

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Group} from '../models/group.model';
import {SessionAction} from '../models/session-action';
import {SessionService} from '../api/session.service';
import {WeightPlanTemplateService} from '../api/weight-plan-template.service';
import {WeightPlanTemplate} from '../models/weight-plan-template.model';
import {LocaleService} from '../../helpers/locale.service';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import {CreateGroupTemplateHelper} from '../../helpers/create-group-template-helper';
import {IntegrationService} from '../api/integration.service';
import {MainService} from '../main.service';

@Component({
  templateUrl: './create-new-group.component.html',
  styleUrls: ['./create-new-group.component.scss'],
  providers: [WeightPlanTemplateService]
})
export class CreateNewGroupComponent implements OnInit {
  @Input() group: Group;
  @Input() sessionAction: SessionAction;
  @Input() assignAction: boolean;
  @Input() name: string;

  step = 0;
  templates: WeightPlanTemplate[];

  constructor(private weightPlanTemplateService: WeightPlanTemplateService,
              private localeService: LocaleService,
              private sessionService: SessionService,
              private integrationService: IntegrationService,
              private bottomNotificationsService: BottomNotificationsService,
              private mainService: MainService,
              private ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit() {

    // get templates in any case: new group or edit
    this.getWeightPlanTemplates();

    // decide is it new group or edit
    if (this.group) {

    } else {
      this.group = this.initNewGroup();
    }

    this.step = 1;
  }

  // go to next step
  nextStep(group: Group): void {
    Object.assign(this.group, group);
    this.step++;

    if (this.step === 2 && this.templates.length === 0 && !this.group.groupId) {
      if (
        this.group.targetWeightPlan === null ||
        this.group.targetWeightPlan === undefined ||
        !this.group.targetWeightPlan.weightPlanPointsJson ||
        this.group.targetWeightPlan.weightPlanPointsJson.length < 5
      ) {
        let plan = null;
        if (this.integrationService.integration.value && this.group.species_SpeciesId === 1) {
          plan = this.getPPLPlanHack();
        }
        let template = CreateGroupTemplateHelper.AdjustTargetWeightTemplate(plan, this.group, this.sessionAction);
        this.group.targetWeightPlan = {
          targetWeightPlanId: this.group.targetWeightPlan ? this.group.targetWeightPlan.targetWeightPlanId : null,
          nominalDateOfBirth: new Date(),
          startDate: template.startDate,
          weightPlanPointsJson: JSON.stringify(template.points),
          group_GroupId: this.group.groupId,
          targetWeightPlanTemplate_TargetWeightPlanTemplateId: template ? template.targetWeightPlanTemplateId : null,
          planName: this.localeService.constants.stringCustom
        };
      }
      this.step++;
    } else if (this.step === 2 && this.group.groupId) {
      this.step++;
    }
  }

  // go to prev step or close the window
  prevStepOrCancel(event: 'cancel' | 'prev'): void {
    if (event === 'cancel') {
      this.ngbActiveModal.dismiss('cancelled by user');
    } else if (this.step > 0) {
      this.step--;

      const templates = this.templates.filter(t => t.species_SpeciesId === this.group.species_SpeciesId).slice();
      if (this.step === 2 && (templates.length === 0 || this.group.groupId)) {
        this.step--;
      }
    } else {
      this.ngbActiveModal.dismiss('cancelled by user');
    }
  }

  finishModifications(group: Group): void {

    // in case it is newly created group
    this.group = Object.assign({}, this.group, { groupId: group.groupId });

    this.ngbActiveModal.close(this.group);
  }

  private initNewGroup(): Group {
    const group = new Group();

    if (this.sessionAction && this.sessionService.sessions.value && !this.name) {
      let session = this.sessionService.sessions.value.find(s => s.sessionId === this.sessionAction.sessionId);
      if (session) {
        group.groupName = session.sessionName;
      }
    } else if (this.name) {
      group.groupName = this.name;
    }

    return group;
  }

  private getWeightPlanTemplates(): void {
    this.weightPlanTemplateService.getWeightPlanTemplates().subscribe(templates => {
      this.templates = templates;
      this.templates.forEach(t => {
        const points = JSON.parse(t.weightPlanPointsJson);

        t['startMonth'] = this.localeService.month[t.monthStart - 1].fullMonth;
        t['endMonth'] = this.localeService.month[(points[points.length - 1].monthOffset - 1 + t.monthStart) % 12].fullMonth;
        t['startWeight'] = points[0].weight;
        t['endWeight'] = points[points.length - 1].weight;
      });
    }, err => {
      this.bottomNotificationsService.secondLevelNotification(err);
    });
  }

  private getPPLPlanHack(): WeightPlanTemplate {
    let northernRegions = ['Queensland', 'Northern Territory', 'Western Australia'];

    if (northernRegions.find(r => r.toLocaleLowerCase() === this.mainService.farmChanged.value.region.toLocaleLowerCase())) {
      return {
        targetWeightPlanTemplateId: null,
        planTemplateName: 'PPL plan',
        monthStart: 0,
        weightPlanPointsJson: '[{\"monthOffset\":0,\"weight\":200},{\"monthOffset\":3,\"weight\":281},{\"monthOffset\":6,'
          + '\"weight\":327},{\"monthOffset\":9,\"weight\":345},{\"monthOffset\":12,\"weight\":336}]',
        age: 1,
        species_SpeciesId: 1,
        country_CountryId: 1
      }
    } else {
      return {
        targetWeightPlanTemplateId: null,
        planTemplateName: 'PPL plan',
        monthStart: 0,
        weightPlanPointsJson: '[{\"monthOffset\":0,\"weight\":200},{\"monthOffset\":3,\"weight\":274},{\"monthOffset\":6'
          + ',\"weight\":365},{\"monthOffset\":9,\"weight\":437},{\"monthOffset\":12,\"weight\":547}]',
        age: 1,
        species_SpeciesId: 1,
        country_CountryId: 1
      }
    }
  }
}

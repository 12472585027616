<div class="row " style="width: 100%; overflow: visible">
  <!--
    <div class="col-md-6 col-lg-5 col-xs-12 prn ">
      <button (click)="setMassageEidsFlag()">Massage all EIDs</button>
    </div>
  -->
</div>

<app-loading *ngIf="!farms"></app-loading>

<ng-container *ngIf="farms">
<div class="mb-3">
  <input type="text" pInputText size="50" style="max-width: 250px;" class="form-control" i18n-placeholder="placeholder text for a filter input@@quicksearch"
         placeholder="Quick search" (input)="tt.filterGlobal($event.target.value, 'contains')">
</div>

<div class="card card-table">
  <p-table #tt [columns]="cols" [value]="farms" [rows]="50" [autoLayout]="true" [paginator]="true" [pageLinks]="11" [rowsPerPageOptions]="[20,50,100]"
           class="ui-datatable" tableStyleClass="table table-hover">
    <ng-template pTemplate="header" let-columns>
      <tr class="ui-widget-content">
        <th class="ui-state-default"></th>
        <!--
          <th class="ui-state-default"></th>
        -->
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="ui-state-default">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-farm let-columns="columns">
      <tr class="ui-widget-content">
        <td>
          <button class="btn btn-sm btn-secondary" (click)="changeFarm(farm)">Use Farm</button>
        </td>
        <!--
        <td>
          <button (click)="forceUpdateSpecificFarmIds(farm)">Massage EIDs</button>
        </td>
      -->
        <td *ngFor="let col of columns" [ngSwitch]="col.field" [pEditableColumn]="farm" [pEditableColumnField]="'farmType'">
          <div *ngSwitchCase="'farmId'">
            {{farm[col.field]}}
          </div>
          <div *ngSwitchCase="'country'">
            {{farm[col.field]}}
          </div>
          <div *ngSwitchCase="'farmName'">
            {{farm[col.field]}}
          </div>
          <div *ngSwitchCase="'numUsers'" class="ui-cell-data">
            <a *ngIf="farm['userFarms@odata.count'] > 0" [routerLink]="['/main/admin/users']" [queryParams]="{farmId:farm.farmId}">{{farm['userFarms@odata.count']}} users</a>
            <span *ngIf="farm['userFarms@odata.count'] === 0">0 users</span>
          </div>
          <div *ngSwitchCase="'created'">
            {{farm.created | date: 'dd MMM yyyy'}}
          </div>
          <div *ngSwitchCase="'farmType'">
            <p-cellEditor>
              <ng-template pTemplate="output">
                {{farm[col.field]}}
              </ng-template>
              <ng-template pTemplate="input">
                <p-dropdown *ngIf="farm && farm.farmType" [ngModel]="farm[col.field]" [options]="farmTypes" appendTo="body" (onChange)="farmTypeChanged(farm,$event.value)"></p-dropdown>
              </ng-template>
            </p-cellEditor>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
</ng-container>

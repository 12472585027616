import {Injectable, InjectionToken, Injector} from '@angular/core';
import {SelectItem} from 'primeng/components/common/selectitem';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable} from 'rxjs'

import {WeightRecordService} from '../../api/weight-record.service';
import {SessionAction} from '../../models/session-action';
import {DetailsSearchParamType} from '../../models/details-search-params';
import {ScriptService} from '../../script.service';
import {GroupWeightRecordSplit} from '../../models/group-weight-record-split.model';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {LocaleService} from '../../../helpers/locale.service';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {Group} from '../../models/group.model';
import {DatatypeHelper} from '../../../helpers/datatype.helper';
import {SessionService} from '../../api/session.service';
import {ActionService} from '../../api/action.service';
import {GroupsService} from '../../services/groups.service';
import {MainService} from '../../main.service';
import {ActionsModalComponent} from './actions-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ActionsModalService {
  public animalData: Array<any>; // flattened animal data
  public action: SessionAction;
  public dataTypeHeaders: Array<any>; // all posible life/session data headers
  public groups: Array<SelectItem> = [];
  public groupsWrSplit: Array<GroupWeightRecordSplit>;
  // public actionComplete = new EventEmitter<boolean>();

  public visible = false;
  public movePriorWeights = false;

  /* one is supposed to be true in order to continue */
  /* if user is splitting then function will return current spliter component */
  /* if assignment to full group then  */
  public getGroupsWrSplit: () => Array<GroupWeightRecordSplit> = null;
  public selectedGroupId: number = null;

  constructor(private weightRecordService: WeightRecordService,
              private scriptService: ScriptService,
              private groupsService: GroupsService,
              private actionService: ActionService,
              private bottomNotificationsService: BottomNotificationsService,
              private localeService: LocaleService,
              private createNewGroupService: CreateNewGroupService,
              private sessionService: SessionService,
              private mainService: MainService,
              private ngbModal: NgbModal,
              private injector: Injector) {

  }

  init(action: SessionAction): Observable<SessionAction> {
    if (!action) { // must have a valid action type and action to open modal
      return;
    }

    // TODO: move to component
    // get groups for drop down
    this.groups = this.getGroups();

    this.movePriorWeights = !!this.mainService.farmChanged.value.groupAssignmentAll;
    this.action = action;
    this.action.movePriorWeights = this.movePriorWeights
      ? this.movePriorWeights // if we have a value, then use it
      : this.action.groupId !== null ? false : null; // if no value, AND we are reassigning (eg have a groupId), then default to false
    this.groupsWrSplit = null;
    this.selectedGroupId = null;
    this.visible = true;
    this.weightRecordService.getDataFieldsForSession(action.sessionId, action.ided, action.groupId).subscribe(res => {
      if (res) {
        this.processUdfs(res);
      }
    });

    // open and return modal
    const modalRef: NgbModalRef = this.ngbModal.open(ActionsModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    modalRef.componentInstance.singleAnimal = this.action.animalCount === 1 && this.action.weightRecords;

    return from(modalRef.result);
  }

  updateGroups(opt: number = 3): void {
    this.groups = this.getGroups(opt);
  }

  private getGroups(opt: number = 3): SelectItem[] {
    let groups = [{ label: this.localeService.constants.stringNewGroup, value: 0 }];

    if (this.groupsService.groups.value) {
      this.groupsService.groups.value.forEach(grp => {
        if (opt === 1 && grp.isCurrent) {
          groups.push({ label: grp.displayName, value: grp.groupId });
        } else if (opt === 2 && !grp.isCurrent) {
          groups.push({ label: grp.displayName, value: grp.groupId });
        } else if (opt === 3) {
          groups.push({ label: grp.displayName, value: grp.groupId });
        }
      });
    } else {
      groups = null;
    }

    return groups;
  }

  private processUdfs(wrs) {
    this.animalData = [];
    this.dataTypeHeaders = [{ label: this.localeService.constants.stringWeight, value: { name: 'weight', type: DetailsSearchParamType.number } }];
    wrs.forEach(wr => {
      let anim: any = {};
      anim.weight = wr.weight;
      anim.weightRecordId = wr.weightRecordId;
      if (wr.userDefinedFieldsJson && wr.userDefinedFieldsJson.length > 5) {
        let udf = JSON.parse(wr.userDefinedFieldsJson);
        for (let param in udf) {
          if (udf[param]) {
            anim[param] = udf[param];
            let found = this.dataTypeHeaders.find(a => a.label === param);
            if (!found) {
              this.dataTypeHeaders.push({ label: param, value: { name: param, type: DatatypeHelper.getFieldType(udf[param]) } })
            } else if ((found.value.type === DetailsSearchParamType.date && DatatypeHelper.getFieldType(udf[param]) !== DetailsSearchParamType.date)
              || (found.value.type === DetailsSearchParamType.number && DatatypeHelper.getFieldType(udf[param]) !== DetailsSearchParamType.number)
              || (found.value.type === DetailsSearchParamType.time && DatatypeHelper.getFieldType(udf[param]) !== DetailsSearchParamType.time)) {
              found.value.type = DetailsSearchParamType.string;
            }
          }
        }
      }
      if (wr.animal && wr.animal.userDefinedFieldsJson && wr.animal.userDefinedFieldsJson.length > 5) {
        let ludf = JSON.parse(wr.animal.userDefinedFieldsJson);
        for (let param in ludf) {
          if (ludf[param] && ludf[param].Value) {
            anim[param] = ludf[param].Value;
            let found = this.dataTypeHeaders.find(a => a.label === param);
            if (!found) {
              this.dataTypeHeaders.push({ label: param, value: { name: param, type: DatatypeHelper.getFieldType(ludf[param].Value) } })
            } else if ((found.value.type === DetailsSearchParamType.date && DatatypeHelper.getFieldType(ludf[param].Value) !== DetailsSearchParamType.date)
              || (found.value.type === DetailsSearchParamType.number && DatatypeHelper.getFieldType(ludf[param].Value) !== DetailsSearchParamType.number)
              || (found.value.type === DetailsSearchParamType.time && DatatypeHelper.getFieldType(ludf[param]) !== DetailsSearchParamType.time)) {
              found.value.type = DetailsSearchParamType.string;
            }
          }
        }
      }
      this.animalData.push(anim);
    });
  }

  previousWeightsChanged(keep: boolean) {
    this.movePriorWeights = keep;
    this.action.movePriorWeights = this.movePriorWeights;
    this.actionService.updateFarmGroupAssignmentProperty(keep);
  }

  /**
   * Only care if group is new
   * Opens new group modal
   * @param {any} splitDropdown
   * @param {any} groupId group id should be 0
   * @memberOf ActionsModalService
   */
  private newGroupForSplit(splitDropdown, groupId) {
    if (groupId === 0 && splitDropdown) {
      this.createNewGroupService.createGroup(this.action, false, null, (group: Group) => {
        setTimeout(() => {
          splitDropdown.value = group ? group.groupId : null;
          splitDropdown.selectedOption = group ? this.groups.find(g => g.value === group.groupId) : this.groups[0];
        }, 0)
      });
    }
  }

  private getSessionName(): string {
    let session = this.sessionService.sessions.value ? this.sessionService.sessions.value.find(s => s.sessionId === this.action.sessionId) : null;
    return session ? session.sessionName : '';
  }
}

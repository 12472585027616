<div class="row no-gutters small-gutters">
  <div class="col-7">
    <div class="row no-gutters small-gutters">
      <div class="col-sm-7">
        <span i18n="@@aboveorequalto">Above or equal to</span>&nbsp;
        <div class="text-shady-lady">{{aboveSummary.count}}
          <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{aboveSummary.weight}}&nbsp;
          <ng-container i18n="abbreviation for average@@avgAllCapital">Avg</ng-container>
        </div>
      </div>
      <div class="col-sm-5">
        <input class="form-control" type="number" [(ngModel)]="aboveOrEqualTo" (change)="workoutSummaries()">
      </div>
    </div>
  </div>
  <div class="col-sm-5" *ngIf="groupsSelectList && aboveSummary">
    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="aboveSummary.value"
      (ngModelChange)="groupChanged($event)">
    </ng-select>
  </div>
</div>

<div class="row no-gutters small-gutters mt-3">
  <div class="col-sm-7">
    <span i18n="@@below">Below</span>&nbsp;<span>{{aboveOrEqualTo}}</span>&nbsp;
    <div class="text-shady-lady">{{belowSummary.count}}&nbsp;
      <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{belowSummary.weight}}
      <ng-container i18n="abbreviation for average@@avgAllCapital">Avg</ng-container>
    </div>
  </div>
  <div class="col-sm-5" *ngIf="groupsSelectList && belowSummary">
    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="belowSummary.value"
      (ngModelChange)="groupChanged($event, false)">
    </ng-select>
  </div>
</div>

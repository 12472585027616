<!--<app-actions-modal (actionComplete)="removeAction($event);"></app-actions-modal>-->
<!--<app-actions-sold-dead-modal [trigger]="trigger" (actionComplete)="soldDeadModalResult($event);"></app-actions-sold-dead-modal>-->


<div class="card card-widget">

  <!-- if education -->
  <app-dashboard-education
    *ngIf="this.sessionService.sessions.value && dashboardEducationService.actionsE"
    image="Upload-BLUE.png"
    i18n-titleText="@@steponecaps"
    titleText="STEP 1"
    i18n-text="@@uploadsessionfilestostart"
    text="Upload your session files to start" i18n-buttonText buttonText="Record">
  </app-dashboard-education>

  <!-- widget header -->
  <div class="card-header">
    <h3 class="m-0" i18n="@@actions">Actions</h3>
  </div>

  <!-- widget body/content -->
  <div class="card-body">

    <!-- widget loading -->
    <app-loading *ngIf="!sessions" [local]="true"></app-loading>

    <!-- no actions -->
    <div *ngIf="sessions && actionsCount === 0" class="card-blank-state">
      <i class="fa fa-check"></i>
      <h3>
        <ng-container i18n="@@youreallcaughtup">You're all caught up</ng-container>
      </h3>
      <p>
        <ng-container i18n="@@nopendingactions">No pending actions</ng-container>.
      </p>
    </div>

    <!-- widget data -->
    <ng-container *ngIf="sessions && actionsCount">

      <cdk-virtual-scroll-viewport
        minBufferPx="900"
        maxBufferPx="900"
        itemSize="10"
        class="cdk-virtual-scroll-viewport">

        <ng-container *cdkVirtualFor="let session of sessions">
          <div class="action-box-container" *ngFor="let action of session.actions; let i = index;">

            <app-actions-box
              [action]="action"
              [session]="session"
              [widget]="true"
              (disableEnableAction)="disableEnableAction(action.v_ActionId, $event)">
            </app-actions-box>

          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>

    </ng-container>

  </div>
</div>



<h3 class="mb-1" i18n="@@basedOn">Based on</h3>

<ng-select
  class="form-control form-control-ng-select"
  [items]="actionsSoldDeadService.dataTypeHeaders"
  [placeholder]="localeService.constants.stringSelectField"
  bindLabel="label"
  bindValue="value"
  [(ngModel)]="selectedItem"
  (ngModelChange)="selectedItemChanged($event)">
</ng-select>

<ng-container *ngIf="selectedItem">

  <h4 class="mt-4" i18n="@@WhichAnimalsShouldBeMarkedAsSold" *ngIf="actionsSoldDeadService.actionType === 2">
      Which animals should be marked as sold?
  </h4>
  <h4 class="mt-4" i18n="@@WhichAnimalsShouldBeMarkedAsDied" *ngIf="actionsSoldDeadService.actionType === 3">
      Which animals should be marked as died?
  </h4>

  <app-actions-sold-dead-model-spliter-number #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 0"></app-actions-sold-dead-model-spliter-number>
  <app-actions-sold-dead-model-spliter-strings #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 1"></app-actions-sold-dead-model-spliter-strings>
  <app-actions-sold-dead-model-spliter-date #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 2"></app-actions-sold-dead-model-spliter-date>
  <app-actions-sold-dead-model-splitter-time #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 3"></app-actions-sold-dead-model-splitter-time>
</ng-container>

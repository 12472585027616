import { AuthService } from '../../../../auth/auth.service';
import { UserInvitation } from './user-invitation';
import { MainService } from '../../../main.service';
import { InvitationService } from '../../../api/invitation.service';
import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../../helpers/locale.service';
import { BottomNotificationsService } from '../../../../components/bottom-notifications/bottom-notifications.service';

@Component({
  selector: 'app-user-invitations',
  templateUrl: './user-invitations.component.html',
  styleUrls: ['./user-invitations.component.scss']
})
export class UserInvitationsComponent implements OnInit {
  invitations: Array<UserInvitation> = [];
  loading = false;

  constructor(private invitationService: InvitationService, private mainService: MainService, private authService: AuthService
    , private localeService: LocaleService, private bottomNotificationsService: BottomNotificationsService) { }

  accept(invitation: UserInvitation) {
    if (this.authService.currentUser) {
      let farmId = invitation.farm_FarmId;
      this.loading = true;
      this.invitationService.acceptInvitation(invitation.code)
        .subscribe(resp => {
          invitation.accepted = true;
          this.authService.addFarmToCurrentUser(farmId, invitation.farmRoleId);
          setTimeout(() => {
            this.authService.refreshUser(this.authService.currentUser.userId).subscribe(() => {
              setTimeout(() => {
                this.mainService.changeFarm(farmId);
              });
            });
          });
        }, err => {
          invitation.accepted = false;
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAcceptanceFailure,
            message: this.localeService.constants.stringInvitationAcceptanceFailed,
            type: 'warning'
          });
        });
    }
  }

  ngOnInit() {
    this.invitationService.getInvitations().subscribe((invitations: Array<UserInvitation>) => {
      this.invitations = invitations
    });
  }
}

import {throwError as observableThrowError, Observable, Subscription, BehaviorSubject, throwError} from 'rxjs';
import {UserSubscription} from '../main/models/user-subscription.model';
import {LocalStoreManager} from '../helpers/local-store-manager.service';
import {User} from '../main/models/user.model';
import {Injectable, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {JwtToken} from '../main/models/jwtToken.Model';
import {RegisterUser} from './app-user.model';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http'
import {JwtHelperService} from '@auth0/angular-jwt';
import {Farm} from '../main/models/index';
import {map, catchError} from 'rxjs/operators';
import {of} from 'rxjs';


import {IAuthService} from '../mocks/interfaces/i-auth-service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService, OnDestroy {
  public static emailsToIgnore = ['mihub-admin@trutest.co.nz', 'e2e-test@trutest.co.nz', 'test-e2e@trutest.co.nz'];
  registerUrl = environment.apiUrlRoot + '/jwt/register';
  loginUrl = environment.apiUrlRoot + '/jwt/login';
  forgotPasswordUrl = environment.apiUrlRoot + '/ForgotPassword/sendLinkToResetPassword';
  resetPasswordUrl = environment.apiUrlRoot + '/ForgotPassword/ResetPassword';
  superAdminGetResetCodeUrl = environment.apiUrlRoot + '/ForgotPassword/SuperAdminGetResetCode';
  RefreshTokenUrl = environment.apiUrlRoot + '/jwt/login';
  jwtHelper: JwtHelperService = new JwtHelperService();
  public currentUser: User;
  public remember = true;
  public currentUserChanged = new BehaviorSubject<User>(this.currentUser);
  public registrationDetails: any; // this is cache for registration details in case registration page is navigated away from
  public isDatamarsUser = false; // Check email to see if @trutest and if so we have a local/management user.

  public currentSubscription = new BehaviorSubject<UserSubscription>(null);
  public trialDaysLeft = 0;

  private subs: Array<Subscription> = [];
  private token: string;
  private refreshToken: string;

  private refreshingUser = false;
  private paramSub: any = null;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private localStoreManager: LocalStoreManager) {
    this.paramSub = route.queryParams.subscribe(params => {
      if (params && params.token && !this.refreshingUser) {
        this.paramSub.unsubscribe();
        this.logout();
        setTimeout(() => {
          this.setToken(params.token);
          let accessToken = this.jwtHelper.decodeToken(params.token);
          localStorage.removeItem('access-token-expiry');
          localStorage.setItem('access-token-expiry', accessToken.expires);
          localStorage.setItem('user-id', accessToken.nameid);
          this.refreshUser(accessToken.nameid).subscribe(() => {
            setTimeout(() => {
              this.router.navigate(['/main']);
            });
          });
        });
      }
    });

    // This solves the issue where store manager does not have token on the initial login check.
    this.localStoreManager.syncCallbacks.push(data => {
      if (!this.token) {
        this.token = data['access-token'];
        this.refreshToken = data['refresh-token'];
        this.router.navigate(['/main']);
      }
    });

    this.subs.push(this.currentUserChanged.subscribe(user => {
      if (user && user.subscriptions && user.subscriptions.length) {
        let sub = user.subscriptions[user.subscriptions.length - 1];
        
        const aklTime= moment.tz(moment.utc(sub.expiresAt).format(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland')
        this.trialDaysLeft= Math.ceil(aklTime.diff(moment.now(), 'day',true))

        if (sub !== this.currentSubscription.value) {
          this.currentSubscription.next(sub);
        }
        if ((router.url.indexOf('/main/subscriptions') < 0 && router.url.indexOf('/main/manage/user') < 0)
          && sub && sub.subscriptionPlan_SubscriptionPlanId === 1 && this.trialDaysLeft <= 0) {
          this.router.navigate(['/main/subscriptions']);
        }
      } else {
        this.currentSubscription.next(null);
      }
    }));
  }

  public setToken = (token: string, refreshToken: string = null): void => {
    this.token = token;
    this.refreshToken = refreshToken;
    this.remember || !this.localStoreManager.localStorageAvaliable ? this.localStoreManager.savePermanentData(token, 'access-token')
      : this.localStoreManager.saveSyncedSessionData(token, 'access-token');
    if (refreshToken) {
      this.remember ? this.localStoreManager.savePermanentData(refreshToken, 'refresh-token')
        : this.localStoreManager.saveSyncedSessionData(refreshToken, 'refresh-token');
    }
  }

  addNewFarmToCurrentUser(farm: Farm) {
    this.currentUser.userFarms.push({ farmId: farm.farmId, userId: this.currentUser.userId, farmRoleId: 1 })
  }

  addFarmToCurrentUser(farmId: number, farmRoleId: number) {
    this.currentUser.userFarms.push({ farmId: farmId, userId: this.currentUser.userId, farmRoleId: farmRoleId })
  }

  updateCurrentFarmOfUser(farm_id: number) {
    this.currentUserChanged.next(Object.assign(this.currentUser, { currentFarm_FarmId: farm_id }));
  }

  getRefreshToken = (): string => {
    if (!this.refreshToken) {
      this.refreshToken = this.localStoreManager.getData('refresh-token');
    }
    return this.refreshToken;
  }
  getToken = (): string => {
    if (!this.token) {
      this.token = this.localStoreManager.getData('access-token');
    }
    return this.token;
  }

  handleError(error: any) {
    if (error.status === 503) {
      window.location.reload(true);
    }
    if (error && error.error) {
      return observableThrowError(error);
    }
    return observableThrowError('Incorrect details');
  }

  checkIfTruTest(user: User) {
    if (user) {
      this.isDatamarsUser = user.userType.toLowerCase() === 'staff';
    }
  }

  refreshUser(userId: string, user: User = null): Observable<User> {
    if (!user && this.token && !this.refreshingUser) {
      this.refreshingUser = true;
      let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.token);
      return this.http.get(environment.apiUrlRoot + `/odata/Users('${userId}')?$expand=subscriptions($expand=subscriptionPlan),userFarms`
        , { headers: headers }).pipe(map((newUser: User) => {
          this.refreshingUser = false;
          if (newUser) {
            this.checkIfTruTest(newUser);
            this.currentUser = newUser;
            this.identifyToSmartlook();
            this.currentUserChanged.next(newUser);
            if (!this.currentUser.currentFarm_FarmId) {
              this.router.navigate(['/main/subscriptions']);
            }
            if (this.currentUser.userFarms.find(uf => this.currentUser.currentFarm_FarmId === uf.farmId).farmRoleId === 3) {
              this.router.navigate(['/main/groups/all']);
            }
          } else {
            console.error('newUser is null');
            this.logout();
          }
          return newUser;
        }),
        catchError(err => {
          this.refreshingUser = false;
          if (err.status === 503) {
            window.location.reload(true);
          }
          if (err.status === 401 || err.status === 403) {
            this.logout();
            this.router.navigate(['/']);
          }
          return observableThrowError('Incorrect details');
        }))
    } else if (user) {
      this.checkIfTruTest(user);
      this.currentUser = user;
      this.identifyToSmartlook();
      this.currentUserChanged.next(user);
      if (!this.currentUser.currentFarm_FarmId) {
        this.router.navigate(['/main/subscriptions']);
      }
      if (this.currentUser.userFarms.find(uf => this.currentUser.currentFarm_FarmId === uf.farmId).farmRoleId === 3) {
        this.router.navigate(['/main/groups/all']);
      }
      return of(user);
    }
    return of(null);
  }

  /*
   * return true if login successfull and false if failed
   */
  login(username: string, password: string): Observable<boolean> {
    let headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams({ encoder: new GhQueryEncoder() })
      .set('username', username.trim())
      .set('password', password)
      .set('grant_type', 'password');

    return this.http.post(this.loginUrl, body, { headers: headers })
      .pipe(
        catchError((err, caught) => {
          this.handleError(err);
          return throwError(err);
        }),
        map((jwtResult: JwtToken) => {
          if (jwtResult === null) {
            return false;
          }
          this.setToken(jwtResult.access_token, jwtResult.refresh_token);
          let accessToken = this.jwtHelper.decodeToken(jwtResult.access_token);
          localStorage.removeItem('access-token-expiry');
          localStorage.setItem('access-token-expiry', accessToken.expires);
          localStorage.setItem('user-id', accessToken.nameid);
          this.refreshUser(accessToken.nameid).subscribe();
          return true;
        }));
  }

  superAdminGetResetCode(email: string): Observable<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json; charset=utf-8')
      .append('Prefer', 'return=representation')
      .append('Authorization', 'Bearer ' + this.getToken());
    let payload = { Email: email };
    return this.http.post(this.superAdminGetResetCodeUrl, payload, { headers: headers, responseType: 'text' });
  }

  emailResetPassword(email: string): Observable<any> {
    const postData = { Email: email };
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json');

    return this.http.post(this.forgotPasswordUrl, postData, { headers: headers, responseType: 'text' })
      .pipe(catchError((err, caught) => {
        this.handleError(err);
        return throwError(err);
      }));
  }

  resetPassword(email: string, password: string, confirmPassword: string, code: string): Observable<any> {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.getToken());
    let postData = { Email: email, Password: password, ConfirmPassword: confirmPassword, Code: code };
    return this.http.post(this.resetPasswordUrl, postData, { headers: headers, responseType: 'text' })
      .pipe(catchError((err, caught) => {
        this.handleError(err);
        return of(null);
      }));
  }

  register(registerUser: RegisterUser): Observable<any> {
    let headers = new HttpHeaders().append('Content-Type', 'application/json');

    let postData = registerUser;
    return this.http.post(this.registerUrl, postData, { headers: headers, responseType: 'text' });
  }

  logout() {
    this.logoutNoRedirect();
    this.router.navigate(['/']);
  }

  logoutNoRedirect() {
    this.currentUser = null;
    this.token = null;
    this.refreshToken = null;
    this.currentSubscription.next(null);
    this.currentUserChanged.next(null);
    this.localStoreManager.clearAllStorage();
  }

  isLoggedIn(): Observable<boolean> {
    if (this.currentUser) {
      this.identifyToSmartlook();
      return of(true);
    } else {
      const userId = localStorage.getItem('user-id');
      if (this.getToken() && localStorage.getItem('access-token-expiry') && userId) {
        try {
          let expiry = new Date(localStorage.getItem('access-token-expiry'));
          expiry.setDate(expiry.getDate() - 1);
          let utcNow = new Date();
          if (utcNow.getTime() >= expiry.getTime()) {
            return this.refreshTokenAndStoreToken();
          } else {
            this.refreshUser(userId).subscribe();
            return of(true);
          }
        } catch (e) {
          console.error('Crashed when trying checking if logged in');
          console.error(e);
          this.logout();
          return of(false);
        }
      } else {
        this.localStoreManager.clearAllStorage();
        return of(false);
      }
    }
  }

  identifyToSmartlook() {
    /*
    setTimeout(() => {
        let firstName = this.currentUser.firstName ? this.currentUser.firstName : '';
        let lastName = this.currentUser.lastName ? this.currentUser.lastName : '';
        let fullName = firstName.concat(' ').concat(lastName);
        (<any>window).smartlook('identify', this.currentUser.userId, {
            'name': fullName,
            'email': this.currentUser.email ? this.currentUser.email : ''
        });
    });
    */
  }

  refreshTokenAndStoreToken(refreshToken: string = null): Observable<boolean> {
    if (refreshToken === null) {
      refreshToken = this.getRefreshToken();
    }
    if (refreshToken === null) {
      this.logout();
      return of(false);
    }

    let headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token');

    return this.http.post(this.loginUrl, body, { headers: headers })
      .pipe(
        catchError((err, caught) => {
          this.handleError(err);
          return of(null);
        }),
        map((jwtResult: JwtToken) => {
          if (jwtResult === null) {
            return false;
          }
          this.setToken(jwtResult.access_token, refreshToken);
          let accessToken = this.jwtHelper.decodeToken(jwtResult.access_token);
          localStorage.removeItem('access-token-expiry');
          localStorage.setItem('access-token-expiry', accessToken.expires);
          localStorage.setItem('user-id', accessToken.nameid);
          this.refreshUser(accessToken.nameid).subscribe();
          return true;
        })
      );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

class GhQueryEncoder extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    k = super.encodeKey(k);
    return k.replace(/\+/gi, '%2B');
  }

  encodeValue(v: string): string {
    v = super.encodeKey(v);
    return v.replace(/\+/gi, '%2B');
  }
}

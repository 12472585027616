﻿import {Component} from '@angular/core';
import {OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
declare var $: any;

import {AuthService} from './auth.service';


@Component({
  templateUrl: './auth.template.html'
})
export class AuthComponent implements OnInit, OnDestroy {
  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe(res => {
      if (res) {
        this.router.navigate(['/main']);
        return;
      } else {
        $('body').addClass('external-page');
      }
    });
  }

  ngOnDestroy() {
    $('body').removeClass();
  }
}

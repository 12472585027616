import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApiService } from '../main/api/api.service';
import { AuthService } from '../auth/auth.service';
import { MainService } from '../main/main.service';



@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor( private injector: Injector) { }

    handleError(error: Error) {
        try {
            const message = error.message ? error.message : error,
                apiService: ApiService = this.injector.get(ApiService, null),
                authService: AuthService = this.injector.get(AuthService, null),
                mainService: MainService = this.injector.get(MainService, null),
                wl = window.location.toString(),
                k = wl.indexOf('.'),
                env = window.location.toString().substring(0, k).replace('http://', '').replace('https://', '');
            if (apiService) {
                apiService.post('/Api/Error/Log'
                    , {
                        msg: message
                        , windowurl: window.location.toString()
                        , jserrorurl: document.location.href
                        , line: error.stack
                        , col: ''
                        , farmId: mainService && mainService.farmChanged.value ? mainService.farmChanged.value.farmId : null
                        , farmName: mainService && mainService.farmChanged.value ? mainService.farmChanged.value.farmName : null
                        , error: message
                        , userid: authService && authService.currentUser ? authService.currentUser.userId : null
                        , username: authService && authService.currentUser ? authService.currentUser.email : null
                        , environment: env
                        , jwtpayload: localStorage.getItem('access-token') || ''
                    }).subscribe();
                if (window['ga']) {
                    if ((<any>window).ga) {
                        (<any>window).ga('send', 'event', 'Error', window.location.href, null, { error: error.message ? error.message : error });
                    }
                }
            }
        } catch (e) {
            console.error('Failed to log an error: ' + e);
        }
    }
}

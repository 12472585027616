import { DashboardEducationService } from '../dashboard-education.service';
import { SessionWeightSummary } from '../../models/session-weight-summary';
import { SessionService } from '../../api/session.service';
import { Rounder } from '../../../helpers/rounder.helper';
import { Session } from '../../models/session.model';
import { SessionImportAudits } from '../../models';
import { MainService } from '../../main.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalParameterService } from '../../../helpers/global-parameter.service';
import { DetailsSearchParam, DetailsSearchParamType } from '../../models/details-search-params';
import { LocaleService } from '../../../helpers/locale.service';
import { ScriptService } from '../../script.service';
import {PermissionsService} from '../../../auth/permissions.service';
import {IntegrationService} from '../../api/integration.service';
import { SelectItem } from '../../models/select-item';
import { DatatypeHelper } from '../../../helpers/datatype.helper';

@Component({
  selector: 'app-session-widget',
  templateUrl: './session-widget.component.html',
  styleUrls: ['./session-widget.component.scss']
})
export class SessionWidgetComponent implements OnInit, OnDestroy {
  sessions: Array<SessionWeightSummary>;
  session: Session;
  options: any;
  chartName = this.localeService.constants.stringWeightGraph;
  loading: boolean;

  sessionsSelectList: Array<SelectItem> = [];
  sessionLabel = '';

  private subs: Array<Subscription> = [];

  constructor(
    private mainService: MainService,
    private router: Router,
    public sessionService: SessionService,
    private localeService: LocaleService,
    private scriptService: ScriptService,
    public dashboardEducationService: DashboardEducationService,
    private globalParameterService: GlobalParameterService,
    private permissionsService: PermissionsService,
    private integrationService: IntegrationService) { }


  ngOnInit() {
    this.scriptService.load('highcharts').then(data => {
        this.scriptService.loadArray(['hcBoost', 'hcMore']).then(laterData => {
        this.subs.push(this.sessionService.sessions.subscribe(sessions => {
          this.sessions = sessions ? sessions.slice().filter(s => s.averageWeight) : null;
          // this.sessions = sessions ? sessions.slice().filter(s => new Date(s.sessionStartDate) <= new Date()) : null;
          if (this.sessions && this.sessions.length > 0) {
            this.dashboardEducationService.sessionsE = false;
            this.sessionChanged(this.sessions[0].sessionId);
          } else if (sessions) {
            this.dashboardEducationService.getSessionData().then(s => {
              if (!this.sessions) {
                this.sessions = [];
              }

              this.sessions.push(<SessionWeightSummary>s);
              this.session = s;
              this.setSeries();
            });
          }
        }));
      });
    });
  }

  setChartName() {
    this.chartName = this.localeService.constants.stringWeightGraph;
    // this needs to be a juggling check, rather than strict, as we need to protect from "undefined" (not just null) during page load
    if (this.session != null) {
      this.chartName = `${this.getDisplayName(this.session)} - ` + this.localeService.constants.stringWeightGraph;
    }
  }

  getDisplayName(s: Session) {
    let ssd = new Date(s.sessionStartDate)
      , formattedStartDate = `${ssd.getUTCDate()} ${this.localeService.month[(ssd.getUTCMonth())].month} ${ssd.getUTCFullYear()}`
    return `${s.sessionName} (${formattedStartDate})`;
  }

  sessionChanged(sessionId: number) {
    this.loading = true;
    this.sessionService.GetSessionWithWeights(sessionId).subscribe(s => {
      this.loading = false;
      this.session = s;
      this.setSeries();
      this.setChartName();
      this.sessionsFiltered('');
      this.sessionLabel = s.sessionName + ' (' + DatatypeHelper.tryFormatDateWithMoment(s.sessionStartDate, 'D MMM YYYY') + ')';
    }, () => {
      this.loading = false;
      this.sessionsFiltered('');
    });
  }

  dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'D MMMM YYYY');
  }

  sessionsFiltered(searchWords: string) {
    let filteredSessions = Array<Session>();
    if (searchWords && searchWords.replace(' ', '') !== '') {
      let sw = searchWords.split(' ').filter(s => s.length > 0);
      filteredSessions = this.sessions.filter(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.sessionName.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        return ret >= sw.length;
      });
      this.sessionsSelectList = [];
      filteredSessions.forEach(s => {
        if (s.sessionName && s.sessionId) {
          let sl = s.sessionName + ' (' + DatatypeHelper.tryFormatDateWithMoment(s.sessionStartDate, 'D MMM YYYY') + ')';
          this.sessionsSelectList.push({ label: sl, value: s.sessionId });
        }
      });
    } else {
      filteredSessions = this.sessions.slice();
      this.sessionsSelectList = [];
      filteredSessions.forEach(s => {
        if (s.sessionName && s.sessionId) {
          let sl = s.sessionName + ' (' + DatatypeHelper.tryFormatDateWithMoment(s.sessionStartDate, 'D MMM YYYY') + ')';
          this.sessionsSelectList.push({ label: sl, value: s.sessionId });
        }
      });
    }
  }

  calculateBinSize(minWeight, maxWeight) {
    let weightBreakPoints = [10, 20, 50, 100, 200, 500, 1000, 2000];
    for (let i = 0; i < weightBreakPoints.length; i++) {
      if (Math.ceil(maxWeight) - Math.floor(minWeight) < weightBreakPoints[i]) {
        return weightBreakPoints[i] / 10;
      }
    }

    // shouldn't reach here unless the weight range is greater than 2000.
    return 100;
  }

  createXAxisFromWeights(weights) {
    let minWeight = Math.min.apply(null, weights),
      maxWeight = Math.max.apply(null, weights),
      binSize = this.calculateBinSize(minWeight, maxWeight),
      result: any = new Object();

    minWeight = Math.floor(minWeight / binSize) * binSize;
    maxWeight = Math.floor(maxWeight / binSize) * binSize + binSize;

    result.minWeight = minWeight;
    result.maxWeight = maxWeight;
    result.binSize = binSize;
    result.numberOfBins = (maxWeight - minWeight) / binSize + 1;

    return result;
  }

  setSeries() {
    if (!this.session || this.session.weightRecords.length === 0) {
      this.options = null;
      return;
    }
    this.setChartName();

    let weights = this.session.weightRecords.map(wr => wr.weight),
      xAxis = this.createXAxisFromWeights(weights),
      binnedData = new Array(xAxis.numberOfBins);
    for (let i = 0, l = xAxis.numberOfBins; i < l; i++) {
      binnedData[i] = [xAxis.minWeight + i * xAxis.binSize, 0];
    }
    for (let i = 0; i < weights.length; i++) {
      let w = weights[i],
        bin = Math.floor((w - xAxis.minWeight) / xAxis.binSize);
      binnedData[bin][1] = binnedData[bin][1] + 1;
    }

    let tinterval = 1;
    if (binnedData.length > 1) {
      tinterval = binnedData[1][0] - binnedData[0][0];
    }


    let cName = this.chartName;
    this.options = {
      series: [{
        name: this.localeService.constants.stringCount,
        data: binnedData,
        cursor: 'pointer'
      }],
      title: '',
      subtitle: '',
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        events: {
          beforePrint: function () {
            this.setTitle({
              text: null
            });
          },
          afterPrint: function () {
            this.setTitle({ text: null });
          }
        }
      },
      tooltip: {
        headerFormat: '',
        formatter: function () {
          let val = Rounder.performRounding({ number: this.y });
          return `${this.series.name}: <b>${val}</b><br/>`;
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 2,
          groupPadding: 0,
          shadow: false,
          pointPlacement: +0.5,
          events: {
            click: e => {
              this.globalParameterService.detailsSearchParams.next({
                Weight: new DetailsSearchParam(this.localeService.constants.stringWeight,
                  DetailsSearchParamType.number, e.point.x, e.point.x + e.point.series.closestPointRange, null)
              });
              this.globalParameterService.currentSessionId = this.session.sessionId;
              this.router.navigate(['/main/sessions/details'], {
                queryParams: { id: this.session.sessionId, tab: 'animals' }
              });
            }
          }
        }
      },
      xAxis: {
        title: {
          text: null
        },
        labels: {
          style: {
            fontFamily: '"Lato", Helvetica, Arial, sans-serif',
            fontSize: '12px'
          }
        },
        tickInterval: tinterval
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          style: {
            fontFamily: '"Lato", Helvetica, Arial, sans-serif',
            fontSize: '12px'
          }
        },
        lineWidth: 1,
        allowDecimals: false
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              {
                text: this.localeService.constants.stringPrintChart,
                onclick: function () {
                  this.print();
                }
              },
              {
                text: this.localeService.constants.stringDownloadPDF,
                onclick: function () {
                  this.setTitle({
                    text: null
                  });
                  this.exportChart({
                    type: 'application/pdf',
                    filename: cName
                  });
                }
              },
              {
                text: this.localeService.constants.stringDownloadPNG,
                onclick: function () {
                  this.setTitle({
                    text: null
                  });
                  this.exportChart({
                    filename: cName
                  });
                }
              }
            ]
          }
        }
      }
    };
  }
  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

<header class="my-3 flex-grow-1">

  <div class="row">
    <div class="col-10 col-md-7 d-flex align-items-center">

      <div class="d-flex flex-align-items-center">
        <div class="d-flex flex-column">
          <h3 class="m-0">
            {{ headerTitle }}
          </h3>
          <span class="text-shady-lady fs16">{{subHeaderTitle}}</span>
        </div>
      </div>

    </div>

    <div class="col-2 col-md-5">

      <!--some outlet thing here to allow for custom buttons etc in the header bar-->
      <div class="content-right">
        <ng-content></ng-content>
      </div>

    </div>
  </div>

</header>


import { FarmUser } from '../models/farm-user.model';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';





@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

    constructor(private authService: AuthService, private apiService: ApiService) { }

    public getSubscriptionFarmCreators = (subscriptionId: number): Observable<Array<FarmUser>> => {
        return this.apiService.get(`/odata/Subscriptions/Services.SubscriptionFarmCreator(subscriptionId=${subscriptionId})`).pipe(map(res => res.value));
    }

    public updateSubscriptionType = (subscriptionId: number, newSubscriptionType: number): Observable<boolean> => {
        return this.apiService.post(`/odata/Subscriptions/Services.UpdateSubscriptionType`
            , { subscriptionId: subscriptionId, newSubscriptionType: newSubscriptionType });
    }

}

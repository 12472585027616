<div class="card card-external card-forgot-password">

  <app-loading *ngIf="loading" [local]="true"></app-loading>

  <div class="card-body">

    <!-- logo -->
    <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

    <!-- form -->

    <form *ngIf="form" [formGroup]="form" (ngSubmit)="emailLink($event)">
      <h3 i18n="@@enteremailtoresetpassword">Enter email to reset password</h3>

      <app-form-input [control]="form.get('email')" i18n-label="@@emailaddress" label="Email address"
                      [hideAsterisks]="true"
                      i18n-error="@@emailerror" error="A valid email is required"
                      i18n-errorValidateEmail="@@emailerror" errorValidateEmail="A valid email is required">
        <input type="email" autofocus formControlName="email" class="form-control">
      </app-form-input>

      <div class="text-right">
        <button type="submit" class="btn btn-primary" i18n="@@reset">Reset</button>
      </div>

      <!-- show all errors -->
      <app-form-errors-sum [errors]="errorMessage"></app-form-errors-sum>

    </form>

    <div class="login-links text-center">
      <a routerLink="/login" title="Sign In" class="active" i18n="@@signinhere">Sign in here</a>
    </div>

  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  imgLocations = environment.imgLocations;
  window = window;
  constructor(public location: Location) { }

  ngOnInit() {
    $('body').addClass('bg-dark-grey external-page pbn onload-check sb-l-c sb-r-c');
  }

  ngOnDestroy() {
    $('body').removeClass();
  }

}

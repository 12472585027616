<div class="card card-external">
  <div class="card-body">

    <!-- logo -->
    <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

    <h3 class="m-0">Reset password email sent</h3>
    <p>An email has been sent to the email address you entered. Please follow the instructions in the email to reset your password.</p>

    <div>
      <button type="button" routerLink="/login" class="btn btn-primary ml-0">OK</button>
    </div>

  </div>
</div>

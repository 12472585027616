<!--<p-dialog *ngIf="this.actionsAssignFilteredListModalService.action && this.actionsAssignFilteredListModalService.visible">-->
  <div class="modal-header">
    <h3>{{ changingGroup ? localeService.constants.stringChangeGroup : localeService.constants.stringAssignToGroup }}</h3>
    <a (click)="ngbActiveModal.dismiss(); clearData();" class="close"><i class="fas fa-times"></i></a>
  </div>
  
  <div class="modal-body">
  
    <!-- 7 animals will be assigned and 10 animals will be moved to the new group. (should be count of 'animals' excluding any without IDs) -->
      <div>
        <span>{{unassignedString}}</span>
        <br />
        <span>{{reassignString}}</span>
      </div>
  
    <!-- previous weights section -->
    <ng-container *ngIf="showPreviousWeights"> <!-- if there are no animals already in a group -->
      <h3 class="mt-4" i18n="@@previousweights">Previous Weights</h3>
      <div>
        <span>{{reassignCountString}}</span>
      </div>
      <div class="form-group row no-gutters small-gutters">
        <label *ngIf="!sessionAssignment" class="col-6 col-form-label" i18n="@@WeightsBeforeTheMostRecentSession">
          Weights before the most recent session
        </label>
        <label *ngIf="sessionAssignment" class="col-6 col-form-label" i18n="@@WeightsBeforeThisSession">
          Weights before this session
        </label>
    
        <div class="col-6">
          <div class="form-group">
            <ng-select
              class="form-control form-control-ng-select"
              [items]="dropdownItems"
              bindLabel="label"
              bindValue="value"
              [searchable]="false"
              dropdownPosition="bottom"
              [clearable]="false"
              [(ngModel)]="actionsAssignFilteredListModalService.movePriorWeights"
              (ngModelChange)="previousWeightsChanged($event)">
            </ng-select>
          </div>
        </div>
    
      </div>
    </ng-container>
  
    <!-- create a new group modal -->
    <div class="mt-4">
      <app-actions-modal-group-selection [singleAnimal]="singleAnimal" (closeModal)="closeOrDismissModal($event)" (newGroupSelected)="newGroupSelected($event)"></app-actions-modal-group-selection>
    </div>
  
  </div>
  
  <div class="modal-footer">
    <button class="btn btn-link" (click)="ngbActiveModal.dismiss(); clearData();" i18n="@@cancel">Cancel</button>
    <button class="btn btn-primary ml-1" [disabled]="lockDoneButton" (click)="save()" i18n="@@done">Done</button>
  </div>
  
  
  <ng-template #errorTemplateTitle>
    <div><strong i18n="@@error">Error</strong></div>
  </ng-template>
  
  <ng-template #errorTemplateMessageAnimalCount>
    <ng-container i18n="@@MustHaveAtLeast1AnimalAssignedToAGroup">Must have at least 1 animal assigned to a group</ng-container>
  </ng-template>
  
  <ng-template #errorTemplateMessageGroup>
    <ng-container i18n="@@PleaseSelectValidGroup">Please select valid group</ng-container>
  </ng-template>
  
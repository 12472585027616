import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '../../settings/settings.service';
import { AuthService } from '../../../auth/auth.service';
import { FormValidation } from '../../../helpers/form-validation.helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-temp-password',
  templateUrl: './temp-password.component.html',
  styleUrls: ['./temp-password.component.scss'],
  providers: [SettingsService]
})
export class TempPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  subscription: Array<Subscription> = [];
  errorMessage = '';
  tempPass = '';

  constructor(private settingsService: SettingsService, public authService: AuthService) { }

  setUpTempPassForm() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([FormValidation.validateEmail, Validators.required]))
    });

    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  formChanged() {
    this.errorMessage = '';
  }

  generatePassword(event) {
    if (this.form.valid) {
      this.settingsService.getTempPassword(this.form.get('email').value).subscribe(res => {
        this.tempPass = res;
      },
        error => this.errorMessage = error.message);
    } else {
      if (this.form.get('email').invalid) {
        this.errorMessage = 'A valid email is required.';
      }
    }
    event.preventDefault();
  }

  ngOnInit() {
    this.setUpTempPassForm();
  }

  ngOnDestroy() {
    for (let i = 0; i < this.subscription.length; i++) {
      this.subscription[i].unsubscribe();
    }
    this.tempPass = '';
  }

}

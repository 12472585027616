<app-loading *ngIf="!users || !farmsPickerOptions"></app-loading>

<ng-container *ngIf="users && farmsPickerOptions">

  <div class="row mb-3">
    <div class="col-md-6 col-lg-5">
      <button class="btn btn-sm btn-secondary" (click)="setManualIntercomFlag()">Set Manual Intercom Update</button>
      <!--
      <button (click)="setMassageEidsFlag()">Set EIDs Massage for tonight</button>
    -->
    </div>
    <div class="col-md-6 col-lg-5">
      <div class="custom-control custom-checkbox mr-md-3">
        <input [(ngModel)]="showSuperUsers" (change)="showSuperUsersChanged();" id="showSuperUsers" type="checkbox" class="custom-control-input">
        <label for="showSuperUsers" class="custom-control-label">Show SuperUsers</label>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <input type="text" pInputText size="50" style="max-width: 250px;" class="form-control" i18n-placeholder="placeholder text for a filter input@@quicksearch"
             placeholder="Quick search"
             (input)="dataTable.filterGlobal($event.target.value, 'contains')">
    </div>
    <div class="col-md-6">
      <ng-select
        class="ml-auto"
        [(ngModel)]="filteredFarmId"
        [ngModelOptions]="{standalone: true}"
        [items]="farmsPickerOptions"
        bindLabel="label"
        bindValue="value"
        [clearable]="false"
        (ngModelChange)="getUsers()"
        [virtualScroll]="true">
      </ng-select>

<!--      <p-dropdown [style]="{'height': '33px', 'min-width': '250px'}" class="pull-right" [options]="farmsPickerOptions" (onChange)="getUsers()"-->
<!--                  [(ngModel)]="filteredFarmId" [virtualScroll]="true" [itemSize]="38"></p-dropdown>-->
    </div>
  </div>

  <div class="card card-table">
    <p-table [value]="users" class="no-overflow" #dataTable dataKey="userId"
             [rows]="50" [paginator]="true" [pageLinks]="11" [rowsPerPageOptions]="[20,50,100]"
             [globalFilterFields]="['userId', 'firstName', 'lastName', 'email', 'userType']" tableStyleClass="table table-hover">


      <!-- header -->
      <ng-template pTemplate="header">
        <tr class="ui-widget-content">
          <th class="ui-state-default">User Id</th>
          <th class="ui-state-default">First Name</th>
          <th class="ui-state-default">Last Name</th>
          <th class="ui-state-default">Email</th>
          <th class="ui-state-default">Number of Farms</th>
          <th class="ui-state-default">Created</th>
          <th class="ui-state-default">Type</th>
          <th class="ui-state-default"></th>
          <th class="ui-state-default"></th>
        </tr>
      </ng-template>

      <!-- rows body -->
      <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr class="ui-widget-content">
          <td style="overflow: hidden;">{{ rowData.userId }}</td>
          <td>{{ rowData.firstName }}</td>
          <td>{{ rowData.lastName }}</td>
          <td style="overflow: hidden;">{{ rowData.email }}</td>
          <td>
            <div class="ui-cell-data">
              <a *ngIf="rowData.userFarms && rowData.userFarms.length > 0" [routerLink]="['/main/admin/farms']" [queryParams]="{ userId: rowData.userId }">{{ rowData.userFarms.length }} farms</a>
            </div>
          </td>
          <td>
            {{ rowData.created | date: 'dd MMM yyyy' }}
          </td>
          <td pEditableColumn style="overflow: visible">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [(ngModel)]="rowData.userType" [options]="userTypes" appendTo="body" (onChange)="userTypeChanged(rowData, $event.value)"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.userType }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="resetPasswordModal(rowData.email)">Reset Password</button>
          </td>
          <td>
            <button type="button" class="btn btn-sm btn-secondary" (click)="impersonateUser(rowData.email)">Impersonate User</button>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</ng-container>

<p-dialog [visible]="dialogVisible" header="Edit user's role" [modal]="true" [resizable]="false" [width]="600" (onHide)="editedUser = null; dialogVisible=false;">
  <div *ngIf="!editedUser" style="min-height: 100px;">
    <app-loading [local]="true"></app-loading>
  </div>
  <div *ngIf="editedUser && adminFarms && adminFarms.length === 0">
    <h4 i18n="@@userhasnofarmstheyareadminofwouldyouliketochangetheirrole">User has no farms that they are Admin of. Would you like to change their role?</h4>
  </div>
  <div *ngIf="editedUser && adminFarms && adminFarms.length > 0" style="overflow-y:auto; max-height: 100%;">
    <h4>
      <ng-container i18n="@@userisanadminofthefollowingfarms">User is an admin of the following farms</ng-container>
      :
    </h4>
    <div *ngFor="let uf of adminFarms">{{uf.farm.farmName}},</div>
    <h4 i18n="@@wouldyouliketochangethosefarmstomatchusertype">Would you like to change those farms types to match user type?</h4>

  </div>

  <p-footer>
    <div class="p30 card-footer col-xs-12">
      <button type="button" (click)="changeUserType(true)" class="col-xs-3 col-md-2 pull-left btn btn-primary" i18n="@@yes">Yes</button>
      <button type="button" (click)="changeUserType(false)" class="col-xs-3 col-md-2 pull-left btn btn-primary" i18n="@@no"
              *ngIf="editedUser && editedUser.userFarms && editedUser.userFarms.length > 0">No
      </button>
      <button type="button" (click)="editedUser = null; dialogVisible=false;" class="col-xs-4 col-md-3 pull-right btn btn-primary"
              i18n="@@cancel">Cancel
      </button>
    </div>
  </p-footer>
</p-dialog>

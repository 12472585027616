import { Component, OnInit, Input } from '@angular/core';
import { UploadSummary } from '../../models/upload-summary.model';
import { ErrorSummary } from '../../models/error-summary.model';

@Component({
  selector: 'app-upload-summary',
  templateUrl: './upload-summary.component.html',
  styleUrls: ['./upload-summary.component.scss']
})
export class UploadSummaryComponent implements OnInit {
  @Input()
  uploadSummaries: UploadSummary[];
  @Input()
  errors: ErrorSummary[] = [];

  errorFileCount = 0;
  fileWithInvalidRows = 0;

  constructor() { }

  ngOnInit() {
    this.errorFileCount = this.errors
      ? this.errors.filter(e => e.errorResult.toLowerCase().indexOf('file') >= 0 // english
                            || e.errorResult.toLowerCase().indexOf('fichier') >= 0 // french
                            || e.errorResult.toLowerCase().indexOf('datei') >= 0 // german
                            || e.errorResult.toLowerCase().indexOf('arquivo') >= 0 // portugese
                            || e.errorResult.toLowerCase().indexOf('archivo') >= 0 // spanish
    ).map(a => a.fileName).filter((x, i, a) => a.indexOf(x) === i).length : 0;
    this.fileWithInvalidRows = this.uploadSummaries ? this.uploadSummaries.filter(s => s.invalidRowCount && s.invalidRowCount > 0).length : 0;
  }

}

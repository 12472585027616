import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

import {AuthService} from '../../auth/auth.service';
import {IntegrationService} from '../api/integration.service';
import {MainService} from '../main.service';
import {mobileBreakpoint} from '../../helpers/constants';

declare let $: any;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})

export class MainMenuComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardMenu', { static: false }) dashboardMenu;
  @ViewChild('adminMenu', { static: false }) adminMenu;

  showAllMenus = true;

  private farmChangeSub: Subscription = null;
  private subMainMenu: Subscription = null;
  private routerSub: Subscription = null;

  constructor(
    private router: Router,
    public authService: AuthService,
    public integrationService: IntegrationService,
    public mainService: MainService) {
  }

  ngOnInit() {
    this.farmChangeSub = this.mainService.farmChanged.subscribe(farm => {
      if (farm && this.authService.currentUser) {
        if (this.authService.currentUser.userFarms.find(uf => uf.farmId === farm.farmId).farmRoleId === 3) {
          // group sharing user
          this.showAllMenus = false;
        } else {
          this.showAllMenus = true;
        }
      }
    });

    this.routerSub = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (window.innerWidth < mobileBreakpoint) {
          this.mainService.leftMenuOpen.emit({
            forced: true,
            value: false
          });
        }
      }
    });

    this.subMainMenu = this.mainService.leftMenuOpen.subscribe(leftMenuOpenStatus => {
      if (window.innerWidth < mobileBreakpoint) {
        if (!$('.main-aside').hasClass('d-flex')) {
          setTimeout(() => {
            $('.main-aside').addClass('d-flex');
          }, 300);
        }
      }

      if (leftMenuOpenStatus) {
        if (leftMenuOpenStatus.value === true) {
          $('#main').removeClass('sb-l-c');
          $('body').removeClass('sb-l-c');
        } else if (leftMenuOpenStatus.value === false) {
          $('#main').addClass('sb-l-c');
          $('body').addClass('sb-l-c');
        }
      } else if (window.innerWidth <= mobileBreakpoint) {
        $('#main').toggleClass('sb-l-c');
        $('body').toggleClass('sb-l-c');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.farmChangeSub) {
      this.farmChangeSub.unsubscribe();
    }
    if (this.subMainMenu) {
      this.subMainMenu.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}

import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Integration } from '../../../models/integration';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-integration-model',
  templateUrl: './integration-model.component.html',
  styleUrls: ['./integration-model.component.scss']
})
export class IntegrationModelComponent implements OnInit {
  integration: Integration;
  step = 1;

  constructor(public ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
  }

  next(integration: Integration) {
    this.step++;
    this.integration = integration;
    if (!integration) {
      this.step = 0;
    }
  }
}

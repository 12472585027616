import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Group, GroupCensusWeightSummary, GroupIconTextEnum } from '../../main/models/group.model';
import { LocaleService } from '../../helpers/locale.service';
import { Rounder } from '../../helpers/rounder.helper';
import { EArray } from '../../helpers/constants.helper';

@Component({
  selector: 'app-adg-icon',
  templateUrl: './adg-icon.component.html',
  styleUrls: ['./adg-icon.component.scss']
})
export class AdgIconComponent implements OnChanges {
  @Input()
  group: Group;
  @Input()
  animalsWithWeights: number = null;

  displayData = false;
  tooltipText = '';
  circleIcon = true; // vs ExclamationIcon, this also determines use of yellow

  constructor(public localeService: LocaleService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['group']) {
      this.displayData = false;
      if (this.group.adgIconText === GroupIconTextEnum.Census) {
        this.tooltipText = this.localeService.constants.stringAdgCalculationOnCensus;
        this.displayData = true;
      } else if (this.group.adgIconText === GroupIconTextEnum.Animal) {
        this.tooltipText = this.group.adgAnimCount === 1
                ? this.localeService.constants.stringAdgCalculationOnAnimal
                : this.localeService.constants.stringAdgCalculationOnAnimals.replace('<animalCount/>', this.group.adgAnimCount);
        this.displayData = true;
      }

      if (this.displayData) {
        this.circleIcon = this.group.currentStatus.animalsWithPrevWeight / this.group.currentStatus.animalCount >= 0.5;
      }
    }
  }

}

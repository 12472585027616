import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {SessionService} from '../../api/session.service';
import {SessionsService} from '../shared/sessions.service';
import {SessionSummary} from '../../models';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { ConvertToUTC, DatatypeHelper } from '../../../helpers/datatype.helper';

@Component({
  templateUrl: './session-info.component.html'
})
export class SessionInfoComponent implements OnInit {
  sessionSummary: SessionSummary;
  tabDestroyOnHide: boolean;
  activeTabOnLoad: string;

  private firstPageLoad = true;

  constructor(private activatedRoute: ActivatedRoute,
              private globalParameterService: GlobalParameterService,
              private sessionService: SessionService,
              private sessionsService: SessionsService,
              private location: Location) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tabDestroyOnHide = true;
      this.sessionSummary = null;

      if (!this.firstPageLoad) {

        // if user stays on the sam page reset filter
        this.globalParameterService.detailsSearchParams.next({});
      } else {

        // if user is coming from dashboard session widget
        this.firstPageLoad = false;
      }

      const sessionId = +params['id'] || +this.globalParameterService.currentSessionId;
      const tab = this.activatedRoute.snapshot.queryParams['tab'];
      this.globalParameterService.currentSessionId = sessionId;

      if (tab) {
        this.activeTabOnLoad = tab;
      } else {
        this.activeTabOnLoad = 'summary';
      }

      this.sessionsService.getSessionSummary(sessionId).subscribe(sessionSummary => {
        this.sessionSummary = sessionSummary;
      }, err => {
        // TODO: handle error from server
        console.error(err);
      });
    });

    let currPath = this.location.path();
    if (currPath.includes('/summary')) {
      let updatedUrl = currPath.replace('/summary', '/details');
      this.location.go(updatedUrl); // rewrites url without reloading component etc
    }
  }

  // TODO: do not load all tabs content on load; but once loaded - do not load again?
  tabChange(tabChange: NgbTabChangeEvent): void {
    let currPath = this.location.path();
    let newTab = tabChange.nextId;
    let currTab = tabChange.activeId;
    let updatedUrl = currPath.includes('tab=' + currTab)
                          ? currPath.replace('tab=' + currTab, 'tab=' + newTab)
                          : currPath.includes('tab=' + newTab)
                              ? currPath
                              : currPath + '&tab=' + newTab;
    this.location.go(updatedUrl); // rewrites url without reloading component etc
    if (this.tabDestroyOnHide) {
      // this.tabDestroyOnHide = false;
    }
  }

  dayMonthString(date: string) {
    return ConvertToUTC(date).format('D MMM');
  }

  yearString(date: Date) {
    return ConvertToUTC(date).format('YYYY');
  }
}

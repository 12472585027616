import {FarmService} from '../../api';
import {MainService} from '../../main.service';
import {FormValidation} from '../../../helpers/form-validation.helper';
import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-demo-farm-creation',
  templateUrl: './demo-farm-creation.component.html',
  styleUrls: ['./demo-farm-creation.component.scss']
})
export class DemoFarmCreationComponent implements OnInit, OnChanges {
  @Input()
  show = false;
  form: FormGroup;
  currentFarmName: string;
  showError = false;
  loading = false;

  private opened = false;

  constructor(
    private mainService: MainService,
    private farmService: FarmService,
    private bottomNotificationsService: BottomNotificationsService,
    public ngbActiveModal: NgbActiveModal) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // previousValue will return CD_INIT_VALUE when its not init
    if (changes['show'] && (!changes['show'].previousValue
      || changes['show'].previousValue.toString() !== 'CD_INIT_VALUE')) {
      this.currentFarmName = 'Demo Farm';
    } else if (changes['show']) {
      this.currentFarmName = 'Demo Farm';
    }
  }

  createFarm() {
    if (this.form.valid) {
      this.loading = true;
      this.farmService.createNewDemoFarm(this.form.controls['farmName'].value).subscribe(() => {
        location.reload();
      }, err => {
        this.loading = false;
        this.bottomNotificationsService.currentMessage.next({
          title: 'Create Demo Farm Failed',
          message: 'No demo farm avaliable for your country',
          type: 'warning'
        })
      });
    } else {
      this.showError = true;
    }
  }

  ngOnInit() {
    this.mainService.farmsChanged.subscribe(farms => {
      if (farms) {
        this.form = new FormGroup({
          farmName: new FormControl(this.currentFarmName, FormValidation.validateFarmName(farms))
        });
      }
    });
  }
}

import {Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {combineLatest} from 'rxjs';

import {GroupsService} from '../../services/groups.service';
import {WeightRecordService} from '../../api';
import {Group} from '../../models/group.model';
import {LocaleService} from '../../../helpers/locale.service';

@Component({
  selector: 'app-groups-summary',
  templateUrl: './groups-summary.component.html',
  styleUrls: ['./groups-summary.component.scss'],
  providers: [WeightRecordService]
})
export class GroupsSummaryComponent implements OnInit, OnChanges {
  @Input() group: Group;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  weightRecords: Array<number>;
  weightRecordsAdg: Array<number>;

  constructor(private groupsService: GroupsService,
              private weightRecordService: WeightRecordService,
              private title: Title,
              private localeService: LocaleService) {
  }

  ngOnInit(): void {
    this.title.setTitle(`${this.localeService.constants.stringGroupSummary} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.group.sessionGroupAutomation = ''; // JSON.stringify(sessionGroupAutomation);
    this.downloadData();
  }

  private downloadData() {
    if (this.group.censuses.length > 0) {
      let lastCensus = this.group.censuses.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime())[0];

      combineLatest([
        this.weightRecordService.getUniededWeightsForDayAndGroup(lastCensus.sessionDay, this.group.groupId),
        this.weightRecordService.getIdedWeightsForGroup(this.group.groupId)
      ])
        .subscribe(unIededWeightsIdedWeigths => {
          if (unIededWeightsIdedWeigths[0] && unIededWeightsIdedWeigths[1]) {
            this.weightRecords = unIededWeightsIdedWeigths[0].concat(unIededWeightsIdedWeigths[1]).filter(a => a).sort((a, b) => a > b ? 1 : -1);
          }
        });

      this.groupsService.getAnimalSummary(this.group.groupId)
        .subscribe(weightRecordsAdg => this.weightRecordsAdg = weightRecordsAdg.filter(wradg => wradg.adgFromLastSeen !== null).map(wradg => wradg.adgFromLastSeen));

    } else {
      this.weightRecords = [];
      this.weightRecordsAdg = [];
    }
  }
}

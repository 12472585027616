<div class="modal-header modal-header-side-menu">
  <h3 class="text-capitalize">{{ authService.currentUser?.firstName }} {{ authService.currentUser?.lastName }}</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body">

<!--  <h4 class="margin-x" i18n="@@userDetails">User Details</h4>-->
  <span></span>
  <div class="list-group list-group-flush">
    <a class="list-group-item list-group-item-action" routerLink="/main/manage/user" (click)="ngbActiveModal.dismiss()" *ngIf="authService.currentUser.userType !== 'SuperUser'">
      <i class="fas fa-user"></i> <ng-container i18n="@@mydetails">My Details</ng-container>
    </a>
    <a class="list-group-item list-group-item-action" routerLink="/main/subscriptions" (click)="ngbActiveModal.dismiss()" *ngIf="authService.currentUser.userType !== 'SuperUser'">
      <i class="fas fa-credit-card"></i> <ng-container i18n="@@subscription">Subscription</ng-container>
    </a>
    <button class="list-group-item list-group-item-action" (click)="logoutButtonClicked()">
      <i class="fas fa-sign-out-alt"></i> <ng-container i18n="@@signout">Sign Out</ng-container>
    </button>
  </div>
  <span></span>

  <button type="button" hidden ngbAutofocus></button>

</div>

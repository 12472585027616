import { WeightRecord } from '../../models';
import { Group, GroupCensusWeightSummary } from '../../models/group.model';
import { Rounder } from '../../../helpers/rounder.helper';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { WeightPlanService } from '../../api/weight-plan.service';
import { WeightRecordService } from '../../api/weight-record.service';
import { WeightPlan, WeightPlanPoint } from '../../models/weight-plan.model';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  Input,
  OnDestroy,
  AfterContentChecked
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '../../../helpers/locale.service';
import { AnimalSummary } from '../../models/animal-adg.model';
import { CreateNewGroupService } from '../create-new-group.service';
import { SessionAction } from '../../models/session-action';
import {WeightPlanTemplate} from '../../models/weight-plan-template.model';
import { DatatypeHelper, ConvertToUTC } from '../../../helpers/datatype.helper';
import * as moment from 'moment';


@Component({
  selector: 'app-new-group-plan-adjustment',
  templateUrl: './new-group-plan-adjustment.component.html',
  styleUrls: ['./new-group-plan-adjustment.component.scss'],
  providers: [],
  animations: [
    trigger('flyInOut', [
      state('up', style({ zIndex: 1026, transform: 'translateX(0)' })),
      transition('* => up', [
        style({ transform: 'translateY(-100%)' }),
        animate('400ms')
      ]),
      state('down', style({ zIndex: 1026, transform: 'translateX(0)' })),
      transition('* => down', [
        style({ transform: 'translateY(100%)' }),
        animate('400ms')
      ])
    ])
  ]
})
export class NewGroupPlanAdjustmentComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  // Auto save hides save button and emits updateGraphData event each time plan is changed

  @Input() group: Group;
  @Input() action: SessionAction;
  @Input() templates: WeightPlanTemplate[] = [];
  @Input() step: number;

  @Output() nextStep: EventEmitter<Group> = new EventEmitter<Group>();
  @Output() prevStepOrCancel: EventEmitter<'prev' | 'cancel'> = new EventEmitter();
  @Output() setCanClose: EventEmitter<true | false> = new EventEmitter();

  window = window;
  // @Input()
  graphData: WeightPlan;

  // @Output()
  // updatedGraphData: EventEmitter<WeightPlan> = new EventEmitter();

  saveQue: any;

  years: Array<number> = [];

  form: FormGroup;
  public visible = true;

  graphControls: Array<GroupGraphPoint> = [];
  previousGraphControls: Array<GroupGraphPoint> = [];
  curDates: Array<Date> = [];
  previousDates: Array<Date> = [];
  options: any = null;
  hasClashes = false;
  hasZeros = false;
  sufficientPoints = true;
  currentCensusTimestamps = '';
  model: any;
  newDate: Date;

  loadedDateData = false;

  constructor(private weightPlanService: WeightPlanService, private zone: NgZone
    , private bottomNotificationsService: BottomNotificationsService, public createNewGroupService: CreateNewGroupService
    , private weightRecordService: WeightRecordService, public localeService: LocaleService) {
  }

  ngAfterContentChecked() {
    if (this.group && this.currentCensusTimestamps !== ''
      && this.group.censuses.map(c => c.timeStamp).toString() !== this.currentCensusTimestamps) {
      this.redrawGraph();
    }
  }

  ngOnInit() {
    if (this.group) {
      this.group = Object.assign({}, this.group);
      this.graphData = this.group.targetWeightPlan;
    }
    for (let i = new Date().getFullYear() + 1, l = new Date().getFullYear() - 10; i >= l; i--) {
      this.years.push(i);
    }
    if (this.group) {
      let graphPoints = <Array<WeightPlanPoint>>(JSON.parse(this.graphData.weightPlanPointsJson) || []);
      graphPoints.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      for (let i = 0, l = graphPoints.length; i < l; i++) {
        this.graphControls.push(this.getNewGroupGraphPoint(i, new Date(graphPoints[i].date), graphPoints[i].weight));
        this.curDates.push(new Date(graphPoints[i].date));
      }
      this.loadedDateData = true;
      this.curDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      if (this.group.censuses === undefined || this.group.censuses === null) {
        this.populateGroupCensuses();
      }
    }
    this.redrawGraph(null, true);
  }

  dayMonthYearString(date: string) {
    return ConvertToUTC(date).format('D MMMM YYYY');
  }

  populateGroupCensuses() {
    if (!this.group.censuses) {
      this.group.censuses = [];
    }
    if (this.group.censuses.length === 0 && this.group.censuses.length > 0) {
      let c = this.group.censuses[0];
      if (c.animalSummaries) {
        this.addGroupCensusSummary(c, c.animalSummaries);
      } else {
        this.weightRecordService.GetAllWeightRecordsForDay(c.sessionDay)
          .subscribe(res => {
            this.addGroupCensusSummary(c, res);
          });
      }
    }
  }

  addGroupCensusSummary(c: GroupCensusWeightSummary, animSum: Array<AnimalSummary>) {
    let sum = 0;
    if (animSum.length > 0) {
      for (let i = 0; i < animSum.length; i++) {
        sum += animSum[i].weight;
      }
      let avg = sum / animSum.length;
      let gcws: GroupCensusWeightSummary = {
        averageWeight: avg,
        animalCount: 0,
        maxWeight: 0,
        minWeight: 0,
        topWeight: 0,
        bottomWeight: 0,
        animalWithIdCount: 0,
        animalAdg: 0,
        censusAdg: 0,
        animalsWithPrevWeight: 0,
        sessionIds: c.sessionIds,
        timeStamp: c.timeStamp,
        sessionDay: 0
      }
      this.group.censuses.push(gcws);
      this.group.censuses.slice();
      this.redrawGraph();
    }
  }

  ngOnDestroy() {
    if (this.saveQue) {
      clearTimeout(this.saveQue);
    }

    // if (this.saveQue && !(<any>this.saveQue).state && !(<any>this.saveQue).state.startsWith('not')) {
    //   clearTimeout(this.saveQue);
    //   this.save();
    // }
  }

  addRow() {
    let nextPoint = this.graphControls.filter(gc => gc.date > this.newDate)[0];
    if (nextPoint) {
      if (this.curDates.length > 0) {
        this.newDate.setDate(this.graphControls[0].date.getDate());
      }
      let nextWeight = nextPoint.weight.value, nextAdg = nextPoint.adg.value, nextDate = nextPoint.date;
      let numDaysBack = this.daysUntil(this.newDate, nextDate);
      let newWeight = nextWeight - (numDaysBack * nextAdg);
      this.graphControls.push(this.getNewGroupGraphPoint(this.graphControls.length, this.newDate, newWeight));
      this.graphControls.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      this.curDates.push(this.newDate);
      this.curDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
    this.redrawGraph();
  }

  startDateChanged(newDate: Date) {

    let curStartDate = moment.utc(this.graphControls[0].date);
    let monthDiff =Math.round(moment.utc(newDate).diff(curStartDate,'M', true)) 
    let yearDiff =  moment.utc(newDate).diff(curStartDate,"year")

    this.curDates.forEach((cd,i) => {
      moment(cd).utc().add(monthDiff,"M")
    });

    // the following is horrible, I hate doing it!!
    // however, this is the only way to force change detection by angular in order to update the displayed dates on the frontside
    // when we are only changing properties within elements of the array, due to this not changing the reference to the array
    // or the references to the elements within the array. performance impact is effectively nil, as we have to do the loop for date updates anyway
    // but this still feels ugly and tainted - YAY!!!
    let tempGCs = this.graphControls;
    this.graphControls = [];
    tempGCs.forEach(gc => {
      let momentDate = moment.utc(gc.date);
      momentDate
        .add(monthDiff,'M')

        const lDay = momentDate.endOf('month')

      momentDate= momentDate.date(momentDate.date() > lDay.date() ? lDay.date(): newDate.getUTCDate()).startOf('day')

      gc.date = momentDate.toDate()

      let idx = tempGCs.findIndex(g => g.date === gc.date);
      if (idx > 0) {
        // keep the ADGs exactly the same by tweaking weights to allow for different month lengths
        let numDaysUntil = this.daysUntil(tempGCs[idx - 1].date, gc.date);
        let newWeight = Rounder.performRounding({
          number: tempGCs[idx - 1].weight.value + (gc.adg.value * numDaysUntil),
          roundTo: 0
        });
        gc.weight.setValue(newWeight);
      }
      this.graphControls.push(gc);
    });

    this.curDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    this.curDates = this.curDates.slice();
    this.graphControls.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.graphControls = this.graphControls.slice();
    this.redrawGraph();
  }

  endDateChanged(newDate: Date) {
    let mNewDate = moment.utc(newDate)
    let curEndDate = moment.utc(this.graphControls[this.graphControls.length - 1].date);
    let monthDiff = Math.round(mNewDate.diff(curEndDate,'M',true)) 
      , yearDiff = mNewDate.diff(curEndDate,'year') 
    
      if ((monthDiff <= 0 && yearDiff === 0 && mNewDate.valueOf() <= curEndDate.valueOf()) // earlier same year
      || (yearDiff < 0 && newDate.valueOf() <= curEndDate.valueOf()) // in an earlier year, can't be prior to start date due to UI restrictions
    ) { // new date is within the current range
      // (bonus points if it can remember what these were so if the farmer extends the date back to the
      // same point it remembers the existing graph shape, but not necessary)
      this.previousGraphControls = this.graphControls.slice(); // save the current setup in case they try to go back to it
      this.previousDates = this.curDates.slice();

      // When a user modifies the end month/year, if they bring the date forward,
      // move the last point to the correct date and use the weight as it was at that date
      // as the new finish weight, drop any points from the section below that are now outside
      // (or duplicates) of the dates
      let idx = this.graphControls.findIndex(gc => gc.date.getTime() === newDate.getTime());
      let endWeight = 0;
      let num = this.graphControls.length - idx;
      if (idx === -1) { // not an existing defined point on graph
        this.newDate = mNewDate.toDate();
        this.addRow();
        idx = this.graphControls.findIndex(gc => gc.date.getTime() > newDate.getTime());
        num = this.graphControls.length - idx;
      } else {
        idx++;
      }
      this.graphControls.splice(idx, num);
      this.curDates.splice(idx, num);
    } else if (monthDiff <= 0 && yearDiff <= 0 && mNewDate.valueOf() > curEndDate.valueOf()) { // if just day changed and is now larger then prev
      this.graphControls[this.graphControls.length - 1].date = 
        moment.utc(this.graphControls[this.graphControls.length - 1].date).date(mNewDate.date()).toDate()
    } else { // new date is later than the current range
      if (this.previousGraphControls
        && this.previousGraphControls.length > 0
        && this.daysUntil(this.previousGraphControls[this.previousGraphControls.length - 1].date, newDate) === 0) {
        this.graphControls = this.previousGraphControls.slice();
        this.curDates = this.previousDates.slice();
      } else {
        // When a user modifies the end month/year, if they extend the date out, add a new last point at that date,
        // with points in between every 3 months, graph should extend with ADGs from the ADG of the current final point
        let totalMonths = monthDiff + (12 * yearDiff),
          curAdg = this.graphControls[this.graphControls.length - 1].adg.value,
          curWeight = this.graphControls[this.graphControls.length - 1].weight.value,
          nextDate = curEndDate,
          numDaysUntil, nextWeight;

          nextDate.add(3,'M')

        while (nextDate.valueOf() < mNewDate.valueOf()) {
          numDaysUntil = curEndDate.diff(nextDate,'d') 
          nextWeight = Rounder.performRounding({
            number: curWeight + (numDaysUntil * curAdg),
            roundTo: 2
          });
          this.graphControls.push(this.getNewGroupGraphPoint(this.graphControls.length, nextDate.toDate(), nextWeight));
          this.curDates.push(nextDate.toDate());

          curWeight = nextWeight;
          curEndDate = nextDate
          nextDate.add(3,'M')
        }
        numDaysUntil = curEndDate.diff(newDate, 'd') 
        nextWeight = curWeight + (numDaysUntil * curAdg);
        this.graphControls.push(this.getNewGroupGraphPoint(this.graphControls.length, newDate, nextWeight));
        this.curDates.push(mNewDate.toDate());
        this.curDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      }

      this.previousGraphControls = []; // clear the cached values
    }
    this.curDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    this.curDates = this.curDates.slice();
    this.graphControls.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.graphControls = this.graphControls.slice();
    this.redrawGraph();
  }

  startWeightChanged(newWeight: number) {
    let curStartWeight = this.graphControls[0].weight.value;
    let weightDiff = newWeight - curStartWeight;
    this.graphControls.forEach(gc => gc.weight.setValue(gc.weight.value + weightDiff));
    this.redrawGraph();
  }

  endWeightChanged(newWeight: number) {
    // When a user modifies the end weight, move the last point up to that weight,
    // and adjust all points in between relative to that point to spread the growth - graph should keep the same general shape
    let curStartWeight = this.graphControls[0].weight.value;
    let curEndWeight = this.graphControls[this.graphControls.length - 1].weight.value;
    let curWeightDiff = curEndWeight - curStartWeight;
    let newWeightDiff = newWeight - curStartWeight;

    let weightDiffRatio = newWeightDiff / curWeightDiff;

    this.graphControls.forEach(gc => {
      let idx = this.graphControls.findIndex(g => g.date.getTime() === gc.date.getTime());
      if (idx > 0) {
        gc.adg.setValue(Rounder.performRounding({
          number: gc.adg.value * weightDiffRatio,
          roundTo: 2
        }));
        let daysToWeigh = this.daysUntil(this.graphControls[idx - 1].date, gc.date);
        let newInterimWeight = Rounder.performRounding({
          number: (idx === this.graphControls.length - 1)
            ? newWeight
            : this.graphControls[idx - 1].weight.value + (gc.adg.value * daysToWeigh),
          roundTo: 2
        });
        gc.weight.setValue(newInterimWeight);
      }
    });

    this.redrawGraph();
  }

  rowWeightUpdated() {
    this.graphControls.forEach(gc => {
      let idx = this.graphControls.findIndex(g => g.date === gc.date);
      if (idx > 0) {
        // update the ADGs to reflect the weight specified
        gc.adg.setValue(this.calculateAdg(gc.weight.value, gc.date, this.graphControls[idx - 1]));
      }
    });
    this.redrawGraph();
  }

  save() {
    if (this.graphControls.length > 0) {
      this.graphData.startDate = this.graphControls[0].date;
    }
    let json = JSON.stringify(this.graphControls.map(e => { return { date: e.date, weight: e.weight.value }; }));
    this.group.targetWeightPlan.weightPlanPointsJson = json;
    this.graphData.weightPlanPointsJson = json;

    this.nextStep.next(this.group);
  }

  getNewGroupGraphPoint(curIndex: number = null, date: Date = null, weight: number = null): GroupGraphPoint {
    let adg = null;
    if ((!date || isNaN(date.getMonth())) && this.graphControls.length > 0) {
      date = this.graphControls[this.graphControls.length - 1].date ?
        new Date(this.graphControls[this.graphControls.length - 1].date) : new Date();
      date.setMonth(date.getMonth() + 1);
    } else if (!date || isNaN(date.getMonth())) {
      date = new Date();
    }
    if (!weight) {
      weight = this.graphControls[this.graphControls.length - 1] ?
        this.graphControls[this.graphControls.length - 1].weight.value + 5 : 5;
    }
    if (curIndex > 0) {
      let prev = this.graphControls[curIndex - 1];
      adg = this.calculateAdg(weight, date, prev);
    }
    return {
      buttonColor: '#35373A',
      weight: new FormControl(Rounder.round(weight)),
      adg: adg !== null && !isNaN(adg) && isFinite(adg) ? new FormControl(adg, [Validators.pattern(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)]) : null,
      date: new Date(date),
      animation: null,
      displayDate: `${date.getDate()} ${this.localeService.month[(date.getMonth())].fullMonth} ${date.getFullYear()}`
    };
  }

  ngAfterViewInit() {
    this.redrawGraph(null, true);
  }

  deleteRowClicked(index: number) {
    this.graphControls.splice(index, 1);
    this.curDates.splice(index, 1);
    this.redrawGraph();
  }

  adgChanged(contrInx: number) {
    if (contrInx > 0) { // should never be called but better be safe
      let prev = this.graphControls[contrInx - 1],
        cur = this.graphControls[contrInx];
      if (!cur.adg.value) {
        cur.adg.setValue(0);
      }

      cur.weight.setValue(Rounder.performRounding({
        number: (prev.weight.value + cur.adg.value * ((cur.date.getTime() - prev.date.getTime()) / 86400000))
      }));
      if (this.graphControls.length > contrInx + 1) {
        let next = this.graphControls[contrInx + 1];
        next.adg.setValue(Rounder.performRounding({
          number: (next.weight.value - cur.weight.value) / ((next.date.getTime() - cur.date.getTime()) / 86400000),
          roundTo: 2
        }));
      }
    }
    this.redrawGraph();
  }

  redrawGraph(control: GroupGraphPoint = null, init = false) {
    let curIndex = this.graphControls.indexOf(control);
    this.graphControls.forEach(e => e.clash = false);
    this.hasClashes = false;
    this.hasZeros = false;
    if (this.graphControls.length < 2) {
      this.sufficientPoints = false;
    } else {
      this.sufficientPoints = true;
    }

    this.graphControls = this.graphControls.sort((a, b) => {
      if (a.date.getTime() === b.date.getTime()) {
        this.hasClashes = true;
        a.clash = true;
        b.clash = true;
      }
      return new Date(a.date) > new Date(b.date) ? 1 : -1;
    });
    this.graphControls.forEach((gc, i) => {
      i === 0 ? gc.weight.setValidators(Validators.pattern(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) :
        gc.weight.setValidators(Validators.pattern(/^(\d+\.?\d{0,9}|\.\d{1,9})$/));
      gc.weight.updateValueAndValidity();
      if ((i === 0 && gc.weight.value < 0) || (i > 0 && gc.weight.value <= 0)) {
        this.hasZeros = true;
      }
    });

    let newIndex = this.graphControls.indexOf(control);
    if (control && curIndex !== newIndex) {
      control.animation = curIndex < newIndex ? 'up' : 'down';
      setTimeout(() => {
        control.animation = null;
      }, 400);
    }

    if (!this.graphControls || this.graphControls.length === 0) {
      return;
    }

    let xAxis: any = {
      type: 'datetime',
      tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
      dateTimeLabelFormats: {
        month: '%b %Y'
      },
      labels: {
        style: {
          fontFamily: '"Lato", Helvetica, Arial, sans-serif',
          fontSize: '12px'
        }
      },
      title: { text: this.localeService.constants.stringDate }
    },
      points = this.graphControls.map((point) => {
        return {
          x: point.date.getTime(),
          y: point.weight.value,
          fillColor: 'rgb(124, 181, 236)'
        };
      }),

      chartPoints = this.group && this.group.censuses ? this.group.censuses.filter(cp => cp.averageWeight).slice() : [];
    chartPoints.sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
    let series: Array<any> = [{
      id: 'average',
      name: this.localeService.constants.stringAverage,
      allowPointSelect: true,
      data: this.group && !this.action ? chartPoints.map(c => {
        if (c.averageWeight && c.averageWeight > 0) {
          return {
            x: new Date(c.timeStamp).getTime(),
            y: c.averageWeight,
            extra: [{
              order: 99
              , html: `<span style="color:black;">\u25CF</span> ${this.localeService.constants.stringAnimalCount}: ${c.animalCount}`
            }]
          };
        }
      }) || [] : (this.action && this.action.averageWeight > 0 ? [{
        x: new Date(this.action.sessionStartDate).getTime(),
        y: this.action.averageWeight
      }] : []),
      marker: { enabled: true, lineWidth: 2, lineColor: 'rgb(124, 181, 236)' },
      zIndex: 2,
      type: 'spline'
    }, {
      name: this.localeService.constants.stringTarget,
      allowPointSelect: false,
      data: points,
      marker: { enabled: true, lineColor: 'rgb(105, 179, 66);' },
      zIndex: 4,
      type: 'spline'
    }, {
      name: this.localeService.constants.stringMinToMax,
      data: chartPoints.map(point => [new Date(point.timeStamp).getTime(), point.minWeight, point.maxWeight]),
      type: 'areasplinerange',
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      linkedTo: 'average',
      color: 'rgb(149, 206, 255)',
      fillOpacity: 0.4,
      zIndex: 0
    }
    ];
    this.options = {
      xAxis: xAxis,
      yAxis: {
        title: {
          text: this.localeService.constants.stringTargetWeight
        },
        labels: {
          style: {
            fontFamily: '"Lato", Helvetica, Arial, sans-serif',
            fontSize: '12px'
          }
        },
        lineWidth: 1,
        min: 0
      },
      chart: {
        backgroundColor: 'transparent'
      },
      plotOptions: {
        series: {
          animation: this.options ? null : 500
        }
      },

      exporting: {
        enabled: false
      },
      series: series
    };

    if (this.group && this.group.censuses && this.group.censuses.map(c => c.timeStamp).toString() !== this.currentCensusTimestamps) {
      this.currentCensusTimestamps = this.group.censuses.map(c => c.timeStamp).toString();
    }
    // else if (!init && !this.hasClashes && !this.hasZeros && this.sufficientPoints) {
    //   if (this.saveQue) {
    //     clearTimeout(this.saveQue);
    //   }
    //   this.saveQue = setTimeout(() => {
    //     this.save();
    //   }, 3000);
    // }
  }

  daysUntil(from: Date, to: Date) {
    let diff = Math.abs(to.getTime() - from.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  calculateAdg(weight: number, date: Date, prev: GroupGraphPoint) {
    let res = Rounder.performRounding({
      number: (weight - prev.weight.value)
        / ((date.getTime() - prev.date.getTime()) / 86400000),
      roundTo: 2
    });
    return res;
  }

}


export class GroupGraphPoint {
  date: Date;
  weight: FormControl;
  adg: FormControl;
  animation: string = null;
  buttonColor = '#35373A';

  displayDate = '';

  clash ? = false;

  constructor() { this.buttonColor = '#35373A'; }
}


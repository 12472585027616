import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {PermissionsService} from '../../../auth/permissions.service';
import {Group} from '../../models/group.model';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';
import {LocaleService} from '../../../helpers/locale.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {InviteModalComponent} from '../../invite/invite-modal/invite-modal.component';
import {InvitationFarmRoleId} from '../../models';
import {PageActionsComponent} from '../../../shared/page-actions/page-actions.component';
import {GroupActionsEnum} from '../group-actions.enum';

@Component({
  selector: 'app-groups-actions',
  templateUrl: './groups-actions.component.html'
})
export class GroupsActionsComponent extends PageActionsComponent {
  @Input() allGroups: Group[];
  @Output() actionDone: EventEmitter<GroupActionsEnum> = new EventEmitter<GroupActionsEnum>();

  constructor(public permissionsService: PermissionsService,
              private confirmationService: ConfirmationService,
              private localeService: LocaleService,
              private bottomNotificationsService: BottomNotificationsService,
              private createNewGroupService: CreateNewGroupService,
              private ngbModal: NgbModal) {
    super();
  }

  newGroup(): void {
    this.createNewGroupService.createGroup().result.then((group: Group) => {
      this.actionDone.emit(GroupActionsEnum.CREATED);
    }, () => {
      this.actionDone.emit(GroupActionsEnum.NOT_CREATED);
    });
  }

  shareGroups(): void {
    const modalRef: NgbModalRef = this.ngbModal.open(InviteModalComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    modalRef.componentInstance.groups = this.allGroups;
    modalRef.componentInstance.defaultFarmRoleId = InvitationFarmRoleId.GroupReport; // 3

    modalRef.result.then(() => {
      this.actionDone.emit(GroupActionsEnum.SHARED);
    }, () => {
      this.actionDone.emit(GroupActionsEnum.NOT_SHARED);
    });
  }
}

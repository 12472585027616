<div class="dashboard-education">

  <div class="card">

    <div class="card-body">
      <div class="d-flex">
        <div class="p-2 image-container">
          <img *ngIf="image" src="./assets\custom-icons\{{image}}" alt="">
        </div>
        <div class="text-container">
          <h1>{{titleText}}</h1>
          <div class="">
            <span>{{text}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer text-center">
      <button class="btn btn-primary" [routerLink]="link">{{buttonText}}</button>
    </div>

  </div>

</div>

import {Component, OnInit, OnDestroy, NgZone, AfterViewInit, Renderer2, Input} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, combineLatest} from 'rxjs';

import {LocaleService} from '../../../helpers/locale.service';
import {Animal, WeightRecord} from '../../models';
import {MainService} from '../../main.service';
import {AnimalService} from '../../api/animal.service';
import {WeightRecordService} from '../../api/weight-record.service';
import {RowHeader} from '../../models/report-header.model';
import {Rounder} from '../../../helpers/rounder.helper';
import {FloatingHeaderHelper} from '../../../helpers/floating-header.helper';
import {ReportsService} from '../../reports/reports.service';
import {FarmService} from '../../api/farm.service';
import {ApiService} from '../../api/api.service';
import {SessionService} from '../../api/session.service';
import {SessionWeightSummary} from '../../models/session-weight-summary';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {ScriptService} from '../../script.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import { Location } from '@angular/common';
import { DatatypeHelper } from '../../../helpers/datatype.helper';

declare var $: any;

@Component({
  selector: 'app-animal-details',
  templateUrl: './animal-details.component.html',
  styleUrls: ['./animal-details.component.scss']
})
export class AnimalDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() animal: Animal;
  @Input() sessions: Array<SessionWeightSummary>;
  @Input() weightRecordIds: Array<number>;

  headers: Array<RowHeader>;
  curHeaders: Array<RowHeader>;
  rows: Array<any> = [];
  scrollSet = false;

  private farmSubscription: Subscription;
  private animalSub: Subscription;

  sortDetails: any = null;
  sortField: string = null;
  sortOrder: any = null;
  isMobile: boolean;

  constructor(private route: ActivatedRoute,
              private mainService: MainService,
              public globalParameterService: GlobalParameterService,
              private animalService: AnimalService, title: Title,
              private router: Router,
              private localeService: LocaleService,
              public apiService: ApiService,
              private weightRecordService: WeightRecordService,
              private sessionService: SessionService,
              private scriptService: ScriptService,
              private bottomNotificationsService: BottomNotificationsService,
              private ngZone: NgZone,
              public reportsService: ReportsService,
              private farmService: FarmService,
              private renderer: Renderer2,
              private location: Location) {
    title.setTitle(`${this.localeService.constants.stringAnimalDetails} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.scriptService.load('alasql', 'jszip', 'jspdf').then(data => {
      this.scriptService.load('jspdfAutotable').then(laterData => {
      });
    });
  }

  ngOnInit() {
    this.downloadData();

    setTimeout(() => {
      $(window).on('scroll');
      $(window).scroll(event => {
        this.scrollSet = FloatingHeaderHelper.floatHeaderOnScroll(this.scrollSet, this.renderer);
        FloatingHeaderHelper.updateColumnWidths(this.renderer);
      });
      FloatingHeaderHelper.CleanUpTable(this.renderer);
    });

    this.isMobile = this.mainService.isMobile();
  }

  ngAfterViewInit() {
    $('.ui-table-wrapper').scroll(event => {
      setTimeout(
        <TimerHandler><unknown>FloatingHeaderHelper.clipFloatingHeader($('.ui-table-wrapper').scrollLeft(), this.renderer)
        , 1000
      );
    });

    $('.ui-paginator').click(event => {
      FloatingHeaderHelper.removeFakeRows(this.renderer);
      FloatingHeaderHelper.updateColumnWidths(this.renderer);
    });

    $('.ui-table-thead').click(event => {
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy() {
    if (this.animalSub) {
      this.animalSub.unsubscribe();
    }
    if (this.farmSubscription) {
      this.farmSubscription.unsubscribe();
    }

    setTimeout(() => {
      $(window).off('scroll');
    });
  }

  sortEvent(event: any) {
    if (event && event.field && event.order) {
      this.sortDetails = event;
    }
  }

  dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'D MMM YYYY');
  }

  changeAnimal() {
    if (this.sortDetails) {
      this.reportsService.sortData(this.sortDetails, this.rows);
      this.sortField = this.sortDetails.field;
      this.sortOrder = this.sortDetails.order;
    }
  }

  downloadData() {
    this.processData(this.animal.weightRecords.filter(wr => wr.session_SessionId));
    return;
  }

  processData(data: Array<WeightRecord>) {
    if (!data) { return; }
    data.sort((a, b) => b.timeStamp.getTime() - a.timeStamp.getTime());
    this.headers = [{ header: this.localeService.constants.stringSessionName, field: 'SessionName', category: this.localeService.constants.stringSessionData }
      , { header: this.localeService.constants.stringSessionDate, field: 'SessionDate', category: this.localeService.constants.stringSessionData, type: 2 }
      , { header: this.localeService.constants.stringWeight, field: 'Weight', category: this.localeService.constants.stringSessionData }];
    this.rows = [];
    let showWeight = false;

    for (let i = 0, l = data.length; i < l; i++) {
      let row = {};
      let session = this.sessions.find(s => s.sessionId === data[i].session_SessionId);
      row['SessionName'] = session ? session.sessionName : '';
      row['SessionDate'] = session ? session.sessionStartDate : '';
      row['SessionId'] = session ? session.sessionId : '';

      this.animal.customAnimalIdentifiers.forEach(cid => {
        row[cid.name] = cid.rawValue !== null ? cid.rawValue : cid.value;
        if (!this.headers.find(h => h.header === cid.name)) {
          this.headers.push({ header: cid.name, field: cid.name })
        }
      });
      if (data[i].weight !== null) {
        row['Weight'] = Rounder.round(data[i].weight);
        showWeight = true;
      }


      if (data[i].animal !== null) {
        this.reportsService.extend(row, JSON.parse(this.animal.userDefinedFieldsJson)
          , this.headers, this.localeService.constants.stringLifeData);
      }
      if (data[i].userDefinedFieldsJson !== null) {
        this.reportsService.extend(row, JSON.parse(data[i].userDefinedFieldsJson)
          , this.headers, this.localeService.constants.stringSessionData);
      }

      this.rows.push(row);
    }

    this.headers = FloatingHeaderHelper.reformatHeaderSpaces(this.headers);

    if (this.mainService.farmChanged.value.fieldDisplayOrderForAnimalDetails !== null) {
      let displayLifeData = <Array<RowHeader>>JSON.parse(this.mainService.farmChanged.value.fieldDisplayOrderForAnimalDetails);
      this.curHeaders = this.headers.filter(f => f.category === this.localeService.constants.stringSessionData
        || displayLifeData.findIndex(lf => lf.field === f.field) >= 0).slice();
    } else {
      this.curHeaders = this.headers.filter(f => f.category === this.localeService.constants.stringSessionData).slice();
    }

    if (!showWeight && this.curHeaders) {
      this.curHeaders.splice(this.curHeaders.findIndex(ch => ch.field === 'Weight'), 1);
    }

    if (!this.sortDetails || !this.sortDetails.field || !this.sortDetails.order) {
      this.sortDetails = { 'data': this.rows, 'mode': 'single', 'field': 'SessionDate', 'order': -1 };
      this.reportsService.sortData(this.sortDetails, this.rows);
    }
    this.reportsService.sortData(this.sortDetails, this.rows);
    this.sortField = this.sortDetails.field;
    this.sortOrder = this.sortDetails.order;
  }

  columnsReordered(columns, something) {
    this.curHeaders = columns.map(e => {
      let matchingHeader = this.headers.find(h => h.field === e.field);
      return matchingHeader ? matchingHeader : <RowHeader>{ field: e.field, header: e.header }
    });
    this.saveOrder(this.curHeaders);
    FloatingHeaderHelper.fixHeaderWidths(this.renderer);
  }

  saveOrder(headers, scrollRight = false) {
    let displayLifeData: Array<any> = [],
      fieldChanged = false;
    if (this.mainService.farmChanged.value.fieldDisplayOrderForAnimalDetails !== null) {
      displayLifeData = <Array<RowHeader>>JSON.parse(this.mainService.farmChanged.value.fieldDisplayOrderForAnimalDetails);
    }
    for (let header of this.headers.filter(h => h.category === this.localeService.constants.stringLifeData)) {
      if (headers.findIndex(h => h.field === header.field) >= 0
        && displayLifeData.findIndex(h => h.field === header.field) === -1) {
        // add this field into display field
        fieldChanged = true;
        displayLifeData.push(header);
      } else if (headers.findIndex(h => h.field === header.field) === -1) {
        let idx = displayLifeData.findIndex(h => h.field === header.field);
        if (idx >= 0) {
          // remove this field from display field
          displayLifeData.splice(idx, 1);
          fieldChanged = true;
        }
      }
    }
    if (fieldChanged) {
      let fieldDisplayOrderForAnimalDetails = JSON.stringify(displayLifeData);
      this.farmService.updateFarm({ farmId: this.mainService.farmChanged.value.farmId, fieldDisplayOrderForAnimalDetails: fieldDisplayOrderForAnimalDetails })
        .subscribe(() => {
          this.mainService.farmChanged.value.fieldDisplayOrderForAnimalDetails = fieldDisplayOrderForAnimalDetails;
        });
    }
    setTimeout(() => {
      this.curHeaders = headers.slice();
      FloatingHeaderHelper.fixHeaderWidths(this.renderer);
    });
  }


  getDisplayName() {
    let idList: Array<string> = [];
    let a = this.animal;
    let displayName = '';
    const eid = a.customAnimalIdentifiers.find(n => n.internalName === 'EID'),
    vid = a.customAnimalIdentifiers.find(n => n.name === 'VID'),
    lid = a.customAnimalIdentifiers.find(n => n.internalName === 'LID'),
    fid = a.customAnimalIdentifiers.find(n => n.name === 'FID');
    if (eid && eid.value) {
      let val = eid.value;
      if (eid.rawValue) {
        val = eid.rawValue;
      }
      idList.push(val);
    }
    if (vid && vid.value) {
      idList.push(vid.value);
    }
    if (lid && lid.value) {
      idList.push(lid.value);
    }
    if (fid && fid.value) {
      idList.push(fid.value);
    }
    displayName = idList.splice(0, 1)[0];
    if (idList.length > 0) {
      displayName += ' (' + idList.join(', ') + ')';
    }
    if (!a.displayName || a.displayName.length === 0) {
      displayName = a.customAnimalIdentifiers[0].value + ' (' + a.customAnimalIdentifiers[0].name + ')';
    }

    this.animal.displayName = displayName;
    return this.animal.displayName;
  }
}

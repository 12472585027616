import {LocaleService} from '../../helpers/locale.service';
import {Component, OnInit} from '@angular/core';
import {Validators, FormGroup, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../auth.service';
import {FormValidation} from '../../helpers/form-validation.helper';
import {Title} from '@angular/platform-browser';
import {BottomNotificationsComponent} from '../../components/bottom-notifications/bottom-notifications.component';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  errorMessage = '';
  email = '';
  form: FormGroup;

  loading: boolean;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router
    , title: Title, private localeService: LocaleService, private bottomNotificationsService: BottomNotificationsService) {
    title.setTitle(`${this.localeService.constants.stringResetPassword} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
  }

  resetPassword(event) {
    if (this.form.valid) {
      const code = this.route.snapshot.params['code'],
        email = this.route.snapshot.params['email'];

      this.loading = true;
      this.authService.resetPassword(email,
        this.form.get('password').value, this.form.get('confirmPassword').value, code).subscribe(() => {
          this.authService.login(email, this.form.get('password').value).subscribe(res2 => {
              this.loading = false;
              this.router.navigate(['/main', 'dashboard']);
            },
            error => {
              this.loading = false;
              this.errorMessage = error.message;
            });
        },
        e => {
          this.loading = false;
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringResetPasswordFailed,
            message: this.localeService.constants.stringTokenInvalidOrExpiredResetPasswordAgain,
            type: 'warning'
          });
          this.errorMessage = e.error.innererror.length > 0 ? e.error.innererror[0] : e.error.message || 'Failed to reset password';
        });
    } else {
      this.form.controls['password'].markAsDirty();
      this.form.controls['confirmPassword'].markAsDirty();
    }

    event.preventDefault();
  }

  ngOnInit() {
    this.email = this.route.snapshot.params['email'];
    this.form = new FormGroup({
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.pattern(/\d+/)])),
      confirmPassword: new FormControl(''),
    }, FormValidation.validateConfirmPassword);
    this.form.valueChanges.subscribe(() => this.errorMessage = '');
  }

}

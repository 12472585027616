import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SelectItem} from 'primeng/components/common/selectitem';

import {FarmService} from '../../api/farm.service';
import {MainService} from '../../main.service';
import {Farm} from '../../models';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {AdminService} from '../../api/admin.service';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';

@Component({
  selector: 'app-admin-farms',
  templateUrl: './admin-farms.component.html',
  styleUrls: ['./admin-farms.component.scss'],
  providers: [AdminService]
})
export class AdminFarmsComponent implements OnInit {
  farms: Array<Farm>;
  farmTypes: Array<SelectItem> = [{ label: 'Standard', value: 'Standard' }, { label: 'Test', value: 'Test' }, { label: 'Demo', value: 'Demo' }];
  filteredUserId: string = null;

  cols: any[];

  constructor(private farmService: FarmService,
              private mainService: MainService,
              private confirmationService: ConfirmationService,
              private bottomNotificationsService: BottomNotificationsService,
              private route: ActivatedRoute,
              private adminService: AdminService) {
  }

  changeFarm(newFarm: any) {
    this.mainService.changeFarm(newFarm.farmId);
  }

  ngOnInit() {

    this.cols = [
      { field: 'farmId', header: 'Farm Id' },
      { field: 'farmName', header: 'Farm Name' },
      { field: 'country', header: 'Country' },
      { field: 'numUsers', header: 'Number of Users' },
      { field: 'created', header: 'Created' },
      { field: 'farmType', header: 'Type' }
    ];

    this.filteredUserId = this.route.snapshot.queryParams['userId'] || '';
    this.farms = this.mainService.farmsChanged.value;
    let filteredFarms: Array<Farm> = [];
    if (this.filteredUserId.length > 0) {
      filteredFarms = this.mainService.farmsChanged.value.filter(f => f.userFarms.filter(uf => uf.userId === this.filteredUserId).length > 0);
      this.farms = [...filteredFarms];
    }

  }

  farmTypeChanged(farm: Farm, newType: string) {
    if (farm.farmType !== newType) {
      setTimeout(() => { // zone bug bypass
        this.confirmationService.confirm({
          message: `Are you sure you would like to change ${farm.farmName}'s farm type to ${newType}?`
        }).subscribe(() => {
          this.doFarmTypeChange(farm, newType);
        });
      });
    }
  }

  doFarmTypeChange(farm: Farm, newType: string): void {
    this.farmService.updateFarm({ farmId: farm.farmId, farmType: newType }).subscribe(res => {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Success',
        message: 'Updated farm type'
      });
      farm.farmType = newType;
    }, res => {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Error occurred',
        type: 'warning',
        message: 'Unable to change farm type at this stage.'
      });
    });
  }

  setMassageEidsFlag() {
    this.adminService.setMassageEidsFlag().subscribe(res => {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Massage EIDs set',
        message: 'EID massage is set for tonight'
      });
    }, err => {
      this.bottomNotificationsService.currentMessage.next({
        title: 'Massage EIDs Failed',
        message: 'Unable to set EID massage for tonight',
        type: 'warning'
      });
    });
  }

  forceUpdateSpecificFarmIds(farm: Farm) {
    let doit = confirm('Are you sure you want to trigger an ID merge for ' + farm.farmName + '?\r\n\r\nThis can be a high intensity process for the server.');
    if (doit) {
      this.adminService.forceUpdateSpecificFarmIds(farm.farmId).subscribe(res => {
        this.bottomNotificationsService.currentMessage.next({
          title: 'IDs forced update',
          message: 'ID forced update has been triggered for ' + farm.farmName
        });
      }, err => {
        this.bottomNotificationsService.currentMessage.next({
          title: 'Massage IDs Failed',
          message: 'Unable force ID massage for ' + farm.farmName,
          type: 'warning'
        });
      });
    }
  }

}

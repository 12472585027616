<app-loading *ngIf="isLoading || (code && !invitation)"></app-loading>

<div *ngIf="!isLoading && (!code || (code && invitation !== null))">

  <div *ngIf="form" class="card card-external">

    <div class="card-body">

      <!-- logo -->
      <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

      <!-- form -->
      <form [formGroup]="form" autocomplete="off">
        <h3 i18n="@@registerForAnAccount">Register for an account</h3>

        <!-- form 1st row -->
        <div class="section row">

          <!-- first name -->
          <div class="col-xs-12 col-sm-6">
            <app-form-input [control]="form.get('firstName')"
                            [hideAsterisks]="true"
                            i18n-label="@@firstname" label="First Name"
                            i18n-error="@@validfirstnamerequired" error="A valid first name is required"
                            i18n-errorPattern="@@validfirstnamerequired" errorPattern="A valid first name is required">
              <input type="text" formControlName="firstName" class="form-control" tabindex="1">
            </app-form-input>
          </div>

          <!-- last name -->
          <div class="col-xs-12 col-sm-6">
            <app-form-input [control]="form.get('lastName')"
                            [hideAsterisks]="true"
                            i18n-label="@@lastname" label="Last Name"
                            i18n-error="@@validlastnamerequired" error="A valid last name is required"
                            i18n-errorPattern="@@validlastnamerequired" errorPattern="A valid last name is required">
              <input type="text" formControlName="lastName" class="form-control" tabindex="2">
            </app-form-input>
          </div>
        </div>

        <!-- form 2nd row -->
        <div class="section">

          <!--email -->
          <app-form-input [control]="form.get('email')"
                          [hideAsterisks]="true"
                          i18n-label="@@emailaddress" label="Email address"
                          i18n-error="@@emailerror" error="A valid email is required"
                          i18n-errorValidateEmail="@@emailerror" errorValidateEmail="A valid email is required">
            <input type="email" formControlName="email" class="form-control" tabindex="3">
          </app-form-input>
        </div>

        <hr>

        <!-- Address, Country, Phone Section section -->
        <div class="section">
          <app-form-input [control]="form.get('regionForMiHub')"
                          [hideAsterisks]="true"
                          [label]="localeService.constants.stringRegion"
                          i18n-error="@@regionisrequired" error="Region is required"
                          i18n-errorInvalidRegion="@@invalidregionselected" errorInvalidRegion="Invalid region selected">
            <ng-select
              formControlName="regionForMiHub"
              [items]="regions"
              [clearable]="false"
              tabindex="4">
            </ng-select>
          </app-form-input>
        </div>

        <div class="section row">
          <div class="col-sm-6">
            <app-form-input [control]="form.get('countryForMiHub')"
                            [hideAsterisks]="true"
                            i18n-label="@@country" label="Country"
                            i18n-error="countryisrequired" error="Country is required"
                            i18n-errorInvalidCountry="@@invalidcountryselected" errorInvalidCountry="Invalid country selected">
              <ng-select
                formControlName="countryForMiHub"
                [items]="countries"
                bindLabel="countryName"
                bindValue="countryName"
                [clearable]="false"
                (change)="regions = $event.regionsArray; form.controls['regionForMiHub'].setValue(null)"
                tabindex="5">
              </ng-select>
            </app-form-input>
          </div>

          <div class="section col-sm-6">
            <app-form-input [control]="form.get('phoneNumberForMiHub')"
                            [hideAsterisks]="true"
                            i18n-label="@@phonenumber" label="Phone Number"
                            i18n-error="@@phonenumberrequired" error="Phone Number is required"
                            i18n-errorPattern="@@phonenumberrequired" errorPattern="Phone Number is required">
              <input type="text" formControlName="phoneNumberForMiHub" class="form-control" tabindex="6" autocomplete="off">
            </app-form-input>
          </div>

        </div>

        <hr class="mt-0">

        <!-- Password section -->
        <div class="section row">

          <div class="col-md-6">
            <app-form-input [control]="form.get('password')"
                            [hideAsterisks]="true"
                            i18n-label="@@password" label="Password"
                            i18n-error="@@passwordrequiressixcharactersandonenumber" error="Password requires at least 6 characters and one number"
                            i18n-errorPattern="@@passwordrequiressixcharactersandonenumber" errorPattern="Password requires at least 6 characters and one number"
                            i18n-errorMinlength="@@passwordrequiressixcharactersandonenumber" errorMinlength="Password requires at least 6 characters and one number">
              <input type="password" formControlName="password" class="form-control" tabindex="7">
            </app-form-input>
          </div>
          <div class="col-md-6">
            <app-form-input [showError]="form.hasError('passwordMismatch') && form.controls['confirmPassword'].dirty"
                            [hideAsterisks]="true"
                            i18n-label="@@confirmpassword" label="Confirm Password"
                            i18n-errorPasswordMismatch="@@passwordsdonotmatch" errorPasswordMismatch="Passwords do not match">
              <input type="password" formControlName="confirmPassword" class="form-control" tabindex="8" [ngClass]="{'app-invalid': form.hasError('passwordMismatch')}">
            </app-form-input>
          </div>
        </div>

        <hr class="mt-0">

        <!-- Existing products section -->
        <div class="section">
          <h3 i18n="@@pleaseselectthetrutestdevicesyoucurrentlyhaveuse">Please select the Tru-Test devices you currently have/use</h3>
          <div class="row">
            <div class="col-6 col-sm-4 device-select" *ngFor="let device of deviceList; let i = index;">
              <label tabindex="{{i + 9}}" class="btn btn-block btn-default w100p" [class.btn-primary]="device.checked" [ngClass]="{'text-uppercase': isUpperCase(device.label)}">
                <input [ngModelOptions]="{standalone:true}" hidden id="{{device.id}}" type="checkbox" [(ngModel)]="device.checked"/>{{device.label}}
              </label>
            </div>
          </div>
        </div>

        <!-- show all errors -->
        <app-form-errors-sum [errors]="registerError"></app-form-errors-sum>

        <div class="custom-control custom-checkbox mt-3">
          <input id="tcCheckbox" type="checkbox" #tcCheckbox (change)="tcCheckboxClicked($event)" class="custom-control-input">
          <label tabindex="20" for="tcCheckbox" class="custom-control-label">
            <ng-container i18n="@@ihavereadandiagreetothe">I have read and I agree to the </ng-container>
            <a target="_blank" routerLink="/terms-and-conditions" class="theme-link" i18n="@@termsandconditions" tabindex="21">terms and conditions </a>
          </label>
        </div>

        <div class="my-2 text-danger" *ngIf="!tcCheckboxChecked && submitted">
          <strong i18n="@@youmusttickthatyouhavereadthetermsandconditions">You must tick that you have read the terms and conditions</strong>
        </div>

        <div class="custom-control custom-checkbox mt-3">
          <input id="ppCheckbox" type="checkbox" #ppCheckbox (change)="ppCheckboxClicked($event)" class="custom-control-input">
          <label tabindex="20" for="ppCheckbox" class="custom-control-label">
            <ng-container i18n="@@ihavereadandiagreetothe">I have read and I agree to the </ng-container>
            <a target="_blank" routerLink="/privacy-policy" class="theme-link" i18n="@@privacypolicy" tabindex="22">privacy policy</a>
          </label>
        </div>

        <div class="my-2 text-danger" *ngIf="!ppCheckboxChecked && submitted">
          <strong i18n="@@youmusttickthatyouhavereadtheprivacypolicy">You must tick that you have read the privacy policy</strong>
        </div>

        <!--        <div class="custom-control custom-checkbox mt-3">-->
        <!--          <input id="checkboxMarketingAgreed" type="checkbox" #checkboxMarketingAgreed (change)="marketingCheckboxClicked($event)" class="custom-control-input">-->
        <!--          <label tabindex="20" for="checkboxMarketingAgreed" class="custom-control-label">-->
        <!--            <ng-container i18n="@@iWouldLikeToReceiveOccasionalUpdatesForNewProductsOffersAndNews">I would like to receive occasional updates for new products, offers, and news</ng-container>-->
        <!--          </label>-->
        <!--        </div>-->

        <!--        <div class="custom-control custom-checkbox mt-3">-->
        <!--          <input id="checkboxMarketingAgreed" type="checkbox" #checkboxMarketingAgreed (change)="marketingCheckboxClicked($event)" class="custom-control-input">-->
        <!--          <label tabindex="20" for="checkboxMarketingAgreed" class="custom-control-label">-->
        <!--            <ng-container i18n="@@iWouldLikeToReceiveOccasionalUpdatesForNewProductsOffersAndNews">-->
        <!--              Pursuant to Federal Act on Data Protection (FADP), having read the privacy policy for purpose c), platform registration and with relation to the processing of personal data for purpose e) direct marketing-->
        <!--            </ng-container>-->
        <!--          </label>-->
        <!--        </div>-->

        <div class="mt-3">
          <label i18n="@@iWouldLikeToReceiveOccasionalUpdatesForNewProductsOffersAndNews">
            Pursuant to Federal Act on Data Protection (FADP), having read the <a target="_blank" routerLink="/privacy-policy">privacy policy</a>  for purpose c) platform registration<br><br>
            <strong>And with relation to the processing of personal data for purpose e) direct marketing</strong>
          </label>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" name="radioMarketingAgreed" id="radioMarketingAgreedYes" [value]="true" (change)="marketingRadioClicked(true)">
            <label class="custom-control-label" for="radioMarketingAgreedYes">I give my consent</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" name="radioMarketingAgreed" id="radioMarketingAgreedNo" [value]="false" (change)="marketingRadioClicked(false)">
            <label class="custom-control-label" for="radioMarketingAgreedNo">I don't give consent</label>
          </div>
        </div>

        <div class="my-2 text-danger" *ngIf="marketingAgreed === undefined && submitted">
          <strong i18n="@@youmusttickthatyouhavereadtheprivacypolicy">Select to give consent or not</strong>
        </div>

        <div class="mt-3 text-right">
          <button type="button" data-style="expand-down" (click)="registerNewUser();" class="btn btn-primary" tabindex="23">
            <span i18n="@@register">Register</span>
          </button>
        </div>

      </form>

      <div class="login-links text-center">
        <ng-container i18n="@@alreadyHaveAnAccount">Already have an account</ng-container>
        ? <a routerLink="/login" title="Sign In" class="active" i18n="@@signinhere">Sign in here</a>
      </div>

    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../main.service';
import { Title } from '@angular/platform-browser';
import { SessionService } from '../api/session.service';
import { GroupsService } from '../services/groups.service';
import { Observable } from 'rxjs';
import { ActionService } from '../api/action.service';
import { GroupCluster, SessionForCluster } from '../models/group-for-cluster.model';
import { combineLatest } from 'rxjs';
import { DatatypeHelper } from '../../helpers/datatype.helper';

@Component({
  selector: 'app-actionsv2',
  templateUrl: './actionsv2.component.html',
  styleUrls: ['./actionsV2.component.scss']
})
export class ActionsV2Component implements OnInit {
  groupClusters: Array<GroupCluster> = null;
  groupClustersNotConfident: Array<GroupCluster> = [];

  constructor(private route: ActivatedRoute, private mainService: MainService, title: Title
    , private sessionService: SessionService, private groupsService: GroupsService
    , private router: Router, private actionService: ActionService) { }

  ngOnInit() {
    this.mainService.farmChanged.subscribe(farm => {
        if (farm) {
          this.groupClustersNotConfident = [];
          this.groupClusters = [];
          combineLatest(this.sessionService.sessions, this.groupsService.groups, this.actionService.getMaGroups(farm.farmId)).subscribe(
          sgc => {
            let sessions = sgc[0];
            let groups = sgc[1];
            let clusters = sgc[2];
            this.groupClustersNotConfident = [];
            this.groupClusters = [];
            if (sessions && sessions.length > 0 && clusters && clusters.length > 0) {
              clusters.forEach(c => {
                if (c.groupId > 0 && groups) {
                  let group = groups.find(g => g.groupId === c.groupId);
                  if (group) {
                    c.groupName = group.displayName;
                    c.fakeGroup = false;
                  } else {
                    c.groupName = 'Fake Group';
                    c.fakeGroup = true;
                  }
                } else {
                  c.groupName = 'Fake Group';
                  c.fakeGroup = true;
                }
                if (c.sessionForClusters) {
                  c.sessionForClusters.forEach(s => s.sessionStartDate = new Date(s.sessionStartDate));
                }

                if (c.confident) {
                  this.groupClusters.push(c);
                } else {
                  let sessionsAssigned = c.sessionForClusters.filter(s => s.groupId !== null);
                  let sessionsUnAssigned = c.sessionForClusters.filter(s => s.groupId === null);
                  if (sessionsAssigned && sessionsAssigned.length > 0) {
                    let newGroup = new GroupCluster();
                    newGroup.groupId = c.groupId;
                    newGroup.groupName = c.groupName;
                    newGroup.scoreDetails = c.scoreDetails;
                    newGroup.fakeGroup = c.fakeGroup;
                    newGroup.sessionForClusters = sessionsAssigned;
                    this.groupClusters.push(newGroup);
                  }
                  if (sessionsUnAssigned && sessionsUnAssigned.length > 0) {
                    let newGroup = new GroupCluster();
                    newGroup.groupId = c.groupId;
                    newGroup.groupName = c.groupName;
                    newGroup.scoreDetails = c.scoreDetails;
                    newGroup.fakeGroup = c.fakeGroup;
                    newGroup.sessionForClusters = sessionsUnAssigned;
                    this.groupClustersNotConfident.push(newGroup);
                  }
                }
              })
            }
          });
        }
      }
    );
  }

  dayMonthYearString(date: string) {
    return DatatypeHelper.tryFormatDateWithMoment(date.toString(), 'D MMM YYYY');
  }
}


<div class="modal-header">
  <h3 i18n="@@activatesubscriptionallcaps">ACTIVATE SUBSCRIPTION</h3>
  <a (click)="closeDialog()" class="close"><i class="fas fa-times"></i></a>
</div>

<form [formGroup]="form">
  <div class="modal-body">
    <div>
      <ng-container i18n="Tru-Test is the company name; Starter Plan is capitalised@@enteryourtrutestdeviceserialnumbertoactivatethestarterplanforfree">
        Enter your Tru-Test device serial number to activate the Starter Plan for free
      </ng-container>.
    </div>

    <div class="row">
      <div class="col-6 mt-2">
        <ng-select
          formControlName="currentDeviceType"
          [items]="deviceList"
          bindLabel="label"
          [clearable]="false"
          appendTo="body">
          <ng-template ng-label-tmp let-item="item">
            {{ item.label | uppercase }}
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            {{ item.label | uppercase }}
          </ng-template>
        </ng-select>
      </div>

      <div class="col-6 mt-2">
        <input type="text" i18n-placeholder="@@serialnumber" placeholder="Serial Number" formControlName="serialNumber" class="form-control">
      </div>

    </div>
  </div>

  <!-- footer -->
  <div class="modal-footer">

    <ng-container *ngIf="errorCode.length === 0">
      <a href="javascript:void(0)" (click)="closeDialog()" class="btn btn-link" i18n="@@cancel">Cancel</a>
      <button class="btn btn-primary ml-1" [disabled]="!buttonEnabled" (click)="buttonEnabled = false; submitSerialNumber()" i18n="@@activate">Activate</button>
    </ng-container>

    <ng-container *ngIf="errorCode.length > 0">
      <div class="alert alert-danger dark alert-dismissable p12 mb-0 lh25 w100p" (click)="errorCode=''">
        <button type="button" data-dismiss="alert" aria-hidden="true" class="close posr mt5"></button>
        <i class="fa fa-exclamation-circle "></i>
        <strong>
          <ng-container i18n="@@error">Error</ng-container>
          : </strong>{{localeService.constants.stringInvalidSerial}}
      </div>
    </ng-container>

  </div>

</form>


<div class="card card-external card-login">

  <app-loading *ngIf="!form"></app-loading>
  <app-loading *ngIf="loading" [local]="true"></app-loading>

  <div class="card-body">

    <!-- logo -->
    <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

    <!-- form -->
    <form [formGroup]="form" *ngIf="form" (ngSubmit)="signOn($event)" autocomplete="on">

      <!-- email -->
      <app-form-input [control]="form.get('email')"
                      [hideAsterisks]="true"
                      i18n-label="@@emailaddress" label="Email address"
                      i18n-error="@@emailerror" error="A valid email is required"
                      i18n-errorValidateEmail="@@emailerror" errorValidateEmail="A valid email is required">
        <input type="email" autocomplete="true" autofocus formControlName="email" class="form-control" name="email">
      </app-form-input>

      <!-- password -->
      <app-form-input [control]="form.get('password')"
                      [hideAsterisks]="true"
                      i18n-label="@@password" label="Password"
                      i18n-error="@@passworderror" error="Password required">
        <input type="password" autocomplete="true" formControlName="password" class="form-control" name="password">
      </app-form-input>

      <div class="d-flex flex-align-items-center">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" (change)="authService.remember = $event.target.checked" id="checkboxExample14" type="checkbox" checked>
          <label for="checkboxExample14" class="custom-control-label" i18n="@@rememberme">Remember Me</label>
        </div>
        <button type="submit" class="btn btn-primary ml-auto"><span i18n="@@signin">Sign In</span></button>
      </div>

      <!-- show all errors -->
      <app-form-errors-sum [errors]="loginError"></app-form-errors-sum>

    </form>

    <div class="login-links text-center">
        <a routerLink="/forgotPassword" title="Forgot password" i18n="@@forgotpassword">Forgot Password?</a>
        <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
        <a routerLink="/register" title="Sign Up" i18n="@@signuphere">Sign up here</a>
    </div>

  </div>
</div>

import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie';

import {PermissionsService} from '../../auth/permissions.service';

@Component({
  selector: 'app-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent implements OnInit {
  @Input() buttonText: string;
  @Input() video: string | string[];
  @Output() onClick = new EventEmitter();

  cleanUrls: any[] = [];

  constructor(private domSanitizer: DomSanitizer, public permissionsService: PermissionsService, private cookieService: CookieService) {
  }

  ngOnInit() {
    if (this.video) {
      let lang = this.cookieService.get('locale') || 'en';

      if (typeof this.video === 'string') {
        this.video = [this.video];
      }

      this.video.forEach(video => {
        this.cleanUrls.push(this.domSanitizer.bypassSecurityTrustResourceUrl(video + `?rel=0&yt:cc=on&hl=${lang}&cc_lang_pref=${lang}&cc_load_policy=1`));
      });
    }
  }
}

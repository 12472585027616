import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';

import {GroupsService} from '../../../../services/groups.service';
import {SessionAction} from '../../../../models/session-action';
import {AnimalService} from '../../../../api/animal.service';
import {SessionService} from '../../../../api/session.service';
import {LocaleService} from '../../../../../helpers/locale.service';
import {ActionsSoldDeadService} from '../../../../actions/actions-sold-dead-modal/actions-sold-dead.service';
import {AnimalStatusEnum} from '../../../../animals/shared/animal.model';
import {ActionsModalService} from '../../../../actions/actions-modal/actions-modal.service';
import {AuthService} from '../../../../../auth/auth.service';
import {MainService} from '../../../../main.service';
import {PermissionsService} from '../../../../../auth/permissions.service';

@Component({
  selector: 'app-session-group-assignment-box',
  templateUrl: './session-group-assignment-box.component.html',
  styleUrls: ['./session-group-assignment-box.component.scss']
})
export class SessionGroupAssignmentBoxComponent implements OnInit {
  @Input() action: SessionAction;
  @Output() assignmentDone: EventEmitter<SessionAction> = new EventEmitter<SessionAction>();

  splitButtonSoldDeadItems: Array<any>;
  trigger = false;

  constructor(
    private localeService: LocaleService,
    public sessionService: SessionService,
    public groupsService: GroupsService,
    private animalService: AnimalService,
    public permissionsService: PermissionsService,
    private actionsModalService: ActionsModalService,
    private actionsSoldDeadService: ActionsSoldDeadService,
    private authService: AuthService,
    private mainService: MainService) {

  }

  ngOnInit() {
    this.splitButtonSoldDeadItems = [
      {
        label: this.localeService.constants.stringMarkAsSold, command: () => {
          this.markAsSold();
        }
      },
      {
        label: this.localeService.constants.stringMarkAsDied, command: () => {
          this.markAsDead();
        }
      }
    ];
    if (this.action.sessionStartDate) {
      this.mainService.actionDate = this.mainService.setDate(this.action.sessionStartDate);
    }
  }

  markAsSold() {
    this.mainService.actionDate = this.action.sessionStartDate ? this.mainService.setDate(this.action.sessionStartDate) : this.mainService.setDate();

    const modalRef: Observable<string> = this.actionsSoldDeadService.init(this.action, AnimalStatusEnum.sold);

    modalRef.subscribe(actionId => {
      this.soldDeadModalResult();
    });

    this.trigger = !this.trigger;
  }

  markAsDead() {
    this.mainService.actionDate = this.action.sessionStartDate ? this.mainService.setDate(this.action.sessionStartDate) : this.mainService.setDate();
    this.actionsSoldDeadService.init(this.action, AnimalStatusEnum.dead);
    this.trigger = !this.trigger;
  }

  soldDeadModalResult() {
    this.mainService.actionDate = this.mainService.setDate();
  }

  openAssignmentModal(action): void {
    this.actionsModalService.init(action).subscribe(res => {
      this.assignmentDone.emit(res);
    })
  }
}

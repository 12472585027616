import { Group } from './group.model';
export class Integration {
    integrationId?: number;
    integrationName: string;
    farm_FarmId;
    loginInfo?: string;
    authenticationFailed?: boolean;
    integratedDevices?: Array<IntegratedDevice>;
}

export class IntegratedDevice {
    integratedDeviceId: number;
    integratedDeviceName: string;
    drafter: number;
    integrations_integrationId: number;
    group_GroupId: number;
    lastSeen: Date;
    isActive: boolean;
    connected: boolean;
    group: Group; // calc field
    animalsYesterday: number;
    animals3Days: number;
    animalsWeek: number;
}

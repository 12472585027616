<!--<app-invite-modal #inviteModal [groups]="groups" [allInvitations]="invitations" defaultFarmRoleId="2" (invitations)="invitations=$event"></app-invite-modal>-->
<!--<app-edit-userinvitation #editUserInvitationModal [groups]="groups" [farmUsers]="farmUsers" [invitations]="invitations"></app-edit-userinvitation>-->
<!--<app-loading *ngIf="inviteModal.invitationSend"></app-loading>-->

<app-summary-header i18n-headerTitle="@@users" i18n-subHeaderTitle="@@inviteyourteamtoviewyourfarm" headerTitle="Users" subHeaderTitle="Invite your team to view your farm">

  <!-- invite user button -->
  <button *ngIf="farmUsers && invitations && permissionsService.permissions.farmUsersInvite" class="btn btn-primary"
          [class.disabled]="farmUsers.length + invitations.length >= 10 && currentSub && currentSub.subscriptionPlan_SubscriptionPlanId > 0"
          (click)="openInviteModal()" i18n="@@inviteUser">Invite User
  </button>

</app-summary-header>


<div class="page-content page-content-y">

  <!-- farm users -->
  <h3 class="page-heading" i18n="@@farmUsers">Farm Users</h3>
  <div class="card card-table mb-5">

    <div class="">

      <!-- farm users NOT loaded yet -->
      <app-loading *ngIf="!farmUsers" [local]="true"></app-loading>
      <app-loading *ngIf="loading"></app-loading>

      <!-- there are farm users -->
      <div *ngIf="farmUsers?.length > 0" class="table-responsive">
        <table cdk-table [dataSource]="farmUsers" class="table table-hover mb-0">

          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@name">Name</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">{{uf.firstName}} {{uf.lastName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="phonenumber">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@phonenumber">Phone Number</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">{{uf.phoneNumber}}</td>
          </ng-container>

          <ng-container cdkColumnDef="email">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@email">Email</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">{{uf.email}}</td>
          </ng-container>

          <ng-container cdkColumnDef="role">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@role">Role</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">

              <ng-container *ngIf="uf.farmRoleId === 1" i18n="@@admin">Admin</ng-container>
              <ng-container *ngIf="uf.farmRoleId === 2" i18n="@@user">User</ng-container>
              <ng-container *ngIf="uf.farmRoleId === 3">
                <ng-container i18n="@@groupviewer">Group Viewer</ng-container>
                (
                <a *ngIf="permissionsService.permissions.farmUsersRemove" (click)="openEditInvitationModal(uf)">
                  <span *ngIf="uf.groupIdsJson === '[]'">0 {{localeService.constants.stringGroups}}</span>
                  <span *ngIf="uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length === 1">1 {{localeService.constants.stringGroup}}</span>
                  <span *ngIf="uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length !== 1">{{uf.groupIdsJson.split(',').length}} {{localeService.constants.stringGroups}}</span>
                </a>
                <span *ngIf="!permissionsService.permissions.farmUsersRemove && uf.groupIdsJson === '[]'">0 {{localeService.constants.stringGroups}}</span>
                <span *ngIf="!permissionsService.permissions.farmUsersRemove && uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length === 1">1 {{localeService.constants.stringGroup}}</span>
                <span *ngIf="!permissionsService.permissions.farmUsersRemove && uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length !== 1">
            {{uf.groupIdsJson.split(',').length}} {{localeService.constants.stringGroups}}
          </span>
                )
              </ng-container>

            </td>
          </ng-container>

          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef></th>
            <td class="table-actions" cdk-cell *cdkCellDef="let uf">

              <ng-container *ngIf="uf.farmRoleId !== 1 && permissionsService.permissions.farmUsersRemove">
                <a class="action-icon-holder" (click)="openEditInvitationModal(uf)">
                  <i class="fas fa-pencil"></i>
                </a>
                <a class="action-icon-holder danger" (click)="removeUser(uf)">
                  <i class="fas fa-trash"></i>
                </a>
              </ng-container>

            </td>
          </ng-container>

          <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
          <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>

      <!-- there are NO farm users -->
      <div *ngIf="farmUsers?.length === 0">
        <h5 i18n="@@nofarmusers">No farm users</h5>
      </div>

    </div>

  </div>

  <!-- invited users -->
  <h3 class="page-heading" i18n="@@invitedUsers">Invited Users</h3>
  <div class="card card-table" *ngIf="permissionsService.permissions.farmUsersView || (authService.currentUser && authService.currentUser.userType === 'SuperUser')">

    <div class="">

      <!-- invited users NOT loaded yet -->
      <app-loading *ngIf="!invitations" [local]="true"></app-loading>

      <!-- there are invited users -->
      <div *ngIf="invitations?.length > 0" class="table-responsive">
        <table cdk-table [dataSource]="invitations" class="table table-hover mb-0">

          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@name">Name</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">{{uf.firstName}} {{uf.lastName}} <span class="badge badge-pill badge-warning" i18n="@@pending">Pending</span></td>
          </ng-container>

          <ng-container cdkColumnDef="email">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@email">Email</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">{{uf.email}}</td>
          </ng-container>

          <ng-container cdkColumnDef="phonenumber">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@phonenumber">Phone Number</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">{{uf.phoneNumber}}</td>
          </ng-container>

          <ng-container cdkColumnDef="role">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container i18n="@@role">Role</ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let uf">

              <ng-container *ngIf="uf.farmRoleId === 1" i18n="@@editor">Admin</ng-container>
              <ng-container *ngIf="uf.farmRoleId === 2" i18n="@@user">User</ng-container>
              <ng-container *ngIf="uf.farmRoleId === 3">
                <ng-container i18n="@@groupviewer">Group Viewer</ng-container>
                (
                <a *ngIf="permissionsService.permissions.farmUsersRemove" (click)="openEditInvitationModal(uf)">
                  <span *ngIf="uf.groupIdsJson === '[]'">0 {{localeService.constants.stringGroups}}</span>
                  <span *ngIf="uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length === 1">1 {{localeService.constants.stringGroup}}</span>
                  <span *ngIf="uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length !== 1">{{uf.groupIdsJson.split(',').length}} {{localeService.constants.stringGroups}}</span>
                </a>
                <span *ngIf="!permissionsService.permissions.farmUsersRemove && uf.groupIdsJson === '[]'">0 {{localeService.constants.stringGroups}}</span>
                <span *ngIf="!permissionsService.permissions.farmUsersRemove && uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length === 1">1 {{localeService.constants.stringGroup}}</span>
                <span *ngIf="!permissionsService.permissions.farmUsersRemove && uf.groupIdsJson !== '[]' && uf.groupIdsJson.split(',').length !== 1">
              {{uf.groupIdsJson.split(',').length}} {{localeService.constants.stringGroups}}
            </span>
                )
              </ng-container>

            </td>
          </ng-container>

          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef></th>
            <td class="table-actions" cdk-cell *cdkCellDef="let uf; let i = index;">

              <ng-container *ngIf="permissionsService.permissions.farmUsersRemove">
                <a class="action-icon-holder" (click)="openEditInvitationModal(uf)">
                  <i class="fas fa-pencil"></i>
                </a>
                <a class="action-icon-holder danger" (click)="removeInvitation(uf, i)">
                  <i class="fas fa-trash"></i>
                </a>
              </ng-container>

            </td>
          </ng-container>

          <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
          <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>

      <!-- there are NO invited users -->
      <div *ngIf="invitations?.length === 0">
        <h5 i18n="@@noinvitedusers" class="empty-table">No invited users</h5>
      </div>

    </div>

  </div>
</div>

import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription,} from 'rxjs';
import {combineLatest} from 'rxjs';

import {IntegrationService} from '../../api/integration.service';
import {MainService} from '../../main.service';
import {Integration, IntegratedDevice} from '../../models/integration';
import {GroupsService} from '../../services/groups.service';
import {Group} from '../../models/group.model';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IntegrationModelComponent} from './integration-model/integration-model.component';
import {ActionGroupSelectionComponent} from '../../actions/action-group-selection/action-group-selection.component';
import { DatatypeHelper } from '../../../helpers/datatype.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-ppl-integration',
  templateUrl: './ppl-integration.component.html',
  styleUrls: ['./ppl-integration.component.scss']
})
export class PplIntegrationComponent implements OnInit, OnDestroy {
  integration: Integration;
  loading: boolean;
  currentlyEditedIntegration: IntegratedDevice;
  currentlyEditedGroup: Group;
  stage = 0;

  private subs: Array<Subscription> = [];

  constructor(
    private integrationService: IntegrationService,
    private mainService: MainService,
    private groupsService: GroupsService,
    private bottomNotificationsService: BottomNotificationsService,
    private createNewGroupService: CreateNewGroupService,
    private ngbModal: NgbModal) {
  }

  ngOnInit() {
    this.loading = true;

    this.subs.push(combineLatest([this.integrationService.integration, this.groupsService.groups]).subscribe(params => {
      let groups = params[1];
      this.integration = params[0];
      if (groups && this.integration) {
        this.integration.integratedDevices.forEach(device => {
          if (device.group_GroupId) {
            device.group = groups.find(g => g.groupId === device.group_GroupId);
            // device.isActive = device.connected && !!device.lastSeen && new Date().getTime() - new Date(device.lastSeen).getTime() <= 86400000;
          }
        });
      }

      if (groups) {
        this.loading = false;
      }

    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe);
  }

  dayMonthYearString(date: Date) {
    return moment.tz(moment.utc(date).format(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland').local().format('D MMM YYYY');
  }


  assignNewGroup(group) {
    if (this.currentlyEditedIntegration && group) {
      this.integrationService.assignGroupToDevice(this.currentlyEditedIntegration.integratedDeviceId, group.groupId)
        .subscribe(res => {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Success',
            message: 'Paddock\'s group has been changed'
          });
        });
      this.currentlyEditedIntegration.group = group;
      this.currentlyEditedIntegration.group_GroupId = group.groupId;
    }
    this.currentlyEditedGroup = null;
    this.currentlyEditedIntegration = null;
  }

  openIntegrationModal(integration?: Integration): void {
    const modalRef: NgbModalRef = this.ngbModal.open(IntegrationModelComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    const component: IntegrationModelComponent = modalRef.componentInstance;

    if (integration) {
      component.integration = integration;
    }
  }

  openChangeGroupModal(integratedDevice: IntegratedDevice): void {
    const modalRef = this.ngbModal.open(ActionGroupSelectionComponent, {});

    const component: ActionGroupSelectionComponent = modalRef.componentInstance;

    component.group = integratedDevice.group;
    component.groupChosen.subscribe((group: Group) => {
      modalRef.dismiss();
      this.assignNewGroup(group);
    });

    modalRef.result.then(() => {

    }, () => {});
  }
}

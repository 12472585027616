<!--<p-dialog *ngIf="this.actionsSoldDeadService.action" [header]="this.actionsSoldDeadService.modalHeaderText"-->
<!--          [draggable]="false" [responsive]="true" [resizable]="false" [width]="window.innerWidth > 500? 500 : window.innerWidth * 0.9"-->
<!--          minWidth="350" [(visible)]="this.actionsSoldDeadService.visible" [modal]="true" class="ui-dialog-show-overflow" (onHide)="closeModal()">-->

<div class="modal-header">
  <h3>{{ actionsSoldDeadService.modalHeaderText }}</h3>
  <a (click)="dismissModal()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body">

  <!-- date selection -->
  <div *ngIf="actionsSoldDeadService.showDatePicker" class="form-group row no-gutters small-gutters">
    <label class="col-md-6 col-form-label">
      <ng-container i18n="@@statusDate">Status date</ng-container>
    </label>
    <div class="col-md-6">
      <input class="form-control" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="actionDate">
    </div>
  </div>

  <!-- if only fixedSetOfAnimals is selected -->
  <div *ngIf="actionsSoldDeadService.fixedSetOfAnimals">
    <button style="visibility: hidden">temp</button>
    <p *ngIf="actionsSoldDeadService.fixedSetBodyText">{{actionsSoldDeadService.fixedSetBodyText}}.</p>
  </div>

  <div *ngIf="!actionsSoldDeadService.fixedSetOfAnimals" class="pl10" [class.of-y-a]="splitFilterType === 1" [style.height]="(window.innerHeight * 0.9 - 185) + 'px'">

    <!-- all animals -->
    <div class="custom-control custom-radio">
      <input id="allAnimals" name="actions-selection-radio" [value]="false" type="radio" [(ngModel)]="showSplitTab" class="custom-control-input">
      <label class="custom-control-label" for="allAnimals">
        <span i18n="@@allAnimals">All animals</span>
      </label>
    </div>

    <!-- some animals -->
    <div class="custom-control custom-radio">
      <input id="someAnimals" name="actions-selection-radio" [value]="true" type="radio" [(ngModel)]="showSplitTab" class="custom-control-input">
      <label class="custom-control-label" for="someAnimals">
        <span i18n="@@someAnimals">Some animals</span>
      </label>
    </div>

    <!-- split component if some animals selected -->
    <div class="mt-4" *ngIf="showSplitTab">
      <app-actions-sold-dead-model-spliter (selectedFilter)="selectedFilterChanged($event)"></app-actions-sold-dead-model-spliter>
    </div>

  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-link" (click)="dismissModal()" i18n="@@cancel">Cancel</button>
  <button [disabled]="sendingUpdate" class="btn btn-primary ml-1" (click)="save()" i18n="@@update">
    Update
  </button>
</div>

<!--</p-dialog>-->

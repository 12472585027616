import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {AnimalService} from '../../api/animal.service';
import {LocaleService} from '../../../helpers/locale.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {ActionsSoldDeadService} from '../actions-sold-dead-modal/actions-sold-dead.service';
import {AnimalStatusEnum} from '../../animals/shared/animal.model';
import {ActionsModalService} from '../actions-modal/actions-modal.service';
import {SessionWeightSummary} from '../../models/session-weight-summary';
import {ActionsService} from '../actions.service';
import {MainService} from '../../main.service';
import {SessionAction} from '../../models/session-action';
import { DatatypeHelper } from '../../../helpers/datatype.helper';

@Component({
  selector: 'app-actions-box',
  templateUrl: './actions-box.component.html',
  styleUrls: ['./actions-box.component.scss']
})
export class ActionsBoxComponent implements OnInit {
  @Input() action: SessionAction;
  @Input() session: SessionWeightSummary;
  @Input() widget = false;

  @Output() disableEnableAction = new EventEmitter<boolean | 'DONE'>();

  splitButtonItems: Array<any>;
  splitButtonNoSoldItems: Array<any>;
  splitButtonIgnoreOnly: Array<any>;
  splitButtonSoldDeadIgnoreItems: Array<any>;

  constructor(
    private localeService: LocaleService,
    private actionService: ActionsService,
    private mainService: MainService,
    private animalService: AnimalService,
    private bottomNotificationsService: BottomNotificationsService,
    private actionsSoldDeadService: ActionsSoldDeadService,
    private actionsModalService: ActionsModalService) {
  }

  ngOnInit(): void {
    this.splitButtonItems = [
      {
        label: this.localeService.constants.stringMarkAsSold,
        command: () => this.markAsSold()
      },
      {
        label: this.localeService.constants.stringMarkAsDied,
        command: () => this.markAsDead()
      },
      {
        label: this.localeService.constants.stringChangeGroup,
        command: () => this.assignOrChange(this.action)
      }
    ];

    this.splitButtonNoSoldItems = [
      {
        label: this.localeService.constants.stringMarkAsDied,
        command: () => this.markAsDead()
      },
      {
        label: this.localeService.constants.stringChangeGroup,
        command: () => this.assignOrChange(this.action)
      }
    ];

    this.splitButtonIgnoreOnly = [
      {
        label: this.localeService.constants.stringIgnoreAction,
        command: () => this.ignoreAction()
      }
    ];

    this.splitButtonSoldDeadIgnoreItems = [
      {
        label: this.localeService.constants.stringMarkAsSold,
        command: () => this.markAsSold()
      },
      {
        label: this.localeService.constants.stringMarkAsDied,
        command: () => this.markAsDead()
      },
      {
        label: this.localeService.constants.stringIgnoreAction,
        command: () => this.ignoreAction()
      }
    ];
  }

  markAsSold() {
    this.disableEnableAction.next(false);
    this.mainService.actionDate = this.mainService.setDate(this.action.sessionStartDate);
    this.actionsSoldDeadService.init(this.action, AnimalStatusEnum.sold).subscribe(() => {
      // TODO OLD sessions will be updated through service (sessions behaviour subject)
      this.disableEnableAction.emit('DONE');
    }, () => {
      this.disableEnableAction.next(true);
    });
  }

  markAsDead() {
    this.disableEnableAction.next(false);
    this.mainService.actionDate = this.mainService.setDate(this.action.sessionStartDate);
    this.actionsSoldDeadService.init(this.action, AnimalStatusEnum.dead).subscribe(() => {
      // TODO OLD sessions will be updated through service (sessions behaviour subject)
      this.disableEnableAction.emit('DONE');
    }, () => {
      this.disableEnableAction.next(true);
    });
  }

  ignoreAction() {
    this.disableEnableAction.next(false);
    this.actionService.ignoreWeightRecords(this.action).subscribe(res => {
      // TODO OLD sessions will be updated through service (sessions behaviour subject)
      this.disableEnableAction.emit('DONE');
    }, err => {
      this.disableEnableAction.next(true);
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringIgnoreActionFailed,
        message: this.localeService.constants.stringIgnoreActionFailedMessage,
        type: 'warning'
      });
    });
  }

  assignOrChange(action): void {
    this.disableEnableAction.next(false);
    this.actionsModalService.init(action).subscribe(() => {
      // TODO OLD sessions will be updated through service (sessions behaviour subject)
      this.disableEnableAction.emit('DONE');
    }, () => {
      this.disableEnableAction.next(true);
    });
  }

  confirmAssignment() {
    // we have already assigned the WRs, user is confirming we were correct
    this.disableEnableAction.next(false);
    this.actionService.confirmAssignment(this.action).subscribe(res => {
      // TODO OLD sessions will be updated through service (sessions behaviour subject)
      this.disableEnableAction.emit('DONE');
    }, err => {
      this.disableEnableAction.next(true);
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringIgnoreActionFailedMessage,
        message: this.localeService.constants.stringApiErrorReloadPage,
        type: 'warning'
      });
    });
  }

  approveAssignment() {
    // we have not yet assigned the WRs, only suggested a group, and the user has approved our suggestion
    this.disableEnableAction.next(false);
    this.actionService.assignActionToRecommendedGroup(this.action, true).subscribe(res => {
      // TODO OLD sessions will be updated through service (sessions behaviour subject)
      this.disableEnableAction.emit('DONE');
      this.actionService.updateRecommendation();
    }, err => {
      this.disableEnableAction.next(true);
      let mess = '';
      if (this.action.groupId) {
        mess = (<string>this.localeService.constants.stringAnimalsCannotBeAssignedToTheGroupAtThisTimePleaseTryAgainLater)
          .replace('<animalCount/>', this.action.animalCount.toString())
          .replace('<groupDisplayName/>', this.action.recomendedGroup.displayName);
      } else {
        mess = (<string>this.localeService.constants.stringAnimalsCannotBeAssignedToTheNewGroupAtThisTimePleaseTryAgainLater)
          .replace('<animalCount/>', this.action.animalCount.toString());
      }
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringAnimalsAssignmentFailed,
        message: mess,
        type: 'warning'
      });
    });
  }

  dayMonthYearString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'D MMM YYYY');
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  Subscription } from 'rxjs';
import { Month } from './month';
import { LOCALE_ID } from '@angular/core';
import { ILocaleService } from '../mocks/interfaces/i-locale-service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

declare let $: any;

@Injectable()
export class LocaleService implements ILocaleService {
    public month: Array<Month>;
    public weekdays: Array<string>;
    public constants: any;

    private errors: Array<any>;
    private eventAuditTemplates: Array<any>;
    private today: 'Today';
    private version: string;
    public locale: string;
    public calendarLocale = {
        firstDayOfWeek: 0,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Junz', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        clear: 'Clear'
    };
    private breedsAndSexes: any;
    private breedsSexSpeciesSub: Subscription;
    private animalStatuses: any;


    constructor(private http: HttpClient, @Inject(LOCALE_ID) locale: string) {
        if (!locale) {
            locale = 'en';
        }
        this.locale = locale.substr(0, 2);
        let validLocales = ['en', 'fr', 'pt', 'es', 'de'];
        if (validLocales.indexOf(this.locale) < 0) {
            this.locale = 'en';
        }
        this.version = '?version=' + new Date().toISOString().replace(':', '-').replace(':', '-');
        try {
            let mainSrc = $('script[src^=main]')[0].src as string,
                startIndex = mainSrc.indexOf('main'),
                endIndex = mainSrc.indexOf('bundle');
            this.version = '?version=' + mainSrc.substr(startIndex + 5, endIndex - startIndex - 5);
        } catch (e) {
        }
        this.http.get(`./assets/locale/constants.${this.locale}.json${this.version}`).subscribe(e => {
            this.constants = e;
        });

        this.http.get(`./assets/locale/dates.${this.locale}.json${this.version}`).subscribe((e: any) => {
            let dates = e;
            this.month = dates.monthList;
            this.weekdays = dates.weekdayList;
            this.today = dates.today;
            this.setupCalendarLocale();
        });

        this.getBreedsSexAndSpeciesTranslation().subscribe(); // preload breeds and sexes to avoid mutliple loading when opening new group window.
    }

    private setupCalendarLocale() {
        this.calendarLocale = {
            firstDayOfWeek: 0,
            dayNames: this.weekdays,
            dayNamesShort: this.weekdays.map(wd => wd.substr(0, 3)),
            dayNamesMin: this.weekdays.map(wd => wd.substr(0, 2)),
            monthNames: this.month.map(m => m.fullMonth),
            monthNamesShort: this.month.map(m => m.month),
            today: this.today,
            clear: 'Clear'
        };
    }

    translateBreedSexArray(arrayToTranslate: Array<any>, paramToTranslate: string, breedOrSexArray: Array<any>) {
        arrayToTranslate.forEach(item => {
            const englishNames = Object.keys(breedOrSexArray);
            englishNames.forEach(englishName => {
                if (item[paramToTranslate] === englishName) {
                    item[paramToTranslate] = breedOrSexArray[englishName];
                }
            });
        });
    }

    getBreedsSexAndSpeciesTranslation() {
        if (this.breedsAndSexes) {
            return of(this.breedsAndSexes);
        }
        return this.http.get(`./assets/locale/breeds-and-sexes.${this.locale}.json${this.version}`).pipe(map(res => {
            this.breedsAndSexes = res;
            return this.breedsAndSexes;
        }));
    }

    getUploadErrors(): Observable<any> {
        if (!this.errors) {
            return this.http.get(`./assets/locale/error-messages.${this.locale}.json${this.version}`);
        } else {
            return of(this.errors);
        }
    }
    getEventAuditTemplates(): Observable<any> {
        if (!this.eventAuditTemplates) {
            return this.http.get(`./assets/locale/event-audit-templates.${this.locale.substr(0, 2)}.json${this.version}`);
        } else {
            return of(this.eventAuditTemplates);
        }
    }
}

<!--<p-dialog header="Select paddocks you would like to activate" [draggable]="false" class="ui-dialog-show-overflow" [responsive]="true"-->
<!--  [resizable]="false" [width]="window.innerWidth > 600? 600 : window.innerWidth" minWidth="350" [visible]="visible" [modal]="true"-->
<!--  (onHide)="integrationChanged.emit(integration)">-->

<div class="modal-header">
  <h3>Select paddocks you would like to activate</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body" *ngIf="loading">
  <app-loading [local]="true"></app-loading>
</div>

<div class="modal-body" *ngIf="!loading">
  <div class="card">
    <table class="table table-hover mb-0">
      <thead>
        <tr>
          <th>Device</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody *ngIf="devices">
        <tr *ngFor="let device of devices">
          <td>{{device.integratedDeviceName}}</td>
          <td>
            <div class="custom-control custom-checkbox">
              <input [(ngModel)]="device.isActive" id="checkbox_device_{{device.integratedDeviceId}}" type="checkbox" class="custom-control-input">
              <label for="checkbox_device_{{device.integratedDeviceId}}" class="custom-control-label"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <a class="btn btn-link" (click)="ngbActiveModal.dismiss()">Cancel</a>
  <button class="btn btn-primary ml-1" (click)="sendPpl()">Save</button>
</div>


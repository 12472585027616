import { Directive, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { LocaleService } from '../helpers/locale.service';

declare var $: any;
declare let moment: any;

@Directive({
  selector: '[appTimeSince]'
})
export class TimeSinceDirective implements OnChanges {
  @Input('appTimeSince')
  appTimeSince: Date;

  constructor(private el: ElementRef, private localeService: LocaleService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.appTimeSince && changes['appTimeSince'].currentValue) {
      if (moment(this.appTimeSince).isValid()) {
        if (moment.utc().isBefore(moment.utc(this.appTimeSince))) { // if the value is after right now
          this.el.nativeElement.innerText = moment.utc(this.appTimeSince).toNow();
        } else {
          this.el.nativeElement.innerText = moment.utc(this.appTimeSince).fromNow();
        }
      } else { // just display the original value
        this.el.nativeElement.innerText = this.appTimeSince;
      }
    }
  }
}

//reference: https://blog.usejournal.com/how-to-map-rest-api-data-using-decorator-pattern-in-angular-6-94eb49ba16b1
//http://niceprogrammer.com/angular-4-httpclient-mapping-using-decorators/
import * as moment from "moment-timezone";
import { ConvertToUTC } from "../../helpers/datatype.helper";


function _utcToLocalProperty(target:any, key:string, method:(v:string)=>any){
    function init(isGet:boolean){
        return function(newValue?:string){
            if(!Object.getOwnPropertyDescriptor(this,key)){
                let value: Date;

                const getter = function(){
                    return value;
                }
                const setter = function(val){
                    //value = moment(val,'YYYY-MM-DD').set({hour:0, minute:0, second:0}).toDate();
                    value = method(val)
                }
                Object.defineProperty(this, key, {
                    get: getter,
                    set: setter,
                    enumerable: true,
                    configurable: true
                    })
            }

            if (isGet) {
                return this[key]
            } else {
                this[key] = newValue
            }
        }
    }

    return Object.defineProperty(target,key, {
        get: init(true),
        set: init(false),
        enumerable:false,
        configurable:false
    });
}

export function utcToNZProperty(target:any, key:string){
    return _utcToLocalProperty(target, key, (val)=>{
        const dt= ConvertToUTC(val).toDate();
        return dt;
    });
    
}


export default function utcToLocalProperty(target:any, key:string){
    return _utcToLocalProperty(target, key, (val)=>ConvertToUTC(val).toDate());
}

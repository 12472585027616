<h3 class="page-heading mt-5" i18n="@@successfullyuploaded">Successfully Uploaded</h3>

<div class="card" *ngIf="uploadSummaries">
  <div class="table-responsive">
    <table class="table table-hover mb-0">
      <thead>
        <tr>
          <th>
            <ng-container i18n="@@filename">File Name</ng-container>
          </th>
          <th>
            <ng-container i18n="@@invalidrows">Invalid rows</ng-container>
          </th>
          <th>
            <ng-container i18n="@@recordsuploaded">Records uploaded</ng-container>
          </th>
          <th>
            <ng-container i18n="@@status">Status</ng-container>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let summary of uploadSummaries">
          <td>{{summary.fileName}}</td>
          <td class="text-danger">{{summary.invalidRowCount}}</td>
          <td>{{summary.rowsProcessed}}</td>
          <td>
            <span class="fa fa-check text-success"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

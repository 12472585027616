<ng-container *ngIf="template === 'DETAILS'; else listTemplate;">
  <button *ngIf="permissionsService.permissions.farmUsersInvite" class="btn btn-primary ml-2" (click)="shareGroups()" >
    <ng-container *ngTemplateOutlet="share"></ng-container>
  </button>
  <button *ngIf="permissionsService.permissions.canEditData" class="btn btn-primary ml-2" (click)="newGroup()">
    <ng-container *ngTemplateOutlet="newGroupTemplate"></ng-container>
  </button>
</ng-container>

<ng-template #listTemplate>
  <div ngbDropdown class="d-inline-block" (click)="$event.stopPropagation(); $event.preventDefault();" placement="bottom-right">
    <button class="btn btn-sm" ngbDropdownToggle></button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngIf="permissionsService.permissions.farmUsersInvite" (click)="shareGroups()">
        <ng-container *ngTemplateOutlet="share"></ng-container>
      </button>
      <button ngbDropdownItem *ngIf="permissionsService.permissions.canEditData" (click)="newGroup()">
        <ng-container *ngTemplateOutlet="newGroupTemplate"></ng-container>
      </button>
    </div>
  </div>
</ng-template>


<!-- buttons content templates -->

<ng-template #share>
  <i class="fas fa-share-alt"></i>
  <span i18n="@@share">Share</span>
</ng-template>

<ng-template #newGroupTemplate>
  <i class="fas fa-plus"></i>
  <span i18n="@@creategroup">Create Group</span>
</ng-template>

import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Validators, FormGroup, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {User} from '../../models/user.model';
import {AuthService} from '../../../auth/auth.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {MainService} from '../../main.service';
import {FarmService} from '../../api';
import {CountryService} from '../../api/countries.service';
import {Country, Farm} from '../../models';
import {LocaleService} from '../../../helpers/locale.service';

@Component({
  templateUrl: './farm-creation.component.html',
  styleUrls: ['./farm-creation.component.scss'],
  providers: [CountryService]
})
export class FarmCreationComponent implements OnInit, OnDestroy {
  countries: Array<Country>;
  regions: Array<string> = [];
  form: FormGroup;
  sub: Subscription;
  disableCreateButton = false;

  private opened = false;

  constructor(public ngbActiveModal: NgbActiveModal,
              private countryService: CountryService,
              private farmService: FarmService,
              private mainService: MainService,
              private router: Router,
              private bottomNotificationsService: BottomNotificationsService,
              private authService: AuthService,
              private localeService: LocaleService) {
  }

  generateForm(user: User) {
    let matchingCountry = this.countries.find(c => c.countryName === user.country);
    if (matchingCountry) {
      this.regions = matchingCountry.regionsArray;
    }
    this.form = new FormGroup({
      farmName: new FormControl('', [Validators.required, this.farmNameValidator(this)]),
      region: new FormControl(user.region ? user.region : '', Validators.required),
      country: new FormControl(matchingCountry, Validators.required)
    });
    this.disableCreateButton = false;
  }

  ngOnInit() {
    this.countryService.GetCountries().subscribe(countries => {
      if (this.sub) {
        this.sub.unsubscribe();
      }
      this.sub = this.authService.currentUserChanged.subscribe(user => {
        if (user) {
          this.countries = countries;
          for (let country of countries) {
            country.regionsArray = JSON.parse(country.regions) as Array<string>;
          }
          this.generateForm(user);
        }
      });
    });
  }

  createFarm() {
    this.disableCreateButton = true;
    if (this.form.valid) {
      let newFarm: any = { farmName: this.form.value['farmName'], region: this.form.value['region'], countries_CountryId: this.form.value['country'].countryId };
      this.farmService.SaveNewFarm(newFarm)
        .pipe(
          map((farm: Farm) => {

            // we don't load the userfarms upon farm creation, so change the object in memory
            this.authService.addNewFarmToCurrentUser(farm);
            this.authService.updateCurrentFarmOfUser(farm.farmId);
            return farm;
          }))
        .subscribe(created => {
          created.countries_CountryId = newFarm.countries_CountryId;
          created.countryLookup = this.form.value['country'];

          // this.mainService.addNewFarm(created);
          this.mainService.updateFarms(() => {

            this.bottomNotificationsService.currentMessage.next({
              title: this.localeService.constants.stringFarmSuccessfullyCreated,
              message: (<string>this.localeService.constants.stringFarmNameHasBeenCreated)
                .replace('<farmName/>', created.farmName)
            });
            this.mainService.changeFarm(created.farmId, null, true); // explicitly force a retrieval from server

            this.ngbActiveModal.close();
          });

          this.generateForm(this.authService.currentUser);

        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringFarmCreationFailed,
            message: (<string>this.localeService.constants.stringFarmNameCannotBeCreatedAtThisTimePleaseTryAgainLater)
              .replace('<farmName/>', this.form.controls['farmName'].value),
            type: 'warning'
          });
          this.disableCreateButton = false;
        });
    } else {
      this.form.controls['farmName'].markAsDirty();
      this.form.controls['country'].markAsDirty();
      this.form.controls['region'].markAsDirty();
      this.disableCreateButton = false;
    }
  }

  farmNameValidator(context: FarmCreationComponent) {
    return (control: FormControl): { [key: string]: boolean } => {
      if (context.mainService.farmsChanged.value && control.value && typeof control.value.toString().trim === 'function') {
        const displayName = control.value.toString().trim();
        let res = null;
        this.disableCreateButton = false;
        context.mainService.farmsChanged.value.forEach(f => {
          if (displayName.toUpperCase() !== '' && f.farmName.toUpperCase() === displayName.toUpperCase()) {
            res = { 'farmNameMatched': true };
            this.disableCreateButton = true;
          }
        });
        return res;
      }
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

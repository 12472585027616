import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { AuthService } from '../../../auth/auth.service';
import { Component, OnInit } from '@angular/core';

declare var errorNamespace: any;

@Component({
  selector: 'app-staff-testing',
  templateUrl: './staff-testing.component.html',
  styleUrls: ['./staff-testing.component.scss']
})
export class StaffTestingComponent implements OnInit {

  public errorFunctions: any;
  private errorNs: any;

  constructor(private bottomNotificationsService: BottomNotificationsService, public authService: AuthService) {
  }

  ngOnInit() {
    // this.errorFunctions = this.errorNs.getErrorFunctions();
  }

  throwError() {
    throw new Error('Test error');
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';
import { Integration, IntegratedDevice } from '../models/integration';
import { IntegrationImage } from '../models/integrationImage';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
    public integration = new BehaviorSubject<Integration>(null);
    public gettingImagesSub: Subscription;

    constructor(private apiService: ApiService) {

    }

    getIntegrations(farmId: number): Observable<Integration> {
        return this.apiService.get(`/odata/Integrations?$filter=farm_FarmId eq ${farmId}&$orderby=integrationId desc&$top=1&$expand=integratedDevices`)
            .pipe(
                map(res => res.value.length > 0 ? res.value[0] : null)
                , map(x=>{
                    if(x!==null){
                        this.integration.next(x);
                    }
                    return x;
                })
            );
    }

    private updateImages(farmId: number): Observable<boolean> {
        return this.apiService.get(`/odata/IntegrationImages/Services.UpdateImages(farmId=${farmId})`, 'text').pipe(map(res => {
            return true;
        }));
    }

    getPaddockIdsWithImages(farmId: number): Observable<Array<number>> {
        return this.apiService.get(`/odata/IntegrationImages/Services.GetPaddockIdsWithImages(farmId=${farmId})`)
            .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    getIntegrationImages(farmId: number, paddockId, week = 0): Observable<Array<IntegrationImage>> {
        return this.apiService.get(`/odata/IntegrationImages?$orderby=timeStamp desc&$filter=farm_FarmId eq ${farmId}`
            + ` and integratedDevices_IntegratedDeviceId eq ${paddockId}`
            + `&$skip=${week * 21}&$top=21`)
            .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    saveIntegration(integration: Integration): Observable<Integration> {
        return this.apiService.post(`/odata/Integrations?$expand=integratedDevices`, integration)
            .pipe(map(res => {
                let int = res;
                this.integration.next(int);
                this.gettingImagesSub = this.updateImages(integration.farm_FarmId).subscribe();
                return int;
            }));
    }

    updateIntegration(id: number, integration: any) {
        return this.apiService.patch(`/odata/Integrations(${id})?$expand=integratedDevices`, integration);
    }

    updateDevices(id: number, integratedDevices: Array<IntegratedDevice>): Observable<Integration> {
        return this.apiService.post(`/odata/Integrations/Services.UpdateDevicesStatus?$expand=integratedDevices`, { integrationId: id, integratedDevices: integratedDevices })
            .pipe(map(res => {
                this.integration.next(res);
                return res;
            }));
    }
    assignGroupToDevice(integratedDeviceId: number, groupId: number) {
        return this.apiService.post(`/odata/Integrations/Services.AssignGroupToDevice`, { integratedDeviceId: integratedDeviceId, groupId: groupId });
    }
}

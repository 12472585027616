export class Helper {
    /** Creates an instance of an object T from a JS object (Usialy recieved from the server)
     * Main use is to cast objects into base classes before returing them to patch and post methods
     * instead of having to delete extra vars.
     * @static
     * @template T
     * @param {T} obj New instance of base class (eg: new BaseSite())
     * @param {*} jsonObj Object from the server that has extra vars assignd to it. server would not accept it as is
     * @returns {T} New instance of base class with properties copied from jsonObj
     * @memberOf Helper
     */
    static instantiate<T>(obj: T, jsonObj: T): T {
        for (const propName in obj) {
            obj[propName] = jsonObj[propName];
        }

        return obj;
    }
}

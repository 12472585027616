import { Injectable, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { User } from './main/models/user.model';
import { AuthService } from './auth/auth.service';
import { CookieService } from 'ngx-cookie';

declare let moment: any;

@Injectable({
  providedIn: 'root'
})
export class GaService {

    constructor(private router: Router, private authService: AuthService, private cookieService: CookieService) {
    }

    logEvent(eventName: string, eventAction: string = null, extraVars: any = {}, gaEventLabel: string = null) {
        if (window['ga'] && this.authService.currentUserChanged.value
            && this.authService.currentUserChanged.value.userType.toLowerCase() !== 'superuser'
            && AuthService.emailsToIgnore.indexOf(this.authService.currentUserChanged.value.email.toLowerCase()) < 0
            && this.authService.currentUserChanged.value.email.toLowerCase().indexOf('test-e2e-') < 0) {
            if ((<any>window).ga && eventName !== 'pagenavigate') {
                (<any>window).ga('send', 'event', eventName, eventAction || 'no-page', gaEventLabel, extraVars);
            }
        }
    }

    updateUser(user: User) {
        if (window['ga'] && AuthService.emailsToIgnore.indexOf(user.email.toLowerCase()) < 0
            && (this.authService.currentUserChanged.value && this.authService.currentUserChanged.value.userType.toLowerCase()) !== 'superuser'
            && user.email.toLowerCase().indexOf('test-e2e-') < 0) {
            window['ga']('set', 'userId', user.userId);
            let dimensions: any = {};
            let plan = ''
            if (user.subscriptions.length > 0) {
                switch (user.subscriptions[user.subscriptions.length - 1].subscriptionPlan_SubscriptionPlanId) {
                    case 0:
                        plan = 'Staff'
                        break;
                    case 1:
                        plan = 'Trial'
                        break;
                    case 2:
                        plan = 'Standard'
                        break;
                }
            }
            // const plat = user.subscriptions.length > 0 ? user.subscriptions[user.subscriptions.length - 1].subscriptionPlan_SubscriptionPlanId
            window['ga']('send', 'pageview', {
                dimension1: this.authService.isDatamarsUser ? 'Staff' : 'Standard',
                dimension2: plan,
                dimension3: user.created ? moment(user.created).format('MM/YYYY') : ''
            });
        }
    }
}

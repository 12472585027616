import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { SessionGroupAutomation } from '../models/session-group-automation.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGroupService {

    constructor( private authService: AuthService, private apiService: ApiService) {
    }

    assignSessionActionToGroup(sessionIds: Array<number>, groupId: number,
                                  sessionGroupAutomation: Array<SessionGroupAutomation>, reloadCensuses = false, isBulkAllocation = false): Observable<any> {
        return this.apiService.post(
            `/odata/Sessions/Services.AssignSessionToGroup`, {
                sessionIds: sessionIds,
                groupId: groupId,
                sessionGroupAutomation: JSON.stringify(sessionGroupAutomation),
                reloadCensuses: reloadCensuses,
                isBulkAllocation: isBulkAllocation
            }, 'text');
    }

    getGroupAutomation(farmId: number, debug = false): Observable<Array<SessionGroupAutomation>> {
        return this.apiService.get( `/odata/Sessions/Services.GroupMatchesForFarm(farmId=${farmId},debug=${debug})`)
        .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    getGroupAutomationNewGroup(farmId: number, group: any, debug = false): Observable<Array<SessionGroupAutomation>> {
        return this.apiService.post (`/odata/Sessions/Services.GroupMatchesForNewGroup`
            , { farmId: farmId, debug: debug, group: JSON.stringify(group) }
            )
            .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }
}

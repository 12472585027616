<!--<app-invite-modal #inviteModal defaultFarmRoleId="3" [defaultGroup]="group"></app-invite-modal>-->

<ng-container *ngIf="template === 'DETAILS'; else listTemplate;">
  <button *ngIf="permissionsService.permissions.farmUsersInvite" class="btn btn-primary btn-regular" (click)="shareGroup()">
    <ng-container *ngTemplateOutlet="share"></ng-container>
  </button>
  <button *ngIf="permissionsService.permissions.canEditData" class="btn btn-secondary ml-2 btn-regular" (click)="editGroup()">
    <ng-container *ngTemplateOutlet="edit"></ng-container>
  </button>
  <button *ngIf="permissionsService.permissions.canEditData" class="btn btn-outline-danger ml-2 btn-regular" (click)="deleteGroup()">
    <ng-container *ngTemplateOutlet="remove"></ng-container>
  </button>
</ng-container>

<ng-template #listTemplate>
  <div ngbDropdown class="d-inline-block dropdown-ellipsis" (click)="$event.stopPropagation(); $event.preventDefault();" placement="bottom-right">
    <button class="btn btn-sm" ngbDropdownToggle>
      <i class="far fa-ellipsis-v"></i>
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngIf="permissionsService.permissions.farmUsersInvite" (click)="shareGroup()">
        <ng-container *ngTemplateOutlet="share"></ng-container>
      </button>
      <button ngbDropdownItem *ngIf="permissionsService.permissions.canEditData" (click)="editGroup()">
        <ng-container *ngTemplateOutlet="edit"></ng-container>
      </button>
      <button ngbDropdownItem *ngIf="permissionsService.permissions.canEditData" (click)="deleteGroup()">
        <ng-container *ngTemplateOutlet="remove"></ng-container>
      </button>
    </div>
  </div>
</ng-template>


<!-- buttons content templates -->

<ng-template #share>
  <i class="fas fa-share-alt"></i>
  <span i18n="@@share">Share</span>
</ng-template>

<ng-template #edit>
  <i class="fas fa-pen"></i>
  <span i18n="@@edit">Edit</span>
</ng-template>

<ng-template #remove>
  <i class="fas fa-trash"></i>
  <span i18n="@@delete">Delete</span>
</ng-template>

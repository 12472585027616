
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Group, BaseGroup } from '../../models/group.model';
import { WeightPlan } from '../../models/weight-plan.model';
import { LocaleService } from '../../../helpers/locale.service';
import { BreedService } from '../../api/breed.service';
import { SexService } from '../../api/sex.service';
import { SpeciesService } from '../../api/species.service';
import { MainService } from '../../main.service';
import { combineLatest } from 'rxjs';

import { Breed } from '../../models/breed.model';
import { Species } from '../../models/species.model';
import { Sex } from '../../models/sex.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GroupsService } from '../../services/groups.service';
import { CreateNewGroupService } from '../create-new-group.service';
import { Helper } from '../../../helpers/instantiate.helper';
import { IntercomService } from '../../intercom.service';
import { Router } from '@angular/router';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { WeightPlanService } from '../../api/weight-plan.service';
import { SessionAction } from '../../models/session-action';
import {WeightPlanTemplate} from '../../models/weight-plan-template.model';
import {GlobalLoaderService} from '../../../core/global-loader.service';


@Component({
  selector: 'app-new-group-finilisation',
  templateUrl: './new-group-finilisation.component.html',
  styleUrls: ['./new-group-finilisation.component.scss']
})
export class NewGroupFinilisationComponent implements OnInit {
  private static self: NewGroupFinilisationComponent;

  @Input() group: Group;
  @Input() sessionAction: SessionAction;
  @Input() templates: WeightPlanTemplate[] = [];
  @Input() step: number;
  @Input() assignAction: boolean;

  @Output() finishModifications = new EventEmitter();
  @Output() prevStepOrCancel: EventEmitter<'prev' | 'cancel'> = new EventEmitter();
  @Output() setCanClose: EventEmitter<true | false> = new EventEmitter();

  curBreed: Breed;
  visible = true;
  curSpecies: Species;
  curSex: Sex;
  form: FormGroup;
  window = window;
  disableCreateButton = false;
  showConfirmation = true;

  constructor(
    private mainService: MainService,
    public localeService: LocaleService,
    private breedService: BreedService,
    private router: Router,
    private sexService: SexService,
    private speciesService: SpeciesService,
    private groupsService: GroupsService,
    public createNewGroupService: CreateNewGroupService,
    private bottomNotificationsService: BottomNotificationsService,
    private intercomService: IntercomService,
    private weightPlanService: WeightPlanService,
    private globalLoaderService: GlobalLoaderService) {
    NewGroupFinilisationComponent.self = this;
  }

  ngOnInit() {
    combineLatest(this.breedService.getBreeds(this.mainService.farmChanged.value.countries_CountryId), this.sexService.getSexes()
      , this.speciesService.getSpecies()).subscribe(breedAndSex => {
        const breeds = breedAndSex[0], sexes = breedAndSex[1], species = breedAndSex[2];
        if (breeds && sexes && species) {
          this.curBreed = breeds.find(b => b.breedId === this.group.breed_BreedId);
          this.curSex = sexes.find(s => s.sexId === this.group.sex_SexId);
          this.curSpecies = species.find(s => s.speciesId === this.group.species_SpeciesId);

          this.form = new FormGroup({ groupName: new FormControl(this.group.groupName, [Validators.required, this.groupNameValidator(this)]) });

          if (this.form.controls.groupName && !this.form.controls.groupName.invalid) {
            this.showConfirmation = false;
            this.next();
          }
        }
      });
  }

  next() {
    this.showConfirmation = false;
    this.disableCreateButton = true;
    this.globalLoaderService.showGlobalLoader = true;

    let group = Helper.instantiate(new BaseGroup(), this.group),
      templateId = this.group.targetWeightPlan.targetWeightPlanTemplate_TargetWeightPlanTemplateId;

    group.groupName = this.form.controls['groupName'].value;
    group.targetWeightPlan = Helper.instantiate(new WeightPlan(), this.group.targetWeightPlan);
    group.targetWeightPlan.targetWeightPlanTemplate_TargetWeightPlanTemplateId = templateId > 0 ? templateId : null;

    if (this.sessionAction && this.assignAction) {
      let action = Helper.instantiate(new SessionAction(), this.sessionAction);
      action.recommendedGroupId = this.sessionAction.recomendedGroup ? this.sessionAction.recomendedGroup.groupId : null;
      if (group.groupId) {
        action.groupId = group.groupId;
      }
      group.v_Actions = [action];
    }

    if (!group.v_Actions) {
      delete group.v_Actions;
    }

    if (group.groupId) {
      delete group.censuses;

      this.weightPlanService.UpdateWeightPlan(group.targetWeightPlan).subscribe(res => {
        this.groupsService.updateGroup(group.groupId, group).subscribe(grp => {
          const displayName = GroupsService.GetGroupDisplayName(this.group.groupName, this.group.targetWeightPlan);
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringGroupSuccessfullyUpdated
            , message: `${displayName} ${this.localeService.constants.stringHasBeenUpdated}.`,
            type: 'success'
          });

          this.intercomService.logEvent('UpdateGroup', this.router.url, {
            groupname: displayName,
            groupid: grp.groupId,
            sessionCount: grp.censuses.length
          });


          // slight delay here to allow for reprocessing all Group data and displaying on Groups/Summary page
          setTimeout(() => {
            this.globalLoaderService.showGlobalLoader = false;
            this.finishModifications.next(grp)
          }, 1000);

        }, err => {
          this.disableCreateButton = false;
          this.globalLoaderService.showGlobalLoader = false;
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringGroupUpdateFailed,
            message: `${GroupsService.GetGroupDisplayName(this.group.groupName, this.group.targetWeightPlan)}`
              + ` ${this.localeService.constants.stringCannotBeUpdatedAtThisTimePleaseTryAgainLater}.`,
            type: 'warning'
          });
        })
      }, err => {
        this.disableCreateButton = false;
        this.globalLoaderService.showGlobalLoader = false;
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringGroupUpdateFailed,
          message: `${GroupsService.GetGroupDisplayName(this.group.groupName, this.group.targetWeightPlan)}`
            + ` ${this.localeService.constants.stringCannotBeUpdatedAtThisTimePleaseTryAgainLater}.`,
          type: 'warning'
        })
      });
    } else {
      delete group.groupId;
      delete group.targetWeightPlan.targetWeightPlanId;
      if (group.v_Actions) {
        delete group.v_Actions;
      }
      group.farm_FarmId = this.mainService.farmChanged.value.farmId;

      this.groupsService.saveGroup(group, []).subscribe(grp => {
        const displayName = GroupsService.GetGroupDisplayName(this.group.groupName, this.group.targetWeightPlan);
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringGroupSuccessfullyCreated
          , message: `${displayName} ${this.localeService.constants.stringHasBeenCreated}.`,
          type: 'success'
        });
        this.intercomService.logEvent('CreateGroup', this.router.url, {
          groupname: displayName,
          groupid: grp.groupId,
          sessionCount: grp.censuses.length,
          actions: group.v_Actions ? JSON.stringify(group.v_Actions) : null
        });

        this.globalLoaderService.showGlobalLoader = false;
        this.finishModifications.next(grp);
      }, err => {
        this.disableCreateButton = false;
        this.globalLoaderService.showGlobalLoader = false;
        const displayName = GroupsService.GetGroupDisplayName(this.group.groupName, this.group.targetWeightPlan);
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringGroupCreationFailed,
          message: `${displayName} ${this.localeService.constants.stringCannotBeCreatedAtThisTimePleaseTryAgainLater}.`,
          type: 'warning'
        });
      });
    }
  }

  groupNameValidator(context: NewGroupFinilisationComponent) {
    return (control: FormControl): { [key: string]: boolean } => {
      if (context.groupsService.groups.value && typeof control.value.toString().trim === 'function') {
        const displayName = GroupsService.GetGroupDisplayName(control.value.toString().trim(), this.group.targetWeightPlan);
        let res = null;
        this.disableCreateButton = false;
        this.globalLoaderService.showGlobalLoader = false;
        context.groupsService.groups.value.forEach(g => {
          if (g.groupId !== context.group.groupId && g.displayName.toUpperCase() === displayName.toUpperCase()) {
            res = { 'groupNameMatched': true };
            this.disableCreateButton = true;
            this.globalLoaderService.showGlobalLoader = false;
          }
        });
        return res;
      }
    }
  }
}

<div class="stepper-container">

  <!-- step 1 - name -->
  <div class="step">
    <i class="far" [ngClass]="getCircleClass(1)"></i>
    <div i18n="@@name">Name</div>
  </div>

  <!-- step 2 - templates -->
  <div class="step" *ngIf="templates && (templates.length > 0 || forcedTemplatesStepDisplay) && !group.groupId">
    <i class="far" [ngClass]="getCircleClass(2)"></i>
    <div i18n="@@template">Template</div>
  </div>

  <!-- step 2/3 - targets -->
  <div class="step">
    <i class="far" [ngClass]="getCircleClass(3)"></i>
    <div i18n="@@targets">Targets</div>
  </div>

</div>

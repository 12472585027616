<div class="row flex-align-items-center action-box">

  <div *ngIf="action.greyOut" [class.grey-out]="action.greyOut"></div>

  <!-- icon -->
  <div class="col-xl-1">
    <div class="action-icon" [ngClass]="action.groupId ? 'bg-success' : 'bg-warning'">
      <i class="fal" [class.fa-check]="action.groupId" [class.fa-bell]="!action.groupId" aria-hidden="true"></i>
    </div>
  </div>

  <div class="col-xl-11">

    <!-- assign to new group -->
    <div class="row flex-align-items-center" *ngIf="!action.groupId && !action.recomendedGroup">

      <!-- text -->
      <div class="action-text" [ngClass]="widget ? 'col-xl-7' : 'col-xl-9'">

        <!-- session name -->
        <div *ngIf="widget">
          <span i18n="@@sessionfile">Session file</span>&nbsp;
          '<a routerLink="/main/sessions/summary" [queryParams]="{id: session.sessionId}">{{session.sessionName}} ({{dayMonthYearString(session.sessionStartDate)}})</a>':
        </div>

        <!-- animals with any custom ID -->
        <span *ngIf="action.ided">
          {{action.animalCount}}&nbsp;
          <ng-container *ngIf="action.animalCount !== 1" i18n="@@newanimalsneedassigningtoagroup">new animals need assigning to a group</ng-container>
          <ng-container *ngIf="action.animalCount === 1" i18n="@@newanimalneedsassigningtoagroup">new animal needs assigning to a group</ng-container>
        </span>

        <!-- animals without any ID -->
        <span *ngIf="!action.ided">
          {{action.animalCount}}&nbsp;
          <ng-container *ngIf="action.animalCount !== 1" i18n="@@weightswithoutIDsneedassigningtoagroup">weights without IDs need assigning to a group</ng-container>
          <ng-container *ngIf="action.animalCount === 1" i18n="@@weightwithoutanIDneedsassigningtoagroup">weight without an ID needs assigning to a group</ng-container>
        </span>
      </div>

      <!-- buttons -->
      <div class="action-buttons text-right" [ngClass]="widget ? 'col-xl-5' : 'col-xl-3'">

        <button type="button" class="btn btn-primary" (click)="assignOrChange(action)" i18n="@@assign">Assign</button>

        <div class="btn-group" [class.btn-group-sm]="widget">
          <div class="btn-group" ngbDropdown role="group" placement="bottom-right" container="body">
            <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem *ngFor="let item of (action.ided ? splitButtonSoldDeadIgnoreItems : splitButtonIgnoreOnly)" (click)="item.command()">
                {{item.label}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- assign to selected -->
    <div class="row flex-align-items-center" *ngIf="!action.groupId && !!action.recomendedGroup">

      <!-- text -->
      <div class="action-text" [ngClass]="widget ? 'col-xl-7' : 'col-xl-9'">

        <!-- session name -->
        <div *ngIf="widget">
          <span i18n="@@sessionfile">Session file</span>&nbsp;
          '<a routerLink="/main/sessions/summary" [queryParams]="{id: session.sessionId}">{{session.sessionName}} ({{dayMonthYearString(session.sessionStartDate)}})</a>':
        </div>

        <span *ngIf="action.ided">{{action.animalCount}}&nbsp;<ng-container *ngIf="action.animalCount !== 1" i18n="@@newanimalsneedassigningtoagroup">new&nbsp;animals need assigning to a group.</ng-container>
          <ng-container *ngIf="action.animalCount === 1" i18n="@@newanimalneedsassigningtoagroup">new&nbsp;animal needs assigning to a group.</ng-container>
          &nbsp;<ng-container i18n="@@assigntogroup">Assign to group</ng-container> '<b>{{action.recomendedGroup.displayName}}</b>'?</span>
        <span *ngIf="!action.ided">{{action.animalCount}}&nbsp;
          <ng-container *ngIf="action.animalCount !== 1" i18n="@@weightswithoutIDsneedassigningtoagroup">weights without IDs need assigning to a group.</ng-container>
          <ng-container *ngIf="action.animalCount === 1" i18n="@@weightwithoutanIDneedsassigningtoagroup">weight without an ID needs assigning to a group.</ng-container>
          &nbsp;<ng-container i18n="@@assigntogroup">Assign to group</ng-container> '<b>{{action.recomendedGroup.displayName}}</b>'?</span>
      </div>

      <!-- buttons -->
      <div class="action-buttons text-right" [ngClass]="widget ? 'col-xl-5' : 'col-xl-3'">
        <button class="btn btn-primary " (click)="approveAssignment()" i18n="@@yes">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="assignOrChange(action)" i18n="@@change">Change</button>
        <div class="btn-group" [class.btn-group-sm]="widget">
          <div class="btn-group " ngbDropdown role="group"  placement="bottom-right" container="body">
            <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem *ngFor="let item of (action.ided ? splitButtonSoldDeadIgnoreItems : splitButtonIgnoreOnly)" (click)="item.command()">
                {{item.label}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- already assigned -->
    <div class="row flex-align-items-center" *ngIf="action.groupId && action.confirmationRequiredGroup">

      <!-- text -->
      <div class="action-text" [ngClass]="widget ? 'col-xl-7' : 'col-xl-9'">

        <!-- session name -->
        <div *ngIf="widget">
          <span i18n="@@sessionfile">Session file</span>&nbsp;
          '<a routerLink="/main/sessions/summary" [queryParams]="{id: session.sessionId}">{{session.sessionName}} ({{dayMonthYearString(session.sessionStartDate)}})</a>':
        </div>

        <div *ngIf="action.animalCount === 1">1&nbsp;<ng-container i18n="specify the group the animal has been assigned to@@animalupdatedingroup">animal updated in group</ng-container>
          '<b>{{action.confirmationRequiredGroup.displayName}}</b>'
        </div>
        <div *ngIf="action.animalCount !== 1">{{action.animalCount}}&nbsp;<ng-container i18n="specify the group the animals have been assigned to@@animalsupdatedingroup">animals updated in group
        </ng-container>
          '<b>{{action.confirmationRequiredGroup.displayName}}</b>'
        </div>
      </div>

      <!-- buttons -->
      <div class="action-buttons text-right" [ngClass]="widget ? 'col-xl-5' : 'col-xl-3'">
        <button class="btn btn-primary" (click)="confirmAssignment()" i18n="@@ok">OK</button>
        <button type="button" class="btn btn-secondary" (click)="markAsSold()" i18n="@@sell">Sell</button>
        <div class="btn-group" [class.btn-group-sm]="widget">
          <div class="btn-group" ngbDropdown role="group" placement="bottom-right" container="body">
            <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem *ngFor="let item of splitButtonNoSoldItems" (click)="item.command()">
                {{item.label}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

import { EArray } from '../../../helpers/constants.helper';
import { RowHeader } from '../../models/report-header.model';
import { Component, OnInit, Input, HostListener, ElementRef, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-reports-column-picker',
  templateUrl: './reports-column-picker.component.html',
  styleUrls: ['./reports-column-picker.component.scss']
})
export class ReportsColumnPickerComponent implements OnInit, OnChanges {
  @Input()
  headers: EArray<RowHeader>;
  @Input()
  curHeaders: Array<RowHeader>;

  @Output()
  headersChanged = new EventEmitter<Array<RowHeader>>();

  groupedHeader: any;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['headers'] && changes['headers'].currentValue) {
      this.loadUpHeaders();
    }
  }

  loadUpHeaders() {
    if (this.curHeaders) {
      this.headers.forEach(h => {
        if (this.curHeaders.findIndex(o => o.header === h.header) >= 0) {
          h.visible = true;
        } else {
          h.visible = false;
        }
      });
      this.groupedHeader = this.headers.groupBy('category');
    }
  }

  ngOnInit() {
  }

  listChanged(header = null) {
    if (!header) {
      if (this.curHeaders.length === this.headers.length) { // unselect all
        this.curHeaders.forEach(h => {
          h.visible = false;
        });
        this.curHeaders = [];
      } else { // select all
        this.groupedHeader.forEach(group => {
          if (group && group.list) {
            group.list.forEach(h => {
              if (h && h.header[0] !== '#' && this.curHeaders.indexOf(h) < 0) {
                h.visible = true;
                this.curHeaders.push(h);
              }
            });
          }
        });
      }
    } else {
      header.visible = !header.visible;
      if (header.visible) {
        if (this.curHeaders.findIndex(o => o.header === header.header) < 0) {
          this.curHeaders.push(header);
        }
      } else {
        this.curHeaders.splice(this.curHeaders.findIndex(o => o.header === header.header), 1);
      }
    }
    this.curHeaders = this.curHeaders.slice();
    this.headersChanged.emit(this.curHeaders);
  }

}

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { BottomNotificationsService } from '../../components/bottom-notifications/bottom-notifications.service';
import { IntercomService } from '../intercom.service';
import { MainService } from '../main.service';
import { AuthService } from '../../auth/auth.service';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LocaleService } from '../../helpers/locale.service';
import { ActivatedRoute } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { ErrorSummary } from '../models/error-summary.model';


declare var XLSX: any;
declare var XLS: any;

@Injectable()
export class TransferService {
    uploadComplete = new EventEmitter<any>();
    private csvParams: Array<CsvParams> = [];
    private filesLength = 0;

    constructor(private http: HttpClient, private authService: AuthService, public mainService: MainService
        , private intercomService: IntercomService, private bottomNotificationsService: BottomNotificationsService
        , private localeService: LocaleService, private route: ActivatedRoute) { }


    private readXlsx(files) {
        if (files.length === 0) {
            this.uploadFinished();
            return;
        }
        let fr = new FileReader(),
            self = this,
            count = 0;
        fr.onload = function (e) {
            let workbook = XLSX.read((<FileReader>e.target).result, { type: 'binary' });
            if (workbook.SheetNames.length === 0) {
                self.filesLength--; // when xl file has no sheets count down total files to upload.
                return;
            }
            let sheet = workbook.Sheets[workbook.SheetNames[0]];

            // below remove the view layer data for eid column only, cannot do all columns because date column would be hard to detect,
            // xls date columns can have 'z' to define the time, but xlsx does not
            let eidColumn = null;
            Object.keys(sheet).forEach(function(s) {
                if (sheet[s].w && sheet[s].w.toLowerCase
                    && typeof sheet[s].w.trim === 'function'
                    && (sheet[s].w.toLowerCase().trim() === 'eid' || sheet[s].w.toLowerCase().trim() === 'ide')) {
                        eidColumn = s.charAt(0);
                }
                if (eidColumn != null && s && s.startsWith && s.startsWith(eidColumn)) {
                    if (sheet[s].w && (sheet[s].z === undefined || sheet[s].z === null)) {
                        delete sheet[s].w;
                        sheet[s].z = '0';
                    }
                }
            });
            let XL_row_object = XLSX.utils.sheet_to_csv(sheet);
            self.csvParams.push({
                Content: XL_row_object,
                FarmId: self.mainService.farmChanged.value.farmId,
                SessionName: files[count].name,
                FileName: files[count].name,
                LastModified: new Date(files[count].lastModified)
            });
            count++;
            if (files.length <= count) {
                self.uploadFinished();
                return;
            }
            fr.readAsBinaryString(files[count]);
        };
        fr.readAsBinaryString(files[count]);
    }

    private readCsv(files) {
        if (files.length === 0) {
            this.uploadFinished();
            return;
        }
        let fr = new FileReader(),
            self = this,
            count = 0;
        fr.onload = function (e) {
            self.csvParams.push({
                Content: (<FileReader>e.target).result.toString(),
                // FarmName: self.mainService.farmChanged.value.farmName,
                FarmId: self.mainService.farmChanged.value.farmId,
                SessionName: files[count].name,
                FileName: files[count].name,
                LastModified: new Date(files[count].lastModified)
            });
            count++;
            if (files.length <= count) {
                self.uploadFinished();
                return;
            }
            fr.readAsText(files[count], 'ISO-8859-1');
        };
        fr.readAsText(files[count], 'ISO-8859-1');
    }

    private uploadFinished() {
        let url = environment.apiUrlRoot + '/csv/SessionBulk';
        if (this.csvParams.length >= this.filesLength) {
            let headers = new HttpHeaders()
                .append('Authorization', 'Bearer ' + this.authService.getToken())
                .append('timeout', '600000'); // 10 minutes, same as server
            this.http.post(url, this.csvParams, { headers: headers })
                .pipe(catchError((error: any, cought: Observable<any>) => {
                    this.intercomService.logEvent('UploadPostFailed');
                    if (error.status === 503) {
                        window.location.reload(true);
                    }
                    if (error.status === 400 && error.error) {
                        this.uploadComplete.emit(error.error);
                        return observableThrowError('Was not able to upload all of the files');
                    } else {
                        let errors: Array<ErrorSummary> = [];
                        this.csvParams.forEach(c => {
                            let ee: ErrorSummary = {
                                fileName: c.FileName,
                                errorResult: this.localeService.constants.stringAnErrorHasOccuredDuringTheUpload,
                                description: this.localeService.constants.stringUploadFailedOrUploadTimedOut,
                                column: null,
                                row: null,
                                errorTypeText: null
                            };
                            errors.push(ee);
                        });
                        let e = {
                            error: {
                                innererror: errors
                                },
                            value: []
                            };
                        this.uploadComplete.emit(e);
                        this.bottomNotificationsService.currentMessage.next({
                            title: this.localeService.constants.stringUploadFailedOrUploadTimedOut,
                            message: this.localeService.constants.stringAnErrorHasOccuredDuringTheUpload,
                            type: 'warning'
                        });

                        // return observableThrowError(error.message);
                    }
                }))
                .subscribe(res => { this.intercomService.logEvent('UploadPostSuccess'); this.uploadComplete.emit(res); });

        }
    }


    public UploadFiles = (files: Array<File>): any => {
        this.csvParams = [];
        this.intercomService.logEvent('UploadPosting');
        this.filesLength = files.filter(f => f.name.toLowerCase().endsWith('.csv')
            || f.name.toLowerCase().endsWith('.xlsx') || f.name.toLowerCase().endsWith('.xls')).length;
        const csvs = files.filter(f => f.name.toLowerCase().endsWith('.csv')),
            xslxs = files.filter(f => f.name.toLowerCase().endsWith('.xlsx') || f.name.toLowerCase().endsWith('.xls'));

        if (xslxs.length > 0 && FileReader.prototype.readAsBinaryString != null) {
            this.readXlsx(xslxs);
        }
        if (csvs.length > 0) {
            this.readCsv(csvs);
        }


        return this.uploadComplete;
    }
}

class CsvParams {
    // public FarmName: string;
    public FarmId: number;
    public SessionName: string;
    public Content: string;
    public FileName: string;
    public LastModified: Date;
}


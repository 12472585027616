<!--<p-dialog [header]="group.groupId ? localeService.constants.stringEditGroup : localeService.constants.stringNewGroup"-->
<!--  [draggable]="false" class="ui-dialog-show-overflow" [responsive]="true" [resizable]="false" [width]="window.innerWidth > 600? 600 : window.innerWidth"-->
<!--  minWidth="350" [(visible)]="visible" (onHide)="createNewGroupService.closeModel(1)" [modal]="true">-->

<!-- header -->
<div class="modal-header">
  <h3>{{ group?.groupId ? localeService.constants.stringEditGroup : localeService.constants.stringNewGroup }}</h3>
  <app-new-group-line [group]="group" [templates]="templates" [step]="step" ></app-new-group-line>
  <a (click)="prevStepOrCancel.next('cancel')" class="close"><i class="fas fa-times"></i></a>
</div>

<!-- loading -->
<div *ngIf="!form">
  <app-loading [local]="true"></app-loading>
</div>

<!-- content -->
<form [formGroup]="form" *ngIf="form" class="modal-form-with-footer" (ngSubmit)="submitChanges($event)">
  <div class="modal-body" >

    <!-- name -->
    <app-form-input [control]="form.get('groupName')" i18n-label="@@groupName" label="Group Name"
                    i18n-error="@@namerequired" error="Name required">
      <input type="text" formControlName="groupName" class="form-control" >
    </app-form-input>

    <!-- species -->
    <app-form-input [control]="form.get('species_SpeciesId')" i18n-label="@@species" label="Species" *ngIf="species.length > 0"
                    i18n-error="@@speciesrequired" error="Species required" >
      <ng-select
        formControlName="species_SpeciesId"
        [items]="species"
        [searchable]="false"
        dropdownPosition="bottom"
        bindLabel="speciesName"
        bindValue="speciesId">
      </ng-select>
    </app-form-input>

    <ng-container *ngIf="showBreedAndSexSelection()">

      <!-- breed -->
      <app-form-input [control]="form.get('breed_BreedId')" [label]="localeService.constants.stringBreed"
                      i18n-error="@@breedrequired" error="Breed required" 
                      i18n-errorPattern="@@breedrequired" errorPattern="Breed required">
        <ng-select
          formControlName="breed_BreedId"
          [items]="breeds"
          bindLabel="breedName"
          bindValue="breedId"
          (search)="breedsFiltered($event.term)"
          (clear)="breedsFiltered('')">
          <ng-template ng-footer-tmp>
            <p *ngIf="!showInternationalBreeds" i18n="@@typeToFindMore" class="text-muted font-italic">Type to find more</p>
          </ng-template>
        </ng-select>
      </app-form-input>

      <!-- sex -->
      <app-form-input [control]="form.get('sex_SexId')" [label]="localeService.constants.stringSex"
                      i18n-error="@@sexrequired" error="Sex required" 
                      i18n-errorPattern="@@sexrequired" errorPattern="Sex required">
        <ng-select
          formControlName="sex_SexId"
          [items]="sexes"
          bindLabel="sexName"
          bindValue="sexId">
        </ng-select>
      </app-form-input>

    </ng-container>
  </div>

  <!-- footer -->
  <div class="modal-footer" >
    <a href="javascript:void(0)" class="btn btn-link" (click)="prevStepOrCancel.next('cancel');" i18n="@@cancel">Cancel</a>
    <button type="submit" [disabled]="!form || !templates" class="btn btn-primary ml-1">
      <ng-container *ngIf="templates" i18n="@@next">Next</ng-container>
      <ng-container *ngIf="!templates" i18n="@@loading">Loading...</ng-container>
    </button>
  </div>

</form>



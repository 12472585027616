import { MainService } from '../main.service';
import { SignalRService, SignalrType } from './signal-r.service';
import { AuthService } from '../../auth/auth.service';
import { SessionImportAudits } from '../models';

import { Injectable, OnDestroy } from '@angular/core';

import { Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';
import { map } from 'rxjs/operators';





import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
@Injectable()
export class SessionImportAuditsService implements OnDestroy {
    // imports = new BehaviorSubject<Array<SessionImportAudits>>([]);
    public imports = new BehaviorSubject<Array<SessionImportAudits>>(null);
    private subs: Array<Subscription> = [];
    private curFarmId: number = null;

    constructor(private authService: AuthService, private signalRService: SignalRService
        , private mainService: MainService, private apiService: ApiService) {
        // we listen to changes to imports and get the new one as it comes up
        this.subs.push(this.signalRService.update.subscribe(msg => {
            if (msg.type === SignalrType.forceRefresh && this.mainService.farmChanged.value) {
                this.imports.next(null);
                this.getImports(this.mainService.farmChanged.value.farmId);
            } else if (msg.type === SignalrType.imports && !isNaN(msg.id)) {
                this.getImportActivity(msg.id).subscribe(imp => {
                    const index = this.imports.value.findIndex(i => imp.sessionImportAuditId === i.sessionImportAuditId);
                    if (index >= 0) {
                        this.imports.value[index] = imp;
                    } else {
                        this.imports.value.push(imp);
                    }
                    this.imports.next(this.imports.value);
                });
            }
        }));

        this.subs.push(this.mainService.farmChanged.subscribe(farm => {
            if (farm) {
                this.imports.next(null);
                this.getImports(farm.farmId);
            }
        }));
    }

    ngOnDestroy() {
        this.subs.forEach(sub => { sub.unsubscribe(); });
    }

    private getImports(farmId: number) {
        this.apiService.get(
            `/odata/SessionImportAudits?$select=session_SessionId,importDateTime,weightCount,importResult,created,userName,userAgent,device_DeviceId,user_UserId,notes`
            + `&$filter=farm_FarmId eq ${farmId}&`
            + `$expand=session($expand=device;$select=device,sessionStartDate)`
        )
            .pipe(map((res: any) => res.value))
            .subscribe(imports => this.imports.next(imports));
    }

    private extractData(res: any) {
        const body = res;
        return body || {};
    }

    public getImportActivity = (importId: number): Observable<SessionImportAudits> => {
        return this.apiService.get(
            `/odata/SessionImportAudits(${importId})?$select=sessionImportAuditId,session_SessionId`
            + `,importDateTime,weightCount,importResult,created,userName,userAgent,device_DeviceId,user_UserId,notes`
            + `&$expand=session($expand=device;$select=device,sessionStartDate)`
        );
    }

    public getFileImportInformation = (startDate: Date, endDate: Date, ignoreGood = true, farmId: number = null): Observable<Array<SessionImportAudits>> => {
        return this.apiService.get(
            `/odata/SessionImportAudits?$select=sessionImportAuditId,farm_FarmId,userName,userAgent,user_UserId,error,created&$expand=session($select=sessionName)`
            + `,sessionFile($select=fileName)&$filter=created gt ${startDate.toISOString()}`
            + (farmId ? ' and farm_FarmId eq ' + farmId : '')
            + (ignoreGood ? ' and error ne null' : '')
            + ` and created le ${endDate.toISOString()}&$orderby=created desc`
        )
            .pipe(map((res: any) => res.value));
    }

    public getImportedFile = (importId: number, rawData: boolean): Observable<string> => {
        return this.apiService.get(
            `/odata/SessionImportAudits(${importId})?$select=sessionFile&$expand=sessionFile($select=csvText,rawData)`
        ).pipe(map((res: any) => rawData ? res.sessionFile.rawData : res.sessionFile.csvText));
    }

    public getImportedError = (importId: number): Observable<string> => {
        return this.apiService.get(
            `/odata/SessionImportAudits(${importId})?$select=error`
        ).pipe(map((res: any) => res.error));
    }
}

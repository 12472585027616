import { WeightPlanTemplate, WeightPlanTemplatePoint } from '../main/models/weight-plan-template.model';
import { Group } from '../main/models/group.model';
import { Session } from '../main/models/session.model';
import { SessionAction } from '../main/models/session-action';
import { WeightPlan } from '../main/models/weight-plan.model';
import { ConvertToUTC } from './datatype.helper';
import * as moment from 'moment';

export class CreateGroupTemplateHelper {

  public static customTwpPoints = '[{"monthOffset":0},{"monthOffset":3},{"monthOffset":6},{"monthOffset":9},{"monthOffset":12}]';

  public static AdjustTargetWeightTemplate(weightPlanTemplate: WeightPlanTemplate, group: Group, action: SessionAction): WeightPlanTemplate {
    if (!weightPlanTemplate) {
      weightPlanTemplate = <WeightPlanTemplate>({
        targetWeightPlanTemplateId: 0,
        planTemplateName: 'Custom',
        weightGain: group.species_SpeciesId === 4 // deer
          ? 0.2
          : group.species_SpeciesId === 3 // sheep
            ? 0.3
            : 1,
        weightPlanPointsJson: this.customTwpPoints,
        monthStart: action ? new Date(action.sessionStartDate).getUTCMonth() : new Date().getUTCMonth(),
        species_SpeciesId: group.species_SpeciesId,
        country_CountryId: 1,
        startDate: action ? new Date(action.sessionStartDate) : new Date(),
        graphOptions: null
      });
      // shift month to 3 months earlier, month starts from 0, but we want 1-12
      weightPlanTemplate.monthStart = weightPlanTemplate.monthStart > 2 ? weightPlanTemplate.monthStart - 2 : weightPlanTemplate.monthStart + 10;
    }
    // this.selectedGraph = weightPlan;
    weightPlanTemplate.startDate = new Date();
    let dataPoints = <Array<WeightPlanTemplatePoint>>JSON.parse(weightPlanTemplate.weightPlanPointsJson);

    weightPlanTemplate.startDate.setMonth(weightPlanTemplate.monthStart - 1); // -1 because js date 0 based
    weightPlanTemplate.startDate.setDate(1);

    if (weightPlanTemplate.targetWeightPlanTemplateId === 0) {
      // this is custom template
      let startWeight;
      if (group.species_SpeciesId === 4) { // deer
        startWeight = 16;
      } else if (group.species_SpeciesId === 3) { // sheep
        startWeight = 30;
      } else {
        startWeight = 100;
      }
      if (action) {
        weightPlanTemplate.monthStart = new Date(action.sessionStartDate).getUTCMonth();
        let weight3MonthsAgo = action['averageWeight'] + this.getDaysDiff(new Date(action.sessionStartDate), -3) * weightPlanTemplate.weightGain;
        let weight2MonthsAgo = action['averageWeight'] + this.getDaysDiff(new Date(action.sessionStartDate), -2) * weightPlanTemplate.weightGain;
        let weight1MonthsAgo = action['averageWeight'] + this.getDaysDiff(new Date(action.sessionStartDate), -1) * weightPlanTemplate.weightGain;
        let weight0MonthsAgo = action['averageWeight'] + this.getDaysDiff(new Date(action.sessionStartDate), 0) * weightPlanTemplate.weightGain;
        if (action['averageWeight'] && action['averageWeight'] !== 0) {
          // MIHUBDRY-2926, ignore action weight is action does not have avg weight
          if (Math.abs(startWeight - weight0MonthsAgo) < Math.abs(startWeight - weight1MonthsAgo)
            && Math.abs(startWeight - weight0MonthsAgo) < Math.abs(startWeight - weight2MonthsAgo)
            && Math.abs(startWeight - weight0MonthsAgo) < Math.abs(startWeight - weight3MonthsAgo)) {
            startWeight = weight0MonthsAgo;
          } else if (Math.abs(startWeight - weight1MonthsAgo) < Math.abs(startWeight - weight2MonthsAgo)
            && Math.abs(startWeight - weight1MonthsAgo) < Math.abs(startWeight - weight3MonthsAgo)) {
            startWeight = weight1MonthsAgo;
            weightPlanTemplate.monthStart -= 1;
          } else if (Math.abs(startWeight - weight2MonthsAgo) < Math.abs(startWeight - weight3MonthsAgo)) {
            startWeight = weight2MonthsAgo;
            weightPlanTemplate.monthStart -= 2;
          } else {
            startWeight = weight3MonthsAgo;
            weightPlanTemplate.monthStart -= 3;
          }
          if (weightPlanTemplate.monthStart < 0) {
            weightPlanTemplate.monthStart += 12;
          }
        }

        weightPlanTemplate.startDate = moment.utc([action.sessionStartDate.getUTCFullYear(), weightPlanTemplate.monthStart, 1, 0, 0 , 0]).toDate()
      }
      for (let dataPoint of dataPoints) {
        dataPoint.weight = startWeight + this.getDaysDiff(weightPlanTemplate.startDate, dataPoint.monthOffset) * weightPlanTemplate.weightGain;
      }
    }

    if (action && dataPoints.length > 0 && action.averageWeight) {
      let sessionDate = new Date(action.sessionStartDate),
        curYear = sessionDate.getUTCFullYear(), // curYear is current year of TWP
        diff = [];
      while (curYear + Math.ceil(dataPoints[dataPoints.length - 1].monthOffset) / 12 + 1 >= sessionDate.getUTCFullYear()) {
        // session month (need -1 as js data 0 based)
        let sessMonth = sessionDate.getUTCMonth() + ((sessionDate.getUTCFullYear() - curYear) * 12);
        for (let i = 1, l = dataPoints.length; i < l; i++) {
          let curMonth = dataPoints[i].monthOffset + weightPlanTemplate.monthStart, // month of latest twp point
            prevMonth = dataPoints[i - 1].monthOffset + weightPlanTemplate.monthStart - 1; // month of previous twp point
          if (sessMonth >= prevMonth && curMonth >= sessMonth) { // we go here if we found a place where session falls between twp points
            let ang = Math.atan((dataPoints[i].weight - dataPoints[i - 1].weight)
              / (dataPoints[i].monthOffset - dataPoints[i - 1].monthOffset));
            diff.push({
              year: curYear, offset: Math.floor((sessMonth - curMonth) / 12)
              , dist: Math.abs(((sessMonth - prevMonth) / Math.cos(ang) + dataPoints[i - 1].weight) - action['averageWeight'])
              // distance between twp weight and session weight as absolute
            });
            break;
          }
        }
        curYear--;
      }
      if (diff.length > 0) { // only if session falls into two places
        weightPlanTemplate.startDate.setFullYear(diff.sort((a, b) => a.dist - b.dist)[0].year);
      } else { // if we could not work out by weight use old logic to place start year
        weightPlanTemplate.startDate.setFullYear(sessionDate.getUTCMonth() < weightPlanTemplate.startDate.getUTCMonth()
          ? sessionDate.getUTCFullYear() - 1 : sessionDate.getUTCFullYear());
      }
    }
    weightPlanTemplate.points = dataPoints.map((point) => {
      let xDate = new Date(Date.UTC(
        weightPlanTemplate.startDate.getUTCFullYear(),
        weightPlanTemplate.startDate.getUTCMonth() + point.monthOffset,
        1, 0, 0, 0, 0
      ));

      return {
        date: xDate.toUTCString(),
        weight: point.weight
      };
    });

    return weightPlanTemplate;
  }


  public static AdjustWeightPlanTemplate(weightPlan: WeightPlanTemplate, group: Group, session: Session): WeightPlanTemplate {
    if (!weightPlan) {
      weightPlan = <WeightPlanTemplate>({
        targetWeightPlanTemplateId: 0,
        planTemplateName: 'Custom',
        weightGain: group.species_SpeciesId === 4 // deer
          ? 0.2
          : group.species_SpeciesId === 3 // sheep
            ? 0.3
            : 1,
        weightPlanPointsJson: this.customTwpPoints,
        monthStart: session ? new Date(session.sessionStartDate).getMonth() : new Date().getMonth(),
        species_SpeciesId: group.species_SpeciesId,
        country_CountryId: 1,
        graphOptions: null
      });
      // shift month to 3 months earlier, month starts from 0, but we want 1-12
      weightPlan.monthStart = weightPlan.monthStart > 2 ? weightPlan.monthStart - 2 : weightPlan.monthStart + 10;
    }
    // this.selectedGraph = weightPlan;
    weightPlan.startDate = new Date();
    let dataPoints = <Array<WeightPlanTemplatePoint>>JSON.parse(weightPlan.weightPlanPointsJson);

    weightPlan.startDate.setMonth(weightPlan.monthStart - 1); // -1 because js date 0 based
    weightPlan.startDate.setDate(1);

    if (weightPlan.targetWeightPlanTemplateId === 0) {
      // this is custom template
      let startWeight;
      if (group.species_SpeciesId === 4) { // deer
        startWeight = 16;
      } else if (group.species_SpeciesId === 3) { // sheep
        startWeight = 30;
      } else {
        startWeight = 100;
      }

      if (session) {
        let weight3MonthsAgo = session['averageWeight'] + this.getDaysDiff(new Date(session.sessionStartDate), -3) * weightPlan.weightGain;
        let weight2MonthsAgo = session['averageWeight'] + this.getDaysDiff(new Date(session.sessionStartDate), -2) * weightPlan.weightGain;
        let weight1MonthsAgo = session['averageWeight'] + this.getDaysDiff(new Date(session.sessionStartDate), -1) * weightPlan.weightGain;
        let weight0MonthsAgo = session['averageWeight'] + this.getDaysDiff(new Date(session.sessionStartDate), 0) * weightPlan.weightGain;
        weightPlan.monthStart = new Date(session.sessionStartDate).getMonth();
        if (Math.abs(startWeight - weight0MonthsAgo) < Math.abs(startWeight - weight1MonthsAgo)
          && Math.abs(startWeight - weight0MonthsAgo) < Math.abs(startWeight - weight2MonthsAgo)
          && Math.abs(startWeight - weight0MonthsAgo) < Math.abs(startWeight - weight3MonthsAgo)) {
          startWeight = weight0MonthsAgo;
        } else if (Math.abs(startWeight - weight1MonthsAgo) < Math.abs(startWeight - weight2MonthsAgo)
          && Math.abs(startWeight - weight1MonthsAgo) < Math.abs(startWeight - weight3MonthsAgo)) {
          startWeight = weight1MonthsAgo;
          weightPlan.monthStart -= 1;
        } else if (Math.abs(startWeight - weight2MonthsAgo) < Math.abs(startWeight - weight3MonthsAgo)) {
          startWeight = weight2MonthsAgo;
          weightPlan.monthStart -= 2;
        } else {
          startWeight = weight3MonthsAgo;
          weightPlan.monthStart -= 3;
        }
        if (weightPlan.monthStart < 0) {
          weightPlan.monthStart += 12;
        }
        weightPlan.startDate = new Date(session.sessionStartDate);
        weightPlan.startDate.setMonth(weightPlan.monthStart);
        weightPlan.startDate.setDate(1);
        weightPlan.startDate = new Date(weightPlan.startDate);
      }

      for (let dataPoint of dataPoints) {
        dataPoint.weight = startWeight + this.getDaysDiff(weightPlan.startDate, dataPoint.monthOffset) * weightPlan.weightGain;
      }
    }

    if (session && dataPoints.length > 0) {
      let sessionDate = new Date(session.sessionStartDate),
        curYear = sessionDate.getFullYear(), // curYear is current year of TWP
        diff = [];
      while (curYear + Math.ceil(dataPoints[dataPoints.length - 1].monthOffset) / 12 + 1 >= sessionDate.getFullYear()) {
        // session month (need -1 as js data 0 based)
        let sessMonth = sessionDate.getMonth() + ((sessionDate.getFullYear() - curYear) * 12);
        for (let i = 1, l = dataPoints.length; i < l; i++) {
          let curMonth = dataPoints[i].monthOffset + weightPlan.monthStart, // month of latest twp point
            prevMonth = dataPoints[i - 1].monthOffset + weightPlan.monthStart - 1; // month of previous twp point
          if (sessMonth >= prevMonth && curMonth >= sessMonth) { // we go here if we found a place where session falls between twp points
            let ang = Math.atan((dataPoints[i].weight - dataPoints[i - 1].weight)
              / (dataPoints[i].monthOffset - dataPoints[i - 1].monthOffset));
            diff.push({
              year: curYear, offset: Math.floor((sessMonth - curMonth) / 12)
              , dist: Math.abs(((sessMonth - prevMonth) / Math.cos(ang) + dataPoints[i - 1].weight) - session['averageWeight'])
              // distance between twp weight and session weight as absolute
            });
            break;
          }
        }
        curYear--;
      }
      if (diff.length > 0) { // only if session falls into two places
        weightPlan.startDate.setFullYear(diff.sort((a, b) => a.dist - b.dist)[0].year);
      } else { // if we could not work out by weight use old logic to place start year
        weightPlan.startDate.setFullYear(sessionDate.getMonth() < weightPlan.startDate.getMonth()
          ? sessionDate.getFullYear() - 1 : sessionDate.getFullYear());
      }
    }
    weightPlan.points = dataPoints.map((point) => {
      
      let xDate = new Date(Date.UTC(
        weightPlan.startDate.getFullYear(),
        weightPlan.startDate.getMonth() + point.monthOffset,
        1, 0, 0, 0, 0
      ));
      
      return {
        date: xDate.toUTCString(),
        weight: point.weight
      };
    });
    return weightPlan;
  }

  public static getDaysDiff(startDate: Date, monthOffset: number): number {
    let toDate = new Date(startDate.getFullYear(), startDate.getMonth() + monthOffset, 1);
    let timeDiff = toDate.getTime() - startDate.getTime();
    return Math.round(timeDiff / 86400000);
  }

}

<!-- page header -->
<app-summary-header [headerTitle]="sessionSummary?.sessionName"></app-summary-header>

<app-loading *ngIf="!sessionSummary"></app-loading>

<ng-container *ngIf="sessionSummary">

  <!-- page content, tabs -->
  <ngb-tabset [destroyOnHide]="tabDestroyOnHide" (tabChange)="tabChange($event)" #tabSet="ngbTabset" [activeId]="activeTabOnLoad">

    <!-- summary -->
    <ngb-tab i18n-title="@@summary" title="Summary" id="summary">
      <ng-template ngbTabContent>

        <div class="page-content">

          <!-- summary header -->
          <div class="card card-summary-header">
            <div class="card-body">
              <div>
                <h2 [appNumberDisplay]="{number: sessionSummary.animalCount}"></h2>
                <p i18n="@@animals">Animals</p>
              </div>
              <div>
                <h2 [appNumberDisplay]="{number: sessionSummary.averageWeight}"></h2>
                <p i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
              </div>
              <div>
                <h2 [appNumberDisplay]="{number: sessionSummary.minWeight}"></h2>
                <p i18n="abbreviation for Minimum Weight@@minweight">Min Weight</p>
              </div>
              <div>
                <h2 [appNumberDisplay]="{number: sessionSummary.maxWeight}"></h2>
                <p i18n="abbreviation for Maximum Weight@@maxweight">Max Weight</p>
              </div>
              <div>
                <h2>
                  {{dayMonthString(sessionSummary.sessionStartDate)}}
                </h2>
                <p>{{yearString(sessionSummary.sessionStartDate)}}</p>
              </div>
            </div>
          </div>

          <app-sessions-summary [sessionSummary]="sessionSummary" (changeTab)="tabSet.select('animals')"></app-sessions-summary>

        </div>
      </ng-template>
    </ngb-tab>

    <!-- animal list -->
    <ngb-tab i18n-title="@@animals" title="Animals" id="animals">
      <ng-template ngbTabContent>
        <div class="page-content">
          <app-sessions-details [sessionSummary]="sessionSummary"></app-sessions-details>
        </div>
      </ng-template>
    </ngb-tab>

  </ngb-tabset>

</ng-container>

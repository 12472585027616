import {Component, OnInit, Input} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {MainService} from '../../main.service';
import {DraftsService} from '../../api/drafts.service';
import {Draft} from '../../models/draft';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {IntegratedDevice} from '../../models/integration';
import {IntercomService} from '../../intercom.service';
import {AuthService} from '../../../auth/auth.service';

@Component({
  selector: 'app-create-draft',
  templateUrl: './create-draft.component.html',
  styleUrls: ['./create-draft.component.scss']
})
export class CreateDraftComponent implements OnInit {
  @Input() animalIds: Array<number>;
  @Input() integratedDevice: IntegratedDevice = null;
  @Input() integratedDevices: Array<IntegratedDevice> = null;

  form: FormGroup;
  window: Window = window;
  draftDirectionId: number;

  constructor(
    private draftsService: DraftsService,
    private mainService: MainService,
    private bottomNotificationsService: BottomNotificationsService,
    private intercomService: IntercomService,
    private authService: AuthService,
    private router: Router,
    public ngbActiveModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.generateForm();
  }

  generateForm() {
    this.form = new FormGroup({
      draftName: new FormControl('', Validators.required),
      draftDirection_DraftDirectionId: new FormControl(this.draftDirectionId || '', Validators.required),
      draftDevice: new FormControl('', this.integratedDevices.length > 1 ? Validators.required : null)
    });
    this.form.controls['draftDevice'].setValue(this.integratedDevice.integratedDeviceName);
  }

  sendDraft() {
    if (this.form.valid) {
      let draft = {
        farm_FarmId: this.mainService.farmChanged.value.farmId,
        draftName: this.form.controls['draftName'].value,
        draftDirection_DraftDirectionId: this.form.controls['draftDirection_DraftDirectionId'].value,
        integratedDevice_IntegratedDeviceId: this.integratedDevice.integratedDeviceId
      } as Draft;
      draft.draftAnimals = this.animalIds.map(d => {
        return { animal_AnimalId: d }
      });
      this.draftsService.SaveDraft(draft).subscribe(res => {
        if (res) {
          this.bottomNotificationsService.currentMessage.next({
            title: 'Create draft success',
            message: this.animalIds.length === 1 ? `Draft created for 1 animal.` : `Draft created for ${this.animalIds.length} animals.`,
            type: 'success'
          });
          this.ngbActiveModal.close(draft);
          this.intercomService.logEvent('Create Draft', this.router.url, {
            user_id: this.authService.currentUser.userId,
            farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
            draftId: res.draftId
          });
        }
      }, err => {
        this.bottomNotificationsService.currentMessage.next({
          title: 'Create draft failed',
          message: 'Failed to create draft.',
          type: 'warning'
        });
      });
    } else {
      this.markAllFieldsAsDirty();
    }
  }

  markAllFieldsAsDirty() {
    this.form.controls['draftName'].markAsDirty();
  }
}

<header class="my-3 flex-grow-1">

  <div class="container-fluid">
    <div class="row">
      <div class="col-10 col-md-6 d-flex align-items-center">

        <div class="d-flex flex-align-items-center">
          <i class="fa fa-bars main-menu-toggle" (click)="toggleLeftMenu(); $event.stopPropagation();"></i>
          <div class="d-flex flex-column">
<!--            <app-breadcrumbs class="mb-1 breadcrumbs-component" [headerTitle]="headerTitle"></app-breadcrumbs>-->
            <h1 class="m-0">
              {{ headerTitle }}
            </h1>
          </div>
        </div>
        <!-- <span class="text-shady-lady">{{subHeaderTitle}}</span>-->

      </div>

      <div class="col-2 col-md-6">

        <!--some outlet thing here to allow for custom buttons etc in the header bar-->
        <div class="content-right">
          <ng-content></ng-content>
        </div>

      </div>
    </div>
  </div>

</header>

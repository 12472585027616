import {Component, OnDestroy, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';

import {LocaleService} from '../../../helpers/locale.service';
import {WeightRecord} from '../../models';
import {SessionAction} from '../../models/session-action';
import {MainService} from '../../main.service';
import {WeightRecordService} from '../../api';
import {SessionService} from '../../api/session.service';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {PermissionsService} from '../../../auth/permissions.service';
import {GroupsService} from '../../services/groups.service';
import {ActionsSoldDeadService} from '../../actions/actions-sold-dead-modal/actions-sold-dead.service';
import {SessionSummary} from '../../models';
import {Group} from '../../models/group.model';
import {SessionWeightSummary} from '../../models/session-weight-summary';

@Component({
  selector: 'app-sessions-summary',
  templateUrl: './sessions-summary.component.html',
  styleUrls: ['./sessions-summary.component.scss']
})
export class SessionsSummaryComponent implements OnInit, OnDestroy {
  @Input() sessionSummary;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  weights: number[] = [];
  sessionActions: Array<SessionAction> = new Array<SessionAction>();
  preAllocatedGroups: Array<SessionAction> = new Array<SessionAction>();

  private sub: Subscription;

  constructor(private sessionService: SessionService,
              private mainService: MainService,
              private weightRecordService: WeightRecordService,
              private route: ActivatedRoute,
              private router: Router,
              private title: Title,
              private globalParameterService: GlobalParameterService,
              private localeService: LocaleService,
              public permissionsService: PermissionsService,
              private actionsSoldDeadService: ActionsSoldDeadService,
              private groupsService: GroupsService) {
  }

  ngOnInit(): void {
    this.title.setTitle(`${this.localeService.constants.stringSessionSummary} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.mainService.actionDate = this.mainService.setDate(this.sessionSummary.sessionStartDate);

    this.sub = combineLatest([
      this.sessionService.sessions, this.groupsService.groups
    ]).subscribe(sessionsAndGroups => {
      const sessions = sessionsAndGroups[0];
      const groups = sessionsAndGroups[1];

      this.handleChange(sessions, groups);
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  assignmentDone(sessionAction: SessionAction): void {

    // update sessions
    this.sessionService.updateSessions([sessionAction.sessionId]);
  }

  private handleChange(sessions: SessionWeightSummary[], groups: Group[]): void {
    if (sessions) {
      const currentSession = sessions.find(session => session.sessionId === this.sessionSummary.sessionId);

      if (currentSession) {
        this.sessionSummary = currentSession;
      }
    }

    this.weightRecordService.GetAllWeightRecordIdsForSession(this.sessionSummary.sessionId).subscribe((weightRecords: WeightRecord[]) => {
      this.weights = weightRecords.map(wr => wr.weight).filter(wr => wr);
      this.createCompletedSessionActions(weightRecords);
    });
  }

  private createCompletedSessionActions(wrs: Array<WeightRecord>) {
    this.preAllocatedGroups = [];
    this.sessionActions = this.sessionSummary.actions;

    if (this.groupsService.groups.value !== null) {
      const preAllocatedGroups = [];

      // number of weight records will be the same number of animals
      const assignedIDdWRs = wrs.slice().filter(wr => wr.groups_GroupId !== null && wr.animal_AnimalId !== null);
      const grpdIDd: Map<number, WeightRecord[]> = this.groupBy(assignedIDdWRs, 'groups_GroupId') as Map<number, WeightRecord[]>;
      const assignedNonIDdWRs = wrs.slice().filter(wr => wr.groups_GroupId !== null && wr.animal_AnimalId === null);
      const grpdNonIDd: Map<number, WeightRecord[]> = this.groupBy(assignedNonIDdWRs, 'groups_GroupId') as Map<number, WeightRecord[]>;

      for (const key in grpdIDd) {
        if (grpdIDd !== null) {
          const weightRecords: WeightRecord[] = grpdIDd[key];
          const groupId: number = weightRecords[0].groups_GroupId;
          const group: Group = this.groupsService.groups.value.find(g => g.groupId === groupId);

          if (group) {
            const sessionAction = <SessionAction>{
              groupId: groupId,
              animalCount: weightRecords.length,
              confirmationRequiredGroup: group,
              sessionId: this.sessionSummary.sessionId,
              sessionStartDate: this.sessionSummary.sessionStartDate,
              ided: true,
              reassignGroup: true,
              oldGroupId: groupId
            };

            if (preAllocatedGroups.findIndex(p => p.groupId === groupId && p.animalCount === weightRecords.length && p.ided === true) === -1) {
              const tempAction = this.sessionActions.find(a => a.groupId === groupId && a.ided === true);

              if (tempAction) {
                this.sessionActions = this.sessionActions.filter(obj => obj !== tempAction);
              }

              preAllocatedGroups.push(sessionAction);
            }
          }
        }
      }

      for (let key in grpdNonIDd) {
        if (grpdNonIDd !== null) {
          const weightRecords: WeightRecord[] = grpdNonIDd[key];
          let groupId: number = weightRecords[0].groups_GroupId;
          let group: Group = this.groupsService.groups.value.find(g => g.groupId === groupId);

          if (group) {
            const sa = <SessionAction>{
              groupId: groupId,
              animalCount: weightRecords.length,
              confirmationRequiredGroup: group,
              sessionId: this.sessionSummary.sessionId,
              sessionStartDate: this.sessionSummary.sessionStartDate,
              ided: false,
              reassignGroup: true,
              oldGroupId: groupId
            };

            if (preAllocatedGroups.findIndex(p => p.groupId === groupId && p.animalCount === weightRecords.length && p.ided === false) === -1) {
              const tempAction = this.sessionActions.find(a => a.groupId === groupId && a.ided === false);

              if (tempAction) {
                this.sessionActions = this.sessionActions.filter(obj => obj !== tempAction);
              }

              preAllocatedGroups.push(sa);
            }
          }
        }
      }

      this.preAllocatedGroups = preAllocatedGroups.sort(pag => pag.groupId);
    }
  }

  private groupBy(xs, key): Map<number, WeightRecord[]> {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}

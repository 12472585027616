<div class="card card-summary-header">

  <app-loading *ngIf="!animal || loading" [local]="true"></app-loading>

  <div class="card-body" *ngIf="animal && !loading">
    <div>
      <h2 [appNumberDisplay]="{number: lastWeight, roundTo: 0}"></h2>
      <p i18n="@@weight">Weight</p>
    </div>

    <div>
      <h2 [appNumberDisplay]="{number: animalAdg, roundTo: 2, roundAfter: 5}"></h2>
      <p i18n="acronym for Average Daily Gain@@adg">ADG</p>
    </div>

    <div>
      <h2>{{status}}</h2>
      <p i18n="@@status">Status</p>
    </div>

    <div>
      <h2>{{dayMonthYearString(lastSeen)}}</h2>
      <p i18n="@@lastseen">Last Seen</p>
    </div>
  </div>
</div>


import {throwError as observableThrowError,  Observable } from 'rxjs';
import { BottomNotificationsService } from '../../components/bottom-notifications/bottom-notifications.service';
import { MainService } from '../../main/main.service';
import { User } from '../../main/models/user.model';
import { UserService } from '../../main/api';
import { AuthService } from '../../auth/auth.service';
import { FarmService } from '../../main/api/farm.service';
import { InvitationService } from '../../main/api/invitation.service';
import { Invitation } from '../../main/models/invitation.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '../../helpers/locale.service';
import { catchError } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit, OnDestroy {
  invitation: Invitation = null;
  errorMessage = '';
  code = '';
  invitationStatus = InvitationViewStatus.loading;

  constructor(private authService: AuthService, private route: ActivatedRoute, private invitationService: InvitationService,
    private userService: UserService, private router: Router, private mainService: MainService, private farmService: FarmService,
    private bottomNotificationsService: BottomNotificationsService, private localeService: LocaleService) { }

  accept() {
    this.invitationStatus = InvitationViewStatus.loading;
    this.invitationService.acceptInvitation(this.invitation.code).pipe(catchError(err => {
      if (err.error) {
        this.errorMessage = JSON.parse(err.error).error.message;
      } else {
        this.errorMessage = this.localeService.constants.stringUnknownErrorPleaseTryAgainLater;
      }
      this.invitationStatus = InvitationViewStatus.error;
      return observableThrowError(err);
    })).subscribe(user => {
      this.errorMessage = '';
      this.invitationStatus = InvitationViewStatus.success;
      this.mainService.updateFarms();
      this.authService.currentUser.currentFarm_FarmId = this.invitation.farm_FarmId;
      this.authService.refreshUser(this.authService.currentUser.userId).subscribe();
      this.router.navigate(['/main']);
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringFarmSuccessfullyAdded,
        message: this.localeService.constants.stringYouNowHaveAccessToNewFarm,
        type: 'success'
      });
    }, err => {
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringInvitationFailed,
        message: this.localeService.constants.stringFailedToProcessInvitePleaseTryTheLinkAgainLater,
        type: 'warning'
      });
    });
  }

  processInvite(user: User = null) {
    this.invitationService.getInvitation(this.code).subscribe(invitation => {
      this.invitation = invitation;
      if (this.invitation === null) {
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringInvitationFailed,
          message: this.localeService.constants.stringThisInvitationIsNoLongerValidOrHasBeenRevoked,  // identical string as below for constants usage
          type: 'warning'
        });
        this.router.navigate(['/login']);
      }
      if (!user) {
        this.userService.UserExists(this.invitation.code).subscribe(exists => {
          if (exists) {
            this.invitationStatus = InvitationViewStatus.userNotLoggedIn;
            this.router.navigate(['/login'], { queryParams: { username: this.invitation.email, redirectUrl: '/invitations/' + this.code } });
          } else {
            this.invitationStatus = InvitationViewStatus.userNotRegistered;
            this.router.navigate(['/register/' + this.code]);
          }
        });
      } else if (invitation.email.toLowerCase() === user.email.toLowerCase()) {
        this.invitationStatus = InvitationViewStatus.acceptPromt;
        this.accept();
      } else {
        this.invitationStatus = InvitationViewStatus.wrongUser;
      }
    }, err => {
      if (err.status === 404 || (err.indexOf && err.indexOf('404') >= 0)) {
        this.errorMessage = this.localeService.constants.stringThisInvitationIsNoLongerValidOrHasBeenRevoked;
      } else if (err.error) {
        this.errorMessage = JSON.parse(err.error).error.message;
      } else {
        this.errorMessage = this.localeService.constants.stringUnknownErrorPleaseTryAgainLater;
      }
    });
  }


  ngOnInit() {
    $('body').addClass('bg-dark-grey external-page pbn onload-check sb-l-c sb-r-c');

    this.code = this.route.snapshot.params['code'];
    if (this.code) {
      this.authService.isLoggedIn().subscribe(res => {
        if (!res) {
          this.processInvite();
        } else {
          let sub = this.authService.currentUserChanged.subscribe(user => {
            if (user) {
              this.processInvite(user);
              if (sub) {
                sub.unsubscribe();
              }
            }
          });
        }
      })
    }
  }

  ngOnDestroy(): void {
    $('body').removeClass();
  }
}

export enum InvitationViewStatus {
  loading = 0, acceptPromt = 1, wrongUser = 2, error = 3, success = 4, userNotRegistered = 5, userNotLoggedIn = 6
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';
import {combineLatest} from 'rxjs';

import {CreateNewGroupService} from '../create-new-group.service';
import {Group} from '../../models/group.model';
import {MainService} from '../../main.service';
import {SexService} from '../../api/sex.service';
import {LocaleService} from '../../../helpers/locale.service';
import {BreedService} from '../../api/breed.service';
import {SpeciesService} from '../../api/species.service';
import {Breed} from '../../models/breed.model';
import {Sex} from '../../models/sex.model';
import {Species} from '../../models/species.model';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SessionAction} from '../../models/session-action';
import {WeightPlanTemplate} from '../../models/weight-plan-template.model';

@Component({
  selector: 'app-new-group-details',
  templateUrl: './new-group-details.component.html',
  styleUrls: ['./new-group-details.component.scss']
})
export class NewGroupDetailsComponent implements OnInit {
  @Input() group: Group;
  @Input() sessionAction: SessionAction;
  @Input() templates: WeightPlanTemplate[] = [];
  @Input() step: number;

  @Output() nextStep: EventEmitter<Group> = new EventEmitter<Group>();
  @Output() prevStepOrCancel: EventEmitter<'prev' | 'cancel'> = new EventEmitter();
  @Output() setCanClose: EventEmitter<true | false> = new EventEmitter();

  window = window;
  visible = true;
  breeds: Array<Breed> = [];
  sexes: Array<Sex> = [];
  allBreeds: Array<Breed> = [];
  allSexes: Array<Sex> = [];
  species: Array<Species> = [];
  form: FormGroup;
  showCancelButton = false;

  private showInternationalBreeds = false;

  // private ignoreDeerCountries = ['brazil', 'canada', 'australia', 'united states', 'uruguay', 'bolivia, plurinational state Of'
  //   , 'Mexico', 'Colombia', 'Argentina', 'Peru', 'Dominican Republic', 'Venezuela, Bolivarian Republic Of', 'Chile', 'Ecuador'
  //   , 'Guatemala', 'Cuba', 'Haiti', 'Honduras', 'Paraguay', 'Nicaragua', 'El Salvador', 'Costa Rica', 'Panama', 'Puerto Rico'
  //   , 'Guadeloupe', 'Martinique', 'French Guiana']; // todo replace with value from server
  private ignoreDeerCountries = [8, 11, 24, 25, 31, 37, 40, 41, 42, 50, 52, 68, 74, 77, 82, 84, 131, 138, 145, 152, 153, 159, 163, 186, 205, 206, 209];

  constructor(private breedService: BreedService,
              private mainService: MainService,
              private sexService: SexService,
              public localeService: LocaleService,
              private speciesService: SpeciesService,
              public createNewGroupService: CreateNewGroupService) {
  }

  ngOnInit() {
    this.showCancelButton = this.sessionAction === null; // if we dont have an action then we have a perv page

    combineLatest([
      this.breedService.getBreeds(this.mainService.farmChanged.value.countries_CountryId),
      this.sexService.getSexes(),
      this.speciesService.getSpecies()
    ]).subscribe(breedAndSex => {
      const breeds = breedAndSex[0], sexes = breedAndSex[1], species = breedAndSex[2];
      if (breeds && sexes && species) {
        this.allBreeds = breeds.slice();
        this.allSexes = sexes.slice();
        this.species = species.slice();
        if (this.mainService.farmChanged.value && this.ignoreDeerCountries.find(c => c === this.mainService.farmChanged.value.countries_CountryId)) {
          this.species.splice(this.species.findIndex(s => s.speciesId === 4), 1)
        }

        // create form
        this.createForm();

        // after species
        this.updateBreedsAndSexes();

        // species ID preset and change value subscribe
        if (this.speciesId) {
          this.speciesId.valueChanges.subscribe(newVal => {
            this.showInternationalBreeds = false;
            this.updateBreedsAndSexes();
            this.updateBreedIdAndSexId();
          });
        }
      }
    });
  }

  markAllFieldsAsDirty() {
    this.groupName.markAsDirty();
    this.speciesId.markAsDirty();
    this.breedId.markAsDirty();
    this.sexId.markAsDirty();
  }

  breedsFiltered(searchWords: string) {
    if (typeof searchWords.trim === 'function' && this.showInternationalBreeds !== searchWords.trim().length > 0) {
      this.showInternationalBreeds = searchWords.trim().length > 0;
      this.updateBreedsAndSexes();
      this.updateBreedIdAndSexId();
    }
  }

  submitChanges(event: Event) {
    event.preventDefault();

    if (this.form && this.form.valid) {
      this.nextStep.next(this.form.value);
    } else {
      this.markAllFieldsAsDirty();
    }
  }


  sexAndBreedValidator(context: NewGroupDetailsComponent, name: string) {
    return (control: FormControl): { [key: string]: boolean } => {
      if (
        (control.parent && control.parent.value.species_SpeciesId === 100) ||
        (name === 'breed_BreedId' && this.breeds.length === 0) ||
        (name === 'sex_SexId' && this.sexes.length === 0) || control.value) {
        return null;
      }

      return { 'pattern': true };
    }
  }

  createForm() {
    this.form = new FormGroup({
      groupName: new FormControl(this.group.groupName || null, Validators.required),
      species_SpeciesId: new FormControl(this.group.species_SpeciesId || null, Validators.required)
    });

    if (this.speciesId.value) {
      this.speciesChanged();
    }

    this.speciesId.valueChanges.subscribe(() => this.speciesChanged());
  }

  speciesChanged(): void {
    if (this.showBreedAndSexSelection()) {
      if (this.breeds.filter(b => b.breedId === this.group.breed_BreedId).length === 0) {
        // safety check for editing in case the group has a non-local breed
        this.showInternationalBreeds = true;
        this.updateBreedsAndSexes();
      }
      this.form.addControl('breed_BreedId', new FormControl(
        { value: this.group.breed_BreedId || null, disabled: this.speciesId && !this.speciesId.value },
        [Validators.required, this.sexAndBreedValidator(this, 'breed_BreedId')]
      ));
      this.form.addControl('sex_SexId', new FormControl(
        { value: this.group.sex_SexId || null, disabled: this.speciesId && !this.speciesId.value },
        [Validators.required, this.sexAndBreedValidator(this, 'sex_SexId')]
      ));
    } else {
      this.form.removeControl('breed_BreedId');
      this.form.removeControl('sex_SexId');
    }
  }

  updateBreedsAndSexes(): void {
    const speciesId = this.speciesId.value;

    let cId = this.mainService.farmChanged.value.countries_CountryId;
    let allBreedsForSpecies = this.allBreeds.filter(t => t.species_SpeciesId === speciesId);
    let localBreedsForSpecies = allBreedsForSpecies.filter( t => t.countryId === cId);
    if (localBreedsForSpecies.length === 0) { // if no breeds for current farm's country
      localBreedsForSpecies = allBreedsForSpecies.filter(t => t.countryId === 150); // hardcoded to NZ as default list
    }

    this.breeds = this.showInternationalBreeds ? allBreedsForSpecies : localBreedsForSpecies;
    this.sexes = this.allSexes.filter(t => t.species_SpeciesId === speciesId);
  }

  updateBreedIdAndSexId() {

    if (this.breedId) {
      this.breedId.reset();
      this.breedId.markAsPristine();
    }

    if (this.sexId) {
      this.sexId.reset();
      this.sexId.markAsPristine();
    }

  }

  showBreedAndSexSelection(): boolean {
    return this.speciesId && this.speciesId.value !== 100 && this.speciesId.value !== undefined && this.speciesId.value !== null;
  }

  get groupName(): AbstractControl {
    return this.form && this.form.get('groupName');
  }

  get speciesId(): AbstractControl {
    return this.form && this.form.get('species_SpeciesId');
  }

  get breedId(): AbstractControl {
    return this.form && this.form.get('breed_BreedId');
  }

  get sexId(): AbstractControl {
    return this.form && this.form.get('sex_SexId');
  }

}

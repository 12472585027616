import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Group } from '../../../models/group.model';
import { GlobalParameterService } from '../../../../helpers/global-parameter.service';
import { Router } from '@angular/router';
import { DetailsSearchParam, DetailsSearchParamType } from '../../../models/details-search-params';
import { LocaleService } from '../../../../helpers/locale.service';
import { Rounder } from '../../../../helpers/rounder.helper';

@Component({
    selector: 'app-groups-group-by-weight',
    templateUrl: './groups-group-by-weight.component.html',
    styleUrls: ['./groups-group-by-weight.component.scss']
})
export class GroupsGroupByWeightComponent implements OnInit, OnChanges {
    @Input() weightRecords: Array<number>;
    @Input() group: Group;

    @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

    belowValue: number = null;
    aboveValue: number = null;
    options: any;
    chartName: string;

    constructor(private globalParameterService: GlobalParameterService, private router: Router, public localeService: LocaleService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes['weightRecords'] && changes['weightRecords'].currentValue) {
            this.calculateRange();
        }
        this.setChartName();
    }

    ngOnInit() {
        if (this.belowValue === null) {
            this.calculateRange();
        }
    }

    setChartName() {
        this.chartName = this.localeService.constants.stringGroupGyWeightGain;
        if (this.group !== null) {
            this.chartName = `${this.group.displayName} - ` + this.localeService.constants.stringGroupGyWeightGain;
        }
    }

    calculateRange() {
        if (this.weightRecords) {
            this.belowValue = Rounder.performRounding({ number: this.weightRecords[Math.ceil(this.weightRecords.length * 0.1)] });
            this.aboveValue = Rounder.performRounding({ number: this.weightRecords[Math.floor(this.weightRecords.length * 0.9)] });
            if (this.belowValue > this.aboveValue) {
                let bv = this.belowValue, av = this.aboveValue;
                this.aboveValue = bv;
                this.belowValue = av;
            }
            if (this.belowValue === null) {
                this.belowValue = 0;
            }
            this.redrawGraph();
        }
    }

    redrawGraph() {
        this.setChartName();
        if (this.weightRecords.length === 0) {
            this.options = {
                series: []
            };
            return;
        }

        let belowCount = 0,
            aboveCount = 0,
            betweenCount = 0,
            bv = this.belowValue,
            av = this.aboveValue,
            self = this;

        for (let i = 0; i < this.weightRecords.length; i++) {
            let roundedWeight = Rounder.round(this.weightRecords[i]);
            if (roundedWeight < this.belowValue) {
                belowCount += 1;
            } else if (roundedWeight < this.aboveValue) {
                betweenCount += 1;
            } else {
                aboveCount += 1;
            }
        }

        let series = {
            type: 'pie',
            name: '',
            innerSize: '50%',
            data: [
                { name: this.localeService.constants.stringBelow + ' ' + this.belowValue, y: belowCount, below: null, above: this.belowValue },
                { name: this.belowValue + ' ' + this.localeService.constants.stringUpTo + ' ' + this.aboveValue, y: betweenCount, below: this.belowValue, above: this.aboveValue },
                { name: this.aboveValue + ' ' + this.localeService.constants.stringAndAbove, y: aboveCount, below: this.aboveValue, above: null }
            ],
            cursor: 'pointer'
        };

        let cName = this.chartName;
        this.options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                events: {
                    beforePrint: function () {
                        this.setTitle({
                        });
                    },
                    afterPrint: function () {
                        this.setTitle({ text: null });
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                formatter: function () {
                    if (this.point.x === 0) {
                        return '<span style="color:black}">\u25CF</span> ' + self.localeService.constants.stringAnimalCount + ':<b>'
                            + this.point.y + '</b><br/><span style="color:black;">\u25CF</span> ' + self.localeService.constants.stringPercent + ':<b>'
                            + (Math.round(this.point.percentage * 100) / 100).toFixed(0) + '%</b><br/><span style="color:black;">\u25CF</span> ' +
                            self.localeService.constants.stringWeightRange + ': <b>' + self.localeService.constants.stringBelow + ' '
                            + bv + '</b>'
                    } else if (this.point.x === 2) {
                        return '<span style="color:black}">\u25CF</span> ' + self.localeService.constants.stringAnimalCount + ':<b>'
                            + this.point.y + '</b><br/><span style="color:black;">\u25CF</span> ' + self.localeService.constants.stringPercent + ':<b>'
                            + (Math.round(this.point.percentage * 100) / 100).toFixed(0) + '%</b><br/><span style="color:black;">\u25CF</span> ' +
                            self.localeService.constants.stringWeightRange + ': <b>'
                            + av + ' ' + self.localeService.constants.stringAndAbove + '</b>'
                    } else {
                        return '<span style="color:black}">\u25CF</span> ' + self.localeService.constants.stringAnimalCount + ':<b>'
                            + this.point.y + '</b><br/><span style="color:black;">\u25CF</span> ' + self.localeService.constants.stringPercent + ':<b>'
                            + (Math.round(this.point.percentage * 100) / 100).toFixed(0) + '%</b><br/><span style="color:black;">\u25CF</span> ' +
                            self.localeService.constants.stringWeightRange + ': <b>'
                            + bv + ' ' + self.localeService.constants.stringUpTo + ' ' + av + '</b>'
                    }
                }
            },
            plotOptions: {
                series: {
                    animation: this.options ? null : 500
                },
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        style: {
                            'stroke': 'none',
                            'fill': 'black',
                            'color': 'black',
                            'font-size': '18px',
                            textOutline: false
                        },
                        format: '<b>{y}</b> ({point.percentage:.0f}%)',
                    },
                    size: '120%',
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '75%'],
                    showInLegend: true,
                    allowPointSelect: false,
                    point: {
                        events: {
                            legendItemClick: function (e) {
                                e.preventDefault();
                            },
                            click: e => {
                                this.globalParameterService.detailsSearchParams.next({
                                    Weight: new DetailsSearchParam(this.localeService.constants.stringWeight,
                                        DetailsSearchParamType.number, e.point.below, e.point.above, null)
                                });
                                // this.router.navigate(['/main/groups/details']);
                                this.changeTab.emit();
                            }
                        }
                    }
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                floating: true,
                itemStyle: {
                    fontSize: '14px',
                    fontFamily: '"Lato", Helvetica, Arial, sans-serif'
                },
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                text: this.localeService.constants.stringPrintChart,
                                onclick: function () {
                                    this.print();
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPDF,
                                onclick: function () {
                                    this.exportChart({
                                        type: 'application/pdf',
                                        filename: cName
                                    });
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPNG,
                                onclick: function () {
                                    this.exportChart({
                                        filename: cName
                                    });
                                }
                            }
                        ]
                    }
                },
                chartOptions: {
                    title: {
                    }
                }
            },
            series: [series]
        };
    }
}

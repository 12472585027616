import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { GroupWeightRecordSplit } from '../../../../models/group-weight-record-split.model';
import { Group } from '../../../../models/group.model';
import { CreateNewGroupService } from '../../../../create-new-group/create-new-group.service';
import { ActionsSoldDeadService } from '../../actions-sold-dead.service';

@Component({
  selector: 'app-actions-sold-dead-model-spliter-number',
  templateUrl: './actions-sold-dead-model-spliter-number.component.html',
  styleUrls: ['./actions-sold-dead-model-spliter-number.component.scss']
})
export class ActionsSoldDeadModelSpliterNumberComponent implements OnInit, OnChanges {
  public static self: ActionsSoldDeadModelSpliterNumberComponent;
  @Input()
  public headerName: string;
  public aboveOrEqualTo = 0;
  public aboveSummary;
  public belowSummary;
  public aboveSelected = true;

  constructor(public actionsSoldDeadService: ActionsSoldDeadService, private createNewGroupService: CreateNewGroupService) {
    ActionsSoldDeadModelSpliterNumberComponent.self = this;
  }

  workoutSummaries() {
    this.aboveSummary = { count: 0, weight: 0, groupId: null, weightRecordIds: [] };
    this.belowSummary = { count: 0, weight: 0, groupId: null, weightRecordIds: [] };
    this.actionsSoldDeadService.animalData.forEach(ad => {
      if (ad[this.headerName] !== null && ad[this.headerName] !== undefined) {
        if (ad[this.headerName] >= this.aboveOrEqualTo) {
          this.aboveSummary.count++;
          this.aboveSummary.weight += ad.weight || 0;
          this.aboveSummary.weightRecordIds.push(ad.weightRecordId);
        } else {
          this.belowSummary.count++;
          this.belowSummary.weight += ad.weight || 0;
          this.belowSummary.weightRecordIds.push(ad.weightRecordId);
        }
      }
    });
    this.aboveSummary.weight = this.aboveSummary.count > 0 ? Math.round(this.aboveSummary.weight / this.aboveSummary.count) : 0;
    this.belowSummary.weight = this.belowSummary.count > 0 ? Math.round(this.belowSummary.weight / this.belowSummary.count) : 0;
  }

  ngOnInit() {
    this.actionsSoldDeadService.getWeightRecordIds = this.getWeightRecordIds;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headerName && changes['headerName'].currentValue) {
      this.workoutSummaries()
    }
  }

  getWeightRecordIds(): Array<number> {
    if (ActionsSoldDeadModelSpliterNumberComponent.self.aboveSelected) {
      return ActionsSoldDeadModelSpliterNumberComponent.self.aboveSummary.weightRecordIds;
    } else {
      return ActionsSoldDeadModelSpliterNumberComponent.self.belowSummary.weightRecordIds;
    }
  }
}

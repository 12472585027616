import { Injectable } from '@angular/core';
import { DetailsSearchParams, DetailsSearchParam } from '../main/models/details-search-params';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';


/**
 * Reason for this service is to be able to pass variables between different pages
 * Eg: Group id, session id, filter params
 */
@Injectable()
export class GlobalParameterService {
    public currentSessionId: number;
    public currentGroupId: number;
    public currentAnimalId: number;

    public appVersion = '';

    public detailsSearchParams = new BehaviorSubject<DetailsSearchParams>(new DetailsSearchParams());
    // : DetailsSearchParams = new DetailsSearchParams();

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart && event.url.toLocaleLowerCase().indexOf('/details') < 0) {
                this.detailsSearchParams.next(new DetailsSearchParams());
            }
        })
    }
}

import {Component, OnInit, Input, Inject, ViewChild, TemplateRef} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {SessionAction} from '../../models/session-action';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {LocaleService} from '../../../helpers/locale.service';
import {SessionService} from '../../api/session.service';
import {Helper} from '../../../helpers/instantiate.helper';
import {ActionsModalService} from './actions-modal.service';
import {ActionsService} from '../actions.service';
import {GroupsService} from '../../services/groups.service';
import {MainService} from '../../main.service';
import {ActionService} from '../../api/action.service';
import {Group} from '../../models/group.model';
import {GlobalLoaderService} from '../../../core/global-loader.service';

@Component({
  templateUrl: './actions-modal.component.html',
  styleUrls: ['./actions-modal.component.scss']
})
export class ActionsModalComponent implements OnInit {
  // @Input() public action: SessionAction;

  @Input() singleAnimal = false;
  // @Output() public actionComplete = new EventEmitter<string>();

  @ViewChild('errorTemplateTitle', { static: true }) errorTemplateTitle: TemplateRef<any>;
  @ViewChild('errorTemplateMessageAnimalCount', { static: true }) errorTemplateMessageAnimalCount: TemplateRef<any>;
  @ViewChild('errorTemplateMessageGroup', { static: true }) errorTemplateMessageGroup: TemplateRef<any>;

  window = window;
  showSplitTab = false;
  lockDoneButton = false;
  filterType: number;
  dropdownItems = [
    { value: true, label: this.localeService.constants.stringMoveToTheNewGroup },
    { value: false, label: this.localeService.constants.stringStayInTheCurrentGroup }
  ];

  constructor(public actionsModalService: ActionsModalService,
              public createNewGroupService: CreateNewGroupService,
              private actionsService: ActionsService,
              private actionService: ActionService,
              private bottomNotificationsService: BottomNotificationsService,
              public localeService: LocaleService,
              private groupsService: GroupsService,
              private sessionService: SessionService,
              private mainService: MainService,
              private globalLoaderService: GlobalLoaderService,
              public ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal) {
  }

  ngOnInit() {
    // update movePriorWeights here if needed
  }

  closeOrDismissModal(group?: Group): void {
    // if (this.createNewGroupService.stage === 0) { // if we are not moving on to create group then close modal
    // this.actionComplete.emit(group && group.groupId ? this.actionsModalService.action.v_ActionId : null);

    if (group && group.groupId && this.actionsModalService.action && this.actionsModalService.action.v_ActionId) {
      this.ngbActiveModal.close(this.actionsModalService.action);
    } else {
      this.ngbActiveModal.dismiss();
    }

    this.clearData();
    this.ngbModal.dismissAll();
    // }
  }

  clearData(): void {
    this.actionsModalService.action = null;
    this.showSplitTab = false;
    this.lockDoneButton = false;
    this.actionsModalService.visible = false;
  }

  updateFilterType(filterType: any) {
    if (filterType && filterType && filterType.type >= 0) {
      this.filterType = filterType.type;
    }
  }

  newGroupSelected(group: Group): void {
    this.save();
  }

  save() {
    if (
      !this.actionsModalService.selectedGroupId &&
      this.actionsModalService.getGroupsWrSplit && this.actionsModalService.getGroupsWrSplit().length === 0
    ) {
      this.bottomNotificationsService.currentMessage.next({
        titleTemplate: this.errorTemplateTitle,
        messageTemplate: this.errorTemplateMessageAnimalCount,
        type: 'failure',
        duration: 6000
      });

      this.globalLoaderService.showGlobalLoader = false;
      return;
    }

    if (!this.actionsModalService.selectedGroupId && !this.actionsModalService.getGroupsWrSplit) {
      this.bottomNotificationsService.currentMessage.next({
        titleTemplate: this.errorTemplateTitle,
        messageTemplate: this.errorTemplateMessageGroup,
        type: 'failure',
        duration: 6000
      });

      this.globalLoaderService.showGlobalLoader = false;
      return;
    }


    this.lockDoneButton = true;
    this.actionsModalService.action.greyOut = true;
    if (this.actionsModalService.action.groupId && !this.mainService.farmChanged.value.groupAssignmentAll) {
      // handling setting this value if the user doesn't change it from the default
      // don't want to set a default value until they hit save though
      this.previousWeightsChanged(false);
    }
    if (this.actionsModalService.selectedGroupId) { // assigning an action
      let newAction = Helper.instantiate(new SessionAction(), Object.assign({}, this.actionsModalService.action));
      newAction.groupId = this.actionsModalService.selectedGroupId;
      newAction.recommendedGroupId = this.actionsModalService.action.recomendedGroup ? this.actionsModalService.action.recomendedGroup.groupId : null;
      if (newAction.recommendedGroupId !== null && newAction.groupId !== newAction.recommendedGroupId) {
        newAction.reassignGroup = true;
      }
      (
        this.singleAnimal
          // this is for just assigning a single Animal to a Group
          ? (this.actionsModalService.action.groupId === null
          ? this.actionService.assignSingleAnimal(newAction, this.mainService.farmChanged.value.groupAssignmentAll)
          : this.actionService.reAssignSingleAnimal(newAction, this.mainService.farmChanged.value.groupAssignmentAll))
          // otherwise do group assignment options for an Action
          : (this.actionsModalService.action.groupId === null
          ? this.actionsService.assignAction(newAction, this.mainService.farmChanged.value.groupAssignmentAll)
          : this.actionsService.reAssignAction(newAction, this.mainService.farmChanged.value.groupAssignmentAll))
      ).subscribe(() => {
        this.actionsService.updateRecommendation();
        let baseMessage = this.actionsModalService.action.ided
          ? (<string>this.localeService.constants.stringAnimalshavebeenassignedtothegroup)
          : (<string>this.localeService.constants.stringWeightsWithoutIDshavebeenassignedtothegroup);
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringAnimalsSuccessfullyAssignedToAGroup,
          message: baseMessage
            .replace('<animalCount/>', this.actionsModalService.action.animalCount.toString())
            .replace('<groupDisplayName/>', this.groupsService.groups.value.find(g => g.groupId === this.actionsModalService.selectedGroupId).groupName),
          type: 'success'
        });
        // this.actionComplete.emit(this.actionsModalService.action.v_ActionId);
        Object.assign(this.actionsModalService.action, newAction);
        this.ngbActiveModal.close(this.actionsModalService.action);
        this.actionsModalService.action = null;
        this.lockDoneButton = false;
        this.globalLoaderService.showGlobalLoader = false;
      }, err => {
        this.globalLoaderService.showGlobalLoader = false;
        this.lockDoneButton = false;
        this.actionsModalService.action.greyOut = false;
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringUpdateFailed,
          message: this.localeService.constants.stringSessionAssignmentFailed,
          type: 'warning'
        });
      });
    } else if (this.actionsModalService.getGroupsWrSplit) { // assining a split action
      let split = this.actionsModalService.getGroupsWrSplit();
      if (split !== null && split.length > 0) {
        let ac = 0;
        split.forEach(s => {
          ac += s.weightRecordIds.length;
        });
        let baseMessage = this.actionsModalService.action.ided
          ? (<string>this.localeService.constants.stringCountAnimalsHaveBeenAssignedToCountGroups)
          : (<string>this.localeService.constants.stringCountWeightsWithoutIDsHaveBeenAssignedToCountGroups);
        let msg = baseMessage
          .replace('<animalCount/>', ac.toString())
          .replace('<groupCount/>', split.length.toString());
        this.actionsService.assignWeightRecordsToGroups(this.actionsModalService.action, split, this.actionsModalService.movePriorWeights).subscribe(() => {
          this.actionsService.updateRecommendation();
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAnimalsSuccessfullyAssignedToGroups,
            message: msg,
            type: 'success'
          });
          // this.actionComplete.emit(this.actionsModalService.action.v_ActionId);
          this.ngbActiveModal.close(this.actionsModalService.action);
          this.actionsModalService.action = null;
          this.lockDoneButton = false;
          this.globalLoaderService.showGlobalLoader = false;
        }, err => {
          this.globalLoaderService.showGlobalLoader = false;
          this.lockDoneButton = false;
          this.actionsModalService.action.greyOut = false;
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUpdateFailed,
            message: this.localeService.constants.stringSessionAssignmentFailed,
            type: 'warning'
          });
        });
      }
    } else {
      this.globalLoaderService.showGlobalLoader = false;
    }
  }

  previousWeightsChanged(keep: boolean) {
    // this.actionsModalService.movePriorWeights = keep;
    this.actionsModalService.action.movePriorWeights = this.actionsModalService.movePriorWeights;
    this.actionService.updateFarmGroupAssignmentProperty(keep);
  }
}

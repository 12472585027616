import { WeightRecord } from './weight-record.model';
import utcToLocalConverter from '../api/utc-to-local-converter';
import utcToLocalProperty from '../api/utc-to-local-converter';

export class Session {
    sessionId?: number;
    sessionName?: string;
    @utcToLocalProperty
    sessionStartDate: string | Date;
    @utcToLocalProperty
    sessionEndDate?: string | Date;
    sessionNote?: string;
    farm_FarmId: number;
    fieldDisplayOrder?: string;
    weightRecords?: Array<WeightRecord>;
    // censuses?: Array<Census>;
    device?: any;

    animalCount = 0;
    averageWeight = 0;
    minWeight = 0;
    maxWeight = 0;
}

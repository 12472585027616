import { UserSubscription } from '../models/user-subscription.model';
import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}

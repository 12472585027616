import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Group } from '../../models/group.model';
import { WeightPlan } from '../../models/weight-plan.model';
import { WeightPlanTemplate } from '../../models/weight-plan-template.model';
import { CreateGroupTemplateHelper } from '../../../helpers/create-group-template-helper';
import { CreateNewGroupService } from '../create-new-group.service';
import { LocaleService } from '../../../helpers/locale.service';
import {SessionAction} from '../../models/session-action';

@Component({
  selector: 'app-new-group-plans',
  templateUrl: './new-group-plans.component.html',
  styleUrls: ['./new-group-plans.component.scss']
})
export class NewGroupPlansComponent implements OnInit {
  @Input() group: Group;
  @Input() templates: WeightPlanTemplate[] = [];
  @Input() sessionAction: SessionAction;
  @Input() step: number;

  @Output() nextStep: EventEmitter<Group> = new EventEmitter<Group>();
  @Output() prevStepOrCancel: EventEmitter<'prev' | 'cancel'> = new EventEmitter();
  @Output() setCanClose: EventEmitter<true | false> = new EventEmitter();

  visible = true;
  window = window;

  // @Output()
  // weightPlanSelected = new EventEmitter<WeightPlan>();
  // @Output()
  // weightPlanName = new EventEmitter<string>();

  // @Input()
  // weightPlanId: number;

  selectedGraph: WeightPlanTemplate;

  constructor(public createNewGroupService: CreateNewGroupService, public localeService: LocaleService) { }

  ngOnInit() {
    this.group = Object.assign({}, this.group);
    // if (this.weightPlanId === 0) {
    //   this.planSelected(null);
    // } else if (this.weightPlanId) {
    //   this.planSelected(this.templates.find(wp => wp.targetWeightPlanTemplateId === this.weightPlanId));
    // }
    this.templates = this.templates.filter(t => t.species_SpeciesId === this.group.species_SpeciesId).slice();

    if (this.templates.length === 0) {
      this.planSelected(null);
      this.next();
    }
  }

  planSelected(weightPlanTemplate: WeightPlanTemplate) {
    // ToDo: workout if there is more then two intersections in twp to not do calculation if not needed.
    // weightPlan = CreateGroupTemplateHelper.AdjustWeightPlanTemplate(weightPlan, this.group, this.session);
    this.selectedGraph = CreateGroupTemplateHelper.AdjustTargetWeightTemplate(weightPlanTemplate, this.group, this.sessionAction);


    // this.weightPlanName.emit(weightPlan.planTemplateName);
    // this.weightPlanSelected.emit({
    //   nominalDateOfBirth: new Date(),
    //   startDate: weightPlan.startDate,
    //   weightPlanPointsJson: JSON.stringify(weightPlan.points),
    //   group_GroupId: this.group.groupId,
    //   templateId: weightPlan.targetWeightPlanTemplateId,
    //   session: this.session
    // });
  }

  next() {
    if (this.selectedGraph) {
      this.group.targetWeightPlan = {
        nominalDateOfBirth: new Date(),
        startDate: this.selectedGraph.startDate,
        weightPlanPointsJson: JSON.stringify(this.selectedGraph.points),
        group_GroupId: this.group.groupId,
        targetWeightPlanTemplate_TargetWeightPlanTemplateId: this.selectedGraph.targetWeightPlanTemplateId,
        planName: this.selectedGraph.planTemplateName
        // action: this.action
      };

      this.nextStep.next(this.group);
    }
  }
}

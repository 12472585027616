import {Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges} from '@angular/core';
import { Integration, IntegratedDevice } from '../../../models/integration';
import { IntegrationService } from '../../../api/integration.service';
import { GroupsService } from '../../../services/groups.service';
import { Group } from '../../../models/group.model';
import { Subscription } from 'rxjs';
import { DatatypeHelper } from '../../../../helpers/datatype.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-remote-wow',
  templateUrl: './remote-wow.component.html',
  styleUrls: ['./remote-wow.component.scss']
})
export class RemoteWowComponent implements OnInit, OnDestroy, OnChanges {
  // public integration: Integration;
  @Input()
  public paddock: IntegratedDevice;
  public group: Group = null;
  public connected = true;
  public loaded = false;
  public connectedMessage = '-';

  private sub: Subscription;

  animalsYesterday = '-';
  animals3Days = '-';
  animalsWeek = '-';

  constructor(private integrationService: IntegrationService, private groupsService: GroupsService) { }

  ngOnInit() {
    this.sub = this.groupsService.groups.subscribe(groups => {
      if (groups && this.paddock.group_GroupId) {
        this.group = Object.assign(new Group(), groups.find(g => g.groupId === this.paddock.group_GroupId), this.group);
      }
      this.loaded = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.connected = true;
    if (this.paddock && (!this.paddock.connected || !this.paddock.lastSeen || new Date().getTime() - new Date(this.paddock.lastSeen).getTime() > 86400000*3)) {
      this.connected = false;
    }

    if (this.paddock.animalsYesterday || this.paddock.animalsYesterday === 0) { // need to allow for zero being a valid value, but JS sees it as false
      this.animalsYesterday = this.paddock.animalsYesterday.toString();
    }
    if (this.paddock.animals3Days || this.paddock.animals3Days === 0) {
      this.animals3Days = this.paddock.animals3Days.toString();
    }
    if (this.paddock.animalsWeek || this.paddock.animalsWeek === 0) {
      this.animalsWeek = this.paddock.animalsWeek.toString();
    }

    if (this.connected) {
      this.connectedMessage = `Paddock ${this.paddock.integratedDeviceName} connected`;
    } else {
      this.connectedMessage = 'No animals recently seen';
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  dayMonthYearString(date: string) {
    return moment.tz(moment.utc(date).format(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland').local().format('D MMM YYYY')
  }

  dateAsUTC(date:string){
    return moment.tz(moment.utc(date).format(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland').utc().toDate()
  }
}

import { Month } from '../../helpers/Month';
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, HostListener, SimpleChange } from '@angular/core';
import { LocaleService } from '../../helpers/locale.service';
import { DatatypeHelper } from '../../helpers/datatype.helper';

@Component({
  selector: 'app-month-picker-popup',
  templateUrl: './month-picker-popup.component.html',
  styleUrls: ['./month-picker-popup.component.scss']
})
export class MonthPickerPopupComponent implements OnInit {
  visible = false;
  selectedYear: number = new Date().getFullYear();
  selectedMonth;
  startYear: number = new Date().getFullYear();
  startMonth;
  endYear: number = new Date().getFullYear();
  endMonth;
  invalid = false;
  startDate: Date = new Date();
  endDate: Date = new Date();

  @Input()
  width = '150px';

  @Input()
  curDates: Array<Date> = [];

  @Output()
  dateChange = new EventEmitter<Date>();

  months: Array<Month> = [];

  hasChanged = false;

  constructor(private monthPickerPl: ElementRef, public localeService: LocaleService) {
    this.selectedMonth = localeService.month[new Date().getMonth()]
    this.startMonth = localeService.month[new Date().getMonth()]
    this.endMonth = localeService.month[new Date().getMonth()]
  }

  @HostListener('window:click', ['$event.target'])
  windowClicked(clickedPl) {
    if (!this.monthPickerPl.nativeElement.contains(clickedPl)) {
      this.visible = false;
    }
  }

  setCurrentDate() {
    let initDate = new Date(this.startYear, this.startMonth.id, 1, 0, 0, 0);
    initDate.setMonth(initDate.getMonth() + 1);
    while (this.isInvalidDate(initDate.getMonth(), initDate.getFullYear())) {
      initDate.setMonth(initDate.getMonth() + 1);
    }
    this.selectedYear = initDate.getFullYear();
    this.selectedMonth = this.localeService.month[initDate.getMonth()];
    this.yearChanged();
  }

  monthChange(newMonth: number) {
    this.hasChanged = true;
    this.selectedMonth = this.localeService.month[newMonth];
    let d = new Date(this.selectedYear, this.selectedMonth.id, 1, 0, 0, 0);
    this.dateChange.emit(d);
    this.visible = false;
  }

  yearChanged() {
    this.months = [];
    this.localeService.month.forEach(m => this.months.push(Object.assign({}, m)));
    for (let i = 0; i < this.months.length; i++) {
      this.months[i].disabled = this.isInvalidDate(i, this.selectedYear);
    }
  }

  isInvalidDate(monthId: number, theYear: number) {
    let testDate = new Date(theYear, monthId, 1, 0, 0, 0);
    let afterEndDate = this.curDates[this.curDates.length - 1] < testDate,
      beforeStartDate = this.curDates[0] > testDate,
      existingDate = this.curDates.filter(function (cd) { return cd.getMonth() === testDate.getMonth() && cd.getFullYear() === testDate.getFullYear() }).length > 0;
    return afterEndDate || beforeStartDate || existingDate;
  }

  getDate(monthId: number) {
    let thisYear = new Date().getFullYear();
    return new Date(thisYear, monthId, 1, 0, 0, 0);
  }

  monthString(date: Date) {
    return DatatypeHelper.tryFormatDateWithMoment(date, 'MMM');
  }

  monthStringForMonthId(monthId: number)
  {
    // 0 => "Jan", but in the locale of the browser (i.e. from moment.locale())
    return DatatypeHelper.monthStringForMonthId(monthId);
  }

  ngOnInit() {
    this.startDate = this.curDates[0];
    this.startMonth = this.localeService.month[new Date(this.startDate).getMonth()];
    this.startYear = new Date(this.startDate).getFullYear();
    this.endDate = this.curDates[this.curDates.length - 1];
    this.endMonth = this.localeService.month[new Date(this.endDate).getMonth()];
    this.endYear = new Date(this.endDate).getFullYear();
    this.hasChanged = false;
    this.yearChanged();
  }
}

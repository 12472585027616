import { Component, OnInit, Input } from '@angular/core';

import { CreateNewGroupService } from '../create-new-group.service';
import {WeightPlanTemplate} from '../../models/weight-plan-template.model';
import {Group} from '../../models/group.model';

@Component({
  selector: 'app-new-group-line',
  templateUrl: './new-group-line.component.html',
  styleUrls: ['./new-group-line.component.scss']
})
export class NewGroupLineComponent implements OnInit {
  @Input() step = 1;
  @Input() group: Group;
  @Input() templates: WeightPlanTemplate[] = [];
  @Input() forcedTemplatesStepDisplay = false;

  constructor(public createNewGroupService: CreateNewGroupService) { }

  ngOnInit() {
  }

  getCircleClass(step: number) {
    return this.step > step ? 'fa-check-circle text-success' : this.step === step ? 'fa-dot-circle' : 'fa-circle opacity-low';
  }
}

import {NgZone, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {PermissionsService} from '../../../auth/permissions.service';
import {LocaleService} from '../../../helpers/locale.service';
import {WeightPlan} from '../../models/weight-plan.model';
import {SessionService} from '../../api/session.service';
import {GroupsService} from '../../services/groups.service';
import {MainService} from '../../main.service';
import {Group} from '../../models/group.model';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {GroupActionsEnum} from '../group-actions.enum';

@Component({
  selector: 'app-groups-all',
  templateUrl: './groups-all.component.html',
  styleUrls: ['./groups-all.component.scss']
})
export class GroupsAllComponent implements OnInit, OnDestroy {
  allGroups: Array<Group> = [];
  groups: Array<Group>;
  stage = 0;
  searchWord = '';

  private groupSub: Subscription;

  displayCurrentGroups = false;
  displayCompletedGroups = false;
  displayAllGroups = false;

  constructor(private mainService: MainService,
              private groupsService: GroupsService,
              private sessionService: SessionService,
              title: Title,
              private localeService: LocaleService,
              public permissionsService: PermissionsService,
              private createNewGroupService: CreateNewGroupService,
              private changeDetectorRef: ChangeDetectorRef,
              public zone: NgZone,
              private ngbModal: NgbModal) {
    title.setTitle(`${this.localeService.constants.stringAllGroups} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
  }

  search() {
    const searchWords = this.searchWord;
    let sw = searchWords.split(' ').filter(s => s.length > 0);
    let groups = this.allGroups;
    if (this.displayCurrentGroups) {
      groups = this.allGroups.filter(g => g.isCurrent);
    } else if (this.displayCompletedGroups) {
      groups = this.allGroups.filter(g => !g.isCurrent);
    }
    if (searchWords && searchWords.replace(' ', '') !== '') {
      this.groups = groups.filter(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.displayName.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        return ret >= sw.length;
      });
    } else {
      this.groups = groups.slice();
    }
  }

  ngOnInit() {
    this.groupSub = this.groupsService.groups.subscribe(grps => {
      if (grps !== null && grps !== undefined) {
        let groups = grps.slice();
        if (this.allGroups) {
          for (let i = 0, l = groups.length; i < l; i++) {
            let oldG = this.allGroups.find(g => g.groupId === groups[i].groupId);
            if (oldG) {
              groups[i] = Object.assign(new Group(), oldG, groups[i]);
            }
          }
        }

        this.zone.run(() => {
          this.groups = groups;
          this.allGroups = groups;

          for (let i = 0, l = groups.length; i < l; i++) {
            this.groups[i] = Object.assign(new Group(), {}, groups[i]);
            this.allGroups[i] = Object.assign(new Group(), {}, groups[i]);
          }
        });
        this.setDisplayGroups('current');
      } else {
        this.allGroups = null;
        this.groups = null;
      }
    });
  }

  ngOnDestroy() {
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
  }

  updatedGraphData(weightPlan: WeightPlan) {
    let group = this.groups.find(g => g.groupId === weightPlan.group_GroupId);
    group.targetWeightPlan = weightPlan;
    group.displayName = `${group.groupName} (${new Date(weightPlan.startDate).getFullYear()})`;
  }

  groupActionDone(action: GroupActionsEnum): void {
    switch (action) {
      case GroupActionsEnum.DELETED:
        // this.router.navigate(['/main/groups/all'], {  });
        break;
      case GroupActionsEnum.EDITED:
        // this.router.navigate(['/main/groups/all']);
        break;
      case GroupActionsEnum.NOT_EDITED:
      case GroupActionsEnum.NOT_DELETED:
      case GroupActionsEnum.SHARED:
      case GroupActionsEnum.NOT_SHARED:
      default:
      // do nothing
    }
  }

  setDisplayGroups(disp: string) {
    switch (disp) {
      case 'completed':
        this.groups = this.allGroups.filter(g => !g.isCurrent);
        break;
      case 'all':
        this.groups = this.allGroups;
        break;
      case 'current':
      default:
        this.groups = this.allGroups.filter(g => g.isCurrent);
    }
    this.displayCurrentGroups = disp === 'current';
    this.displayCompletedGroups = disp === 'completed';
    this.displayAllGroups = disp === 'all';

    this.search();
  }
}

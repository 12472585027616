<app-summary-header
  i18n-headerTitle="@@remotewowdashboard"
  i18n-subHeaderTitle="@@viewkeyremotewowinformation"
  headerTitle="Remote WOW Dashboard"
  subHeaderTitle="View key Remote WOW information and status at a glance">
</app-summary-header>

<div class="page-content page-content-y">

  <div class="row">
    <ng-container *ngIf="integration">
      <div class="col-md-6" *ngFor="let paddock of devices">
        <app-remote-wow [paddock]="paddock" class="widget"></app-remote-wow>
      </div>
    </ng-container>
    <div class="col-md-6 col-12">
      <app-camera-widget class="widget"></app-camera-widget>
    </div>
  </div>

  <app-sub-heading
    *ngIf="showDrafting"
    i18n-headerTitle="@@drafts"
    i18n-subHeaderTitle="@@currentlyactivedrafts"
    headerTitle="Drafts"
    subHeaderTitle="Currently active drafts"
  class="mt-3">
  </app-sub-heading>

  <ng-container *ngIf="activeDrafts && showDrafting">

    <div *ngIf="activeDrafts && activeDrafts.length === 0">
      <app-no-data-found>
        <ng-container i18n="@@youhavenocurrentlyactivedrafts">You have no currently active drafts</ng-container>
      </app-no-data-found>
    </div>

    <div *ngIf="activeDrafts.length > 0">
      <div class="card mb-1 text-center" *ngFor="let draft of activeDrafts">
        <div class="card-body">
          <div class="row flex-align-items-center">

            <!-- icon and draft name-->
            <div class="row col-12 col-md-4 col-lg-5 flex-align-items-center">

              <!-- icon -->
              <div class="col-3 text-left" *ngIf="draft.draftDirectionId; let direction;">
                <div class="draft-direction-icon" [ngClass]="direction === 0 ? 'bg-danger' : 'bg-success'">
                  <i class="fas" [ngClass]="{'fa-minus': direction === 0, 'fa-arrow-right': direction === 1, 'fa-arrow-up': direction === 2, 'fa-arrow-left': direction === 3}"></i>
                </div>
              </div>

              <!-- draft name -->
              <div class="col-9 text-left">
                <div class="flex-align-items-center">
                  <div class="data-display">
                    <h1>
                      <span>{{draft.paddockName}}</span>
                      <span *ngIf="!draft.status || draft.status === 'Pending'" class="label label-pending" i18n="@@pending">Pending</span>
                    </h1>
                    <p>{{draft.draftName}}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- numbers -->
            <div class="row flex-align-items-center col-12 col-md-8 col-lg-7">
              <div class="row flex-align-items-center col-md-10 col-12">
                <div class="col-6 col-sm-3">
                  <div class="data-display">
                      <h2 *ngIf="!draft.expectedDraftCompletionDate" class="mb-0 mt-2 monserrat">{{localeService.constants.stringNA}}</h2>
                      <h2 *ngIf="draft.expectedDraftCompletionDate" class="mb-0 mt-2 monserrat">{{dayMonthString(draft.expectedDraftCompletionDate)}}</h2>
                      <p i18n="abbreviation for Estimated Completion@@estcompletion">Est. Completion</p>
                  </div>
                </div>
                <div class="col-6 col-sm-3 pln">
                  <div class="data-display">
                      <h2 class="mb-0 mt-2 monserrat">
                        <span [appNumberDisplay]="{number: draft.completedAnimalCount}"></span>/<span [appNumberDisplay]="{number: draft.totalAnimalCount}"></span>
                      </h2>
                      <p i18n="@@drafted">Drafted</p>
                    </div>
                </div>
                <div class="col-6 col-sm-3">
                  <div class="data-display">
                      <h2 *ngIf="draft.totalAnimalCount === 0" class="mb-0 mt-2 monserrat">{{localeService.constants.stringNA}}</h2>
                      <h2 *ngIf="draft.totalAnimalCount > 0" class="mb-0 mt-2 monserrat">
                        <span [appNumberDisplay]="{number: (draft.completedAnimalCount/draft.totalAnimalCount) * 100}"></span>%</h2>
                      <p i18n="@@complete">Complete</p>
                    </div>
                </div>
                <div class="col-6 col-sm-3">
                  <a class="action-icon-holder danger" *ngIf="authService.currentUser && authService.currentUser.userType !== 'SuperUser'">
                    <i (click)="deleteDraft(draft.draftId)" class="fas fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>


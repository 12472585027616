import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Group, BaseGroup, GroupCensusWeightSummary } from '../models/group.model';
import { AnimalSummary } from '../models/animal-adg.model';
import { GroupWeightRecordSplit } from '../models/group-weight-record-split.model';
import { map, catchError } from 'rxjs/operators';
import { CensusWeightSummary } from '../models/session-weight-summary';
import { WeightPlanPoint } from '../models/weight-plan.model';
import { stringify } from 'querystring';
import { utcToNZProperty } from './utc-to-local-converter';

import { ConvertToUTC } from '../../helpers/datatype.helper';


@Injectable({
  providedIn: 'root'
})
export class GroupService {
    
    constructor(private apiService: ApiService) { }
    changeGroupColumnDisplayOrder = (groupId: number, fieldDisplayOrder: any): Observable<Group> => {
        return this.apiService.patch(`/odata/Groups(${groupId})`, { fieldDisplayOrder: fieldDisplayOrder });
    }
    assignWeightRecordsToGroups(recommendedGroupId: number, groupAssignments: Array<GroupWeightRecordSplit>, movePriorWeights?: boolean): Observable<any> {
        return this.apiService.post('/odata/Groups/Services.AssignWeightRecordsToGroup', {
            GroupAssignments: groupAssignments, RecommendedGroupId: recommendedGroupId, MovePriorWeights: movePriorWeights
        }, 'text');
    }
    getAnimalSummary(groupId: number): Observable<Array<AnimalSummary>> {
        return this.apiService.get(`/odata/Groups/Services.AnimalSummaries(groupId=${groupId})`).pipe(map((res: any) => res.value));
    }
    updateGroup(groupId: number, changed: any): Observable<Group> {
        return this.apiService.patch(`/odata/Groups(${groupId})`, changed)
    }
    saveGroup(baseGroup: BaseGroup): Observable<Group> {
        return this.apiService.post(`/odata/Groups?$expand=targetWeightPlan` // removed ,census($select=session_SessionId,censusId) (GroupsV2 tech phase)
            , baseGroup);
    }
    getLatestGroups(farmId: number, latestGroupCreated: Date): Observable<Array<Group>> {
        return this.apiService.get(`/odata/Groups?$filter=farm_FarmId eq ${farmId} and `
            + `created gt cast(${encodeURI(new Date(latestGroupCreated).toISOString())},Edm.DateTimeOffset)&$expand=targetWeightPlan`)
            .pipe(map((res: any) => res.value), catchError(this.apiService.handleError));
    }

    getGroup(groupId: number): Observable<Group> {
        return this.apiService.get(`/odata/Groups(${groupId})?$expand=targetWeightPlan`)
        .pipe(map((item: any) => {
            const group = new Group();
            Object.assign(group, item);
            this._createCensus(group.censuses)
            this._setWeightPlansDateToLocal(group);
            return group;
        }));
    }

    getGroups(farmId: number): Observable<Array<Group>> {
        return this.apiService.get(`/odata/Groups?$filter=farm_FarmId eq ${farmId}&$expand=targetWeightPlan`)
            .pipe(map((res: any) => res.value))
            .pipe(
                map((data: any[]) => {
                    return data.map((item: any) => {
                        const group = new Group();
                        Object.assign(group, item);
                        this._createCensus(group.censuses)
                        this._setWeightPlansDateToLocal(group);
                        return group;
                    })
                }
            )
            , catchError(this.apiService.handleError));
    }

  deleteGroup(groupId: number): Observable<any> {
    return this.apiService.delete(`/odata/Groups(${groupId})`, 'text');
  }

  private _createCensus(censuses:GroupCensusWeightSummary[]){
    censuses.map((c,i,arr)=>{
        arr[i]= Object.assign(new GroupCensusWeightSummary(),c);
    })
  }

  /* NOTE: Intentionally not invoking the method. Legacy data points were absolute UTC values, as part of 'timezone changes #MIHUBDRY-5149' 
  the new logic would be absolute NZ dates converted to UTC. refer confluence for more information
  */
  private _setWeightPlansDateToLocal(group:Group):void{
    return;
    if(group.targetWeightPlan === null || group.targetWeightPlan=== undefined){
        return;
    }
    if(group.targetWeightPlan.weightPlanPointsJson === null || group.targetWeightPlan.weightPlanPointsJson=== undefined){
        return;
    }

    let json =group.targetWeightPlan.weightPlanPointsJson;
    if(json === undefined || json === null){
        return;
    }

    const targetWeightPlan= (<Array<WeightPlanPoint>>JSON.parse(json, (name,value)=>{
        if(name ==='date'){
            //return (value as string).replace('Z','');
            value = value.replace("Z", "");
            const dt= ConvertToUTC(value).toISOString();
            return dt;
        }
        return value;
    }))
    group.targetWeightPlan.weightPlanPointsJson = JSON.stringify(targetWeightPlan);
  }
}

import {Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {throwError as observableThrowError, Subscription, throwError} from 'rxjs';

import {PermissionsService} from '../../../auth/permissions.service';
import {LocaleService} from '../../../helpers/locale.service';
import {Group} from '../../models/group.model';
import {GroupsService} from '../../services/groups.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {SessionWeightSummary} from '../../models/session-weight-summary';
import {WeightRecordService} from '../../api/weight-record.service';
import {MainService} from '../../main.service';
import {SessionService} from '../../api/session.service';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';
import {SessionSummary} from '../shared/session-summary.model';
import { ConvertToUTC, DatatypeHelper } from '../../../helpers/datatype.helper';


@Component({
  selector: 'app-sessions-all',
  templateUrl: './sessions-all.component.html',
  styleUrls: ['./sessions-all.component.scss']
})
export class SessionsAllComponent implements OnInit, OnDestroy {
  @ViewChild('actionsDataScroller', { static: false }) actionsDataScroller;
  @ViewChild('deleteSessionConfirmationTitleTemplate', { static: false }) deleteSessionConfirmationTitleTemplate: TemplateRef<any>;
  @ViewChild('deleteSessionConfirmationBodyTemplate', { static: false }) deleteSessionConfirmationBodyTemplate: TemplateRef<any>;

  summaries: Array<SessionWeightSummary>;
  allSummaries: Array<SessionWeightSummary>;
  groups: Array<Group>;
  currentlyDeleting = false;

  private sessionsToDelete: Array<number> = [];
  private subs: Array<Subscription> = [];

  constructor(private sessionService: SessionService,
              private mainService: MainService,
              private weightRecordService: WeightRecordService,
              private bottomNotificationsService: BottomNotificationsService,
              public router: Router,
              private confirmationService: ConfirmationService,
              private groupsService: GroupsService,
              private title: Title,
              private localeService: LocaleService,
              public permissionsService: PermissionsService) {
  }

  ngOnInit() {
    this.title.setTitle(`${this.localeService.constants.stringAllSessions} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);

    this.subs.push(this.sessionService.sessions.subscribe(sessions => {
      if (sessions !== null && sessions !== undefined) {
        let newSessions = sessions.slice()
          .sort((a, b) => new Date(b.sessionStartDate).getTime() - new Date(a.sessionStartDate).getTime());
        if (this.allSummaries && this.allSummaries.length > 0) {
          let deletedSessions = this.allSummaries.filter(s => s.deleted).slice();
          deletedSessions.forEach(session => {
            let index = this.allSummaries.findIndex(s => s.sessionId === session.sessionId);
            if (index >= 0 && session.farm_FarmId === this.mainService.farmChanged.value.farmId) {
              newSessions.splice(index, 0, this.allSummaries[index]);
            }
          })
        }
        this.allSummaries = newSessions;
        this.summaries = this.allSummaries.slice();
        this.setUpGroupNames();
      }
    }));

    this.subs.push(this.groupsService.groups.subscribe(groups => {
      if (groups !== null && groups !== undefined) {
        this.groups = groups;
        this.setUpGroupNames();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  search(searchWords: string) {
    const sw = searchWords.split(' ').filter(s => s.length > 0);
    if (searchWords && searchWords.replace(' ', '') !== '') {
      this.summaries = this.allSummaries.filter(s => {
        let ret = 0;
        sw.forEach(word => {
          ret += s.sessionName.toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
          ret += (new Date(s.sessionStartDate).toString()).substr(4, 11).toLowerCase().indexOf(word.toLowerCase()) >= 0 ? 1 : 0;
        });
        return ret >= sw.length;
      });
    } else {
      this.summaries = this.allSummaries.slice();
    }
  }

  // Set up an array of group names for each session to display on the page
  setUpGroupNames() {
    if (this.allSummaries && this.groups) {
      this.allSummaries.forEach(s => {
        s['groupNames'] = [];
        s.censuses.forEach(c => {
          let group = this.groups.find(g => g.groupId === c.group_GroupId);
          if (group) {
            s['groupNames'].push(group.displayName);
          }
        });
      });
    }
  }

  deleteSession(session: SessionSummary): void {
    this.confirmationService.confirm({
      titleTemplate: this.deleteSessionConfirmationTitleTemplate,
      messageTemplate: this.deleteSessionConfirmationBodyTemplate
    }).subscribe(() => {
      this.deleteConfirmed(session.sessionId);
    });
  }

  deleteConfirmed(sessionId) {
    this.sessionsToDelete.push(sessionId);
    this.summaries.find(s => s.sessionId === sessionId).deleted = true;
    this.summaries.find(s => s.sessionId === sessionId).currentlyDeleting = true;
    this.doTheDelete();
  }

  doTheDelete() {
    if (!this.currentlyDeleting && this.sessionsToDelete.length > 0) {
      this.currentlyDeleting = true;
      const sessionId = this.sessionsToDelete.pop();

      this.sessionService.deleteSession(sessionId).pipe(
        catchError(error => {
          const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';

          this.currentlyDeleting = false;
          this.summaries.find(s => s.sessionId === sessionId).deleted = false;

          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringSaveFailed,
            message: `${this.localeService.constants.stringFailedToDeleteAllData}: ${errMsg}`,
            type: 'warning'
          });

          this.summaries.find(s => s.sessionId === sessionId).currentlyDeleting = false;

          if (this.sessionsToDelete.length > 0) {
            this.doTheDelete();
          }

          return throwError(errMsg);
        })).subscribe(res => {

        if (res != null && res !== undefined) {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringDeleteSuccessful,
            message: this.localeService.constants.stringSuccessfullyDeletedAllSessionAndAnimalData,
            type: 'success'
          });
          this.currentlyDeleting = false;
          this.summaries.find(s => s.sessionId === sessionId).currentlyDeleting = false;
          if (this.sessionsToDelete.length > 0) {
            this.doTheDelete();
          }
        }

      });
    }

    this.allSummaries = this.allSummaries.filter((v, i, a) => a.indexOf(v) === i);
  }

  public dayMonthString(summary: SessionWeightSummary) {
    return ConvertToUTC(summary.sessionStartDate).format('D MMM');
  }

  public yearString(summary: SessionWeightSummary) {
    return ConvertToUTC(summary.sessionStartDate).format('YYYY');
  }
}

<!-- <app-create-group #createGroupModal *ngIf="stage > 0" [stage]="stage" (stageChange)="stage = $event">
</app-create-group> -->

<!--<app-invite-modal #inviteModal [groups]="allGroups" defaultFarmRoleId="3"></app-invite-modal>-->

<app-summary-header
  i18n-headerTitle="@@allgroups"
  headerTitle="All Groups"
  i18n-subHeaderTitle="@@aquickviewofhowallyourgroupsaretracking"
  subHeaderTitle="A quick view of how all your groups are tracking">

  <ng-container *ngIf="allGroups && allGroups.length > 0">
    <app-groups-actions [allGroups]="allGroups"></app-groups-actions>
  </ng-container>

</app-summary-header>

<app-loading *ngIf="!allGroups"></app-loading>

<div class="page-content page-content-y" *ngIf="groups">

  <ng-container *ngIf="allGroups && allGroups.length > 0">

    <app-all-groups-header [groups]="groups"></app-all-groups-header>

    
    <div class="input-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
        </div>
        <input type="text" i18n-placeholder="placeholder text for a filter input@@quicksearch" placeholder="Quick search" class="form-control"
               [(ngModel)]="searchWord" [ngModelOptions]="{ standalone: true }" (keyup)="search()">
        <div class="btn-group">
            <button class="btn" [class.btn-primary]="displayCurrentGroups" [class.btn-secondary]="!displayCurrentGroups" (click)="setDisplayGroups('current')" i18n="@@Current">Current</button>
            <button class="btn" [class.btn-primary]="displayCompletedGroups" [class.btn-secondary]="!displayCompletedGroups" (click)="setDisplayGroups('completed')" i18n="@@Completed">Completed</button>
            <button class="btn" [class.btn-primary]="displayAllGroups" [class.btn-secondary]="!displayAllGroups" (click)="setDisplayGroups('all')" i18n="@@All">All</button>
        </div>
    </div>

    <div *ngIf="displayCurrentGroups && groups.length === 0" class="noDataBlock text-center text-shady-lady">
      <i class="fa fa-calendar-alt"></i>
      <h3 i18n="@@nocurrentgroups">No current groups</h3>
      <div class="innerDescription">
        <ng-container i18n="@@createanewgroupandassignanimalstotracktheirperformancetoatargetweight">
          Create a new group and assign animals to track their performance to a target weight</ng-container>.
      </div>
    </div>
    <div *ngIf="displayCompletedGroups && groups.length === 0" class="noDataBlock text-center text-shady-lady">
      <i class="fa fa-calendar-check"></i>
      <h3 i18n="@@nocompletedgroups">No completed groups</h3>
      <div class="innerDescription">
        <ng-container i18n="@@groupswillappearherewhentheyarepastthetargetenddateandcontainnocurrentanimals">
          Groups will appear here when they are past the target end date and contain no current animals</ng-container>.
      </div>
    </div>

    <div class="row">

      <div *ngFor="let group of groups" class="col-xl-4 col-lg-6">

        <a class="card card-list" [routerLink]="['/main/groups/summary']" [queryParams]="{id: group.groupId}">

          <div class="card-header">
            <h3>
              {{group.displayName}}
            </h3>
            <div class="ml-auto">
              <app-group-actions [group]="group" initialTemplate="LIST" (actionDone)="groupActionDone($event)"></app-group-actions>
            </div>
          </div>

          <div class="card-body">

            <div class="row text-center group-summary">

              <ng-container *ngIf="group.censuses.length > 0">
                <div class="col-6 col-sm-4">
                  <h2 [appNumberDisplay]="{number: group.currentStatus.animalCount}"></h2>
                  <p class="text-shady-lady" i18n="@@animals">Animals</p>
                </div>
                <div class="col-6 col-sm-4">
                  <h2 [appNumberDisplay]="{number: group.currentStatus.averageWeight}"></h2>
                  <p class="text-shady-lady" i18n="@@averageweight">Average Weight</p>
                </div>
                <div class="col-sm-4">
                  <h2 [appAdg]="group"></h2>
                  <p class="text-shady-lady">
                    <ng-container i18n="acronym for Average Daily Gain@@adg">ADG</ng-container>
                    <app-adg-icon [group]="group" class="ml-1"></app-adg-icon>
                  </p>
                </div>
              </ng-container>

              <ng-container *ngIf="group.censuses.length === 0">
                <div class="col-12">
                  <p i18n="@@noanimalsassigned">No animals assigned</p>
                </div>
              </ng-container>

            </div>

            <div class="mt-3">
              <app-groups-small-graph [group]="group"></app-groups-small-graph>
            </div>

          </div>
        </a>

      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="allGroups && allGroups.length == 0">

<!--    <app-no-data-found (onClick)="createNewGroupService.createGroup()" i18n-buttonText="@@creategroupcaps" buttonText="CREATE GROUP"-->
<!--                       [video]="['https://www.youtube.com/embed/u9Q48yzbdiU', 'https://www.youtube.com/embed/HTm5tjJSYH4']">-->
    <app-no-data-found (onClick)="createNewGroupService.createGroup()" i18n-buttonText="@@creategroupcaps" buttonText="CREATE GROUP">
      <ng-container i18n="@@trackyouranimalstotargetweightsbycreatinggroups">Track your animals to target weights by creating groups.</ng-container>
      <br/>
      <ng-container i18n="@@createagroupforeachmobofanimalsyouweigh">Create a group for each mob of animals you weigh.</ng-container>
<!--      <br/>-->
<!--      <ng-container i18n="@@viewweightlifeandsessiondataforeachanimalinyourgroup">View weight, life and session data for each animal in your group.</ng-container>-->
<!--      <br/>-->
<!--      <ng-container i18n="@@animalsdetailscanonlybeshownforanimalswithauniqueid">Animals details can only be shown for animals with a unique ID.</ng-container>-->
    </app-no-data-found>

  </ng-container>

</div>

import { BottomNotificationsService } from '../components/bottom-notifications/bottom-notifications.service';
import { Farm } from './models/farm.model';
import { AuthService } from '../auth/auth.service';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { LocaleService } from '../helpers/locale.service';
import { PermissionsService } from '../auth/permissions.service';


@Injectable()
export class GroupViewerGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private localeService: LocaleService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (PermissionsService.self && PermissionsService.self.currentFarmRoleId > 2) {
            return false;
        }
        return true;
    }
}

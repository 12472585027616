<!-- farm menu -->
<app-farm-menu></app-farm-menu>

<ul class="nav sidebar-menu">

  <li *ngIf="showAllMenus">
    <a routerLink="dashboard" [routerLinkActive]="['active']">
      <span class="ci ci-dashboard-template"></span>
      <span class="sidebar-title" i18n="@@dashboard">Dashboard</span>
    </a>
  </li>

  <li #dashboardMenu *ngIf="integrationService.integration.value && showAllMenus">
    <a routerLink="wow-dashboard" [routerLinkActive]="['active']">
      <i class="far fa-house-signal"></i>
      <span class="sidebar-title" i18n="@@remotewow">Remote WOW</span>
    </a>
  </li>

  <li *ngIf="showAllMenus">
    <a routerLink="actions" [routerLinkActive]="['active']">
      <span class="ci ci-notif-custom"></span>
      <span i18n="@@actions">Actions</span>
    </a>
  </li>

  <li *ngIf="showAllMenus">
    <a routerLink="transfer" [routerLinkActive]="['active']">
      <span class="ci ci-record-custom"></span>
      <span i18n="@@upload">Upload</span>
    </a>
  </li>

  <li>
    <a routerLink="groups" [routerLinkActive]="['active']">
      <span class="ci ci-groups-custom"></span>
      <span i18n="@@groups">Groups</span>
    </a>
  </li>

  <li>
    <a routerLink="sessions" [routerLinkActive]="['active']">
      <span class="ci ci-session-custom"></span>
      <span i18n="@@sessions">Sessions</span>
    </a>
  </li>

  <li>
    <a routerLink="animals" [routerLinkActive]="['active']">
      <span class="ci ci-animals-custom"></span>
      <span i18n="@@animals">Animals</span>
    </a>
  </li>

  <li #adminMenu *ngIf="authService.currentUser && authService.currentUser.userType === 'SuperUser'">
    <a class=" menu-open" routerLink="admin/file-import" [routerLinkActive]="['active']">
      <span class="ci ci-groups-custom"></span>
      <span i18n="abbreviation for Administration@@admin">Admin</span>
      <span class="caret"></span>
    </a>
    <ul class="nav sub-nav">
      <li>
        <a routerLink="admin/users" [routerLinkActive]="['active']">Manage Users</a>
      </li>
      <li>
        <a routerLink="admin/farms" [routerLinkActive]="['active']">Manage Farms</a>
      </li>
      <li>
        <a routerLink="admin/traceability" [routerLinkActive]="['active']">Traceability</a>
      </li>
      <!--
		commented out currently as we need to figure out how to whitelist through the load balancer
	  <li [routerLinkActive]="['active']">
		<a (click)="hideMenu()" routerLink="admin/whitelist-ips" class="pl50">Whitelist IPs</a>
	  </li>
	-->
    </ul>
  </li>
</ul>

<!-- user menu -->
<app-user-menu></app-user-menu>

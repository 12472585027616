import { Group, GroupCensusWeightSummary } from '../../../models/group.model';
import { Rounder } from '../../../../helpers/rounder.helper';
import { WeightPlanPoint } from '../../../models/weight-plan.model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocaleService } from '../../../../helpers/locale.service';
import { EArray } from '../../../../helpers/constants.helper';
import {PermissionsService} from '../../../../auth/permissions.service';
import {IntegrationService} from '../../../api/integration.service';

@Component({
    selector: 'app-group-summary-weight-gain',
    templateUrl: './group-summary-weight-gain.component.html',
    styleUrls: ['./group-summary-weight-gain.component.scss']
})
export class GroupSummaryWeightGainComponent implements OnInit, OnChanges {
    @Input()
    group: Group;

    options: any;
    chartName: string;

    isPplFarmWithIntegration = false;

    constructor(private localeService: LocaleService, private permissionsService: PermissionsService, private integrationService: IntegrationService) { }

    // Redraw graph when new Group Weight
    ngOnChanges(changes: SimpleChanges) {
        if (changes['group'] && changes['group'].currentValue) {
            this.redrawGraph();
        }
    }


    ngOnInit() {
        this.group.soldDiedLeftGroupSummaries = this.group.soldDiedLeftGroupSummaries.filter(s => s.group_GroupId === this.group.groupId).slice();
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }
        this.redrawGraph();
    }

    redrawGraph() {
        if (this.group === null || this.group.targetWeightPlan === null) {
            this.options = {
                series: []
            };
            return;
        }

        this.chartName = `${this.group.displayName} - ` + this.localeService.constants.stringWeightGainGraph;
        let chartPoints = this.group.censuses.filter(cp => cp.averageWeight).slice();

        chartPoints.sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
        // need the below filter included as editing a Group can sometimes result in some extra sold-died summaries being passed through via the change detector
        let soldDiedLeftGroupPoints = this.group.soldDiedLeftGroupSummaries.filter(s => s.group_GroupId === this.group.groupId).slice().map((point) => {
            let dataLabels = Array<any>();
            if (point.soldCount > 0) {
                dataLabels.push({
                    order: 98,
                    html: `<span style="color:#ADB5BD;">\u25CF</span> ${this.localeService.constants.stringSold}: ${point.soldCount}`,
                    dontShare: true
                });
            }
            if (point.diedCount > 0) {
                dataLabels.push({
                    order: 97,
                    html: `<span style="color:#ADB5BD;">\u25CF</span> ${this.localeService.constants.stringDied}: ${point.diedCount}`,
                    dontShare: true
                });
            }
            if (point.leftGroupCount > 0) {
                dataLabels.push({
                    order: 96,
                    html: `<span style="color:#ADB5BD;">\u25CF</span> ${this.localeService.constants.stringMovedOutOfGroup}: ${point.leftGroupCount}`,
                    dontShare: true
                });
            }

            return {
                x: new Date(point.timeStamp)['getRoundedTime'](),
                y: 0,
                extra: dataLabels
            };
        });

        let points = chartPoints.map((point) => {
            let tenPcNumber = Math.ceil(point.animalCount / 10),
                tenPcAnimalsString = tenPcNumber === 1 ? `${tenPcNumber} ${this.localeService.constants.stringAnimal}`
                    : `${tenPcNumber} ${this.localeService.constants.stringAnimals}`;
            return {
                x: new Date(point.timeStamp)['getRoundedTime'](),
                y: point.averageWeight,
                extra: [
                    { order: 99,
                      html: `<span style="color:black;">\u25CF</span> ${this.localeService.constants.stringAnimalCount}: ${point.animalCount}`
                    },
                    { order: 2,
                      html: `<span style="color:rgb(157, 206, 249)">\u25CF</span> ${this.localeService.constants.stringTop} 10%: ${tenPcAnimalsString} > ${point.topWeight}`
                    },
                    { order: 1,
                      html: `<span style="color:rgb(165, 206, 243)">\u25CF</span> ${this.localeService.constants.stringBottom} 10%: ${tenPcAnimalsString} < ${point.bottomWeight}`
                    }
                ]
            };
        });

        let zones = []; // if this is an empty array it will have no impact on the graph
        if (this.isPplFarmWithIntegration) {
            let lastIdx = chartPoints.length - 1;
            let lastPt = chartPoints[lastIdx];
            if (lastPt && (new Date(lastPt.timeStamp)).getUTCDay() !== 0) { // if it's a rolling session, so the data is still being incremented
                chartPoints.splice(lastIdx); // remove the rolling session from the standard datapoints
                points.forEach(p => {
                    zones.push({
                        value: p.x + 0.1, // need the slight overage to ensure we don't get previous colour coming off the end
                        color: '#00AAE7'
                    });
                });
                zones[lastIdx] = { // changing colour of final point, which also changes colour of line from previous point to match
                    value: points[lastIdx].x + 0.1, // need the slight overage to ensure we don't get previous colour coming off the end
                    color: '#ADB5BD'
                };
                zones[lastIdx + 1] = { // set a default colour for the line to override the "global" colours used for our highcharts
                    color: '#ADB5BD'
                };
            }
        }

        let originalTargetData = (<Array<WeightPlanPoint>>JSON.parse(this.group.targetWeightPlan.weightPlanPointsJson))
        .map(e => { return { x: new Date(e.date).getTime(), y: e.weight }; }).sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
        let refactoredTargetData = originalTargetData.slice();
        points.forEach(p => {
            let pt = new Date(p.x).getTime();
            let priorTargets = originalTargetData.filter(m => new Date(m.x).getTime() < pt);
            let f = priorTargets[priorTargets.length - 1];
            let l = originalTargetData.filter(m => new Date(m.x).getTime() > pt)[0];

            if (l && f) {
                let ft = new Date(f.x).getTime();
                let lt = new Date(l.x).getTime();
                let totalDays = Math.round((lt - ft) / 86400000);
                let dg = Math.round(((l.y - f.y) / totalDays) * 1000) / 1000;
                if (pt > ft && pt < lt) {
                    let days = Math.round((pt - ft) / 86400000);
                    let diff = days * dg;
                    refactoredTargetData.push({ x: pt, y: diff + f.y });
                }
            }
        });

        let xAxis: any = {
                type: 'datetime',
                tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
                dateTimeLabelFormats: {
                    month: '%b %Y'
                },
                labels: {
                    style: {
                        fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                        fontSize: '12px'
                    }
                },
            },

            series = [{
                name: this.localeService.constants.stringAverage,
                id: 'average',
                allowPointSelect: true,
                data: points,
                marker: { enabled: true, lineWidth: 2, symbol: 'circle' },
                zIndex: 4,
                type: 'line',
                zones: zones,
                zoneAxis: 'x'
            }, {
                hideMyDataFromToolTips: true, // this flag prevents the data for this series from being included in tooltips
                name: this.localeService.constants.stringTarget,
                allowPointSelect: true,
                lineColor: '#69B342',
                data: originalTargetData,
                marker: { enabled: true, lineWidth: 1, lineColor: '#69B342', symbol: 'diamond' },
                zIndex: 5,
                type: 'line',
                enableMouseTracking: true
            }, {
                name: this.localeService.constants.stringTarget,
                allowPointSelect: true,
                data: refactoredTargetData, // this is the refactored data to actually populate the tooltips with the Target values
                lineWidth: 0,
                lineColor: '#69B342',
                color: '#69B342',
                marker: {
                    enabled: false,
                    lineColor: '#69B342',
                    states: {
                        hover: {
                            enabled: false,
                            lineColor: '#69B342'
                        }
                    }
                },
                zIndex: 5,
                type: 'spline'
            }, {
                name: this.localeService.constants.stringMinToMax,
                data: chartPoints.map(point => [new Date(point.timeStamp)['getRoundedTime'](), point.minWeight, point.maxWeight]),
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                type: 'areasplinerange',
                linkedTo: 'average',
                color: 'rgb(149, 206, 255)',
                fillOpacity: 0.4,
                zIndex: 3
            }, {
                name: this.localeService.constants.stringBottom + '-' + this.localeService.constants.stringTop + ' 10%',
                data: chartPoints.map(point => [new Date(point.timeStamp)['getRoundedTime'](), point.bottomWeight, point.topWeight]),
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                type: 'areasplinerange',
                linkedTo: 'average',
                color: 'rgb(149, 206, 255)',
                fillOpacity: 1,
                lineWidth: 0,
                zIndex: -99
            },
            {
                name: 'solddiedpoints',
                allowPointSelect: true,
                data: soldDiedLeftGroupPoints,
                lineWidth: 0,
                color: '#ADB5BD',
                marker: {
                    enabled: true,
                    width: 8,
                    height: 30,
                    symbol: 'url(assets/custom-icons/pointer_dark.png)'
                },
                type: 'line'
            },
        ];

        let cName = this.chartName;
        this.options = {
            title: {
                text: null
            },

            subtitle: {
                text: null
            },
            xAxis: xAxis,
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                        fontSize: '12px'
                    }
                },
                lineWidth: 1,
                min: 0
            },

            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                text: this.localeService.constants.stringPrintChart,
                                onclick: function () {
                                    this.print();
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPDF,
                                onclick: function () {
                                    this.exportChart({
                                        type: 'application/pdf',
                                        filename: cName
                                    });
                                }
                            },
                            {
                                text: this.localeService.constants.stringDownloadPNG,
                                onclick: function () {
                                    this.exportChart({
                                        filename: cName
                                    });
                                }
                            }
                        ]
                    }
                },
            },
            series: series
        };
    }

}

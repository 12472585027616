<app-summary-header
  i18n-headerTitle="@@sessions"
  headerTitle="Sessions"
  i18n-subHeaderTitle="@@aquickviewofallsessions"
  subHeaderTitle="A quick view of all sessions">
</app-summary-header>

<app-loading *ngIf="!allSummaries"></app-loading>

<div class="page-content page-content-y" *ngIf="allSummaries">

  <!-- no data -->
  <ng-container *ngIf="allSummaries.length === 0">
    <app-no-data-found i18n-buttonText="@@uploadsessionfile" buttonText="Upload Session File" (onClick)="router.navigate(['/main/transfer']);"
                       i18n="@@uploadyoursessionfilessoyoucanassignthemtogroups">
      Upload your session files so you can assign them to groups.
    </app-no-data-found>
  </ng-container>

  <ng-container *ngIf="allSummaries.length > 0">

    <!-- filter -->
    <div class="quick-search">
      <app-form-input icon="fa-search">
        <input type="text" i18n-placeholder="placeholder text for a filter input@@quicksearch" placeholder="Filter" (keyup)="search($event.target.value)" class="form-control">
      </app-form-input>
    </div>

    <!-- list -->
    <p-dataScroller [value]="summaries" [rows]="10" #actionsDataScroller>

      <ng-template let-summary pTemplate="item">
        <a *ngIf="allSummaries.length > 0" class="card card-list" routerLink="/main/sessions/summary" [queryParams]="{id:summary.sessionId}">

          <div class="card-blocker" (click)="$event.stopPropagation(); $event.preventDefault();" *ngIf="summary.deleted || summary.currentlyDeleting"></div>

          <div class="card-body" [class.deleted]="summary.deleted">

            <div class="row flex-align-items-center">

              <div class="col-lg-5">
                <div>
                  <h3 class="mb-0">
                    {{summary.sessionName}}
<!--                    <a routerLink="/main/sessions/details" [queryParams]="{id:summary.sessionId}" *ngIf="!summary.deleted">{{summary.sessionName}}</a>-->
<!--                    <ng-container *ngIf="summary.deleted">{{summary.sessionName}}</ng-container>-->
                  </h3>
                  <p class="text-shady-lady ">
                    <ng-container i18n="@@groups">Groups</ng-container>:
                    <span *ngIf="!summary.groupNames || summary.groupNames.length == 0" i18n="@@unassigned">Unassigned</span>
                    <span *ngFor="let name of summary.groupNames; let i = index">{{i > 0 ? ',' : ''}} {{name}} </span>
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="summary-header">

                  <div class="row">

                    <div class="col-sm-3">
                      <h3>{{dayMonthString(summary)}}</h3>
                      <p class="text-shady-lady ">{{yearString(summary)}}</p>
                    </div>

                    <div class="col-sm-3">
                      <h3 [appNumberDisplay]="{number: summary.animalCount}"></h3>
                      <p *ngIf="summary.animalCount === 1" class="text-shady-lady " i18n="@@animal">Animal</p>
                      <p *ngIf="summary.animalCount !== 1" class="text-shady-lady " i18n="@@animals">Animals</p>
                    </div>

                    <div class="col-sm-3">
                      <h3 [appNumberDisplay]="{number: summary.averageWeight} "></h3>
                      <p class="text-shady-lady " i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
                    </div>

<!--                    <div class="col-md-3" *ngIf="summary.invalidRowCount && summary.invalidRowCount !== 0; else noInvalidRows;">-->
                    <div class="col-md-3" *ngIf="summary.invalidRowCount && summary.invalidRowCount !== 0">
                      <h3 [appNumberDisplay]="{number: summary.invalidRowCount}" class="text-danger"></h3>
                      <p *ngIf="summary.invalidRowCount === 1" class="text-shady-lady">Invalid Row</p>
                      <p *ngIf="summary.invalidRowCount !== 1" class="text-shady-lady">Invalid Rows</p>
                    </div>

                    <ng-template class="col-sm-3" #noInvalidRows>
                      <div class="col-md-3">
                        <h3 class="text-muted">0</h3>
                        <p class="text-muted text-shady-lady">Invalid Rows</p>
                      </div>
                    </ng-template>

                  </div>


                </div>
              </div>

              <div *ngIf="!summary.deleted && permissionsService.permissions.sessionsDelete" class="col-lg-1 mb-0">
                <a (click)="$event.stopPropagation(); $event.preventDefault(); deleteSession(summary);" class="action-icon-holder danger">
                  <i class="fas fa-trash"></i>
                </a>
              </div>
              <div *ngIf="summary.deleted && permissionsService.permissions.sessionsDelete && summary.currentlyDeleting" class="col-md-1">
                <i class="fa fa-circle-o-notch fa-spin"></i>
              </div>

            </div>
          </div>
        </a>
      </ng-template>
    </p-dataScroller>
  </ng-container>
</div>


<ng-template #deleteSessionConfirmationTitleTemplate>
  <ng-container i18n="@@deletesessioncaps">DELETE SESSION</ng-container>
</ng-template>

<ng-template #deleteSessionConfirmationBodyTemplate>
  <ng-container i18n="@@stringAreYouSureYouWantToDeleteThisSession">Are you sure that you want to delete this session?</ng-container>
</ng-template>

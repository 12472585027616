import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {PermissionsService} from '../../../auth/permissions.service';
import {Group} from '../../models/group.model';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';
import {LocaleService} from '../../../helpers/locale.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {GroupActionsEnum} from '../group-actions.enum';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {InviteModalComponent} from '../../invite/invite-modal/invite-modal.component';
import {InvitationFarmRoleId} from '../../models';
import {PageActionsComponent} from '../../../shared/page-actions/page-actions.component';
import {GlobalLoaderService} from '../../../core/global-loader.service';
import {GroupService} from '../../api/group.service';
import {GroupsService} from '../../services/groups.service';

@Component({
  selector: 'app-group-actions',
  templateUrl: './group-actions.component.html',
  styleUrls: ['./group-actions.component.scss']
})
export class GroupActionsComponent extends PageActionsComponent {
  @Input() group: Group;
  @Output() actionDone: EventEmitter<GroupActionsEnum> = new EventEmitter<GroupActionsEnum>();

  constructor(
    public permissionsService: PermissionsService,
    private confirmationService: ConfirmationService,
    private localeService: LocaleService,
    private groupService: GroupService,
    private groupsService: GroupsService,
    private bottomNotificationsService: BottomNotificationsService,
    private createNewGroupService: CreateNewGroupService,
    private ngbModal: NgbModal,
    private globalLoaderService: GlobalLoaderService) {
    super();
  }

  deleteGroup(): void {
    this.confirmationService.confirm({
      title: this.localeService.constants.stringDeleteGroup,
      message: this.localeService.constants.stringAreYouSureThatYouWantToDeleteGroup + ' ' + this.group.displayName + '?'
    }).subscribe(() => {
      this.doDeleteGroup();
    }, () => {
    });
  }

  editGroup(): void {
    this.createNewGroupService.editGroup(this.group).result.then((updatedGroup: Group) => {
      this.actionDone.emit(GroupActionsEnum.EDITED);
    }, () => {
      this.actionDone.emit(GroupActionsEnum.NOT_EDITED);
    });
  }

  shareGroup(): void {
    const modalRef: NgbModalRef = this.ngbModal.open(InviteModalComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    modalRef.componentInstance.defaultGroup = this.group;
    modalRef.componentInstance.defaultFarmRoleId = InvitationFarmRoleId.GroupReport; // 3
  }

  private doDeleteGroup() {
    this.globalLoaderService.showGlobalLoader = true;

    this.groupService.deleteGroup(this.group.groupId).subscribe(res => {
      this.globalLoaderService.showGlobalLoader = false;
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringDeleteSuccessful,
        message: this.localeService.constants.stringGroupSuccessfullyDeleted,
        type: 'success'
      });

      const indexToDelete = this.groupsService.groups.value.findIndex(group => group.groupId === this.group.groupId);

      if (indexToDelete > -1) {
        const allGroups = this.groupsService.groups.value.slice();
        allGroups.splice(indexToDelete, 1);
        this.groupsService.groups.next(allGroups);
      }

      this.actionDone.emit(GroupActionsEnum.DELETED);
    }, error => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';

      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringSaveFailed,
        message: `${this.localeService.constants.stringFailedToDeleteAllData}: ${errMsg}`,
        type: 'warning'
      });

      this.globalLoaderService.showGlobalLoader = false;
      this.actionDone.emit(GroupActionsEnum.NOT_DELETED);
    });
  }
}

import {Component, OnInit, Output, EventEmitter, OnChanges, Input, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {ActionsSoldDeadService} from './actions-sold-dead.service';
import {AnimalStatusEnum} from '../../animals/shared/animal.model';
import {AnimalService} from '../../api/animal.service';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {LocaleService} from '../../../helpers/locale.service';
import {IntercomService} from '../../intercom.service';
import {MainService} from '../../main.service';
import {AuthService} from '../../../auth/auth.service';
import {WeightRecordService} from '../../api';
import utcToLocalProperty from '../../api/utc-to-local-converter';

@Component({
  templateUrl: './actions-sold-dead-modal.component.html',
  styleUrls: ['./actions-sold-dead-modal.component.scss']
})
export class ActionsSoldDeadModalComponent implements OnInit, OnChanges {
  @Input() trigger = false;
  @Output() actionComplete = new EventEmitter<string>();

  window = window;
  showSplitTab = false;
  splitFilterType: number;
  sendingUpdate = false;
  actionDate = this.mainService.setDate();

  private opened = false;

  constructor(public createNewGroupService: CreateNewGroupService, public actionsSoldDeadService: ActionsSoldDeadService
    , private animalService: AnimalService, private bottomNotificationsService: BottomNotificationsService
    , public localeService: LocaleService, private router: Router, private intercomService: IntercomService
    , private authService: AuthService, private mainService: MainService, private weightRecordService: WeightRecordService, private ngbActiveModal: NgbActiveModal) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.actionDate = this.mainService.setDate(this.mainService.actionDate);
  }

  ngOnInit() {
    this.sendingUpdate = false;
    this.actionDate = this.actionsSoldDeadService.action.sessionId > 0 ? this.mainService.setDate(this.actionsSoldDeadService.action.sessionStartDate) : this.mainService.setDate();
  }

  dismissModal() {
    this.ngbActiveModal.dismiss();
    // this.actionComplete.emit(null);
    this.actionsSoldDeadService.action = null;
    this.actionsSoldDeadService.actionType = AnimalStatusEnum.none;
    this.sendingUpdate = false;
    this.actionsSoldDeadService.visible = false;
  }

  selectedFilterChanged(filter: any) {
    if (filter && filter.type >= 0) {
      this.splitFilterType = filter.type;
    }
  }

  save() {
    this.sendingUpdate = true;
    this.actionsSoldDeadService.action.sessionStartDate = this.actionDate;
    if (this.actionsSoldDeadService.getWeightRecordIds) {
      // split an action as sold or died
      let ids = this.actionsSoldDeadService.getWeightRecordIds();
      // if no sessionId, then it's from Group Details page or Animals All page, so use currentDate
      (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
        ? this.animalService.updateAnimalsStatusesSoldByWRIds(ids, this.actionsSoldDeadService.action.sessionId
          , this.actionsSoldDeadService.action.groupId, this.actionDate)
        : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
          ? this.animalService.updateAnimalsStatusesDiedByWRIds(ids, this.actionsSoldDeadService.action.sessionId
            , this.actionsSoldDeadService.action.groupId, this.actionDate)
          : this.animalService.updateAnimalsStatusesCurrentByWRIds(ids, this.actionsSoldDeadService.action.sessionId
            , this.actionsSoldDeadService.action.groupId, this.actionDate))
        .subscribe(res => {
          let eventName = this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
            ? 'Mark Animal Sold by IDs'
            : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
              ? 'Mark Animal Died by IDs'
              : 'Mark Animal Current by IDs';
          this.intercomService.logEvent(eventName, this.router.url, {
            user_id: this.authService.currentUser.userId,
            farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
            sessionId: this.actionsSoldDeadService.action.sessionId,
            weightRecordIds: ids
          });
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAnimalsSuccessfullyAssignedAsSoldDead,
            message: this.localeService.constants.stringSelectedAnimalsHaveBeenMarkedAs + ' '
              + (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold ? this.localeService.constants.stringSold
                : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead ? this.localeService.constants.stringDead
                  : this.localeService.constants.stringCurrent),
            type: 'success'
          });
          this.actionComplete.emit(this.actionsSoldDeadService.action.v_ActionId);
          this.ngbActiveModal.close(this.actionsSoldDeadService.action.v_ActionId);
          // this.actionsSoldDeadService.action = null;
          this.sendingUpdate = false;
          this.weightRecordService.ResetAnimalsWeightSummariesCache(this.mainService.farmChanged.value.farmId);
        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUpdateFailed,
            message: this.localeService.constants.stringSessionAssignmentFailed,
            type: 'warning'
          });
          this.sendingUpdate = false;
        });
    } else if (this.actionsSoldDeadService.action.weightRecords) {
      // from session details or group details page, mark a subset of weightRecords as sold or died
      let ids = this.actionsSoldDeadService.action.weightRecords.map(wr => wr.weightRecordId);
      // if no sessionId, then it's from Group Details page or Animals All page, so use currentDate
      (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
        ? this.animalService.updateAnimalsStatusesSoldByWRIds(ids, this.actionsSoldDeadService.action.sessionId
          , this.actionsSoldDeadService.action.groupId, this.actionDate)
        : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
          ? this.animalService.updateAnimalsStatusesDiedByWRIds(ids, this.actionsSoldDeadService.action.sessionId
            , this.actionsSoldDeadService.action.groupId, this.actionDate)
          : this.animalService.updateAnimalsStatusesCurrentByWRIds(ids, this.actionsSoldDeadService.action.sessionId
            , this.actionsSoldDeadService.action.groupId, this.actionDate))
        .subscribe(res => {
          let eventName = this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
            ? 'Mark Animal Sold by IDs'
            : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
              ? 'Mark Animal Died by IDs'
              : 'Mark Animal Current by IDs';
          this.intercomService.logEvent(eventName, this.router.url, {
            user_id: this.authService.currentUser.userId,
            farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
            sessionId: this.actionsSoldDeadService.action.sessionId,
            weightRecordIds: ids
          });
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAnimalsSuccessfullyAssignedAsSoldDead,
            message: this.localeService.constants.stringSelectedAnimalsHaveBeenMarkedAs + ' '
              + (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold ? this.localeService.constants.stringSold
                : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead ? this.localeService.constants.stringDead
                  : this.localeService.constants.stringCurrent),
            type: 'success'
          });
          this.actionComplete.emit(this.actionsSoldDeadService.action.v_ActionId);
          this.ngbActiveModal.close(this.actionsSoldDeadService.action.v_ActionId);
          // this.actionsSoldDeadService.action = null;
          this.sendingUpdate = false;
          this.weightRecordService.ResetAnimalsWeightSummariesCache(this.mainService.farmChanged.value.farmId);
        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUpdateFailed,
            message: this.localeService.constants.stringSessionAssignmentFailed,
            type: 'warning'
          });
          this.sendingUpdate = false;
        });
    } else if (this.actionsSoldDeadService.action.entireSession) {
      // not used, was designed to mark entire session as sold or died
      (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
        ? this.animalService.updateAnimalsStatusesSoldBySessionId(this.actionsSoldDeadService.action.ided
          , this.actionsSoldDeadService.action.sessionId)
        : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
          ? this.animalService.updateAnimalsStatusesDiedBySessionId(this.actionsSoldDeadService.action.ided
            , this.actionsSoldDeadService.action.sessionId)
          : this.animalService.updateAnimalsStatusesCurrentBySessionId(this.actionsSoldDeadService.action.ided
            , this.actionsSoldDeadService.action.sessionId))
        .subscribe(res => {
          let eventName = this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
            ? 'Action Animal Sold'
            : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
              ? 'Action Animal Died'
              : 'Action Animal Current';
          this.intercomService.logEvent(eventName, this.router.url, {
            user_id: this.authService.currentUser.userId,
            farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
            action: this.actionsSoldDeadService.action
          });
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAnimalsSuccessfullyAssignedAsSoldDead,
            message: this.localeService.constants.stringSelectedAnimalsHaveBeenMarkedAs + ' '
              + (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold ? this.localeService.constants.stringSold
                : this.localeService.constants.stringDead),
            type: 'success'
          });
          // this.actionComplete.emit(this.actionsSoldDeadService.action.v_ActionId);
          this.ngbActiveModal.close(this.actionsSoldDeadService.action.v_ActionId);
          this.actionsSoldDeadService.action = null;
          this.sendingUpdate = false;
          this.weightRecordService.ResetAnimalsWeightSummariesCache(this.mainService.farmChanged.value.farmId);
        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUpdateFailed,
            message: this.localeService.constants.stringSessionAssignmentFailed,
            type: 'warning'
          });
          this.sendingUpdate = false;
        });
    } else {
      // mark an entire action as sold or died
      (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
        ? this.animalService.updateAnimalsStatusesSold(this.actionsSoldDeadService.action.ided
          , this.actionsSoldDeadService.action.sessionId, this.actionsSoldDeadService.action.groupId, this.actionDate)
        : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
          ? this.animalService.updateAnimalsStatusesDied(this.actionsSoldDeadService.action.ided
            , this.actionsSoldDeadService.action.sessionId, this.actionsSoldDeadService.action.groupId, this.actionDate)
          : this.animalService.updateAnimalsStatusesCurrent(this.actionsSoldDeadService.action.ided
            , this.actionsSoldDeadService.action.sessionId, this.actionsSoldDeadService.action.groupId, this.actionDate))
        .subscribe(res => {
          let eventName = this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold
            ? 'Action Animal Sold'
            : this.actionsSoldDeadService.actionType === AnimalStatusEnum.dead
              ? 'Action Animal Died'
              : 'Action Animal Current';
          this.intercomService.logEvent(eventName, this.router.url, {
            user_id: this.authService.currentUser.userId,
            farmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
            action: this.actionsSoldDeadService.action
          });
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringAnimalsSuccessfullyAssignedAsSoldDead,
            message: this.localeService.constants.stringSelectedAnimalsHaveBeenMarkedAs + ' '
              + (this.actionsSoldDeadService.actionType === AnimalStatusEnum.sold ? this.localeService.constants.stringSold
                : this.localeService.constants.stringDead),
            type: 'success'
          });
          // this.actionComplete.emit(this.actionsSoldDeadService.action.v_ActionId);
          this.ngbActiveModal.close(this.actionsSoldDeadService.action.v_ActionId);
          this.actionsSoldDeadService.action = null;
          this.sendingUpdate = false;
          this.weightRecordService.ResetAnimalsWeightSummariesCache(this.mainService.farmChanged.value.farmId);
        }, err => {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUpdateFailed,
            message: this.localeService.constants.stringSessionAssignmentFailed,
            type: 'warning'
          });
          this.sendingUpdate = false;
        });
    }
  }
}

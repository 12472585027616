import { FarmUser } from './farm-user.model';

export class Invitation {
    invitationId: number;
    email = '';
    firstName = '';
    lastName = '';
    code: string;
    user_UserId: string;
    farm_FarmId: number;
    farmRoleId: InvitationFarmRoleId;
    groupIdsJson: string;
    groupIdsCensusJson: string;

    constructor(userId: string, farmId: number) {
        this.user_UserId = userId;
        this.farm_FarmId = farmId;
    }
}

export enum InvitationFarmRoleId {
    Admin = 1,
    Editor = 2,
    GroupReport = 3
}

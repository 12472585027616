<div [style.position]="relative ? 'relative' : local ? 'absolute' : 'fixed'" class="blocker"></div>

<div [style.position]="relative ? 'relative' : local ? 'absolute' : 'fixed'" class="spinner">

    <span class="sr-only" i18n="@@loading">Loading...</span>

    <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>

<!--    <p *ngIf="loadingText" class="loading-text">{{loadingText}}</p>-->

</div>

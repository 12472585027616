import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable} from 'rxjs';

import {ConfirmationComponent} from './confirmation.component';
import {Confirmation} from './confirmation';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private modalService: NgbModal) {
  }

  confirm(data: Confirmation): Observable<any> {
    const instance = this.modalService.open(ConfirmationComponent, {});

    if (data.title) {
      instance.componentInstance.messageTitle = data.title;
    }

    if (data.message) {
      instance.componentInstance.messageBody = data.message;
    }

    if (data.titleTemplate) {
      instance.componentInstance.messageTitleTemplate = data.titleTemplate;
    }

    if (data.messageTemplate) {
      instance.componentInstance.messageBodyTemplate = data.messageTemplate;
    }

    return from(instance.result);
  }
}

import {HttpClientModule} from '@angular/common/http'
import {NgModule, ErrorHandler} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {CdkTableModule} from '@angular/cdk/table';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {NgSelectModule} from '@ng-select/ng-select';
import {CookieModule} from 'ngx-cookie';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDateNativeUTCAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/components/calendar/calendar';
import {DialogModule} from 'primeng/components/dialog/dialog';
import {ProgressBarModule} from 'primeng/components/progressbar/progressbar';
import {PaginatorModule} from 'primeng/components/paginator/paginator';
import {SplitButtonModule} from 'primeng/primeng';
import {OverlayPanelModule} from 'primeng/primeng';
import {DropdownModule} from 'primeng/primeng';
import {GalleriaModule} from 'primeng/primeng';
import {TooltipModule} from 'primeng/primeng';
import {ButtonModule} from '../../node_modules/primeng/components/button/button';
import {DataScrollerModule} from 'primeng/datascroller';

import {GroupInfoComponent} from './main/groups/group-info/group-info.component';
import {AddDeviceComponent} from './main/subscriptions/add-device/add-device.component'
import {BottomNotificationsService} from './components/bottom-notifications/bottom-notifications.service';
import {SubscriptionsFarmsComponent} from './main/subscriptions/subscriptions-farms/subscriptions-farms.component';
import {SubscriptionsInfoComponent} from './main/subscriptions/subscriptions-info/subscriptions-info.component';
import {RouterLinkStubDirective} from './directives/router-link-stub.directive';
import {DemoFarmCreationComponent} from './main/manage/demo-farm-creation/demo-farm-creation.component';
import {TransferEducationComponent} from './main/transfer/transfer-education/transfer-education.component';
import {ActionGroupSelectionComponent} from './main/actions/action-group-selection/action-group-selection.component';
import {DashboardEducationComponent} from './main/dashboard/dashboard-education/dashboard-education.component';
import {SignalRService} from './main/api/signal-r.service';
import {GroupWidgetGraphComponent} from './main/dashboard/group-widget/group-widget-graph/group-widget-graph.component';
import {SessionWeightDistributionComponent} from './main/sessions/sessions-summary/session-weight-distribution/session-weight-distribution.component';
import {SessionGroupAssignmentsComponent} from './main/sessions/sessions-summary/session-group-assignments/session-group-assignments.component';
import {SessionGroupAssignmentBoxComponent} from './main/sessions/sessions-summary/session-group-assignments/session-group-assignment-box/session-group-assignment-box.component';
import {ActivityWidgetComponent} from './main/dashboard/activity-widget/activity-widget.component';
import {SessionsGroupByWeightComponent} from './main/sessions/sessions-summary/sessions-group-by-weight/sessions-group-by-weight.component';
import {GroupWidgetComponent} from './main/dashboard/group-widget/group-widget.component';
import {ActionWidgetComponent} from './main/dashboard/action-widget/action-widget.component';
import {SessionWidgetComponent} from './main/dashboard/session-widget/session-widget.component';
import {FarmDetailsComponent} from './main/settings/farm-details/farm-details.component';
import {LocalStoreManager} from './helpers/local-store-manager.service';
import {AllGroupsHeaderComponent} from './main/groups/groups-all/all-groups-header/all-groups-header.component';
import {GroupsSmallGraphComponent} from './main/groups/groups-all/groups-small-graph/groups-small-graph.component';
import {AppComponent} from './app.component';
import {AppRoutingModule, routingComponents} from './app.router';
import {ForgotPasswordConfirmationComponent} from './auth/forgot-password-confirmation/forgot-password-confirmation.component';
import {MonthPickerComponent} from './components/month-picker/month-picker.component';
import {MonthPickerPopupComponent} from './components/month-picker-popup/month-picker-popup.component';
import {DateDisplayInputComponent} from './components/date-display-input/date-display-input.component';
import {HighchartsDirective} from './directives/highcharts.directive';
import {TimeSinceDirective} from './directives/time-since.directive';
import {NumberDisplayDirective} from './directives/number-display.directive';
import {DisplayLongDateDirective} from './directives/display-long-date.directive';
import {GroupSummaryWeightGainComponent} from './main/groups/groups-summary/group-summary-weight-gain/group-summary-weight-gain.component';
import {GroupsAdgComponent} from './main/groups/groups-summary/groups-adg/groups-adg.component';
import {GroupsGroupByAdgComponent} from './main/groups/groups-summary/groups-group-by-adg/groups-group-by-adg.component';
import {GroupsGroupByWeightComponent} from './main/groups/groups-summary/groups-group-by-weight/groups-group-by-weight.component';
import {GroupsSummaryComponent} from './main/groups/groups-summary/groups-summary.component';
import {UserInvitationsComponent} from './main/manage/user-details/user-invitations/user-invitations.component';
import {UserPasswordComponent} from './main/manage/user-details/user-password/user-password.component';
import {SummaryHeaderComponent} from './main/summary-header/summary-header.component';
import {SubHeadingComponent} from './main/sub-heading/sub-heading.component';
import {ErrorSummaryComponent} from './main/transfer/error-summary/error-summary.component';
import {UploadSummaryComponent} from './main/transfer/upload-summary/upload-summary.component';
import {SuccessfulUploadComponent} from './main/transfer/successful-upload/successful-upload.component';
import {FarmCreationComponent} from './main/manage/farm-creation/farm-creation.component';
import {ReportsColumnPickerComponent} from './main/reports/reports-column-picker/reports-column-picker.component';
import {LoadingComponent} from './components/loading/loading.component';
import {BottomNotificationsComponent} from './components/bottom-notifications/bottom-notifications.component';
import {DeviceDetailsComponent} from './main/settings/device-details/device-details.component';
import {StaffTestingComponent} from './main/settings/staff-testing/staff-testing.component';
import {TempPasswordComponent} from './main/settings/temp-password/temp-password.component';
import {FormInputComponent} from './components/form-input/form-input.component';
import {ConfirmationComponent} from './components/confirmation/confirmation.component';
import {InviteComponent} from './main/invite/invite.component';
import {InviteModalComponent} from './main/invite/invite-modal/invite-modal.component';
import {MainMenuComponent} from './main/main-menu/main-menu.component';
import {ApiService} from './main/api/api.service';
import {NoDataFoundComponent} from './components/no-data-found/no-data-found.component';
import {ReportsFiltersComponent} from './main/reports/reports-filters/reports-filters.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {PplIntegrationComponent} from './main/settings/ppl-integration/ppl-integration.component';
import {RemoteWowComponent} from './main/dashboard/wow-dashboard/remote-wow/remote-wow.component';
import {CameraWidgetComponent} from './main/dashboard/wow-dashboard/camera-widget/camera-widget.component';
import {AdgDirective} from './directives/adg.directive';
import {AdgIconComponent} from './components/adg-icon/adg-icon.component';
import {LocaleService} from './helpers/locale.service';
import {EditUserinvitationComponent} from './main/invite/edit-userinvitation/edit-userinvitation.component';
import {CreateDraftComponent} from './main/groups/create-draft/create-draft.component';
import {NewPplIntegrationComponent} from './main/settings/ppl-integration/integration-model/new-ppl-integration/new-ppl-integration.component';
import {PplChooseDeviceModalComponent} from './main/settings/ppl-integration/integration-model/ppl-choose-device-modal/ppl-choose-device-modal.component';
import {IntegrationModelComponent} from './main/settings/ppl-integration/integration-model/integration-model.component';
import {CreateNewGroupComponent} from './main/create-new-group/create-new-group.component';
import {NewGroupDetailsComponent} from './main/create-new-group/new-group-details/new-group-details.component';
import {NewGroupLineComponent} from './main/create-new-group/new-group-line/new-group-line.component';
import {NewGroupPlansComponent} from './main/create-new-group/new-group-plans/new-group-plans.component';
import {NewGroupPlanAdjustmentComponent} from './main/create-new-group/new-group-plan-adjustment/new-group-plan-adjustment.component';
import {NewGroupFinilisationComponent} from './main/create-new-group/new-group-finilisation/new-group-finilisation.component';
import {GlobalParameterService} from './helpers/global-parameter.service';
import {ActionsSoldDeadModalComponent} from './main/actions/actions-sold-dead-modal/actions-sold-dead-modal.component';
import {ActionsSoldDeadModelSpliterStringsComponent} from './main/actions/actions-sold-dead-modal/actions-sold-dead-model-spliter/actions-model-spliter-strings/actions-sold-dead-model-spliter-strings.component';
import {ActionsSoldDeadModelSpliterComponent} from './main/actions/actions-sold-dead-modal/actions-sold-dead-model-spliter/actions-sold-dead-model-spliter.component';
import {ActionsSoldDeadModelSpliterDateComponent} from './main/actions/actions-sold-dead-modal/actions-sold-dead-model-spliter/actions-model-spliter-date/actions-sold-dead-model-spliter-date.component';
import {ActionsSoldDeadModelSplitterTimeComponent} from './main/actions/actions-sold-dead-modal/actions-sold-dead-model-spliter/actions-sold-dead-model-splitter-time/actions-sold-dead-model-splitter-time.component';
import {ActionsModalSplitterComponent} from './main/actions/actions-modal/actions-modal-splitter/actions-modal-splitter.component';
import {ActionsModalSplitterStringsComponent} from './main/actions/actions-modal/actions-modal-splitter/actions-modal-splitter-strings/actions-modal-splitter-strings.component';
import {ActionsModalGroupSelectionComponent} from './main/actions/actions-modal/actions-modal-group-selection/actions-modal-group-selection.component';
import {ActionsModalSplitterDateComponent} from './main/actions/actions-modal/actions-modal-splitter/actions-modal-splitter-date/actions-modal-splitter-date.component';
import {ActionsModalSplitterTimeComponent} from './main/actions/actions-modal/actions-modal-splitter/actions-modal-splitter-time/actions-modal-splitter-time.component';
import {ActionsModalSplitterNumberComponent} from './main/actions/actions-modal/actions-modal-splitter/actions-modal-splitter-number/actions-modal-splitter-number.component';
import {ActionsSoldDeadModelSpliterNumberComponent} from './main/actions/actions-sold-dead-modal/actions-sold-dead-model-spliter/actions-model-spliter-number/actions-sold-dead-model-spliter-number.component';
import {ActionsModalComponent} from './main/actions/actions-modal/actions-modal.component';
import {ActionsAssignFilteredListModalComponent} from './main/actions/actions-assign-filtered-list-modal/actions-assign-filtered-list-modal.component';
import {ActionsComponent} from './main/actions/actions.component';
import {ActionsBoxComponent} from './main/actions/actions-box/actions-box.component';
import {ActionsV2Component} from './main/actionsV2/actionsV2.component';
import {FloatingScrollBarComponent} from './components/floating-scroll-bar/floating-scroll-bar.component';
import {NgbDateCustomParserFormatter} from './core/ngb-date-parser-format.service';
import {BreadcrumbComponent} from './shared/breadcrumb/breadcrumb.component';
import {FarmMenuComponent} from './main/farm-menu/farm-menu.component';
import {FarmMenuModalComponent} from './main/farm-menu/farm-menu-modal/farm-menu-modal.component';
import {GroupActionsComponent} from './main/groups/group-actions/group-actions.component';
import {AnimalActionsComponent} from './main/animals/animal-actions/animal-actions.component';
import {AnimalsListComponent} from './main/animals/animals-list/animals-list.component';
import {AnimalInfoComponent} from './main/animals/animal-info/animal-info.component';
import {AdminResetPasswordComponent} from './main/admin/admin-users/admin-reset-password/admin-reset-password.component';
import {UserMenuModalComponent} from './main/user-menu/user-menu-modal/user-menu-modal.component';
import {UserMenuComponent} from './main/user-menu/user-menu.component';
import {PageActionsComponent} from './shared/page-actions/page-actions.component';
import {GroupsActionsComponent} from './main/groups/groups-actions/groups-actions.component';
import {FormErrorsSumComponent} from './shared/form-errors-sum/form-errors-sum.component';
import { GlobalLoaderComponent } from './shared/global-loader/global-loader.component';

import {GlobalErrorHandler} from './helpers/global-error-handler.service';

import { ClickOutsideModule } from 'ng-click-outside';
import { NgbDateNativeAdapter as NgbCustomDateNativeAdapter } from './core/ngb-date-native-adapter';


const MiHubDirectives = [
  HighchartsDirective, TimeSinceDirective, NumberDisplayDirective, RouterLinkStubDirective, DisplayLongDateDirective, AdgDirective
];
const MiHubComponents = [ // these are non routed components
  ActivityWidgetComponent, SessionWidgetComponent, ForgotPasswordConfirmationComponent, NewGroupPlansComponent
  , UploadSummaryComponent, SuccessfulUploadComponent, ErrorSummaryComponent, GroupWidgetGraphComponent
  , UserInvitationsComponent, NewGroupLineComponent
  , GroupsSmallGraphComponent, InviteComponent, InviteModalComponent, EditUserinvitationComponent, DemoFarmCreationComponent
  , MonthPickerComponent, MonthPickerPopupComponent, FormInputComponent, NewGroupPlanAdjustmentComponent, NewGroupFinilisationComponent
  , ActionsComponent, GroupSummaryWeightGainComponent, SubscriptionsInfoComponent, ActionsModalSplitterStringsComponent
  , GroupsGroupByAdgComponent, GroupsSummaryComponent, GroupSummaryWeightGainComponent, AdgIconComponent
  , GroupsGroupByWeightComponent, GroupsAdgComponent, GroupsGroupByAdgComponent, SubscriptionsFarmsComponent
  , SummaryHeaderComponent, SubHeadingComponent, FarmCreationComponent, LoadingComponent, BottomNotificationsComponent
  , SessionsGroupByWeightComponent, AllGroupsHeaderComponent, SessionWeightDistributionComponent, SessionGroupAssignmentsComponent, SessionGroupAssignmentBoxComponent
  , ReportsColumnPickerComponent, FarmDetailsComponent, AddDeviceComponent, CameraWidgetComponent
  , GroupWidgetComponent, ActionWidgetComponent, DeviceDetailsComponent, StaffTestingComponent, ConfirmationComponent
  , MainMenuComponent, FarmMenuComponent, FarmMenuModalComponent, UserMenuComponent, UserMenuModalComponent, DashboardEducationComponent, ActionGroupSelectionComponent
  , ActionsBoxComponent
  , TransferEducationComponent, UserPasswordComponent, TempPasswordComponent, DateDisplayInputComponent
  , NoDataFoundComponent, ReportsFiltersComponent, PplIntegrationComponent, NewPplIntegrationComponent, RemoteWowComponent
  , CreateDraftComponent, PplChooseDeviceModalComponent, IntegrationModelComponent, NewGroupDetailsComponent
  , ActionsModalGroupSelectionComponent, ActionsModalSplitterComponent, CreateNewGroupComponent
  , ActionsModalSplitterDateComponent, ActionsModalSplitterNumberComponent, ActionsModalSplitterTimeComponent, ActionsSoldDeadModalComponent
  , ActionsSoldDeadModalComponent, ActionsSoldDeadModelSpliterComponent, ActionsSoldDeadModelSpliterStringsComponent
  , ActionsSoldDeadModelSpliterDateComponent, ActionsSoldDeadModelSplitterTimeComponent, ActionsSoldDeadModelSpliterNumberComponent
  , NewGroupPlanAdjustmentComponent, NewGroupFinilisationComponent
  , ActionsModalComponent, FloatingScrollBarComponent, ActionsV2Component, ActionsAssignFilteredListModalComponent
  , GroupInfoComponent, BreadcrumbComponent, GroupActionsComponent, GroupsActionsComponent, AnimalActionsComponent, AnimalsListComponent, AdminResetPasswordComponent
  , PageActionsComponent, FormErrorsSumComponent, GlobalLoaderComponent
];


@NgModule({
  imports: [BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, HttpClientModule,
    BrowserAnimationsModule,
    CalendarModule,
    TableModule,
    DialogModule,
    ProgressBarModule,
    PaginatorModule,
    GalleriaModule,
    OverlayPanelModule,
    DropdownModule,
    AutoCompleteModule,
    SplitButtonModule,
    TooltipModule,
    CommonModule, ButtonModule,
    CookieModule.forRoot(),
    DataScrollerModule,

    NgbModule,
    CdkTableModule,
    CdkStepperModule,
    DragDropModule,
    NgSelectModule,
    ClickOutsideModule
  ],
  exports: [ButtonModule],
  declarations: [AppComponent, routingComponents, MiHubComponents, MiHubDirectives],
  bootstrap: [AppComponent],
  providers: [LocalStoreManager, LocaleService, SignalRService, ApiService, BottomNotificationsService, GlobalParameterService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NgbDateAdapter, useClass: NgbCustomDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
  entryComponents: [
    CreateNewGroupComponent,
    ConfirmationComponent,
    FarmMenuModalComponent,
    FarmCreationComponent,
    DemoFarmCreationComponent,
    ActionsModalComponent,
    ActionsSoldDeadModalComponent,
    ActionsAssignFilteredListModalComponent,
    AnimalInfoComponent,
    InviteModalComponent,
    EditUserinvitationComponent,
    AddDeviceComponent,
    IntegrationModelComponent,
    CreateDraftComponent,
    ActionGroupSelectionComponent,
    AdminResetPasswordComponent,
    UserMenuModalComponent
  ]

})

export class AppModule {}


import {throwError as observableThrowError,  Observable ,  Subscription } from 'rxjs';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { User } from '../../models/user.model';
import { RequestStatus } from '../../models/request-status.enum';
import { Country } from '../../models';
import { AuthService } from '../../../auth/auth.service';
import { CountryService } from '../../api/countries.service';
import { UserService, FarmService } from '../../api';
import { MainService } from '../../main.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormValidation } from '../../../helpers/form-validation.helper';
import { Title } from '@angular/platform-browser';
import { LocaleService } from '../../../helpers/locale.service';
import { map, catchError } from 'rxjs/operators';


@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    providers: [CountryService]
})
export class UserDetailsComponent implements OnInit, OnDestroy {
    curUser: User = new User();
    form: FormGroup;
    requestStatus: RequestStatus = RequestStatus.notStarted;
    serverErrorMessage = '';
    countries: Array<Country> = [];
    formHasChanged = false;
    regions: Array<string> = [];
    allowDeletion = false;
    private subs: Array<Subscription> = [];


    constructor(private userService: UserService, private authService: AuthService, public localeService: LocaleService
        , private mainService: MainService, private farmService: FarmService, private countryService: CountryService,
        private bottomNotificationsService: BottomNotificationsService, title: Title) {
        title.setTitle(`${this.localeService.constants.stringMyDetails} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    }

    save(event) {
        if (this.form.valid) {
            let email = this.form.controls['email'].value.trim(),
                oldEmail = this.authService.currentUser.email;
            this.requestStatus = RequestStatus.working;
            let newUserDetails = this.form.value;
            this.userService.SaveUserData(Object.assign({}, this.curUser, newUserDetails)).subscribe(res => {
                if (email !== oldEmail) {
                    this.userService.UpdateEmailAndPassword(email).pipe(catchError((err: any, caught: Observable<any>) => {
                        if (err) {
                            if (err.status === 409) {
                                this.bottomNotificationsService.currentMessage.next({
                                    title: this.localeService.constants.stringSaveFailed,
                                    message: this.localeService.constants.stringEmailIsAlreadyTaken.replace('<emailAddress/>', email),
                                    type: 'warning'
                                });
                            } else {
                                this.bottomNotificationsService.currentMessage.next({
                                    title: this.localeService.constants.stringSaveFailed,
                                    message: this.localeService.constants.stringMyDetailsFailedToUpdatePleaseTryAgainLater,
                                    type: 'warning'
                                });
                            }
                            this.serverErrorMessage = err.statusText;
                        }
                        this.requestStatus = RequestStatus.notStarted;
                        return observableThrowError('error');
                    })).subscribe(res2 => {
                        this.requestStatus = RequestStatus.notStarted;
                        this.formHasChanged = false;
                        this.curUser.email = email;
                        this.authService.refreshTokenAndStoreToken();
                        this.bottomNotificationsService.currentMessage.next({
                            title: this.localeService.constants.stringSaveSuccessful,
                            message: this.localeService.constants.stringMyDetailsSuccessfullyUpdated,
                            type: 'success'
                        });
                    });
                } else {
                    this.bottomNotificationsService.currentMessage.next({
                        title: this.localeService.constants.stringSaveSuccessful,
                        message: this.localeService.constants.stringMyDetailsSuccessfullyUpdated,
                        type: 'success'
                    });
                    this.requestStatus = RequestStatus.notStarted;
                    this.formHasChanged = false;
                }
            });
        } else {
            this.form.controls['region'].markAsDirty();
            this.form.controls['email'].markAsDirty();
            this.form.controls['firstName'].markAsDirty();
            this.form.controls['lastName'].markAsDirty();
            this.form.controls['phoneNumber'].markAsDirty();
            this.serverErrorMessage = this.localeService.constants.stringPleaseCompleteAllFieldsCurrentlyMarkedRed;
        }
    }

    deleteAccount() {
        this.userService.deleteUser().subscribe(() => {
            this.authService.logout();
        });
    }


    setUpForm(user: User) {
        if (user) {
            this.curUser = user;
            this.form = new FormGroup({
                email: new FormControl(user.email.toString(), Validators.compose([FormValidation.validateEmail, Validators.required])),
                firstName: new FormControl(user.firstName, Validators.compose([Validators.required, Validators.pattern(this.mainService.usernameValidationRegex)])),
                lastName: new FormControl(user.lastName, Validators.compose([Validators.required, Validators.pattern(this.mainService.usernameValidationRegex)])),
                addressLine1: new FormControl(user ? user.addressLine1 : ''),
                addressLine2: new FormControl(user ? user.addressLine2 : ''),
                postcode: new FormControl(user ? user.postcode : ''),
                city: new FormControl(user ? user.city : ''),
                region: new FormControl(user ? user.region : '', Validators.required),
                country: new FormControl(user ? user.country : '', Validators.required),
                phoneNumber: new FormControl(user.phoneNumber, Validators.compose([Validators.required, Validators.pattern(/^[0-9 \+\-\(\)]+$/)]))
            });

            this.form.valueChanges.subscribe(change => {
                this.serverErrorMessage = '';

                // potentially need to check that changes aren't setting fields to their default values
                this.formHasChanged = true;
            });
        }
    }

    ngOnInit() {
        this.subs.push(this.authService.currentUserChanged.subscribe(user => {
            if (user && user.email.startsWith('test-') && user.email.endsWith('@trutest.co.nz')) {
                this.allowDeletion = true;
            }
        }));

        this.countryService.GetCountries().subscribe(countries => {
            this.countries = countries;
            for (let country of countries) {
                country.regionsArray = JSON.parse(country.regions) as Array<string>;
            }
            if (this.authService.currentUser) {
                let matchingCountry = this.countries.find(c => c.countryName === this.authService.currentUser.country);
                if (matchingCountry) {
                    this.regions = matchingCountry.regionsArray;
                }
            }

            this.setUpForm(this.authService.currentUser);

            this.subs.push(this.authService.currentUserChanged.subscribe(user => {
                this.setUpForm(user);
            }));
        });
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}

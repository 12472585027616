import { Group } from '../../../models/group.model';
import { Rounder } from '../../../../helpers/rounder.helper';
import { WeightPlan, WeightPlanPoint } from '../../../models/weight-plan.model';
import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { LocaleService } from '../../../../helpers/locale.service';
import {PermissionsService} from '../../../../auth/permissions.service';
import {IntegrationService} from '../../../api/integration.service';

@Component({
  selector: 'app-groups-small-graph',
  templateUrl: './groups-small-graph.component.html',
  styleUrls: ['./groups-small-graph.component.scss']
})
export class GroupsSmallGraphComponent implements OnInit, AfterContentChecked {
  @Input()
  group: Group;
  options: any;
  currentCensusTimeStamps = '';

  isPplFarmWithIntegration = false;

  constructor(private localeService: LocaleService, private permissionsService: PermissionsService, private integrationService: IntegrationService) { }


  ngAfterContentChecked() {
    if (this.group && this.currentCensusTimeStamps !== ''
      && this.group.censuses.map(c => c.timeStamp).toString() !== this.currentCensusTimeStamps) {
      this.redrawGraph();
    }
  }

  ngOnInit() {
    if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
        this.isPplFarmWithIntegration = true;
    }
    this.redrawGraph();
  }
  redrawGraph() {
    if (!this.group) {
      return;
    }
    if (this.group.censuses && this.group.censuses.map(c => c.timeStamp).toString() !== this.currentCensusTimeStamps) {
      this.currentCensusTimeStamps = this.group.censuses.map(c => c.timeStamp).toString();
    }
    let xAxis: any = {
      type: 'datetime',

      tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
      dateTimeLabelFormats: {
        month: '%b %Y'
      },
      labels: {
        style: {
          fontFamily: '"Lato", Helvetica, Arial, sans-serif',
          fontSize: '12px'
        }
      },
    },
    chartPoints = this.group.censuses ?  this.group.censuses.filter(cp => cp.averageWeight).slice() : [];
    chartPoints.sort((a, b) => a.timeStamp.getTime() - b.timeStamp.getTime());

    let points = chartPoints.map(point => {
      return {
        x: point.timeStamp.getTime(), y: point.averageWeight, extra: [{
          order: 99
          , html: `<span style="color:black;">\u25CF</span> ${this.localeService.constants.stringAnimalCount}: ${point.animalCount}`
        }]
      };
    });

    let zones = []; // if this is an empty array it will have no impact on the graph
    if (this.isPplFarmWithIntegration) {
        let lastIdx = chartPoints.length - 1;
        let lastPt = chartPoints[lastIdx];
        if (lastPt && lastPt.timeStamp.getUTCDay() !== 0) { // if it's a rolling session, so the data is still being incremented
            chartPoints.splice(lastIdx); // remove the rolling session from the standard datapoints
            points.forEach(p => {
                zones.push({
                    value: p.x + 0.1, // need the slight overage to ensure we don't get previous colour coming off the end
                    color: '#00AAE7'
                });
            });
            zones[lastIdx] = { // changing colour of final point, which also changes colour of line from previous point to match
                value: points[lastIdx].x + 0.1, // need the slight overage to ensure we don't get previous colour coming off the end
                color: '#ADB5BD'
            };
            zones[lastIdx + 1] = { // set a default colour for the line to override the "global" colours used for our highcharts
                color: '#ADB5BD'
            };
        }
      }
    let series = [{
      name: this.localeService.constants.stringAverage,
      id: 'average',
      allowPointSelect: true,
      data: points,
      marker: { enabled: true, lineWidth: 2, symbol: 'circle' },
      zIndex: 2,
      type: 'line',
      zones: zones,
      zoneAxis: 'x'
    }, {
      name: this.localeService.constants.stringMinToMax,
      data: chartPoints.map(point => [point.timeStamp.getTime(), point.minWeight, point.maxWeight]),
      marker: {
          enabled: false,
          states: {
              hover: {
                  enabled: false
              }
          }
      },
      type: 'areasplinerange',
      linkedTo: 'average',
      color: 'rgb(149, 206, 255)',
      fillOpacity: 0.4,
      zIndex: 0
    },
    {
      name: this.localeService.constants.stringTarget,
      allowPointSelect: true,
      data: (<Array<WeightPlanPoint>>JSON.parse(this.group.targetWeightPlan.weightPlanPointsJson))
        .map(e => { return { x: new Date(e.date).getTime(), y: e.weight }; }),
      marker: { enabled: true, lineWidth: 1, lineColor: '#69B342', symbol: 'diamond' },
      zIndex: 4,
      type: 'spline'
    }];

    this.options = {
      title: {
        text: null
      },

      subtitle: {
        text: null
      },
      // see https://stackoverflow.com/a/17027213/1222318 for explanation of the background settings for transparent to be supported across "most" browsers
      chart: {
        backgroundColor: 'rgba(255, 255, 255, 0.002)'
      },
      xAxis: xAxis,
      yAxis: {
        endOnTick: false,
        tickAmount: 5,
        title: {
          text: null
        },
        labels: {
          style: {
            fontFamily: '"Lato", Helvetica, Arial, sans-serif',
            fontSize: '12px'
          }
        },
        lineWidth: 1,
        min: 0
      },

      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },

      exporting: {
        enabled: false

      },

      series: series
    };
  }

}

<app-loading *ngIf="!sessions" [local]="true"></app-loading>

<ng-container *ngIf="sessions">

  <!-- filters -->
  <div class="filter">
    <app-reports-filters
      [data]="rows || []" [showFilters]="false"
      (globalFilterChanged)="tt.filterGlobal($event.value, 'contains')"
      (globalParameterService.detailsSearchParams)="tt.filter($event.value, 'contains')">
    </app-reports-filters>
  </div>

  <!-- actions, exports, table columns and actual table -->
  <div class="card card-table">

    <div class="card-header">
      <div class="row flex-grow-1">
        <div class="col-md-6"></div>

        <div class="col-md-6 d-flex justify-content-end">

          <!-- export -->
          <div ngbDropdown>
            <button *ngIf="headers" class="btn btn-link" ngbDropdownToggle i18n="@@export">Export</button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="reportsService.downloadCsv(curHeaders, rows, getDisplayName())">{{localeService.constants.stringSpreadsheetBracketsCsv}}</button>
              <button ngbDropdownItem (click)="reportsService.exportPDF(curHeaders, rows, getDisplayName())">PDF</button>
            </div>
          </div>

          <!-- columns -->
          <app-reports-column-picker
            *ngIf="curHeaders"
            [curHeaders]="curHeaders"
            [headers]="headers"
            (headersChanged)="columnsReordered($event,true)">
          </app-reports-column-picker>

        </div>
      </div>
    </div>

    <p-table class="no-overflow"
             #tt
             [columns]="curHeaders"
             [value]="rows"
             (onColReorder)="columnsReordered($event.columns)"
             [reorderableColumns]="true"
             (sortFunction)="reportsService.sortData($event, rows); sortEvent($event);"
             [customSort]="true"
             [sortField]="sortField"
             [sortOrder]="sortOrder"
             [autoLayout]="true"
             [rows]="50"
             [paginator]="true"
             [pageLinks]="11"
             [rowsPerPageOptions]="[20,50,100]"
             (window:resize)="reportsService.onResize();"
             tableStyleClass="table table-hover">

      <!-- header -->
      <ng-template pTemplate="header" let-columns>
        <tr class="ui-widget-content">
          <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="ui-state-default">
            <span [innerHTML]="col.header"></span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <!-- body -->
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr class="ui-widget-content">
          <td *ngFor="let col of columns">
            <div *ngIf="col.type !== 2 && col.field === 'SessionName'" class="ui-cell-data">
                                <span [title]="row[col.field]">
                                    <a routerLink="/main/sessions/summary" [queryParams]="{id: row['SessionId']}">{{row[col.field]}}</a>
                                </span>
            </div>
            <div *ngIf="col.type !== 2 && col.field !== 'SessionName'" class="ui-cell-data">
                                <span [title]="row[col.field]">
                                    {{row[col.field]}}
                                </span>
            </div>
            <div *ngIf="col.type === 2" class="ui-cell-data">
                                <span [title]="dayMonthYearString(row[col.field])">
                                    {{dayMonthYearString(row[col.field]) }}
                                </span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-floating-scroll-bar *ngIf="!isMobile"></app-floating-scroll-bar>

  </div>
</ng-container>

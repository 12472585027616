<div class="row no-gutters small-gutters">

  <div class="col-md-6">

    <!-- above -->
    <div class="custom-control custom-radio">
      <input [value]="true" id="sold-dead-radio-above" name="sold-dead-radio" type="radio" [(ngModel)]="aboveSelected" class="custom-control-input">
      <label class="custom-control-label" for="sold-dead-radio-above">
        <span i18n="@@onorafter">On or after</span>
        <span class="d-block">{{aboveSummary.count}}
          <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{aboveSummary.weight}}
          <ng-container i18n="abbreviation for average@@avg">Avg</ng-container>
      </span>
      </label>
    </div>

  </div>
  <div class="col-md-6">

    <!-- datepicker -->
    <input
      class="form-control"
      ngbDatepicker
      #d="ngbDatepicker"
      [ngModelOptions]="{standalone: true}"
      [(ngModel)]="aboveOrEqualToDate"
      (ngModelChange)="workoutSummaries()"
      (click)="d.toggle()">
  </div>
</div>

<!-- below -->
<div class="custom-control custom-radio">
  <input [value]="false" id="sold-dead-radio-below" name="sold-dead-radio" type="radio" [(ngModel)]="aboveSelected" class="custom-control-input">
  <label class="custom-control-label" for="sold-dead-radio-below">
    <span i18n="@@before">Before</span>&nbsp;
    <span>{{aboveOrEqualToDate | date : 'd MMM yyyy'}}</span>
    <span class="text-muted d-block">{{belowSummary.count}}&nbsp;
          <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{belowSummary.weight}}
      <ng-container i18n="abbreviation for average@@avg">Avg</ng-container>
        </span>
  </label>
</div>

<div class="alert alert-danger">
    <i class="fa fa-2x fa-info-circle"></i>
    <div>
        <span *ngIf="currentSession.invalidRowCount === 1" i18n="@@rowinsessionwasinvalidandnotuploaded">
          1 row in this session was invalid and could not be imported.
        </span>
        <span *ngIf="currentSession.invalidRowCount !== 1" i18n="@@rowsinsessionwasinvalidandnotuploaded">
          {{currentSession.invalidRowCount}} rows in this session were invalid and could not be imported.
        </span>
        <a class="px-1" (click)="getOriginalFile()" i18n="@@downloadoriginalfile">Download Original File</a>
        <a *ngIf="!showErrors" class="px-1" (click)="getErrors()" i18n="@@showerrors">Show Errors</a>
        <a *ngIf="showErrors" class="px-1" (click)="getErrors()" i18n="@@hideerrors">Hide Errors</a>
    </div>
</div>

<div class="errors-summary" *ngIf="errors && showErrors">
  <app-error-summary [errors]="errors" [hideFileName]="true"></app-error-summary>
</div>

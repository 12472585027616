import { LocaleService } from '../../../../helpers/locale.service';
import { MainService } from '../../../../main/main.service';
import { GroupsService } from '../../../services/groups.service';
import { Group } from '../../../models/group.model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupsAnimalCount } from '../../../models/groups-animal-count.model';


@Component({
    selector: 'app-all-groups-header',
    templateUrl: './all-groups-header.component.html',
    styleUrls: ['./all-groups-header.component.scss']
})
export class AllGroupsHeaderComponent implements OnInit, OnDestroy {
    @Input()
    groups: Array<Group> = [];
    loading = true;
    groupAnimCount: GroupsAnimalCount;
    numberOfItems = 0;
    private sub: Subscription;

    constructor(private groupsService: GroupsService, private mainService: MainService, public localeService: LocaleService) { }

    public ngOnInit(): void {
        this.sub = this.groupsService.groups.subscribe(grps => {
            this.groupAnimCount = new GroupsAnimalCount();
            this.numberOfItems = 0;
            if (grps && grps.length > 0) {
                this.groupAnimCount.groupCount = grps.length;
                grps.forEach(g => {
                    if (g.currentStatus && g.currentStatus.animalCount) {
                        this.groupAnimCount.animalCount += g.currentStatus.animalCount;
                        switch (g.species_SpeciesId) {
                            case 1:
                                this.groupAnimCount.beefCount += g.currentStatus.animalCount;
                                break;
                            case 2:
                                this.groupAnimCount.dairyCount += g.currentStatus.animalCount;
                                break;
                            case 3:
                                this.groupAnimCount.sheepCount += g.currentStatus.animalCount;
                                break;
                            case 4:
                                this.groupAnimCount.deerCount += g.currentStatus.animalCount;
                                break;
                            case 100:
                                this.groupAnimCount.otherCount += g.currentStatus.animalCount;
                                break;
                        }
                    }
                })
                this.loading = false;
                for (const param of Object.keys(this.groupAnimCount)) {
                    if (this.groupAnimCount[param] && this.groupAnimCount[param] > 0) {
                        this.numberOfItems++;
                    }
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

}

export enum GroupActionsEnum {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  EDITED = 'EDITED',
  SHARED = 'SHARED',
  NOT_CREATED = 'NOT_CREATED',
  NOT_DELETED = 'NOT_DELETED',
  NOT_EDITED = 'NOT_EDITED',
  NOT_SHARED = 'NOT_SHARED'
}

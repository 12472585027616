<div class="plans">
  <div class="plan">
    <div class="plan-name">
      <div>
       <p i18n="@@starter">Starter</p>
      </div>
     
   </div>
   <div class="plan-price">
     <div class="price-main">
     <p class="xlarge allcaps" i18n="@@free">FREE</p>
     <p class="conditions allcaps"><span i18n="@@withDatamars">with Datamars</span><br /><span i18n="@@livestockDevices">livestock devices</span>*</p>
     </div>
     <div class="price-details">
       <div class="or"><p i18n="@@or">or</p></div>
       <p style="margin-top: 10px;"><span class="currency-baseline-shift">$</span><span class="large-numbers">25</span><span class="dash-baseline-shift">/</span><span class="large-month" i18n="abbreviation for month@@mo">mo</span>&nbsp;<span i18n="@@withFreeThirtyDayTrial">with free 30 day trial</span></p>
     </div>
   </div>
   <div class="plan-description">
     <p><span i18n="@@basicWeightTrackingAndAnalysisToolsForUpToTwoFarmsWithTenUsers">Basic weight tracking and analysis tools for up to 2 farms with 10 users</span>.</p>
     <p class="conditions">*<span i18n="@@staticWeighingAndEnergizersOnly">Tru-Test static weighing & EID devices, and Speedrite/Stafix/PEL connected energizers only</span></p>
   </div>
   <div class="plan-cta">
    <button *ngIf="!subscriptionsPlanId" (click)="createNewFarm()" class="btn btn-primary" i18n="@@createnewfarm">Create New Farm</button>
    <button *ngIf="subscriptionsPlanId === 1 && authService.trialDaysLeft <= 0" (click)="openAddDeviceModal()" class="btn btn-primary" i18n="@@activatesubscription">Activate Subscription</button>
    <button *ngIf="subscriptionsPlanId === 1 && authService.trialDaysLeft > 0" (click)="openAddDeviceModal()" class="btn btn-primary" i18n="@@currentlytrialling">Currently Trialling</button>
    <button *ngIf="subscriptionsPlanId === 2" class="btn btn-ternary" disabled i18n="@@currentlyactive">Currently Active</button>
  </div>
 </div>
 <div class="plan">
   <div class="plan-name">
     <div>
        <p i18n="@@premium">Premium</p>
     </div>
      
    </div>
    <div class="plan-price">
      <div class="price-main">
      <p class="large allcaps" i18n="@@priceOnApplication">PRICE ON APPLICATION</p>
      </div>
      <div class="price-details">
        <p><span i18n="@@enableAccessToManageData">Enables access to manage data</span><br /><span i18n="@@fromOurSmartFarmSolutions">from our smart farm solutions</span>.</p>
      </div>
    </div>
    <div class="plan-description">
      <p class="bold" i18n="Remote WOW is the product name@@remoteWOWAddOn">Remote WOW Add On</p>
      <p><span i18n="Tru-Test Remote WOW is the product name@@automatedWeightCaptureAndTracking">Automated weight capture and tracking from <a href="https://livestock.datamars.com/downloads/3857TT13-WOW-A4-BROCHURE-JUL20.pdf" target="_blank">Tru-Test Remote WOW</a> systems including drafter and camera integration</span>.</p>
    </div>
    <div class="plan-cta">
      <button class="btn btn-primary" (click)="intercomService.triggerPremiumConversation();" i18n="@@contactus">Contact Us</button>
    </div>
  </div>
  <div class="plan">
 <div class="plan-name">
   <div>
      <p i18n="@@enterprise">Enterprise</p>
   </div>
    
  </div>
  <div class="plan-price">
    <div class="price-main">
    <p class="large allcaps" i18n="@@priceOnApplication">PRICE ON APPLICATION</p>
    </div>
    <div class="price-details">
      <p><span i18n="@@enablesAccessToManageData">Enables access to manage data</span><br /><span i18n="@@fromOurSmartFarmSolutions">from our smart farm solutions</span>.</p>
    </div>
  </div>
  <div class="plan-description">
    <p><span i18n="@@customDataAnalysisTrackingAndProactiveUserSupport">Custom data analysis, advanced training and proactive user support</span>.</p>
    <p class="bold" i18n="Remote WOW is the product name@@remoteWOWAddOn">Remote WOW Add On</p>
    <p><span i18n="Tru-Test Remote WOW is the product name@@automatedWeightCaptureAndTracking">Automated weight capture and tracking from <a href="https://livestock.datamars.com/downloads/3857TT13-WOW-A4-BROCHURE-JUL20.pdf" target="_blank">Tru-Test Remote WOW</a> systems including drafter and camera integration</span>.</p>
  </div>
  <div class="plan-cta">
    <button class="btn btn-primary" (click)="intercomService.triggerEnterpriseConversation();" i18n="@@contactus">Contact Us</button>
  </div>
</div>
</div>

<div class="mt-2">

  <div class="alert alert-info" *ngIf="subscriptionsPlanId === null">
    <i class="fa fa-2x fa-check-circle"></i>
    <div>
      <strong i18n="@@nosubscriptionactive">No subscription currently active</strong>. <span i18n="@@pleasecreatenewfarm">Please create a new farm to activate your free trial</span>.
    </div>
  </div>

  <div class="alert alert-info" *ngIf="subscriptionsPlanId === 0">
    <i class="fa fa-2x fa-info-circle"></i>
    <strong i18n="@@youAreOnStaffSubscription">You are on staff subscription</strong>
  </div>

  <div class="alert alert-info" *ngIf="subscriptionsPlanId === 1" [ngClass]="authService.trialDaysLeft <= 0 ? 'alert-warning' : 'alert-info'">
    <i class="fa fa-2x fa-exclamation-circle"></i>
    <div class="flex-grow-1">
      <strong *ngIf="authService.trialDaysLeft <= 0" i18n="@@yourtrialhasexpired">Your trial has expired</strong>
      <strong *ngIf="authService.trialDaysLeft > 0">{{authService.trialDaysLeft}} <ng-container i18n="@@daysleftontrial">days left on your free trial</ng-container></strong>&nbsp;
      <span>
        <ng-container i18n="Tru-Test is the company name@@haveatrutestdevice">Have a Tru-Test device?</ng-container>&nbsp;
        <a (click)="openAddDeviceModal()" class="for-hover" i18n="@@enteryourserialnumber">Enter your serial number</a>&nbsp;
        <ng-container i18n="Starter Plan should be capitalised@@toactivatetheStarterPlanforfree">to activate the Starter Plan for free</ng-container>.
      </span>
    </div>
    <button class="btn btn-primary" (click)="openAddDeviceModal()" i18n="@@enterserialnumber">Enter Serial Number</button>
  </div>

  <div class="alert alert-info" *ngIf="subscriptionsPlanId === 2">
    <i class="fa fa-2x fa-info-circle"></i>
    <strong i18n="Tru-Test is the company name@@starterplanisfree">Starter plan is free with a Tru-Test device</strong>
  </div>

</div>
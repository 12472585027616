<a class="link" (click)="openFarmMenu()">

  <div class="farm-icon-container">
    <div class="farm-icon">
      <i class="far fa-farm"></i>
    </div>
  </div>

  <div class="farm-name">
    <div *ngIf="mainService.farmChanged?.value?.farmName; else loading;">{{ mainService.farmChanged?.value?.farmName }}</div>
  </div>

  <ng-template #loading>
    <ng-container i18n="@@loading">Loading...</ng-container>
  </ng-template>

</a>

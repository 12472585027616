
<div class="modal-header">
  <h3 i18n="@@createnewfarm">CREATE NEW FARM</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body">

  <app-loading *ngIf="!form" [local]="true"></app-loading>

  <form *ngIf="form" class="" [formGroup]="form">

    <!-- name -->
    <app-form-input [control]="form.get('farmName')" i18n-error="@@farmnameisrequired" error="Farm name is required" i18n-label="@@name" label="Name">
      <input type="text" i18n-placeholder="@@farmname" placeholder="Farm Name" formControlName="farmName" class="form-control">
    </app-form-input>
    <div class="alert dark alert-danger alert-sm" *ngIf="!form.controls['farmName'].valid && form.controls['farmName'].dirty && form.controls['farmName'].value.length > 0">
      <i class="fa fa-exclamation-circle "></i>
      <ng-container i18n="@@thisnameisalreadyinuse">This name is already in use</ng-container>
    </div>

    <!-- region -->
    <app-form-input [control]="form.get('region')" i18n-error="@@regionisrequired" error="Region is required" i18n-label="@@region" label="Region">
      <ng-select
        formControlName="region"
        [items]="regions"
        [clearable]="false">
      </ng-select>
    </app-form-input>

    <!-- country -->
    <app-form-input [control]="form.get('country')" i18n-error="@@countryisrequired" error="Country is required" i18n-label="@@conutry" label="Country">
      <ng-select
        formControlName="country"
        [items]="countries"
        bindLabel="countryName"
        [clearable]="false"
        (change)="regions = $event.regionsArray; form.controls['region'].setValue(null)">
      </ng-select>
    </app-form-input>

  </form>

</div>

<div class="modal-footer">
  <button type="button" (click)="ngbActiveModal.dismiss()" class="btn btn-link" i18n="@@cancel">Cancel</button>
  <button [disabled]="disableCreateButton" type="button" (click)="createFarm()" class="btn btn-primary ml-1" i18n="@@createfarm">Create Farm</button>
</div>

<button type="button" hidden ngbAutofocus></button>


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.authService.getToken()) {
            this.router.navigate(['/login']);
        }
        if (state.url.includes('/animals/summary')) {
            let id = parseInt(route.queryParams['id'], 10) || 0;
            this.router.navigate(['/main/animals/all'], {
                queryParams: {
                    id: id,
                    tab: 'summary'
                }
              });
        }
        if (state.url.includes('/animals/details')) {
            let id = parseInt(route.queryParams['id'], 10) || 0;
            this.router.navigate(['/main/animals/all'], {
                queryParams: {
                    id: id,
                    tab: 'sessionData'
                }
              });
        }
        if (state.url.includes('/sessions/summary')) {
            let id = parseInt(route.queryParams['id'], 10) || 0;
            this.router.navigate(['/main/sessions/details'], {
                queryParams: {
                    id: id,
                    tab: 'summary'
                }
              });
        }
        if (state.url.includes('/groups/summary')) {
            let id = parseInt(route.queryParams['id'], 10) || 0;
            this.router.navigate(['/main/groups/details'], {
                queryParams: {
                    id: id,
                    tab: 'summary'
                }
              });
        }
        return this.authService.isLoggedIn().pipe(map(res => {
            if (!res) {
                this.router.navigate(['/login']);
            }
            return res;
        }));
    }
}

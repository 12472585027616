<h3 class="mb-1" i18n="@@basedOn">Based on</h3>

<ng-select
  class="form-control form-control-ng-select"
  [items]="actionsModalService.dataTypeHeaders"
  [placeholder]="localeService.constants.stringSelectField"
  bindLabel="label"
  bindValue="value"
  [(ngModel)]="selectedItem"
  (ngModelChange)="selectedItemChanged($event)">
</ng-select>

<h3 class="mt30 " i18n="@@choosegroup" *ngIf="selectedItem && selectedItem.value">Choose group</h3>

<div class="mt-4" *ngIf="selectedItem">
  <app-actions-modal-splitter-number #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 0"></app-actions-modal-splitter-number>
  <app-actions-modal-splitter-strings #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 1"></app-actions-modal-splitter-strings>
  <app-actions-modal-splitter-date #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 2"></app-actions-modal-splitter-date>
  <app-actions-modal-splitter-time #actionsModelSpliter [headerName]="selectedItem.name" *ngIf="selectedItem.type === 3"></app-actions-modal-splitter-time>
</div>

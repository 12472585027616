
import { Directive, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { LocaleService } from '../helpers/locale.service';

declare var $: any;
@Directive({
  selector: '[appDisplayLongDate]'
})
export class DisplayLongDateDirective implements OnChanges {

  @Input()
  appDisplayLongDate: Date;

  constructor(private el: ElementRef, private localeService: LocaleService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.appDisplayLongDate && changes['appDisplayLongDate'].currentValue) {
      let date = new Date(changes['appDisplayLongDate'].currentValue);
      this.el.nativeElement.value = `${date.getDate()} ${this.localeService.month[(date.getMonth())].month} ${date.getFullYear()}`;
    }
  }
}

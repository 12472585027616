<app-summary-header i18n-headerTitle="@@upload" i18n-subHeaderTitle="@@uploadSessionFilesAndAnimalData" headerTitle="Upload" subHeaderTitle="Upload session files and animal data"></app-summary-header>

<app-loading *ngIf="!mainService.farmChanged.value"></app-loading>

<div class="page-content page-content-y" *ngIf="mainService.farmChanged.value">

  <div *ngIf="!canReadXslx && uploadProgress < 0 && !uploadResult" class="text-shady-lady mb-010" i18n="@@currentBrowserDoesNotSupportXlsXlsx">
    Your current browser does not support uploading of XLSX/XLS files. Please use a different browser or upload CSV files instead.
  </div>
  <!-- <div *ngIf="!canReadXslx && uploadProgress < 0 && !uploadResult" style="margin: auto; margin-top: 15px; padding-left: 15px;" class="stack_bar_bottom animated animated-long alert-warning">
	  <div><span class="fa fa-2x fa-exclamation-circle" style="color:white;"></span></div>
	  <h4 style="color:white !important;">
		  <ng-container i18n="@@warning">Warning</ng-container>
	  </h4>
	  <div style="color: whitesmoke; font-weight: 800;">
		  Your current browser does not support uploading of XLSX/XLS files. Please use a different browser or upload csv files instead.
		  <div style="margin-top: 5px; clear: both; text-align: right; display: none;"></div>
	  </div>
  </div> -->


  <div class="card heading-border mt30" *ngIf="uploadProgress >= 0 && !uploadResult">

    <div class="card-header">
      <h3 i18n="@@uploadingselectedfiles">Uploading Selected Files</h3>
    </div>

    <!-- feedback on upload -->
    <div class="card-body ">

      <p>
        <ng-container i18n="@@uploadingfile">Uploading file</ng-container>
        {{currentFileNumber}}
        <ng-container i18n="@@of">of</ng-container>
        {{filesArray.length}}
      </p>

      <div class="mb-3">
        <ngb-progressbar showValue="true" type="success" [value]="uploadProgress" height="30px"></ngb-progressbar>
      </div>

      <table class="table">
        <tr *ngFor="let file of filesArray">
          <td>
            <i class="fa fa-table mr-3"></i>
            <strong>{{file.name}}</strong>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <input #fileInput type="file" id="files" [accept]="'.csv' + (canReadXslx ? ',.xls,.xlsx' : '')" class="filestyle" multiple
         data-icon="true" style="position: absolute; clip: rect(0px 0px 0px 0px);" (change)="filesChanged($event.target.files)"/>

  <app-transfer-education *ngIf="uploadProgress < 0 && !uploadResult" (uploadFiles)="openUploadScreen();"></app-transfer-education>

  <!-- upload result -->
  <div *ngIf="uploadResult">
    <div class="row text-center mb-3">
      <div class="col-sm-6 mb-2">
        <button (click)="uploadMore()" class="btn btn-primary" i18n="@@uploadmorefiles">Upload more files</button>
      </div>
      <div class="col-sm-6 mb-2">
        <button routerLink="/main/actions" class="btn btn-primary" i18n="@@done">Done</button>
      </div>
    </div>

    <div class="mb-4" *ngIf="uploadResult.error">
      <app-upload-summary [uploadSummaries]="uploadResult.value" [errors]="uploadResult.error.innererror"></app-upload-summary>
    </div>
    <div class="mb-4" *ngIf="!uploadResult.error">
      <app-upload-summary [uploadSummaries]="uploadResult.value"></app-upload-summary>
    </div>
    <div class="mb-4" *ngIf="uploadResult.value && uploadResult.value.length > 0">
      <app-successful-upload [uploadSummaries]="uploadResult.value"></app-successful-upload>
    </div>
    <div class="mb-4" *ngIf="uploadResult.error && uploadResult.error.innererror.length > 0">
      <app-error-summary [errors]="uploadResult.error.innererror"></app-error-summary>
    </div>

  </div>

</div>

import { BottomNotificationsService } from '../components/bottom-notifications/bottom-notifications.service';
import { Farm } from './models/farm.model';
import { AuthService } from '../auth/auth.service';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { LocaleService } from '../helpers/locale.service';


@Injectable()
export class MainGuard implements CanActivate {
    public hasFarms = true;
    public currentFarm: Farm = null;
    public bottomNotificationsService: BottomNotificationsService;
    constructor(private router: Router, private authService: AuthService, private localeService: LocaleService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.currentUser && this.authService.currentUser.userType === 'SuperUser') {
            return true;
        }
        if (state.url.indexOf('/main/subscriptions') < 0 && this.authService.currentUser && !this.authService.currentUser.currentFarm_FarmId) {
            if (this.bottomNotificationsService) {
                if (this.hasFarms) {
                    this.bottomNotificationsService.currentMessage.next({
                        title: this.localeService.constants.stringFarmsSubscriptionsHaveExpired,
                        message: this.localeService.constants.stringSubscriptionsHaveExpiredForTheFarmsYouHaveBeenInvitedTo,
                        type: 'error'
                    });
                } else {
                    this.bottomNotificationsService.currentMessage.next({
                        title: this.localeService.constants.stringNoFarmsExistForThisAccount,
                        message: this.localeService.constants.stringPleaseCreateNewFarmToStartUsingMiHub,
                        type: 'warning'
                    });
                }
            }
            this.router.navigate(['/main/subscriptions']);
            return false;
        }
        if (state.url.indexOf('/main/subscriptions') < 0 && this.currentFarm && this.currentFarm.subscriptionDaysLeft <= 0) {
            if (this.bottomNotificationsService) {
                if (this.authService.currentSubscription.value && this.authService.currentSubscription.value.subscriptionPlan_SubscriptionPlanId === 1) {
                    this.bottomNotificationsService.currentMessage.next({
                        title: this.localeService.constants.stringYourTrialHasExpired,
                        message: this.localeService.constants.stringHaveATruTestDeviceEnterYourSerialNumberToActivateTheStarterPlanForFree,
                        type: 'exclamation'
                    });
                } else {
                    this.bottomNotificationsService.currentMessage.next({
                        title: this.localeService.constants.stringSubscriptionHasExpired,
                        message: this.localeService.constants.stringSubscriptionHasExpiredPurchaseSubscriptionOrEnterYourTruTestDeviceSerialNumber,
                        type: 'warning'
                    });
                }
            }
            this.router.navigate(['/main/subscriptions']);
            return false;
        }
        if (state.url.indexOf('/main/subscriptions') < 0 && (!this.hasFarms)) {
            this.router.navigate(['/main/subscriptions']);
            return false;
        }

        return true;
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { GroupsService } from './services/groups.service';
import { Subscription } from 'rxjs';
import { SessionService } from './api/session.service';

@Injectable()
export class NpsSurveyService {
    private lastSurveyedUserId: string; // to avoid surveying same user twice. log here

    constructor(private authService: AuthService, private groupsService: GroupsService, private sessionService: SessionService) { }

    runNpsSurvey(user) {
        if ((<any>window).wootric && user && this.lastSurveyedUserId !== user.userId && user.userType !== 'Staff' && !this.authService.isDatamarsUser
            && this.authService.currentUserChanged.value
            && AuthService.emailsToIgnore.indexOf(this.authService.currentUserChanged.value.email.toLowerCase()) < 0
            && this.authService.currentUserChanged.value.userType.toLowerCase() !== 'superuser'
            && this.authService.currentUserChanged.value.email.toLowerCase().indexOf('test-e2e-') < 0
            && (this.groupsService.groups && this.groupsService.groups.value && this.groupsService.groups.value.length > 0) // at least one group created
            && (this.sessionService.sessions && this.sessionService.sessions.value && this.sessionService.sessions.value.length > 0) // uploaded at least one session
            && (this.authService.currentSubscription && this.authService.currentSubscription.value &&
            this.authService.currentSubscription.value.subscriptionPlan_SubscriptionPlanId === 2) // subscription is activated
        ) {
            this.lastSurveyedUserId = user.userId;
            (<any>window).wootricSettings = {
                email: user.email, // TODO: The current logged in user's email address. OPTIONAL
                external_id: user.userId, // TODO: The current logged in user's unique ID in your system.  Reference field for external integrations only.  OPTIONAL
                created_at: parseInt((new Date(user.created).getTime() / 1000).toFixed(0), 10),
                account_token: 'NPS-e810ddaa' // This is your unique account token.
            };
            setTimeout(() => { // just to ensure that angular finished loading before showing the survey
                // (<any>window).wootric_survey_immediately = true; // Shows survey immediately for testing purposes.  TODO: Comment out for production.
                (<any>window).wootric('run');
            });
        }
    }
}

import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { GroupWeightRecordSplit } from '../../../../models/group-weight-record-split.model';
import { CreateNewGroupService } from '../../../../create-new-group/create-new-group.service';
import { Group } from '../../../../models/group.model';
import { ActionsSoldDeadService } from '../../actions-sold-dead.service';
import { LocaleService } from '../../../../../helpers/locale.service';

@Component({
  selector: 'app-actions-sold-dead-model-spliter-date',
  templateUrl: './actions-sold-dead-model-spliter-date.component.html',
  styleUrls: ['./actions-sold-dead-model-spliter-date.component.scss']
})
export class ActionsSoldDeadModelSpliterDateComponent implements OnInit, OnChanges {
  public static self: ActionsSoldDeadModelSpliterDateComponent;
  @Input()
  public headerName: string;
  public aboveOrEqualToDate = new Date();
  public aboveSummary;
  public belowSummary;
  public aboveSelected = true;

  constructor(public actionsSoldDeadService: ActionsSoldDeadService, private createNewGroupService: CreateNewGroupService
    , public localeService: LocaleService) { ActionsSoldDeadModelSpliterDateComponent.self = this; }

  workoutSummaries() {
    this.aboveSummary = { count: 0, weight: 0, groupId: null, weightRecordIds: [] };
    this.belowSummary = { count: 0, weight: 0, groupId: null, weightRecordIds: [] };
    this.actionsSoldDeadService.animalData.forEach(ad => {
      if (ad[this.headerName] !== null && ad[this.headerName] !== undefined) {
        // console.log(new Date(this.aboveOrEqualToDate));
        if (ad[this.headerName] && new Date(ad[this.headerName]).getTime() >= new Date(this.aboveOrEqualToDate).getTime()) {
          this.aboveSummary.count++;
          this.aboveSummary.weight += ad.weight || 0;
          this.aboveSummary.weightRecordIds.push(ad.weightRecordId);
        } else {
          this.belowSummary.count++;
          this.belowSummary.weight += ad.weight || 0;
          this.belowSummary.weightRecordIds.push(ad.weightRecordId);
        }
      }
    });
    this.aboveSummary.weight = this.aboveSummary.count > 0 ? Math.round(this.aboveSummary.weight / this.aboveSummary.count) : 0;
    this.belowSummary.weight = this.belowSummary.count > 0 ? Math.round(this.belowSummary.weight / this.belowSummary.count) : 0;
  }

  ngOnInit() {
    this.actionsSoldDeadService.getWeightRecordIds = this.getWeightRecordIds;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headerName && changes['headerName'].currentValue) {
      this.workoutSummaries()
    }
  }

  getWeightRecordIds(): Array<number> {
    if (ActionsSoldDeadModelSpliterDateComponent.self.aboveSelected) {
      return ActionsSoldDeadModelSpliterDateComponent.self.aboveSummary.weightRecordIds;
    } else {
      return ActionsSoldDeadModelSpliterDateComponent.self.belowSummary.weightRecordIds;
    }
  }
}

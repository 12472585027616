import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-sub-heading',
    templateUrl: './sub-heading.component.html',
    styleUrls: ['./sub-heading.component.scss'],
    providers: []
})
export class SubHeadingComponent implements OnInit {
    @Input() headerTitle = '';
    @Input() subHeaderTitle = '';

    public ngOnInit(): void {
    }
}

<div class="row no-gutters small-gutters">

  <div class="col-sm-8">

    <div class="row no-gutters small-gutters">
      <div class="col-sm-7">
        <span i18n="@@onorafter">On or after</span>&nbsp;<span>{{displayTime}}</span>
        <div class="text-shady-lady">{{aboveSummary.count}}
          <ng-container i18n="@@animalsCapitalA">Animals</ng-container>&nbsp;&middot;&nbsp;{{aboveSummary.weight}}
          <ng-container i18n="abbreviation for average@@avg">Avg</ng-container>
        </div>
      </div>

      <div class="col-sm-5">
        <input type="time" class="form-control" (click)="workoutSummaries()" (change)="workoutSummaries()" [(ngModel)]="time">
      </div>
    </div>

  </div>

  <div class="col-sm-4" *ngIf="groupsSelectList && aboveSummary">

    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="aboveSummary.value"
      (ngModelChange)="groupChanged($event)">
    </ng-select>

  </div>
</div>


<div class="row no-gutters small-gutters mt-3">

  <div class="col-sm-8">
    <span i18n="@@before">Before</span>&nbsp;<span>{{displayTime}}</span>
    <div class="text-shady-lady">{{belowSummary.count}}&nbsp;
      <span i18n="@@animalsCapitalA">Animals</span>&nbsp;&middot;&nbsp;{{belowSummary.weight}}
      <span i18n="abbreviation for average@@avg">Avg</span>
    </div>
  </div>

  <div class="col-sm-4" *ngIf="groupsSelectList && belowSummary">
    <ng-select
      class="form-control form-control-ng-select"
      [items]="groupsSelectList"
      [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
      bindLabel="label"
      bindValue="value"
      (search)="groupsFiltered($event.term)"
      (clear)="groupsFiltered('')"
      (close)="groupsFiltered('')"
      [(ngModel)]="belowSummary.value"
      (ngModelChange)="groupChanged($event, false)">
    </ng-select>
  </div>
</div>

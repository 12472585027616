import {Injectable, InjectionToken, Injector} from '@angular/core';
import {SelectItem} from 'primeng/components/common/selectitem';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {from, Observable} from 'rxjs'

import {WeightRecordService} from '../../api/weight-record.service';
import {SessionAction} from '../../models/session-action';
import {DetailsSearchParamType} from '../../models/details-search-params';
import {ScriptService} from '../../script.service';
import {GroupWeightRecordSplit} from '../../models/group-weight-record-split.model';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {LocaleService} from '../../../helpers/locale.service';
import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {Group} from '../../models/group.model';
import {DatatypeHelper} from '../../../helpers/datatype.helper';
import {SessionService} from '../../api/session.service';
import {ActionService} from '../../api/action.service';
import {GroupsService} from '../../services/groups.service';
import {MainService} from '../../main.service';
import {ActionsAssignFilteredListModalComponent} from './actions-assign-filtered-list-modal.component';

import {AnimalService} from '../../api/animal.service';
import {WeightRecord} from '../../models/weight-record.model';
import {ActionsModalService} from '../../actions/actions-modal/actions-modal.service'

@Injectable({
  providedIn: 'root'
})
export class ActionsAssignFilteredListModalService {
  public animalData: Array<any>; // flattened animal data
  public action: SessionAction;
  public dataTypeHeaders: Array<any>; // all posible life/session data headers
  public groups: Array<SelectItem> = [];
  public groupsWrSplit: Array<GroupWeightRecordSplit>;
  // public actionComplete = new EventEmitter<boolean>();

  public visible = false;
  public movePriorWeights = false;

  /* one is supposed to be true in order to continue */
  /* if user is splitting then function will return current spliter component */
  /* if assignment to full group then  */
  public selectedGroupId: number = null;

  constructor(private weightRecordService: WeightRecordService,
              private scriptService: ScriptService,
              private groupsService: GroupsService,
              private actionService: ActionService,
              private bottomNotificationsService: BottomNotificationsService,
              private localeService: LocaleService,
              private createNewGroupService: CreateNewGroupService,
              private sessionService: SessionService,
              private mainService: MainService,
              private ngbModal: NgbModal,
              private injector: Injector,
              private animalService: AnimalService,
              private actionsModalService: ActionsModalService) {

  }

  init(animalList: Array<number>, wrList: Array<WeightRecord> = null, sessionAssignment = false): Observable<SessionAction> {
    animalList = animalList.filter(item => item !== undefined).slice();
    if (animalList.length < 1 && (!wrList || wrList.length < 1)) { // must have at least one animal to open modal
      return;
    }

    // open and return modal
    const modalRef: NgbModalRef = this.ngbModal.open(ActionsAssignFilteredListModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'modal-right modal-break-point-sm'
    });

    modalRef.componentInstance.singleAnimal = animalList.length === 1;
    modalRef.componentInstance.sessionAssignment = sessionAssignment;
    modalRef.componentInstance.showPreviousWeights = false;

    let wrs: Array<WeightRecord> = [];
    let reassignedSet = false;
    let anyIdAnimals = false;
    let recordCount = animalList.length;
    if (!wrList || wrList.length < 1) {
      let idReassignCount = 0;
      animalList.forEach(anim => {
        this.animalService.GetAnimalSummaryAndDetail(anim).subscribe(sum => {
          anyIdAnimals = anyIdAnimals || sum.customAnimalIdentifiers.length > 0; // do we have at least one ID'd animal in the list

          let wr = sum.weightRecords.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime())[0];
          wr.animal_AnimalId = anim;
          wrs.push(wr);

          if (wr.groups_GroupId !== null && wr.groups_GroupId !== undefined) {
            idReassignCount++;
          }

          // need to do this here as each animal completes WR load, otherwise the process has completed before there is data for the animal
          let unassigned = wrs.filter(function(w) { return !w.groups_GroupId || w.groups_GroupId === null; } ).length;
          let reassigned = wrs.length - unassigned;
          if (reassigned === 1) {
            modalRef.componentInstance.reassignString = this.localeService.constants.stringOneAnimalWillBeMovedToTheNewGroup;
            modalRef.componentInstance.changingGroup = true;
            reassignedSet = true;
            if (idReassignCount === 1) {
              modalRef.componentInstance.reassignCountString = this.localeService.constants.stringForTheOnenimalWithAnIDAlreadyAssignedToAGroup;
              modalRef.componentInstance.showPreviousWeights = true;
            }
          } else if (reassigned > 1) {
            modalRef.componentInstance.reassignString = this.localeService.constants.stringXAnimalsWillBeMovedToTheNewGroup.replace('<animalCount/>', reassigned);
            modalRef.componentInstance.changingGroup = true;
            reassignedSet = true;
            if (idReassignCount > 1) {
              modalRef.componentInstance.reassignCountString = this.localeService.constants.stringForTheXnimalsWithIDsAlreadyAssignedToAGroup.replace('<animalCount/>', idReassignCount);
              modalRef.componentInstance.showPreviousWeights = true;
            }
          } else if (!reassignedSet) {
            modalRef.componentInstance.showPreviousWeights = false;
            modalRef.componentInstance.changingGroup = false;
          }

          if (unassigned === 1) {
            modalRef.componentInstance.unassignedString = this.localeService.constants.stringOneAnimalWillBeAssignedToTheNewGroup;
          } else if (unassigned > 1) {
            modalRef.componentInstance.unassignedString = this.localeService.constants.stringXAnimalsWillBeAssignedToTheNewGroup.replace('<animalCount/>', unassigned);
          }

          if (!anyIdAnimals) {
            // never want to see this if there are no ID'd animals
            // regardless of reasignment or not
            modalRef.componentInstance.showPreviousWeights = false;
          }
        });
      });
    } else {
      wrs = wrList.slice();
      recordCount = wrs.length;
      modalRef.componentInstance.showPreviousWeights = animalList.length > 0;
      let idReassignCount = wrs.filter(w =>
                                        w.groups_GroupId !== null
                                        && w.groups_GroupId !== undefined
                                        && w.animal_AnimalId !== null
                                        && w.animal_AnimalId !== undefined
                                    ).length;

      let unassigned = wrs.filter(w => w.groups_GroupId === undefined || w.groups_GroupId === null).length;
      let reassigned =  wrs.filter(w => w.groups_GroupId !== undefined && w.groups_GroupId !== null).length;
      if (reassigned === 1) {
        modalRef.componentInstance.reassignString = this.localeService.constants.stringOneAnimalWillBeMovedToTheNewGroup;
        modalRef.componentInstance.changingGroup = true;
        reassignedSet = true;
        if (idReassignCount === 1) {
          modalRef.componentInstance.reassignCountString = this.localeService.constants.stringForTheOnenimalWithAnIDAlreadyAssignedToAGroup;
          modalRef.componentInstance.showPreviousWeights = true;
        }
      } else if (reassigned > 1) {
        modalRef.componentInstance.reassignString = this.localeService.constants.stringXAnimalsWillBeMovedToTheNewGroup.replace('<animalCount/>', reassigned);
        modalRef.componentInstance.changingGroup = true;
        reassignedSet = true;
        if (idReassignCount > 1) {
          modalRef.componentInstance.reassignCountString = this.localeService.constants.stringForTheXnimalsWithIDsAlreadyAssignedToAGroup.replace('<animalCount/>', idReassignCount);
          modalRef.componentInstance.showPreviousWeights = true;
        }
      } else if (!reassignedSet) {
        modalRef.componentInstance.showPreviousWeights = false;
        modalRef.componentInstance.changingGroup = false;
      }

      if (unassigned === 1) {
        modalRef.componentInstance.unassignedString = this.localeService.constants.stringOneAnimalWillBeAssignedToTheNewGroup;
      } else if (unassigned > 1) {
        modalRef.componentInstance.unassignedString = this.localeService.constants.stringXAnimalsWillBeAssignedToTheNewGroup.replace('<animalCount/>', unassigned);
      }

      anyIdAnimals = wrs.filter(w => w.animal_AnimalId !== null && w.animal_AnimalId !== undefined).length > 0;
      if (!anyIdAnimals) {
        // never want to see this if there are no ID'd animals
        // regardless of reasignment or not
        modalRef.componentInstance.showPreviousWeights = false;
      }
    }

    let action = <SessionAction>{
      v_ActionId: '',
      farmId: this.mainService.farmId,
      animalCount: recordCount,
      ided: true,
      entireSession: false,
      reassignGroup: true,
      weightRecords: wrs
    };
    this.actionsModalService.action = action; // need to put it here as well, as we use this for the actual group creation later

    // TODO: move to component
    // get groups for drop down
    this.groups = this.getGroups();

    this.movePriorWeights = !!this.mainService.farmChanged.value.groupAssignmentAll;
    this.action = action;
    this.action.movePriorWeights = this.movePriorWeights
      ? this.movePriorWeights // if we have a value, then use it
      : this.action.groupId !== null ? false : null; // if no value, AND we are reassigning (eg have a groupId), then default to false
    this.groupsWrSplit = null;
    this.selectedGroupId = null;
    this.visible = true;

    return from(modalRef.result);
  }

  updateGroups(opt: number = 3): void {
    this.groups = this.getGroups(opt);
  }

  private getGroups(opt: number = 3): SelectItem[] {
    let groups = [{ label: this.localeService.constants.stringNewGroup, value: 0 }];

    if (this.groupsService.groups.value) {
      this.groupsService.groups.value.forEach(grp => {
        if (opt === 1 && grp.isCurrent) {
          groups.push({ label: grp.displayName, value: grp.groupId });
        } else if (opt === 2 && !grp.isCurrent) {
          groups.push({ label: grp.displayName, value: grp.groupId });
        } else if (opt === 3) {
          groups.push({ label: grp.displayName, value: grp.groupId });
        }
      });
    } else {
      groups = null;
    }

    return groups;
  }

  previousWeightsChanged(keep: boolean) {
    this.movePriorWeights = keep;
    this.action.movePriorWeights = this.movePriorWeights;
    this.actionService.updateFarmGroupAssignmentProperty(keep);
  }
}

<section id="main" class="external-page-content">

  <div class="form-holder">

    <div class="card card-external">

      <div class="card-body">

        <!-- logo -->
        <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

        <!-- content -->
        <div class="mt-2 pb-5">

          <h1>Privacy Policy</h1>

          <p>
            In accordance with Federal Data Protection Act (<a href="https://www.fedlex.admin.ch/eli/cc/1993/1945_1945_1945/it" target="_blank">FADP</a>) we provide you with the necessary information
            regarding the processing of the personal data provided browsing this website. The information is not to be considered valid for other websites (for example, other websites that can be
            consulted through links on this page) external to the datamars.com domain, which is not to be considered in any way responsible for the websites of third parties.
          </p>

          <strong>Cookies</strong>

          <p>
            For more information on how this website uses Cookies, please visit the Cookie Policy available <a routerLink="/cookie-policy">here</a>.
          </p>

          <strong>Data controller</strong>

          <p>
            The company that determines the purpose and means of the processing of personal data through the website (hereinafter, the "Data Controller") is Datamars SA, a limited company under Swiss
            law with registered office in Via Industria 16 6814 Lamone, Switzerland, represented by the persons entitled to sign in accordance with the entries in the Cantonal Commercial Register (<a
            href="https://ti.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-106.245.144" target="_blank">link</a>).
          </p>

          <p>
            The contact details of the controller are the following: <a href="mailto:privacy@datamars.com">privacy@datamars.com</a>
          </p>

          <strong>Personal data collected</strong>

          <p>
            <strong>Personal data:</strong> any information concerning an identified or identifiable person. Datamars SA, through the website, collects various personal data: name, surname, e-mail
            address, telephone number, IP address of the user's device connected to the Internet.<br>
            <strong>Processing:</strong> any operation concerning personal data, regardless of the means and procedures used, namely the collection, recording, storage, use, modification,
            communication, archiving, deletion or destruction of data;<br>
            <strong>Data subject:</strong> the private person whose personal data is being processed. By way of example, the interested party is the user who navigates on the platform and sends for
            example, through the same, a request for information.
          </p>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td valign="top"><p><strong> PURPOSE OF THE PROCESSING AND GROUNDS OF JUSTIFICATION</strong></p>

                </td>
              </tr>

              <tr>
                <td><p><strong><em>a)</em></strong> Browsing the website, viewing content and technical management of the operation of the platform: </p>

                  <p>The computer systems used to operate this website acquire, acquisition, in order to operate the site, of personal data whose transmission is implicit in the use of Internet
                    communication protocols. This category of data includes IP addresses or domain names of computers and terminals used by users, URI/URL (Uniform Resource Identifier/Locator)
                    addresses of the resources requested, the time of the request, the method used to submit the request to the server, the size of the file obtained in response, the numerical code
                    indicating the status of the response given by the server (successful, error, etc.) and other parameters relating to the users operating system and computer environment.</p>

                </td>
              </tr>

              <tr>
                <td><p><strong><em>b)</em></strong> <strong>Contact or Information Request: </strong>to provide feedback to requests of information addressed to the Data Controller filling out the
                  contact form.</p>

                </td>
              </tr>

              <tr>
                <td><p><strong><em>c) </em>Signin-Up / Signin-In to Reserved Areas: </strong>registration to the platform through the creation of an account consisting of user number and password
                  (which you can also use to access the system through the App) in order to use the service offered by the Data Controller (for example: manage your farm directly in the reserved area,
                  upload documentation etc.). <strong></strong></p>

                  <p><strong></strong></p></td>
              </tr>

              <tr>
                <td><p><strong><em>d) Newsletter subscription: </em></strong>with prior consent, to subscribe to the Data Controller’s Newsletter to receive commercial, promotional, advertising
                  communications on products, initiatives, events, trade fairs and other information content of the Company.<strong></strong></p></td>
              </tr>

              <tr>
                <td><p><strong><em>e) Direct Marketing: </em></strong>with prior consent and until opposition for direct marketing, market research, direct sales, surveys on satisfaction degree,
                  newsletters and promotionals, commercial and advertising material or regarding events and initiatives, through automated means of E-Mail, telefax, messages, SMS, MMS or other types,
                  as well as operator-phone calls, paper mail or other information pack. The Data Controller uses newsletters and promotional communication reports to compare and possibly improve
                  results. Thanks to reports, the Data Controller will be able to discover, for example: the number of readers, single openings, unique “clickers” and clicks; devices and operating
                  systems employed to read the communication; user’s detailed activity; details of sent, delivered and forwarded emails; All these data are employed with the purpose of comparing, and
                  possibly improving, the result of communication.</p></td>
              </tr>

            </tbody>
          </table>

          <strong>Categories of recipients of personal data</strong>

          <p>
            The personal data provided will be communicated to subjects who will process personal data as data processor, as persons acting under the authority of the Data Controller or as individuals
            acting under the authority of the Controller or the Processor for the purposes listed above. Specifically, personal data will be communicated to recipients belonging to the following
            categories: (i) subjects that provide services for the management of the information system used by Datamars SA and of the telecommunications networks (including e-mail); (ii) subjects
            supporting the Data Controller in the management of web platforms; (iii) studies or companies in the context of assistance and consultancy relationships; (iv) competent authorities to
            fulfil legal obligations and/or provisions of public bodies, upon request. The list of data processors is constantly updated and available at the headquarters of the Data Controller.
          </p>

          <strong>Data retention and nature of conferral</strong>

          <p>
            The processing will be carried out in automated and / or manual form, with methods and tools to ensure maximum security and confidentiality, by persons specially trained for this purpose.
            The personal data collected will be stored in a form that allows identification of data subjects for a period of time not exceeding the achievement of the purposes for which personal data
            are processed. Data will be store:
          </p>

          <ul>
            <li style="list-style: lower-latin;">Browsing the website: until browsing session;</li>
            <li style="list-style: lower-latin;">Contact/Information requests: 10 years;</li>
            <li style="list-style: lower-latin;">Registration to the platform: for 3 years in case of unused account or until request of account deletion;</li>
            <li style="list-style: lower-latin;">Newsletter subscription: Opt out, consent withdrawal;</li>
            <li style="list-style: lower-latin;">Marketing purpose: consent withdrawal.</li>
          </ul>

          <p>
            The conferment of browsing data (purpose a)) is compulsory as it is carried out for the management of the platforms at a technical level and to allow you to browsing the website. The
            provision of data name, surname, e-mail address, telephone is necessary to send a contact request by filling out the forms located in the "contacts" (purpose b)). The provision of data for
            purposes c), registration to the platform and creation of an account that can also be used on the App is necessary. Failure to provide data will make it impossible to register on the
            platform. The provision of data for the purpose d), subscription to newsletters is free and optional. Failure to provide name, surname, e-mail address and telephone number will make it
            impossible to send the request. The provision of data for the purpose e), direct marketing is free and optional.
          </p>

          <strong>Communication of data abroad</strong>

          <p>
            Datamars SA within the scope of the services offered, communicates data to suppliers located outside of Switzerland. In particular, the data are hosted on Amazon's AWS. The Data Controller
            then assessed the conditions underlying the data communications and the related guarantees, through the analysis of contracts and information such as security certifications provided by
            the service providers in question. Amazon Web Services, Inc., has made available on its platform the SCC, Standard Contractual Clauses. The data transfer therefore takes place in
            compliance with FADP. You are free to request further information by contacting the data controller at <a href="mailto:privacy@datamars.com">privacy@datamars.com</a>.
          </p>

          <strong>Exercise of rights</strong>

          <p>Under the conditions set out in the LPD, the Data Controller acknowledges in particular the following rights (non-exhaustive list):</p>

          <ul>
            <li>obtain the rectification of inaccurate or obsolete personal data:</li>
            <li>to be informed in writing and free of charge if personal data concerning you are being processed;</li>
            <li>to revoke the consent to the processing of data that you have previously given;</li>
            <li>prevent the communication to third parties of personal data worthy of particular protection;</li>
            <li>express your opinion on an automated individual decision or request that it be reviewed by a natural person;</li>
            <li>to obtain the delivery of personal data or to demand their transmission to a third party;</li>
            <li>to request that data processing be stopped, that its disclosure to third parties be prevented, or that personal data be corrected or destroyed;</li>
            <li>the right to demand that a certain processing of personal data be prohibited, that a certain communication of personal data to third parties be prohibited or that personal data be
              deleted or destroyed;
            </li>
            <li>if neither the correctness nor the inaccuracy of the personal data can be proved, to request that a note be added to the data noting its disputed nature;</li>
            <li>to request that the rectification, destruction, blocking, in particular the communication to third parties, as well as the mention of the disputed character or the judgment be
              communicated to third parties or published;
            </li>
            <li>to have the illegality of the processing of personal data established.</li>
          </ul>

          <p>
            Without prejudice to any other administrative or judicial recourse, if the user considers that the processing of data relating to him/her is in breach of the provisions of the FADP, you
            have the right to lodge a complaint with the competent supervisory authority for the protection of personal data (Switzerland: <a
            href="https://www.edoeb.admin.ch/edoeb/it/home/l-ifpdt/contatto.html" target="_blank">IFPD</a>)).
          </p>

          <p>
            To unsubscribe from direct marketing feeds (eg. Newsletters, E-Mail), please write to <a href="mailto:privacy@datamars.com">privacy@datamars.com</a> or use our automated unsubscribing
            tools.
          </p>

          <strong>Privacy Information Modification | Effective Data</strong>

          <p>
            The Data controller has the right to change, update, add or remove portions of this privacy policy at its sole discretion and at any time. In order to facilitate such verification, this policy will indicate of the date of update.
          </p>

          <p>
            Update and effective data: February 1st, 2022
          </p>


        </div>

        <div class="text-center mb-2">
          <button routerLink="/terms-and-conditions" class="btn btn-link mx-1" i18n="@@termsandconditions">Terms and Conditions</button>
          <button routerLink="/cookie-policy" class="btn btn-link mx-1" i18n="@@cookiepolicy">Cookie Policy</button>
        </div>

      </div>
    </div>

  </div>

</section>

import {Component, Input, ViewChild, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnChanges, AfterViewInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() top = 0;
  @Input() readOnly = false;
  @Input() showError: boolean = null; // pass bool to when error needs to be shown.
  @Input() control: FormControl | AbstractControl; // alternate to showError.

  @Input() error: string; // general error or required error text
  @Input() errorPattern: string;
  @Input() errorMinlength: string;
  @Input() errorValidateEmail: string; // validateEmail is defined in form-validation.helper
  @Input() errorInvalidRegion: string; // invalidRegion is defined in form-validation.helper
  @Input() errorInvalidCountry: string; // invalidCountry is defined in form-validation.helper
  @Input() errorPasswordMismatch: string; // passwordMismatch is defined in form-validation.helper

  @Input() hideAsterisks: boolean;

  @ViewChild('inputGroup', { static: false }) inputGroup;

  inputBox;
  doc = document;
  required: boolean;

  constructor() {
    this.required = this.hideAsterisks ? false : this.hasRequiredField(this.control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.required = this.hideAsterisks ? false : this.hasRequiredField(this.control);
  }

  ngAfterViewInit() {
    if (this.inputGroup) {
      this.inputBox = Array.from<any>(this.inputGroup.nativeElement.children).find(tag => tag.tagName === 'INPUT');
    } else {
      console.error('Please ensure that you have provided <app-form-input> with an <input> element.');
    }
  }

  getErrorMessage(): string {
    if (this.control && this.control.errors) {
      const firstErrorKey: string = Object.keys(this.control.errors)[0];

      if (!firstErrorKey) {
        return '';
      }

      // required error
      if (firstErrorKey === 'required') {
        return this.error;
      } else {
        const errorPropertyName = 'error' + firstErrorKey.charAt(0).toUpperCase() + firstErrorKey.slice(1);
        const errorProperty = this[errorPropertyName];

        return errorProperty ? errorProperty : 'Unknown error';
      }
    } else if (this.showError) {
      return this.error || this.errorPattern || this.errorMinlength || this.errorValidateEmail ||
        this.errorPasswordMismatch || this.errorInvalidRegion || this.errorInvalidCountry || 'Unknown error';
    }
  }

  private hasRequiredField(abstractControl: AbstractControl): boolean {
    if (!abstractControl) {
      return false;
    }

    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.hasRequiredField(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }

    return false;
  }
}

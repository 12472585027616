import {Component, HostListener, Input, OnInit} from '@angular/core';
import {mobileBreakpoint} from '../../helpers/constants';

@Component({
  templateUrl: './page-actions.component.html'
})
export class PageActionsComponent implements OnInit {
  @Input() initialTemplate: 'LIST' | 'DETAILS';

  template: 'LIST' | 'DETAILS';

  @HostListener('window:resize', ['$event'])
  findTemplate() {
    this.template = window.innerWidth <= mobileBreakpoint ? 'LIST' : this.initialTemplate ? this.initialTemplate : 'DETAILS';
  }

  constructor() {
  }

  ngOnInit(): void {
    this.findTemplate();
  }
}

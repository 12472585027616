
<!-- header -->
<div class="modal-header">
  <h3 i18n="@@assignpermissionstothepersonyoureinvitingallcaps">INVITE USER</h3>
  <a (click)="hideDialog()" class="close"><i class="fas fa-times"></i></a>
</div>

<!-- content -->
<form [formGroup]="form" *ngIf="form" class="modal-form-with-footer">
  <div class="modal-body">

    <!-- ??? -->
    <app-form-input hidden="true">
      <input type="text" placeholder="dummyField" formControlName="dummyField" class="form-control">
    </app-form-input>

    <!-- first name -->
    <app-form-input [control]="form.get('firstName')"
                    i18n-error="@@pleaseenterafirstname" error="Please enter a first name"
                    i18n-errorPattern="@@validfirstnamerequired" errorPattern="A valid first name is required">
      <input type="text" i18n-placeholder="@@firstname" placeholder="First Name" formControlName="firstName" class="form-control">
    </app-form-input>

    <!-- last name -->
    <app-form-input [control]="form.get('lastName')"
                    i18n-error="@@pleaseenteralastname" error="Please enter a last name"
                    i18n-errorPattern="@@validlastnamerequired" errorPattern="A valid last name is required">
      <input type="text" i18n-placeholder="@@lastname" placeholder="Last Name" formControlName="lastName" class="form-control">
    </app-form-input>

    <!-- email -->
    <app-form-input [control]="form.get('email')"
                    i18n-error="@@pleaseenteravalidemailaddress" error="Please enter a valid email address"
                    i18n-errorValidateEmail="@@pleaseenteravalidemailaddress" errorValidateEmail="Please enter a valid email address">
      <input type="text" i18n-placeholder="@@emailaddress" placeholder="Email Address" formControlName="email" class="form-control"/>
    </app-form-input>

    <!-- ROLE PICKER -->
    <h2 class="mt-5">User Role</h2>

    <!-- farm role id -->
    <div class="custom-control custom-radio mt-3">
      <input id="radioUser" type="radio" formControlName="farmRoleId" [value]="2" class="custom-control-input">
      <label for="radioUser" class="custom-control-label">
        <span i18n="@@user">User</span>
        <div i18n="@@userRoleDescription">Has full access to the farm but cannot invite other users.</div>
      </label>
    </div>

    <!-- group viewer -->
    <div class="custom-control custom-radio mt-3">
      <input id="radioGroupViewer" type="radio" formControlName="farmRoleId" [value]="3" class="custom-control-input">
      <label for="radioGroupViewer" class="custom-control-label">
        <span i18n="@@groupViewer">Group Viewer</span>
        <div i18n="@@groupViewerRoleDescription">Can view selected groups and the animals in them.</div>
      </label>
    </div>

    <!-- group -->
    <div class="ml-4 mt-3">
      <ng-select
        class="form-control form-control-ng-select"
        *ngIf="farmRoleId?.value == 3"
        [multiple]="true"
        [items]="displayGroups"
        [placeholder]="localeService.constants.stringTypeToSearchOlderGroups"
        (search)="groupsFiltered($event.term)"
        (clear)="groupsFiltered('')"
        (close)="groupsFiltered('')"
        [closeOnSelect]="false"
        bindLabel="displayName"
        [(ngModel)]="selectedGroups"
        [ngModelOptions]="{standalone: true}">

        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="custom-control custom-checkbox">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" class="custom-control-input">
            <label class="custom-control-label">{{ item.displayName }}</label>
          </div>
        </ng-template>

      </ng-select>
    </div>
  </div>

  <!-- footer -->
  <div class="modal-footer">
    <a href="javascript:void(0)" class="btn btn-link" (click)="hideDialog()" i18n="@@cancel">Cancel</a>
    <button [disabled]="farmRoleId?.value == 3 && selectedGroups.length === 0" class="btn btn-primary ml-1" (click)="sendInvitation()" i18n="@@sendInvite">
      Send Invite
    </button>
  </div>

</form>


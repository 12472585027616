import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {Group} from '../models/group.model';
import {SessionAction} from '../models/session-action';
import {CreateNewGroupComponent} from './create-new-group.component';

@Injectable({
  providedIn: 'root'
})
export class CreateNewGroupService {
  private modalRef: NgbModalRef;

  constructor(private ngbModal: NgbModal) {

  }

  // open new group modal
  createGroup(action: SessionAction = null, assignAction = false, name: string = null, callBack: (group: Group) => void = null): NgbModalRef {

    this.modalRef = this.openModal();

    (this.modalRef.componentInstance as CreateNewGroupComponent).sessionAction = action;
    (this.modalRef.componentInstance as CreateNewGroupComponent).assignAction = assignAction;
    (this.modalRef.componentInstance as CreateNewGroupComponent).name = name;

    // do callback on modal close
    this.onModalClose(callBack);

    return this.modalRef;
  }

  // open edit group modal
  editGroup(group: Group, callBack: (group: Group) => void = null): NgbModalRef {
    this.modalRef = this.openModal();

    (this.modalRef.componentInstance as CreateNewGroupComponent).group = group;

    // do callback on modal close
    this.onModalClose(callBack);

    return this.modalRef;
  }

  private openModal(): NgbModalRef {
    const modalRef = this.ngbModal.open(CreateNewGroupComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-75'
    });

    modalRef.result.then(() => {

    }, dismissReason => {
      // console.log('dismissReason', dismissReason);
    });

    return modalRef;
  }

  private onModalClose(callBack): void {
    this.modalRef.result.then(updatedGroup => {
      callBack(updatedGroup);
    }, dismissReason => {
      // console.log(dismissReason);
    });
  }
}

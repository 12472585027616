import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { environment } from '../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  imgLocations = environment.imgLocations;
  window = window;
  constructor(public location: Location) { }

  ngOnInit() {
    $('body').addClass('external-page');
  }

  ngOnDestroy() {
    $('body').removeClass();
  }
}

import { SessionGroupAutomation } from './session-group-automation.model';
import { CensusWeightSummary } from './session-weight-summary';
import { WeightPlan, WeightPlanPoint } from './weight-plan.model';
import { WeightRecord } from './weight-record.model';
import { AnimalSummary } from './animal-adg.model';
import { SessionAction } from './session-action';
import { EArray } from '../../helpers/constants.helper';
import { Rounder } from '../../helpers/rounder.helper';
import utcToLocalProperty from '../api/utc-to-local-converter';

export class BaseGroup {
    groupId: number = null;
    groupName: string = null;
    farm_FarmId: number = null;
    species_SpeciesId: number = null;
    breed_BreedId: number = null;
    sex_SexId: number = null;
    targetWeightPlan?: WeightPlan = null;
    censuses: Array<GroupCensusWeightSummary> = null;
    v_Actions?: Array<SessionAction> = null;
}

export class Group extends BaseGroup {
    private _adg?: number;
    private _adgIconText?: GroupIconTextEnum;
    private _adgAnimCount?: number;
    private _isCurrent: boolean;

    displayName?: string;
    sessionGroupAutomation?: string; // a string to post back to create automation result report
    // adg?: number;
    selected: boolean; // selected to be shared to another user
    created?: Date;
    modified?: Date;
    targetWeightPlans?: Array<WeightPlan>;
    fieldDisplayOrder?: string = null;
    currentStatus: GroupCensusWeightSummary;
    soldDiedLeftGroupSummaries: Array<GroupSoldDiedLeftGroupSummaryModel> = null;

    // constructor(data: Object|Group) {
    //     super();
    //     Object.assign(this, data);
    // }

    public get isCurrent(): boolean {
      let nowDate = new Date();
      let pts = (<Array<WeightPlanPoint>>JSON.parse(this.targetWeightPlan.weightPlanPointsJson))
                      .map(e => { return { x: new Date(e.date), y: e.weight }; })
                      .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
      let endDate = pts[pts.length - 1].x;
      return nowDate <= endDate || (this.currentStatus.animalCount && this.currentStatus.animalCount > 0);
    }

    public get adgIconText(): GroupIconTextEnum {
      return this._adgIconText;
    }

    public get adgAnimCount(): number {
      return this._adgAnimCount;
    }

    public get adg(): number {
      if (this._adg !== undefined && this._adgIconText !== undefined && this._adgAnimCount !== undefined) {
          return this._adg;
      }

      this._adg = null;
      this._adgIconText = null;
      this._adgAnimCount = null;
      let groupedCensuses = (<EArray<GroupCensusWeightSummary>>this.censuses).groupBy('timeStamp');

      let adgCount = 0, animCount = 0;
      if (groupedCensuses.length > 0
        && this.censuses[0] !== null
        && this.censuses[0] !== undefined
        && this.censuses[0].animalWithIdCount >= 50) {
        for (let i = 0; i < groupedCensuses.length; i++) {
          if (groupedCensuses[i].list.find(c => c.animalAdg !== null)) {
            groupedCensuses[i].list.filter(c => c.animalAdg !== null).forEach((gc: GroupCensusWeightSummary) => {
              adgCount += gc.animalAdg * gc.animalsWithPrevWeight;
              animCount += gc.animalsWithPrevWeight;
            });
            break;
          }
        }
        if (animCount > 0 && this.currentStatus.animalsWithPrevWeight > 0) {
          this._adg = Rounder.round(adgCount / animCount); // if most animals have ids then use current status to include all current animals
          this._adgIconText = GroupIconTextEnum.Animal;
          this._adgAnimCount = animCount;
        }
      } else if (groupedCensuses.length > 1) {
        groupedCensuses[0].list.forEach((gc: GroupCensusWeightSummary) => {
          adgCount += gc.censusAdg * gc.animalsWithPrevWeight;
          animCount += gc.animalsWithPrevWeight;
        });
        for (let i = 0; i < groupedCensuses.length; i++) {
          if (groupedCensuses[i].list.find(c => c.censusAdg !== null)) {
            groupedCensuses[i].list.filter(c => c.animalAdg !== null).forEach((gc: GroupCensusWeightSummary) => {
              adgCount += gc.censusAdg * gc.animalsWithPrevWeight;
              animCount += gc.animalsWithPrevWeight;
            });
            break;
          }
        }
        if (animCount > 0 && this.currentStatus.animalsWithPrevWeight > 0) {
          this._adg = Rounder.round(adgCount / animCount); // if most animals have ids then use current status to include all current animals
          this._adgIconText = GroupIconTextEnum.Animal;
          this._adgAnimCount = animCount;
        }
      }
      if (this._adg === null
        && this.censuses.length > 0
        && this.censuses[0] !== null
        && this.censuses[0] !== undefined
        && this.censuses[0].animalWithIdCount === 0
        && this.censuses[0].censusAdg !== null) {
        this._adg = Rounder.round(this.censuses[0].censusAdg);
        this._adgIconText = GroupIconTextEnum.Census;
        this._adgAnimCount = null;
      }
      if (this._adg === null && groupedCensuses.length > 0 && groupedCensuses[0].list[0].censusAdg !== null && animCount > 0) {
        this._adg = Rounder.round(groupedCensuses[0].list[0].censusAdg);
        this._adgIconText = GroupIconTextEnum.Census;
        this._adgAnimCount = null;
      }

      return this._adg;
    }
}

export class GroupCensusWeightSummary {
    averageWeight = 0;
    animalCount = 0;
    maxWeight = 0;
    minWeight = 0;
    topWeight = 0;
    bottomWeight = 0;
    animalAdg = 0;
    censusAdg = 0;
    animalWithIdCount = 0;
    animalsWithPrevWeight = 0;
    // sessionName?: string; // calc

    @utcToLocalProperty
    timeStamp?: Date;
    group_GroupId?: number;
    sessionIds: Array<number>;

    // weightRecords?: Array<WeightRecord>;
    animalSummaries?: Array<AnimalSummary>;
    sessionDay = 0;
}

export class GroupSoldDiedLeftGroupSummaryModel {
    soldCount = 0;
    diedCount = 0;
    leftGroupCount = 0;
    timeStamp: Date;
    group_GroupId?: number;
}

export enum GroupIconTextEnum {
  Animal = 'animal',
  Census = 'census'
}

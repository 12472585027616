<div class="card card-widget group-by-graph">

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0" i18n="@@groupbyweight">Group by Weight</h3>
  </div>

  <!-- content -->
  <div class="card-body">

    <!-- loading -->
    <app-loading *ngIf="!weightRecords" [local]="true"></app-loading>

    <ng-container *ngIf="weightRecords">

      <form novalidate="novalidate" class="widget-below-above-form" *ngIf="weightRecords.length > 0">
        <span class="" i18n="@@below">Below</span>
        <input type="number" style="width:100px; display:inline !important; height:33px" class="form-control" step="5" [(ngModel)]="belowValue" max="{{aboveValue}}" [ngModelOptions]="{standalone: true}" (ngModelChange)="redrawGraph()"/>
        <span class="" i18n="@@andabove">and above</span>
        <input type="number" style="width:100px; display:inline !important; height:33px" class="form-control" step="5" [(ngModel)]="aboveValue" min="{{belowValue}}" [ngModelOptions]="{standalone: true}" (ngModelChange)="redrawGraph()"/>
      </form>

      <div class="group-by-graph-highchart" [appHighcharts]="options"></div>

    </ng-container>


  </div>

</div>

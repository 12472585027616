import { Animal } from '../../../models';
import { Group } from '../../../models/group.model';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DatatypeHelper } from '../../../../helpers/datatype.helper';

declare let moment: any;

@Component({
  selector: 'app-animal-life-data',
  templateUrl: './animal-life-data.component.html',
  styleUrls: ['./animal-life-data.component.scss']
})
export class AnimalLifeDataComponent implements OnChanges {
  @Input()
  animal: Animal;
  @Input()
  groups: Array<Group>;

  udfNames: Array<string> = [];
  udfData: Array<string> = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.animal && changes['animal'] && changes['animal'].currentValue) {
      this.udfNames = [];
      this.udfData = [];
      if (changes['animal'].currentValue.userDefinedFieldsJson) {
        let udfs = JSON.parse(this.animal.userDefinedFieldsJson);
        let c = 0;
        for (let property in udfs) {
          if (udfs[property]) {
            this.udfNames[c] = property;
            let val = '';
            let prop = udfs[property];
            val = prop.hasOwnProperty('RawValue') ? prop['RawValue']
                : prop.hasOwnProperty('Value') ? prop['Value']
                : prop;
            this.udfData[c] = val;
            // check val for being timestamp
            this.udfData[c] = DatatypeHelper.tryFormatDateWithMoment(val, 'D MMM YYYY');
            c++;
          }
        }
      }
    }
  }

  yearString(date: Date) {
    return moment.utc(date).format('YYYY');
  }
}

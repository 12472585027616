import { DetailsSearchParamType } from '../main/models/details-search-params';
import * as moment from 'moment-timezone';

const nz=moment.tz.zonesForCountry('NZ',true);

export class DatatypeHelper {

    public static getFieldType(data): DetailsSearchParamType { // format is able to be passed in to force an explicit format check only
        if (this.isValidDate(data)) {
            return DetailsSearchParamType.date;
        } else if (/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(data)) {
            return DetailsSearchParamType.time;
        } else if (!isNaN(data)) {
            return DetailsSearchParamType.number;
        }
        return DetailsSearchParamType.string;
    }

    static isValidIsoDate(val: string) {
        // this regex allows 9999 as the year, 99 as the month, and 99 as the day; however the moment.isValid call is the safety net here
        return /^\d{4}-[01]\d-[0-3]\d( |T)\d{2}:\d{2}:\d{2}(\.\d{3,7})?(Z|(\+|-)[012]\d:\d{2})?$/.test(val) && moment(val).isValid();
    }

    private static isValidDMMMYYYYformatted(val: string) {
        return /\d{1,2} [A-Za-z]{3} \d{4}/.test(val) && moment(val).isValid();
    }

    private static isValiddddMMMDDYYYYformatted(val: string) {
        return /[A-Za-z]{3} [A-Za-z]{3} \d{1,2} \d{4}/.test(val) && moment(val).isValid();
    }

    private static isValidDate(val: string) {
        return this.isValidIsoDate(val) || this.isValidDMMMYYYYformatted(val) || this.isValiddddMMMDDYYYYformatted(val);
    }

    public static tryFormatDateWithMoment(val: string|Date, format: string):string|any {
        if(val===undefined || val == null){
            return val;
        }

        if(typeof val  === 'string'){
            if(this.isValidDate(val)){
                //remove the zone factory and parse it as utc
                var parsedDate = moment(val,"YYYY-MM-DDTHH:mm:ss", true)
                if(!parsedDate.isValid()){
                    //return ConvertToUTC(val).format(format);// passed real date string
                    return moment(val).format(format);
                }
                return parsedDate.utc(true).format(format)
            }
        }else if(typeof val.getMonth === 'function'){//check if it's date
            return ConvertToUTC(val).format(format);
        }
        return val;
    }

    public static monthStringForMonthId(monthId: number)
    {                
        // 0 => "Jan", but in the locale of the moment.js (i.e. from moment.locale())

        // Every month of the year 2000 has a 15th in it.
        return moment([2000,0,15]).month(monthId).format("MMM");
    }

    public static dateEquals(date1: string, date2: string, format: string) {
        return this.tryFormatDateWithMoment(date1, format) === this.tryFormatDateWithMoment(date2, format);
    }
}

export function ConvertToUTC(val:Date|number[]|string):moment.Moment{
    return moment.utc(val)
}
import {Injectable} from '@angular/core';
import {GroupsService} from '../../services/groups.service';
import {Group} from '../../models/group.model';
import {SelectItem} from 'primeng/components/common/selectitem';
import {DetailsSearchParamType} from '../../models/details-search-params';
import {DatatypeHelper} from '../../../helpers/datatype.helper';
import {SessionAction} from '../../models/session-action';
import {ScriptService} from '../../script.service';
import {WeightRecordService} from '../../api/weight-record.service';
import {LocaleService} from '../../../helpers/locale.service';
import {AnimalStatusEnum, Animal} from '../../animals/shared/animal.model';
import {GroupWeightRecordSplit} from '../../models/group-weight-record-split.model';
import {ActionsModalComponent} from '../actions-modal/actions-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActionsSoldDeadModalComponent} from './actions-sold-dead-modal.component';
import {from, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionsSoldDeadService {
  // groups: Array<SelectItem>;
  dataTypeHeaders: Array<SelectItem>;
  animalData: Array<any>;
  action: SessionAction = null;
  actionType: AnimalStatusEnum = AnimalStatusEnum.none;
  modalHeaderText = '';

  visible = false;

  fixedSetOfAnimals: boolean;
  fixedSetBodyText = '';

  showDatePicker = true; // we don't display the datepicker for current, as that is just removing a status record

  /* one is supposed to be true in order to continue */
  /* if user is psliting then function will return current spliter component */
  /* if assignint to full group then  */
  public getWeightRecordIds: () => Array<number> = null;

  constructor(private scriptService: ScriptService,
              private weightRecordService: WeightRecordService,
              private localeService: LocaleService,
              private ngbModal: NgbModal) {
  }

  init(action: SessionAction, actionType: AnimalStatusEnum, fixedSet: boolean = false): Observable<string> {
    if (!action) { // must have a valid action type and action to open modal
      return;
    }

    this.fixedSetOfAnimals = fixedSet;

    this.modalHeaderText = actionType === AnimalStatusEnum.dead
      ? this.localeService.constants.stringMarkAsDied
      : actionType === AnimalStatusEnum.sold
        ? this.localeService.constants.stringMarkAsSold
        : this.localeService.constants.stringMarkAsCurrent;
    this.action = action;
    this.actionType = actionType;
    this.visible = true;

    this.showDatePicker = (actionType === AnimalStatusEnum.dead || actionType === AnimalStatusEnum.sold);

    if (this.fixedSetOfAnimals) {
      let tempBodyTextPlural = actionType === AnimalStatusEnum.dead
        ? this.localeService.constants.stringCountAnimalsWithIdsWillBeUpdatedToHaveAStatusOfDied
        : actionType === AnimalStatusEnum.sold
          ? this.localeService.constants.stringCountAnimalsWithIdsWillBeUpdatedToHaveAStatusOfSold
          : this.localeService.constants.stringCountAnimalsWithIdsWillBeUpdatedToHaveAStatusOfCurrent;
      let tempBodyTextSingular = actionType === AnimalStatusEnum.dead
        ? this.localeService.constants.stringOneAnimalWithAnIdWillBeUpdatedToHaveAStatusOfDied
        : actionType === AnimalStatusEnum.sold
          ? this.localeService.constants.stringOneAnimalWithAnIdWillBeUpdatedToHaveAStatusOfSold
          : this.localeService.constants.stringOneAnimalWithAnIdWillBeUpdatedToHaveAStatusOfCurrent;

      if (this.action.v_ActionId && this.action.v_ActionId.toString().endsWith('_single')) {
        // it's come from the Animals/Summary page, so we don't bother saying it's for 1 animal
        tempBodyTextSingular = '';
      }

      this.fixedSetBodyText = action.animalCount === 1 ? tempBodyTextSingular : tempBodyTextPlural.replace('<animalCount/>', action.animalCount);

    } else {
      this.scriptService.loadScript('momentJs').then(() => {
        if (action.weightRecords) {
          // from details page, which has weightRecords already, so no need to query again
          this.processUdfs(action.weightRecords);
        } else if (action.entireSession) {
          this.weightRecordService.getDataFieldsForEntireSession(action.sessionId, true).subscribe(res => {
            if (res) {
              this.processUdfs(res);
            }
          });
        } else {
          this.weightRecordService.getDataFieldsForSession(action.sessionId, true, action.groupId).subscribe(res => {
            if (res) {
              this.processUdfs(res);
            }
          });
        }
      });
    }

    const ngbModalRef: NgbModalRef = this.ngbModal.open(ActionsSoldDeadModalComponent, {
      backdrop: 'static',
      windowClass: 'modal-right'
    });

    return from(ngbModalRef.result);
  }

  private processUdfs(wrs) {
    this.animalData = [];
    this.dataTypeHeaders = [{ label: this.localeService.constants.stringWeight, value: { name: 'weight', type: DetailsSearchParamType.number } }];
    wrs.forEach(wr => {
      if (wr.animal && wr.animal_AnimalId) {
        let anim: any = {};
        anim.animalId = wr.animal_AnimalId;
        anim.weight = wr.weight;
        anim.weightRecordId = wr.weightRecordId;
        if (wr.userDefinedFieldsJson && wr.userDefinedFieldsJson.length > 5) {
          let udf = JSON.parse(wr.userDefinedFieldsJson);
          for (let param in udf) {
            if (udf[param]) {
              anim[param] = udf[param];
              let found = this.dataTypeHeaders.find(a => a.label === param);
              if (!found) {
                this.dataTypeHeaders.push({ label: param, value: { name: param, type: DatatypeHelper.getFieldType(udf[param]) } })
              } else if ((found.value.type === DetailsSearchParamType.date && DatatypeHelper.getFieldType(udf[param]) !== DetailsSearchParamType.date)
                || (found.value.type === DetailsSearchParamType.number && DatatypeHelper.getFieldType(udf[param]) !== DetailsSearchParamType.number)
                || (found.value.type === DetailsSearchParamType.time && DatatypeHelper.getFieldType(udf[param]) !== DetailsSearchParamType.time)) {
                found.value.type = DetailsSearchParamType.string;
              }
            }
          }
        }
        if (wr.animal.userDefinedFieldsJson && wr.animal.userDefinedFieldsJson.length > 5) {
          let ludf = JSON.parse(wr.animal.userDefinedFieldsJson);
          for (let param in ludf) {
            if (ludf[param] && ludf[param].Value) {
              anim[param] = ludf[param].Value;
              let found = this.dataTypeHeaders.find(a => a.label === param);
              if (!found) {
                this.dataTypeHeaders.push({ label: param, value: { name: param, type: DatatypeHelper.getFieldType(ludf[param].Value) } })
              } else if ((found.value.type === DetailsSearchParamType.date && DatatypeHelper.getFieldType(ludf[param].Value) !== DetailsSearchParamType.date)
                || (found.value.type === DetailsSearchParamType.number && DatatypeHelper.getFieldType(ludf[param].Value) !== DetailsSearchParamType.number)
                || (found.value.type === DetailsSearchParamType.time && DatatypeHelper.getFieldType(ludf[param]) !== DetailsSearchParamType.time)) {
                found.value.type = DetailsSearchParamType.string;
              }
            }
          }
        }
        this.animalData.push(anim);
      }
    });
  }
}

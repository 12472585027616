<!--<app-create-draft *ngIf="integration" #createDraftModal [animalIds]="dataForPpl" [integratedDevice]="curIntegratedDevice" [integratedDevices]="integration.integratedDevices"></app-create-draft>-->

<app-loading *ngIf="!data" relative="true"></app-loading>

<ng-container *ngIf="data">

  <!-- numbers -->
  <div class="card card-summary-header">
    <div class="card-body">
      <div>
        <h2>
          <span *ngIf="filteredCensus" [appNumberDisplay]="{number: filteredCensus.animalCount}"></span>
          <span *ngIf="filteredCensus"> / </span>
          <span [appNumberDisplay]="{number: group && group.currentStatus ? group.currentStatus.animalCount : 0}"></span>
        </h2>
        <p class="text-shady-lady" i18n="@@animals">Animals</p>
      </div>

      <div>
        <h2 *ngIf="filteredCensus" [appNumberDisplay]="{number: filteredCensus.averageWeight}"></h2>
        <h2 *ngIf="!filteredCensus" [appNumberDisplay]="{number: group && group.currentStatus ? group.currentStatus.averageWeight : 0}"></h2>
        <p class="text-shady-lady" i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
      </div>

      <div>
        <h2 *ngIf="filteredCensus" [appNumberDisplay]="{number: filteredCensus.minWeight}"></h2>
        <h2 *ngIf="!filteredCensus" [appNumberDisplay]="{number: group && group.currentStatus ? group.currentStatus.minWeight : 0}"></h2>
        <p class="text-shady-lady" i18n="abbreviation for Minimum Weight@@minweight">Min Weight</p>
      </div>

      <div>
        <h2 *ngIf="filteredCensus" [appNumberDisplay]="{number: filteredCensus.maxWeight}"></h2>
        <h2 *ngIf="!filteredCensus" [appNumberDisplay]="{number: group && group.currentStatus ? group.currentStatus.maxWeight : 0}"></h2>
        <p class="text-shady-lady" i18n="abbreviation for Maximum Weight@@maxweight">Max Weight</p>
      </div>

      <div>
        <h2 *ngIf="filteredCensus" [appNumberDisplay]="{number: filteredCensus.animalAdg, roundTo: 2}"></h2>
        <h2 *ngIf="!filteredCensus" [appAdg]="group"></h2>
        <p class="text-shady-lady">
          <ng-container i18n="acronym for Average Daily Gain@@adg">ADG</ng-container>
          <app-adg-icon *ngIf="group?.currentStatus" class="ml-1" [group]="group" [animalsWithWeights]="filteredCensus ? filteredCensus.animalCount : null"></app-adg-icon>
        </p>
      </div>
    </div>
  </div>

  <!-- filters -->
  <div class="filter">
    <app-reports-filters
      [data]="fullDataSet || []" [filterOptions]="filterOptions"
      (globalFilterChanged)="tt.filterGlobal($event.value, 'contains')"
      (globalParameterService.detailsSearchParams)="tt.filter($event.value, 'contains')">
    </app-reports-filters>
  </div>

  <!-- actions, exports, table columns and actual table -->
  <div class="card card-table">

    <div class="card-header card-header-table">
      <div class="animals-header-bar">
        <div>

            
          <!-- actions -->
          <div ngbDropdown>
            <button class="btn btn-link" ngbDropdownToggle *ngIf="curCensus && permissionsService.permissions.canEditData" i18n="@@updateStatus">Update Status</button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="markAsSold(filteredAnimals)">{{localeService.constants.stringMarkAsSold}}</button>
              <button ngbDropdownItem (click)="markAsDied(filteredAnimals)">{{localeService.constants.stringMarkAsDied}}</button>
            </div>
            <!-- assignment -->
            <button class="btn btn-link" (click)="assignOrReassignAnimals()" *ngIf="curCensus && permissionsService.permissions.canEditData" i18n="@@changeGroup">Change Group</button>
          </div>

        </div>
        <div class="d-flex justify-content-end">

          <!-- export -->
          <div ngbDropdown>
            <button *ngIf="headers" class="btn btn-link" ngbDropdownToggle i18n="@@export">Export</button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="reportsService.downloadCsv(curHeaders, filteredAnimals, group.displayName)">{{localeService.constants.stringSpreadsheetBracketsCsv}}</button>
              <button ngbDropdownItem (click)="reportsService.exportPDF(curHeaders, filteredAnimals, group.displayName)">PDF</button>
              <button ngbDropdownItem *ngIf="integration && permissionsService.permissions.canAccessPpl && permissionsService.permissions.canEditData"
                      (click)="showCreateDraftModal()" i18n="@@remotewowdrafter">Remote WOW Drafter
              </button>
            </div>
          </div>

          <!-- columns -->
          <app-reports-column-picker
            *ngIf="curHeaders"
            [curHeaders]="curHeaders"
            [headers]="headers"
            (headersChanged)="columnsReordered($event,true)">
          </app-reports-column-picker>
        </div>
      </div>
    </div>

    <!--    <app-animals-list [headers]="curHeaders" [dataSource]="data"></app-animals-list>-->

    <p-table class="no-overflow"
             #tt
             [columns]="curHeaders"
             [value]="data"
             (onColReorder)="columnsReordered($event.columns)"
             [reorderableColumns]="true"
             (sortFunction)="reportsService.sortData($event, data); sortEvent($event);"
             [customSort]="true"
             [sortField]="sortField"
             [sortOrder]="sortOrder"
             [autoLayout]="true"
             [rows]="50"
             [paginator]="true"
             [pageLinks]="11"
             [rowsPerPageOptions]="[20,50,100]"
             (onFilter)="filterChanged(); filtered($event)"
             (window:resize)="reportsService.onResize();"
             tableStyleClass="table table-hover">

      <!-- header -->
      <ng-template pTemplate="header" let-columns>
        <tr class="ui-widget-content">
          <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="ui-state-default">
            <span [innerHTML]="col.header"></span>
            <p-sortIcon *ngIf="col.field !== 'wgGraphInternal' && col.field !== 'adgGraphInternal'" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <!-- body -->
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr class="ui-widget-content">
          <td *ngFor="let col of columns">
            <ng-container *ngIf="col.field !== 'wgGraphInternal' && col.field !== 'adgGraphInternal'">
              <div *ngIf="col.isId && col.isId === true" class="ui-cell-data">
                <span [title]="row[col.field]">
                  <a href="javascript:void(0)" (click)="openAnimalInModal(row)">{{ row[col.field]}}</a>
                  <!-- <a routerLink="/main/animals/details" [queryParams]="{id:row['#aid']}">{{row[col.field]}}</a>-->
                </span>
              </div>
              <div *ngIf="!col.isId">
                                    <span *ngIf="col.type !== 2" [title]="row[col.field]">
                                        {{row[col.field]}}
                                    </span>
                <span *ngIf="col.type === 2" [title]="dayMonthYearString(row[col.field])">
                                        {{dayMonthYearString(row[col.field]) }}
                                    </span>
              </div>
            </ng-container>
            <ng-container *ngIf="col.field === 'wgGraphInternal' || col.field === 'adgGraphInternal'">

              <div style="width: 174px; height: 30px; margin-left: -5px;">
                <div *ngIf="graphsLoaded && (row.barGraph || row.sparkline)" style="width: 174px; height: 30px;"
                     [appHighcharts]="col.field === 'adgGraphInternal' ? row.barGraph : row.sparkline"></div>
              </div>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-floating-scroll-bar *ngIf="!isMobile"></app-floating-scroll-bar>

  </div>

</ng-container>


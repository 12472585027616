import {Directive, Input, SimpleChanges, ElementRef, OnChanges} from '@angular/core';

import {LocaleService} from '../helpers/locale.service';
import {Group} from '../main/models/group.model';

@Directive({
  selector: '[appAdg]'
})
export class AdgDirective implements OnChanges {
  @Input() appAdg: Group;

  constructor(private el: ElementRef, private localeService: LocaleService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.appAdg && changes['appAdg'].currentValue && this.appAdg.censuses && this.appAdg.censuses.length > 0) {
      let adgNum = this.appAdg.adg;
      this.el.nativeElement.innerText = adgNum !== null && !isNaN(adgNum) ? adgNum.toString() : this.localeService.constants.stringNA;
    } else if (this.appAdg && changes['appAdg'].currentValue) {
      this.el.nativeElement.innerText = this.localeService.constants.stringNA;
    }
  }
}

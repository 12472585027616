<app-summary-header
  i18n-headerTitle="@@mydetails"
  i18n-subHeaderTitle="@@managepersonalinformation"
  headerTitle="My Details"
  subHeaderTitle="Manage personal information">
</app-summary-header>

<app-loading *ngIf="!form"></app-loading>

<div class="page-content page-content-y" *ngIf="form">

  <div *ngIf="allowDeletion">
    <div class="card mb-3">
      <div class="card-header">
        <h3 i18n="@@testaccountsonlysection">Test Accounts Only section</h3>
      </div>
      <div class="card-body">
        <button class="btn btn-danger" (click)="deleteAccount()" i18n="@@deleteaccount">DELETE ACCOUNT</button>
      </div>
    </div>
  </div>

  <div class="row holder">

    <div class="col-lg-6 mb-grid-gutter">

      <!-- details form -->
      <div class="card">

        <div class="card-header">
          <h3 class="m-0" i18n="@@mydetails">My Details</h3>
        </div>

        <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="save($event)" class="form-horizontal">

            <!-- first name -->
            <app-form-input [control]="form.get('firstName')" i18n-label="@@firstname" label="First Name"
                            i18n-error="@@validfirstnamerequired" error="A valid first name is required"
                            i18n-errorPattern="@@validfirstnamerequired" errorPattern="A valid first name is required">
              <input type="text" formControlName="firstName" class="form-control">
            </app-form-input>

            <!-- last name -->
            <app-form-input [control]="form.get('lastName')" i18n-label="@@lastname" label="Last Name"
                            i18n-error="@@validlastnamerequired" error="A valid last name is required"
                            i18n-errorPattern="@@validlastnamerequired" errorPattern="A valid last name is required">
              <input type="text" formControlName="lastName" class="form-control">
            </app-form-input>

            <!-- email -->
            <app-form-input [control]="form.get('email')" i18n-label="@@email" label="Email"
                            i18n-error="@@invalidemail" error="Invalid Email"
                            i18n-errorValidateEmail="@@invalidemail" errorValidateEmail="Invalid Email">
              <input type="email" formControlName="email" class="form-control">
            </app-form-input>

            <!-- phone number -->
            <app-form-input [control]="form.get('phoneNumber')" i18n-label="@@phonenumber" label="Phone Number"
                            i18n-error="@@invalidphonenumber" error="Invalid phone number"
                            i18n-errorPattern="@@invalidphonenumber" errorPattern="Invalid phone number">
              <input type="phone" formControlName="phoneNumber" class="form-control">
            </app-form-input>

            <!-- address line 1 -->
            <app-form-input [control]="form.get('addressLine1')" i18n-label="@@address1" label="Address 1"
                            i18n-error="@@invalidaddress" error="Invalid address">
              <input type="text" formControlName="addressLine1" class="form-control">
            </app-form-input>

            <!-- address line 2 -->
            <app-form-input [control]="form.get('addressLine2')" i18n-label="@@address2" label="Address 2"
                            i18n-error="@@invalidaddress" error="Invalid address">
              <input type="text" formControlName="addressLine2" class="form-control">
            </app-form-input>

            <!-- Town/City -->
            <app-form-input [control]="form.get('city')" i18n-label="@@towncity" label="Town/City">
              <input type="text" formControlName="city" class="form-control">
            </app-form-input>

            <!-- Post Code -->
            <app-form-input [control]="form.get('postcode')" i18n-label="@@postcode" label="Post Code">
              <input type="text" formControlName="postcode" class="form-control">
            </app-form-input>

            <!-- region -->
            <app-form-input [control]="form.get('region')" i18n-error="@@regionisrequired" error="Region is required" i18n-label="@@region" label="Region">
              <ng-select
                formControlName="region"
                [items]="regions"
                [clearable]="false">
              </ng-select>
            </app-form-input>

            <!-- country -->
            <app-form-input [control]="form.get('country')" i18n-error="@@countryisrequired" error="Country is required" i18n-label="@@country" label="Country">
              <ng-select
                formControlName="country"
                [items]="countries"
                bindLabel="countryName"
                bindValue="countryName"
                [clearable]="false"
                (change)="regions = $event.regionsArray; form.controls['region'].setValue(null)">
              </ng-select>
            </app-form-input>

            <!-- show all errors -->
            <app-form-errors-sum [errors]="serverErrorMessage"></app-form-errors-sum>

            <!-- submit button -->
            <div class="form-footer">
              <button type="submit" [disabled]="!formHasChanged" class="btn btn-primary" i18n="@@savechanges">Save Changes</button>
            </div>

          </form>
        </div>

        <app-loading *ngIf="requestStatus === 1"></app-loading>

      </div>
    </div>

    <div class="col-lg-6">
      <div class="mb-grid-gutter">
        <app-user-invitations></app-user-invitations>
      </div>
      <div class="mb-grid-gutter">
        <app-user-password></app-user-password>
      </div>
    </div>

  </div>
</div>

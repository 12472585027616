import { Group } from '../../../models/group.model';
import { WeightPlanPoint } from '../../../models/weight-plan.model';
import { Component, OnInit, Input } from '@angular/core';
import { LocaleService } from '../../../../helpers/locale.service';
import { ScriptService } from '../../../script.service';
import {PermissionsService} from '../../../../auth/permissions.service';
import {IntegrationService} from '../../../api/integration.service';

@Component({
    selector: 'app-group-widget-graph',
    templateUrl: './group-widget-graph.component.html',
    styleUrls: ['./group-widget-graph.component.scss']
})
export class GroupWidgetGraphComponent implements OnInit {
    @Input()
    group: Group;

    options: any;

    isPplFarmWithIntegration = false;

    constructor(private localeService: LocaleService, private scriptService: ScriptService
        , private permissionsService: PermissionsService, private integrationService: IntegrationService) { }

    ngOnInit() {
        if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) {
            this.isPplFarmWithIntegration = true;
        }
        this.scriptService.load('highcharts').then(data => {
            this.scriptService.loadArray(['hcBoost', 'hcMore']).then(laterData => {
                this.redrawGraph();
            });
        });
    }

    redrawGraph() {
        if (this.group === null || this.group.targetWeightPlan === null) {
            this.options = {
                series: []
            };
            return;
        }
        let chartPoints = this.group.censuses ? this.group.censuses.filter(cp => cp.averageWeight).slice() : [];
        chartPoints.sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
        let points = chartPoints.map((point) => {
            return {
                x: new Date(point.timeStamp).getTime(),
                y: point.averageWeight,
            };
        }),
            xAxis: any = {
                type: 'datetime',

                tickInterval: (365.25 / 12) * (24 * 3600 * 1000),
                tickLength: 0,
                dateTimeLabelFormats: {
                    month: '%b %Y'
                },
                labels:
                {
                    enabled: false
                }
            };


            let zones = []; // if this is an empty array it will have no impact on the graph
            if (this.isPplFarmWithIntegration) {
                let lastIdx = chartPoints.length - 1;
                let lastPt = chartPoints[lastIdx];
                if ((new Date(lastPt.timeStamp)).getUTCDay() !== 0) { // if it's a rolling session, so the data is still being incremented
                    //if not sunday
                    chartPoints.splice(lastIdx); // remove the rolling session from the standard datapoints
                    points.forEach(p => {
                        zones.push({
                            value: p.x + 0.1, // need the slight overage to ensure we don't get previous colour coming off the end
                            color: '#00AAE7'
                        });
                    });
                    zones[lastIdx] = { // changing colour of final point, which also changes colour of line from previous point to match
                        value: points[lastIdx].x + 0.1, // need the slight overage to ensure we don't get previous colour coming off the end
                        color: '#ADB5BD'
                    };
                    zones[lastIdx + 1] = { // set a default colour for the line to override the "global" colours used for our highcharts
                        color: '#ADB5BD'
                    };
                }
            }

            let series = [{
                name: this.localeService.constants.stringAverage,
                allowPointSelect: true,
                data: points,
                marker: { enabled: true, lineWidth: 2, symbol: 'circle' },
                zIndex: 2,
                type: 'line',
                zones: zones,
                zoneAxis: 'x'
            }, {
                name: this.localeService.constants.stringMinToMax,
                data: chartPoints.map(point => [new Date(point.timeStamp).getTime(), point.minWeight, point.maxWeight]),
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                type: 'areasplinerange',
                linkedTo: ':previous',
                color: 'rgb(149, 206, 255)',
                fillOpacity: 0.4,
                zIndex: 0
            },
            {
                name: this.localeService.constants.stringTarget,
                allowPointSelect: true,
                data: (<Array<WeightPlanPoint>>JSON.parse(this.group.targetWeightPlan.weightPlanPointsJson))
                    .map(e => { return { x: new Date(e.date).getTime(), y: e.weight }; }),
                marker: { enabled: true, lineWidth: 1, lineColor: '#69B342', symbol: 'diamond' },
                zIndex: 4,
                type: 'spline'
            }];

        this.options = {
            title: {
                text: null
            },

            subtitle: {
                text: null
            },
            xAxis: xAxis,
            yAxis: {
                title: {
                    text: null
                },
                lineWidth: 1,
                allowDecimals: false,
                tickAmount: 5,
                endOnTick: false,
                alignTicks: false,
                labels: {
                    style: {
                        fontFamily: '"Lato", Helvetica, Arial, sans-serif',
                        fontSize: '12px'
                    }
                },
                min: 0
            },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            exporting: {
                enabled: false

            },

            series: series
        };
    }
}

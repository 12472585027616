<div class="row">

  <!-- upload -->
  <div class="col-12 col-md-6">
    <div class="card text-center">
      <div class="card-body">
        <div class="card-body-image">
          <img src="assets/custom-icons/Upload-BLUE.png" alt="">
        </div>
        <div class="card-body-text" i18n="Tru-Test is the company name@@uploadsessionfilesfrompc">
          Upload Tru-Test session files from your PC
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" (click)="log();" i18n="@@uploadfiles">Upload Files</button>
      </div>
    </div>
  </div>

  <!-- XR5000 or ID5000 -->
  <div class="col-12 col-md-6">
    <div class="card text-center">
      <div class="card-body">
        <div class="card-body-image">
          <img src="assets/custom-icons/xr_upload_blue.png" alt="">
        </div>
        <div class="card-body-text" i18n="'XR5000' and 'ID5000' are model names@@uploadsessionfilesfromdevice">
          Upload session files from your XR5000 or ID5000 indicator
        </div>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary" target="_blank" href="http://support.livestock.datamars.com/{{language}}/articles/591063"
                i18n="abbreviation for 'Information'@@info">Info
      </a>
      </div>
    </div>
  </div>

  <!-- Data Link -->
  <div class="col-12 col-md-6">
    <div class="card text-center">
      <div class="card-body">
        <div class="card-body-image">
          <img src="assets/custom-icons/mobile_upload_blue.png" alt="">
        </div>
        <div class="card-body-text" i18n="'Data Link' is the product name@@uploadsessionfilesfromapp">
          Upload session files using the Data Link smartphone app
        </div>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary mh5"
        target="_blank" href="http://support.livestock.datamars.com/{{language}}/articles/619563">Android
      </a>
        <a class="btn btn-primary mh5 btn-nocaps"
        target="_blank" href="http://support.livestock.datamars.com/{{language}}/articles/741780">iPhone
      </a>
      </div>
    </div>
  </div>

  <!-- Data Link for PC -->
  <div class="col-12 col-md-6">
    <div class="card text-center">
      <div class="card-body">
        <div class="card-body-image">
          <img src="assets/custom-icons/pc_data_link_upload.png" alt="">
        </div>
        <div class="card-body-text" i18n="'Data Link' is the product name@@uploadsessionfilesfrompcdatalink">
          Upload session files using Data Link for PC
        </div>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary" target="_blank" href="http://support.livestock.datamars.com/{{language}}/articles/623357"
                i18n="abbreviation for 'Information'@@info">Info
      </a>
      </div>
    </div>
  </div>

</div>

import { AuthService } from '../../auth/auth.service';





import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { Breed } from '../models/breed.model';
import { Observable } from 'rxjs';
import { LocaleService } from '../../helpers/locale.service';
import { of, combineLatest } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BreedService {
    private cachedBreeds: Array<Breed> = [];
    private cachedCountry: number;

    constructor(private authService: AuthService, private apiService: ApiService, private localeService: LocaleService) { }

    // privateGetBreeds

    getBreeds = (countryId: number): Observable<Array<Breed>> => {
        if (this.cachedCountry === countryId) {
            return of(this.cachedBreeds);
        }
        this.cachedCountry = countryId;
        return combineLatest(this.localeService.getBreedsSexAndSpeciesTranslation(), this.apiService.get(`/odata/Breeds`)
            .pipe(map((res: any) => {
                this.cachedBreeds = res.value;
                return this.cachedBreeds;
            }))).pipe(map(res => {
                if (res[0] && res[1]) {
                    this.localeService.translateBreedSexArray(res[1], 'breedName', res[0]['breeds']);
                    return res[1];
                }
            }), catchError(this.apiService.handleError));
    }
}



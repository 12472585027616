export class GroupWeightRecordSplit {
    fieldName: string;
    splitCriteria: string;
    groupId: number;
    weightRecordIds: Array<number> = [];
    constructor(groupId, fieldName, splitCriteria) {
        this.groupId = groupId;
        this.fieldName = fieldName;
        this.splitCriteria = splitCriteria + '';
    }
}

<h3 class="page-heading" i18n="@@devices">Devices</h3>
<div class="card card-table mb-5">
  <div *ngIf="devices">
    <table class="table mb-0">
      <thead>
        <tr>
          <th i18n="refers to the device model@@model">Model</th>
          <th i18n="@@serialnumber">Serial Number</th>
          <th i18n="@@softwareversion">Software Version</th>
          <th i18n="@@lastseen">Last Seen</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of devices">
          <td>{{device.model}}</td>
          <td>{{device.serialNumber}}</td>
          <td>{{device.softwareVersion}}</td>
          <td>{{dayMonthYearString(device.lastSeen)}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="devices.length === 0">
        <tr>
          <td colspan="1000" i18n="@@noregistereddevices">No registered devices</td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-loading *ngIf="!devices" [local]="true"></app-loading>

</div>


<h3 class="page-heading mt-5" i18n="Abbreviation for applications@@apps">Apps</h3>
<div class="card">
  <div *ngIf="mobileApps">
    <table class="table mb-0">
      <thead>
        <tr>
          <th i18n="Abbreviation for application@@app">App</th>
          <th i18n="Abbreviation for application@@appVersion">App Version</th>
          <th i18n="@@phoneOrComputer">Phone/Computer</th>
          <th i18n="@@lastuser">Last User</th>
          <th i18n="@@lastseen">Last Seen</th>
        </tr>
      </thead>
      <tbody *ngIf="mobileApps.length > 0">
        <tr *ngFor="let device of mobileApps">
          <td>{{device.appType}}</td>
          <td>{{device.dataLinkVersion}}</td>
          <td>{{device.dataLinkDeviceModel}}</td>
          <td>{{device.lastUser}}</td>
          <td>{{dayMonthYearString(device.lastSeen)}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="mobileApps.length === 0">
        <tr>
          <td colspan="1000" i18n="Apps is abbreviation for applications@@noregisteredmobileapps">No registered mobile apps</td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-loading *ngIf="!mobileApps" [local]="true"></app-loading>

</div>

import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {combineLatest} from 'rxjs';

import {SessionService} from '../../api/session.service';
import {GroupsService} from '../../services/groups.service';
import {DashboardEducationService} from '../dashboard-education.service';
import {MainService} from '../../main.service';
import {SessionGroupSummary} from '../../models/session-group-summary.model';
import {SessionAction} from '../../models/session-action';
import {SessionRecomendation} from '../../models/session-recomendation';
import {ActionsService} from '../../actions/actions.service';
import {Group} from '../../models/group.model';
import {SessionDoableActions} from '../../actions/shared/session-doable-actions';

@Component({
  selector: 'app-action-widget',
  templateUrl: './action-widget.component.html',
  styleUrls: ['./action-widget.component.scss']
})
export class ActionWidgetComponent implements OnInit, OnDestroy {
  sessions: SessionGroupSummary[];
  actionsCount = 0;

  private sessionsGroupsSub: Subscription;
  private recommendationsSub: Subscription;

  constructor(
    private mainService: MainService,
    public sessionService: SessionService,
    public dashboardEducationService: DashboardEducationService,
    private groupsService: GroupsService,
    public actionsService: ActionsService) {

  }

  ngOnInit() {

    // listen for sessions/groups change
    this.sessionsGroupsSub = combineLatest([
      this.sessionService.sessions, this.groupsService.groups
    ]).subscribe(sessionsAndGroups => {
      const sessions = sessionsAndGroups[0];
      const groups = sessionsAndGroups[1];

      this.handleSessions(sessions, groups);
    });

    // listen for recommendations change
    this.recommendationsSub = this.actionsService.recommendations.subscribe(recommendations => {
      this.handleRecommendations(recommendations);
    });
  }

  ngOnDestroy() {
    if (this.sessionsGroupsSub) {
      this.sessionsGroupsSub.unsubscribe();
    }
    if (this.recommendationsSub) {
      this.recommendationsSub.unsubscribe();
    }
  }

  disableEnableAction(actionId: string, enabled: boolean | 'DONE') {
    const session = this.sessions.find(s => s.actions.findIndex(a => a.v_ActionId === actionId) >= 0);

    if (session) {
      const action = session.actions.find(a => a.v_ActionId === actionId);
      if (action) {
        action.greyOut = !enabled;

        if (enabled === 'DONE') {
          this.soldDeadModalResult(action);
        }
      }
    }
  }

  soldDeadModalResult(action: SessionAction) {
    if (action.v_ActionId !== null && action.v_ActionId !== undefined) {
      this.actionsService.ignoreWeightRecords(action, false).subscribe(res => {
        // sessions will be updated through service (sessions behaviour subject)

        const session = this.sessions.find(s => s.actions.findIndex(a => a.v_ActionId === action.v_ActionId) >= 0);
        if (session) {
          const ac = session.actions.find(a => a.v_ActionId === a.v_ActionId);
          if (ac) {
            this.actionsService.ignoreWeightRecords(ac, false).subscribe(() => {});
          }
        }
      });
    }
  }

  private async handleSessions(sessions: SessionGroupSummary[], groups: Group[]) {
    if (!sessions || groups) {
      this.sessions = sessions;
    }

    // only for the dashboard (do not run on actions page)
    if (sessions !== null && sessions !== undefined && groups !== null && groups !== undefined) {
      if (sessions.length === 0) { // if no sessions then get education and display it
        sessions = await this.dashboardEducationService.getActionsData();
      } else {

        // if we have sessions now then make sure education is hidden
        this.dashboardEducationService.actionsE = false;
      }
    } else {
      this.dashboardEducationService.actionsE = false; // on farm change hide education.
    }

    const sessionDoableActions: SessionDoableActions = this.actionsService.getSessionsWithDoableActions(sessions, groups);

    this.actionsCount = sessionDoableActions.actionsCount;

    if (sessionDoableActions.sessions) {
      this.sessions = sessionDoableActions.sessions;

      if (this.actionsService.recommendations.value) {
        this.handleRecommendations(this.actionsService.recommendations.value);
      }
    }
  }

  private handleRecommendations(recommendations: SessionRecomendation[]): void {
    if (recommendations && this.sessions && this.groupsService.groups.value) {
      this.sessions = this.actionsService.getSessionsWithRecommendations(recommendations, this.sessions);
    }
  }
}

import {LocaleService} from '../../helpers/locale.service';
import {Component} from '@angular/core';
import {OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ApiService} from '../../main/api/api.service';
declare var $: any;

@Component({
  templateUrl: './comms.component.html',
  styleUrls: ['./comms.component.scss']
})


export class CommsComponent implements OnInit, OnDestroy {
  userId: string;
  userEmailObfuscated: string;
  marketingOptIn: string;

  optingOut = false;
  optedOut = false;
  marketingMessage: string;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private localeService: LocaleService,
    private apiService: ApiService,
    private router: Router
  ) {
  }

  ngOnDestroy() {
    $('body').removeClass();
  }

  ngOnInit(): void {
    $('body').addClass('bg-dark-grey external-page pbn onload-check sb-l-c sb-r-c');
    this.title.setTitle(`${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.userId = this.route.snapshot.queryParams['u'];
    this.marketingOptIn = this.route.snapshot.queryParams['m'];
    if (!this.userId) {
      this.router.navigate(['login']);
    }
    if (!this.marketingOptIn || this.marketingOptIn.toLocaleLowerCase() !== 'y') {
      this.marketingOptIn = 'n'; // always default to unsubscribing workflow unless we have an explicit Yes
    }
    this.marketingOptIn = this.marketingOptIn.toLocaleLowerCase();

    this.setUpDisplay();
  }

  setUpDisplay() {
    if (this.apiService) {
      this.apiService.get('/Api/Comms/GetObfuscatedEmailAddress?guid=' + this.userId, 'text').subscribe(result => {
        if (!result || result === '') {
          this.router.navigate(['login']);
        }
        this.userEmailObfuscated = result;
        // get rid of wrapping quotes and add html bolding style
        this.userEmailObfuscated = '<b>' + this.userEmailObfuscated.substring(1, this.userEmailObfuscated.length - 1) + '</b>';
        if (this.marketingOptIn === 'y') {
          this.submitOptIn();
        } else {
          this.marketingMessage = this.localeService.constants.stringAreYouSureYouWantToUnsubscribeEmailFromDatamarsLivestockMarketingEmails
                                        .replace('<emailAddress/>', this.userEmailObfuscated);
          this.optingOut = true;
        }
      });
    }
  }

  submitOptIn() {
    this.apiService.get('/Api/Comms/OptUserIn?guid=' + this.userId, 'text').subscribe(result => {
      this.marketingMessage = this.localeService.constants.stringEmailHasBeenSubscribedToDatamarsLivestockMarketingEmails.replace('<emailAddress/>', this.userEmailObfuscated);
    });
  }

  submitOptOut() {
    this.apiService.get('/Api/Comms/OptUserOut?guid=' + this.userId, 'text').subscribe(result => {
      this.marketingMessage = this.localeService.constants.stringEmailHasBeenUnsubscribedFromDatamarsLivestockMarketingEmails.replace('<emailAddress/>', this.userEmailObfuscated);
      this.optedOut = true;
    });
  }
}

<div class="container-full-height">

  <!-- page header -->
  <app-summary-header [headerTitle]="group?.displayName">

    <app-group-actions [group]="group" initialTemplate="DETAILS" (actionDone)="groupActionDone($event)"></app-group-actions>

  </app-summary-header>

  <app-loading *ngIf="!group"></app-loading>

  <ng-container *ngIf="group">

    <!-- page content, tabs -->
    <ngb-tabset [destroyOnHide]="tabDestroyOnHide" (tabChange)="tabChange($event)" #tabSet="ngbTabset" [activeId]="activeTabOnLoad">

      <!-- summary -->
      <ngb-tab i18n-title="@@summary" title="Summary" id="summary">
        <ng-template ngbTabContent>

          <div class="page-content">

            <!-- summary header -->
            <div class="card card-summary-header">
              <div class="card-body">
                <div>
                  <h2 [appNumberDisplay]="{number: group.censuses.length > 0 ? group.currentStatus.animalCount : 0, roundTo: 0}"></h2>
                  <p i18n="@@animals">Animals</p>
                </div>
                <div>
                  <h2 [appNumberDisplay]="{number: group.censuses.length > 0 ? group.currentStatus.averageWeight : 0}"></h2>
                  <p i18n="abbreviation for Average Weight@@avgweight">Avg Weight</p>
                </div>
                <div>
                  <h2 [appNumberDisplay]="{number: group.censuses.length > 0 ? group.currentStatus.minWeight : 0}"></h2>
                  <p i18n="abbreviation for Minimum Weight@@minweight">Min Weight</p>
                </div>
                <div>
                  <h2 [appNumberDisplay]="{number: group.censuses.length > 0 ? group.currentStatus.maxWeight : 0}"></h2>
                  <p i18n="abbreviation for Maximum Weight@@maxweight">Max Weight</p>
                </div>
                <div>
                  <h2 [appAdg]="group"></h2>
                  <p>
                    <ng-container i18n="acronym for Average Daily Gain@@adg">ADG</ng-container>
                    <app-adg-icon class="ml-1" *ngIf="group?.currentStatus" [group]="group" [animalsWithWeights]="adgAnimalCount"></app-adg-icon>
                  </p>
                </div>
              </div>
            </div>

            <app-groups-summary [group]="group" (changeTab)="tabSet.select('animals')"></app-groups-summary>

          </div>
        </ng-template>
      </ngb-tab>

      <!-- animal list -->
      <ngb-tab i18n-title="@@animals" title="Animals" id="animals">
        <ng-template ngbTabContent>
          <div class="page-content">
            <app-groups-details [group]="group"></app-groups-details>
          </div>
        </ng-template>
      </ngb-tab>

    </ngb-tabset>

  </ng-container>

</div>

import { WeightRecordService } from '../api';
import { SessionService } from '../api/session.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss'],
  providers: [WeightRecordService]
})
export class SessionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

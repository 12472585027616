<div class="card card-widget group-by-graph">

  <!-- widget header -->
  <div class="card-header d-flex">
    <h3 class="m-0" i18n="acronym for Average Daily Gain@@groupbyadg">Group by ADG</h3>
  </div>

  <!-- content -->
  <div class="card-body">

    <!-- loading -->
    <app-loading *ngIf="!weightRecordsAdg" [local]="true"></app-loading>

    <ng-container *ngIf="weightRecordsAdg">

      <form novalidate="novalidate" class="widget-below-above-form" *ngIf="weightRecordsAdg.length > 0">
        <span class="" i18n="@@below">Below</span>
        <input step="0.05" type="number" style="width:100px; display:inline !important; height:33px" class="form-control" [(ngModel)]="belowValue" max="{{aboveValue}}" [ngModelOptions]="{standalone: true}" (ngModelChange)="redrawGraph()"/>
        <span class="" i18n="@@andabove">and above</span>
        <input step="0.05" type="number" style="width:100px; display:inline !important; height:33px" class="form-control" [(ngModel)]="aboveValue" min="{{belowValue}}" [ngModelOptions]="{standalone: true}" (ngModelChange)="redrawGraph()"/>
      </form>

      <div class="group-by-graph-highchart" [appHighcharts]="options"></div>

    </ng-container>

  </div>
</div>

<app-summary-header
  i18n-headerTitle="@@dashboard"
  i18n-subHeaderTitle="@@viewkeyinformationataglance"
  headerTitle="Dashboard"
  subHeaderTitle="View key information at a glance">
</app-summary-header>

<!-- widgets -->
<div class="page-content page-content-y">
  <div class="row">

    <div class="col-xl-6">
      <app-action-widget class="widget"></app-action-widget>
    </div>

    <div class="col-xl-6">
      <app-group-widget class="widget"></app-group-widget>
    </div>

    <div class="col-xl-6">
      <app-activity-widget class="widget"></app-activity-widget>
    </div>

    <div class="col-xl-6">
      <app-session-widget class="widget"></app-session-widget>
    </div>

  </div>
</div>

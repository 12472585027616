import { SubscriptionService } from '../../api/subscription.service';
import { FarmService } from '../../api';
import { AuthService } from '../../../auth/auth.service';
import { Farm } from '../../models';
import { MainService } from '../../main.service';
import {Component, OnInit, Input, OnDestroy, ViewChild, TemplateRef} from '@angular/core';
import { IntercomService } from '../../intercom.service';
import { Observable ,  Subscription } from 'rxjs';
import { LocaleService } from '../../../helpers/locale.service';
import { BottomNotificationsService } from '../../../components/bottom-notifications/bottom-notifications.service';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserFarm } from '../../models/user-farm';
import {ConfirmationService} from '../../../components/confirmation/confirmation.service';
import {FarmCreationComponent} from '../../manage/farm-creation/farm-creation.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DatatypeHelper } from '../../../helpers/datatype.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-subscriptions-farms',
  templateUrl: './subscriptions-farms.component.html',
  styleUrls: ['./subscriptions-farms.component.scss']
})
export class SubscriptionsFarmsComponent implements OnInit, OnDestroy {
  farms: Array<Farm>; // farms with current sub
  nonDeletedFarms: Array<Farm>;

  farmToDelete: Farm;
  @ViewChild('deleteConfirmationTitleTemplate', { static: true }) deleteConfirmationTitleTemplate: TemplateRef<any>;
  @ViewChild('deleteConfirmationTemplate', { static: true }) deleteConfirmationTemplate: TemplateRef<any>;

  private sub: Subscription;

  constructor(public mainService: MainService,
              public authService: AuthService,
              private farmService: FarmService,
              private subscriptionService: SubscriptionService,
              private intercomService: IntercomService,
              private localeService: LocaleService,
              private confirmationService: ConfirmationService,
              private bottomNotificationsService: BottomNotificationsService,
              private ngbModal: NgbModal) { }

  ngOnInit() {
    this.nonDeletedFarms = [];
    this.mainService.updateFarms();
    this.sub = combineLatest(this.mainService.farmsChanged, this.authService.currentSubscription).subscribe(farmAndSub => {
      const farms = farmAndSub[0], sub = farmAndSub[1];
      if (farms) {
        if (!sub) {
          this.farms = [];
          return;
        }
        const newFarms = farms.filter(f => f.subscription_SubscriptionId === sub.subscriptionId).slice();
        if (this.farms) {
          this.farms = Array.from(new Set(newFarms)); // uniquify the list of Farms
          this.farms.forEach((farm, index) => {
            if (farm.deleted) {
              newFarms.splice(index, 0, farm);
            }
          });
        }
        this.farms = Array.from(new Set(newFarms)); // uniquify the list of Farms
        this.subscriptionService.getSubscriptionFarmCreators(sub.subscriptionId).subscribe(farmUsers => {
          if (farmUsers) {
            farmUsers.forEach(user => {
              const farm = this.farms.find(f => f.farmId === user.farmId);
              if (farm) {
                farm['creator'] = user;
              }
            })
          }
        });
      } else {
        this.farms = null;
      }

      if (this.farms) {
        this.nonDeletedFarms = this.farms.filter(f => !f.deleted);
      }
    });
  }

  dayMonthYearString(date: string) {
    return moment.tz(moment.utc(date).format(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland').local().format('D MMM YYYY')
  }

  deleteFarm(farm: Farm): void {
    this.farmToDelete = farm;

    this.confirmationService.confirm({
      titleTemplate: this.deleteConfirmationTitleTemplate,
      messageTemplate: this.deleteConfirmationTemplate
    }).subscribe(() => {
      this.doDeleteFarm(farm);
    }, () => {
      this.farmToDelete = null;
    });
  }

  doDeleteFarm(farm: Farm): void {

    this.farms.find(f => f.farmId === farm.farmId).deleting = true;
    if (this.farms && this.farms.filter(f => !f.deleted).length > 1) {
      this.farmService.updateFarm({ farmId: farm.farmId, deleted: true }).pipe(catchError((err: any, caught: Observable<any>) => {
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringDeleteFailed,
          message: this.localeService.constants.stringFarmDetailsFailedToUpdatePleaseTryAgainLater,
          type: 'warning'
        });
        this.farms.find(f => f.farmId === farm.farmId).deleted = false;
        this.farms.find(f => f.farmId === farm.farmId).deleting = false;
        this.farmToDelete = null;
        return of(null);
      })).subscribe(() => {
        this.farmToDelete = null;
        this.nonDeletedFarms.splice(this.nonDeletedFarms.indexOf(farm), 1);

        if (this.mainService.farmsChanged.value && this.mainService.farmsChanged.value.length > 1) {
          let userAdminFarmIds = this.authService.currentUser.userFarms.filter(uf => uf.farmRoleId === 1).map(uf => uf.farmId);
          this.mainService.changeFarm(
            this.mainService.farmsChanged.value.find(f =>
              f.farmId !== farm.farmId
              && userAdminFarmIds.indexOf(f.farmId) > -1
            ).farmId, () => {
              this.mainService.updateFarms();
          });
        } else {
          this.mainService.updateFarms(() => {
            this.authService.refreshUser(this.authService.currentUser.userId).subscribe();
          });
        }
        this.farms.find(f => f.farmId === farm.farmId).deleted = true;
        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringDeleteSuccessful,
          message: this.localeService.constants.stringFarmXSuccessfullyDeleted.replace('<farmName/>', farm.farmName),
          type: 'success'
        });
        this.intercomService.updateIntercom(farm, this.authService.currentUser);
        this.farms = Array.from(new Set(this.farms)); // uniquify the list of Farms
        this.nonDeletedFarms = Array.from(new Set(this.nonDeletedFarms)); // uniquify the list of non-deleted Farms
      });
    } else {
      this.bottomNotificationsService.currentMessage.next({
        title: this.localeService.constants.stringErrorOccurred,
        type: 'warning',
        message: this.localeService.constants.stringUnableToDeleteLastFarm
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  createNewFarm(): void {
    const modalRef = this.ngbModal.open(FarmCreationComponent, {
      backdrop: 'static',
      windowClass: 'modal-right'
    });

    modalRef.result.then(() => {

    }, () => {});
  }
}

<div class="card card-external card-reset-password">

  <app-loading *ngIf="loading" [local]="true"></app-loading>

  <div class="card-body">

    <!-- logo -->
    <div class="logo"><img src="assets/mihub-logo.svg" class="d-inline"></div>

    <h3 i18n="@@enterYourNewPassword">Enter your new password</h3>

    <form [formGroup]="form" (ngSubmit)="resetPassword($event)">

      <!-- password -->
      <app-form-input [control]="form.get('password')" i18n-label="@@newpassword" label="New Password"
                      [hideAsterisks]="true"
					  i18n-error="@@passwordrequiresatleastsixcharactersandonenumber" error="Password requires at least 6 characters and one number"
					  i18n-errorMinlength="@@passwordrequiresatleastsixcharactersandonenumber" errorMinlength="Password requires at least 6 characters and one number"
					  i18n-errorPattern="@@passwordrequiresatleastsixcharactersandonenumber" errorPattern="Password requires at least 6 characters and one number">
        <input type="password" formControlName="password" class="form-control" autofocus>
      </app-form-input>

      <!-- confirm password -->
      <app-form-input i18n-label="@@confirmpassword" label="Confirm Password"
                      [hideAsterisks]="true"
                      [showError]="form.hasError('passwordMismatch') && form.controls['confirmPassword'].dirty"
                      i18n-errorPasswordMismatch="@@passwordsdonotmatch" errorPasswordMismatch="Passwords do not match">
        <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{'app-invalid': form.hasError('passwordMismatch')}">
      </app-form-input>

      <div class="form-footer text-right">
        <button type="submit" class="btn btn-primary ml-auto" i18n="@@resetpassword">Reset Password</button>

        <!-- show all errors -->
        <app-form-errors-sum [errors]="errorMessage"></app-form-errors-sum>

      </div>

    </form>
  </div>
</div>



<div class="modal-header" >
  <h3 i18n="@@targetweighttemplates">TARGET WEIGHT TEMPLATES</h3>
  <app-new-group-line [group]="group" [templates]="templates" [step]="step" ></app-new-group-line>
  <a (click)="prevStepOrCancel.next('cancel')" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body" >

  <div class="row w100p">

    <!-- form -->
    <div class="col-md-12 col-lg-5 groups-target-scrollable-points">
      <div class="box">

        <div class="row no-gutters small-gutters flex-align-items-center" *ngIf="loadedDateData && graphControls && graphControls.length > 0">
          <div class="col-3">
            <h4 i18n="@@starting">Starting</h4>
          </div>
          <div class="col-5">
            <app-month-picker width="100%" [endDate]="graphControls[graphControls.length - 1].date" [displayDate]="graphControls[0].date"
                              [ignoreEndDate]="true" (dateChange)="startDateChanged($event);" ></app-month-picker>
          </div>
          <div class="col-2">
            <h4 i18n="@@weight">Weight</h4>
          </div>
          <div class="col-2">
            <input class="form-control" type="number" min="0" step="5" #firstWeightSelect [class.dirtyInput]="startWeightDirty"
                   (change)="startWeightChanged(firstWeightSelect.value); startWeightDirty = true;" [value]="graphControls[0].weight.value" />
          </div>
        </div>

        <div class="row no-gutters small-gutters flex-align-items-center mt-1" *ngIf="loadedDateData && graphControls && graphControls.length > 0">
          <div class="col-3 pl5">
            <h4 i18n="@@finishing">Finishing</h4>
          </div>
          <div class="col-5">
            <app-month-picker width="100%" [startDate]="graphControls[0].date" [minDate]="graphControls[0].date" [displayDate]="graphControls[graphControls.length - 1].date"
                              (dateChange)="endDateChanged($event);" ></app-month-picker>
          </div>
          <div class="col-2">
            <h4 i18n="@@weight">Weight</h4>
          </div>
          <div class="col-2">
            <input class="form-control" type="number" min="0" step="5" #lastWeightSelect [class.dirtyInput]="endWeightDirty"
                   (change)="endWeightChanged(lastWeightSelect.value); endWeightDirty = true;" [value]="graphControls[graphControls.length - 1].weight.value" />
          </div>
        </div>
      </div>

      <div class="mt-5 mb-3">
        <h2 class="mb-0" i18n="@@customise">Customise</h2>
        <ng-container i18n="@@customisegrowthratesforeachmonth">Customise growth rates for each month.</ng-container>
      </div>

      <div class="row">
        <div class="col-10">
          <div class="row no-gutters small-gutters">
            <div class="col-5">
              <h4 class="mb-0" i18n="@@month">Month</h4>
            </div>
            <div class="col-4">
              <h4 class="mb-0" i18n="@@weight">Weight</h4>
            </div>
            <div class="col-3">
              <h4 class="mb-0" i18n="acronym for Average Daily Gain@@adg">ADG</h4>
            </div>
          </div>
        </div>
        <div class="col-2">
          <app-month-picker-popup [curDates]="curDates" width="100%" (dateChange)="newDate = $event; addRow();" ></app-month-picker-popup>
        </div>
      </div>

      <form novalidate="novalidate" class="row" [@flyInOut]="contr.animation" *ngFor="let contr of graphControls; let i = index">
        <div class="col-10">
          <div class="row no-gutters small-gutters mb-2">
            <div class="col-5">
              <input [readonly]=true width="100%" class="form-control inviolable" value="{{dayMonthYearString(contr.date)}}"/>
            </div>
            <div class="col-4">
              <input *ngIf="i != 0 && i != graphControls.length - 1" class="form-control" (change)="rowWeightUpdated()" type="number" min="0"
                     step="5" [formControl]="contr.weight"/>
              <input [readonly]=true *ngIf="i == 0 || i == graphControls.length - 1" class="form-control inviolable" (change)="rowWeightUpdated()"
                     type="number" min="0" step="5" [formControl]="contr.weight"/>
            </div>
            <div class="col-3">
              <input *ngIf="contr.adg !== null" class="form-control group-target-adg" (change)="adgChanged(i)" type="number" min="-5" step="0.1000"
                     [formControl]="contr.adg"/>
            </div>
          </div>
        </div>
        <div class="col-2">
          <i *ngIf="i != 0 && i != graphControls.length - 1" class="fa fa-2x fa-minus-circle pt8 pull-right" (click)="deleteRowClicked(i);"
             [style.color]='contr.buttonColor' style="cursor: pointer; color: #EE3124; line-height: 100%;"></i>
        </div>
      </form>


      <div>
        <div class="alert dark alert-danger alert-sm" *ngIf="!sufficientPoints">
          <i class="fa fa-exclamation-circle "></i>
          <ng-container i18n="@@twpmusthavetwodates">Target weight plan must have at least two dates and weights</ng-container>
        </div>
        <div class="alert dark alert-danger alert-sm" *ngIf="hasClashes && sufficientPoints">
          <i class="fa fa-exclamation-circle "></i>
          <ng-container i18n="@@twpcanthavetwomonthssame">Target weight plan can not have two months the same</ng-container>
        </div>
        <div class="alert dark alert-danger alert-sm" *ngIf="!hasClashes && hasZeros && sufficientPoints">
          <i class="fa fa-exclamation-circle "></i>
          <ng-container i18n="@@twpcanthavezeroweightduring">Target weight plan cannot have a zero weight during the plan</ng-container>
        </div>
      </div>

    </div>

    <!-- graph -->
    <div class="col-md-12 col-lg-7">
      <div [appHighcharts]="options" class="chart"></div>
    </div>

  </div>

</div>

<div class="modal-footer" >
  <a href="javascript:void(0)" class="btn btn-link" (click)="prevStepOrCancel.next('cancel');" i18n="@@cancel">Cancel</a>
  <button class="btn btn-secondary ml-1" (click)="prevStepOrCancel.next('prev')" i18n="@@back">Back</button>
  <button class="btn btn-primary ml-1" (click)="save()">
    {{group.groupId ? localeService.constants.stringUpdate : localeService.constants.stringCreate}}
  </button>
</div>

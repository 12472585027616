import {Component, OnInit, TemplateRef} from '@angular/core';

import { BottomNotificationsService } from './bottom-notifications.service';

@Component({
  selector: 'app-bottom-notifications',
  templateUrl: './bottom-notifications.component.html',
  styleUrls: ['./bottom-notifications.component.scss'],
  providers: []
})
export class BottomNotificationsComponent implements OnInit {
  messageLog: Array<NotificationMessage> = [];


  constructor(private bottomNotificationsService: BottomNotificationsService) { }

  ngOnInit() {
    this.bottomNotificationsService.currentMessage.subscribe(msg => {
      if (msg) {
        this.messageLog.push(msg);
        if (msg.type === 'error') {
          msg.pinned = true;
          return;
        }

        setTimeout(() => {
          if (!msg.pinned) {
            this.closeMsg(msg);
          }
        }, msg.duration ?  msg.duration : msg.type === 'warning' ? 5000 : 3000); // Time how long the notification shows up for
      }
    });
  }

  closeMsg(msg: NotificationMessage) {
    msg.closing = true;
    setTimeout(() => {
      let index = this.messageLog.findIndex(i => i === msg);
      if (index >= 0) {
        this.messageLog.splice(index, 1);
      }
    }, 500); // Dont touch this timout unless changing animation
  }

}

export class NotificationMessage {
  title?: string;
  message?: string;
  titleTemplate?: TemplateRef<any>;
  messageTemplate?: TemplateRef<any>;

  // type is defined by bootstrap class. defaults to success. Other option is warning for errors
  type?: 'success' | 'error' | 'warning' | 'exclamation' | 'failure' = 'success';

  pinned ? = false;
  closing ? = false;

  duration?: number;
}

import { UserSubscription } from './user-subscription.model';
import { UserFarm } from './user-farm';
export class User {
    userId: string;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    postcode: number;
    suburb: string;
    phoneNumber: string;
    country: string;
    city: string;
    email: string;
    currentFarm_FarmId: number;
    userHash: string;
    created: string;
    region: string;
    userFarms: Array<UserFarm>;
    userType: string;
    subscriptions: Array<UserSubscription>;
    source = 'Website';
}

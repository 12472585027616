import { AuthService } from '../../auth/auth.service';
import { WeightPlan } from '../models/weight-plan.model';





import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class WeightPlanService {
    constructor(private authService: AuthService, private apiService: ApiService) { }

    public UpdateWeightPlan = (weightPlan: WeightPlan): Observable<WeightPlan> => {
        return this.apiService.patch(`/odata/TargetWeightPlans(${weightPlan.targetWeightPlanId})`, weightPlan);
    }
}

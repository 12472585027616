<div class="modal-header">
  <h3>Enter Precision Pastoral account details</h3>
  <a (click)="ngbActiveModal.dismiss()" class="close"><i class="fas fa-times"></i></a>
</div>

<div class="modal-body" *ngIf="loading">
  <app-loading [local]="true"></app-loading>
</div>

<form *ngIf="form && !loading" [formGroup]="form" class="modal-form-with-footer" (ngSubmit)="sendPpl($event)">
  <div class="modal-body">

    <app-form-input [control]="login" i18n-error="@@emailerror" error="A valid email is required">
      <input type="text" autocomplete="true" autofocus name="login" placeholder="Precision Pastoral Username" formControlName="login" class="form-control">
    </app-form-input>

    <app-form-input [control]="password" i18n-error="@@passworderror" error="Password required">
      <input type="password" autocomplete="true" name="password" i18n-placeholder="@@password" placeholder="Precision Pastoral Password" formControlName="password" class="form-control">
    </app-form-input>

  </div>

  <div class="modal-footer">
    <a class="btn btn-link" (click)="ngbActiveModal.dismiss()" i18n="cancel">Cancel</a>
    <button class="btn btn-primary ml-1">Connect</button>
  </div>

</form>

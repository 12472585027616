<app-loading *ngIf="!transfers"></app-loading>

<div class="row mb-3 flex-align-items-center">

  <div class="col-md-6">
    <div class="custom-control custom-checkbox">
      <input [(ngModel)]="ignoreSuccessfulTransfers" (change)="currentPage=1; getTransfersForDates();" id="checkbox1" type="checkbox" class="custom-control-input">
      <label for="checkbox1" class="custom-control-label">Ignore successful transfers</label>
    </div>
  </div>

  <div class="col-md-3">
    <input class="form-control form-control-sm w100p" type="date" name="" (input)="currentPage=1; startDate=parseDate($event.target.value); getTransfersForDates();"
           [value]="startDate | date: 'yyyy-MM-dd'" [max]="endDate | date: 'yyyy-MM-dd'">
  </div>

  <div class="col-md-3">
    <input class="form-control form-control-sm w100p" type="date" name="" (input)="currentPage=1; endDate=parseDate($event.target.value); getTransfersForDates();"
           [value]="endDate | date: 'yyyy-MM-dd'" [min]="startDate | date: 'yyyy-MM-dd'">
  </div>

</div>

<ng-container *ngIf="transfers">

  <div class="mb-3">
    <input type="text" pInputText size="50" style="max-width: 250px;" class="form-control pull-left" placeholder="Quick search"
           (input)="dataTable.filterGlobal($event.target.value, 'contains')">
  </div>

  <div class="card">

    <!-- new table -->
    <p-table [value]="transfers" rowHover="true" class="no-overflow" #dataTable dataKey="animalMovementRequestId"
             [globalFilterFields]="['integrationUserName', 'clientKey', 'linkedClientKey', 'transactionName', 'userName']" tableStyleClass="table table-hover">

      <!-- header -->
      <ng-template pTemplate="header">
        <tr class="ui-widget-content">
          <th class="ui-state-default" style="width: 3em"></th>
          <th class="ui-state-default">User Name</th>
          <th class="ui-state-default">Client Key</th>
          <th class="ui-state-default">Linked Client Key</th>
          <th class="ui-state-default">Movement Type</th>
          <th class="ui-state-default">Communication Success</th>
          <th class="ui-state-default">Transfer Success</th>
          <th class="ui-state-default">MiHub Livestock User</th>
          <th class="ui-state-default">Date</th>
        </tr>
      </ng-template>

      <!-- rows body -->
      <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr class="ui-widget-content" [pRowToggler]="rowData">
          <td style="width: 3em">
            <a href="javascript:void(0)">
              <i class="fa" [ngClass]="expanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            </a>
          </td>
          <td>{{ rowData.integrationUserName }}</td>
          <td>{{ rowData.clientKey }}</td>
          <td>{{ rowData.linkedClientKey }}</td>
          <td>{{ rowData.transactionName }}</td>
          <td>{{ rowData.communicationSuccess ? 'Yes' : 'No' }}</td>
          <td>{{ rowData.transferSuccess ? 'Yes' : 'No' }}</td>
          <td>
            <div class="ui-cell-data">
              <a [routerLink]="['/main/admin/users']" [queryParams]="{ userId: rowData.userId }">{{ rowData.userName }}</a>
            </div>
          </td>
          <td>
            {{ rowData.timeStamp | date:'d MMM yyyy h:mm aa' }}
          </td>
        </tr>
      </ng-template>

      <!-- row expansion -->
      <ng-template pTemplate="rowexpansion" let-rowData>
        <tr class="ui-expanded-row-content">
          <td [attr.colspan]="9">
            <div class="httpData">
              <h4>Request</h4>
              <p class="text-wrap">{{ rowData.requestPayload }}</p>
            </div>
            <div class="httpData">
              <h4>Response</h4>
              <p class="text-wrap">{{ rowData.responsePayload }}</p>
            </div>
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>

  <div class="p-3">
    <div class="text-center">
      <button *ngIf="showLoadMoreButton" class="btn btn-primary" (click)="getNextBatch()">Load More</button>
    </div>
  </div>

</ng-container>

﻿import {LocaleService} from '../../helpers/locale.service';
import {AuthService} from '../auth.service';
import {LocalStoreManager} from '../../helpers/local-store-manager.service';
import {Component} from '@angular/core';
import {OnInit, AfterContentInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {GaService} from '../../gaService';
import {ApiService} from '../../main/api/api.service';
import {BottomNotificationsService} from '../../components/bottom-notifications/bottom-notifications.service';
import {FormValidation} from '../../helpers/form-validation.helper';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit, AfterContentInit {
  form: FormGroup;
  redirectUrl: string;
  loginError = '';
  loading: boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private localeService: LocaleService,
    private gaService: GaService,
    private localStoreManager: LocalStoreManager,
    private apiService: ApiService,
    private bottomNotificationsService: BottomNotificationsService
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle(`${this.localeService.constants.stringLogin} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
    this.createLoginForm();
  }

  ngAfterContentInit() {
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
    this.form.controls['email'].setValue(this.route.snapshot.queryParams['username']);
  }

  createLoginForm() {
    this.form = new FormGroup({
      email: new FormControl(this.route.snapshot.queryParams['username'] || '', [Validators.required, FormValidation.validateEmail]),
      password: new FormControl('', Validators.required)
    });

    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  signOn(event) {
    if (!this.localStoreManager.localStorageAvaliable) {
      this.loginError = this.localeService.constants.stringLocalStorageUnAvaliable;
      return;
    }

    // mark form touched and dirty
    this.form.markAllAsTouched();
    Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsDirty());

    if (this.form.valid) {
      this.loading = true;
      this.authService.login(this.form.get('email').value, this.form.get('password').value).subscribe(res => {
        this.loading = false;
        if (res) {
          this.form = null;
          setTimeout(() => { // make sure BS is set
            this.gaService.logEvent('Login', this.router.url);
            this.authService.identifyToSmartlook();
            this.apiService.post('/api/UserActivity',
              {
                category: 2,
                timeStamp: new Date(),
                eventName: 'Login',
                pageName: this.router.url,
                farm_FarmId: null,
                userId: null,
                userGenerated: true
              }, 'text'
            ).subscribe(() => {
            }, err => {
            });
          });
          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
          } else {
            this.router.navigate(['/main']);
          }
        } else {

          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUsernameOrPasswordIsIncorrect,
            message: '',
            type: 'failure'
          });

          this.loginError = this.localeService.constants.stringUsernameOrPasswordIsIncorrect;
        }
      }, err => {
        this.loading = false;

        this.bottomNotificationsService.currentMessage.next({
          title: this.localeService.constants.stringUsernameOrPasswordIsIncorrect,
          message: '',
          type: 'failure'
        });

        this.loginError = this.localeService.constants.stringUsernameOrPasswordIsIncorrect;
      });
    } else {
      if (this.form.get('email').invalid) {
        this.loginError = this.localeService.constants.stringPleaseEnterAValidEmail;
      } else {
        this.loginError = this.localeService.constants.stringPleaseEnterAValidPassword;
      }
    }
    event.preventDefault();
  }

  formChanged() {
    this.loginError = '';
  }
}

import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit, AfterViewInit, OnDestroy {
  imgLocations = environment.imgLocations;
  window = window;

  constructor(
    public location: Location
  ) { }

  ngOnInit() {
    $('body').addClass('bg-dark-grey external-page pbn onload-check sb-l-c sb-r-c');
  }

  ngAfterViewInit() {
    let script = document.createElement('script');
    script.type = `text/javascript`;
    script.src = `https://consent.cookiebot.com/867fcff6-4f08-414d-a6d9-14986f200859/cd.js`;
    script.id = 'CookieDeclaration';
    script.async = true;

    document.getElementById('cookiePolicyHolder').appendChild(script);
  }

  ngOnDestroy() {
    $('body').removeClass();
  }

}

import {MainService} from '../../main.service';
import {SubscriptionService} from '../../api/subscription.service';
import {DeviceService} from '../../api/device.service';
import {AuthService} from '../../../auth/auth.service';
import {deviceList, DeviceType} from '../../models';
import {BottomNotificationsService} from '../../../components/bottom-notifications/bottom-notifications.service';
import {Component, OnInit, Input} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {isNumeric} from 'rxjs/internal-compatibility';
import {LocaleService} from '../../../helpers/locale.service';
import {GaService} from '../../../gaService';
import {PermissionsService} from '../../../auth/permissions.service';
import {ApiService} from '../../api/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
  providers: [DeviceService]
})
export class AddDeviceComponent implements OnInit {
  @Input() subscriptionId;

  deviceList = deviceList;
  form: FormGroup;
  errorCode = '';
  show = false;
  buttonEnabled = true;

  constructor(private bottomNotificationsService: BottomNotificationsService, private subscriptionService: SubscriptionService, private gaService: GaService
    , private deviceService: DeviceService, private authService: AuthService, private mainService: MainService, private localeService: LocaleService
    , private permissionsService: PermissionsService, private apiService: ApiService, private ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit() {
    const currentDeviceType = deviceList[deviceList.findIndex(e => e.id === 1)];
    this.form = new FormGroup({
      serialNumber: new FormControl('', Validators.compose([Validators.required])),
      currentDeviceType: new FormControl(currentDeviceType)
    });

    this.form.valueChanges.subscribe(() => this.formChanged());
  }

  formChanged() {
    this.errorCode = '';
  }

  get formCurrentDeviceType(): AbstractControl {
    return this.form.get('currentDeviceType');
  }

  submitSerialNumber() {
    let maxL = this.formCurrentDeviceType.value.maxLength;
    let minL = this.formCurrentDeviceType.value.minLength;
    let minVal = this.formCurrentDeviceType.value.minVal;
    let maxVal = this.formCurrentDeviceType.value.maxVal;
    let dName = this.formCurrentDeviceType.value.label; // only used for S3 checking currently, as its serial number has leading zeros to pad it to 12 characters
    let serial = this.form.controls['serialNumber'].value;
    if (serial.length > maxL
      || (dName !== 'S3' && serial.length < minL) // S3 we don't check minimum length as we can pad it later
      || !isNumeric(serial)
      || (Number(serial) <= minVal && minVal !== 0)
      || (Number(serial) >= maxVal && maxVal !== 0)
    ) {
      this.errorCode = 'invalidSerial';
      this.buttonEnabled = true;
    } else {
      this.errorCode = '';

      if (dName === 'S3') {
        serial = (String('0').repeat(maxL) + serial).substr((maxL * -1), maxL);
      }

      this.subscriptionService.updateSubscriptionType(this.subscriptionId, 2).subscribe(res => {
        if (res) {
          this.authService.refreshUser(this.authService.currentUser.userId).subscribe();
          this.mainService.updateFarms();
          this.deviceService.AddDevice(serial, this.formCurrentDeviceType.value.label).subscribe(res2 => {
            if (res2) {
              this.bottomNotificationsService.currentMessage.next({
                title: this.localeService.constants.stringDeviceSuccessfullyAdded,
                message: (<string>this.localeService.constants.stringDeviceHasBeenSuccessfullyAddedAndYourSubscriptionUpdated)
                  .replace('<deviceModal/>', this.formCurrentDeviceType.value.label.toUpperCase()),
                type: 'success'
              });
              this.closeDialog();
            } else {
              this.bottomNotificationsService.currentMessage.next({
                title: this.localeService.constants.stringSubscriptionUpdated,
                message: this.localeService.constants.stringYourSubscriptionHasBeenSuccessfullyUpdated,
                type: 'success'
              });
              this.closeDialog();
            }
            this.mainService.changeFarm(this.authService.currentUser.currentFarm_FarmId, null, true); // quite a few parts are listening to farm change event. trigger them.
          });

          setTimeout(() => { // process file first. then do ga
            this.gaService.logEvent('Subscription Changed', '/main/subscriptions', null, 'Trial to Standard using Serial');
            this.apiService.post('/api/UserActivity',
              {
                category: 2,
                timeStamp: new Date(),
                eventName: 'Subscription Changed',
                pageName: '/main/subscriptions',
                farm_FarmId: this.mainService.farmChanged.value ? this.mainService.farmChanged.value.farmId : null,
                userId: this.authService.currentUserChanged.value.userId,
                userGenerated: true
              }, 'text'
            ).subscribe(res3 => {
            }, err => {
            });
          });
        } else {
          this.bottomNotificationsService.currentMessage.next({
            title: this.localeService.constants.stringUpdatedError,
            message: this.localeService.constants.stringUnableToUpdateYourSubscriptionAtThisTimePleaseTryAgainLaterTheErrorHasBeenLogged,
            type: 'failure'
          });
          this.closeDialog();
        }
        this.mainService.changeFarm(this.authService.currentUser.currentFarm_FarmId); // quite a few parts are listening to farm change event. trigger them.
      });
    }
  }

  closeDialog() {
    this.ngbActiveModal.dismiss();
    this.form.controls['serialNumber'].setValue('');
  }
}

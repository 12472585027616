import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {MainService} from '../main.service';
import {FarmMenuModalComponent} from './farm-menu-modal/farm-menu-modal.component';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-farm-menu',
  templateUrl: './farm-menu.component.html',
  styleUrls: ['./farm-menu.component.scss']
})
export class FarmMenuComponent implements OnInit {
  ngbModalRef: NgbModalRef;

  constructor(public mainService: MainService,
              public authService: AuthService,
              private ngbModal: NgbModal) {
  }

  ngOnInit(): void {

  }

  openFarmMenu(): void {
    if (this.ngbModalRef) {
      this.ngbModalRef.dismiss();
      return;
    }

    this.mainService.mainCustomCssClasses = ['modal-secondary-menu-open'];

      this.ngbModal.dismissAll();

      this.ngbModalRef = this.ngbModal.open(FarmMenuModalComponent, {
        size: 'lg',
        // backdrop: 'static',
        windowClass: 'modal-secondary-menu'
      });

      this.ngbModalRef.result.then(() => {
        this.mainService.mainCustomCssClasses = [];
        this.ngbModalRef = null;
      }, () => {
        this.mainService.mainCustomCssClasses = [];
        this.ngbModalRef = null;
      });
  }
}

export class GroupCluster {
    groupId: number = null;
    groupName: string = null;
    sessionForClusters: Array<SessionForCluster> = null;
    scoreDetails: ScoreDetails;
    fakeGroup = false;
    confident: boolean;
}

export class SessionForCluster {
    id: number;
    sessionId: number;
    sessionName: string;
    sessionStartDate: Date;
    recordCount: number;
    groupId: number;
    idedAnimalPercent: number;
}

export class ScoreDetails {
    avgInnerGroupDistance: number;
    outerGroupDistance: number;
    withInGroupSessionAvgWeightScore: number;
}

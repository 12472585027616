import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {SessionSummary} from '../../models';
import {ApiService} from '../../api/api.service';
import { SessionAction } from '../../models/session-action';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {

  constructor(private apiService: ApiService) {
  }

  // get all session summaries
  getSessionsSummaries(): Observable<SessionSummary[]> {
    return this.apiService.postType<SessionSummary>(SessionSummary,`/odata/Sessions/Services.SessionsSummaries()`, { sessionIds: [], groupIds: []})
    .pipe(
      //map((res: any) => res.value as SessionSummary[]),
      map(sessions => {
        return sessions.sort((a, b) => new Date(b.sessionStartDate).getTime() - new Date(a.sessionStartDate).getTime());
      }), map((sessions:SessionSummary[])=>{
        
        return sessions.map(s=>{
          s.actions= (s.actions || []).map(a=>Object.assign(new SessionAction(),a))
          return s;
        });
      })
    );
  }

  // get session summary by ID (sessionId)
  getSessionSummary(id: number): Observable<SessionSummary> {
    return this.getSessionsSummaries().pipe(
      map(sessionSummaries => sessionSummaries.find(ss => ss.sessionId === id))
    );
  }
}

export class Permissions {
    farmUsersView = false;
    farmUsersInvite = false;
    farmDeleteAll = false;
    farmDetailsEdit = false;
    farmUsersRemove = false;
    sessionsDelete = false;
    canAccessPpl = false;
    canEditData = false;
    groupViewOnly = true;
}

import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

import { SessionAction } from '../../../models/session-action';

@Component({
  selector: 'app-session-group-assignments',
  templateUrl: './session-group-assignments.component.html',
  styleUrls: ['./session-group-assignments.component.scss']
})
export class SessionGroupAssignmentsComponent implements OnInit {
  @Input() sessionActions: Array<SessionAction> = new Array<SessionAction>();
  @Input() preAllocatedGroups: Array<SessionAction> = new Array<SessionAction>();

  @Output() assignmentDone: EventEmitter<SessionAction> = new EventEmitter<SessionAction>();

  constructor() { }

  ngOnInit() {
  }

  emitAssignmentDone(event): void {
    this.assignmentDone.emit(event);
  }
}

import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {AuthService} from '../../../auth/auth.service';
import {MainService} from '../../main.service';
import {ApiService} from '../../api/api.service';
import {FarmCreationComponent} from '../../manage/farm-creation/farm-creation.component';
import {DemoFarmCreationComponent} from '../../manage/demo-farm-creation/demo-farm-creation.component';
import {Farm} from '../../models';
import { Subscription } from 'rxjs';
import {mobileBreakpoint} from '../../../helpers/constants';

@Component({
  templateUrl: './farm-menu-modal.component.html',
  styleUrls: ['./farm-menu-modal.component.scss']
})
export class FarmMenuModalComponent implements OnInit, OnDestroy {
  farmsFilter: string;
  farmLengthLimit = 5;
  farmHeight = 66;

  showSettingsAndUsers = true;
  private farmChangeSub: Subscription = null;

  constructor(public authService: AuthService,
              public ngbActiveModal: NgbActiveModal,
              public mainService: MainService,
              private apiService: ApiService,
              private router: Router,
              private ngbModal: NgbModal) {
  }

  changeFarm(newFarm: any) {
    this.ngbActiveModal.dismiss();
    this.mainService.changeFarm(newFarm.farmId);
    if (window.innerWidth < mobileBreakpoint) {
      this.mainService.leftMenuOpen.emit({
        forced: true,
        value: false
      });
    }
  }

  createNewFarm(): void {
    this.ngbActiveModal.dismiss();
    const modalRef = this.ngbModal.open(FarmCreationComponent, {
      backdrop: 'static',
      windowClass: 'modal-right'
    });

    modalRef.result.then(() => {
      this.ngbActiveModal.close();
    }, () => {});
  }

  createNewDemoFarm(): void {
    this.ngbActiveModal.dismiss();
    const modalRef = this.ngbModal.open(DemoFarmCreationComponent, {
      backdrop: 'static',
      windowClass: 'modal-right'
    });

    modalRef.result.then(() => {
      this.ngbActiveModal.close();
    }, () => {});
  }

  getFilteredFarms(): Farm[] {
    return this.farmsFilter ?
      this.mainService.farmsChanged.value.filter(farm => farm.farmName.toLowerCase().indexOf(this.farmsFilter.toLowerCase()) > -1
        || farm.farmId.toString().indexOf(this.farmsFilter.toLowerCase()) > -1) :
      this.mainService.farmsChanged.value;
  }

  ngOnInit() {
    this.farmChangeSub = this.mainService.farmChanged.subscribe(farm => {
      if (farm && this.authService.currentUser) {
        if (this.authService.currentUser.userFarms.find(uf => uf.farmId === farm.farmId).farmRoleId > 2) { // GV etc
          this.showSettingsAndUsers = false;
        } else {
          this.showSettingsAndUsers = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.farmChangeSub) {
      this.farmChangeSub.unsubscribe();
    }
  }
}

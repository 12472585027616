import {SessionService} from '../../api/session.service';
import {Rounder} from '../../../helpers/rounder.helper';
import {RowHeader} from '../../models/report-header.model';
import {GroupsService} from '../../services/groups.service';
import {WeightRecordService} from '../../api/weight-record.service';
import {AnimalService} from '../../api/animal.service';
import {MainService} from '../../main.service';
import {Group, GroupCensusWeightSummary} from '../../models/group.model';
import {combineLatest, Subscription, of, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ReportsService} from '../../reports/reports.service';
import {GroupDetailsWeightGain} from './group-details-weight-gain';
import {Component, OnDestroy, OnInit, ViewChildren, NgZone, ChangeDetectorRef, Renderer2, ViewChild, AfterViewInit, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {GlobalParameterService} from '../../../helpers/global-parameter.service';
import {WeightRecord} from '../../models/weight-record.model';
import {LocaleService} from '../../../helpers/locale.service';
import {FloatingHeaderHelper} from '../../../helpers/floating-header.helper';
import {DetailsSearchParamType} from '../../models/details-search-params';
import {AuthService} from '../../../auth/auth.service';
import {PermissionsService} from '../../../auth/permissions.service';
import {IntegrationService} from '../../api/integration.service';
import {Integration, IntegratedDevice} from '../../models/integration';
import {ScriptService} from '../../script.service';


import {ApiService} from '../../api/api.service';


import {CreateNewGroupService} from '../../create-new-group/create-new-group.service';
import {AnimalSummary} from '../../models/animal-adg.model';
import {CustomAnimalIdentifier, AnimalStatusEnum, Animal} from '../../models';
import {ActionsSoldDeadService} from '../../actions/actions-sold-dead-modal/actions-sold-dead.service';
import {SessionAction} from '../../models/session-action';
import {AnimalInfoComponent, AnimalInfoParentViewEnum} from '../../animals/animal-info/animal-info.component';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CreateDraftComponent} from '../create-draft/create-draft.component';
import {Location} from '@angular/common';
import {GroupService} from '../../api/group.service';

import {ActionsAssignFilteredListModalService} from '../../actions/actions-assign-filtered-list-modal/actions-assign-filtered-list-modal.service';
import { DatatypeHelper, ConvertToUTC } from '../../../helpers/datatype.helper';
import * as moment from 'moment';


declare let $: any;

@Component({
  selector: 'app-groups-details',
  templateUrl: './groups-details.component.html',
  styleUrls: ['./groups-details.component.scss'],
  providers: [WeightRecordService]
})
export class GroupsDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() group: Group;

  @ViewChild('tt', { static: false })
  dataTable: any;
  @ViewChild('floatHeaders', { static: false })
  floatHeaders: any;
  window = window;

  integration: Integration = null;
  curIntegratedDevice: IntegratedDevice = null;
  isOnlyAnimalsFromSamePaddock = false;

  filteredCensus: GroupCensusWeightSummary;
  scrollSet = false;

  curCensus: GroupCensusWeightSummary;
  // targetCensus: GroupCensusWeightSummary; // Actual adg cenus
  targetCensuses: Array<GroupCensusWeightSummary>;
  headers: Array<RowHeader>;
  curHeaders: Array<RowHeader>;
  data: Array<any>;
  fullDataSet: Array<any>; // this one is for filtering options. Fix for 2076
  dataForPpl: Array<number>;
  graphsLoaded = false;
  public stacked = false;
  filterOptions = []; // top filter items (weight, adg, ect..);
  private subs: Array<Subscription> = [];

  private allWrc: Array<AnimalSummary> = [];

  filteredAnimals: Array<any> = [];

  gettingGraphs = false;

  sortDetails: any = null;
  sortField: string = null;
  sortOrder: any = null;

  private weightsSub: Subscription;
  private weightDataSub: { [key: number]: any } = null;
  awgs: any;
  isMobile: boolean;

  isPplFarmWithIntegration = false;

  constructor(
    private mainService: MainService,
    public reportsService:
      ReportsService,
    private renderer: Renderer2,
    private authService: AuthService,
    private groupsService: GroupsService,
    private weightRecordService: WeightRecordService,
    private animalService: AnimalService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private localeService: LocaleService,
    title: Title,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    public globalParameterService: GlobalParameterService,
    public permissionsService: PermissionsService,
    private integrationService: IntegrationService,
    private scriptService: ScriptService,
    public createNewGroupService: CreateNewGroupService,
    private actionsSoldDeadService: ActionsSoldDeadService,
    public apiService: ApiService,
    private ngbModal: NgbModal,
    private location: Location,
    private actionsAssignFilteredListModalService: ActionsAssignFilteredListModalService
  ) {
    title.setTitle(`${this.localeService.constants.stringGroupDetails} | ${this.localeService.constants.stringTruTestMiHubLivestockManagement}`);
    this.scriptService.load('alasql', 'jszip', 'jspdf').then(data => {
      this.scriptService.load('jspdfAutotable').then(laterData => {
      });
    });
  }

  ngOnInit() {
    if (this.integrationService.integration.value) {
      this.integration = this.integrationService.integration.value;
      if (this.permissionsService.permissions.canAccessPpl) {
        // this is based off both PPL & Integration everywhere else,
        // but here we can save repeating the check
          this.isPplFarmWithIntegration = true;
      }
    }

    const sub = combineLatest([
      this.sessionService.sessions, this.integrationService.integration
    ]).subscribe(res => {
      if (res[0] !== null && res[0] !== undefined) {
        this.graphsLoaded = false;
        this.groupChanged();
      } else {
        this.curHeaders = null;
        this.data = null;
        this.dataForPpl = null;
        this.curCensus = null;
      }
    });

    const sub2 = this.groupsService.groups.subscribe(() => {
      const group = this.groupsService.groups.value.find(g => g.groupId === this.group.groupId);

      if (group) {
        this.group = group;
        this.groupChanged();
      }
    });

    this.subs.push(sub);
    this.subs.push(sub2);

    this.weightsSub = this.getAnimalSummary().subscribe(awgs => {
      this.awgs = awgs;
      this.processData();
    });

    this.subs.push(this.globalParameterService.detailsSearchParams.subscribe(search => {
      if (this.group && this.allWrc && this.allWrc.length > 0) {
        this.graphsLoaded = false;
        this.processData();
      }
    }));

    setTimeout(() => {
      $(window).on('scroll');
      $(window).scroll(event => {
        this.scrollSet = FloatingHeaderHelper.floatHeaderOnScroll(this.scrollSet, this.renderer);
        FloatingHeaderHelper.updateColumnWidths(this.renderer);
      });
      FloatingHeaderHelper.CleanUpTable(this.renderer);
    });

    this.isMobile = this.mainService.isMobile();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
    if (this.weightsSub) {
      this.weightsSub.unsubscribe();
    }
    this.globalParameterService.detailsSearchParams.next({});
  }

  groupChanged() {
    this.data = null;
    this.dataForPpl = null;
    if (this.integration && this.integration.integratedDevices) {
      this.curIntegratedDevice = this.integration.integratedDevices.find(id => id.group_GroupId === this.group.groupId);
    }

    if (this.group.censuses.length > 0) {
      this.curCensus = this.group.censuses.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime())[0];
      this.targetCensuses = this.group.censuses.filter(c => new Date(c.timeStamp).getTime() < new Date(this.curCensus.timeStamp).getTime());

      const sub = combineLatest([
        this.groupsService.getAnimalSummary(this.curCensus.group_GroupId),
        this.weightRecordService.getWeightsWithNoAnimalIds(this.curCensus.group_GroupId, this.curCensus.timeStamp)
      ]).subscribe(summariesAndWeights => {
        if (summariesAndWeights !== null && summariesAndWeights !== undefined && summariesAndWeights.length > 1) {
          let summaries = summariesAndWeights[0];
          let summaryNoAnimalIds = summariesAndWeights[1];
          if (summaries || summaryNoAnimalIds) {
            this.allWrc = summaries.slice().concat(summaryNoAnimalIds.slice());
            this.processData();
          }
        }
      });

      this.subs.push(sub);

      this.processData();
    } else {
      this.curCensus = null;
      this.targetCensuses = [];
      this.headers = [];
      this.data = [];
      this.dataForPpl = [];
    }

    if (this.sortDetails) {
      this.reportsService.sortData(this.sortDetails, this.data);
      this.sortField = this.sortDetails.field;
      this.sortOrder = this.sortDetails.order;
    }
  }

  processData() {
    this.dataForPpl = [];
    this.fullDataSet = [];
    const search = this.globalParameterService.detailsSearchParams.value,
      params = Object.keys(search);
    this.filteredCensus = null;
    if (params && params.length > 0) {
      this.filteredCensus = new GroupCensusWeightSummary();
    }
    if (!this.curCensus) {
      this.curCensus = <any>{};
    }
    this.curCensus.animalSummaries = this.allWrc.slice();
    let totalWeight = 0, animalWithWeightCount = 0;

    let constHeaders: Array<RowHeader> = [
      { header: this.localeService.constants.stringWeightGainSinceLastSeen, field: 'weightGainLastSeenInternal', type: DetailsSearchParamType.number },
      { header: this.localeService.constants.stringWeightGainSinceInGroup, field: 'weightGainFirstSeenInternal', type: DetailsSearchParamType.number },
      { header: this.localeService.constants.stringADGSinceInGroup, field: 'adgFirstSeenInternal', type: DetailsSearchParamType.number },
      { header: this.localeService.constants.stringADGSinceLastSeen, field: 'adgLastSeenInternal', type: DetailsSearchParamType.number },
      { header: this.localeService.constants.stringADGForSessions, field: 'adgGraphInternal' },
      { header: this.localeService.constants.stringLastSeen, field: 'lastSeenInternal', type: DetailsSearchParamType.date },
      { header: this.localeService.constants.stringWeightGain, field: 'wgGraphInternal' },
      { header: this.localeService.constants.stringWeight, field: 'Weight', type: DetailsSearchParamType.number }];
    if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) { // only PPL Farms have this field generated
      constHeaders.push({ header: this.localeService.constants.stringRemoteWowDevice, field: 'remoteWowDevice', type: DetailsSearchParamType.string });
    }
    this.headers = [];
    this.data = [];
    this.curCensus.animalSummaries.forEach(sum => {
      const newWr: any = {};
      newWr['#aid'] = sum.animalId; // This one is not shown and is used for ref only
      newWr['weightRecordId'] = sum.weightRecordId; // also not shown and used for ref only
      if (sum.weight) {
        newWr['Weight'] = Rounder.round(sum.weight);
      }
      const wrFields = <any>JSON.parse(sum.sessionData);
      this.reportsService.extend(newWr, wrFields, this.headers, this.localeService.constants.stringSessionData);

      let isFilteredOut = false // if true hide record
        , primaryIdName = {};
      if (sum.ids) {
        JSON.parse(sum.ids).forEach(cid => {
          let val = cid.Value;
          if (cid.RawValue) {
            val = cid.RawValue;
          }
          newWr[cid.InternalName || cid.Name] = val;
          if (!constHeaders.find(h => h.field === (cid.InternalName || cid.Name))) {
            if (cid.InternalName && !primaryIdName[cid.InternalName]) {
              primaryIdName[cid.InternalName] = cid.Name;
            }
            constHeaders.push(<any>{ header: (primaryIdName[cid.InternalName] || cid.Name), field: (cid.InternalName || cid.Name), isId: true })
          }
        });
      }

      newWr['TotalWeightGained'] = '';
      newWr.weightGainFirstSeenInternal = sum.weightGainFirstSeenInGroup;
      newWr.weightGainLastSeenInternal = sum.weightGainLastSeen;
      newWr.adgFirstSeenInternal = sum.adgFromFirstSeenInGroup;
      newWr.adgLastSeenInternal = sum.adgFromLastSeen;
      if (sum.lifeData) {
        const aFields = <Array<RowHeader>>JSON.parse(sum.lifeData);
        this.reportsService.extend(newWr, aFields, this.headers, this.localeService.constants.stringLifeData);
      }
      // Setting theese columns to empty stings to avoid getting undefined on csv exports
      newWr['wgGraphInternal'] = '';
      newWr['adgGraphInternal'] = '';
      newWr['lastSeenInternal'] = sum.timeStamp;
      if (sum.animalLastSeen != null && sum.animalLastSeen > sum.timeStamp) {
        newWr['lastSeenInternal'] = sum.animalLastSeen;
      }
      if (sum.pplLastSeen !== null) {
        newWr['lastSeenInternal'] = sum.pplLastSeen;
      }
      if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) { // only PPL Farms have this field generated
        newWr['remoteWowDevice'] = sum.paddockName;
      }
      params.forEach(param => {
        // if wrc[param] is null and there is any filter in place then we do not show it.
        if (search[param].compare(newWr[param])) {
          isFilteredOut = true;
        }
      });

      if (this.filteredCensus && !isFilteredOut) {
        this.filteredCensus.animalCount++;
        if (newWr['Weight']) {
          this.filteredCensus.animalAdg += sum.adgFromLastSeen ? sum.adgFromLastSeen : 0;
          this.filteredCensus.averageWeight += newWr['Weight'];
          animalWithWeightCount++;
          if (this.filteredCensus.maxWeight < newWr['Weight']) {
            this.filteredCensus.maxWeight = newWr['Weight'];
          } else if (this.filteredCensus.minWeight > newWr['Weight'] || this.filteredCensus.minWeight === 0) {
            this.filteredCensus.minWeight = newWr['Weight'];
          }
        }
      }

      newWr['adgLastSeenInternal'] = this.updateAdgSinceLast(newWr);

      this.fullDataSet.push(newWr);
      if (!isFilteredOut) {
        this.data.push(newWr);
        if (newWr['EID'] !== null && newWr['EID'] !== undefined && newWr['EID'] !== '') { // get rid of non-EID animals
          this.dataForPpl.push(newWr['#aid']);
        }
      }
      this.filteredAnimals = this.data;
    });

    // changing this as per MIHUBDRY-4322
    // was designed to distinguish our generated headers from UDFs by adding a suffix, but exporting and reimporting that file causes problems
    // initial plan was to just leave the two columns with the same name, but this fails to display the UDF one due to same name.
    // I have changed our headers' field values across the board so that have "Internal" at the end, but this fails somewhere due to pattern matching the header,
    // so now had to change our calculated field, so that the header has a trailing space as well.... p-table uses the actual header for sorting, so having two
    // matching header strings mean they both display the sort CSS behaviour when class updates are applied to the DOM.
    // Ffffff, I'm sorry!!
    for (const hdr of this.headers) {
      let cIndex = constHeaders.findIndex(hh => hh.header === hdr.header);
      if (cIndex >= 0) {
        // constHeaders[cIndex].field = constHeaders[cIndex].field + 'Internal';
        constHeaders[cIndex].header = constHeaders[cIndex].header + ' ';
      }
    }

    const savedH: Array<RowHeader> = JSON.parse(this.group.fieldDisplayOrder);
    this.headers = constHeaders.reverse().concat(this.headers);
    // remove date column from udf since we will display LastSeen in the table
    if (this.headers.findIndex(hh => hh.field === 'Date') >= 0) {
      this.headers.splice(this.headers.findIndex(hh => hh.field === 'Date'), 1);
    }

    this.headers = FloatingHeaderHelper.reformatHeaderSpaces(this.headers);
    constHeaders = FloatingHeaderHelper.reformatHeaderSpaces(constHeaders);

    if (savedH) {
      this.curHeaders = savedH.filter(sh => sh && this.headers.findIndex(hh => hh && hh.field === sh.field && hh.header === sh.header) >= 0)
        .map(sh => this.headers.find(hh => hh.field === sh.field)).slice();
    } else {
      this.curHeaders = constHeaders.filter(hh =>
        hh.field !== 'adgFirstSeenInternal' && hh.field !== 'weightGainFirstSeenInternal' && hh.field !== 'weightGainLastSeenInternal').slice();
    }

    if (this.filteredCensus) {
      this.filteredCensus.averageWeight = animalWithWeightCount > 0 ? this.filteredCensus.averageWeight / animalWithWeightCount : 0;
      this.filteredCensus.animalAdg = animalWithWeightCount > 0 ? this.filteredCensus.animalAdg / animalWithWeightCount : 0;
    }
    this.filterOptions = this.headers.filter(header => header.field[0] && header.field[0] !== '#' && header.field !== 'adgGraphInternal' && header.field !== 'wgGraphInternal')
      .map(header => {
        return { label: header.header['replaceAll']('&nbsp;', ' '), type: header['type'] >= 0 ? header['type'] : 1, value: header.field }
      });

    if (this.permissionsService.permissions.canAccessPpl && this.integrationService.integration.value) { // only PPL Farms need this check
      let filteredWithPaddock = this.filteredAnimals.filter(fa => fa.remoteWowDevice && fa.remoteWowDevice !== undefined && fa.remoteWowDevice !== '');
      if (filteredWithPaddock && filteredWithPaddock.length > 0) {
        let paddockCheckName = filteredWithPaddock[0].remoteWowDevice;
        let filteredByName = this.filteredAnimals.filter(fa => fa.remoteWowDevice === paddockCheckName);
        this.isOnlyAnimalsFromSamePaddock = filteredByName.length === this.filteredAnimals.length && paddockCheckName.length > 0;
      } else {
        this.isOnlyAnimalsFromSamePaddock = false;
      }
    }

    setTimeout(
      <TimerHandler><unknown>this.getGraphs()
    );
  }

  showCreateDraftModal() {
    const modalRef: NgbModalRef = this.ngbModal.open(CreateDraftComponent, {});

    const component: CreateDraftComponent = modalRef.componentInstance;

    component.animalIds = this.dataForPpl;
    component.integratedDevice = this.curIntegratedDevice;
    component.integratedDevices = this.integration.integratedDevices;
  }

  markAsSold(rows: Array<any>) {
    this.mainService.actionDate = this.mainService.setDate();
    let animalIds = rows.filter(r => r['#aid']).map(r => r['#aid']);
    let wrs = this.allWrc.filter(w => animalIds.findIndex(a => a === w.animalId) >= 0).map(summary =>
      <WeightRecord>{
        weightRecordId: summary.weightRecordId, weight: summary.weight, animal_AnimalId: summary.animalId,
        animal: { userDefinedFieldsJson: summary.lifeData }, userDefinedFieldsJson: summary.sessionData
      });
    let action = <SessionAction>{ animalCount: wrs.length, ided: true, weightRecords: wrs, groupId: this.group.groupId };
    let filtered = wrs.length < this.fullDataSet.length; // currently not using this, so all modals will just be confirmations

    this.actionsSoldDeadService.init(action, AnimalStatusEnum.sold, true).subscribe(() => {
      this.processData();
    });
  }

  markAsDied(rows: Array<any>) {
    this.mainService.actionDate = this.mainService.setDate();
    let animalIds = rows.filter(r => r['#aid']).map(r => r['#aid']);
    let wrs = this.allWrc.filter(w => animalIds.findIndex(a => a === w.animalId) >= 0).map(summary =>
      <WeightRecord>{
        weightRecordId: summary.weightRecordId, weight: summary.weight, animal_AnimalId: summary.animalId,
        animal: { userDefinedFieldsJson: summary.lifeData }, userDefinedFieldsJson: summary.sessionData
      });
    let action = <SessionAction>{ animalCount: wrs.length, ided: true, weightRecords: wrs, groupId: this.group.groupId };
    let filtered = wrs.length < this.fullDataSet.length; // currently not using this, so all modals will just be confirmations

    this.actionsSoldDeadService.init(action, AnimalStatusEnum.dead, true).subscribe(() => {
      this.processData();
    });
  }

  sortEvent(event: any) {
    this.reloadGraphWhenSort(event);
    this.sortDetails = event;
  }

  reloadGraphWhenSort(event: any) {
    if (event.field !== 'wgGraphInternal' && event.field !== 'adgGraphInternal') {
      if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
        window.scrollTo(0, 0);
      }
      this.graphsLoaded = false;
      this.getGraphs();
    }
  }

  filtered(event) {
    if (event.filteredValue.length < this.data.length || Object.keys(this.globalParameterService.detailsSearchParams.value).length > 0) {
      this.filteredCensus = new GroupCensusWeightSummary();
      let totalCount = 0, totalWeight = 0, totalCountWithWeight = 0, adg = 0;
      event.filteredValue.forEach(el => {
        totalCount++;
        if (el.Weight) {
          totalCountWithWeight++;
          totalWeight += el.Weight;
          adg += el.adgLastSeen;
          if (el.Weight > this.filteredCensus.maxWeight) {
            this.filteredCensus.maxWeight = el.Weight;
          }
          if (el.Weight < this.filteredCensus.minWeight || this.filteredCensus.minWeight === 0) {
            this.filteredCensus.minWeight = el.Weight;
          }
        }
      });
      this.filteredCensus.averageWeight = totalCountWithWeight > 0 ? totalWeight / totalCountWithWeight : 0;
      this.filteredCensus.animalCount = totalCount;
      this.filteredCensus.animalAdg = adg > 0 ? adg / totalCountWithWeight : 0;

      this.filteredAnimals = event.filteredValue;

    } else {
      this.filteredCensus = null;
      this.filteredAnimals = this.data;
    }

    if (this.floatHeaders) {
      this.floatHeaders.checkScrollbarVisibility();
    }
    setTimeout(() => {
      this.dataForPpl = this.filteredAnimals.filter(f => f['EID'] !== null && f['EID'] !== undefined && f['EID'] !== '').map(v => v['#aid']); // get rid of non-EID animals
    });
  }

  updateAdgSinceLast(wr) {
    if (wr['adgLastSeenInternal'] && wr['adgLastSeenInternal'] !== null && wr['adgLastSeenInternal'] !== '') {
      return wr['adgLastSeenInternal'];
    }
    if (this.awgs) {
      if (wr && wr !== undefined && wr['#aid'] && this.awgs[wr['#aid']]) {
        let awg = this.awgs[wr['#aid']];
        return Rounder.round(awg.adgLastSeen);
      }
    }
    return '';
  }

  columnsReordered(columns, scrollRight = false) {
    this.curHeaders = columns.map(e => this.headers.find(h => h.field === e.field));
    const fieldDisplayOrder = JSON.stringify(this.curHeaders);
    let currentRoleId = this.authService.currentUser.userFarms.find(uf => uf.farmId === this.authService.currentUser.currentFarm_FarmId).farmRoleId;
    if (this.authService.currentUser && this.authService.currentUser.userType.toLowerCase() !== 'superuser' && currentRoleId !== 3) {
      this.groupsService.changeGroupColumnDisplayOrder(this.group.groupId, fieldDisplayOrder)
        .subscribe(() => {
          this.group.fieldDisplayOrder = fieldDisplayOrder;
        });
    } else {
      // we don't want SuperUser or GroupViewer to change the columns displayed or their order for the Group in the DB,
      // but we do want them to be able to change it for themselves during current viewing
      this.group.fieldDisplayOrder = fieldDisplayOrder;
    }

    if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
      window.scrollTo(0, 0);
    }
  }

  getGraphs(first: number = null, rows: number = null) {
    if (!this.gettingGraphs) {
      this.gettingGraphs = true;
      this.ngZone.run(() => {
        if (!this.data) {
          return;
        }
        if (this.dataTable && (!first || !rows)) {
          first = this.dataTable.first;
          rows = this.dataTable.rows;
        }
        let animalIds = this.data.slice(first, first + rows).filter(a => !a['sparkline'] && a['#aid']).map(a => a['#aid']);
        if (this.group && animalIds && animalIds.length > 0) {
          // Using pagination to only download data that is curently needed for graphs.
          const groupId = this.group.groupId;
          this.weightRecordService.GetAllWeightRecordsForAnimals(groupId, animalIds)
            .subscribe(wr => {
              if (wr !== null && wr !== undefined) {
                this.data.forEach(cr => {
                  if (wr.find(w => w.animalId === cr['#aid'])) {
                    const animalData = <Array<any>>JSON.parse(wr.find(w => w.animalId === cr['#aid']).chartPoints) || [],
                      barPoints = [], barDates = [];
                    // Working out adg for bar graph
                    // This is adg between every session
                    for (let i = 1, l = animalData.length; i < l; i++) {
                      const daysDiff: number = (new Date(animalData[i].timeStamp).getTime()
                        - new Date(animalData[i - 1].timeStamp).getTime()) / 86400000;
                      barPoints.push(Math.round((animalData[i].weight - animalData[i - 1].weight)
                        / (daysDiff !== 0 ? daysDiff : 1) * 1000) / 1000);
                      const date = ConvertToUTC(animalData[i].timeStamp).toDate();
                      barDates.push(`${date.getUTCDate()} ${this.localeService.month[(date.getUTCMonth())].month} ${date.getUTCFullYear()}`); // eg: 30 Dec 2017
                    }
                    const series = [{
                        name: this.localeService.constants.stringWeight,
                        allowPointSelect: false,
                        data: animalData.map(ad => {
                          return { x: ConvertToUTC(ad.timeStamp).valueOf(), y: ad.weight };
                        }),
                        marker: { enabled: !(this.isPplFarmWithIntegration && animalData.length>1), lineWidth: 2, lineColor: 'rgb(124, 181, 236)' },
                        color: 'rgb(124, 181, 236)',
                        zIndex: 2,
                      }],
                      seriesBar = [{
                        name: 'ADG',
                        allowPointSelect: false,
                        data: barPoints,
                        marker: { enabled: false }
                      }],
                      xAxis: any = {
                        type: 'datetime',
                        labels: {
                          enabled: false
                        },
                        title: {
                          text: null
                        },
                        startOnTick: false,
                        endOnTick: false,
                        tickPositions: []
                      };

                    cr.sparkline = GroupDetailsWeightGain.getOptionsSparkline(this.group.targetWeightPlan,
                      xAxis,
                      series,
                      this.localeService.constants.stringTarget);

                    cr.barGraph = GroupDetailsWeightGain.getOptionsColumn(this.group.targetWeightPlan, {
                      categories: barDates,
                      lineWidth: 0,
                      minorGridLineWidth: 0,
                      lineColor: 'transparent',
                      labels: {
                        enabled: false
                      },
                      minorTickLength: 0,
                      tickLength: 0
                    }, seriesBar);
                  }
                });
              }
              setTimeout(() => {
                this.graphsLoaded = true;
              });
            });
        } else {
          setTimeout(() => {
            // Had to add this line to fix the issue on FF wich caused table columns to resize
            // was caused by jquery (highcharts) manupilating dom same time as angular does its pass.
            this.graphsLoaded = true;
          });
        }
      });
      this.gettingGraphs = false;
    }
  }

  filterChanged(event = null) {
    this.graphsLoaded = false;
    setTimeout(() => {
      this.getGraphs();
    })
  }

  ngAfterViewInit() {
    $('.ui-table-wrapper').scroll(event => {
      setTimeout(
        <TimerHandler><unknown>FloatingHeaderHelper.clipFloatingHeader($('.ui-table-wrapper').scrollLeft(), this.renderer)
        , 1000
      );
    });

    $('.ui-paginator').click(event => {
      if (FloatingHeaderHelper.IsDisplayingFloatingHeader()) {
        window.scrollTo(0, 0);
      }

      // need to trigger the loading of the next batch of graphs based on the updated table content
      setTimeout(
        <TimerHandler><unknown>this.getGraphs()
      );
    });

    // $(".spacious-container").floatingScroll();
  }

  openAnimalInModal(animal: Animal): void {
    const modalRef = this.ngbModal.open(AnimalInfoComponent, {
      backdrop: 'static',
      windowClass: 'modal-right modal-75'
    });

    const animalId = animal['animalId'] || animal['AnimalId'] || animal['#aid'];
    modalRef.componentInstance.animalId = animalId;
    modalRef.componentInstance.parentView = AnimalInfoParentViewEnum.GROUP;
    modalRef.componentInstance.parentId = this.group.groupId;

    let currPath = this.location.path();
    let updatedUrl = currPath.substring(0, currPath.indexOf('/groups/details')) + '/animals/all?id=' + animalId + '&tab=summary';
    this.location.go(updatedUrl); // rewrites url without reloading component etc

    modalRef.componentInstance.actionDone.subscribe(res => {
      this.groupChanged();
      // if (res.group) {
      //   const currentAnimal = this.rows.find(r => r['AnimalId'] && r['AnimalId'] === +animalId);
      //   const group = this.groupsService.groups.value.find(g => g.groupId === res.group.groupId);
      //   currentAnimal['groupName'] = group.displayName;
      // } else {
      //   this.statusChangeAnimalIds = [res.vActionId];
      //   this.statusChangeValue = res.status;
      //   this.soldDeadModalResult(res.vActionId);
      // }
    });
  }

  private getAnimalSummary(): Observable<any> {
    if (this.weightDataSub) {
      return of(this.weightDataSub);
    } else {
      return this.weightRecordService.GetAnimalsWeightSummaries(this.mainService.farmChanged.value.farmId)
        .pipe(map(res => {
          this.weightDataSub = res;
          return res;
        }));
    }
  }

  // new assignment or assign to different group
  assignOrReassignAnimals(): void {
    let animalIds = this.filteredAnimals.map(function(a) { return a['#aid']; });
    this.filteredAnimals.forEach(fa => {
      fa.groups_GroupId = this.group.groupId;
      fa.animal_AnimalId = fa['#aid'];
    });
    this.actionsAssignFilteredListModalService.init(animalIds, this.filteredAnimals).subscribe(result => {
      this.groupChanged();
    });
  }

  dayMonthYearString(date: string) {
    return ConvertToUTC(date).format('D MMM YYYY');
  }
}

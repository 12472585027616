import { AuthService } from '../../auth/auth.service';





import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { Breed } from '../models/breed.model';
import { LocaleService } from '../../helpers/locale.service';
import { Observable } from 'rxjs';

import { Species } from '../models/species.model';
import { MainService } from '../main.service';
import { map, catchError } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpeciesService {
    species: Array<Species> = [];

    constructor(private authService: AuthService, private apiService: ApiService, private localeService: LocaleService, private mainService: MainService) {

    }


    getSpecies(): Observable<Array<Species>> {
        if (this.species) {
            return of(this.species);
        }
        return combineLatest(this.localeService.getBreedsSexAndSpeciesTranslation(), this.apiService.get(`/odata/Species`).pipe(map((res: any) => res.value)))
            .pipe(map(res => {
                if (res[0] && res[1]) {
                    this.localeService.translateBreedSexArray(res[1], 'speciesName', res[0]['species']);
                    this.species = res[1];
                    return res[1];
                }
            }),
                catchError(this.apiService.handleError));
    }
}



import { FarmUser } from '../../models/farm-user.model';
import { MobileApplication } from '../../models/data-link-device.model';
import { EArray } from '../../../helpers/constants.helper';
import { DeviceService } from '../../api/device.service';
import { Device } from '../../models';
import { Observable ,  Subscription, combineLatest } from 'rxjs';

import { MainService } from '../../main.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatypeHelper } from '../../../helpers/datatype.helper';
import * as moment from 'moment';

declare let objectIsEquivalent: any;

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
  providers: [DeviceService]
})
export class DeviceDetailsComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  devices: Array<Device>;
  mobileApps: Array<MobileApplication>;
  farmUsers: Array<FarmUser>;
  showDevData = false;

  constructor(private mainService: MainService, private deviceService: DeviceService
    , private route: ActivatedRoute) { }

  ngOnInit() {
    this.showDevData = this.route.snapshot.queryParams['dev'] === 'true' || false;

    this.subscription = this.mainService.farmChanged.subscribe(farm => {
      if (farm) {
        this.deviceService.GetDeviceForFarm(farm.farmId).subscribe(devices => {
          devices.forEach(device => {
            device.model = device.model.replace('EZIWEIGH', 'EziWeigh');
            if (device.deviceJson && (device.model.toUpperCase() === 'THOR' || device.model.toUpperCase() === '46000W')) {
              // try to get serial from DeviceJson
              // if not there, then still use the MAC address already set into serialNumber field
              let dj = JSON.parse(device.deviceJson);
              if (dj['CaseSerialNumber']) {
                device.serialNumber = dj['CaseSerialNumber'];
              }
            }
          });
          this.devices = devices;
        });

        combineLatest(this.mainService.getUsers(), this.deviceService.GetDataLinkDeviceForFarm(farm.farmId)).subscribe(uAndD => {
          this.farmUsers = uAndD[0];
          this.mobileApps = uAndD[1];
          this.mobileApps.forEach(device => {
            const user = this.farmUsers.find(u => u.userId === device.modifiedBy);
            if (user) {
              device['lastUser'] = `${user.firstName} ${user.lastName}`;
            }
            if (!device.appType) {
              device.appType = 'Data Link';
            }
          });
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dayMonthYearString(date: Date) {
    return moment.tz(moment.utc(date).format(),'YYYY-MM-DD HH:mm:ss', 'Pacific/Auckland').local().format('D MMM YYYY')
  }
}
